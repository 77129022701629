<!-- eslint-disable global-require -->
<template>
    <div id="detailMarketItem">
        <div class="container">
            <div class="row mt-2">
                <div class="col-lg-6 col-12 py-3">
                    <div v-if="detail" class="detailBox-image w-100">
                        <img :src="detail.item.image" alt="" />
                    </div>
                </div>
                <div v-if="detail" class="col-lg-6 col-12 py-3 px-lg-5">
                    <div class="detailBox-content w-100">
                        <div class="d-flex align-items-center">
                            <div class="box-title-tag">
                                <img :src="tagImg" alt="" />
                            </div>
                            <div class="title-tag">{{ detail.platform }}</div>
                        </div>
                        <div class="detailBox-content-title">
                            {{ detail.type }} | {{ detail.itemName }}
                        </div>
                        <div class="detailBox-content-decs" v-html="decs"></div>
                        <div v-if="false" class="detailBox-btn">
                            <button class="bede-btn">
                                <p>Inspect in Game...</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detailBox-main-content mt-lg-5 mt-3">
                <p>
                    The appearance of items in the individual listings may vary
                    slightly from the one above. For example, items could have
                    custom names, descriptions, or colors.
                </p>
                <p>After purchase, this item:</p>
                <ul>
                    <li>Will not be tradable for one week</li>
                    <li>
                        Can immediately be re-sold on the Steam Community Market
                    </li>
                </ul>
            </div>
            <div v-if="dataSelect" class="chartItem mt-5">
                <div class="chartItem-box p-3 pt-4">
                    <ChartItem :dataPropped="dataSelect" />
                </div>
            </div>
            <div class="section-item-detail">
                <DetailList />
            </div>
        </div>
    </div>
</template>

<script>
import ChartItem from '@/components/shared/ChartItem.vue';
import DetailList from '@/components/market-second/detail-list.vue';

const axios = require('@/store/axios.config').default;

export default {
    components: { ChartItem, DetailList },
    data() {
        return {
            // eslint-disable-next-line global-require
            img: require('@/assets/images/mockup/market/new/mk2.svg'),
            // eslint-disable-next-line global-require
            tagImg: require('@/assets/images/mockup/market/new/mk3.svg'),
            decs: '<span class="main-text-color">Exterior: </span>Battle-Scarred <br/> The SSG08 bolt-action is a low-damage but very cost-effective sniper rifle, making it a smart choice for early-round long-range marksmanship. It has individual parts spray-painted solid colors in a moss color scheme.<br/> <span class="main-text-color">The Safehouse Collection</span>',

            listChart: null,
            detail: null,
        };
    },
    computed: {
        dataSelect() {
            return this.listChart;
        },
    },
    methods: {
        async getDetailsList() {
            const { type, itemName } = this.$route.params;

            const data = await axios.get(
                `/api/herocoin/detail-list/${type}/${itemName}`,
                {
                    params: this.filter,
                },
            );
            if (data && data.results.length > 0) {
                this.listChart = data.chart;
                // eslint-disable-next-line prefer-destructuring
                this.detail = data.results[0];
            }
            console.log(this.detail);
        },
    },
    created() {
        this.getDetailsList();
    },
};
</script>

<style lang="scss">
#detailMarketItem {
    font-family: 'Poppins';
    font-style: normal;
    .detailBox-image {
        background: linear-gradient(
            180deg,
            rgba(53, 63, 72, 0) 0%,
            rgba(53, 63, 72, 0.2) 100%
        );
        border-radius: 16px;
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 80%;
        }
    }
    .detailBox-content {
        margin-top: 60px;
        .box-title-tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
            border-radius: 100px;
            padding: 5px;
            img {
                width: 100%;
            }
        }
        .title-tag {
            font-weight: 500;
            font-size: 16px;
            color: #f0f0f0;
            margin: 0;
            padding-left: 30px;
        }
        .detailBox-content-title {
            font-weight: 600;
            font-size: 32px;
            color: #fcfcfc;
            padding: 18px 0 30px;
        }
        .detailBox-content-decs {
            font-weight: 500;
            font-size: 16px;
            line-height: 29px;
            color: #f8f8f8;
        }
        .detailBox-btn {
            width: 100%;
            max-width: 270px;
            margin-top: 40px;
            button {
                border-radius: 4px;
                width: 100%;
                height: 54px;
                font-weight: 500;
                font-size: 18px;
                color: #00ffff !important;
                outline: unset !important;
                &::before {
                    border-radius: 4px;
                }
            }
        }
    }
    .detailBox-main-content {
        p {
            margin: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 29px;
            color: #dcdcdc;
        }
        ul {
            li {
                font-weight: 500;
                font-size: 16px;
                line-height: 29px;
                color: #dcdcdc;
            }
        }
    }
    .chartItem {
        background: linear-gradient(rgba(35, 60, 87, 0), rgba(35, 60, 87, 0.3));
        border-top: 1.5px solid rgb(35, 60, 87);
        border-left: 1.5px solid rgb(35, 60, 87);
        border-bottom: 3.5px solid rgb(35, 60, 87);
        .chartItem-title {
            margin: 0;
            padding: 15px 25px 10px;
            font-size: 13px;
            color: #ccc;
        }
        .chartItem-box {
            min-height: 450px;
        }
    }
    .select-chartItem {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 25px 20px 0;
        button {
            padding: 0;
            outline: unset;
            box-shadow: none;
            border: none;
            font-size: 13px;
            color: #ccc;
            margin: 0 8px;
            opacity: 0.4;
            font-weight: 500;
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }
        button.isActive {
            opacity: 1;
        }
    }
}
</style>
