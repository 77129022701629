const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        listPicker: [],
        listIdPicker: [],
        listHero: {
            list: [],
            current: 1,
            total: 1,
        },
        history: {
            total: 1,
            list: [],
        },
    }),
    getters: {
        ListPicker: (state) => state.listPicker,
        ListIdPicker: (state) => state.listIdPicker,
        ListHero: (state) => state.listHero,
        History: (state) => state.history,
    },
    actions: {
        req_getListHero({ commit }, input) {
            axios
                .get(`/api/hero/list-inject`, {
                    params: input,
                })
                .then((res) => {
                    if (res) {
                        commit('ADD_LIST_HERO', res);
                    }
                });
        },
        req_getListHistory({ commit }, input) {
            axios
                .get('/api/hero/inject-vaccine-history', {
                    params: input,
                })
                .then((res) => {
                    if (res) {
                        commit('SET_LIST_HISTORY', res);
                    }
                });
        },
    },
    mutations: {
        SET_LIST_HISTORY(state, data) {
            const { totalPages, results } = data;
            state.history = {
                list: results ?? [],
                total: totalPages ?? 1,
            };
        },
        ADD_LIST_HERO(state, data) {
            const { listPicker } = state;
            const { results, page, totalPages, totalResults } = data;
            const listHero = results;

            for (let i = 0; i < listHero.length; i += 1) {
                const obj = listHero[i];

                if (listPicker.indexOf(obj.tokenId) !== -1) {
                    listHero[i].onUpgrade = true;
                } else {
                    listHero[i].onUpgrade = false;
                }
            }
            state.listHero = {
                list: listHero,
                current: page,
                total: totalPages,
                totalHero: totalResults,
            };
        },

        RESET_PICKER(state) {
            state.listPicker = [];
            state.listIdPicker = [];
        },
        REMOVE_HERO(state, hero) {
            const { listPicker, listIdPicker } = state;
            const idx = listPicker.findIndex((x) => x.tokenId === hero);
            listPicker.splice(idx, 1);
            listIdPicker.splice(idx, 1);
            state.listPicker = listPicker;
            state.listIdPicker = listIdPicker;
        },
        ADD_HERO(state, hero) {
            const { listPicker, listIdPicker } = state;
            if (listPicker.length >= 1) {
                return;
            }
            listPicker.push(hero);
            listIdPicker.push(hero.tokenId);
            state.listPicker = listPicker;
        },
    },
};
