<template>
    <div id="listCurrentGame">
        <table class="tablelist-game table table-borderless">
            <thead>
                <tr class="background-header">
                    <th scope="col" class="text-center">#</th>
                    <th colspan="2">Name</th>
                    <th scope="col">
                        <p class="m-0 text-center">Blockchain</p>
                    </th>
                    <th scope="col" class="text-center">Device</th>
                    <th scope="col" class="text-center">Status</th>
                    <th scope="col" class="text-center">NFT</th>
                    <th scope="col" class="text-center">Rating</th>
                </tr>
            </thead>
            <tbody v-for="(game, idx) in listCurrentGame" :key="idx">
                <tr :class="idx % 2 === 0 ? 'background1' : 'background2'">
                    <th scope="col">
                        <p class="table-ID text-center">{{ (idx += 1) }}</p>
                    </th>
                    <th colspan="2">
                        <div class="d-flex align-items-center">
                            <div class="table-game-logo">
                                <img :src="game.logo" alt="" />
                            </div>
                            <a :href="game.ref" class="table-game-name">
                                {{ game.name }}
                            </a>
                        </div>
                    </th>
                    <th scope="col">
                        <p class="table-ID text-center isNFT">
                            {{ game.blockChain }}
                        </p>
                    </th>
                    <th
                        scope="col"
                        class="d-flex align-items-center justify-content-center"
                    >
                        <div
                            v-for="device in game.devices"
                            :key="device.img + '' + idx"
                        >
                            <img class="device-img" :src="device.img" alt="" />
                        </div>
                    </th>
                    <th scope="col" class="text-center">
                        <div class="game-status d-flex justify-content-center">
                            <p :class="setStatusColor(game.status)" class="m-0">
                                {{ game.status }}
                            </p>
                        </div>
                    </th>
                    <th scope="col">
                        <p class="table-ID text-center">
                            <span v-if="game.isNFT" class="isNFT">Yes</span>
                            <span v-else class="isNotNFT">No</span>
                        </p>
                    </th>
                    <th scope="col">
                        <div class="box-rating">
                            <div class="box-rating-process">
                                <div
                                    class="box-rating-process-value"
                                    :style="{
                                        width: formatValueRating(game.rating),
                                    }"
                                ></div>
                            </div>
                            <p class="m-0">{{ game.rating }}</p>
                        </div>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            listCurrentGame: [
                {
                    name: 'Hero Snake',
                    // eslint-disable-next-line global-require
                    logo: require('../../assets/images/system/hero-snake-logo.png'),
                    blockChain: 'HBG',
                    devices: [
                        // {
                        // eslint-disable-next-line global-require
                        //     img: require('../../assets/images/logo/logo-apple.png'),
                        // },
                        {
                            // eslint-disable-next-line global-require
                            img: require('../../assets/images/logo/logo-android.png'),
                        },
                    ],
                    status: 'Beta',
                    isNFT: true,
                    rating: '8',
                    ref: 'https://herosnake.io',
                },
                {
                    name: 'Hero Book',
                    // eslint-disable-next-line global-require
                    logo: require('../../assets/images/system/hero-book-logo.png'),
                    blockChain: 'HBG',
                    devices: [
                        {
                            // eslint-disable-next-line global-require
                            img: require('../../assets/images/logo/logo-android.png'),
                        },
                    ],
                    status: 'Update',
                    isNFT: false,
                    rating: '6',
                    ref: 'https://game.herobook.io/',
                },
                {
                    name: 'Hero Land',
                    // eslint-disable-next-line global-require
                    logo: require('../../assets/images/system/hero-land-logo.png'),
                    blockChain: 'HBG',
                    devices: [
                        {
                            // eslint-disable-next-line global-require
                            img: require('../../assets/images/logo/logo-android.png'),
                        },
                    ],
                    status: 'Live',
                    isNFT: true,
                    rating: '7',
                    ref: 'https://heroland.app/home',
                },
                {
                    name: 'Hero Card',
                    // eslint-disable-next-line global-require
                    logo: require('../../assets/images/system/hero-card-logo.png'),
                    blockChain: 'HBG',
                    devices: [
                        {
                            // eslint-disable-next-line global-require
                            img: require('../../assets/images/logo/logo-window.png'),
                        },
                    ],
                    status: 'Development',
                    isNFT: true,
                    rating: '0',
                    ref: '#',
                },
                {
                    name: 'Hero War',
                    // eslint-disable-next-line global-require
                    logo: require('../../assets/images/system/hero-war-logo.png'),
                    blockChain: 'HBG',
                    devices: [
                        {
                            // eslint-disable-next-line global-require
                            img: require('../../assets/images/logo/logo-window.png'),
                        },
                    ],
                    status: 'Beta',
                    isNFT: false,
                    rating: '8',
                    ref: 'https://herowar.co',
                },
            ],
            listDevice: [],
        };
    },
    methods: {
        setStatusColor(status) {
            // return class name for status
            switch (status) {
                case 'Beta':
                    return 'statusBeta';
                case 'Live':
                    return 'statusLive';
                case 'Update':
                    return 'statusUpdate';
                case 'Development':
                    return 'statusUpdate';
                default:
                    return 'statusDefault';
            }
        },
        formatValueRating(value) {
            const newVal = parseFloat(value);
            return `${newVal * 10}%`;
        },
    },
};
</script>

<style lang="scss" scoped>
#listCurrentGame {
    overflow-x: auto;
    table.tablelist-game {
        color: #fff;
        font-size: 14px;
        min-width: 1000px;
        thead {
            th {
                font-size: 16px;
            }
        }
        .table-ID {
            margin: 0;
            padding-top: 12px;
        }
        .isNFT {
            color: #00ffff;
        }
        .isNotNFT {
            color: #888;
            opacity: 0.9;
        }
        .table-game-logo {
            width: 48px;
            height: 48px;
            padding: 8px;
            background: rgba(0, 255, 255, 0.15);
            backdrop-filter: blur(2px);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            img {
                width: 100%;
                height: auto;
            }
        }
        .table-game-name {
            margin: 0;
            padding-left: 10px;
            font-size: 18px;
            text-decoration: none;
            color: #fff;
            font-family: 'HHBI';
            &:hover {
                color: #00ffff;
            }
        }
        .device-img {
            height: 24px;
            width: auto;
            margin: 8px 5px 0;
        }

        .game-status {
            margin-top: 9px;
            p {
                padding: 5px 20px;
                border-radius: 20px;
            }
        }

        .box-rating {
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            .box-rating-process {
                height: 1rem;
                width: 100%;
                background: rgba(0, 255, 255, 0.2);
                border-radius: 20px;
                .box-rating-process-value {
                    background: #00ffff;
                    border-radius: 20px;
                    height: 1rem;
                }
            }
            p {
                padding-left: 12px;
                width: 32px;
            }
        }

        .background1 {
            background: rgba(53, 63, 72, 0.4);
        }
        .background2 {
            background: rgba(53, 63, 72, 0.2);
        }
        .background-header {
            background: rgba(0, 255, 255, 0.15);
        }
        .statusLive {
            background: rgba(0, 255, 255, 0.15);
            color: #00ffff;
        }
        .statusUpdate {
            background: rgba(102, 102, 102, 0.2);
            color: #999;
        }
        .statusBeta {
            background: rgba(255, 165, 0, 0.2);
            color: orange;
        }
    }
}
</style>
