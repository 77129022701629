<script>
import { mapGetters, mapMutations } from 'vuex';
import HeroShow from '@/components/inventory/hero/HeroShow.vue';
import Hero from './Hero.vue';

const axios = require('@/store/axios.config').default;

export default {
    components: { Hero, HeroShow },
    data() {
        return {
            promotion: {},
            isLock: true,
            slug: null,
            detail: null,
            listHero: {
                total: 1,
                list: 0,
            },
            filterList: {
                rarity: null,
                skin: null,
                page: 1,
                limit: 10,
            },
            lock: 0,
            listLockSuccess: [],
            step: 1,
            HeroLock: null,
            beTransaction: null,
            heroLockPick: null,
            heroChoose: null,
            resetData: true,
        };
    },
    computed: {
        ...mapGetters('promotion', [
            'ListPicker',
            'ListIdPicker',
            'ListHero',
            'LockAddress',
        ]),
        listHeroLocked() {
            if (this.detail) {
                return this.detail.myHero;
            }
            return [];
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),

        onChoseHero(hero) {
            this.$store.commit('promotion/ADD_HERO', hero);
        },
        onRemoveHero(hero) {
            console.log(hero);
            this.$store.commit('promotion/REMOVE_HERO', hero);
        },
        onLocked(type, id) {
            if (type === 0) {
                this.onTransferHero(id);
            }
            if (type === 1) {
                this.onLockHeroMore(id);
            }
        },
        async onTransferHero(tokenId) {
            this.onLoad();
            const { hero } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await hero.cont.methods
                .transferFrom(addr, this.LockAddress, tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await hero.cont.methods
                    .transferFrom(addr, this.LockAddress, tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hero.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.listLockSuccess.push(tokenId);
                            this.onSendTransaction(
                                txTransferData.transactionHash,
                            );
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async onLockHeroMore(Id) {
            const { id } = this.detail.detailPromo;
            const data = await axios.post('/api/promotion/join-plus', {
                tokenId: Id,
                promotionId: id,
            });
            if (data) {
                this.HeroLock = data;
                this.onGetDetail();
                this.$bvModal.show('lockSuccess');
                this.$bvModal.hide('heroLockConfirm');
                this.outLoad();
            }
            this.outLoad();
        },
        async onSendTransaction(hash) {
            this.onLoad();
            const { id } = this.detail.detailPromo;
            const data = await axios.post('/api/promotion/block-hero', {
                transactionHash: hash,
                _idPromotion: id,
            });
            if (data) {
                this.HeroLock = data;
                this.onGetDetail();
                this.$bvModal.show('lockSuccess');
                this.$bvModal.hide('heroLockConfirm');
                this.outLoad();
            } else if (hash) {
                this.beTransaction = hash;
                await this.sleep(5000);
                this.onSendTransaction(this.beTransaction);
            }
        },
        async onGetDetail() {
            const data = await axios.get(`/api/promotion/${this.slug}`);
            if (!data) {
                this.$router.push({ name: 'Promotion' });
                this.$toast.error('Promotion not found');
                return;
            }
            this.detail = data;
            this.isLock = data.detailPromo.isLock;
            const { myHero } = data;
            if (myHero.length >= 1) {
                this.isLock = true;
                for (let i = 0; i < myHero.length; i += 1) {
                    this.listLockSuccess.push(myHero[i].tokenId);
                }
            }
        },
        showConfirmLock(payload) {
            this.heroChoose = payload;
            this.$bvModal.show('heroLockConfirm');
        },

        pickHero(hero) {
            this.heroLockPick = null;
            this.heroLockPick = hero;
            // eslint-disable-next-line no-underscore-dangle
            this.$bvModal.show(`modal-info-lock`);
        },
        fetchNewData() {
            this.resetData = false;
            this.$nextTick(() => {
                this.resetData = true;
            });
        },
    },
    watch: {
        $route: {
            handler() {
                const { slug } = this.$route.params;
                if (slug !== this.slug) {
                    this.slug = slug;
                    this.onGetDetail();
                }
            },
        },
    },
    mounted() {
        this.$store.dispatch('promotion/req_getAddressLock');
        const { slug } = this.$route.params;
        if (slug) {
            this.slug = slug;
            this.onGetDetail();
        } else {
            this.$router.push({ name: 'Promotion' });
        }
    },
    beforeDestroy() {
        this.slug = null;
        this.detail = null;
        this.$store.commit('promotion/RESET_PICKER');
        this.HeroLock = null;
    },
};
</script>
<template>
    <b-container class="detail-promotion" v-if="detail">
        <b-row>
            <b-col cols="12" class="">
                <router-link
                    :to="{ name: 'Promotion' }"
                    class="back-to-shop text-decoration-none"
                >
                    <div class="icons">
                        <img src="~@/assets/images/icons/back.png" alt="" />
                    </div>
                    <div class="text">Back</div>
                </router-link>
            </b-col>
            <b-col cols="12">
                <div class="section-title">
                    <div class="page-name">
                        <div class="before">Detail Promotion</div>
                        <div class="after">Detail Promotion</div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row class="row-detail-lock">
            <b-col cols="12" md="12" :lg="isLock ? 5 : 12" class="px-1 mx-auto">
                <div class="hero-lock-detail mx-auto">
                    <div class="hero-lock-detail-image">
                        <img
                            :src="
                                detail.detailPromo.image
                                    ? detail.detailPromo.image
                                    : require('@/assets/images/logo/logo.png')
                            "
                            alt=""
                        />
                    </div>
                    <div class="hero-lock-detail-text">
                        <div class="hero-lock-detail-text-key">Name</div>
                        <div class="hero-lock-detail-text-title">
                            {{ detail.detailPromo.name }}
                        </div>
                        <div class="hero-lock-detail-text-key">Description</div>
                        <div class="hero-lock-detail-text-description">
                            {{ detail.detailPromo.description }}
                        </div>
                        <div class="hero-lock-detail-text-key">Requirement</div>
                        <div
                            class="hero-lock-detail-text-requirement"
                            v-html="detail.detailPromo.required"
                        ></div>
                        <div class="hero-lock-detail-text-key">Reward</div>
                        <div
                            class="hero-lock-detail-text-reward"
                            v-html="detail.detailPromo.reward"
                        ></div>
                    </div>
                    <div
                        class="hero-lock-detail-action"
                        v-if="
                            !isLock &&
                            detail.isJoin == false &&
                            detail.detailPromo.status === 1 &&
                            detail.detailPromo.isLock
                        "
                    >
                        <b-button
                            variant="primary"
                            class="btn-join"
                            @click="isLock = true"
                        >
                            Join Now
                        </b-button>
                    </div>
                </div>
            </b-col>
            <b-col
                cols="12"
                md="12"
                :lg="isLock ? 7 : 12"
                class="px-1"
                v-if="isLock"
            >
                <div class="container-lock mt-5 mt-lg-0">
                    <div class="title-locked-2">
                        <div class="text">My Hero</div>
                    </div>
                    <div
                        class="body-step"
                        v-if="
                            detail.detailPromo.maxBlockPerUser -
                                detail.myHero.length >
                            0
                        "
                    >
                        <Hero
                            v-if="resetData"
                            class="my-2"
                            :promoId="detail.detailPromo.id"
                            :rarity="detail.detailPromo.ratityHero"
                            :skin="detail.detailPromo.skinHero"
                            @pickHero="showConfirmLock"
                        />
                    </div>
                </div>
                <div class="is-join-promotion" v-if="detail.myHero.length >= 1">
                    <div class="hero-lock-complete">
                        Thank you for participating in this promotion. If you
                        have any related questions, please contact us via the
                        support function in my account or email:
                        support@herobook.io
                    </div>
                </div>
                <div class="list-of-locked">
                    <div class="title-locked">
                        <div class="text">List of locked heroes</div>
                    </div>
                    <div class="title-locked-2">
                        <div class="text">
                            Number Lock: {{ detail.myHero.length }} /
                            {{ detail.detailPromo.maxBlockPerUser }}
                        </div>
                    </div>
                    <div class="list-hero-locked">
                        <div
                            class="hero-l"
                            v-for="hero in detail.myHero"
                            :key="`${hero.tokenId}-${promotion._id}`"
                        >
                            <div class="id">#{{ hero.tokenId }}</div>
                            <img
                                :src="
                                    getImageHero(
                                        '3d',
                                        `${hero.name.toLowerCase()}-${hero.skin.toLowerCase()}`,
                                        hero.version,
                                    )
                                "
                                @click="pickHero(hero)"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="warning-locked">
                    For security reasons and to ensure your personal property,
                    when locking a hero you have to lock each hero one by one,
                    it is the same as transferring the hero through a lock
                    wallet on our system and locking it, so in this process
                    there are It may take up to 24 hours for lock orders to be
                    confirmed by the HeroBook and Binance Smart Chain.
                </div>
            </b-col>
        </b-row>

        <b-modal
            id="listHeroPromotion"
            :hide-footer="true"
            title="List Hero"
            :centered="true"
            body-class="p-1"
            content-class="border-0"
            size="xl"
        >
            <Hero
                class="my-2"
                :promoId="detail.detailPromo.id"
                :rarity="detail.detailPromo.ratityHero"
                :skin="detail.detailPromo.skinHero"
                @close="$bvModal.hide('listHeroPromotion')"
            />
        </b-modal>
        <b-modal
            id="heroLockConfirm"
            :title="`Confirm Locked Hero for Promotion`"
            hide-footer
            size="md"
            @hidden="
                detail.myHero.length != detail.detailPromo.maxBlockPerUser
                    ? onGetDetail
                    : ''
            "
        >
            <div class="text-confirm" v-if="heroChoose">
                Are you sure to lock hero #{{ heroChoose.tokenId }}
                with
                {{
                    detail && detail.detailPromo
                        ? detail.detailPromo.timeBlockNFT
                        : ''
                }}
                months time to participate in promotion ?
            </div>
            <div class="hero hero-receiver mx-auto" v-if="heroChoose">
                <div class="left-hero mx-auto">
                    <div class="top-hero">
                        <div class="id-hero" ref="idHero">
                            #{{ heroChoose.tokenId }}
                        </div>
                        <div class="hero-img">
                            <div class="avatar-hero">
                                <img
                                    v-if="heroChoose.name"
                                    :src="`https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/${heroChoose.name.toLowerCase()}-${heroChoose.skin.toLowerCase()}.png`"
                                    alt=""
                                />
                            </div>
                            <div class="right-hero">
                                <div class="stat-hero">
                                    <div class="hero-level">
                                        {{
                                            10 > heroChoose.level
                                                ? '0' +
                                                  heroChoose.level.toString()
                                                : heroChoose.level
                                        }}
                                    </div>
                                    <img
                                        :key="`inventory-${heroChoose.id}`"
                                        :src="
                                            require(`@/assets/images/mockup/inventory/${heroChoose.ratity.toLowerCase()}.png`)
                                        "
                                        alt=""
                                    />
                                    <img
                                        :key="`inventory-2-${heroChoose.id}`"
                                        :src="
                                            require(`@/assets/images/mockup/inventory/${heroChoose.skin.toLowerCase()}.png?v=1`)
                                        "
                                        alt=""
                                    />
                                    <img
                                        :key="`inventory-3-${heroChoose.id}`"
                                        :src="
                                            require(`@/assets/images/mockup/inventory/${heroChoose.type.toLowerCase()}.png?v=1`)
                                        "
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-hero">
                        <div class="hero-name">
                            the
                            <span>
                                {{ heroChoose.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="form-group d-flex justify-content-center align-items-center mt-2"
                v-if="heroChoose"
            >
                <b-button
                    variant="primary"
                    class="btn-lock mx-auto my-2"
                    v-if="!listLockSuccess.includes(heroChoose.tokenId)"
                    @click="
                        onLocked(
                            heroChoose.quantityPromoteBlock ? 1 : 0,
                            heroChoose.tokenId,
                        )
                    "
                >
                    Lock Hero #{{ heroChoose.tokenId }}
                </b-button>
                <b-button
                    v-if="false"
                    class="mx-auto my-2 button-cancel"
                    variant="primary"
                    @click="$bvModal.hide('heroLockConfirm')"
                >
                    Close
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="lockSuccess"
            @show="fetchNewData()"
            :title="`Confirm Locked Hero for Promotion`"
            hide-footer
            size="md"
        >
            <div class="text-confirm" v-if="HeroLock">
                You are locked hero #{{ HeroLock.tokenId }}
                with
                {{
                    detail && detail.detailPromo
                        ? detail.detailPromo.timeBlockNFT
                        : ''
                }}
                months successfully?
            </div>
            <div class="hero hero-receiver mx-auto" v-if="HeroLock">
                <div class="left-hero mx-auto">
                    <div class="top-hero">
                        <div class="id-hero" ref="idHero">
                            #{{ HeroLock.tokenId }}
                        </div>
                        <div class="hero-img">
                            <div class="avatar-hero">
                                <img
                                    v-if="HeroLock.name"
                                    :src="`https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/${HeroLock.name.toLowerCase()}-${HeroLock.skin.toLowerCase()}.png`"
                                    alt=""
                                />
                            </div>
                            <div class="right-hero">
                                <div class="stat-hero">
                                    <div class="hero-level">
                                        {{
                                            10 > HeroLock.level
                                                ? '0' +
                                                  HeroLock.level.toString()
                                                : HeroLock.level
                                        }}
                                    </div>
                                    <img
                                        :key="`inventory-${HeroLock.id}`"
                                        :src="
                                            require(`@/assets/images/mockup/inventory/${HeroLock.ratity.toLowerCase()}.png`)
                                        "
                                        alt=""
                                    />
                                    <img
                                        :key="`inventory-2-${HeroLock.id}`"
                                        :src="
                                            require(`@/assets/images/mockup/inventory/${HeroLock.skin.toLowerCase()}.png?v=1`)
                                        "
                                        alt=""
                                    />
                                    <img
                                        :key="`inventory-3-${HeroLock.id}`"
                                        :src="
                                            require(`@/assets/images/mockup/inventory/${HeroLock.type.toLowerCase()}.png?v=1`)
                                        "
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-hero">
                        <div class="hero-name">
                            the
                            <span>
                                {{ HeroLock.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="form-group d-flex justify-content-center align-items-center mt-2"
            >
                <b-button
                    class="mx-auto my-2 btn-lock"
                    variant="primary"
                    @click="$bvModal.hide('lockSuccess')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            :id="`modal-info-lock`"
            title="Information Hero Locked in Promotion "
            :centered="true"
            footer-class="d-none"
        >
            <HeroShow v-if="heroLockPick" :hero="heroLockPick"></HeroShow>
            <b-button
                variant="success"
                class="d-block btn-confirm-p mx-auto mb-3 rounded-2"
                @click="$bvModal.hide(`modal-info-lock`)"
            >
                Confirm
            </b-button>
        </b-modal>
    </b-container>
</template>

<style lang="scss">
#lockSuccess,
#heroLockConfirm {
    .VueCarousel-navigation-next {
        right: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
    }
    .VueCarousel-navigation-prev {
        left: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
    }
    .VueCarousel-inner {
        visibility: visible !important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    .hero-receiver {
        flex: 1;
        min-width: 200px;
        max-width: 225px;
        width: 100%;
        margin: 0 15px 5px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 1 1 auto;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        border: 1px solid #00ffff;
                        max-width: calc(100% - 50px);
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .right-hero {
                        flex: 0 0 50px;
                        max-width: 50px;
                        .stat-hero {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            .hero-level {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ffff;
                                border: 1px solid #00ffff;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                            }
                            img {
                                width: 35px;
                                height: auto;
                                max-height: 35px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }
                .hero-exp {
                    position: relative;
                    width: 100%;
                    border: 1px solid #00ffff;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px auto 15px;
                    background: rgba(0, 255, 255, 0.2);
                    .text {
                        position: relative;
                        z-index: 10;
                        font-weight: 500;
                        font-size: 12px;
                        font-style: italic;
                        span {
                            font-weight: 700;
                            font-family: 'Helvetica';
                            font-size: 15px;
                        }
                    }
                    .current {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
                .hero-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        width: auto;
                        padding: 5px;
                        min-width: 100px;
                        height: 40px;
                        border-radius: 5px;
                        border: 0;
                        color: #ffff;
                        &.sell {
                            background: {
                                image: url('~@/assets/images/mockup/account/sell.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.transfer {
                            background: {
                                image: url('~@/assets/images/mockup/account/transfer.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.price {
                            background: {
                                image: url('~@/assets/images/mockup/account/price.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.cancel {
                            background: {
                                image: url('~@/assets/images/mockup/account/cancel.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .text-confirm {
        font-size: 22px;
        @media (max-width: 767px) {
            font-size: 13px;
        }
    }
    .modal-title {
        font-size: 1.5em;
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }
    .btn-lock {
        margin: 0 auto;
        width: 100%;
        max-width: 200px;
        display: flex;
        justify-content: center;
        height: 40px;
        align-items: center;
        font-size: 18px;
        font-family: 'HHBI';
        color: #00ffff;
        background: #00172a;
        border: 1px solid #00ffff;
        border-radius: 10px;
        padding: 5px;
        &:hover {
            background: #00ffff;
            color: #00172a;
        }
    }
}
#heroLockConfirm___BV_modal_outer_ {
    z-index: unset !important;
}
.detail-promotion {
    .box-hr {
        position: relative;
        z-index: 10;
    }
    .back-to-shop {
        margin: 0px 0 20px;
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: flex-start;
        .icons {
            img {
                width: 40px;
                height: auto;
            }
        }
        .text {
            font-family: 'HHBI';
            color: #00ffff;
            font-size: 27px;
            margin-left: 15px;
        }
        @media (max-width: 575px) {
            .text {
                font-size: 20px;
            }
            margin-bottom: 30px;
        }
    }
    .page-name {
        position: relative;
        display: flex;
        justify-content: center;
        .before {
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            font-family: 'HHBI';

            line-height: 120%;
            text-transform: uppercase;

            -webkit-text-stroke-color: #4effff;
            opacity: 0.6;
            font-size: clamp(30px, 5.5vw, 50px);
        }
        .after {
            font-family: 'HHBI';
            font-size: clamp(17px, 3vw, 27px);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
        @media (max-width: 575px) {
            .before {
                font-size: clamp(30px, 5.5vw, 50px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(17px, 3vw, 27px);
            }
        }
        @media (max-width: 424px) {
            .before {
                font-size: clamp(30px, 5.5vw, 50px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(17px, 3vw, 27px);
            }
        }
        @media (max-width: 374px) {
            .before {
                font-size: clamp(30px, 5.5vw, 50px);
                opacity: 0.3;
            }
            .after {
                bottom: -10px;
                font-size: clamp(17px, 3vw, 27px);
            }
        }
    }
    .sub-text {
        color: #4effff;
        font-size: 25px;
        max-width: 991px;
        line-height: 120%;
        margin: auto;
        text-align: center;
        font-family: 'HHBI';
    }
    .row-detail-lock {
        margin-top: 35px;
    }
    .hero-lock-detail {
        width: 100%;
        max-width: 576px;
        border: 1px solid #00ffff;
        border-radius: 10px;
        background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
        padding: 10px 20px;
        &-image {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: 1px solid #fff;
            margin: 10px auto 15px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        &-text {
            p {
                margin-bottom: 5px !important;
            }
            &-title {
                padding-left: 10px;
                color: #ffff;
                font-size: 17px;
            }
            &-key {
                color: #00ffff;
                font-size: 22px;
                margin: 15px 0 3px;
                font-family: 'HHBI';
            }
            &-description {
                padding-left: 10px;
                color: #ffff;
                font-size: 15px;
            }
            &-requirement {
                padding-left: 10px;
                color: #ffff;
                font-size: 15px;
            }
            &-reward {
                padding-left: 10px;
                color: #ffff;
                font-size: 15px;
            }
        }
        &-action {
            margin: auto;
            text-align: center;
            padding: 10px 0;
            margin-top: 15px;
            .btn-join {
                margin: 0 auto;
                width: 100%;
                max-width: 200px;
                font-size: 18px;
                font-family: 'HHBI';
                color: #00ffff;
                background: #00172a;
                border: 1px solid #00ffff;
                border-radius: 10px;
                padding: 10px;
                &:hover {
                    background: #00ffff;
                    color: #00172a;
                }
            }
        }
    }
    .is-join-promotion {
        width: 100%;
        border: 1px solid #00ffff;
        border-radius: 10px;
        background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
        padding: 15px 10px;
        color: #00ffff;
        margin-top: 10px;
        color: #4effff;
        font-size: 20px;
        line-height: 120%;
        margin: 20px auto;
        text-align: center;
        font-family: 'HHBI';
    }
    .warning-locked {
        width: 100%;
        border: 1px solid #00ffff;
        border-radius: 10px;
        background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
        padding: 15px 10px;
        color: #ffc107;
        margin-top: 10px;
    }
    .list-of-locked {
        background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
        width: 100%;
        border: 1px solid #00ffff;
        padding: 15px 10px;
        margin-top: 50px;
        min-height: 200px;

        border-radius: 10px;
        position: relative;
        .title-locked {
            position: absolute;
            top: -20px;
            height: 40px;
            padding: 0 10px;
            left: 15px;
            transform: skewX(-20deg);
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            border: 1px solid #00ffff;
            display: flex;
            align-items: center;
            border-radius: 5px;
            justify-content: center;
            .text {
                transform: skewX(20deg);
                color: #00ffff;
                font-family: 'HHBI';
                text-transform: capitalize;
                font-size: 20px;
            }
        }
        .title-locked-2 {
            position: absolute;
            top: -20px;
            height: 40px;
            padding: 0 10px;
            right: 15px;
            transform: skewX(-20deg);
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            border: 1px solid #00ffff;
            display: flex;
            align-items: center;
            border-radius: 5px;
            justify-content: center;
            .text {
                transform: skewX(20deg);
                color: #00ffff;
                font-family: 'HHBI';
                text-transform: capitalize;
                font-size: 20px;
            }
        }
        .list-hero-locked {
            padding: 20px 10px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .hero-l {
                margin: 10px 15px;
                width: 80px;
                .id {
                    color: #00ffff;
                    font-family: 'HHBI';
                    text-transform: capitalize;
                    font-size: 14px;
                }

                img {
                    width: 100%;
                    box-shadow: 2px 2px 5px -1px #00ffff4d;
                    cursor: pointer;
                }
            }
        }
        @media (max-width: 575px) {
            margin-top: 100px;
            .title-locked {
                top: -80px;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 250px;
            }
            .title-locked-2 {
                right: auto;
                top: -15px;
                left: 15px;
            }
        }
    }

    .container-lock {
        width: 100%;
        border: 1px solid #00ffff;
        border-radius: 10px;
        background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
        padding: 15px 10px;
        position: relative;
        .title-locked-2 {
            position: absolute;
            top: -20px;
            height: 40px;
            padding: 0 10px;
            right: 15px;
            transform: skewX(-20deg);
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            border: 1px solid #00ffff;
            display: flex;
            align-items: center;
            border-radius: 5px;
            justify-content: center;
            .text {
                transform: skewX(20deg);
                color: #00ffff;
                font-family: 'HHBI';
                text-transform: capitalize;
                font-size: 20px;
            }
        }
        .hero-lock-list {
            width: 100%;
            justify-content: space-around;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            &-item {
                // border: 1px solid #fff;
                width: 100%;
                max-width: 225px;
                min-height: 270px;
                margin: 0 5px 10px 5px;
                position: relative;
                min-width: 200px;
                &-background {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                .button-add {
                    position: absolute;
                    bottom: 5px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    button {
                        height: 30px;
                        padding: 0;
                        width: 120px;
                        background: #00ffff;
                        color: #00172a;
                        font-size: 17px;
                        font-weight: bold;
                        font-family: 'HHBI';
                        border: 0;
                        box-shadow: 2px 2px 2px 3px
                            rgba($color: #000000, $alpha: 0.5);
                    }
                }
                .button-remove {
                    position: absolute;
                    display: none;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    z-index: 20;
                    text-align: center;
                    margin: auto;
                    height: 50px;
                    button {
                        height: 30px;
                        padding: 0;
                        width: 120px;
                        background: #df0101;
                        color: #00172a;
                        font-size: 17px;
                        font-weight: bold;
                        font-family: 'HHBI';
                        border: 0;
                        box-shadow: 2px 2px 2px 3px
                            rgba($color: #000000, $alpha: 0.5);
                    }
                }
                &:hover {
                    .button-remove {
                        display: flex;
                        justify-self: center;
                        align-items: center;
                        width: max-content;
                    }
                }
            }
        }
        .action-locked {
            margin-top: 12px;
            text-align: center;
            position: relative;
            z-index: 10;
            .btn-lock {
                margin: 0 auto;
                width: 100%;
                max-width: 200px;
                font-size: 18px;
                font-family: 'HHBI';
                color: #00ffff;
                background: #00172a;
                border: 1px solid #00ffff;
                border-radius: 10px;
                padding: 5px 10px;
                margin-bottom: 10px;
                &:hover {
                    background: #00ffff;
                    color: #00172a;
                }
            }
        }
        .header-step {
            text-align: center;
            font-size: 18px;
            font-family: 'HHBI';
            color: #00ffff;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .step-item {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .step-item-number {
                    font-size: 18px;
                    width: 40px;
                    height: 40px;
                    font-family: 'HHBI';
                    color: #00ffff;
                    background: #00172a;
                    border: 1px solid #00ffff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                    z-index: 10;
                    margin: 5px;
                    &.done {
                        background: #04a31a;
                        color: #fff;
                        border: 1px solid #fff;
                    }
                    &.active,
                    &:hover {
                        background: #00ffff;
                        color: #00172a;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    &.end {
                        border: 0;
                        &.active,
                        &:hover {
                            background: #fff;
                            color: #00172a;
                        }
                    }
                }

                &:nth-child(2n + 2) {
                    position: relative;
                    flex: 1 1 auto;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        background: #00ffff;
                        height: 1px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        background: #00ffff;
                        width: 100%;
                        height: 1px;
                    }
                }
            }
        }
        .hero-lock-complete {
            color: #4effff;
            font-size: 20px;
            line-height: 120%;
            margin: 20px auto;
            text-align: center;
            font-family: 'HHBI';
        }
        .VueCarousel-navigation-next {
            right: 5% !important;
            font-size: 40px !important;
            color: #f78b00 !important;
            opacity: 1 !important;
            border: 0 !important;
            outline: none !important;
        }
        .VueCarousel-navigation-prev {
            left: 5% !important;
            font-size: 40px !important;
            color: #f78b00 !important;
            opacity: 1 !important;
            border: 0 !important;
            outline: none !important;
        }
        .VueCarousel-inner {
            visibility: visible !important;
            // flex-basis: 100%!important;
            // width: 100%!important;
        }
        .VueCarousel-dot {
            margin-top: 5px !important;
        }
        .VueCarousel-dot-container {
            margin-top: 5px !important;
        }
        .VueCarousel-dot--active {
            background-color: #00ffff !important;
            width: 10px;
            height: 10px;
        }
    }
}
</style>
