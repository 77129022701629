// when animating on canvas, it is best to use requestAnimationFrame instead of setTimeout or setInterval
// not supported in all browsers though and sometimes needs a prefix, so we need a shim

// now we will setup our basic variables for the demo
/* eslint-disable */
var // full screen dimensions
    canvas = null,
    ctx = null,
    cw = 10,
    ch = 10,
    // firework collection
    fireworks = [],
    // particle collection
    particles = [],
    // starting hue
    hue = 120,
    // when launching fireworks with a click, too many get launched at once without a limiter, one launch per 5 loop ticks
    limiterTotal = 5,
    limiterTick = 0,
    // this will time the auto launches of fireworks, one launch per 80 loop ticks
    timerTotal = 80,
    timerTick = 0,
    mousedown = false,
    // mouse x coordinate,
    mx,
    // mouse y coordinate
    my,
    timeOutEffect = null,
    myReq = null,
    confettiReq = null,
    isStop = 0;
// now we are going to setup our function placeholders for the entire demo

// get a random number within a range
function random(min, max) {
    return Math.random() * (max - min) + min;
}

// calculate the distance between two points
function calculateDistance(p1x, p1y, p2x, p2y) {
    var xDistance = p1x - p2x,
        yDistance = p1y - p2y;
    return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
}

// create firework
function Firework(sx, sy, tx, ty) {
    // actual coordinates
    this.x = sx;
    this.y = sy;
    // starting coordinates
    this.sx = sx;
    this.sy = sy;
    // target coordinates
    this.tx = tx;
    this.ty = ty;
    // distance from starting point to target
    this.distanceToTarget = calculateDistance(sx, sy, tx, ty);
    this.distanceTraveled = 0;
    // track the past coordinates of each firework to create a trail effect, increase the coordinate count to create more prominent trails
    this.coordinates = [];
    this.coordinateCount = 3;
    // populate initial coordinate collection with the current coordinates
    while (this.coordinateCount--) {
        this.coordinates.push([this.x, this.y]);
    }
    this.angle = Math.atan2(ty - sy, tx - sx);
    this.speed = 2;
    this.acceleration = 1.05;
    this.brightness = random(50, 70);
    // circle target indicator radius
    this.targetRadius = 50;
}

// update firework
Firework.prototype.update = function (index) {
    // remove last item in coordinates array
    this.coordinates.pop();
    // add current coordinates to the start of the array
    this.coordinates.unshift([this.x, this.y]);

    // cycle the circle target indicator radius
    if (this.targetRadius < 8) {
        this.targetRadius += 0.3;
    } else {
        this.targetRadius = 1;
    }

    // speed up the firework
    this.speed *= this.acceleration;

    // get the current velocities based on angle and speed
    var vx = Math.cos(this.angle) * this.speed,
        vy = Math.sin(this.angle) * this.speed;
    // how far will the firework have traveled with velocities applied?
    this.distanceTraveled = calculateDistance(
        this.sx,
        this.sy,
        this.x + vx,
        this.y + vy,
    );

    // if the distance traveled, including velocities, is greater than the initial distance to the target, then the target has been reached
    if (this.distanceTraveled >= this.distanceToTarget) {
        createParticles(this.tx, this.ty);
        // remove the firework, use the index passed into the update function to determine which to remove
        fireworks.splice(index, 1);
    } else {
        // target not reached, keep traveling
        this.x += vx;
        this.y += vy;
    }
};

// draw firework
Firework.prototype.draw = function () {
    ctx.beginPath();
    // move to the last tracked coordinate in the set, then draw a line to the current x and y
    ctx.moveTo(
        this.coordinates[this.coordinates.length - 1][0],
        this.coordinates[this.coordinates.length - 1][1],
    );
    ctx.lineTo(this.x, this.y);
    ctx.strokeStyle = 'hsl(' + hue + ', 100%, ' + this.brightness + '%)';
    ctx.stroke();

    ctx.beginPath();
    // draw the target for this firework with a pulsing circle
    ctx.arc(this.tx, this.ty, this.targetRadius, 0, Math.PI * 2);
    ctx.stroke();
};

// create particle
function Particle(x, y) {
    this.x = x;
    this.y = y;
    // track the past coordinates of each particle to create a trail effect, increase the coordinate count to create more prominent trails
    this.coordinates = [];
    this.coordinateCount = 5;
    while (this.coordinateCount--) {
        this.coordinates.push([this.x, this.y]);
    }
    // set a random angle in all possible directions, in radians
    this.angle = random(0, Math.PI * 2);
    this.speed = random(1, 10);
    // friction will slow the particle down
    this.friction = 0.95;
    // gravity will be applied and pull the particle down
    this.gravity = 1;
    // set the hue to a random number +-20 of the overall hue variable
    this.hue = random(hue - 20, hue + 20);
    this.brightness = random(50, 80);
    this.alpha = 1;
    // set how fast the particle fades out
    this.decay = random(0.015, 0.03);
}

// update particle
Particle.prototype.update = function (index) {
    // remove last item in coordinates array
    this.coordinates.pop();
    // add current coordinates to the start of the array
    this.coordinates.unshift([this.x, this.y]);
    // slow down the particle
    this.speed *= this.friction;
    // apply velocity
    this.x += Math.cos(this.angle) * this.speed;
    this.y += Math.sin(this.angle) * this.speed + this.gravity;
    // fade out the particle
    this.alpha -= this.decay;

    // remove the particle once the alpha is low enough, based on the passed in index
    if (this.alpha <= this.decay) {
        particles.splice(index, 1);
    }
};

// draw particle
Particle.prototype.draw = function () {
    ctx.lineWidth = 5;
    ctx.beginPath();
    // move to the last tracked coordinates in the set, then draw a line to the current x and y
    ctx.moveTo(
        this.coordinates[this.coordinates.length - 1][0],
        this.coordinates[this.coordinates.length - 1][1],
    );
    ctx.lineTo(this.x, this.y);
    ctx.strokeStyle =
        'hsla(' +
        this.hue +
        ', 100%, ' +
        this.brightness +
        '%, ' +
        this.alpha +
        ')';
    ctx.stroke();
};

// create particle group/explosion
function createParticles(x, y) {
    // increase the particle count for a bigger explosion, beware of the canvas performance hit with the increased particles though
    var particleCount = 30;
    while (particleCount--) {
        particles.push(new Particle(x, y));
    }
    // Just for fun :)
    speak('hm', -100, 100);
}

// main demo loop
function loop() {
    if (isStop === 1) {
        cancelAnimationFrame(loop);
        fireworks = [];
        particles = [];
        return;
    }

    // this function will run endlessly with requestAnimationFrame
    requestAnimationFrame(loop);

    // increase the hue to get different colored fireworks over time
    hue += 0.5;

    // normally, clearRect() would be used to clear the canvas
    // we want to create a trailing effect though
    // setting the composite operation to destination-out will allow us to clear the canvas at a specific opacity, rather than wiping it entirely
    ctx.globalCompositeOperation = 'destination-out';
    // decrease the alpha property to create more prominent trails
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.fillRect(0, 0, cw, ch);
    // change the composite operation back to our main mode
    // lighter creates bright highlight points as the fireworks and particles overlap each other
    ctx.globalCompositeOperation = 'lighter';

    // loop over each firework, draw it, update it

    var i = fireworks.length;
    while (i--) {
        fireworks[i].draw();
        fireworks[i].update(i);
    }

    // loop over each particle, draw it, update it
    var i = particles.length;
    while (i--) {
        particles[i].draw();
        particles[i].update(i);
    }

    // launch fireworks automatically to random coordinates, when the mouse isn't down
    if (timerTick >= timerTotal) {
        if (!mousedown) {
            // start the firework at the bottom middle of the screen, then set the random target coordinates, the random y coordinates will be set within the range of the top half of the screen
            fireworks.push(
                new Firework(cw / 2, ch, random(0, cw), random(0, ch / 2)),
            );
            timerTick = 0;
        }
    } else {
        timerTick++;
    }

    // limit the rate at which fireworks get launched when mouse is down
    if (limiterTick >= limiterTotal) {
        if (mousedown) {
            // start the firework at the bottom middle of the screen, then set the current mouse coordinates as the target
            fireworks.push(new Firework(cw / 2, ch, mx, my));
            limiterTick = 0;
        }
    } else {
        limiterTick++;
    }
}

class Progress {
    constructor(param = {}) {
        this.timestamp = null;
        this.duration = param.duration || Progress.CONST.DURATION;
        this.progress = 0;
        this.delta = 0;
        this.progress = 0;
        this.isLoop = !!param.isLoop;

        this.reset();
    }

    static get CONST() {
        return {
            DURATION: 1000,
        };
    }

    reset() {
        this.timestamp = null;
    }

    start(now) {
        this.timestamp = now;
    }

    tick(now) {
        if (this.timestamp) {
            this.delta = now - this.timestamp;
            this.progress = Math.min(this.delta / this.duration, 1);

            if (this.progress >= 1 && this.isLoop) {
                this.start(now);
            }

            return this.progress;
        } else {
            return 0;
        }
    }
}

class Confetti {
    constructor(param) {
        this.parent = param.elm || document.body;
        this.canvas = document.createElement('canvas');
        this.canvas.id = param.id;
        this.ctx = this.canvas.getContext('2d');
        this.width = param.width || this.parent.offsetWidth;
        this.height = param.height || this.parent.offsetHeight;
        this.length = param.length || Confetti.CONST.PAPER_LENGTH;
        this.yRange = param.yRange || this.height * 2;
        this.progress = new Progress({
            duration: param.duration,
            isLoop: true,
        });

        this.rotationRange =
            typeof param.rotationLength === 'number' ? param.rotationRange : 10;
        this.speedRange =
            typeof param.speedRange === 'number' ? param.speedRange : 10;
        this.sprites = [];

        this.canvas.style.cssText = [
            'display: block',
            'position: absolute',
            'top: 0',
            'left: 0',
            'pointer-events: none',
            'z-index: 1040',
        ].join(';');

        this.render = this.render.bind(this);

        this.build();

        this.parent.appendChild(this.canvas);
        this.progress.start(performance.now());

        requestAnimationFrame(this.render);
    }

    static get CONST() {
        return {
            SPRITE_WIDTH: 9,
            SPRITE_HEIGHT: 16,
            PAPER_LENGTH: 100,
            DURATION: 8000,
            ROTATION_RATE: 50,
            COLORS: [
                '#EF5350',
                '#EC407A',
                '#AB47BC',
                '#7E57C2',
                '#5C6BC0',
                '#42A5F5',
                '#29B6F6',
                '#26C6DA',
                '#26A69A',
                '#66BB6A',
                '#9CCC65',
                '#D4E157',
                '#FFEE58',
                '#FFCA28',
                '#FFA726',
                '#FF7043',
                '#8D6E63',
                '#BDBDBD',
                '#78909C',
            ],
        };
    }

    build() {
        for (let i = 0; i < this.length; ++i) {
            let canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');
            canvas.width = Confetti.CONST.SPRITE_WIDTH;
            canvas.height = Confetti.CONST.SPRITE_HEIGHT;

            canvas.position = {
                initX: Math.random() * this.width,
                initY: -canvas.height - Math.random() * this.yRange,
            };

            canvas.rotation =
                this.rotationRange / 2 - Math.random() * this.rotationRange;
            canvas.speed =
                this.speedRange / 2 + Math.random() * (this.speedRange / 2);

            ctx.save();
            ctx.fillStyle =
                Confetti.CONST.COLORS[
                    (Math.random() * Confetti.CONST.COLORS.length) | 0
                ];
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.restore();

            this.sprites.push(canvas);
        }
    }

    render(now) {
        let progress = this.progress.tick(now);

        this.canvas.width = this.width;
        this.canvas.height = this.height;

        for (let i = 0; i < this.length; ++i) {
            this.ctx.save();
            this.ctx.translate(
                this.sprites[i].position.initX +
                    this.sprites[i].rotation *
                        Confetti.CONST.ROTATION_RATE *
                        progress,
                this.sprites[i].position.initY +
                    progress * (this.height + this.yRange),
            );

            this.ctx.rotate(this.sprites[i].rotation);
            this.ctx.drawImage(
                this.sprites[i],
                (-Confetti.CONST.SPRITE_WIDTH *
                    Math.abs(
                        Math.sin(
                            progress * Math.PI * 2 * this.sprites[i].speed,
                        ),
                    )) /
                    2,
                -Confetti.CONST.SPRITE_HEIGHT / 2,
                Confetti.CONST.SPRITE_WIDTH *
                    Math.abs(
                        Math.sin(
                            progress * Math.PI * 2 * this.sprites[i].speed,
                        ),
                    ),
                Confetti.CONST.SPRITE_HEIGHT,
            );

            this.ctx.restore();
        }

        confettiReq = requestAnimationFrame(this.render);
    }
}

export function StartEffect() {
    canvas = null;
    ctx = null;
    cw = 10;
    ch = 10;
    isStop = 0;
    // firework collection
    fireworks = [];
    // particle collection
    particles = [];
    // starting hue
    hue = 120;
    // when launching fireworks with a click, too many get launched at once without a limiter, one launch per 5 loop ticks
    limiterTotal = 5;
    limiterTick = 0;
    // this will time the auto launches of fireworks, one launch per 80 loop ticks
    timerTotal = 80;
    timerTick = 0;
    mousedown = false;
    // mouse x coordinate,
    mx = null;
    // mouse y coordinate
    my = null;
    timeOutEffect = null;
    myReq = null;
    confettiReq = null;
    const cvContainer = document.createElement('fireworks');
    if (cvContainer) {
        cvContainer.remove();
    }
    canvas = document.createElement('canvas');
    canvas.width = canvas.height = 10;
    canvas.id = 'fireworks';
    document.body.appendChild(canvas);
    ctx = canvas.getContext('2d');
    canvas.style.position = 'fixed';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.right = 0;
    canvas.style.bottom = 0;
    canvas.style.zIndex = 1041;

    cw = window.innerWidth;
    ch = window.innerHeight;

    canvas.width = cw;
    canvas.height = ch;

    myReq = window.requestAnimFrame = (function () {
        return (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            }
        );
    })();
    loop(false);
    const DURATION = 8000,
        LENGTH = 120;

    new Confetti({
        width: window.innerWidth,
        height: window.innerHeight,
        length: LENGTH,
        duration: DURATION,
        id: 'Confetti-1',
    });

    timeOutEffect = setTimeout(() => {
        new Confetti({
            width: window.innerWidth,
            height: window.innerHeight,
            length: LENGTH,
            duration: DURATION,
            id: 'Confetti-2',
        });
    }, DURATION / 2);
}

export function StopEffect() {
    isStop = 1;
    const Confetti1 = document.getElementById('Confetti-1');
    const Confetti2 = document.getElementById('Confetti-2');
    if (Confetti1) {
        Confetti1.remove();
    }
    if (Confetti2) {
        Confetti2.remove();
    }
    canvas.remove();
    clearTimeout(timeOutEffect);
    cancelAnimationFrame(confettiReq);
    cancelAnimationFrame(myReq);
}
function speak(sentence, pitch, rate) {
    const utterance = new SpeechSynthesisUtterance(sentence);
    utterance.rate = rate;
    utterance.pitch = pitch;
    utterance.volume = 0.5;
    utterance.lang = 'en-US';

    window.speechSynthesis.speak(utterance);
}
