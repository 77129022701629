<script>
import { mapGetters, mapMutations } from 'vuex';
import { MetaMask } from '@/components/auth';
import { filter } from 'lodash';

const axios = require('@/store/axios.config').default;
const BigNumber = require('bignumber.js');

export default {
    props: {
        coin: {
            type: String,
            default: null,
        },
    },
    components: {
        MetaMask,
    },
    data() {
        return {
            depositAmount: 0,
            submitted: false,
            withdrawAmount: 0,
            transferHBG: {
                to: '',
                amount: 0,
            },
            transfer: {
                type: 'Heronium',
                to: null,
                amount: 1,
            },
            swap: {
                from: 'Heronium',
                to: 'HBG',
                amount: 100,
                sign: null,
            },

            typeTransfer: [
                {
                    text: 'Heronium',
                    value: 'Heronium',
                },
                {
                    text: 'Oxygen',
                    value: 'Oxygen',
                },
                {
                    text: 'Electric',
                    value: 'Electric',
                },
                {
                    text: 'HeroStone',
                    value: 'HeroStone',
                },
            ],

            typeSwap: [
                // {
                //     text: 'HBG',
                //     value: 'HBG',
                // },
                {
                    text: 'Heronium',
                    value: 'Heronium',
                },
                // {
                //     text: 'Oxygen',
                //     value: 'Oxygen',
                // },
                // {
                //     text: 'Electric',
                //     value: 'Electric',
                // },
                // {
                //     text: 'HeroStone',
                //     value: 'HeroStone',
                // },
            ],
            transaction: null,
            infoSwap: {},
            predictSwap: {
                amount: 0,
                amountHBG: 0,
                amountUSDT: 0,
                rateCurrent: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            BalanceSystem: 'info/Balance',
            isLogin: 'auth/loggedIn',
            Balance: 'wallet/Balance',
            UserInfo: 'info/UserInfo',
            Fee: 'core/Fee',
        }),
        listTo() {
            return filter(this.typeSwap, (data) => {
                return data.value !== this.swap.from;
            });
        },
        getValueReal() {
            if (!this.infoSwap)
                return {
                    value: 0,
                    feeToken: 0,
                };
            const toToken =
                this.swap.amount * this.infoSwap[`price${this.swap.to}`];
            const feeToken = toToken * this.infoSwap[this.swap.to];

            const value = toToken - feeToken;
            return {
                value,
                feeToken,
            };
        },
    },
    watch: {
        'swap.from': {
            handler(newVal) {
                // if (newVal !== 'HBG') {
                //     this.onGetInfoSwap(newVal);
                // }
                if (newVal === 'HBG') {
                    this.swap.to = 'Heronium';
                }
                if (newVal === 'Heronium') {
                    this.swap.to = 'HBG';
                }
                this.onPredictSwap();
            },
        },
        'swap.to': {
            handler() {
                this.onPredictSwap();
            },
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        onShowTransfer(type) {
            this.transfer.type = type;
            this.$bvModal.show('transfer');
        },

        resetDeposit() {
            this.depositAmount = 0;
        },
        resetWithdraw() {
            this.withdrawAmount = 0;
        },
        resetTransfer() {
            this.transfer = {
                type: 'Heronium',
                to: null,
                amount: 1,
            };
        },
        resetTransferHBG() {
            this.transferHBG = {
                to: '',
                amount: 0,
            };
        },
        resetSwap() {
            this.swap = {
                from: 'Heronium',
                to: 'HBG',
                amount: 100,
                sign: null,
            };
        },

        ComingSoonT() {
            this.$toastr.s('Function Coming Soon', 'Coming Soon');
        },
        onShowDeposit() {
            this.$bvModal.show('deposit');
            this.$bvModal.hide('SuggestDeposit');
        },

        async onGetInfoSwap(currency) {
            const data = await axios.get(`/api/swap-ingame/config/${currency}`);
            if (data) {
                this.swap.amount = data.minSwap;
                this.infoSwap = data;
                this.infoSwap.HBG = data.fee2HBG;
                this.infoSwap[this.swap.from] = data.fee2Energy;
                this.infoSwap.priceHBG = 1 / data.rate;
                this.infoSwap[`price${this.swap.from}`] = data.rate;
            }
        },
        async onPredictSwap() {
            const predict = await axios.post(`/api/swap-ingame/predict`, {
                from: this.swap.from,
                to: this.swap.to,
                amount: this.swap.amount,
            });
            if (predict) this.predictSwap = predict;
        },
        async onShowModalSwap() {
            await this.onPredictSwap();
            this.$bvModal.show('swap');
        },

        async onSwap() {
            this.onLoad();
            if (this.transfer.amount < 1) {
                this.$toastr.w('Please enter amount greater than 0', 'Error');
                this.outLoad();
                return;
            }

            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Swap'],
            });
            if (!txData) {
                this.$toastr.e('Please Confirm Transfer', 'Error');
                return;
            }
            this.swap = {
                ...this.swap,
                sign: txData,
            };
            // this.submitted = false;
            await axios.post(`api/swap-ingame/swap`, this.swap);

            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('swap');

            this.outLoad();
        },
        async onWithdraw() {
            this.onLoad();
            if (this.transfer.amount < 1) {
                this.$toastr.w('Please enter amount greater than 0', 'Error');
                this.outLoad();
                return;
            }
            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Withdraw'],
            });
            if (!txData) {
                this.$toastr.e('Please Confirm Withdraw', 'Error');
                return;
            }
            // this.submitted = false;
            await axios.post('api/me/withdraw', {
                amount: this.withdrawAmount,
                sign: txData,
            });

            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('withdraw');

            this.outLoad();
        },
        async onTransfer() {
            this.onLoad();
            if (this.transfer.amount < 1) {
                this.$toastr.w('Please enter amount greater than 0', 'Error');
                this.outLoad();
                return;
            }

            if (
                this.transfer.to.toLowerCase() ===
                this.UserInfo.address.toLowerCase()
            ) {
                this.$toastr.w('You can not transfer to yourself', 'Error');
                this.outLoad();
                return;
            }
            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Transfer'],
            });
            if (!txData) {
                this.$toastr.e('Please Confirm Transfer', 'Error');
                return;
            }
            this.transfer = {
                ...this.transfer,
                sign: txData,
            };
            // this.submitted = false;
            await axios.post('api/market/order/transfer/energy', this.transfer);

            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('transfer');

            this.outLoad();
        },
        async onTransferHBG() {
            this.onLoad();
            if (this.transferHBG.amount < 1) {
                this.$toastr.w('Please enter amount greater than 0', 'Error');
                this.outLoad();
                return;
            }
            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Transfer'],
            });
            this.transferHBG = {
                ...this.transferHBG,
                sign: txData,
            };
            // this.submitted = false;
            await axios.post(
                '/api/market/order/transfer/hbg',
                this.transferHBG,
            );

            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('transferHBG');

            this.outLoad();
        },
        async sendTransaction(transaction) {
            if (transaction) {
                this.transaction = transaction;
            }
            this.onLoad();
            const data = await axios.post('api/me/deposit', {
                transactionHash: transaction,
            });
            console.log(data);
            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('deposit');
            this.outLoad();
            this.outLoad();
        },
        async DepositHBG() {
            this.onLoad();
            const addressDeposit = await axios.get(
                'api/system/get-system-wallet',
            );
            if (!addressDeposit) {
                this.$toastr.e(
                    'Something went wrong, Please reload a page',
                    'Error',
                );
                this.outLoad();
                return;
            }
            this.submitted = true;
            if (this.depositAmount < 1) {
                this.$toastr.e(
                    'Please enter a valid amount, min deposit is 1 HBG',
                    'Deposit Failed',
                );
                this.outLoad();
                return;
            }
            const { web3 } = this.$store.state.contract;
            const { hbg } = this.$store.state.contract.bep_20;
            const { addr } = this.$store.state.wallet;
            const deposit = addressDeposit;
            const { depositAmount } = this.$data;
            const amount = web3.provider.utils.toBN(
                BigNumber(depositAmount * hbg.dec),
            );
            const txObj = hbg.cont.methods
                .transfer(deposit, amount)
                .encodeABI();
            let gas;
            try {
                // Calculate gas
                gas = await hbg.cont.methods
                    .transfer(deposit, amount)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: 2500000,
                    });
                // return;
            } catch (error) {
                this.outLoad();
                this.$toastr.e(error, 'Oops!');
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;
            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hbg.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                            type: '1',
                        },
                    ],
                });
                // transaction result
                let submitted = true;
                let check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        check = undefined;
                        if (txData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction(txData.transactionHash);
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.$toastr.e(error.message, 'Oops!');
            }
        },
    },
    mounted() {
        this.onGetInfoSwap(this.swap.from);
    },
};
</script>
<template>
    <div class="container-wallet">
        <b-row v-if="!coin">
            <b-col cols="12" xl="6" lg="12" md="6">
                <div class="box-wallet main-box">
                    <div class="box-wallet-header border-b">
                        <div class="box-wallet-header-image">
                            <img src="~@/assets/images/logo/HBG.png" alt="" />
                        </div>
                        <div class="box-wallet-header-title">
                            Hero Book Game Token (HBG)
                        </div>
                    </div>
                    <div class="box-wallet-body">
                        <div class="box-wallet-body-image"></div>
                        <div class="box-wallet-body-amount">
                            {{ TruncateToDecimals2(BalanceSystem.HBG, '', 4) }}
                        </div>
                        <div class="box-wallet-body-symbol">HBG</div>
                    </div>
                    <MetaMask class="right" :isBuy="true" v-if="!isLogin" />
                    <div class="box-wallet-footer" v-else>
                        <b-button
                            class="box-wallet-footer-button"
                            @click="$bvModal.show('deposit')"
                        >
                            <div class="box-wallet-footer-button-text">
                                Deposit
                            </div>
                        </b-button>
                        <b-button
                            class="box-wallet-footer-button"
                            @click="$bvModal.show('withdraw')"
                        >
                            <div class="box-wallet-footer-button-text">
                                Withdraw
                            </div>
                        </b-button>
                        <b-button
                            class="box-wallet-footer-button"
                            @click="$bvModal.show('transferHBG')"
                        >
                            <div class="box-wallet-footer-button-text">
                                Transfer
                            </div>
                        </b-button>
                    </div>
                </div>
            </b-col>
            <b-col cols="12" xl="6" lg="12" md="6">
                <b-row>
                    <b-col cols="12">
                        <div class="box-wallet">
                            <div class="box-wallet-body">
                                <div class="box-wallet-body-image">
                                    <img
                                        src="~@/assets/images/icons/heronium.svg"
                                        alt=""
                                    />
                                </div>
                                <div class="box-wallet-body-amount">
                                    {{
                                        TruncateToDecimals2(
                                            BalanceSystem.Heronium,
                                            '',
                                            4,
                                        )
                                    }}
                                </div>
                                <div class="box-wallet-body-symbol">
                                    Heronium
                                </div>
                            </div>
                            <MetaMask
                                class="right"
                                :isBuy="true"
                                v-if="!isLogin"
                            />
                            <div class="box-wallet-footer" v-else>
                                <b-button
                                    class="box-wallet-footer-button"
                                    @click="onShowModalSwap"
                                >
                                    <div class="box-wallet-footer-button-text">
                                        Swap
                                    </div>
                                </b-button>
                                <b-button
                                    class="box-wallet-footer-button"
                                    @click="onShowTransfer('Heronium')"
                                >
                                    <div class="box-wallet-footer-button-text">
                                        Transfer
                                    </div>
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12">
                        <div class="box-wallet">
                            <div class="box-wallet-body">
                                <div class="box-wallet-body-image">
                                    <img
                                        src="~@/assets/images/icons/oxygen.svg"
                                        alt=""
                                    />
                                </div>
                                <div class="box-wallet-body-amount">
                                    {{
                                        TruncateToDecimals2(
                                            BalanceSystem.Oxygen,
                                            '',
                                            4,
                                        )
                                    }}
                                </div>
                                <div class="box-wallet-body-symbol">Oxygen</div>
                            </div>
                            <MetaMask
                                class="right"
                                :isBuy="true"
                                v-if="!isLogin"
                            />
                            <div class="box-wallet-footer" v-else>
                                <b-button
                                    v-if="false"
                                    class="box-wallet-footer-button"
                                    @click="$bvModal.show('swap')"
                                >
                                    <div class="box-wallet-footer-button-text">
                                        Swap
                                    </div>
                                </b-button>
                                <b-button
                                    class="box-wallet-footer-button"
                                    @click="onShowTransfer('Oxygen')"
                                >
                                    <div class="box-wallet-footer-button-text">
                                        Transfer
                                    </div>
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" xl="6" lg="12" md="6">
                <div class="box-wallet">
                    <div class="box-wallet-body">
                        <div class="box-wallet-body-image">
                            <img
                                src="~@/assets/images/icons/herostone.svg"
                                alt=""
                            />
                        </div>
                        <div class="box-wallet-body-amount">
                            {{
                                TruncateToDecimals2(
                                    BalanceSystem.HeroStone,
                                    '',
                                    4,
                                )
                            }}
                        </div>
                        <div class="box-wallet-body-symbol">Hero Stone</div>
                    </div>
                    <MetaMask class="right" :isBuy="true" v-if="!isLogin" />
                    <div class="box-wallet-footer" v-else>
                        <b-button
                            class="box-wallet-footer-button"
                            @click="onShowTransfer('HeroStone')"
                        >
                            <div class="box-wallet-footer-button-text">
                                Transfer
                            </div>
                        </b-button>
                    </div>
                </div>
            </b-col>
            <b-col cols="12" xl="6" lg="12" md="6">
                <div class="box-wallet">
                    <div class="box-wallet-body">
                        <div class="box-wallet-body-image">
                            <img
                                src="~@/assets/images/icons/electric.svg"
                                alt=""
                            />
                        </div>
                        <div class="box-wallet-body-amount">
                            {{
                                TruncateToDecimals2(
                                    BalanceSystem.Electric,
                                    '',
                                    4,
                                )
                            }}
                        </div>
                        <div class="box-wallet-body-symbol">Electric</div>
                    </div>
                    <MetaMask class="right" :isBuy="true" v-if="!isLogin" />
                    <div class="box-wallet-footer" v-else>
                        <b-button
                            class="box-wallet-footer-button"
                            @click="onShowTransfer('Electric')"
                        >
                            <div class="box-wallet-footer-button-text">
                                Transfer
                            </div>
                        </b-button>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12">
                <div
                    class="upgrade-header d-flex flex-wrap justify-content-center justify-content-md-start"
                >
                    <img
                        src="../../assets/images/logo/logo-heronium.svg"
                        alt=""
                        class="mb-4"
                    />
                    <div class="upgrage-header-content col-md-10 col-12">
                        <div class="d-flex flex-wrap align-items-center">
                            <h4 class="upgrade-title py-1 m-0">
                                {{
                                    TruncateToDecimals2(
                                        BalanceSystem.Heronium,
                                        '',
                                        4,
                                    )
                                }}
                            </h4>
                            <span class="upgrade-coin py-1">[ Heronium ]</span>
                        </div>
                        <MetaMask class="right" :isBuy="true" v-if="!isLogin" />
                        <div class="row mt-3" v-else>
                            <div
                                class="col-lg-3 col-md-4 col-sm-6 col-12 pl-3 py-2"
                            >
                                <button
                                    @click="$bvModal.show('swap')"
                                    class="upgrade-header-btn"
                                >
                                    <img
                                        src="../../assets/images/icons/icon-swap.svg"
                                        alt=""
                                    />
                                    <span>Swap</span>
                                </button>
                            </div>
                            <div
                                class="col-lg-3 col-md-4 col-sm-6 col-12 pl-3 py-2"
                            >
                                <button
                                    @click="onShowTransfer('Heronium')"
                                    class="upgrade-header-btn2"
                                >
                                    <img
                                        src="../../assets/images/icons/icon-trade.svg"
                                        alt=""
                                    />
                                    <span>Trade</span>
                                </button>
                            </div>
                            <div
                                class="col-lg-3 col-md-4 col-sm-6 col-12 pl-3 py-2"
                            >
                                <router-link to="/shop/In-game">
                                    <button class="upgrade-header-btn2">
                                        <img
                                            src="../../assets/images/icons/icon-buy.svg"
                                            alt=""
                                        />
                                        <span>Buy</span>
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <!-- list modal -->
        <b-modal
            id="withdraw"
            title="Withdraw HeroBook Game Token (HBG)"
            hide-footer
            @hidden="resetWithdraw"
        >
            <b-form @submit.prevent="onWithdraw()">
                <b-form-group>
                    <label for="inputAmount">Your Address Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            BalanceSystem.HBG,
                            '',
                            4,
                        )} HBG`"
                        readonly
                        class="read-form"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Withdraw Amount</label>
                    <b-form-input
                        min="0"
                        v-model="withdrawAmount"
                        type="number"
                        id="inputAmount"
                        placeholder="Enter Your Withdraw Deposit"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Actually Receive</label>
                    <b-form-input
                        class="pl-2"
                        min="0"
                        :value="`${TruncateToDecimals2(
                            withdrawAmount - withdrawAmount * Fee.claimHBG,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <div class="note">
                    <div class="title">NOTED:</div>
                    <div class="content">
                        <p
                            class="text-left text-warning mb-1 pl-3 font-weight-bold"
                            style="letter-spacing: 1px"
                        >
                            - If you can't received hbg to your wallet, please
                            wait a minutes for the bscscan network to confirm
                            the transaction to log into the system
                            <span> (maximum time 48h will received) </span>
                        </p>
                        <p class="text-left text-warning pl-3 font-weight-bold">
                            - If you have a question or more than 48 hours
                            please contact with support team
                            <router-link :to="{ name: 'Support' }">
                                <span> In Here </span>
                            </router-link>
                        </p>
                    </div>
                </div>
                <b-button
                    class="button-confirm"
                    type="submit"
                    :disabled="withdrawAmount > BalanceSystem.HBG"
                >
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
        <b-modal
            id="deposit"
            title="Deposit HeroBook Game Token (HBG)"
            hide-footer
            @hidden="resetDeposit"
        >
            <b-form @submit.prevent="DepositHBG()">
                <b-form-group>
                    <label for="inputAmount">Your Address Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            Balance.hbg,
                            '',
                            4,
                        )} HBG`"
                        readonly
                        class="read-form"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Deposit Amount</label>
                    <b-form-input
                        min="0"
                        v-model="depositAmount"
                        type="number"
                        id="inputAmount"
                        placeholder="Enter Your Amount Deposit"
                        class="read-form"
                    ></b-form-input>
                </b-form-group>
                <div class="note">
                    <div class="title">NOTED:</div>
                    <div class="content">
                        <p
                            class="text-left text-warning mb-1 pl-3 font-weight-bold"
                            style="letter-spacing: 1px"
                        >
                            - If you can't received hbg to the marketplace,
                            please wait a minutes for the bscscan network to
                            confirm the transaction to log into the system
                            <span> (maximum time 48h will received) </span>
                        </p>
                        <p class="text-left text-warning pl-3 font-weight-bold">
                            - If you have a question or more than 48 hours
                            please contact with support team
                            <router-link :to="{ name: 'Support' }">
                                <span> In Here </span>
                            </router-link>
                        </p>
                    </div>
                </div>
                <b-button class="button-confirm" type="submit">
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
        <b-modal
            id="transferHBG"
            title="Transfer HeroBook Game Token (HBG)"
            hide-footer
            @hidden="resetTransferHBG"
        >
            <b-form @submit.prevent="onTransferHBG()">
                <b-form-group>
                    <label for="inputAmount">Your Address Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            BalanceSystem.HBG,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group>
                    <label for="inputAmount">Address Receive</label>
                    <b-form-input
                        min="0"
                        v-model="transferHBG.to"
                        id="inputAmount"
                        placeholder="Enter  Address Receive"
                        class="read-form"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Amount Transfer</label>
                    <b-form-input
                        min="0"
                        v-model="transferHBG.amount"
                        type="number"
                        id="inputAmount"
                        class="read-form"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Actually Receive</label>
                    <b-form-input
                        class="pl-2"
                        min="0"
                        :value="`${TruncateToDecimals2(
                            transferHBG.amount -
                                transferHBG.amount * Fee.transferHBG,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-button
                    class="button-confirm"
                    type="submit"
                    :disabled="withdrawAmount > BalanceSystem.HBG"
                >
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
        <b-modal
            id="transfer"
            title="Transfer Energy"
            hide-footer
            @hidden="resetTransfer"
        >
            <b-form @submit.prevent="onTransfer()">
                <b-form-group>
                    <label for="">Energy</label>
                    <div class="form-sl">
                        <b-form-select
                            v-model="transfer.type"
                            :options="typeTransfer"
                            class="mx-auto"
                        ></b-form-select>
                        <img src="~@/assets/images/icons/drop.png" alt="" />
                    </div>
                </b-form-group>
                <b-form-group>
                    <label for="">Your Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            BalanceSystem[transfer.type],
                            '',
                            4,
                        )} ${transfer.type}`"
                        readonly
                        class="bg-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Transfer Amount</label>
                    <b-form-input
                        min="0"
                        v-model="transfer.amount"
                        type="number"
                        id="inputAmount"
                        placeholder="Enter Your Amount Transfer"
                        class="bg-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAddress">Address Receive </label>
                    <b-form-input
                        min="0"
                        v-model="transfer.to"
                        type="text"
                        id="inputAddress"
                        placeholder="Enter Address Receive"
                        class="bg-input"
                    ></b-form-input>
                </b-form-group>
                <b-button
                    class="button-confirm"
                    type="submit"
                    :disabled="depositAmount > BalanceSystem.HBG"
                >
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
        <b-modal id="swap" title="Swap Energy" hide-footer @hidden="resetSwap">
            <b-form @submit.prevent="onSwap()">
                <b-form-group>
                    <label for="">From</label>
                    <div class="form-sl">
                        <b-form-select
                            v-model="swap.from"
                            :options="typeSwap"
                            class="mx-auto"
                        ></b-form-select>
                        <img src="~@/assets/images/icons/drop.png" alt="" />
                    </div>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmountHBGEquivalent"> To </label>
                    <b-form-input
                        v-model="swap.to"
                        id="inputAmountHBGEquivalent"
                        readonly
                        class="bg-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group v-if="false">
                    <label for="">To</label>
                    <div class="form-sl">
                        <b-form-select
                            v-model="swap.to"
                            :options="listTo"
                            class="mx-auto"
                        ></b-form-select>
                        <img src="~@/assets/images/icons/drop.png" alt="" />
                    </div>
                </b-form-group>
                <b-form-group>
                    <label for="">Your Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            BalanceSystem[swap.from],
                            '',
                            4,
                        )} ${swap.from}`"
                        readonly
                        class="bg-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">
                        Swap Amount (
                        {{ swap.from === 'HBG' ? 'USDT' : swap.from }} )
                    </label>
                    <b-form-input
                        min="100"
                        v-model="swap.amount"
                        step="any"
                        type="number"
                        id="inputAmount"
                        placeholder="Enter Your Amount Transfer"
                        @change="onPredictSwap"
                        class="bg-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group v-if="swap.from === 'HBG'">
                    <label for="inputAmountHBGEquivalent">
                        Amount HBG Equivalent
                    </label>
                    <b-form-input
                        min="0"
                        v-model="predictSwap.amountHBG"
                        step="any"
                        type="number"
                        id="inputAmountHBGEquivalent"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group v-if="false">
                    <label for="inputAmount">
                        Fee ({{ infoSwap[`${swap.to}`] * 100 }}%)
                    </label>
                    <b-form-input
                        :value="`${TruncateToDecimals2(
                            getValueReal.feeToken,
                            '',
                            4,
                        )} ${swap.to}`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Min Swap</label>
                    <b-form-input
                        :value="`${TruncateToDecimals2(
                            swap.from === 'HBG' ? 10 : 100,
                            '',
                            4,
                        )} ${swap.from === 'HBG' ? 'USDT' : swap.from}`"
                        readonly
                        class="bg-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group v-if="swap.from !== 'HBG'">
                    <label for="inputAddress">Price </label>
                    <b-form-input
                        :value="`${TruncateToDecimals2(
                            predictSwap.rateCurrent,
                            '',
                            4,
                        )} Heronium/HBG`"
                        readonly
                        class="bg-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAddress">Actually Receive </label>
                    <b-form-input
                        :value="`${TruncateToDecimals2(
                            swap.from === 'HBG'
                                ? predictSwap.amount
                                : predictSwap.amountHBG,
                            '',
                            4,
                        )} ${swap.to}`"
                        readonly
                        class="bg-input"
                    ></b-form-input>
                </b-form-group>
                <b-button
                    class="button-confirm"
                    type="submit"
                    :disabled="swap.amount > BalanceSystem[swap.from]"
                >
                    Swap
                </b-button>
            </b-form>
        </b-modal>
        <b-modal id="SuggestDeposit" title="User Suggest Deposit" hide-footer>
            <div>
                <p class="mb-0 text-confirm-2">
                    Your balance InGame is not enough to buy this item.
                </p>
                <p class="mb-0 text-confirm-2">Do you want to deposit now ?</p>
            </div>
            <div
                class="d-flex mt-5 flex-wrap justify-content-around align-items-center"
            >
                <b-button
                    class="mx-auto my-2 button-confirm"
                    variant="primary"
                    @click="onShowDeposit()"
                >
                    Deposit Now
                </b-button>
                <b-button
                    class="mx-auto my-2 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('SuggestDeposit')"
                >
                    Cancel
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<style scoped>
input {
    background: rgba(0, 255, 255, 0.15) !important;
    border: none;
}
</style>
<style lang="scss">
.container-wallet {
    @media (max-width: 991px) {
        .row {
            margin: 0;
        }
    }
    .col-12 {
        padding-left: 5px;
        padding-right: 5px;
        .row {
            margin: 0;
            .col-12 {
                padding: 0;
            }
        }
    }
    .box-wallet {
        width: 100%;
        min-height: 150px;
        border: 1px solid #00ffff;
        margin-bottom: 15px;
        padding: 10px 10px;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &:after,
        &::before {
            z-index: 0;
            content: '';
            width: 6px;
            height: 6px;
            background: #00ffff;
        }

        &:after {
            position: absolute;
            top: 1px;
            right: 1px;
        }
        &::before {
            position: absolute;
            bottom: 1px;
            left: 1px;
        }
        &.main-box {
            min-height: 315px;
            padding: 15px 25px;
            @media (max-width: 413px) {
                padding: 15px 10px;
            }
        }
        &-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
            &.border-b {
                border-bottom: 1px solid #fff;
                padding: 0 0 15px;
            }
            &-image {
                margin-right: 20px;
                img {
                    width: 29px;
                    height: 29px;
                }
            }
            &-title {
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 20px;
                line-height: 33px;
                letter-spacing: 0.75px;
                color: #fcfcfc;
            }
        }
        &-body {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 15px 0;
            flex-wrap: wrap;
            &-image {
                position: relative;
                margin-right: 15px;
                flex: 0 0 65px;
                img {
                    width: 43px;
                    height: 43px;
                    margin-right: 15px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    width: 5px;
                    height: 5px;
                    background: #00ffff;
                    border-radius: 50%;
                }
            }
            &-amount {
                flex: 1 1 auto;
                font-family: 'Epilogue';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                color: #00ffff;
            }
            &-symbol {
                flex: 1 1 auto;
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.75px;
                color: #00ffff;
                text-align: center;
            }
        }
        &-footer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            a {
                .box-wallet-footer-button {
                    max-width: unset;
                }
            }
            &-button {
                margin: 10px;
                background: #55555550;
                border-radius: 54px;
                height: 44px;
                width: 160px;
                border: none;
                box-shadow: unset;
                outline: none !important;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: calc(50% - 20px);
                &:hover,
                &:focus,
                &:active {
                    background: rgba(0, 255, 255, 0.95);
                    * {
                        color: #000 !important;
                    }
                }
                &-text {
                    font-family: 'HHBI';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: 0.75px;
                    color: #dcdcdc;
                }
                &-image {
                    margin-right: 12px;
                    img {
                        width: 17px;
                        height: auto;
                        max-height: 17px;
                        object-fit: scale-down;
                    }
                }
            }
        }
    }
}

#transferHBG,
#transfer,
#swap,
#withdraw,
#deposit {
    .note {
        text-align: center;
        .title {
            color: #00ffff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        p {
            color: #fff;
            font-weight: 500;
            span {
                color: #00ffff;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }
    .modal-content {
        .form-group {
            width: 80%;
            min-width: 200px;
            margin: 0 auto 15px;
        }
        .form-sl {
            position: relative;
            img {
                height: 15px;
                width: auto;
                position: absolute;
                right: 15px;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }
        select {
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #00ffff;
            option {
                background: #00000b;
            }
        }
        input {
            margin: 0 auto;
            width: 100%;
        }
        label {
            width: 100%;
            min-width: 200px;
            margin: 0 auto;
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
    }
}
</style>

<style lang="scss" scoped>
a {
    text-decoration: none;
}
.upgrade-header {
    font-family: 'Poppins';
    border: 1px solid rgba(0, 255, 255, 0.3);
    border-radius: 4px;
    padding: 47px 70px;
    @media (max-width: 618px) {
        padding: 30px 20px;
        .upgrage-header-content {
            padding-left: 20px !important;
            .col-12 {
                padding-right: 1rem;
            }
        }
    }
    @media (max-width: 480px) {
        .upgrage-header-content {
            padding: 0 !important;
        }
    }
    .upgrage-header-content {
        padding-left: 40px;
        .upgrade-title {
            font-weight: 600;
            font-size: 24px;
            color: #00ffff;
            padding-left: 40px;
            position: relative;
            @media (min-width: 576px) {
                &::before {
                    content: '';
                    position: absolute;
                    top: 40%;
                    left: 15px;
                    width: 6px;
                    height: 6px;
                    background: #00ffff;
                    border-radius: 50%;
                }
            }
            @media (max-width: 575px) {
                width: 100%;
                padding: 0 1rem;
                text-align: center;
            }
        }
        .upgrade-coin {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #f8f8f8;
            padding-left: 36px;
            @media (max-width: 575px) {
                width: 100%;
                padding: 0 1rem;
                text-align: center;
            }
        }
        .upgrade-header-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: rgba(0, 255, 255, 0.2);
            height: 38px;
            border-radius: 54px;
            outline: unset !important;
            span {
                padding-left: 16px;
                font-weight: 500;
                font-size: 12px;
                color: #00ffff;
            }
        }
        .upgrade-header-btn2 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: rgba(85, 85, 85, 0.2);
            height: 38px;
            border-radius: 54px;
            outline: unset !important;
            span {
                padding-left: 16px;
                font-weight: 500;
                font-size: 12px;
                color: #dcdcdc;
            }
        }
    }
}
</style>
