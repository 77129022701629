const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        dashboardStatistic: {
            lastDay: {
                totalSale: 0,
                totalVolume: 0,
                totalHero: 0,
            },
            lastWeek: {
                totalSale: 0,
                totalVolume: 0,
                totalHero: 0,
            },
            lastMonth: {
                totalSale: 0,
                totalVolume: 0,
                totalHero: 0,
            },
        },
        recentlySold: {
            hero: {
                results: [],
            },
            land: {
                results: [],
            },
            box: {
                results: [],
            },
        },
        recentlyListed: {
            hero: {
                results: [],
            },
            land: {
                results: [],
            },
            box: {
                results: [],
            },
        },
    }),
    getters: {
        ListDashboardStatistic: (state) => state.dashboardStatistic,
        // RecentlySoldHero: (state) => state.recentlySold.hero,
        // RecentlyListedHero: (state) => state.recentlyListed.hero,
        RecentlySold: (state) => state.recentlySold,
        RecentlyListed: (state) => state.recentlyListed,
    },
    actions: {
        req_getDashboardStatistic: ({ commit }) => {
            axios.get(`api/market/dashboard/statistic`).then((response) => {
                commit('SET_DASHBOARD_STATISTIC', response);
            });
        },
        req_getRecentlySold: ({ commit }, input) => {
        //   console.log(input);
            axios
                .get(`api/market/dashboard/recently-list-sold/${input.type}`, {
                    params: input,
                })
                .then((response) => {
                    commit('SET_RECENTLY_SOLD', { [input.type]: response});
                });
        },
        req_getRecentlListed: ({ commit }, input) => {
            axios
                .get(`api/market/dashboard/recently-list/${input.type}`, {
                    params: input,
                })
                .then((response) => {
                    commit('SET_RECENTLY_LISTED', { [input.type]: response});
                });
        },
    },
    mutations: {
        SET_DASHBOARD_STATISTIC(state, data) {
            state.dashboardStatistic = data;
        },
        SET_RECENTLY_SOLD(state, data) {
            state.recentlySold = {
                ...state.recentlySold,
                ...data,
            };
        },
        SET_RECENTLY_LISTED(state, data) {
            state.recentlyListed = {
                ...state.recentlyListed,
                ...data,
            };
        },
    },
};
