/* eslint-disable no-unused-vars */
const store = require('@/store');

export default {
    namespaced: true,
    state: () => ({
        addr: '',
        bnb: 0,
        hbg: 0,
        usdt: 0,
        busd: 0,
        price: 0.04,
        bnbPrice: 0,
        busdPrice: 0,
        contractPrice: 0,
    }),

    getters: {
        Balance: (state) => {
            return {
                usdt: state.usdt,
                bnb: state.bnb,
                hbg: state.hbg,
                busd: state.busd,
                price: state.price,
                bnbPrice: state.bnbPrice,
                busdPrice: state.busdPrice,
            };
        },
    },

    actions: {},

    mutations: {
        async setupWallet(state, input) {
            state.addr = input;
        },

        async updateWallet(state, address) {
            if (address) {
                state.addr = address;
            }
            if (state.addr === '') {
                return;
            }
            const { web3 } = store.default.state.contract;
            const { bnb, hbg } = store.default.state.contract.bep_20;
            state.bnb =
                (await web3.provider.eth.getBalance(state.addr)) / bnb.dec;
            state.hbg =
                (await hbg.cont.methods.balanceOf(state.addr).call()) / hbg.dec;
            this.commit('wallet/getPriceHBG');
            // setInterval(() => {
            //   this.commit('wallet/getPriceHBG');
            // }, 1000000);
        },
        async getPriceHBG(state) {
            const { web3, isNetWork } = store.default.state.contract;
            const { bnb, hbg, usdt, busd } =
                store.default.state.contract.bep_20;
            const { pancake, marketNew } =
                store.default.state.contract.marketplace;

            const oneUSDT = web3.provider.utils.toWei('1').toString();
            const txObj = await pancake.cont.methods
                .getAmountsOut(oneUSDT, [hbg.addr, bnb.addr, usdt.addr])
                .call();
            const txObject = await pancake.cont.methods
                .getAmountsOut(oneUSDT, [usdt.addr, bnb.addr])
                .call();
            if (isNetWork === 'mainnet') {
                const priceBUSD = await pancake.cont.methods
                    .getAmountsOut(oneUSDT, [usdt.addr, busd.addr])
                    .call();
                state.busdPrice = parseFloat(priceBUSD[1] / busd.dec);
            }

            state.bnbPrice = parseFloat(txObject[1] / bnb.dec);
            const BNBtoUSDT = txObj[1] / bnb.dec / (txObject[1] / bnb.dec);
            const ava = (txObj[2] / usdt.dec + BNBtoUSDT) / 2;
            const data = await marketNew.cont.methods
                .convertUSDtoToken(oneUSDT)
                .call();
            state.price = parseFloat(data / 10 ** 18);
        },
        async clearWallet(state) {
            state.bnb = 0;
            state.hbg = 0;
            state.usdt = 0;
        },
    },
};
