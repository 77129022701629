const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        listPicker: [],
        listIdPicker: [],
        listHero: {
            list: [],
            current: 1,
            total: 1,
        },
        existingList: {
            list: [],
            current: 1,
            total: 1,
        },
        comingList: {
            list: [],
            current: 1,
            total: 1,
        },
        participatingList: {
            list: [],
            current: 1,
            total: 1,
        },
        lockAddress: null,
    }),
    getters: {
        ListPicker: (state) => state.listPicker,
        ListIdPicker: (state) => state.listIdPicker,
        ListHero: (state) => state.listHero,
        ExistingList: (state) => state.existingList,
        ComingList: (state) => state.comingList,
        ParticipatingList: (state) => state.participatingList,
        LockAddress: (state) => state.lockAddress,
    },
    actions: {
        req_getExistingList({ commit }, input) {
            axios
                .get('/api/promotion/follow-user', {
                    params: input,
                })
                .then((res) => {
                    if (res) {
                        commit('SET_EXISTING_LIST', res);
                    }
                });
        },
        req_getComingList({ commit }, input) {
            axios
                .get('/api/promotion/follow-user', {
                    params: input,
                })
                .then((res) => {
                    if (res) {
                        commit('SET_COMING_LIST', res);
                    }
                });
        },
        req_getComingParticipating({ commit }, input) {
            axios
                .get('/api/promotion/follow-user', {
                    params: input,
                })
                .then((res) => {
                    if (res) {
                        commit('SET_PARTICIPATING_LIST', res);
                    }
                });
        },
        req_getAddressLock({ commit }) {
            axios.get('/api/promotion/system-address').then((res) => {
                if (res) {
                    commit('SET_LOCK_ADDRESS', res);
                }
            });
        },
        req_postLock({ commit }, input) {
            axios.post('/api/promotion/block-hero', input).then((res) => {
                if (res) {
                    commit('SET_LOCK', res);
                }
            });
        },
        req_postUnlock({ commit }, input) {
            axios
                .post('/api/promotion/unblock', {
                    token: input,
                })
                .then((res) => {
                    if (res) {
                        commit('SET_UNLOCK', res);
                    }
                });
        },
    },
    mutations: {
        RESET_PICKER(state) {
            state.listPicker = [];
            state.listIdPicker = [];
        },
        REMOVE_HERO(state, hero) {
            const { listPicker, listIdPicker } = state;
            const idx = listPicker.findIndex((x) => x.tokenId === hero);
            listPicker.splice(idx, 1);
            listIdPicker.splice(idx, 1);
            state.listPicker = listPicker;
            state.listIdPicker = listIdPicker;
        },
        ADD_HERO(state, hero) {
            const { listPicker, listIdPicker } = state;
            if (listPicker.length >= 1) {
                if (
                    listPicker.filter((e) => e.tokenId === hero.tokenId)
                        .length > 0
                ) {
                    return;
                }
                listPicker.push(hero);
                listIdPicker.push(hero.tokenId);
            } else {
                listPicker.push(hero);
                listIdPicker.push(hero.tokenId);
            }
            state.listPicker = listPicker;
        },
        ADD_LIST_HERO(state, data) {
            const { listPicker } = state;
            const { results, page, totalPages, totalResults } = data;
            const listHero = results;

            for (let i = 0; i < listHero.length; i += 1) {
                const obj = listHero[i];

                if (listPicker.indexOf(obj.tokenId) !== -1) {
                    listHero[i].onUpgrade = true;
                } else {
                    listHero[i].onUpgrade = false;
                }
            }
            state.listHero = {
                list: listHero,
                current: page,
                total: totalPages,
                totalHero: totalResults,
            };
        },
        SET_LOCK_ADDRESS(state, data) {
            state.lockAddress = data;
        },
        SET_UNLOCK(state, data) {
            console.log(data);
        },
        SET_LOCK(state, data) {
            console.log(data);
        },
        SET_EXISTING_LIST(state, data) {
            const { results, totalPages } = data;
            state.existingList = {
                list: results,
                total: totalPages,
            };
        },
        SET_COMING_LIST(state, data) {
            const { results, totalPages } = data;
            state.comingList = {
                list: results,
                total: totalPages,
            };
        },
        SET_PARTICIPATING_LIST(state, data) {
            const { results, totalPages } = data;
            state.participatingList = {
                list: results,
                total: totalPages,
            };
        },
    },
};
