<template>
    <div id="FooterView" class="mt-4">
        <b-container>
            <b-row class="ml-0 w-100">
                <b-col xl="3" md="6" class="py-3">
                    <div class="d-flex align-items-center" data-aos="fade-up">
                        <div class="home-dot">
                            <img
                                src="../../assets/images/icons/footer-dot.png"
                                alt=""
                            />
                        </div>
                        <p class="footer-title m-0 ml-3">OUR ECOSYSTEM</p>
                    </div>
                    <b-row class="ml-0 w-100 mt-3 mt-md-4">
                        <b-col
                            v-for="(item, idx) in col_1"
                            :key="item + idx"
                            cols="6"
                            class="p-0"
                        >
                            <p class="col1-p my-2" data-aos="fade-up">
                                {{ item.title }}
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xl="2" md="6" class="mt-3 mt-lg-0 px-2 py-3">
                    <div class="d-flex align-items-center" data-aos="fade-up">
                        <div class="home-dot">
                            <img
                                src="~@/assets/images/icons/footer-dot.png"
                                alt=""
                            />
                        </div>
                        <p class="footer-title m-0 ml-3">CONTACT US</p>
                    </div>
                    <p class="col1-p mt-4" data-aos="zoom-in">
                        Enterprise Service <br class="d-none d-md-block" />
                        Management PTE. LTD
                    </p>
                    <div
                        class="mini-line my-3 my-md-4"
                        data-aos="zoom-in"
                    ></div>
                    <a href="mailto:support@herobook.io" data-aos="zoom-in" class="linked-email"
                        >support@herobook.io</a
                    >
                </b-col>
                <b-col
                    xl="7"
                    class="p-2 d-flex flex-column justify-content-center"
                >
                    <b-row
                        class="ml-0 w-100 justify-content-between px-lg-5 px-3"
                    >
                        <div
                            v-for="(item, idx) in col_3"
                            :key="item + idx"
                            class="d-flex"
                        >
                            <a
                                :href="item.link"
                                class="col_3a"
                                data-aos="fade-up"
                            >
                                {{ item.title }}
                            </a>
                            <div class="boder-left-footer">
                                <div class="boder-left-footer-line"></div>
                            </div>
                        </div>
                    </b-row>
                    <b-row class="ml-0 w-100 align-items-center pt-5">
                        <b-col cols="8" class="px-0 px-sm-2">
                            <div
                                cols="2"
                                class="p-0 d-flex justify-content-center"
                            >
                                <a
                                    v-for="(item, idx) in social"
                                    :key="idx + item"
                                    :href="item.link"
                                    class="social-link mx-lg-4 mx-2 mx-sm-1 mx-md-2"
                                >
                                    <img :src="item.src" alt="" />
                                </a>
                            </div>
                        </b-col>
                        <b-col cols="4 d-flex justify-content-end">
                            <div class="footer-logo">
                                <img
                                    src="~@/assets/images/logo/logo.png"
                                    alt=""
                                    draggable="false"
                                />
                            </div>
                        </b-col>
                    </b-row>
                    <p class="reserved text-right pt-4 mt-3 px-2">
                        © 2022, Hero Book. All rights reserved.
                    </p>
                    <p
                        v-if="false"
                        class="col_3p mt-4 px-2 d-none"
                        data-aos="fade-left"
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed cras du dela accumsan consectetur adipiscing
                        susapendisse sagittis tempora ultricies non. Sed
                        consectetur adipiscing massa nunc nec vitae delalih,
                        senectus mollis ultrices consectetur adipiscing unne
                        consectetura mo adipiscing accumsan suspendisse duis ad.
                    </p>
                </b-col>
            </b-row>
            <div v-if="false" class="divide-line"></div>
            <b-row v-if="false" class="ml-0 w-100">
                <b-col
                    sm="4"
                    class="d-flex align-items-center justify-content-between p-0"
                >
                    <p class="reserved">
                        © 2022, Hero Book. All rights reserved.
                    </p>
                    <button
                        class="btn-to-top d-flex d-sm-none"
                        :class="{ activeProgress: isActive }"
                        @click="handleClick"
                        v-if="false"
                    >
                        <!-- <img src="~/assets/images/to-top.png" class="to-top" /> -->
                    </button>
                </b-col>
                <b-col
                    v-if="false"
                    sm="7"
                    class="px-0 px-sm-2 mt-3 mt-sm-0 d-flex align-items-center"
                >
                    <b-row class="ml-0 w-100 align-items-center">
                        <b-col cols="8" class="px-0 px-sm-2">
                            <div
                                cols="2"
                                class="p-0 d-flex justify-content-center"
                            >
                                <a
                                    v-for="(item, idx) in social"
                                    :key="idx + item"
                                    :href="item.link"
                                    class="social-link mx-lg-4 mx-2 mx-sm-1 mx-md-2"
                                >
                                    <img :src="item.src" alt="" />
                                </a>
                            </div>
                        </b-col>
                        <b-col cols="4">
                            <div class="footer-logo">
                                <img
                                    src="../../assets/images/logo/footer-logo.png"
                                    alt=""
                                    draggable="false"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'FooterView',
    data() {
        return {
            scrollProgress: 0,
            col_1: [
                {
                    title: 'HeroBook',
                },
                {
                    title: 'Hero War',
                },
                {
                    title: 'Hero Land',
                },
                {
                    title: 'Hero Snake',
                },

                {
                    title: 'Hero Card',
                },
            ],
            col_3: [
                {
                    title: 'White Paper',
                    line: true,
                    link: '',
                },
                {
                    title: 'HBG Token',
                    line: true,
                    link: 'https://bscscan.com/token/0x8c2Da84EA88151109478846cc7c6c06C481dbe97',
                },
                {
                    title: 'Terms of Service',
                    line: true,
                    link: 'https://herobook.io/term-of-service',
                },
                {
                    title: 'Privacy Policy',
                    line: false,
                    link: 'https://herobook.io/privacy-policy',
                },
            ],
            social: [
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/twitter.png'),
                    link: 'https://twitter.com/HeroBookGlobal',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/telegram.png'),
                    link: 'https://t.me/herobook_chat',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/tittok.png'),
                    link: 'https://www.tiktok.com/@herobookglobal',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/facebook.png'),
                    link: 'https://www.facebook.com/herobookglobal',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/youtube.png'),
                    link: 'https://www.youtube.com/channel/UCKxpBy7RW2KR-aUQQ04v-WA',
                },
            ],
        };
    },
    computed: {
        isActive() {
            return this.scrollProgress > 10;
        },
        scrollPercent() {
            return (
                308 -
                (this.scrollProgress /
                    (document.body.scrollHeight - window.innerHeight)) *
                    308
            );
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleClick() {
            window.scrollTo(0, 0);
        },
        handleScroll() {
            this.scrollProgress = window.scrollY;
        },
    },
};
</script>
<style lang="scss" scoped>
#FooterView {
    font-family: 'Poppins';
    padding: 4rem 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        rgba(53, 63, 72, 0.6);
    @media (max-width: 991px) {
        padding: 3rem 0;
    }
    @media (max-width: 768px) {
        padding: 2rem 0;
    }
    .col-to-top {
        display: flex;
        justify-content: center;
        @media (max-width: 575px) {
            justify-content: flex-end;
        }
    }

    p.reserved {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #dcdcdc !important;
        margin: 0;
    }
    .linked-email {
        text-decoration: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #dcdcdc;
    }

    button {
        width: 50px;
        height: 50px;
        border: none;
        outline: none;
        position: relative;
        cursor: pointer;
        background: linear-gradient(to right, #18d4e2, #a506f9);
        &:hover {
            background-size: 200%;
            animation: loop 1s infinite linear;
        }
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: scale-down;
        }
        @media (max-width: 991px) {
            width: 35px;
            height: 35px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
        }
    }
    .footer-logo {
        max-width: 169px;
        width: 100%;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
            max-width: 139px;
            width: 100%;
        }
        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }
    .social-link {
        width: 40px;
        height: 40px;
        @media (max-width: 768px) {
            width: 30px;
            height: 30px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }
    .divide-line {
        height: 1px;
        width: 100%;
        background: #353f48;
        margin: 3rem 0;
        @media (max-width: 991px) {
            margin: 2rem 0;
        }
    }
    .footer-title {
        font-weight: 600;
        color: #f8f8f8;
    }
    .col1-p {
        color: #dcdcdc;
        font-size: clamp(14px, 1.5vw, 16px);
    }
    .mini-line {
        height: 0.5px;
        width: 35px;
        background: #dcdcdc;
    }
    .col_3a {
        font-weight: 400;
        color: #f0f0f0;
    }
    .col-line {
        width: 1px;
        background: #f0f0f0;
        height: 100%;
    }
    .col_3p {
        font-weight: 400;
        color: #dcdcdc;
    }
    .boder-left-footer {
        position: relative;
        .boder-left-footer-line {
            position: absolute;
            right: -35px;
            width: 1px;
            height: 20px;
            background: #f8f8f8;
        }
    }
}
</style>
