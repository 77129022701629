/* eslint-disable camelcase */
import { TOKEN_ABI } from '@/store/abi/token';
import { REGISTER_ABI } from '@/store/abi/register';
import { PRESALE_ABI } from '@/store/abi/presale';
import { HERO_ABI } from '@/store/abi/hero';
import { MARKET_ABI } from '@/store/abi/market';
// import { MARKET_ABI_NEWS } from '@/store/abi/market_new';
import { LAND_ABI } from '@/store/abi/land';
import { AIRDROP_ABI } from '@/store/abi/airDrop';
import { PANCAKE_ABI } from '@/store/abi/pancake';
import { HERO_ABI2 } from '@/store/abi/hero2';
import { MARKET_ABI2 } from '@/store/abi/market2';

// const network = 'mainnet';
const network = 'testnet';
function getNetwork() {
    const mainNet = {
        provider: {
            chainId: 0x38,
            link: 'https://bsc-dataseed1.defibit.io',
            url: 'https://bscscan.com',
        },
        pool: {
            deposit: '',
        },
        busd: {
            addr: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
            dec: 10 ** 18,
            cont: {},
        },

        usdt: {
            addr: '0x55d398326f99059ff775485246999027b3197955',
            dec: 10 ** 18,
            cont: {},
        },
        bnb: {
            addr: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            dec: 10 ** 18,
            cont: {},
        },
        hbg: {
            addr: '0x8c2Da84EA88151109478846cc7c6c06C481dbe97',
            dec: 10 ** 18,
            cont: {},
        },
        preSale: {
            addr: '0xE8AFAa0eD3a73202202362997252c7FF8dC621A0',
            abi: PRESALE_ABI,
            cont: {},
        },
        hero: {
            addr: '0xfEeE95adD36e54876F3b6ec6ed4d9f94dF433f52',
            abi: HERO_ABI,
            cont: {},
        },
        hero2: {
            addr: '0xF2647913ccbEe95B88c3ccC00aF167f006979aFD',
            abi: HERO_ABI2,
            cont: {},
        },
        land: {
            addr: '',
            abi: LAND_ABI,
            cont: {},
        },
        market: {
            addr: '0xa2f4C708839fb0f33b45826543B6880F29c2abf3',
            abi: MARKET_ABI,
            cont: {},
        },
        marketNew: {
            addr: '0xAc8cdAa6AFf27b92069BB5016d192c1C4007a973',
            abi: MARKET_ABI2,
            cont: {},
        },
        register: {
            addr: '0x234f80f2a1b04b88489346d9e346681305bb053b',
            abi: REGISTER_ABI,
            cont: {},
        },
        airdrop: {
            addr: '0x721d2e19a739cdedcb81d36fd60b247c8b570adc',
            abi: AIRDROP_ABI,
            cont: {},
        },
        pancake: {
            addr: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
            abi: PANCAKE_ABI,
            cont: {},
        },
    };
    const testNet = {
        provider: {
            chainId: 0x61,
            link: 'https://data-seed-prebsc-2-s2.binance.org:8545',
            url: 'https://testnet.bscscan.com',
        },
        pool: {
            deposit: '0x9AABBC35cd3025eab5D59d7206de6058F3A2B1F4',
        },
        usdt: {
            addr: '0x878Ca3AF8FDA0344BA7DDbb2186489d18433cfE9',
            dec: 10 ** 18,
            cont: {},
        },
        bnb: {
            addr: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
            dec: 10 ** 18,
            cont: {},
        },
        hbg: {
            addr: '0xA9Afb65805e143980C4FC231E008DA764FD29b53',
            dec: 10 ** 18,
            cont: {},
        },
        busd: {
            addr: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
            dec: 10 ** 18,
            cont: {},
        },
        preSale: {
            addr: '0x3901025EA66a6f3685cC307ACC6A4c737B5E8e98',
            abi: PRESALE_ABI,
            cont: {},
        },
        hero: {
            addr: '0xA7Ae9Eabf74902F0ab7Fd3b49b63291679B9fc66',
            abi: HERO_ABI,
            cont: {},
        },
        hero2: {
            addr: '0xd843b20d8638922f32fc7b6b953e4ebfe9dab892',
            abi: HERO_ABI2,
            cont: {},
        },
        land: {
            addr: '0x653c631c8F1AE34D082c9c4c6Ec82dA6f9E20701',
            abi: LAND_ABI,
            cont: {},
        },
        register: {
            addr: '0x341ba10330fdf85f550e73e5f78e081923a99157',
            abi: REGISTER_ABI,
            cont: {},
        },
        market: {
            addr: '0xe64E9CE3D3DAAc5e372f083De2ebF79a9Da1af54',
            abi: MARKET_ABI,
            cont: {},
        },
        marketNew: {
            addr: '0x6adc06485eb72852cc39456dfdb0dea294d2f2a4',
            abi: MARKET_ABI2,
            cont: {},
        },
        airdrop: {
            addr: '0x3c3f4ad6f981e85de20f45892fc7623cecb0a697',
            abi: AIRDROP_ABI,
            cont: {},
        },
        pancake: {
            addr: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
            abi: PANCAKE_ABI,
            cont: {},
        },
    };

    if (network === 'mainnet') {
        return mainNet;
    }
    return testNet;
}
export default {
    namespaced: true,
    state: () => ({
        provider: getNetwork().provider,
        web3: {},

        // wallet
        wallet: {
            addr: '',
        },

        // token
        bep_20: {
            hbg: getNetwork().hbg,
            usdt: getNetwork().usdt,
            bnb: getNetwork().bnb,
            busd: getNetwork().busd,
        },
        pool: getNetwork().pool,
        bep_721: {},
        // manager
        managers: {
            register: getNetwork().register,
            airdrop: getNetwork().airdrop,
        },
        marketplace: {
            preSale: getNetwork().preSale,
            hero: getNetwork().hero,
            hero2: getNetwork().hero2,
            market: getNetwork().market,
            land: getNetwork().land,
            pancake: getNetwork().pancake,
            marketNew: getNetwork().marketNew,
        },
        isNetwork: network,
    }),

    getters: {
        isNetWork: (state) => state.isNetwork,
    },

    actions: {},
    mutations: {
        async setupTokens(state) {
            const bep_20_token = state.bep_20;

            Object.keys(bep_20_token).forEach((key) => {
                if (state.bep_20[key].addr !== '') {
                    state.bep_20[key].cont =
                        new state.web3.provider.eth.Contract(
                            TOKEN_ABI,
                            state.bep_20[key].addr,
                        );
                }
            });
        },
        async setupManagers(state) {
            // Managers
            Object.keys(state.managers).forEach((key) => {
                if (state.managers[key].addr !== '') {
                    state.managers[key].cont =
                        new state.web3.provider.eth.Contract(
                            state.managers[key].abi,
                            state.managers[key].addr,
                        );
                }
            });
            // market
            Object.keys(state.marketplace).forEach((key) => {
                if (state.marketplace[key].addr !== '') {
                    state.marketplace[key].cont =
                        new state.web3.provider.eth.Contract(
                            state.marketplace[key].abi,
                            state.marketplace[key].addr,
                        );
                }
            });
        },
    },
};
