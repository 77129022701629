import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Layout from './layouts/Master.vue';

Vue.use(VueRouter);

const routes = [
    { path: '*', redirect: '/market' },
    {
        path: '/',
        component: Layout,
        redirect: '/market',
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import('./views/Dashboard/Index.vue'),
                meta: {
                    title: 'Dashboard',
                },
            },
            {
                path: 'marketOld',
                name: 'MarketOld',
                component: () => import('./views/Market/Index.vue'),
                redirect: { name: 'HeroList' },
                children: [
                    {
                        path: 'hero',
                        name: 'HeroList',
                        component: () => import('@/components/market/Hero.vue'),
                        meta: {
                            title: 'Hero Market',
                        },
                    },
                    {
                        path: 'land',
                        name: 'LandList',
                        component: () => import('@/components/market/Land.vue'),
                        meta: {
                            title: 'Land Market',
                        },
                    },
                    {
                        path: 'box',
                        name: 'BoxListMarket',
                        component: () => import('@/components/market/Box.vue'),
                        meta: {
                            title: 'Box Market',
                        },
                    },
                ],
            },
            {
                path: 'market',
                name: 'Market',
                component: () => import('@/components/market-second/index.vue'),
                meta: {
                    title: 'Hero Dock Market',
                },
            },
            {
                path: 'market/detail/:type/:itemName',
                name: 'DetailMarket',
                component: () => import('./views/Market/Details.vue'),
            },
            {
                path: 'market/hero/:id',
                name: 'MarketDetail',
                component: () => import('@/components/market/DetailHero.vue'),
                meta: {
                    title: 'Detail Hero',
                },
            },
            {
                path: 'market/land/:id',
                name: 'MarketDetailLand',
                component: () => import('@/components/market/DetailLand.vue'),
                meta: {
                    title: 'Detail Land',
                },
            },
            {
                path: 'market/box/:id',
                name: 'MarketDetailBox',
                component: () => import('@/components/market/DetailBox.vue'),
                meta: {
                    title: 'Detail Box',
                },
            },
            {
                path: 'hero/:id',
                name: 'DetailHero',
                component: () => import('./views/public/detailHero.vue'),
                meta: {
                    title: 'Detail Hero',
                },
            },
            {
                path: 'shop',
                name: 'Shop',
                component: () => import('./views/Shop/Index.vue'),
                redirect: { name: 'InGame' },
                children: [
                    {
                        path: 'box',
                        name: 'BoxList',
                        component: () =>
                            import('@/components/shop/INO/Box.vue'),
                        meta: {
                            title: 'INO Shop',
                        },
                    },
                    {
                        path: 'box/:id',
                        name: 'BoxDetail',
                        component: () =>
                            import('@/components/shop/INO/DetailBox.vue'),
                        meta: {
                            title: 'Box Detail',
                        },
                    },
                    {
                        path: 'in-game',
                        name: 'InGame',
                        component: () =>
                            // import('@/components/shop/ItemShop.vue'), // Old page
                            import('@/components/shop/NewShopItem.vue'),
                        meta: {
                            title: 'Item in Game',
                        },
                    },
                ],
            },
            {
                path: 'account',
                name: 'Account',
                component: () => import('./views/Account/Index.vue'),
                meta: {
                    authRequired: true,
                    title: 'Information Account',
                },
                redirect: { name: 'Wallet' },
                children: [
                    {
                        path: 'wallet',
                        name: 'Wallet',
                        component: () =>
                            import('@/components/account/Wallet.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Account',
                        },
                    },
                    {
                        path: 'inventory',
                        name: 'Inventory',
                        component: () =>
                            import('@/components/account/NewInventory.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Inventory',
                        },
                    },
                    {
                        path: 'game',
                        name: 'Game',
                        component: () =>
                            import('@/components/account/NewGame.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Inventory',
                        },
                    },
                    {
                        path: 'transform-hero-snake',
                        name: 'SwapHeroSnake',
                        component: () =>
                            import('@/components/account/SwapHeroSnake.vue'),
                        meta: {
                            authRequired: true,
                            title: 'Swap Hero Snake',
                        },
                    },
                    {
                        path: 'mystery-box',
                        name: 'MysteryBoxDetail',
                        component: () =>
                            import(
                                '@/components/inventory/box/OpenMystery.vue'
                            ),
                        meta: {
                            authRequired: true,
                            title: 'Open Mystery Box',
                        },
                    },
                    {
                        path: 'activity',
                        name: 'Activity',
                        component: () =>
                            import('@/components/account/Activity.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Activity',
                        },
                    },
                    {
                        path: 'member',
                        name: 'Member',
                        component: () =>
                            import('@/components/account/Member.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Referral Link',
                        },
                    },
                    {
                        path: 'setting',
                        name: 'Setting',
                        component: () =>
                            import('@/components/account/Setting.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Account Setting',
                        },
                    },
                    {
                        path: 'summon',
                        name: 'Summon',
                        component: () =>
                            import('@/components/account/Summon.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Summon Hero',
                        },
                    },
                    // {
                    //     path: 'summon-old',
                    //     name: 'SummonOld',
                    //     component: () =>
                    //         import('@/components/account/SummonOld.vue'),
                    //     meta: {
                    //         authRequired: true,
                    //         title: 'User Summon Hero 1.0',
                    //     },
                    // },
                    {
                        path: 'game-balance',
                        name: 'GameBalance',
                        component: () =>
                            import('@/components/account/GameBalance.vue'),
                        meta: {
                            authRequired: true,
                            title: 'GameBalance',
                        },
                    },
                    {
                        path: 'upgrade',
                        name: 'Upgrade',
                        component: () =>
                            import('@/components/account/NewUpgrade.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Upgrade Hero',
                        },
                    },
                    {
                        path: 'burn',
                        name: 'Burn',
                        component: () => import('@/components/burn/Index.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Burn Hero',
                        },
                    },

                    {
                        path: 'support',
                        name: 'Support',
                        component: () =>
                            import('@/components/account/Support.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Support',
                        },
                    },
                    {
                        path: 'vaccinate',
                        name: 'Vaccinate',
                        component: () =>
                            import('@/components/vaccinate/Index.vue'),
                        meta: {
                            authRequired: true,
                            title: 'Vaccinate Lab',
                        },
                    },
                    {
                        path: 'swap',
                        name: 'Swap',
                        component: () =>
                            import('@/components/swapHero/Index.vue'),
                        meta: {
                            authRequired: true,
                            title: 'Swap NFT',
                        },
                    },
                    {
                        path: 'agency',
                        name: 'Agency',
                        component: () =>
                            import('@/components/account/NewAgency.vue'),
                        meta: {
                            authRequired: true,
                            title: 'Agency',
                        },
                    },
                ],
            },
            {
                path: 'promotion',
                name: 'Promotion',
                redirect: { name: 'PromotionList' },
                component: () => import('./views/promotion/Index.vue'),
                children: [
                    {
                        path: '/',
                        name: 'PromotionList',
                        component: () =>
                            import('@/components/lockHero/LockHero.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Promotion',
                        },
                    },
                    {
                        path: ':slug',
                        name: 'DetailPromotion',
                        component: () =>
                            import('@/components/lockHero/detailPromotion.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Promotion',
                        },
                    },
                ],
            },
            {
                path: 'vesting-schedule',
                name: 'PreSale',
                component: () => import('./views/PreSale/Index.vue'),
                meta: {
                    title: 'Vesting Schedule',
                },
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import('./views/Auth/Login.vue'),
                meta: {
                    title: 'Login Marketplace',
                },
            },
            {
                path: 'testing',
                name: 'test',
                component: () => import('./views/testing/index.vue'),
                children: [
                    {
                        path: 'upgrade',
                        component: () => import('./views/testing/upgrade.vue'),
                        name: 'upgrade2',
                    },
                    {
                        path: 'mystery',
                        component: () => import('./views/testing/mystery.vue'),
                        name: 'mystery2',
                    },
                    {
                        path: 'ino',
                        component: () => import('./views/testing/ino.vue'),
                        name: 'ino',
                    },
                ],
            },
            {
                path: 'register',
                name: 'Register',
                component: () => import('./views/Auth/Register.vue'),
                meta: {
                    title: 'Register Marketplace',
                },
            },
            // {
            //     path: 'forgot',
            //     name: 'Forgot',
            //     component: () => import('./views/Auth/Fogot.vue'),
            //     meta: {
            //         title: 'Forgot Password Marketplace',
            //     },
            // },
            {
                path: 'trade',
                name: 'Trade',
                component: () => import('./views/trade/Energy.vue'),
                meta: {
                    title: 'Energy Market',
                },
            },
        ],
    },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    linkActiveClass: 'active',
    linkExactActiveClass: 'active-children',
});
router.beforeEach((routeTo, routeFrom, next) => {
    const publicPages = ['/login', '/register', '/forgot', '/resend'];
    const authpage = publicPages.includes(routeTo.path);
    const isLogin = store.getters['auth/loggedIn'];
    if (routeTo.matched.some((m) => m.meta.authRequired)) {
        if (isLogin) {
            next();
        } else {
            next({ name: 'Login' });
        }
    } else if (isLogin) {
        if (authpage) {
            next({ name: 'Account', query: { tab: 'Member' } });
        } else {
            next();
        }
    } else {
        next();
    }
});
export default router;
