<script>
import { mapGetters, mapActions } from 'vuex';
import Paginate from '@/components/shared/paginate-second.vue';
import ItemBuy from './item-buy.vue';
import ItemBox from './item-box.vue';

const axios = require('@/store/axios.config').default;

export default {
    name: '',
    components: {
        Paginate,
        ItemBuy,
        ItemBox,
    },

    data() {
        return {
            marketTab: 'Newly',
            filter: {
                limit: 15,
                page: 1,
            },
            marketDetails: {
                totalItem: 1,
                totalPages: 1,
                limit: 1,
                items: [],
            },
        };
    },
    methods: {
        ...mapActions('hub', ['req_getPlatform']),
        onChangePage(page) {
            if (page === this.filter.page) {
                return;
            }
            this.$refs.topOfListItem.scrollIntoView({ behavior: 'smooth' });

            this.filter.page = page;
            this.getDetailsList();
        },
        onReloadList() {
            this.$refs.topOfListItem.scrollIntoView({ behavior: 'smooth' });
            this.getDetailsList();
        },
        async getDetailsList() {
            const { type, itemName } = this.$route.params;

            const data = await axios.get(
                `/api/herocoin/detail-list/${type}/${itemName}`,
                {
                    params: this.filter,
                },
            );
            if (data && data.results.length > 0) {
                const { limit, totalPages, totalResults, results } = data;
                this.marketDetails = {
                    totalItem: totalResults,
                    totalPages,
                    limit,
                    items: results,
                };
            } else {
                this.$toastr.w('Somethings went wrong', 'Please try again');
                this.$router.push({ name: 'Market' });
            }
        },
    },
    computed: {
        ...mapGetters({
            Balance: 'wallet/Balance',
            isLogin: 'auth/loggedIn',
            UserInfo: 'info/UserInfo',
        }),
        ...mapGetters('hub', ['PlatformList']),
    },
    created() {
        this.req_getPlatform();
        this.getDetailsList();
    },
};
</script>
<template>
    <b-row class="market-detail-item">
        <b-col cols="12">
            <div class="item-list">
                <div class="item-list-header" ref="topOfListItem">
                    <div class="item-list-header-name">Name</div>
                    <div class="item-list-header-quantity">Seller</div>

                    <div class="item-list-header-price">Price</div>
                    <div class="item-list-header-newly"></div>
                </div>
                <template v-for="(item, indexFor) in marketDetails.items">
                    <ItemBuy
                        @onReloadList="onReloadList"
                        :key="`${item.itemName}-market-${indexFor}`"
                        v-if="item.name != 'box'"
                        :class="{ odd: indexFor % 2 == 0 }"
                        :item="item"
                        :showSeller="true"
                    />
                    <ItemBox
                        @onReloadList="onReloadList"
                        v-else
                        :key="`${item.itemName}-market2-${indexFor}`"
                        :class="{ odd: indexFor % 2 == 0 }"
                        :item="item"
                        :showSeller="true"
                    />
                </template>
                <Paginate
                    :totalPages="marketDetails.totalPages || 1"
                    :totalResult="marketDetails.totalItem || 1"
                    :perPage="marketDetails.limit || 1"
                    @current="onChangePage"
                    class="mt-3"
                />
            </div>
        </b-col>
    </b-row>
</template>

<style lang="scss">
.market-detail-item {
    margin-top: 70px;

    .item-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        background: rgba(53, 63, 72, 0.4);
        padding: 5px 15px;

        color: #fcfcfc;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        &-name {
            flex: 1 1 auto;
            max-width: 275px;
            min-width: 275px;
            min-width: 150px;
            @media (max-width: 575px) {
                min-width: 175px;
                max-width: 180px;
            }
        }
        &-quantity {
            flex: 0 0 100px;
            text-align: center;
            @media (max-width: 575px) {
                display: none;
            }
        }
        &-price {
            text-align: center;
            flex: 1 1 min(200px, 500px);
        }
        &-newly {
            text-align: center;
            flex: 0 0 110px;
            @media (max-width: 480px) {
                display: none;
            }
        }
    }
    .market-sidebar {
        min-height: 400px;
        width: 100%;
        background: rgba(53, 63, 72, 0.2);
        padding: 45px 20px;
        &\:title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #dcdcdc;
            padding-left: 15px;
            margin-bottom: 15px;
        }
        &-game {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            background: rgba(53, 63, 72, 0.3);
            border-radius: 4px;
            margin-bottom: 5px;
            &\:avatar {
                width: 50px;
                height: 50px;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #353f48;
                border: 0.5px solid #dcdcdc;
                border-radius: 100px;
                padding: 5px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
            &\:name {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                color: #f0f0f0;
                flex: 1 1 auto;
                margin-left: 50px;
            }
        }
        &-advance {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px;

            margin-bottom: 50px;
            outline: none !important;
            box-shadow: none !important;
            background: transparent !important;
            border: 0;
            border-bottom: 0.5px solid #dcdcdc;
            border-radius: 0;
            &\:text {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #888888;
            }
            &\:icon {
                transform: rotate(-90deg);
                height: 18px;
                width: 20px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
        }
        &-search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px;

            margin-bottom: 50px;
            outline: none !important;
            box-shadow: none !important;
            background: transparent !important;
            border: 0;
            border-bottom: 0.5px solid #dcdcdc;
            border-radius: 0;
            &\:input {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #888888;
                background: transparent;
                outline: none !important;
                box-shadow: none !important;
                background: transparent !important;
                border: 0;
                border-radius: 0;
                &:not(:placeholder-shown),
                &:focus {
                    color: #fcfcfc;
                }
            }
            &\:icon {
                transform: rotate(-90deg);
                height: 18px;
                width: 20px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
        }
        @media (max-width: 991px) {
            display: none;
        }
    }
}
</style>
