<script>
import { mapGetters, mapMutations } from 'vuex';
import { MetaMask } from '@/components/auth';
import LandShow from '@/components/inventory/land/LandShow.vue';
import Paginate from '@/components/shared/Paginate.vue';

const axios = require('@/store/axios.config').default;

export default {
    components: {
        Paginate,
        MetaMask,
        LandShow,
    },
    data() {
        return {
            // listHero,
            search: {
                tokenId: null,
                pricefrom: null,
                priceto: null,
                farmingFrom: null,
                farmingTo: null,
                type: 'land',
                page: 1,
                limit: 12,
                sortBy: null,
            },
            show: {
                class: false,
                price: false,
                level: false,
                rarity: false,
                farming: false,
                stat: false,
            },
            approve: false,
            showFilter: true,
            width: 0,
            DetailLand: {},
            receivedLand: {},
        };
    },
    computed: {
        ...mapGetters({
            ListLandSelling: 'market/ListLandSelling',
            SortBoxList: 'market/SortBoxList',
            Balance: 'wallet/Balance',
            isLogin: 'auth/loggedIn',
            UserInfo: 'info/UserInfo',
        }),
    },
    watch: {
        'search.sortBy': {
            handler() {
                this.onChangePage(1);
            },
            deep: true,
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        getPercents(current, end) {
            const percent = (current / end) * 100;
            if (percent < 20) {
                return 20;
            }
            if (percent >= 100) {
                return 100;
            }
            return percent;
        },
        onChangePage(pageNext) {
            this.search.page = pageNext;
            this.$store.dispatch('market/req_getListLandSelling', this.search);
        },
        handleResize() {
            this.width = window.innerWidth;
            if (window.innerWidth <= 1205) {
                this.showFilter = false;
            } else {
                this.showFilter = true;
            }
        },
        onFilter(typeFilter) {
            if (typeFilter === 'reset') {
                this.search.pricefrom = null;
                this.search.priceto = null;
                this.search.farmingFrom = null;
                this.search.farmingTo = null;
                this.search.tokenId = null;
                this.$store.dispatch(
                    'market/req_getListLandSelling',
                    this.search,
                );
                return;
            }
            if (this.search.tokenId) {
                if (this.search.tokenId.includes('#')) {
                    this.search.tokenId = this.search.tokenId.replace('#', '');
                }
                this.search.tokenId = this.search.tokenId.replace('#', '');
                this.search.tokenId = parseInt(this.search.tokenId, 10);
            }

            this.$store.dispatch('market/req_getListLandSelling', this.search);
        },
        async checkApproval() {
            const { hbg } = this.$store.state.contract.bep_20;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            this.onLoad();
            if (addr && marketNew.addr && hbg.cont.methods) {
                const hbgApproval =
                    (await hbg.cont.methods
                        .allowance(addr, marketNew.addr)
                        .call()) /
                    10 ** 18;
                console.log(this.TruncateToDecimals2(hbgApproval));
                this.approve = hbgApproval >= 1000000000;
                this.outLoad();
            } else {
                await this.sleep(2000);
                this.checkApproval();
            }
        },
        async Approve(tokenId) {
            const { hbg } = this.$store.state.contract.bep_20;
            const { web3 } = this.$store.state.contract;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            this.onLoad();
            const gasLimit = await this.getLimitGas();
            const txObj = hbg.cont.methods
                .approve(
                    marketNew.addr,
                    (1000000000 * 10 ** 18).toLocaleString('fullwide', {
                        useGrouping: false,
                    }),
                )
                .encodeABI();
            let gas = 0;
            try {
                // Calculate gas
                gas = await hbg.cont.methods
                    .approve(
                        marketNew.addr,
                        (1000000000 * 10 ** 18).toLocaleString('fullwide', {
                            useGrouping: false,
                        }),
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;
            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hbg.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.onBuyLand(tokenId);
                            this.outLoad();
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        async onBuyLand(tokenCheck) {
            this.onLoad();
            this.getDetailLand(tokenCheck);
            const { tokenId, price, land } = this.DetailLand;
            const { version } = land;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const decimalPrice = web3.provider.utils.toWei(
                price.toString(),
                'ether',
            );
            const txObj = await marketNew.cont.methods
                .buy(tokenId, version, decimalPrice)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();

            try {
                gas = await marketNew.cont.methods
                    .buy(tokenId, version, decimalPrice)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('buy', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async onCancelLand(tokenIdx) {
            this.onLoad();
            this.getDetailLand(tokenIdx);
            const { tokenId, land } = this.DetailLand;
            const { version } = land;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await marketNew.cont.methods
                .cancelSale(tokenId, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await marketNew.cont.methods
                    .cancelSale(tokenId, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancel', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async getDetailLand(tokenId) {
            const detail = await axios.get(
                `api/market/dashboard/detail/land/${tokenId}`,
            );
            if (
                !detail ||
                detail.buyer !== null ||
                detail.isCancel ||
                detail.land == null
            ) {
                if (!detail || detail.isCancel) {
                    this.$toastr.e('Land has been bought or sold', 'Oops!');
                }
                if (detail.land == null) {
                    this.$toastr.e('Can not buy this land', 'Oops!');
                }
                this.$bvModal.hide('confirm-buy');
                this.$bvModal.hide('confirm-cancel');
                this.$store.dispatch(
                    'market/req_getListLandSelling',
                    this.search,
                );
                this.outLoad();
            }
            if (detail) {
                this.DetailLand = detail;
            }
        },
        onPickLand(type, tokenId) {
            this.getDetailLand(tokenId);
            if (type === 'buy' || type === 'approve') {
                this.$bvModal.show('confirm-buy');
            }
            if (type === 'cancel') {
                this.$bvModal.show('confirm-cancel');
            }
        },
        async sendTransaction(type, txData) {
            if (type === 'buy') {
                const land = await axios.post(
                    'api/market/order/land/buy/send-transaction',
                    {
                        transactionHash: txData.transactionHash,
                    },
                );
                this.outLoad();
                this.receivedLand = land;
                this.$store.commit('wallet/updateWallet');
                this.$bvModal.hide('confirm-buy');
                this.$bvModal.show('modal-received');
            }
            if (type === 'cancel') {
                const cancel = await axios.post(
                    'api/market/order/sell/land/cancel',
                    {
                        transactionHash: txData.transactionHash,
                    },
                );
                this.outLoad();
                console.log(cancel);
                this.$bvModal.hide('confirm-cancel');
                this.$bvModal.show('modal-received');
            }
        },
    },
    mounted() {
        this.handleResize();
        this.checkApproval();
    },
    async created() {
        window.addEventListener('resize', this.handleResize);
        if (window.innerWidth <= 1205) {
            this.showFilter = false;
        } else {
            this.showFilter = true;
        }

        this.$store.dispatch('market/req_getListLandSelling', this.search);
        this.$store.dispatch('market/req_getListSortBox');
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<template>
    <div class="land-market">
        <div
            class="filter-active"
            v-if="!showFilter"
            @click="showFilter = !showFilter"
        >
            <div class="icons">
                <!-- <i delected class="far fa-caret-square-down"></i> -->
            </div>
            <div class="name">Filter</div>
        </div>
        <b-container class="content-market">
            <transition
                name="slide"
                enter-active-class="animate__animated animate__slideInLeft"
                leave-active-class="animate__animated animate__slideOutLeft"
            >
                <div class="left-filter" v-if="showFilter">
                    <div class="title">
                        Filter
                        <div
                            class="close"
                            v-if="showFilter"
                            @click="showFilter = !showFilter"
                        >
                            <!-- <i delected class="fas fa-times"></i> -->
                        </div>
                    </div>
                    <b-form-group class="search-id">
                        <b-form-input
                            v-model="search.tokenId"
                            placeholder="Search ID"
                        ></b-form-input>
                        <b-button>
                            <img
                                src="~@/assets/images/icons/search-2.png"
                                alt=""
                            />
                        </b-button>
                    </b-form-group>
                    <div class="collapse-section">
                        <b-button
                            v-b-toggle.collapse-price
                            variant="none"
                            class="button-collapse-search"
                        >
                            <div class="text">Price</div>
                            <div class="icons">
                                <img
                                    src="~@/assets/images/icons/drop-2.png"
                                    :style="`transform: rotate(${
                                        show.price ? '180deg' : '0'
                                    })`"
                                    alt=""
                                />
                            </div>
                        </b-button>
                        <b-collapse
                            id="collapse-price"
                            v-model="show.price"
                            class="mt-2"
                        >
                            <div class="price-filter">
                                <div class="left-price">
                                    <span> From </span>
                                    <b-form-input
                                        v-model="search.pricefrom"
                                    ></b-form-input>
                                </div>
                                <div class="right-price">
                                    <span> To </span>
                                    <b-form-input
                                        v-model="search.priceto"
                                    ></b-form-input>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    <div class="collapse-section">
                        <b-button
                            v-b-toggle.collapse-farming
                            variant="none"
                            class="button-collapse-search"
                        >
                            <div class="text">Mining Rare</div>
                            <div class="icons">
                                <img
                                    src="~@/assets/images/icons/drop-2.png"
                                    :style="`transform: rotate(${
                                        show.farming ? '180deg' : '0'
                                    })`"
                                    alt=""
                                />
                            </div>
                        </b-button>
                        <b-collapse
                            id="collapse-farming"
                            v-model="show.farming"
                            class="mt-2"
                        >
                            <div class="price-filter">
                                <div class="left-price">
                                    <span> From </span>
                                    <b-form-input
                                        v-model="search.farmingFrom"
                                    ></b-form-input>
                                </div>
                                <div class="right-price">
                                    <span> To </span>
                                    <b-form-input
                                        v-model="search.farmingTo"
                                    ></b-form-input>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    <div class="group-btn d-flex">
                        <b-button
                            variant="none"
                            class="button-search"
                            @click="onFilter('filter')"
                        >
                            Find Land
                        </b-button>
                        <b-button
                            variant="none"
                            class="button-reset"
                            @click="onFilter('reset')"
                        >
                            Reset Filter
                        </b-button>
                    </div>
                </div>
            </transition>
            <!-- CONTENT -->
            <div class="right-hero">
                <b-col cols="12">
                    <div class="sort-box">
                        <b-form-select
                            v-model="search.sortBy"
                            :options="SortBoxList"
                        ></b-form-select>
                        <img src="~@/assets/images/icons/drop.png" alt="" />
                    </div>
                </b-col>
                <b-col
                    cols="12"
                    class="box-name-2"
                    v-if="ListLandSelling.results.length <= 0"
                >
                    <div class="after">No Land Selling</div>
                </b-col>
                <div class="land-list" id="land-list-market" v-else>
                    <b-row>
                        <b-col
                            cols="12"
                            xl="4"
                            md="4"
                            lg="3"
                            sm="6"
                            class="col-xs-6"
                            v-for="(Land, idx) in ListLandSelling.results"
                            :key="`list-land-inventory-${Land.item.parentId}-${idx}`"
                        >
                            <div class="land-item">
                                <div class="item">
                                    <div
                                        class="id-land"
                                        ref="idHero"
                                        style="height: 25px"
                                    >
                                        #{{ Land.tokenId }}
                                    </div>

                                    <div class="land-img">
                                        <img
                                            :src="Land.item.image"
                                            v-if="Land.item.image"
                                            alt=""
                                        />
                                        <img
                                            v-else
                                            src="~@/assets/images/mockup/market/land.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        class="land-name mb-1"
                                        style="
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                        "
                                    >
                                        <div class="land-name-content">
                                            No.{{ Land.item._id }}
                                        </div>
                                    </div>
                                    <div class="land-process mt-2">
                                        <div class="loading-progress">
                                            <div class="progress-bg">
                                                <div
                                                    class="active-bg"
                                                    :style="`width:${
                                                        100 -
                                                        Land.item.restPoint
                                                    }%`"
                                                ></div>
                                            </div>
                                            <div
                                                class="progress-active"
                                                :class="
                                                    Land.item.isRecievedPoint
                                                        ? 'text-danger'
                                                        : ''
                                                "
                                            >
                                                Mining:
                                                {{
                                                    (
                                                        100 -
                                                        Land.item.restPoint
                                                    ).toFixed(4)
                                                }}
                                                / 100
                                            </div>
                                        </div>
                                    </div>
                                    <div class="land-price">
                                        <div class="left">Price:</div>
                                        <div class="right">
                                            <div class="hbg">
                                                {{
                                                    TruncateToDecimals2(
                                                        Land.price *
                                                            Balance.price,
                                                        '',
                                                        4,
                                                    )
                                                }}
                                                HBG
                                            </div>
                                            <div class="usd">
                                                $
                                                {{
                                                    TruncateToDecimals2(
                                                        Land.price,
                                                        '',
                                                        2,
                                                    )
                                                }}
                                                USD
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action">
                                        <MetaMask
                                            :isBuy="true"
                                            v-if="!isLogin"
                                        />

                                        <b-button
                                            class="cancel"
                                            @click="
                                                onPickLand(
                                                    'cancel',
                                                    Land.tokenId,
                                                )
                                            "
                                            v-else-if="
                                                Land.ownerAddress.toLowerCase() ===
                                                UserInfo.address.toLowerCase()
                                            "
                                        >
                                            Cancel
                                        </b-button>

                                        <b-button
                                            class="buy"
                                            @click="Approve(Land.tokenId)"
                                            v-else-if="!approve"
                                        >
                                            Approve
                                        </b-button>

                                        <b-button
                                            class="buy"
                                            @click="
                                                onPickLand('buy', Land.tokenId)
                                            "
                                            v-else-if="
                                                Land.ownerAddress.toLowerCase() !==
                                                UserInfo.address.toLowerCase()
                                            "
                                        >
                                            Buy
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <Paginate
                        @current="onChangePage"
                        :totalPages="ListLandSelling.totalPages"
                    />
                </div>
            </div>
        </b-container>
        <b-modal centered id="confirm-buy" title="Confirm Buy Hero" hide-footer>
            <div>
                <p class="mb-0 text-confirm">Do you want buy this Land?</p>
                <LandShow
                    v-if="DetailLand && DetailLand.land"
                    :landItem="DetailLand.land"
                    :onlyEarth="true"
                    class="mx-auto"
                ></LandShow>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="Approve(DetailLand.tokenId, DetailLand.price)"
                >
                    Confirm Buy
                </b-button>
            </div>
        </b-modal>
        <b-modal
            centered
            id="confirm-cancel"
            title="Confirm Buy Land"
            hide-footer
        >
            <div>
                <p class="mb-0 text-confirm">
                    Do you want to cancel sell this Land?
                </p>
                <LandShow
                    v-if="DetailLand && DetailLand.land"
                    :landItem="DetailLand.land"
                    :onlyEarth="true"
                    class="mx-auto"
                ></LandShow>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="onCancelLand(DetailLand.tokenId)"
                >
                    Cancel Now
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="modal-received"
            title="CONGRATULATION"
            :centered="true"
            footer-class="d-none"
            @hidden="
                () => {
                    this.$store.dispatch(
                        'market/req_getListLandSelling',
                        this.search,
                    );
                }
            "
        >
            <p class="my-3 text-normal">You have received:</p>
            <LandShow
                v-if="receivedLand"
                :landItem="receivedLand"
                :onlyEarth="true"
                class="mx-auto"
            ></LandShow>
            <b-button
                class="btn-confirm d-block mx-auto text-dark mb-3 rounded-0"
                @click="$bvModal.hide('modal-received')"
            >
                Confirm
            </b-button>
        </b-modal>
    </div>
</template>
<style lang="scss">
.land-market {
    .content-market {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: 1399px) {
            max-width: calc(100% - 10px);
        }
        .left-filter {
            flex: 0 0 375px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            height: auto;
            padding-bottom: 25px;
            margin-right: 25px;
            .title {
                background: #00ffff;
                height: 60px;
                color: #00172a;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2em;
                font-weight: 600;
                text-transform: uppercase;
            }
            .search-id {
                padding: 0 15px;
                margin-top: 25px;
                position: relative;
                input.form-control {
                    color: #fff;
                    font-weight: 600;
                    letter-spacing: 1px;
                    &::-webkit-input-placeholder {
                        color: #fff;
                        // opacity: 0.6;
                        text-align: left;
                        font-weight: 500;
                        font-size: 16px;
                        letter-spacing: 1px;
                        font-style: italic;
                    }
                    height: 40px;
                    border: 1px solid #00ffff;
                    background: linear-gradient(
                        180deg,
                        #18274a 0%,
                        #001326 100%
                    );
                    border-radius: 0;
                    padding-right: 50px;
                }
                button {
                    background: transparent;
                    width: 40px;
                    height: 40px;
                    padding: 5px;
                    border: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 25px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .collapse-section {
                padding: 0 15px;
                margin-top: 15px;
                .button-collapse-search {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    .text {
                        height: 40px;
                        width: calc(100% - 40px);
                        border: 1px solid #00ffff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #fff;
                        padding: 0 0.75rem;
                        font-style: italic;
                    }
                    .icons {
                        width: 40px;
                        height: 40px;
                        background: #00ffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .price-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                .left-price,
                .right-price {
                    display: flex;
                    align-items: center;
                    span {
                        color: #fff;
                        margin-right: 10px;
                        font-size: 1.2em;
                    }
                    input {
                        width: 75px;
                        height: 35px;
                        border-radius: 0;
                        border: 1px solid #00ffff;
                        color: #fff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                    }
                }
            }
            .class-filter {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0 20px;
                .item {
                    width: 50%;
                    padding: 0 5%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .icon {
                        width: 40px;
                        height: 40px;
                    }
                    .text {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1.2em;
                    }
                }
            }
            .level-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                .left-level,
                .right-level {
                    display: flex;
                    align-items: center;
                    span {
                        color: #fff;
                        margin-right: 10px;
                        font-size: 1.2em;
                    }
                    input {
                        width: 75px;
                        height: 35px;
                        border-radius: 0;
                        border: 1px solid #00ffff;
                        color: #fff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                    }
                }
            }
            .rarity-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                button {
                    color: #fff;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    &.active {
                        filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
                        transform: scale(1.2);
                    }
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .skin-filter {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0 20px;
                .item {
                    width: 50%;
                    padding: 0 5%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .icon {
                        width: 40px;
                        height: 40px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 100%;
                            height: auto;
                            max-height: 40px;
                            object-fit: scale-down;
                        }
                    }
                    .text {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1.2em;
                    }
                }
            }
            .stat-filter {
                .range-slider {
                    width: 300px;
                    margin: auto;
                    text-align: center;
                    position: relative;
                    height: 6em;
                }

                .range-slider input[type='range'] {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }

                input[type='number'] {
                    border: 1px solid #ddd;
                    text-align: center;
                    font-size: 1.6em;
                    -moz-appearance: textfield;
                }

                input[type='number']::-webkit-outer-spin-button,
                input[type='number']::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                input[type='number']:invalid,
                input[type='number']:out-of-range {
                    border: 2px solid #ff6347;
                }

                input[type='range'] {
                    -webkit-appearance: none;
                    width: 100%;
                }

                input[type='range']:focus {
                    outline: none;
                }

                input[type='range']:focus::-webkit-slider-runnable-track {
                    background: #2497e3;
                }

                input[type='range']:focus::-ms-fill-lower {
                    background: #2497e3;
                }

                input[type='range']:focus::-ms-fill-upper {
                    background: #2497e3;
                }

                input[type='range']::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 5px;
                    cursor: pointer;
                    animate: 0.2s;
                    background: #2497e3;
                    border-radius: 1px;
                    box-shadow: none;
                    border: 0;
                }

                input[type='range']::-webkit-slider-thumb {
                    z-index: 2;
                    position: relative;
                    box-shadow: 0px 0px 0px #000;
                    border: 1px solid #2497e3;
                    height: 18px;
                    width: 18px;
                    border-radius: 25px;
                    background: #a1d0ff;
                    cursor: pointer;
                    -webkit-appearance: none;
                    margin-top: -7px;
                }
            }
            @media (max-width: 1205px) {
                .title {
                    position: relative;
                }
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                max-width: 375px;
                height: 100%;
                bottom: 0;
                z-index: 1037;
            }
        }
        .right-hero {
            flex: 1 1 auto;
            .sort-box {
                display: flex;
                justify-content: flex-end;
                margin: 0 0 15px auto;
                padding-right: 5px;
                position: relative;
                max-width: 475px;
                .btn-box-buy-now {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    color: #002338;
                    min-width: 120px;
                    max-width: 150px;
                    width: max-content;
                    height: 40px;
                    font-weight: 700;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    box-shadow: 0 0 5px 2px #00b1ff;
                    border: 0;
                    @media (max-width: 576px) {
                        font-size: 15px;
                    }
                }
                select {
                    max-width: 275px;
                    height: 40px;
                    background: linear-gradient(
                        to right,
                        #00172a 0%,
                        #00000b 100%
                    );
                    color: #fff;
                    border-radius: 0;
                    border: 1px solid #00ffff;
                    option {
                        background: #00000b;
                    }
                }
                img {
                    height: 15px;
                    width: auto;
                    position: absolute;
                    right: 15px;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }
            }
        }
    }
    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 60px;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
    }
    .filter-active {
        position: fixed;
        left: 0;
        top: 175px;
        z-index: 1030;
        background: #00ffff;
        border-radius: 0;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 20px;
        width: 150px;
        .icons {
            i,
            svg {
                font-size: 16px;
            }
            margin: 0 10px;
        }
        .name {
            color: #000;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
        }
    }
    @media (max-width: 991px) {
        .filter-active {
            top: 90px;
        }
    }
    .close {
        background: #00ffff;
        width: 35px;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        height: 35px;
        justify-content: center;
        align-items: center;
        line-height: 0;
        color: #000;
        opacity: 1;
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        box-shadow: 5px 5px 5px black;
        display: none;
        @media (max-width: 1205px) {
            display: flex;
        }
    }
}
.land-list {
    .land-item {
        max-width: 275px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 575px) {
            max-width: 100%;
        }
        .item {
            width: 100%;
            max-width: 250px;
            margin-bottom: 30px;
            @media (max-width: 575px) {
                max-width: 175px;
                margin-bottom: 30px;
            }
            .id-land {
                color: #00ffff;
                font-size: 1.2em;
                font-weight: 800;
                margin-bottom: 0px;
                font-family: 'HHBI';
                line-height: 1.2em;
            }
            .land-img {
                border: 1px solid #00ff00;
                margin-bottom: 10px;
                box-shadow: 0px 0px 7px #00ff00;
                img {
                    width: 100%;
                    height: auto;
                    max-height: 160px;
                    object-fit: cover;
                }
            }
            .land-name {
                width: 100%;
                background-color: #00ff00;
                font-size: 20px;
                padding: 5px;
                line-height: 100%;
                color: #000000;
                text-decoration: none;
                font-family: 'HHBI';
                @media (max-width: 575px) {
                    font-size: 18px;
                }
                .land-name-content {
                    display: flex;
                    width: max-content;
                    height: max-content;
                }
            }
            .land-reward {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                color: #00ff00;
                text-shadow: 0px 0px 5px #00ff00;
                font-size: 16px;
                line-height: 100%;
                @media (max-width: 575px) {
                    font-size: 12px;
                }
                span {
                    font-size: 17px;
                    color: #ffffff;
                    @media (max-width: 575px) {
                        font-size: 15px;
                    }
                }
            }
            .land-process {
                width: 100%;
                text-align: center;
                margin-bottom: 5px;
                .loading-progress {
                    height: 30px;
                    width: 100%;
                    position: relative;
                    .progress-bg {
                        background: #33004d;
                        border: 1px solid #00ff00;
                        box-sizing: border-box;
                        height: 30px;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        .active-bg {
                            content: '';
                            background: #00ff0085;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            bottom: 0;
                            margin: auto;
                            width: 10%;
                            height: 30px;
                        }
                    }
                    .progress-active {
                        position: absolute;
                        top: 0;
                        left: 0px;
                        right: 0px;
                        bottom: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #f4f4f4;
                        font-size: 12px;
                        @media (max-width: 991px) {
                            font-size: 12px;
                        }
                        @media (max-width: 575px) {
                            font-size: 10px;
                        }
                    }
                }
            }
            .land-btn {
                display: flex;
                justify-content: space-between;
                button {
                    background: none;
                    border: none;
                    font-size: 13px;
                    padding: 10px 20px;
                    font-style: italic;
                    letter-spacing: 1px;
                    font-weight: 600;
                    font-family: 'HHBI';
                    @media (max-width: 575px) {
                        padding: 5px 10px;
                    }
                    &.btn-sell {
                        background: url('~@/assets/images/mockup/account/bgBtn-red.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    &.btn-price {
                        background: url('~@/assets/images/mockup/account/bgBtn-blue.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                        color: #000;
                        font-weight: 800;
                    }
                    &:nth-child(2) {
                        background: url('~@/assets/images/mockup/account/bgBtn-green.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
            }
        }
        .land-price {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            .left {
                flex: 0 0 25%;
                color: #ffff;
                font-weight: 600;
            }
            .right {
                flex: 0 0 75%;
                padding-left: 10px;

                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .hbg {
                    font-size: 1.15em;
                    font-weight: 900;
                    line-height: 120%;
                    font-family: 'Helvetica';
                    color: #00ffff;
                }
                .usd {
                    font-size: 0.8em;
                    font-weight: 900;
                    margin-left: 10px;
                    font-family: 'Helvetica';
                    color: #fff;
                }
            }
        }
        .action {
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                margin: 5px;
                &.buy {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    border-radius: 0;
                    width: auto;
                    padding: 5px 15px;
                    height: 45px;
                    color: #000;
                    font-weight: 600;
                    text-transform: capitalize;
                    min-width: 140px;
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &.cancel {
                    background: linear-gradient(
                        90deg,
                        #762525 0%,
                        #f92323 100%
                    );
                    border-radius: 0;
                    width: auto;
                    padding: 5px 15px;
                    height: 45px;
                    color: #fff;
                    font-weight: 600;
                    text-transform: capitalize;
                    min-width: 140px;
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid;
                    &:hover {
                        color: #fff !important;
                        border: 2px solid #fff !important;
                    }
                }
            }
        }
    }
}
#modal-received {
    .modal-dialog {
        @media (min-width: 768px) {
            max-width: 600px;
        }
    }

    .modal-content {
        background-image: url(~@/assets/images/mockup/market/bg-modal-market-congrat.png);
        background-size: 110% 110%;
        background-position: center center;
    }

    .modal-body {
        background: none;
        font-family: 'hemiheadRg-BoldItalic';
        color: #00ffff;

        .hero-name {
            font-size: 1.5em;
            text-shadow: 0px 0px 20px #00ffff;

            span {
                font-size: 2em;
                color: white;
            }
        }

        .box-img-modifer {
            width: 250px;
            margin: 0 auto;
            max-height: 250px;
            overflow: hidden;
        }

        .x1 {
            width: 75px;
            height: 50px;
            margin: 15px auto 30px;
            border: 1px solid #00ffff;

            font-size: 43px;
            text-shadow: 0px 0px 10px #00ffff;
            line-height: 50px;
            text-align: center;
        }

        .btn-confirm {
            background-color: #00ffff;
        }
    }
}
.text-normal {
    font-family: 'helvetica-neue-regular';
    color: white;
    font-size: 28px;
    text-align: center;
}

.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}
</style>
