<script>
import { mapGetters, mapMutations } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import { StartEffect, StopEffect } from '@/plugins/effect';

const axios = require('@/store/axios.config').default;

export default {
    components: { Carousel, Slide },
    data() {
        return {
            listReward: [],
            mysteryBox: {},
            infoHeight: '10px',
            resizeObserver: null,
            quantity: 1,
            quantityBuy: 1,
            quantityOpen: 1,
            approve: false,
            reward: 1,
            listShow: [],
            idxReward: 79,
            marginLeft: 0,
            showActive: false,
            nameReward: null,
            showConfig: {
                992: {
                    idx: 64,
                    margin: -6974,
                },
                576: {
                    idx: 56,
                    margin: -6150,
                },
                320: {
                    idx: 56,
                    margin: -6350,
                },
            },
            isOpen: false,
            backgroundImageActive: null,
            rewardList: [],
        };
    },
    computed: {
        ...mapGetters({
            ListBoxStore: 'shop/ListBoxStore',
            isLogin: 'auth/loggedIn',
            BalanceSystem: 'info/Balance',
        }),
    },
    watch: {},
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        Start() {
            StartEffect();
        },
        Stop() {
            StopEffect();
        },
        resetReward() {
            this.Stop();
        },
        resetBuy() {
            this.quantityBuy = 1;
            this.Stop();
        },
        async getDetail() {
            this.onLoad();
            const mysteryBox = await axios.get('api/shop/mistery-box');
            this.mysteryBox = mysteryBox;
            this.listReward = mysteryBox.items;
            this.generateReward();
            this.outLoad();
        },
        randomInt(min, max) {
            return Math.floor(Math.random() * (max - min)) + min;
        },
        async generateReward(reward) {
            const width = window.innerWidth;
            if (width >= 992) {
                this.idxReward = this.showConfig[992].idx;
                this.marginLeft = this.showConfig[992].margin;
            } else if (width >= 576) {
                this.idxReward = this.showConfig[576].idx;
                this.marginLeft = this.showConfig[576].margin;
            } else {
                this.idxReward = this.showConfig[320].idx;
                this.marginLeft = this.showConfig[320].margin;
            }
            const RollerContainer = this.$refs['roller-container'];
            if (!RollerContainer) {
                await this.sleep(1000);
                this.generateReward();
                return;
            }
            RollerContainer.style.transform = `transition: 'sdf'`;
            RollerContainer.style.marginLeft = `0px`;
            this.listShow = [];
            this.showActive = false;
            for (let i = 0; i < 101; i += 1) {
                let rangedArr = this.randomInt(0, this.listReward.length);
                let elm = {
                    idx: i,
                    id: `CardNumber${i}`,
                    ref: `CardNumber${i}`,
                    class: `item class_red_item`,
                    name: this.listReward[rangedArr].name,
                    style: `background-image:url(https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/mystery-box/${this.listReward[rangedArr].image}.png)`,
                };
                const ranged = this.randomInt(1, 1000);
                if (ranged < 50) {
                    rangedArr = this.randomInt(0, this.listReward.length);
                    elm = {
                        id: `CardNumber${i}`,
                        ref: `CardNumber${i}`,
                        class: `item class_red_item`,
                        name: this.listReward[rangedArr].name,
                        style: `background-image:url(https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/mystery-box/${this.listReward[rangedArr].image}.png)`,
                    };
                } else if (ranged > 500) {
                    rangedArr = this.randomInt(0, this.listReward.length);
                    elm = {
                        id: `CardNumber${i}`,
                        ref: `CardNumber${i}`,
                        class: `item class_red_item`,
                        name: this.listReward[rangedArr].name,
                        style: `background-image:url(https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/mystery-box/${this.listReward[rangedArr].image}.png)`,
                    };
                }
                this.listShow[i] = elm;
            }
            if (reward) {
                setTimeout(() => {
                    this.goRoll(this.reward);
                }, 1000);
            }
        },
        async onOpenBox() {
            if (this.isOpen) return;
            if (
                this.quantityOpen < 0 ||
                this.isNaN(this.quantityOpen) ||
                !this.quantityOpen
            ) {
                this.$toastr.w(
                    'Min open is 1, Please check your quantity open',
                    'Open Failed',
                );
                return;
            }
            if (this.quantityOpen > 50) {
                this.$toastr.w(
                    'Max open is 50, Please check your quantity open',
                    'Open Failed',
                );
                return;
            }
            this.isOpen = true;
            const itv = setInterval(() => {
                if (this.isOpen) {
                    this.generateReward();
                } else {
                    clearInterval(itv);
                }
            }, 500);
            this.$bvModal.hide('confirm-open');
            const data = await axios.post('api/box/open/multi/Mistery', {
                quantity: this.quantityOpen,
            });
            this.isOpen = false;
            if (data) {
                this.rewardList = data;
                this.$bvModal.show('OpenBox');
                this.$store.dispatch('info/req_getBalance');
            }
        },
        async goRoll(positionReward) {
            const reward = this.listReward[positionReward];
            const RollerContainer = this.$refs['roller-container'];
            RollerContainer.style.transform = `transition: 'all 8s cubic-bezier(.08,.6,0,1)'`;

            RollerContainer.style.marginLeft = `${
                this.marginLeft + this.randomInt(-41, 49)
            }px`;
            await this.sleep(1000);
            this.backgroundImageActive = `background-image:url(https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/mystery-box/${reward.image}.png)`;
            this.nameReward = reward.name;

            await this.sleep(7000);

            this.showActive = true;
            this.Start();
            this.$bvModal.show('mysteryReward');
            this.$store.dispatch('info/req_getBalance');
        },
        getBox(data) {
            return data.box;
        },
        onOpenReceiver() {
            this.$bvModal.hide('OpenBox');
            this.Start();
            this.$bvModal.show('mysteryReward');
            this.showCarousel = false;
            setTimeout(() => {
                this.$refs.carousel.onResize();
                this.$refs.carousel.goToPage(0);
            }, 200);
        },
    },
    async mounted() {
        this.getDetail();
    },

    beforeUnmount() {},
};
</script>
<template>
    <b-container class="box-detail">
        <b-row>
            <b-col cols="12" class="">
                <router-link
                    :to="{ name: 'Inventory', query: { active: 'item' } }"
                    class="back-to-shop"
                >
                    <div class="icons">
                        <img src="~@/assets/images/icons/back.png" alt="" />
                    </div>
                    <div class="text">Back</div>
                </router-link>
            </b-col>
            <b-col cols="12" lg="12" class="px-0">
                <b-row>
                    <b-col cols="12" class="p-0">
                        <b-row class="p-0 m-0">
                            <b-col cols="12">
                                <div class="box-name-mystery">
                                    <div class="before diamond">
                                        Mystery Box
                                    </div>
                                    <div class="after diamond">Mystery Box</div>
                                </div>
                            </b-col>
                            <b-col cols="12" class="open-mystery-box">
                                <div
                                    class="d-flex justify-content-around align-items-center"
                                    style="margin: 40px 0px 15px"
                                >
                                    <div class="left-title">
                                        <div
                                            class="box-mystery-subtitle sub-title2"
                                        >
                                            Available Box:
                                            <span class="text-diamond">
                                                {{ BalanceSystem.MisteryBox }}
                                                Box
                                            </span>
                                        </div>
                                    </div>
                                    <div class="right-title">
                                        <b-button
                                            class="btn-modal-info-mystery-box d-block"
                                            v-b-modal="'info-mystery-box'"
                                        >
                                            Help
                                        </b-button>
                                    </div>
                                </div>

                                <div class="raffle-roller" v-if="false">
                                    <div class="raffle-roller-holder">
                                        <div class="arrow">
                                            <img
                                                src="~@/assets/images/icons/arrow-up-filling.png"
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            class="raffle-roller-container"
                                            ref="roller-container"
                                            style="margin-left: 0px"
                                        >
                                            <div
                                                class="box-reward"
                                                :key="`reward-${idx}-${item.idx}`"
                                                v-for="(item, idx) in listShow"
                                                :class="[
                                                    item.class,
                                                    {
                                                        'winning-item':
                                                            showActive &&
                                                            idx == idxReward,
                                                    },
                                                    `idx-${idx}`,
                                                ]"
                                            >
                                                <div
                                                    class="avatar"
                                                    :ref="item.ref"
                                                    :style="`${
                                                        backgroundImageActive &&
                                                        idx == idxReward
                                                            ? backgroundImageActive
                                                            : item.style
                                                    }`"
                                                ></div>
                                                <div class="name">
                                                    {{
                                                        nameReward &&
                                                        idx == idxReward
                                                            ? nameReward
                                                            : item.name
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="action">
                                    <b-button
                                        @click="$bvModal.show('confirm-open')"
                                    >
                                        Open Mystery Box
                                    </b-button>
                                    <router-link
                                        :to="{ name: 'InGame' }"
                                        style="text-decoration: none !important"
                                    >
                                        <b-button variant="info">
                                            Buy Mystery
                                        </b-button>
                                    </router-link>
                                </div>
                            </b-col>
                            <b-col cols="12">
                                <div
                                    class="box-mystery-subtitle sub-title2 mt-5"
                                >
                                    Available Items
                                </div>
                                <div class="item-on-mystery">
                                    <b-row>
                                        <b-col
                                            cols="6"
                                            md="4"
                                            lg="3"
                                            xl="3"
                                            v-for="reward in listReward"
                                            :key="`reward-${reward.name}`"
                                        >
                                            <div class="item-hero">
                                                <div class="avatar-item">
                                                    <img
                                                        :class="reward.image"
                                                        :src="`https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/mystery-box/${reward.image}.png`"
                                                        alt=""
                                                    />
                                                </div>
                                                <div class="name-item">
                                                    {{ reward.name }}
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-modal
            id="confirm-open"
            title="Confirm Open Box"
            size="md"
            hide-footer
        >
            <div>
                <p class="mb-4 text-confirm">How many boxes do you need?</p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-row>
                    <b-col cols="12">
                        <img
                            :src="
                                require(`@/assets/images/mockup/shop/mystery.png?v=1`)
                            "
                            alt=""
                            class="d-sm-block mx-auto"
                            width="100%"
                            style="max-width: 245px"
                        />
                        <div class="d-flex flex-column input-opn">
                            <label for="inputAmount">Open Quantity</label>

                            <input
                                min="1"
                                max="50"
                                v-model.number="quantityOpen"
                                type="number"
                                id="inputAmount"
                                placeholder="Quantity"
                                class="main-bg"
                                step="1"
                                pattern="[0-9]{3}"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            />
                            <small class="mt-2 text-danger">
                                Maximum 50 box
                            </small>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div
                class="form-group d-flex justify-content-center mt-3 input-opn"
            >
                <b-button
                    v-if="true"
                    class="button-confirm my-2 mb-0"
                    variant="primary"
                    @click="onOpenBox"
                    :disabled="
                        quantityOpen > BalanceSystem.MisteryBox ||
                        quantityOpen > 50
                    "
                >
                    Open {{ quantityOpen }}
                </b-button>
                <b-button
                    v-else
                    class="button-confirm my-2 mb-0"
                    variant="primary"
                >
                    Coming Soon
                </b-button>
                <b-button
                    class="mx-auto my-2 mb-0 button-cancel"
                    variant="primary"
                    @click="$bvModal.hide('confirm-open')"
                >
                    Cancel
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="mysteryReward"
            title="Open Mystery Box Received"
            @hidden="resetReward"
            size="xl"
            hide-footer
        >
            <carousel
                ref="carousel"
                v-if="rewardList.length > 0"
                :key="`carousel-hero-receiver-${rewardList.length}`"
                :perPage="4"
                :mouseDrag="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                :autoplay="true"
                :autoplayTimeout="2000"
                :perPageCustom="[
                    [320, 1],
                    [600, 2],
                    [1024, 3],
                    [1200, 4],
                    [1800, 4],
                ]"
                :centerMode="true"
                :loop="false"
                navigation-next-label
                navigation-prev-label
            >
                <slide
                    v-for="(rewardItem, idx) in rewardList"
                    :key="`carousel-hero-receiver-#${idx}`"
                >
                    <div class="box">
                        <div class="box-img">
                            <img
                                :class="rewardItem.name.image"
                                :src="`https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/mystery-box/${rewardItem.name.image}.png`"
                                alt=""
                                class="d-sm-block mx-auto"
                                width="100%"
                            />
                        </div>
                        <div class="box-available box-name platinum">
                            <div>You Have Receive:</div>
                            <div>
                                <span>{{ rewardItem.name.name }} </span>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>

            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-3 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('mysteryReward')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="OpenBox"
            hide-header
            body-class="p-0"
            hide-footer
            modal-class="customer-open-box"
            no-close-on-backdrop
        >
            <video-background
                :src="require(`@/assets/video/mystery.mp4`)"
                class="video-cn"
                :loop="false"
                @ended="onOpenReceiver"
            >
            </video-background>
        </b-modal>
        <b-modal
            id="info-mystery-box"
            title="Information Mystery Box"
            hide-footer
        >
            <div>
                <p class="mb-0 text-confirm text-left mb-1">
                    1, Mystery Box is a special box that contains a random item.
                </p>
                <p class="mb-0 text-confirm text-left mb-1">
                    2, You can open Mystery Box by clicking the button below.
                </p>
                <p class="mb-0 text-confirm text-left mb-1">
                    3, You can buy Mystery Box in
                    <router-link :to="{ name: 'InGame' }"> Here </router-link>
                </p>
                <p class="mb-0 text-confirm text-left mb-1">
                    4, it may take you from 1 minute to 15 minutes to get the
                    hero or box rewards
                </p>
            </div>
            <b-button
                class="mx-auto mt-5 mb-0 button-confirm"
                variant="primary"
                @click="$bvModal.hide('info-mystery-box')"
            >
                confirm
            </b-button>
        </b-modal>
    </b-container>
</template>
<style lang="scss">
$box-max-width: 375px;
$img-max-width: calc(375px * 0.9);
$price-max-width: calc(375px * 0.8);
$price-max-height: calc(375px / 10 * 1.3);
$liner-gradient-color: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
$button-max-width: calc(375px * 0.6);
$button-max-height: calc(375px / 10 * 1.5);
.customer-open-box {
    .modal-dialog {
        // max-width: 320px;

        .modal-content {
            border-radius: 5px;
        }
        .video-cn {
            width: 100%;
            position: relative;
            padding-bottom: 74.69%; /* ratio 16/9 */
        }
    }
}
#mysteryReward___BV_modal_outer_,
#mysteryReceive___BV_modal_outer_ {
    z-index: unset !important;
}
#mysteryReward,
#mysteryReceive {
    .VueCarousel-navigation-next {
        right: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        outline: none;
        opacity: 1 !important;
    }
    .VueCarousel-navigation-prev {
        left: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
        outline: none;
    }
    .VueCarousel-inner {
        visibility: visible !important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    .box-i,
    .box {
        max-width: 200px;
        width: 100%;
        padding: 5px 0 15px;
        margin: 0 auto 5px;
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            width: 100%;
            overflow: hidden;
            text-align: center;
            position: relative;
            max-width: 200px;
            margin: 10px auto;
            min-height: 200px;
            display: flex;
            align-items: flex-end;
            img {
                width: 100%;
                height: 100%;
                max-width: 130px;
                object-fit: scale-down;
                &.diamond,
                &.silver,
                &.platinum,
                &.gold {
                    max-width: 150px;
                }
                &.hbg {
                    max-width: 110px;
                }
                object-fit: scale-down;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.2em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
    }
}
#info-mystery-box {
    .text-confirm {
        font-size: 1.2em;
        margin: 0px;
        p {
            margin-bottom: 5px;
        }
    }
}
.text-diamond {
    font-size: 1em;
    text-align: center;
    margin: 10px auto;
    font-family: 'HHBI';
    text-transform: uppercase;
    min-height: unset;
    color: #ff00ff;
    text-shadow: 1px 0px 2px #ff00ff;
}
.input-opn {
    max-width: 350px;
    margin: 10px auto;
    input {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    label {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
    }
    small {
        font-size: 15px;
        color: #fff;
        margin-bottom: 0;
    }
}
.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}
.back-to-shop {
    margin: 40px 0 20px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    .icons {
        img {
            width: 40px;
            height: auto;
        }
    }
    .text {
        font-family: 'HHBI';
        color: #00ffff;
        font-size: 27px;
        margin-left: 15px;
    }
    @media (max-width: 575px) {
        .text {
            font-size: 20px;
        }
        margin-bottom: 30px;
    }
}

.box-name-mystery {
    position: relative;
    margin-top: 5px;
    &.title {
        @media (min-width: 992px) {
            min-height: 140px;
        }
    }

    .before {
        -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 0.5px;
        font-family: 'HHBI';

        font-size: clamp(50px, 6vw, 80px);
        line-height: 120%;
        text-transform: uppercase;
        text-align: center;
        &.diamond {
            -webkit-text-stroke-color: #ff00ff;
            opacity: 1;
        }
        &.gold {
            -webkit-text-stroke-color: #f3ff00;
            opacity: 0.6;
        }
        &.silver {
            -webkit-text-stroke-color: #98d7e0;
            opacity: 0.6;
        }
        &.platinum {
            -webkit-text-stroke-color: #4effff;
            opacity: 0.4;
        }
        &.herobox {
            color: #fff;
            text-shadow: 1px 0px 9px #ff00ff;
        }
    }
    .after {
        font-size: clamp(40px, 4vw, 70px);
        font-family: 'HHBI';
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: auto;
        text-transform: uppercase;
        width: max-content;
        &.diamond {
            color: #ff00ff;
            text-shadow: 1px 0px 9px #ff00ff;
        }
        &.gold {
            color: #f3ff00;
            text-shadow: 1px 0px 9px #f3ff00;
        }
        &.silver {
            color: #98d7e0;
            text-shadow: 1px 0px 9px #98d7e0;
        }
        &.platinum {
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
    }
    @media (max-width: 991px) {
        margin-bottom: 10px;
        .mobile {
            display: block;
        }
        .before {
            text-align: center;
            margin: auto;
        }
        .after {
            left: 0;
            right: 0;
            margin: 0 auto;
            width: max-content;
        }
    }
    @media (max-width: 991px) {
        .before {
            font-size: clamp(70px, 5.5vw, 120px);
            opacity: 0.3;
        }
        .after {
            font-size: clamp(40px, 3vw, 75px);
        }
    }
    @media (max-width: 575px) {
        .before {
            font-size: clamp(55px, 5.5vw, 120px);
            opacity: 0.3;
        }
        .after {
            font-size: clamp(30px, 3vw, 65px);
        }
    }
    @media (max-width: 424px) {
        .before {
            font-size: clamp(45px, 5.5vw, 120px);
            opacity: 0.3;
        }
        .after {
            font-size: clamp(25px, 3vw, 65px);
        }
    }
    @media (max-width: 374px) {
        .before {
            font-size: clamp(40px, 5.5vw, 120px);
            opacity: 0.3;
        }
        .after {
            bottom: -10px;
            font-size: clamp(30px, 3vw, 65px);
        }
    }
}
.box-mystery-subtitle {
    color: #4effff;
    font-size: clamp(1em, 4vw, 1.5em);
    max-width: 991px;
    line-height: 120%;
    margin: auto;
    text-align: center;
    font-family: 'HHBI';
    &.sub-title2 {
        text-align: left;
        position: relative;
        width: max-content;
        font-size: clamp(1em, 4vw, 2em);
        text-transform: uppercase;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }
}
.btn-modal-info-mystery-box {
    background: #00ffff;
    width: 50px;
    height: 30px;
    border: 0;
    border-radius: 5px;
    padding: 0;
    z-index: 10;
    color: black;
    font-weight: bold;
    font-family: 'HHBI';
}
.box-mystery-img {
    width: 100%;
    max-width: 275px;
    margin: 15px auto 0;
    position: relative;
    img {
        width: 100%;
        z-index: 10;
        position: relative;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
        object-fit: scale-down;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
        z-index: 0;
        animation: light 2s infinite linear;
    }
}
.item-on-mystery {
    border: 1px solid #00ffff;
    background: linear-gradient(180deg, #18274a 0%, #001326 100%);
    width: 100%;
    min-height: 300px;
    padding: 15px;
    max-height: 400px;
    overflow: {
        x: hidden;
        y: auto;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        // background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 10px;
        background-color: #18274a;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background-color: #00ffff;
    }
    .item-hero {
        width: 100%;
        border: 1px solid #00b1ff;
        min-height: 155px;
        position: relative;
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .avatar-item {
            width: 100%;
            overflow: hidden;
            text-align: center;
            margin-top: 10px;
            img {
                width: 100%;
                height: 100%;
                max-width: 110px;
                object-fit: scale-down;
                &.diamond,
                &.silver,
                &.platinum,
                &.gold {
                    max-width: 130px;
                }
                &.hbg {
                    max-width: 90px;
                }
            }
        }
        .name-item {
            color: #4effff;
            font-size: 18px;
            line-height: 120%;
            text-align: center;
            font-family: 'HHBI';
            margin-bottom: 5px;
        }
        .percent-item {
            position: absolute;
            top: -15px;
            left: 0;
            right: 0;
            margin: auto;
            background: #4effff;
            width: 100px;
            height: 25px;
            border-radius: 15px;
            text-align: center;
            font-size: clamp(1em, 4vw, 1.5em);
            line-height: 120%;
            text-align: center;
            font-family: 'HHBI';
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.box-mystery {
    width: 100%;
    max-width: 500px;
    margin: auto;
    display: flex;
    .box-img {
        position: relative;
        width: 100%;
        max-width: 250px;
        min-width: 175px;
    }
    .box-content {
        background: {
            image: url('~@/assets/images/mockup/shop/content.png');
            size: 100% 100%;
            position: bottom center;
            repeat: no-repeat;
        }
        margin-top: 5px;
        width: 100%;
        min-height: 300px;
        padding: 15px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .action {
            display: flex;
            flex-direction: column;
            justify-content: center;
            button {
                background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
                border-radius: 0;
                width: 100%;
                min-width: 90px;
                height: 45px;
                padding: 5px;
                color: #000;
                font-weight: 600;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 15px auto 0;
                font-family: 'HHBI';
                font-size: 20px;
            }
        }
    }
}
.open-mystery-box {
    .raffle-roller {
        height: 100px;
        position: relative;
        margin: 30px auto 30px auto;
        width: 300px;
        @media (min-width: 576px) {
            width: 500px;
        }
        @media (min-width: 768px) {
            width: 700px;
        }
        @media (min-width: 992px) {
            width: 620px;
        }
        @media (min-width: 1440px) {
            width: 900px;
        }
    }
    .raffle-roller-holder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100px;
        width: 100%;
    }
    .raffle-roller-holder {
        overflow: hidden;
        border-radius: 2px;
        border: 1px solid #00ffff;
    }
    .raffle-roller-holder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 120px;
        width: 100%;
    }
    .raffle-roller-container {
        width: 9999999999999999999px;
        max-width: 999999999999999999px;
        height: 120px;
        background: linear-gradient(180deg, #18274a 0%, #001326 100%);
        margin-left: 0;
        transition: all 8s cubic-bezier(0.08, 0.6, 0, 1);
    }
    .raffle-roller-holder {
        .arrow {
            position: absolute;
            border: none;
            z-index: 1040;
            width: 40px;
            height: 20px;
            bottom: 10px;
            left: calc(50% - 20px);
            img {
                width: 100%;
            }
        }
    }
    .item {
        display: inline-block;
        float: left;
        margin: 4px 0px 0.5px 5px;
        width: 110px;
        height: 110px;
        border: 1px solid #70677c;
        background: #14202b;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 10px 0;
        .avatar {
            width: 50px;
            height: 55px;
            margin: 0 auto 5px;
            background-size: contain !important;
            background-repeat: no-repeat;
            background-position: center center;
        }
        .name {
            color: #00ffff;
            font-family: 'HHBI';
            line-height: 120%;
            text-align: center;
        }
    }
    .class_red_item {
        border-bottom: 4px solid #eb4b4b;
    }
    img {
        border: 0;
        vertical-align: middle;
    }
    .winning-item {
        border: 2px solid #66b233;
        position: relative;
        top: -1px;
        border-bottom: 4px solid #66b233;
    }
    .inventory {
        margin: 0 auto;
        width: 960px;
        max-width: 953px;
        padding: 10px 15px 6px;
        height: auto;
        border: 2px solid #1c3344;
        background: #0e1a23;
    }
    .inventory > .item {
        float: none;
        cursor: pointer;
        margin: 4px 2px 0.5px 2px;
    }
    .inventory > .item:hover {
        background-size: 90%;
        background-color: #182a38;
    }
    .inventory > .item:active {
        height: 83px;
        width: 80px;
        position: relative;
        top: -2px;
        border: 2px solid #356d27;
        border-bottom: 4px solid #356d27;
    }
    .action {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        button {
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border-radius: 0;
            width: 100%;
            min-width: 175px;
            max-width: 250px;
            height: 45px;
            padding: 5px;
            color: #000;
            font-weight: 600;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px 5px 0;
            font-family: 'HHBI';
            font-size: 20px;
        }
        a {
            button {
                background: #001326;
                color: #fff;
                border: 1px solid #fff;
            }
        }
    }
}
</style>
