export const listBox = [
    {
        id: 1,
        img: 'silver.png',
        name: 'Silver Box',
        short: 'silver',
        quantity: 0,
    },
    {
        id: 2,
        img: 'gold.png',
        name: 'Gold Box',
        short: 'gold',
        quantity: 0,
    },
    {
        id: 3,
        img: 'platinum.png',
        name: 'Platinum Box',
        short: 'platinum',
        quantity: 0,
    },
    {
        id: 4,
        img: 'diamond.png',
        name: 'Diamond Box',
        short: 'diamond',
        quantity: 0,
    },
];

export const listHero = [];
