const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        platformList: [],
        itemPopular: {
            totalItem: 1,
            totalPages: 1,
            limit: 1,
            items: [],
        },
        itemNewly: {
            totalItem: 1,
            totalPages: 1,
            limit: 1,
            items: [],
        },
        itemSold: {
            totalItem: 1,
            totalPages: 1,
            limit: 1,
            items: [],
        },
        userSellList: {
            totalItem: 1,
            totalPages: 1,
            limit: 1,
            items: [],
        },
        userSoldList: {
            totalItem: 1,
            totalPages: 1,
            limit: 1,
            items: [],
        },
    }),
    getters: {
        PlatformList: (state) => state.platformList,
        ItemPopular: (state) => state.itemPopular,
        ItemNewly: (state) => state.itemNewly,
        ItemSold: (state) => state.itemSold,
        UserSellList: (state) => state.userSellList,
        UserSoldList: (state) => state.userSoldList,
    },
    actions: {
        // Get platform hero hub
        req_getPlatform: ({ commit }) => {
            axios.get(`/api/herocoin/get-all-platform`).then((response) => {
                if (response) {
                    commit('SET_LIST_PLATFORM', response);
                }
            });
        },
        // Get list item of hero coin
        req_getPopularList: ({ commit }, input) => {
            axios
                .get('/api/herocoin/popular-list', { params: input })
                .then((response) => {
                    commit('SET_LIST_POPULAR', response);
                });
        },
        req_getNewlyList: ({ commit }, input) => {
            axios
                .get('/api/herocoin/newly-list', { params: input })
                .then((response) => {
                    commit('SET_LIST_NEWLY', response);
                });
        },
        req_getSoldList: ({ commit }, input) => {
            axios
                .get('/api/herocoin/sold-list', { params: input })
                .then((response) => {
                    commit('SET_LIST_SOLD', response);
                });
        },

        // User in hub
        req_getUserSellList: ({ commit }, input) => {
            axios
                .get('/api/herocoin/my-list-active', { params: input })
                .then((response) => {
                    commit('SET_USER_SELL_LIST', response);
                });
        },
        req_getUserSoldList: ({ commit }, input) => {
            axios
                .get('/api/herocoin/my-list-history', { params: input })
                .then((response) => {
                    commit('SET_USER_SOLD_LIST', response);
                });
        },
    },
    mutations: {
        SET_LIST_PLATFORM(state, data) {
            state.platformList = data;
        },
        SET_LIST_POPULAR(state, data) {
            const { limit, totalPages, totalResults, results } = data;
            state.itemPopular = {
                totalItem: totalResults,
                totalPages,
                limit,
                items: results,
            };
        },
        SET_LIST_NEWLY(state, data) {
            const { limit, totalPages, totalResults, results } = data;
            state.itemNewly = {
                totalItem: totalResults,
                totalPages,
                limit,
                items: results,
            };
        },
        SET_LIST_SOLD(state, data) {
            const { limit, totalPages, totalResults, results } = data;
            state.itemSold = {
                totalItem: totalResults,
                totalPages,
                limit,
                items: results,
            };
        },
        SET_USER_SELL_LIST(state, data) {
            const { limit, totalPages, totalResults, results } = data;
            state.userSellList = {
                totalItem: totalResults,
                totalPages,
                limit,
                items: results,
            };
        },
        SET_USER_SOLD_LIST(state, data) {
            const { limit, totalPages, totalResults, results } = data;
            state.userSoldList = {
                totalItem: totalResults,
                totalPages,
                limit,
                items: results,
            };
        },
    },
};
