// eslint-disable-next-line import/prefer-default-export
export const MARKET_ABI = [
    {
        inputs: [
            { internalType: 'address', name: '_HGB', type: 'address' },
            {
                internalType: 'address',
                name: '_LandNFTAddress',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_HeroNFTAddress',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'Bid',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
            },
        ],
        name: 'BuyHero',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
            },
        ],
        name: 'BuyLand',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'dayAfter',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'startPrice',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'bigLandId',
                type: 'uint256',
            },
        ],
        name: 'NewAuctionCreated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'TokenID',
                type: 'uint256',
            },
        ],
        name: 'OnCancelHeroSale',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'TokenID',
                type: 'uint256',
            },
        ],
        name: 'OnCancelLandSale',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'TokenID',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
            },
        ],
        name: 'OnSellHero',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'TokenID',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'price',
                type: 'uint256',
            },
        ],
        name: 'OnSellLand',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address',
            },
        ],
        name: 'WithdrawReward',
        type: 'event',
    },
    {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'AuctionByTokenId',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'HGB',
        outputs: [
            { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'HeroAssets',
        outputs: [
            { internalType: 'uint256', name: 'tokenID', type: 'uint256' },
            { internalType: 'uint256', name: 'price', type: 'uint256' },
            { internalType: 'bool', name: 'onSell', type: 'bool' },
            { internalType: 'address', name: 'owner', type: 'address' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'HeroNFT',
        outputs: [
            { internalType: 'contract IERC721', name: '', type: 'address' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'LandAssets',
        outputs: [
            { internalType: 'uint256', name: 'tokenID', type: 'uint256' },
            { internalType: 'uint256', name: 'price', type: 'uint256' },
            { internalType: 'bool', name: 'onSell', type: 'bool' },
            { internalType: 'address', name: 'owner', type: 'address' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'LandNFT',
        outputs: [
            { internalType: 'contract IERC721', name: '', type: 'address' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'uint256', name: '_auctionId', type: 'uint256' },
        ],
        name: 'bid',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'TokenID', type: 'uint256' }],
        name: 'buyHero',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'TokenID', type: 'uint256' }],
        name: 'buyLand',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_auctionId', type: 'uint256' },
        ],
        name: 'cancelAuction',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'TokenID', type: 'uint256' }],
        name: 'cancelHeroSale',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'TokenID', type: 'uint256' }],
        name: 'cancelLandSale',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_newOwner', type: 'address' },
        ],
        name: 'changeOwner',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'newPer', type: 'uint256' }],
        name: 'changePercentage',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'newHGB', type: 'address' },
            { internalType: 'address', name: 'newLand', type: 'address' },
            { internalType: 'address', name: 'newHero', type: 'address' },
        ],
        name: 'changeToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'checkContractBalance',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256[]', name: 'arr', type: 'uint256[]' }],
        name: 'checkOwnerBigLand',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_dayAfter', type: 'uint256' },
            { internalType: 'uint256', name: '_startPrice', type: 'uint256' },
            { internalType: 'uint256', name: '_bigLandID', type: 'uint256' },
            { internalType: 'uint256', name: '_priceStep', type: 'uint256' },
            {
                internalType: 'uint256[]',
                name: 'smallIdTokens',
                type: 'uint256[]',
            },
        ],
        name: 'createBigLandAuction',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_auctionId', type: 'uint256' },
        ],
        name: 'forceEnd',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'getAddressOwner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'price', type: 'uint256' }],
        name: 'getAfterCommissionPrice',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_auctionId', type: 'uint256' },
        ],
        name: 'getAuctionById',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'bigLandId',
                        type: 'uint256',
                    },
                    { internalType: 'uint256', name: 'endAt', type: 'uint256' },
                    { internalType: 'bool', name: 'started', type: 'bool' },
                    { internalType: 'bool', name: 'ended', type: 'bool' },
                    {
                        internalType: 'uint256',
                        name: 'startPrice',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'priceStep',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'highestBid',
                        type: 'uint256',
                    },
                    {
                        internalType: 'address',
                        name: 'bidders',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256[]',
                        name: 'smallLandId',
                        type: 'uint256[]',
                    },
                ],
                internalType: 'struct Marketplace.Auction',
                name: '',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'price', type: 'uint256' }],
        name: 'getCommissionPrice',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'nftId',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'TokenID', type: 'uint256' },
            { internalType: 'uint256', name: 'price', type: 'uint256' },
        ],
        name: 'sellHero',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'TokenID', type: 'uint256' },
            { internalType: 'uint256', name: 'price', type: 'uint256' },
        ],
        name: 'sellLand',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'i', type: 'uint256' }],
        name: 'toString',
        outputs: [{ internalType: 'string', name: 'a', type: 'string' }],
        stateMutability: 'pure',
        type: 'function',
    },
    {
        inputs: [],
        name: 'withdrawAssetFromContract',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: '_auctionId', type: 'uint256' },
        ],
        name: 'withdrawReward',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
