const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        listHero: {
            list: [],
            current: 1,
            total: 1,
        },
    }),
    getters: {
        ListHero: (state) => state.listHero,
    },
    actions: {
        req_postTransferSuccess: ({ commit }, input) => {
            axios.post('api/market/order/transfer/hero', input).then(() => {
                commit('ACTION_SUCCESS');
            });
        },
        req_postTransferFree: ({ commit }, input) => {
            axios.post('api/hero/transfer/free', input).then(() => {
                commit('ACTION_SUCCESS');
            });
        },
        req_postSellSuccess: ({ commit }, input) => {
            axios
                .post('api/market/order/hero/send-transaction', input)
                .then(() => {
                    commit('ACTION_SUCCESS');
                });
        },
        req_postCancelSuccess: ({ commit }, input) => {
            axios.post('api/market/order/sell/hero/cancel', input).then(() => {
                commit('ACTION_SUCCESS');
            });
        },
        req_postCancelOldSuccess: ({ commit }, input) => {
            axios
                .post('api/market/order/sell/hero/cancel/old', input)
                .then(() => {
                    commit('ACTION_SUCCESS');
                });
        },
        req_getListHero: ({ commit }, input) => {
            axios
                .get('api/hero/user-hero', {
                    params: input,
                })
                .then((response) => {
                    commit('SET_LIST_HERO', response);
                });
        },
    },
    mutations: {
        SET_LIST_HERO(state, data) {
            const { results, page, totalPages, totalResults } = data;
            state.listHero = {
                list: results,
                current: page,
                total: totalPages,
                totalHero: totalResults,
            };
        },
        ACTION_SUCCESS() {},
    },
};
