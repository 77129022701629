<script>
export default {
    props: {
        landItem: {
            type: Object,
        },
        onlyEarth: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            priceSell: 0,
        };
    },
    methods: {
        callBackEvt(typeCall, land) {
            switch (typeCall) {
                case 'sell':
                    this.$emit('onSell', land);
                    break;
                case 'transfer':
                    this.$emit('onTransfer', land);
                    break;
                case 'cancel':
                    this.$emit('onCancel', land);
                    break;
                default:
                    break;
            }
        },
        changeIDLand(id) {
            const newID = id.split('_');
            return `${newID[0]}${newID[1]}`;
        },
    },
};
</script>
<template>
    <div class="land-item mt-3">
        <div class="inventory-box-item">
            <div class="inventory-item-image">
                <img
                    v-if="landItem.image"
                    :src="landItem.image"
                    alt=""
                    class=""
                />
                <img
                    class="inventory-item-image"
                    v-else
                    src="~@/assets/images/mockup/market/land.jpg"
                    alt=""
                />
            </div>
            <div class="inventory-box-item-content">
                <div class="d-flex align-items-center">
                    <img
                        src="../../assets/images/icons/icon-title-land.svg"
                        alt=""
                    />

                    <div class="land-name-content">
                        No.{{ changeIDLand(landItem.id) }}
                    </div>
                </div>
                <hr />
                <div class="land-item-information">
                    <div class="row">
                        <div class="title col-5">Token ID</div>
                        <div class="title col-7">
                            {{
                                landItem.isNFT
                                    ? `#${landItem.tokenId}`
                                    : `FREE${landItem.tokenId}`
                            }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="title col-5">Farming</div>
                        <div class="title col-7">
                            {{ (100 - landItem.restPoint).toFixed(2) }}% / 100%
                        </div>
                    </div>
                    <div class="row">
                        <div class="title col-5">Price</div>
                        <div class="title price col-7">
                            {{
                                TruncateToDecimals2(landItem.price || 0, '', 2)
                            }}
                            HBG
                        </div>
                    </div>
                </div>
                <div class="inventory-item-line">
                    <img
                        src="../../assets/images/icons/icon-line-svg.svg"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
.land-item {
    font-family: 'Poppins';
    flex: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 575px) {
        max-width: 100%;
    }
    .inventory-box-item {
        .inventory-item-image {
            width: 150px;
            height: 150px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 1px solid #00ffff;
            }
        }
        .land-name-content {
            font-weight: 600;
            font-size: 24px;
            color: #f8f8f8;
            padding-left: 25px;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .land-item-information {
            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #f8f8f8;
                padding: 8px 1rem;
            }
            .price {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #00ffff;
            }
        }
    }
}
</style>
