<script>
import { mapGetters } from 'vuex';
import { orderBuy, orderSell } from '@/store/modules/exchange';

export default {
    props: {
        showHeader: {
            type: Boolean,
            default: false,
        },
        hiddenHeader: {
            type: Boolean,
            default: false,
        },
        coin: {
            type: Object,
            default: null,
        },
        tabActive: {
            type: String,
            default: '',
        },
        itemPick: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            orderActive: 'isBuy',
            infoSell: {
                amount: 0,
                price: 0.0,
            },
            infoBuy: {
                amount: 0,
                price: 0.0,
            },
        };
    },
    watch: {
        tabActive: {
            handler(newVal) {
                if (newVal === 'isBuy' || newVal === 'isSell') {
                    this.orderActive = newVal;
                }
            },
        },
        itemPick: {
            handler(newVal) {
                this.calculatorAmount({
                    type: newVal.type,
                    energy: newVal.total,
                    hbg: newVal.hbg,
                    price: newVal.price,
                });
            },
        },
    },
    computed: {
        ...mapGetters({
            BalanceSystem: 'info/Balance',
        }),
    },
    methods: {
        async onChoseAmount(type, percent) {
            const balance = this.BalanceSystem[this.coin.item];
            const { HBG } = this.BalanceSystem;
            if (type === 'Sell') {
                this.infoSell.amount = balance * percent;
            } else {
                this.infoBuy.amount = parseInt(
                    (HBG / this.infoBuy.price) * percent,
                    10,
                );
            }
        },
        async onBuySell(type) {
            // if (1 !== 2) {
            //     this.$toastr.w(
            //         'Energy Trading Is ComingSoon, Please Come Back Later',
            //         'ComingSoon',
            //     );
            //     return;
            // }
            if (!type) {
                this.$toastr.e('Error', 'Please try again');
            }

            if (type === 'Sell') {
                const { amount, price } = this.infoSell;
                if (
                    !amount ||
                    this.isNaN(amount) ||
                    !price ||
                    this.isNaN(price)
                ) {
                    this.$toastr.e('Error', 'Please try again');
                    return;
                }

                await orderSell({
                    idCoin: this.coin.id,
                    amount,
                    price,
                });
            } else {
                const { amount, price } = this.infoBuy;

                if (
                    !amount ||
                    this.isNaN(amount) ||
                    !price ||
                    this.isNaN(price)
                ) {
                    this.$toastr.e('Error', 'Please try again');
                    return;
                }

                await orderBuy({
                    idCoin: this.coin.id,
                    amount,
                    price,
                });
            }
            this.$emit('getListTrading', true);
        },
        calculatorAmount({ price, energy, hbg, type }) {
            const { HBG } = this.BalanceSystem;
            const BalanceEnergy = this.BalanceSystem[this.coin.item];
            if (type === 'buy') {
                this.infoBuy.price = price;
                if (HBG >= hbg) {
                    this.infoBuy.amount = energy;
                } else {
                    this.infoBuy.amount = parseInt(HBG / price - 1, 10);
                }
            }
            if (type === 'sell') {
                this.infoSell.price = price;
                if (BalanceEnergy >= energy) {
                    this.infoSell.amount = energy;
                } else {
                    this.infoSell.amount = BalanceEnergy;
                }
            }

            //     if (type === 'sell') {
            //         this.infoSell.amount = energy;
            //     } else {
            //         this.infoBuy.amount = energy;
            //     }
            // } else if (type === 'sell') {
            //     this.infoSell.amount = parseInt(HBG / price - 1, 10);
            // } else {
            //     this.infoBuy.amount = parseInt(HBG / price - 1, 10);
            // }

            // if (type === 'sell') {
            //     this.infoSell.price = price;
            // } else {
            //     this.infoBuy.price = price;
            // }
        },
    },
    mounted() {
        if (this.tabActive === 'isBuy' || this.tabActive === 'isSell') {
            this.orderActive = this.tabActive;
        }
    },
};
</script>
<template>
    <div class="form-trade">
        <div class="trade">
            <div class="trade-header" v-if="showHeader && !hiddenHeader">
                <b-button
                    class="trade-header-item"
                    variant="none"
                    :class="{ active: orderActive == 'isBuy' }"
                    @click="orderActive = 'isBuy'"
                >
                    Order Buy
                </b-button>

                <b-button
                    class="trade-header-item"
                    variant="none"
                    :class="{ active: orderActive == 'isSell' }"
                    @click="orderActive = 'isSell'"
                >
                    Order Sell
                </b-button>
            </div>
            <div class="trade-body">
                <div
                    class="trade-body-buy"
                    v-if="!showHeader || orderActive == 'isBuy'"
                >
                    <div class="form-trade">
                        <div class="label-balance">
                            <div class="label-balance-left">Avbl</div>
                            <div class="label-balance-right">
                                {{
                                    TruncateToDecimals2(
                                        BalanceSystem['HBG'] || 0,
                                        '',
                                        2,
                                    )
                                }}
                                HBG
                            </div>
                        </div>
                        <b-input-group append="HBG" prepend="Price">
                            <b-form-input
                                type="number"
                                placeholder="Price Heronium"
                                v-model.number="infoBuy.price"
                            ></b-form-input>
                        </b-input-group>
                    </div>
                    <div class="form-trade">
                        <div class="label-balance"></div>
                        <b-input-group append="HRN" prepend="Amount">
                            <b-form-input
                                type="number"
                                placeholder="Amount Heronium"
                                v-model.number="infoBuy.amount"
                            ></b-form-input>
                        </b-input-group>
                    </div>
                    <div class="suggest-trade">
                        <b-button
                            @click="onChoseAmount('Buy', 0.25)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            25%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Buy', 0.5)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            50%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Buy', 0.75)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            75%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Buy', 1)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            100%
                        </b-button>
                    </div>
                    <div class="form-trade mt-3">
                        <div class="label-balance"></div>
                        <b-input-group append="HBG" prepend="Total">
                            <b-form-input
                                type="text"
                                readonly
                                :value="
                                    TruncateToDecimals2(
                                        infoBuy.amount * infoBuy.price,
                                        '',
                                        4,
                                    )
                                "
                            ></b-form-input>
                        </b-input-group>
                    </div>
                    <div
                        class="form-trade d-flex justify-content-center align-items-center"
                    >
                        <b-button
                            variant="none"
                            class="button-trade buy"
                            :disabled="
                                infoBuy.price <= 0 || infoBuy.amount <= 0
                            "
                            @click.prevent="onBuySell('Buy')"
                        >
                            Buy
                        </b-button>
                    </div>
                </div>
                <div
                    class="trade-body-sell"
                    v-if="!showHeader || orderActive == 'isSell'"
                >
                    <div class="form-trade">
                        <div class="label-balance">
                            <div class="label-balance-left">Avbl</div>
                            <div class="label-balance-right" v-if="coin">
                                {{
                                    TruncateToDecimals2(
                                        BalanceSystem[coin.item] || 0,
                                        '',
                                        2,
                                    )
                                }}
                                HRN
                            </div>
                        </div>
                        <b-input-group append="HBG" prepend="Price">
                            <b-form-input
                                type="number"
                                placeholder="Price Heronium"
                                v-model.number="infoSell.price"
                            ></b-form-input>
                        </b-input-group>
                    </div>
                    <div class="form-trade">
                        <div class="label-balance"></div>
                        <b-input-group append="HRN" prepend="Amount">
                            <b-form-input
                                type="number"
                                placeholder="Amount Heronium"
                                v-model.number="infoSell.amount"
                            ></b-form-input>
                        </b-input-group>
                    </div>
                    <div class="suggest-trade">
                        <b-button
                            @click="onChoseAmount('Sell', 0.25)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            25%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Sell', 0.5)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            50%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Sell', 0.75)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            75%
                        </b-button>
                        <b-button
                            @click="onChoseAmount('Sell', 1)"
                            variant="none"
                            class="suggest-trade-box"
                        >
                            100%
                        </b-button>
                    </div>
                    <div class="form-trade mt-3">
                        <div class="label-balance"></div>
                        <b-input-group append="HBG" prepend="Total">
                            <b-form-input
                                type="text"
                                readonly
                                :value="
                                    TruncateToDecimals2(
                                        infoSell.amount * infoSell.price,
                                        '',
                                        4,
                                    )
                                "
                            ></b-form-input>
                        </b-input-group>
                    </div>
                    <div
                        class="form-trade d-flex justify-content-center align-items-center"
                    >
                        <b-button
                            variant="none"
                            class="button-trade sell"
                            :disabled="
                                infoSell.price <= 0 || infoSell.amount <= 0
                            "
                            @click.prevent="onBuySell('Sell')"
                        >
                            Sell
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.form-trade {
    .trade {
        width: 100%;
        min-height: 300px;
        padding: 15px;
        &-header {
            // border: 1px solid #00ffff;
            width: 100%;
            // max-width: 300px;
            height: 40px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            &-item {
                color: #fff;
                height: 40px;
                display: flex;
                font-weight: 600;
                box-shadow: none;
                border: 1px solid #fff;
                margin: 0 5px;
                &.active {
                    color: #00ffff;
                    border: 1px solid #00ffff;
                }
            }
        }
        &-body {
            height: calc(100% - 55px);
            margin-top: 25px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            &-sell,
            &-buy {
                flex: 0 0 calc(50% - 10px);
                height: 100%;
                padding: 0 15px;
                .form-trade {
                    margin-bottom: 15px;
                    .label-balance {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #fff;
                        font-family: 'HHBI';
                        .label-balance-left {
                        }
                        .label-balance-right {
                        }
                    }
                    .input-group {
                        border: 1px solid #00ffff;
                        border-radius: 5px;
                    }
                    input {
                        background: linear-gradient(
                            180deg,
                            #00172a 0%,
                            #00000b 100%
                        );
                        color: #fff;
                        border: 0;
                        font-family: 'HHBI';
                        height: 40px;
                        font-size: 19px;
                        &::placeholder {
                            color: #fff;
                            opacity: 0.7;
                        }
                    }
                    input[type='number'] {
                        -moz-appearance: textfield;
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        /* display: none; <- Crashes Chrome on hover */
                        -webkit-appearance: none;
                        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                    }
                    .input-group-text {
                        border: 0;
                        background: linear-gradient(
                            180deg,
                            #00172a 0%,
                            #00000b 100%
                        );
                        color: #fff;
                        font-family: 'HHBI';
                    }
                }
                .suggest-trade {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .suggest-trade-box {
                        flex: 1;
                        margin: 0 5px;
                        background: linear-gradient(
                            180deg,
                            #00172a 0%,
                            #00000b 100%
                        );
                        border: 1px solid #00ffff;
                        font-family: 'HHBI';
                        color: #fff;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .button-trade {
                    margin: 10px auto;
                    width: 175px;
                    height: 50px;
                    color: #fff;
                    outline: none !important;
                    font-style: italic;
                    letter-spacing: 1px;
                    font-size: 1.3em;
                    font-weight: 600;
                    font-family: 'HHBI';
                    box-shadow: none;
                    filter: drop-shadow(
                        2px 2px 1px rgba($color: #000000, $alpha: 0.7)
                    );
                    transition: 500ms;
                    &:focus,
                    &:hover {
                        transform: scale(0.9);
                    }
                    &.sell {
                        background: url('~@/assets/images/mockup/account/bgBtn-red.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    &.buy {
                        background: url('~@/assets/images/mockup/account/bgBtn-green.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
            }
            &-sell {
            }
            &-buy {
            }
            @media (max-width: 991px) {
                flex-wrap: wrap;
                &-sell,
                &-buy {
                    flex: unset;
                    width: 100%;
                }
            }
        }
    }
}
</style>
