<script>
import { mapGetters, mapMutations } from 'vuex';

import UserMarket from './user-market.vue';
import PublicMarket from './public-market.vue';

export default {
    components: {
        UserMarket,
        PublicMarket,
    },
    data() {
        return {
            marketTab: 1,
            approve: false,
        };
    },
    computed: {
        ...mapGetters({
            Balance: 'wallet/Balance',
            UserInfo: 'info/UserInfo',
        }),
    },
    methods: {
        ...mapMutations(['outLoad', 'onLoad']),
        async checkApproval() {
            const { hbg } = this.$store.state.contract.bep_20;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            this.onLoad();
            console.log(123);
            if (addr && marketNew.addr && hbg.cont.methods) {
                const hbgApproval =
                    (await hbg.cont.methods
                        .allowance(addr, marketNew.addr)
                        .call()) /
                    10 ** 18;
                console.log(this.TruncateToDecimals2(hbgApproval));
                this.approve = hbgApproval >= 20000000;
                this.outLoad();
            } else {
                await this.sleep(2000);
                this.checkApproval();
            }
        },
        async Approve() {
            const { hbg } = this.$store.state.contract.bep_20;
            const { web3 } = this.$store.state.contract;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            this.onLoad();
            const txObj = hbg.cont.methods
                .approve(
                    marketNew.addr,
                    (1000000000 * 10 ** 18).toLocaleString('fullwide', {
                        useGrouping: false,
                    }),
                )
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                // Calculate gas
                gas = await hbg.cont.methods
                    .approve(
                        marketNew.addr,
                        (1000000000 * 10 ** 18).toLocaleString('fullwide', {
                            useGrouping: false,
                        }),
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;
            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hbg.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        if (txData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.$toastr.s(
                                'Approved Successfully',
                                'Successfully',
                            );
                            this.checkApproval();
                            this.$bvModal.show('ApproveSuccess');
                            this.outLoad();
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                            this.outLoad();
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
    },
    watch: {
        approve: {
            handler(newVal) {
                if (newVal) {
                    this.$bvModal.hide('callApprove');
                }
            },
        },
        UserInfo: {
            handler() {
                this.checkApproval();
            },
        },
    },
    mounted() {
        if (this.approve) {
            this.$bvModal.hide('callApprove');
        }
        if (this.UserInfo && this.UserInfo.ID) {
            this.checkApproval();
            this.$bvModal.show('callApprove');
        }
    },
};
</script>
<template>
    <b-container class="market-community">
        <b-col cols="12">
            <div class="market-header">
                <div class="market-header-left"></div>
                <div class="market-header-center">
                    <div class="market-header-center-text">
                        <div class="market-header-center-text-title">
                            Community Market
                        </div>
                        <div class="market-header-center-text-content">
                            Buy and sell items with community members using the
                            Hero Book Game Token.
                        </div>
                    </div>
                    <div class="market-header-center-image">
                        <img
                            src="~@/assets/images/mockup/market/new/mk-1.svg"
                            alt=""
                        />
                    </div>
                </div>
                <div class="market-header-right"></div>
            </div>
        </b-col>
        <!--Start User Interface -->
        <UserMarket v-if="this.UserInfo && this.UserInfo.ID" />
        <!--End User Interface -->
        <!--Start Market Interface -->
        <PublicMarket />
        <!--End Market Interface -->
        <b-modal
            id="callApprove"
            hide-footer
            hide-header
            no-close-on-backdrop
            no-close-on-esc
        >
            <div class="market-modal-title">
                <div class="market-modal-title:text text-center">
                    Please approve the marketplace to use HeroBook Game
                    Token(HBG) in your wallet to continue selling!
                </div>
            </div>
            <div class="market-modal-body justify-content-center">
                <!-- <div class="market-modal-body:image">
                    <img :src="item.image" alt="" />
                </div> -->
            </div>
            <div class="market-modal-footer">
                <b-button class="market-modal-footer:confirm" @click="Approve">
                    <div class="button-content">Approve</div>
                </b-button>
            </div>
        </b-modal>
        <b-modal id="ApproveSuccess" hide-footer hide-header>
            <div class="market-modal-title">
                <div class="market-modal-title:text text-center">
                    You have successfully approved, now you can buy the item in
                    the market. Congratulations
                </div>
            </div>
            <div class="market-modal-body justify-content-center">
                <!-- <div class="market-modal-body:image">
                    <img :src="item.image" alt="" />
                </div> -->
            </div>
            <div class="market-modal-footer">
                <b-button
                    class="market-modal-footer:confirm"
                    @click="$bvModal.hide('ApproveSuccess')"
                >
                    <div class="button-content">Confirm</div>
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<style lang="scss">
.market-community {
    padding: 100px 0;
    font-family: 'Poppins';
    font-style: normal;
    .market-header {
        min-height: 175px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-left,
        &-right {
            flex: 1 1 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            max-width: 140px;
        }
        &-left {
            &:before {
                width: 9px;
                height: 9px;
                background: #00ffff;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                content: '';
                display: block;
                margin-right: 10px;
            }
            &:after {
                width: calc(100% - 15px);
                height: 1px;
                background: #888888;
                content: '';
                display: block;
            }
        }
        &-right {
            &::before {
                width: calc(100% - 15px);
                height: 1px;
                background: #888888;
                content: '';
                display: block;
            }
            &::after {
                width: 9px;
                height: 9px;
                background: #00ffff;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                content: '';
                display: block;
                margin-left: 10px;
            }
        }
        &-center {
            flex: 1 1 calc(100% - 280px);
            margin: 0 15px;
            max-width: 650px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            &-image {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1 1 200px;
                max-width: 200px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
            &-text {
                max-width: 400px;
                &-title {
                    font-size: 32px;
                    color: #fcfcfc;
                    line-height: 36px;
                    font-weight: 700;
                }
                &-content {
                    margin-top: 20px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 29px;
                    color: #888888;
                }
            }
        }
    }
    .market-user {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-nav {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            max-width: 600px;
            @media (max-width: 575px) {
                flex-wrap: wrap;
            }
            &-item {
                height: 55px;
                flex: 1 1 250px;
                max-width: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #353f48;
                backdrop-filter: blur(2px);
                color: #f0f0f0;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                width: 100%;
                position: relative;
                cursor: pointer;
                &:after {
                    width: 3px;
                    height: 33%;
                    top: 0;
                    right: 0.5px;
                    bottom: 0;
                    margin: auto;
                    content: '';
                    position: absolute;
                    z-index: 2;
                    background: #00ffff;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                &:hover,
                &.active {
                    background: #00ffff15;
                    color: #00ffff;
                }
            }
        }
        &-nav-2 {
            max-width: unset;
            &-item {
                flex: 1 1 auto;
                max-width: unset;
            }
        }
    }


    @media (max-width: 575px) {
        .market-header {
            flex-direction: column;
            &-left,
            &-right {
                width: 100%;
                max-width: unset;
                flex: unset;
            }
            &-left {
                justify-content: flex-start;
                &:after {
                    width: 50%;
                }
            }
            &-right {
                justify-content: flex-end;
                &::before {
                    width: 50%;
                }
            }
        }
    }
    @media (max-width: 767px) {
        &.container {
            max-width: calc(100% - 30px);
        }
    }
}
</style>
