<script>
import { mapMutations } from 'vuex';

export default {
    data() {
        return {
            register: {
                sponsor: '',
                address: '',
            },
            sponsor: '',
            rootAdr: '0xecCEa453A9c3926da52860A8286F8c6E9e9cDEB6',
            isRequest: false,
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        async checkWallet() {
            // return;
            this.onLoad();
            if (!window.ethereum) {
                this.outLoad();
                this.$toastr.e('Please Install Metamask To Join', 'Oops!');
                return false;
            }
            let addrs = [];
            try {
                addrs = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }
            if (addrs.length > 0) {
                return addrs[0];
            }
            return null;
        },
        async onRegister() {
            this.onLoad();
            const walletCurrent = await this.checkWallet();
            if (walletCurrent) {
                this.$store.dispatch('auth/CheckRegister', {
                    sponsor:
                        this.sponsor ||
                        '0xeccea453a9c3926da52860a8286f8c6e9e9cdeb6',
                    wallet: walletCurrent,
                });
            } else {
                this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
            }
        },
        async OnActiveContract() {
            let sponsorAddress;
            let sponsorCheck;
            const { sponsor } = this.$route.query;
            const { register } = this.$store.state.contract.managers;

            if (sponsor || this.sponsor) {
                const tokenCont = this.$store.state.contract.bep_20.hbg.cont;
                sponsorAddress = this.sponsor || sponsor;
                sponsorCheck = await tokenCont.methods
                    .uplineWallet(sponsorAddress)
                    .call();
            } else {
                sponsorAddress = '0x0000000000000000000000000000000000000000';
                sponsorCheck = true;
            }

            if (
                sponsorCheck === '0x0000000000000000000000000000000000000000' &&
                sponsorAddress !== this.rootAdr
            ) {
                this.$toastr.e('Sponsor wallet Is not Registered!', 'Oops!');
                this.outLoad();
            } else {
                if (sponsorAddress === this.rootAdr) {
                    sponsorAddress =
                        '0x0000000000000000000000000000000000000000';
                }

                const txObj = register.cont.methods
                    .register(sponsorAddress)
                    .encodeABI();
                let gas = 0;
                const usdtFee = await register.cont.methods
                    .register_fee()
                    .call();

                const bnbFee = await register.cont.methods
                    .getBNBPrice(usdtFee)
                    .call();

                try {
                    // Calculate gas
                    gas = await register.cont.methods
                        .register(sponsorAddress)
                        .estimateGas({
                            from: this.$store.state.wallet.addr,
                            value: this.$store.state.contract.web3.provider.utils.toHex(
                                bnbFee,
                            ),
                            gas: 1000000,
                        });
                } catch (error) {
                    this.outLoad();
                    if (
                        error.message ===
                        'Returned error: execution reverted: Your addess already registation'
                    ) {
                        this.$toastr.e(' You Are already registation');
                        this.$router.push({ name: 'Login' });
                        return;
                    }
                    this.$toastr.e(error, 'Oops!');
                    return;
                }

                const gasPrice =
                    await this.$store.state.contract.web3.provider.eth.getGasPrice();
                let res;

                try {
                    res = await window.ethereum.request({
                        method: 'eth_sendTransaction',
                        params: [
                            {
                                from: this.$store.state.wallet.addr,
                                to: register.addr,
                                gas: this.$store.state.contract.web3.provider.utils.toHex(
                                    gas + 50000,
                                ),
                                gasPrice:
                                    this.$store.state.contract.web3.provider.utils.toHex(
                                        gasPrice,
                                    ),
                                value: this.$store.state.contract.web3.provider.utils.toHex(
                                    bnbFee,
                                ),
                                data: this.$store.state.contract.web3.provider.utils.toHex(
                                    txObj,
                                ),
                            },
                        ],
                    });
                    // transaction result
                    const check = setInterval(async () => {
                        const txData =
                            await this.$store.state.contract.web3.provider.eth.getTransactionReceipt(
                                res,
                            );
                        if (txData != null) {
                            clearInterval(check);
                            if (txData.status) {
                                if (!this.isRequest) {
                                    this.isRequest = true;
                                    // send transaction
                                    this.$store.dispatch('auth/Register', {
                                        transaction: txData.transactionHash,
                                    });
                                }
                            } else {
                                this.$toastr.e('Register Failed', 'Oops!! ');
                            }
                        }
                    }, 1000);
                } catch (error) {
                    this.outLoad();
                    this.$toastr.e(error.message, 'Oops!');
                }
            }
        },
    },
    mounted() {
        const { sponsor } = this.$route.query;
        if (sponsor) {
            this.sponsor = sponsor;
        }
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'auth/REGISTER_SYSTEM_SUCCESS':
                    this.outLoad();
                    this.OnActiveContract();
                    break;
                case 'auth/REGISTER_SUCCESS':
                    this.outLoad();
                    this.isRequest = false;
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <div class="box-register">
        <b-form>
            <div class="form-group row">
                <div class="col-md-3 cols-12">
                    <label for="sponsor">Sponsor</label>
                </div>
                <div class="col-md-9 cols-12">
                    <b-form-input
                        id="sponsor"
                        type="text"
                        v-model.trim="sponsor"
                        placeholder="Your Refferrer's Wallet Address"
                    ></b-form-input>
                </div>
            </div>
            <b-form-group
                class="d-flex justify-content-center align-items-center mb-0"
            >
                <b-button @click="onRegister"> Active </b-button>
            </b-form-group>
        </b-form>
        <div class="more-info">
            <div class="text-more">
                By continuing, you agree to our
                <a href="https://herobook.io/term-of-service" target="_blank">
                    Terms of Use
                </a>
            </div>
            <div class="breaking"></div>
            <div class="note">
                <div class="title">NOTED:</div>
                <div class="content">
                    <p
                        class="text-left text-warning pl-3 font-weight-bold"
                        style="letter-spacing: 1px"
                    >
                        - Wallet activation successful! If you can't log in to
                        the marketplace, please wait 15 minutes for the bscscan
                        network to confirm the transaction to log into the
                        system
                    </p>
                    <p>
                        - To activate account, you must pay an amount of
                        <span> BNB</span> equivalent to <span>5$</span> (at
                        current exchange rate)
                    </p>
                    <p v-if="false">
                        - You will receive
                        <span>5 HBG tokens, 1 Hero, 1 Land </span> and
                        <span> your reflink </span> after your account is
                        activated.
                    </p>
                    <p>
                        - Commission policy: If a member in your system
                        activated his account successfully, you will receive the
                        amount of <span>BNB</span> equivalent to
                        <span>$2.5</span> (at current exchange rate)
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.box-register {
    min-height: 400px;
    width: 100%;
    max-width: 405px;
    background: {
        image: url(~@/assets/images/mockup/auth/box-2.png);
        size: 100% 100%;
    }
    padding: 15px;
    .form-group {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1.75rem auto 1.75rem !important;
        text-align: left;
        a {
            color: #00ffff;
            font-size: 17px;
            font-weight: 500;
        }
        label {
            color: #00ffa9;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
        }
        input {
            color: #fff;
            font-weight: 600;
            letter-spacing: 1px;
            width: 100%;
            &::-webkit-input-placeholder {
                color: #fff;
                opacity: 0.4;
                text-align: center;
                font-weight: 500;
                font-size: 18px;
                letter-spacing: 1px;
            }
            height: 45px;
            border: 1px solid #00ffff;
            background: linear-gradient(180deg, #18274a 0%, #001326 100%);
            border-radius: 0;
        }
        button {
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            color: #002338;
            width: 150px;
            height: 40px;
            font-weight: 700;
            font-size: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .note {
        text-align: center;
        .title {
            color: #00ffff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        p {
            color: #fff;
            font-weight: 500;
            span {
                color: #00ffff;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }
}
</style>
