<template>
    <b-container class="paginate-2">
        <b-row class="align-items-center justify-content-center">
            <b-col cols="12" lg="4">
                <div class="info-paginate">
                    Showing
                    <span> {{ perPage * (currentPage - 1) + 1 }}</span>
                    -
                    <span>{{ perPage * (currentPage - 1) + perPage }}</span> of
                    <span> {{ totalResult }} </span>
                </div>
            </b-col>
            <b-col cols="12" lg="8">
                <div class="paginate">
                    <button
                        class="paginate prev"
                        :disabled="currentPage <= 1"
                        @click="currentPage--"
                    >
                        <img
                            src="~@/assets/images/mockup/market/new/prev.png"
                            alt=""
                        />
                        <div class="text">prev</div>
                    </button>
                    <button
                        class="paginate"
                        v-for="pageIndex in range(0, totalPages).slice(
                            totalPages - currentPage < 4 &&
                                totalPages - currentPage > 5
                                ? totalPages - 4
                                : currentPage > 1
                                ? currentPage - 1
                                : 1,
                            currentPage <= 1
                                ? currentPage + 5
                                : currentPage + 4,
                        )"
                        :key="`current-page-${pageIndex}`"
                        :class="{ active: pageIndex == currentPage }"
                        @click="currentPage = pageIndex"
                    >
                        <div class="content-paginate">
                            {{ pageIndex }}
                        </div>
                    </button>
                    <button
                        class="paginate next"
                        :disabled="currentPage >= totalPages"
                        @click="currentPage++"
                    >
                        <div class="text">next</div>
                        <img
                            src="~@/assets/images/mockup/market/new/next.png"
                            alt=""
                        />
                    </button>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    props: {
        totalPages: {
            type: Number,
            default: 1,
        },
        totalResult: {
            type: Number,
            default: 1,
        },
        perPage: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            currentPage: 1,
        };
    },
    watch: {
        currentPage: {
            handler(newVal) {
                this.onChangePage(newVal);
            },
        },
    },
    methods: {
        range(start, end) {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        },
        onChangePage(page) {
            this.$emit('current', page);
        },
    },
};
</script>

<style lang="scss" scoped>
.paginate-2 {
    .paginate {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        position: relative;
        z-index: 20;
        margin: 0 -10px 0 -10px;
        .paginate {
            border-radius: 5px;
            width: 44px;
            height: 44px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            background: transparent;
            border: none;
            outline: none;
            box-shadow: none;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            font-family: 'Poppins';
            margin: 5px;
            &:disabled {
                opacity: 0.3;
            }

            &.next {
                color: #2a2e4a;
                width: unset;
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                border: none;
                outline: none;
                box-shadow: none;
                color: #fff;
                img {
                    margin-left: 10px;
                }
            }
            &.prev {
                color: #2a2e4a;
                width: unset;
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                border: none;
                outline: none;
                box-shadow: none;
                color: #fff;
                img {
                    margin-right: 10px;
                }
            }
            &.active {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                line-height: 20px;
                color: #00ffff;
                transition: 0.1s;
                background: linear-gradient(-175deg, #18d4e2 10%, #a506f9 100%);
                padding: 2px;
                .content-paginate {
                    background: #2a2e4a;
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            @media (max-width:575px){
              width: 25px;
              height: 25px;
              font-size: 14px;
              margin-right: 2px;
            }
        }
    }
    .info-paginate {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        color: #888888;
        span {
            color: #00ffff;
        }
    }
    @media (max-width: 991px) {
        .info-paginate {
            text-align: center;
            margin-bottom: 10px;
        }
        .paginate {
            justify-content: center;
        }
    }
}
</style>
