const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        listLand: {
            list: [],
            current: 1,
            total: 1,
        },
    }),
    getters: {
        ListLand: (state) => state.listLand,
    },
    actions: {
        req_postTransferSuccess: ({ commit }, input) => {
            axios.post('api/market/order/transfer/land', input).then(() => {
                commit('ACTION_SUCCESS');
            });
        },
        req_postTransferFree: ({ commit }, input) => {
            axios.post('/api/land/transfer/free', input).then(() => {
                commit('ACTION_SUCCESS');
            });
        },
        req_postSellSuccess: ({ commit }, input) => {
            axios
                .post('api/market/order/land/send-transaction', input)
                .then(() => {
                    commit('ACTION_SUCCESS');
                });
        },
        req_postCancelSuccess: ({ commit }, input) => {
            axios.post('api/market/order/sell/land/cancel', input).then(() => {
                commit('ACTION_SUCCESS');
            });
        },

        req_getListLand: ({ commit }, input) => {
            axios
                .get('api/me/my-land', {
                    params: input,
                })
                .then((response) => {
                    commit('SET_LIST_LAND', response);
                });
        },
    },
    mutations: {
        SET_LIST_LAND(state, data) {
            const { results, page, totalPages } = data;
            state.listLand = {
                list: results,
                current: page,
                total: totalPages,
            };
        },
        ACTION_SUCCESS() {},
    },
};
