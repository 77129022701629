<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';

export default {
    components: {
        QrcodeStream,
        // QrcodeDropZone,
    },
    methods: {
        async onLogin() {
            this.$bvModal.show('qrScanner')
        },
        onDecode(payload) {
            console.log(payload);
        },
    },
};
</script>

<template>
    <b-container class="p-0 login-qr-container">
        <b-button variant="none" class="qrLogin" @click="onLogin">
            Login by QR Scanner
        </b-button>
        <b-modal id="qrScanner" body-class="p-0" title="Login With Scanner QR Code" hide-footer>
            <qrcode-stream @decode="onDecode"></qrcode-stream>
            <div class="form-group mb-0  d-flex justify-content-center btn-cls">
                <b-button
                    class="mx-auto button-reset"
                    variant="primary"
                    @click="$bvModal.hide('qrScanner')"
                >
                    Close
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>
<style lang="scss">
.btn-cls{
  position: absolute;
  bottom: 0;
  right: 0;
  height: max-content;
  button{
    height: 30px;
    width: max-content;
    margin: 0;
    min-width: unset!important;
  }
}
.login-qr-container {
    .qrLogin {
        border: 1px solid #00ffff;
        background: linear-gradient(180deg, #18274a 0%, #001326 100%);
        color: #fff;
        font-weight: 500;
        height: 50px;
        width: 100%;
        font-size: 18px;
        max-width: 375px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        max-width: 375px;
        margin: auto;
        &:hover {
            color: #00ffff;
        }
    }
}
</style>
