const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        listPicker: [],
        listIdPicker: [],
        listHero: {
            list: [],
            current: 1,
            total: 1,
        },
    }),
    getters: {
        ListPicker: (state) => state.listPicker,
        ListIdPicker: (state) => state.listIdPicker,
        ListHero: (state) => state.listHero,
    },
    actions: {
        req_getListHero({ commit }, input) {
            axios
                .get(`api/hero/user-hero/${input.ratity ? input.ratity : ''}`, {
                    params: input,
                })
                .then((res) => {
                    if (res) {
                        commit('ADD_LIST_HERO', res);
                    }
                });
        },
        // req_postRatio({ commit }, input) {
        //     axios.post('api/hero/upgrade-hero/ratio', input).then((res) => {
        //         if (res) {
        //             commit('UPGRADE_RATIO', res);
        //         }
        //     });
        // },
        // req_postUpdateHero({ commit }, input) {
        //     axios.post('api/hero/upgrade-hero', input).then((res) => {
        //         if (res) {
        //             commit('UPGRADE_SUCCESS', res);
        //         }
        //     });
        // },
    },
    mutations: {
        RESET_PICKER(state) {
            state.listPicker = [];
            state.listIdPicker = [];
        },
        REMOVE_HERO(state, hero) {
            const { listPicker, listIdPicker } = state;
            const idx = listPicker.findIndex((x) => x.tokenId === hero);
            listPicker.splice(idx, 1);
            listIdPicker.splice(idx, 1);
            state.listPicker = listPicker;
            state.listIdPicker = listIdPicker;
        },
        ADD_HERO(state, hero) {
            const { listPicker, listIdPicker } = state;
            if (listPicker.length >= 1) {
                if (
                    listPicker.filter((e) => e.tokenId === hero.tokenId)
                        .length > 0
                ) {
                    return;
                }
                listPicker.push(hero);
                listIdPicker.push(hero.tokenId);
            } else {
                listPicker.push(hero);
                listIdPicker.push(hero.tokenId);
            }
            state.listPicker = listPicker;
        },
        ADD_LIST_HERO(state, data) {
            const { listPicker } = state;
            const { results, page, totalPages, totalResults } = data;
            const listHero = results;

            for (let i = 0; i < listHero.length; i += 1) {
                const obj = listHero[i];

                if (listPicker.indexOf(obj.tokenId) !== -1) {
                    listHero[i].onUpgrade = true;
                } else {
                    listHero[i].onUpgrade = false;
                }
            }
            state.listHero = {
                list: listHero,
                current: page,
                total: totalPages,
                totalHero: totalResults,
            };
        },
    },
};
