/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        listGameBalances: [],
    }),
    getters: {
        ListGameBalances: (state) => state.listGameBalances,
    },
    actions: {
        req_getListGameBalances: ({ commit }) => {
            axios.get('/api/me/platform/balance').then((response) => {
                if (response) {
                    commit('SET_GAME_BALANCES', response);
                }
            });
        },
    },
    mutations: {
        SET_GAME_BALANCES(state, data) {
            state.listGameBalances = data;
        },
    },
};
