/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import store from '@/store';

const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        address: null,
        info: {
            email: null,
            address: null,
        },
        balance: {
            Electric: 0,
            HBG: 0,
            HeroStone: 0,
            Heronium: 0,
            Oxygen: 0,
            MisteryBox: 0,
        },
        listAvatar: [],
        infoSwap: [],
        unKnown: false,
        listPlatform: [],
    }),

    getters: {
        Address: (state) => state.address,
        UserInfo: (state) => state.info,
        Balance: (state) => state.balance,
        ListAvatar: (state) => state.listAvatar,
        InfoSwap: (state) => state.infoSwap,
        UnKnown: (state) => state.unKnown,
        ListPlatform: (state) => state.listPlatform,
    },

    actions: {
        req_getInfo: ({ commit }) => {
            axios.get('/api/market/info').then((res) => {
                if (res) {
                    commit('GET_INFO_SUCCESS', res);
                }
            });
        },
        req_getBalance: ({ commit }) => {
            axios.get('api/me/balance').then((res) => {
                if (res) commit('GET_BALANCE_SUCCESS', res);
            });
        },
        req_infoSwap: ({ commit }) => {
            axios.get('/api/swap-ingame/config').then((res) => {
                if (res) commit('GET_SWAP_SUCCESS', res);
            });
        },

        req_updateInfo: ({ commit, dispatch }, input) => {
            axios.post('api/market/update', input).then((data) => {
                console.log(data);
                if (data) {
                    dispatch('req_getInfo');
                    axios
                        .post('/api/me/send-mail-active', {
                            email: input.email,
                        })
                        .then(() => {
                            commit('CONFIRM_MAIL');
                        });
                }
            });
        },
        req_postReEmail: ({ commit }, input) => {
            console.log('reSendMail');
            axios.post('/api/me/send-mail-active', input).then(() => {
                commit('IS_SEND_EMAIL');
            });
        },
        req_postConfirmEmail: ({ dispatch, commit }, input) => {
            axios.post('/api/me/verify-mail', input).then(() => {
                dispatch('req_getInfo');
                commit('CONFIRM_MAIL_SUCCESS');
            });
        },
        req_postChangePassword: ({ commit }, input) => {
            axios.post('api/market/password/change', input).then(() => {
                commit('CHANGE_PASSWORD_SUCCESS');
            });
        },
        req_getForgot: ({ commit }, input) => {
            axios.get('/api/me/forgot-password', input).then(() => {
                commit('FORGOT_PASSWORD_SUCCESS');
            });
        },
        req_postChangeEmail: ({ commit, dispatch }, input) => {
            axios.post('/api/me/send-change-email', input).then((res) => {
                if (res) {
                    commit('CHANGE_EMAIL_SUCCESS');
                    dispatch('req_getInfo');
                }
            });
        },
        req_postConfirmChangeEmail: ({ commit, dispatch }, input) => {
            axios.post('/api/me/verify-change-email', input).then((res) => {
                if (res) {
                    commit('CHANGE_EMAIL_CONFIRM');
                    dispatch('req_getInfo');
                }
            });
        },

        req_postChangeName: ({ dispatch }, input) => {
            axios.post('api/market/username/change', input).then(() => {
                dispatch('req_getInfo');
            });
        },
        req_getListAvatar: ({ commit }) => {
            axios
                .get('/api/me/avatar/list', {
                    params: {
                        limit: 60,
                        page: 1,
                    },
                })
                .then((res) => {
                    if (res) {
                        commit('GET_LIST_AVATAR_SUCCESS', res);
                    }
                });
        },
        req_postChoseAvatar({ commit }, input) {
            axios.post('/api/me/avatar/choose', input).then((res) => {
                commit('SET_AVATAR_SUCCESS', res);
            });
        },

        req_postActiveUser: ({ commit }, input) => {
            axios.post('/api/market/active/address', input).then((res) => {
                commit('ACTIVE_SUCCESS', res);
            });
        },
        req_postCheckAddressUnKnown: ({ commit }, input) => {
            axios.post('/api/market/check/address', input).then((res) => {
                commit('ACTIVE_SYSTEM_SUCCESS', res);
            });
        },
        req_getListConnectSystems: ({ commit }) => {
            axios.get('/api/me/platform').then((res) => {
                commit('GET_LIST_PLATFORM', res);
            });
        },
    },
    mutations: {
        GET_BALANCE_SUCCESS(state, data) {
            state.balance = data.balance;
        },
        GET_SWAP_SUCCESS(state, data) {
            state.infoSwap = data;
        },
        SET_ADDRESS: (state, data) => {
            state.address = data.address;
            console.log(data.address);
        },
        GET_INFO_SUCCESS: (state, data) => {
            state.info = data;

            if (state.info.unknownUser) state.unKnown = true;
            store.commit('wallet/setupWallet', data.address);
        },
        CHANGE_PASSWORD_SUCCESS() {},
        CHANGE_EMAIL_SUCCESS() {},
        GET_LIST_AVATAR_SUCCESS(state, data) {
            state.listAvatar = data.results;
        },
        SET_AVATAR_SUCCESS() {
            this.dispatch('info/req_getInfo');
        },
        IS_SEND_EMAIL() {},
        CONFIRM_MAIL() {},
        FORGOT_PASSWORD_SUCCESS() {},
        CONFIRM_MAIL_SUCCESS() {},
        CHANGE_EMAIL_CONFIRM() {},

        ACTIVE_SYSTEM_SUCCESS() {},
        ACTIVE_SUCCESS() {},
        GET_LIST_PLATFORM(state, data) {
            state.listPlatform = data;
        },
    },
};
