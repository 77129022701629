<script>
import { mapGetters } from 'vuex';

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { listBox } from '../box';

export default {
    components: { VueSlickCarousel },
    data() {
        return {
            settings: {
                dots: false,
                dotsClass: 'slick-dots custom-dot-class',
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                responsive: [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
            leftBox: {
                silver: 0,
                gold: 0,
                platinum: 0,
                diamond: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            ListBoxStore: 'shop/ListBoxStore',
            Statistical: 'shop/Statistical',
        }),
        infoBox() {
            const arr = [
                {
                    img: 'shop-1.png',
                    title: 'Available',
                    content: 'Unlimited',
                },
                {
                    img: 'shop-2.png',
                    title: 'Time Remaining',
                    content: 'Unlimited',
                },
                {
                    img: 'shop-3.png',
                    title: 'Purchased',
                    content: `${this.TruncateToDecimals2(
                        this.Statistical.price,
                        '',
                        0,
                    )} HBG`,
                },
            ];
            return arr;
        },
        boxList() {
            return listBox;
        },
    },
    methods: {
        async getTotalSupplyBox() {
            const { hero } = this.$store.state.contract.marketplace;
            const methodHero = await hero.cont.methods;
            if (!methodHero) {
                await this.sleep(2000);
                this.getTotalSupplyBox();
                return;
            }

            const totalDiamon = await methodHero.totalSupplyDiamondBox().call();
            const totalGold = await methodHero.totalSupplyGoldBox().call();
            const totalPlatinum = await methodHero
                .totalSupplyPlatinumBox()
                .call();
            const totalSilver = await methodHero.totalSupplySilverBox().call();
            this.leftBox = {
                silver: totalSilver,
                gold: totalGold,
                platinum: totalPlatinum,
                diamond: totalDiamon,
            };
        },
    },
    mounted() {
        this.getTotalSupplyBox();
    },
    created() {
        this.$store.dispatch('shop/req_getBoxStore');
        this.$store.dispatch('shop/req_getStoreStatistical');
    },
};
</script>

<template>
    <b-container fluid class="shop-box">
        <b-container class="section section-1">
            <div class="page-name">
                <div class="before">The hero box</div>
                <div class="after">the hero box</div>
            </div>
        </b-container>
        <b-container class="section section-2">
            <div class="sub-text">
                INO program for the first time to launch Hero Box is comming
                soon Are Herobook’s members excited to own "one-of-a-kind" hero
                ?
            </div>
        </b-container>
        <b-container class="section section-3">
            <b-row>
                <b-col
                    cols="12"
                    md="4"
                    sm="6"
                    v-for="info in infoBox"
                    :key="info.img"
                >
                    <div class="box-info-shop">
                        <div class="icons">
                            <img
                                :src="
                                    require(`@/assets/images/mockup/shop/${info.img}?v=1`)
                                "
                                alt=""
                            />
                        </div>
                        <div class="text">
                            <div class="text-title">
                                {{ info.title }}
                            </div>
                            <div class="text-content">
                                {{ info.content }}
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="section section-4">
            <VueSlickCarousel v-bind="settings" v-if="ListBoxStore.length >= 1">
                <div
                    class="carousel-item-shop"
                    v-for="box in ListBoxStore"
                    :key="box.Name"
                >
                    <router-link
                        :to="{
                            name: 'BoxDetail',
                            params: { id: box.Name.toLowerCase() },
                        }"
                        class="none-dec"
                    >
                        <div class="box-container">
                            <div class="box-img">
                                <img
                                    :src="
                                        require(`@/assets/images/mockup/shop/${box.Name.toLowerCase()}.png?v=1`)
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="box-content">
                                <div
                                    class="name"
                                    :class="box.Name.toLowerCase()"
                                >
                                    {{ box.Name }} Box
                                </div>
                                <div class="remaining-box">
                                    Box left:
                                    <span>{{
                                        leftBox[box.Name.toLowerCase()]
                                    }}</span>
                                </div>
                                <div class="buy-section">
                                    <b-button>
                                        <div class="left">Price:</div>
                                        <div class="right">
                                            <div class="hbg">
                                                {{ box.Price }} HBG
                                            </div>
                                            <div class="usd">
                                                <!-- {{ box.usd }} USD -->
                                            </div>
                                        </div>
                                    </b-button>
                                </div>
                                <div class="detail">
                                    {{ box.Description }}
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </VueSlickCarousel>
        </b-container>
    </b-container>
</template>

<style lang="scss">
$box-color: linear-gradient(0deg, #02ffff -60%, #001326 100%);
.shop-box {
    .section {
        padding: 50px 0;
        .page-name {
            position: relative;
            .before {
                -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                -webkit-text-stroke-width: 1px;
                font-family: 'HHBI';

                line-height: 120%;
                text-transform: uppercase;

                -webkit-text-stroke-color: #4effff;
                opacity: 0.6;
                font-size: clamp(70px, 5.5vw, 120px);
            }

            .after {
                font-family: 'HHBI';
                font-size: clamp(40px, 3vw, 65px);
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                text-transform: uppercase;
                color: #fff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            }
            @media (max-width: 575px) {
                .before {
                    font-size: clamp(55px, 5.5vw, 120px);
                    opacity: 0.3;
                }
                .after {
                    font-size: clamp(30px, 3vw, 65px);
                }
            }
            @media (max-width: 424px) {
                .before {
                    font-size: clamp(45px, 5.5vw, 120px);
                    opacity: 0.3;
                }
                .after {
                    font-size: clamp(25px, 3vw, 65px);
                }
            }
            @media (max-width: 374px) {
                .before {
                    font-size: clamp(40px, 5.5vw, 120px);
                    opacity: 0.3;
                }
                .after {
                    bottom: -10px;
                    font-size: clamp(30px, 3vw, 65px);
                }
            }
        }
    }
    .section-1 {
        text-align: center;
        padding: 25px 15px 30px;
        img {
            width: 100%;
            max-width: 740px;
            height: auto;
        }
        @media (max-width: 480px) {
            widows: 100%;
            max-width: unset;
            padding: 15px 0px 15px;
        }
    }
    .section-2 {
        padding: 30px 15px;
        .sub-text {
            color: #4effff;
            font-size: clamp(1em, 4vw, 1.5em);
            max-width: 991px;
            line-height: 120%;
            margin: auto;
            text-align: center;
            font-family: 'HHBI';
        }
        @media (max-width: 480px) {
            padding: 15px 15px;
            .sub-text {
                font-size: 1.1em;
            }
        }
    }
    .section-3 {
        .box-info-shop {
            background: $box-color;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 140px;
            width: 100%;
            max-width: 360px;
            padding: 5% 10%;
            border-radius: 10px;
            margin: 15px auto;
            @media (max-width: 480px) {
                min-height: 100px;
            }
            .icons {
                flex: 0 0 70px;
                width: 70px;
                height: 100%;
                img {
                    width: 50px;
                    height: auto;
                }
            }
            .text {
                flex: 1 1 auto;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                .text-title {
                    color: #4effff;
                    font-size: 1.2em;
                    line-height: 120%;
                    font-family: 'HHBI';
                }
                .text-content {
                    color: #ffffff;
                    font-size: 1.4em;
                    line-height: 120%;
                    font-family: 'HHBI';
                }
            }
        }
    }
    .section-4 {
        .slick-arrow {
            z-index: 30;
            width: max-content;
            height: max-content;

            &.slick-next {
                right: -2%;
                &:before {
                    content: '';
                    background: url('~@/assets/images/icons/next.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 40px;
                    width: 25px;
                    display: block;
                }
            }
            &.slick-prev {
                left: -2%;
                &:before {
                    content: '';
                    background: url('~@/assets/images/icons/prev.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 40px;
                    width: 25px;
                    display: block;
                }
            }
            @media (min-width: 1366px) {
                &.slick-next {
                    right: 0%;
                }
                &.slick-prev {
                    left: 0%;
                }
            }
            @media (max-width: 425px) {
                &.slick-next,
                &.slick-prev {
                    &:before {
                        height: 36px;
                        width: 25px;
                    }
                }
                &.slick-next {
                    right: -25px;
                }
                &.slick-prev {
                    left: -25px;
                }
            }
        }
        .none-dec {
            text-decoration: none !important;
        }
        .box-container {
            width: 100%;
            max-width: 300px;
            margin: auto;

            .box-img {
                position: relative;
                img {
                    width: 100%;
                    z-index: 10;
                    position: relative;
                    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    background: linear-gradient(
                        0deg,
                        #e2dfd3 0%,
                        #f6cb0a00 100%
                    );
                    z-index: 0;
                    animation: light 2s infinite linear;
                }
            }

            .box-content {
                background: {
                    image: url('~@/assets/images/mockup/shop/content.png');
                    size: 100% 100%;
                    position: bottom center;
                    repeat: no-repeat;
                }
                margin-top: 5px;
                width: 100%;
                min-height: 300px;
                padding: 15px 10% 0;
                .name {
                    font-size: 2em;
                    text-align: center;
                    margin-bottom: 15px;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    &.diamond {
                        color: #ff00ff;
                        text-shadow: 1px 0px 9px #ff00ff;
                    }
                    &.gold {
                        color: #f3ff00;
                        text-shadow: 1px 0px 9px #f3ff00;
                    }
                    &.silver {
                        color: #98d7e0;
                        text-shadow: 1px 0px 9px #98d7e0;
                    }
                    &.platinum {
                        color: #4effff;
                        text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                        @media (max-width: 374px) {
                            font-size: 1.7em;
                        }
                    }
                }
                .remaining-box {
                    color: #fff;
                    font-family: 'HHBI';
                    font-size: 1.2em;
                    text-align: center;
                    span {
                        color: red;
                    }
                }
                .buy-section {
                }
                button {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    padding: 5px 10px;
                    .left {
                        flex: 0 0 25%;
                        color: #002338;
                        font-weight: 600;
                    }
                    .right {
                        flex: 0 0 75%;
                        padding-left: 10px;
                        color: #002338;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .hbg {
                            font-size: 1.4em;
                            font-weight: 900;
                            line-height: 120%;
                            font-family: 'HHBI';
                        }
                        .usd {
                            font-size: 1em;
                            font-weight: 900;
                            margin-left: 10px;
                            font-family: 'HHBI';
                        }
                    }
                }

                .detail {
                    color: #fff;
                    padding: 15px 0;
                    text-align: center;
                }
            }
        }
        @media (max-width: 767px) {
            width: 100%;
            padding: 50px 10px;
            max-width: 100%;
        }
    }
}
</style>
