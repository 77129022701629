<script>
import { mapGetters, mapMutations } from 'vuex';
import CountDown from '@/components/shared/CountDown2.vue';
import BalanceBox from '@/components/shared/BalaneIngame.vue';
import oneHero from '../upgrade/oneHero.vue';
import Hero from './Hero.vue';

const axios = require('@/store/axios.config').default;

export default {
    props: {
        isSummonProcess: {
            type: Boolean,
            default: false,
        },
        summon: {
            type: Object,
            default: null,
        },
    },
    components: { Hero, oneHero, CountDown, BalanceBox },
    data() {
        return {
            infoSummon: {
                date: 0,
                predictSummon: {
                    amountHeronium: 0,
                    box: 'herobox',
                    id: null,
                },
            },
            dataSummon: {
                quantity: 1,
                message: '',
            },
            mysteryClaim: null,
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        onRemoveHero(hero) {
            this.$store.commit('upgrade/REMOVE_HERO', hero);
        },
        showConfirm() {
            if (this.ListIdPicker.length <= 1) {
                this.$toastr.e('You must select at least 2 heroes to summon');
                return;
            }
            this.$bvModal.show('confirm-summon');
        },
        async onGetRatedHero() {
            const data = await axios.post('api/hero/upgrade-hero/ratio', {
                listHero: this.ListIdPicker,
            });
            const { ratio, ratity } = data;
            this.infoUpgrade = { ratio: ratio * 100, ratity };
        },
        async confirmSummon() {
            this.onLoad();
            const dataSummon = await axios.post('api/me/summon/', {
                hero1: this.ListIdPicker[0],
                hero2: this.ListIdPicker[1],
            });
            if (!dataSummon.message) {
                this.outLoad();
                return;
            }
            this.dataSummon = dataSummon;
            this.mysteryClaim = dataSummon.boxMistery;
            this.$bvModal.hide('confirm-summon');
            this.$store.commit('upgrade/RESET_PICKER');
            this.$bvModal.show('message-summon-success');
            if (
                dataSummon.promotionSummonNew &&
                dataSummon.promotionSummonNew.status
            ) {
                this.$bvModal.show('promotion-summon-success');
            }
            this.outLoad();
        },
        onClearSummon() {
            this.$store.dispatch('info/req_getBalance');
            this.infoSummon = {
                date: 0,
                predictSummon: {
                    amountHeronium: 0,
                    box: 'herobox',
                    id: null,
                },
            };
        },
        async onCalculator() {
            this.onLoad();
            const data = await axios.post('api/me/summon/predict/v2', {
                hero1: this.ListIdPicker[0],
                hero2: this.ListIdPicker[1],
            });
            this.infoSummon = data;
            this.outLoad();
        },
        ComingSoon() {
            this.$toastr.s('Summon is coming soon', 'Coming Soon');
        },
    },
    computed: {
        ...mapGetters({
            ListPicker: 'upgrade/ListPicker',
            ListIdPicker: 'upgrade/ListIdPicker',
        }),
        hero() {
            if (this.isSummonProcess) {
                return this.summon.summon;
            }
            return false;
        },
    },
    watch: {
        ListIdPicker: {
            handler() {
                const { length } = this.ListIdPicker;
                if (length >= 2) {
                    this.onCalculator();
                }
                if (length <= 1) {
                    this.infoUpgrade = {
                        box: 'silver',
                        heroNium: 0,
                    };
                }
            },
        },
    },

    created() {
        this.$store.commit('upgrade/RESET_PICKER');
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'upgrade/ADD_HERO':
                case 'upgrade/REMOVE_HERO':
                    this.$bvModal.hide('listHero-summon');
                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <b-container class="p-0" fluid>
        <div class="genesis">
            <div class="genesis-header position-relative mt-5">
                <div
                    class="btn-back d-flex align-items-center"
                    @click="
                        $emit('renderComponent', {
                            component: 'summon',
                            summon: {},
                        })
                    "
                >
                    <img
                        src="~@/assets/images/icons/back.png"
                        class="d-block mr-2"
                    />
                    <p class="mb-0">Back</p>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="genesis-title position-relative">
                        <h3 class="text text-center mb-0">GENESIS SUMMON</h3>
                    </div>
                </div>
            </div>
            <b-row>
                <b-col cols="12" class="mx-auto">
                    <BalanceBox coin="Heronium" />
                </b-col>
            </b-row>
            <b-row class="justify-content-center" v-if="!isSummonProcess">
                <b-col lg="4" class="mt-3 column">
                    <div class="genesis-hero-item mt-3">
                        <div class="img" v-if="!ListPicker[0]">
                            <!-- <img
                                src="~@/assets/images/mockup/summon/frame-quesion-mark.png"
                                class="w-100"
                            /> -->
                        </div>
                        <div class="hero" v-else>
                            <oneHero :hero="ListPicker[0]" />
                        </div>
                        <p
                            class="my-3 summon-count text-center"
                            v-if="ListPicker[0]"
                        >
                            Summon Count
                            <span class="text-white">
                                {{ ListPicker[0].quantitySummon }}
                            </span>
                        </p>
                        <b-button
                            v-if="ListPicker[0]"
                            variant="none"
                            class="btn-choose-hero remove relative"
                            @click="onRemoveHero(ListPicker[0].tokenId)"
                        >
                            Remove Hero
                        </b-button>
                        <b-button
                            v-else-if="true"
                            variant="none"
                            class="btn-choose-hero relative"
                            v-b-modal.listHero-summon
                        >
                            Choose Hero
                        </b-button>
                        <b-button
                            v-else
                            variant="none"
                            class="btn-choose-hero relative"
                            @click="ComingSoon()"
                        >
                            Choose Hero
                        </b-button>
                    </div>
                </b-col>

                <b-col
                    lg="4"
                    class="mt-3 column column-center px-lg-0 d-flex align-items-center justify-content-center"
                >
                    <div class="summon-violet" @click="showConfirm">
                        <div class="d-flex justify-content-center">
                            <h3 class="text-center border-bottom border-white">
                                SUMMON
                            </h3>
                        </div>

                        <div
                            class="d-flex align-items-center justify-content-center cursor-pointer"
                        >
                            <div class="img-badge">
                                <img
                                    src="~@/assets/images/mockup/summon/h-letter.png"
                                    class="w-100"
                                />
                            </div>
                            <div class="text-white ml-1">
                                <h4 class="mb-0">
                                    {{
                                        TruncateToDecimals2(
                                            infoSummon.totalHeronium || 0,
                                            '',
                                            1,
                                        )
                                    }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col lg="4" class="mt-3 column">
                    <div class="genesis-hero-item mt-3">
                        <div class="img mx-auto" v-if="!ListPicker[1]">
                            <!-- <img
                                src="~@/assets/images/mockup/summon/frame-quesion-mark.png"
                                class="w-100"
                            /> -->
                        </div>
                        <div class="hero" v-else>
                            <oneHero :hero="ListPicker[1]" />
                        </div>
                        <p
                            class="my-3 summon-count text-center"
                            v-if="ListPicker[1]"
                        >
                            Summon Count
                            <span class="text-white">
                                {{ ListPicker[1].quantitySummon }}
                            </span>
                        </p>
                        <b-button
                            v-if="ListPicker[1]"
                            variant="none"
                            class="btn-choose-hero remove relative"
                            @click="onRemoveHero(ListPicker[1].tokenId)"
                        >
                            Remove Hero
                        </b-button>
                        <b-button
                            v-else-if="true"
                            variant="none"
                            class="btn-choose-hero relative"
                            v-b-modal.listHero-summon
                        >
                            Choose Hero
                        </b-button>
                        <b-button
                            v-else
                            variant="none"
                            class="btn-choose-hero relative"
                            @click="ComingSoon()"
                        >
                            Choose Hero
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <div class="frame-estimate mt-5 text-center position-relative">
                <!-- // not available -->
                <div
                    class="position-relative main-frame-est"
                    style="z-index: 1"
                >
                    <div class="dots"></div>
                    <p
                        class="mb-0 d-flex align-items-start justify-content-center flex-column"
                    >
                        Time:
                        <span class="text-white">
                            {{ infoSummon.predictSummon.minWeek || 0 }}
                            week ~
                            {{ infoSummon.predictSummon.maxWeek || 0 }}
                            week
                        </span>
                    </p>
                    <div class="horizon-line"></div>
                    <p
                        class="mb-0 d-flex align-items-start justify-content-center flex-column"
                    >
                        Box:
                        <span class="text-white">
                            {{ infoSummon.predictSummon.minBox || 0 }}
                            Box ~
                            {{ infoSummon.predictSummon.maxBox || 0 }}
                            Box
                        </span>
                    </p>
                    <div class="dots"></div>
                    <div class="d-flex align-items-center">
                        <p class="mb-0">Result:</p>
                        <div class="img-box mx-5">
                            <img
                                :src="
                                    require(`@/assets/images/mockup/shop/herobox.png`)
                                "
                                class="w-100"
                            />
                        </div>
                        <p class="text-white mb-0">Hero Box</p>
                    </div>
                </div>
            </div>
            <b-row
                class="justify-content-center"
                v-if="isSummonProcess && hero && hero.length >= 2"
            >
                <b-col lg="4" class="mt-3 column">
                    <div class="genesis-hero-item mt-3">
                        <div class="img ml-0 mr-0" v-if="!hero[0]">
                            <img
                                src="~@/assets/images/mockup/summon/frame-quesion-mark.png"
                                class="w-100"
                            />
                        </div>
                        <div class="hero" v-else>
                            <oneHero :hero="hero[0]" />
                        </div>
                        <p class="my-3 summon-count text-center" v-if="hero[0]">
                            Summon Count
                            <span class="text-white">
                                {{ hero[0].quantitySummon }}
                            </span>
                        </p>
                    </div>
                </b-col>

                <b-col lg="4" class="mt-3 column column-center px-lg-0 d-flex">
                    <div class="summon-violet m-auto">
                        <div class="d-flex justify-content-center">
                            <h3 class="text-center border-bottom border-white">
                                SUMMON
                            </h3>
                        </div>

                        <p class="mb-0 text-center text-white mb-2">
                            Processing
                        </p>
                    </div>
                </b-col>

                <b-col lg="4" class="mt-3 column">
                    <div class="genesis-hero-item mt-3">
                        <div class="img ml-0 mr-0" v-if="!hero[1]">
                            <img
                                src="~@/assets/images/mockup/summon/frame-quesion-mark.png"
                                class="w-100"
                            />
                        </div>
                        <div class="hero" v-else>
                            <oneHero :hero="hero[1]" />
                        </div>
                        <p class="my-3 summon-count text-center" v-if="hero[1]">
                            Summon Count
                            <span class="text-white">
                                {{ hero[1].quantitySummon }}
                            </span>
                        </p>
                    </div>
                </b-col>
            </b-row>
            <!-- ---------------------------------------------------------- -->
            <div class="frame-estimate mt-5 text-center position-relative">
                <!-- // not available -->
                <div
                    class="position-relative main-frame-est"
                    style="z-index: 1"
                    v-if="isSummonProcess"
                >
                    <div class="dots"></div>
                    <p
                        class="mb-0 d-flex align-items-start justify-content-center flex-column"
                    >
                        Time:
                        <span
                            class="mb-0 text-center font-weight-bold d-flex align-items-start justify-content-center flex-column"
                        >
                            <CountDown
                                v-if="summon.timeOpen"
                                :time="summon.timeOpen"
                            />
                        </span>
                    </p>
                    <div class="horizon-line"></div>
                    <div class="dots"></div>
                    <div class="d-flex align-items-center">
                        <p class="mb-0">Result:</p>
                        <div class="img-box mx-5">
                            <img
                                :src="
                                    require(`@/assets/images/mockup/shop/herobox.png`)
                                "
                                class="w-100"
                            />
                        </div>
                        <p class="text-white mb-0">Hero Box</p>
                    </div>
                </div>
            </div>
            <!-- ------------------------------------------------ -->
        </div>
        <b-modal
            id="listHero-summon"
            :hide-footer="true"
            title="List Hero"
            :centered="true"
            body-class="p-1"
            content-class="border-0"
            size="xl"
        >
            <Hero
                class="my-2"
                :isSummon="true"
                :version="$store.state.contract.marketplace.hero2.addr"
            />
        </b-modal>
        <b-modal id="confirm-summon" title="Confirm Summon" hide-footer>
            <div>
                <p class="mb-0 text-confirm">Do you want to Summon Hero?</p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="confirmSummon"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal id="promotion-summon-success" title="Successfully" hide-footer>
            <div v-if="dataSummon && dataSummon.promotionSummonNew">
                <p class="mb-0 text-confirm text-confirm-2">
                    Summon Successful, Your are receive
                    {{ dataSummon.promotionSummonNew.amountHeronium }} Heronium
                    by promotion first Legend Summon 2.0
                </p>
                <div class="img-box mx-auto mt-3 text-center">
                    <img
                        src="~@/assets/images/mockup/account/heronium.png"
                        class="w-100"
                        style="max-width: 275px"
                    />
                </div>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('promotion-summon-success')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="message-summon-success"
            title="Successfully"
            hide-footer
            @hidden="onClearSummon"
        >
            <div>
                <p
                    class="mb-0 text-confirm text-confirm-2"
                    v-html="dataSummon.message"
                ></p>
                <div class="img-box mx-auto mt-3 text-center">
                    <img
                        :src="
                            require(`@/assets/images/mockup/shop/herobox.png`)
                        "
                        class="w-100"
                        style="max-width: 275px"
                    />
                </div>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('message-summon-success')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>
<style lang="scss">
.text-confirm-2 {
    font-size: 1.2em;
}
</style>
<style lang="scss" scoped>
.mystery-receive {
    color: #00ffff;
    font-size: 1.5em;
    font-weight: 800;
    font-family: 'HHBI';
    line-height: 150%;
    text-align: center;
    padding: 0px 10px;
    margin: 10px auto;
}
.btn-choose-hero {
    cursor: pointer;
    width: 150px;
    outline: none;
    height: 60px;
    display: flex;
    position: relative;
    padding: 0;
    outline: none;
    font-family: 'Poppins';
    font-size: 17px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none;
    color: #00ffff;
    border: 1px solid #00ffff;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.btn-choose-hero:before {
    content: '';
    height: 60px;
    width: 150px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00ffff;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    @media (max-width: 991px) {
        height: 60px;
        width: 150px;
    }
    @media (max-width: 575px) {
        height: 60px;
        width: 150px;
    }
}
.btn-choose-hero:hover,
.btn-choose-hero:focus,
.btn-choose-hero:active {
    color: black;
}
.btn-choose-hero:hover:before,
.btn-choose-hero:focus:before,
.btn-choose-hero:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.remove {
    position: unset;
    background: red;
    color: #ffff;
    height: 50px;
    border: none;
    transform: unset;
    &:hover {
        box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
            0 0 42px rgba(164, 0, 0, 0.745), 0 0 82px rgba(164, 0, 0, 0.745),
            0 0 92px rgba(164, 0, 0, 0.745);
    }
}
.genesis {
    .genesis-header {
        .btn-back {
            color: #00ffff;
            font-size: 20px;
            font-family: 'helvetica';

            position: absolute;
            // top: 0;
            bottom: 100%;
            left: 0;
            transition: 0.3s;
            cursor: pointer;

            img {
                width: 25px;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .genesis-title {
            background-image: url(~@/assets/images/frame/frame-large.png);
            background-size: 100% 100%;
            padding: 20px;

            .text {
                font-family: hemiheadRg-BoldItalic;
                font-size: 42px;
                color: #00ffff;
            }
        }
    }

    .column {
        max-width: 300px;
    }

    @media (max-width: 885px) {
        .column-center {
            order: 3;
        }
    }

    .genesis-hero-item,
    .frame-quesion-mark {
        position: relative;
        .img {
            background: transparent;
            max-width: 235px;
            width: 100%;
            height: 300px;
            margin: 0 auto;
            position: relative;
            background: linear-gradient(to right, #00ffff 4px, transparent 4px)
                    0 0,
                linear-gradient(to right, #00ffff 4px, transparent 4px) 0 100%,
                linear-gradient(to left, #00ffff 4px, transparent 4px) 100% 0,
                linear-gradient(to left, #00ffff 4px, transparent 4px) 100% 100%,
                linear-gradient(to bottom, #00ffff 4px, transparent 4px) 0 0,
                linear-gradient(to bottom, #00ffff 4px, transparent 4px) 100% 0,
                linear-gradient(to top, #00ffff 4px, transparent 4px) 0 100%,
                linear-gradient(to top, #00ffff 4px, transparent 4px) 100% 100%;

            background-repeat: no-repeat;
            background-size: 20px 20px;

            // border: 3px solid #00ffff;
        }
        .hero {
            cursor: pointer;
            position: relative;
            background: linear-gradient(45deg, #022534, #02404e);
            border: 2px solid #00505c;
            width: 100%;
            min-width: 170px;
            max-width: 235px;
            padding: 15px 0;
            margin: 5px auto;
            flex: 1;
            min-height: 225px;
        }
        .summon-count {
            font-family: 'hemiheadRg-BoldItalic';
            color: #00ffff;
            text-shadow: 0px 0px 10px #00ffff;
        }

        .btn-choose-hero {
            cursor: pointer;
            display: flex;

            &-text {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;

                span {
                    font-family: hemiheadRg-BoldItalic;
                    font-size: 18px;
                    text-shadow: 0px 0px 10px #00ff60;
                }
            }
        }
    }
    .frame-estimate {
        font-family: 'hemiheadRg-BoldItalic';
        color: #00ffff;
        .main-frame-est {
            border-bottom-right-radius: 24px;
            border-top-left-radius: 24px;
            background: rgba(41, 56, 64, 0.5);
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            min-height: 84px;
        }
        .horizon-line {
            position: relative;
            width: 1px;
            min-height: 55px;
            margin: auto 0;
            background: #00ffff;
        }
        .dots {
            background: #00ffff;
            width: 4px;
            height: 4px;
            border-radius: 100%;
        }
        h3 {
            font-size: 23px;
        }

        .bg-half-hexagon {
            width: 100%;

            position: absolute;
            // bottom: -50px;
            bottom: 0;
            left: 0;
        }

        .img-box {
            width: 65px;
            height: 65px;
        }
    }
    .frame-process {
        border: 1px solid #00ffff;
        background-color: #fffff748;
        .process {
            width: auto;
            height: 100%;
            background-image: linear-gradient(to right, #00ffa8, #00b0ff);

            left: 0;
            top: 0;
        }
        p {
            position: relative;
            z-index: 1;

            font-family: 'helvetica';
            font-size: 12px;
            font-weight: bold;
            color: #01171d;
        }
        span {
            font-weight: bold;
        }
    }

    .summon-violet {
        background-image: url(~@/assets/images/mockup/summon/summon-bg.png);
        background-size: 100% 100%;
        padding: 20px 0;
        transition: 0.3s;
        max-width: 200px;
        width: 100%;
        &.blue {
            background-image: url(~@/assets/images/mockup/summon/hexagon-violet-blue.png);
        }

        font-family: 'Poppins';

        h3 {
            font-size: 12px;
            color: #00ffff;
        }

        .img-badge {
            width: 30px;
            height: 30px;
        }

        h4 {
            font-size: 14px;
        }
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
}
</style>
