const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        agencyPackage: [],
        agencyPackageUP: [],
        statistical: {
            isBuyAgency: false,
        },
        member: {
            total: 1,
            list: [],
        },
    }),

    getters: {
        AgencyPackage: (state) => state.agencyPackage,
        AgencyPackageUP: (state) => state.agencyPackageUP,
        Statistical: (state) => state.statistical,
        Member: (state) => state.member,
    },

    actions: {
        req_getAgencyPackage: ({ commit }) => {
            axios.get('/api/agency/list').then((res) => {
                if (res) {
                    commit('SET_LIST_AGENCY_PACKAGE', res);
                }
            });
        },
        req_getAgencyPackageUP: ({ commit }) => {
            axios.get('/api/agency/all-packet').then((res) => {
                if (res) {
                    commit('SET_LIST_AGENCY_PACKAGE_UP', res);
                }
            });
        },
        req_postBuyAgencyPackage: ({ commit }, input) => {
            axios.post('/api/agency/buy', input).then((res) => {
                if (res) {
                    commit('BUY_AGENCY_PACKAGE', res);
                }
            });
        },
        req_postRenewAgencyPackage: ({ commit }) => {
            axios.get('/api/agency/re-new').then((res) => {
                if (res) {
                    commit('BUY_AGENCY_PACKAGE', res);
                }
            });
        },
        req_getStatisticalAgency: ({ commit }) => {
            axios.get('/api/agency/statistic').then((res) => {
                if (res) {
                    commit('SET_STATISTICAL_AGENCY', res);
                }
            });
        },
        req_getListMember: ({ commit }, input) => {
            axios
                .get('/api/agency/my-member', {
                    params: {
                        search: input.search || null,
                        page: input.page || 1,
                        address: input.address || null,
                    },
                })
                .then((res) => {
                    commit('SET_LIST_MEMBER', res);
                });
        },
    },

    mutations: {
        SET_LIST_AGENCY_PACKAGE(state, data) {
            const { results } = data;
            state.agencyPackage = results;
        },
        SET_LIST_AGENCY_PACKAGE_UP(state, data) {
            const { results } = data;
            state.agencyPackageUP = results;
        },
        BUY_AGENCY_PACKAGE() {},
        SET_STATISTICAL_AGENCY(state, data) {
            state.statistical = data;
        },
        SET_LIST_MEMBER(state, data) {
            state.member.total = data.total_page;
            state.member.list = data.results;
        },
    },
};
