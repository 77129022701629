<script>
import { mapGetters, mapMutations } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import { StartEffect, StopEffect } from '@/plugins/effect';
import { listBox } from './fake';

const axios = require('@/store/axios.config').default;

export default {
    components: { Carousel, Slide },
    data() {
        return {
            listBox,
            heroList: [],
            quantityOpen: 1,
            typeBox: 'Silver',
            openNumber: [1, 5, 12],
            showCarousel: true,
            quantity: 1,
            rewardList: [],
        };
    },
    computed: {
        ...mapGetters({
            ListBox: 'inventory/ListBox',
            // ListBoxStore: 'shop/ListBoxStore',
        }),
    },

    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        showConfirmOpen(typeBox) {
            this.typeBox = typeBox;
            this.$bvModal.show('confirm-open');
        },
        confirmOpen(quantity) {
            if (!quantity) {
                this.quantityOpen = 1;
            }
            this.quantityOpen = quantity;
            this.onOpen();
        },
        async onOpen() {
            this.onLoad();

            const { quantityOpen, typeBox } = this.$data;
            if (quantityOpen > this.ListBox[typeBox]) {
                this.$toastr.e(
                    'Open quantity exceeds remaining quantity!',
                    'Open Box Failed',
                );
                this.outLoad();
                return;
            }
            if (quantityOpen < 1) {
                this.$toastr.e(
                    'The number of boxes you want to open must be greater than or equal to one and must be an integer 1!',
                    'Open Box Failed',
                );
                this.outLoad();
                return;
            }
            const hero = await axios.post(`api/box/open/multi/${typeBox}`, {
                quantity: parseInt(quantityOpen, 10),
            });
            if (!hero || hero.length <= 0) {
                this.$toastr.e(
                    'Something went wrong! Please try again!',
                    'Open Box Failed',
                );
                this.$bvModal.hide('confirm-open');
                return;
            }
            this.heroList = hero;
            this.$bvModal.hide('confirm-open');

            this.$bvModal.show('OpenBox');

            this.outLoad();
        },
        onCloseReceiver() {
            this.Stop();
            this.getBOX();
            this.heroList = [];
            this.quantityOpen = 1;
        },
        getBOX() {
            this.$store.dispatch('inventory/req_getListBox');
        },
        onOpenReceiver() {
            this.$bvModal.hide('OpenBox');
            this.Start();
            if (this.typeBox.toLowerCase() === 'herobox') {
                this.$bvModal.show('HeroBoxReward');
            } else {
                this.$bvModal.show('heroReceiver');
            }
            this.showCarousel = false;
            setTimeout(() => {
                this.$refs.carousel.onResize();
                this.$refs.carousel.goToPage(0);
            }, 200);
        },
        Start() {
            StartEffect();
        },
        Stop() {
            StopEffect();
        },
    },
    created() {
        this.getBOX();
        this.$store.dispatch('shop/req_getBoxStore');
    },
};
</script>

<template>
    <b-container class="p-0">
        <div class="box-list-open">
            <b-row>
                <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    lg="4"
                    sm="6"
                    v-for="(idx, name) in ListBox"
                    :key="`list-box-inventory-${name}-${idx}`"
                >
                    <div class="inventory-box-item">
                        <div class="inventory-item-image">
                            <img
                                :src="
                                    require(`@/assets/images/mockup/account/${name.toLowerCase()}.png?v=1`)
                                "
                                alt=""
                                class="w-100 mt-5"
                            />
                        </div>
                        <div class="inventory-box-item-content">
                            <div class="d-flex align-items-center">
                                <img
                                    src="../../../assets/images/icons/icon-title-land.svg"
                                    alt=""
                                />
                                <div class="land-name-content">{{ name }}</div>
                            </div>
                            <hr />
                            <div
                                class="d-flex justify-content-between box-title"
                            >
                                <div>You Have:</div>
                                <div
                                    v-if="
                                        ListBox[name] !== null ||
                                        ListBox[name] !== undefined
                                    "
                                >
                                    <span>
                                        {{ ListBox[name] }}
                                        Box
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center mt-3">
                                <button
                                    @click="showConfirmOpen(name)"
                                    class="bede-btn"
                                >
                                    <p>Open Box</p>
                                </button>
                            </div>
                            <div class="inventory-item-line">
                                <img
                                    src="../../../assets/images/icons/icon-line-svg.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-modal
            id="heroReceiver"
            @hidden="onCloseReceiver"
            :title="`Received ${heroList.length} Hero`"
            hide-footer
            size="xl"
        >
            <carousel
                ref="carousel"
                v-if="
                    heroList.length > 0 &&
                    this.typeBox.toLowerCase() !== 'herobox'
                "
                :key="`carousel-hero-receiver-${heroList.length}`"
                :perPage="4"
                :mouseDrag="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                :autoplay="true"
                :autoplayTimeout="2000"
                :perPageCustom="[
                    [320, 1],
                    [600, 2],
                    [1024, 3],
                    [1200, 4],
                    [1800, 4],
                ]"
                :centerMode="true"
                :loop="false"
                navigation-next-label
                navigation-prev-label
            >
                <slide
                    v-for="hero in heroList"
                    :key="`carousel-hero-receiver-#${hero.tokenId}`"
                >
                    <div class="hero hero-receiver mx-auto">
                        <div class="left-hero mx-auto">
                            <div class="top-hero">
                                <div class="id-hero" ref="idHero">
                                    #{{ hero.tokenId }}
                                </div>
                                <div class="hero-img">
                                    <div class="avatar-hero">
                                        <img
                                            v-if="hero.name"
                                            :src="`https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/${hero.name.toLowerCase()}-${hero.skin.toLowerCase()}.png`"
                                            alt=""
                                        />
                                    </div>
                                    <div class="right-hero">
                                        <div class="stat-hero">
                                            <div class="hero-level">
                                                {{
                                                    10 > hero.level
                                                        ? '0' +
                                                          hero.level.toString()
                                                        : hero.level
                                                }}
                                            </div>
                                            <img
                                                :key="`inventory-${hero.id}`"
                                                :src="
                                                    require(`@/assets/images/mockup/inventory/${hero.ratity.toLowerCase()}.png`)
                                                "
                                                alt=""
                                            />
                                            <img
                                                :key="`inventory-2-${hero.id}`"
                                                :src="
                                                    require(`@/assets/images/mockup/inventory/${hero.skin.toLowerCase()}.png?v=1`)
                                                "
                                                alt=""
                                            />
                                            <img
                                                :key="`inventory-3-${hero.id}`"
                                                :src="
                                                    require(`@/assets/images/mockup/inventory/${hero.type.toLowerCase()}.png?v=1`)
                                                "
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-hero">
                                <div class="hero-name">
                                    the
                                    <span>
                                        {{ hero.name }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>

            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-3 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('heroReceiver')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="OpenBox"
            hide-header
            body-class="p-0"
            hide-footer
            modal-class="customer-open-box"
            no-close-on-backdrop
        >
            <video-background
                v-if="typeBox"
                :src="require(`@/assets/video/${typeBox.toLowerCase()}.mp4`)"
                class="video-cn"
                :loop="false"
                @ended="onOpenReceiver"
            >
            </video-background>
        </b-modal>
        <b-modal
            id="confirm-open"
            title="Confirm Open Box"
            size="md"
            hide-footer
        >
            <div>
                <p class="mb-4 text-confirm">How many boxes do you need?</p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-row>
                    <b-col cols="12">
                        <img
                            :src="
                                require(`@/assets/images/mockup/account/${typeBox.toLowerCase()}.png?v=1`)
                            "
                            alt=""
                            class="d-sm-block mx-auto"
                            width="100%"
                            style="max-width: 245px"
                        />
                        <div class="d-flex flex-column input-opn">
                            <label for="inputAmount">Open Quantity</label>
                            <b-form-input
                                min="1"
                                v-model.number="quantityOpen"
                                type="number"
                                id="inputAmount"
                                placeholder="Quantity"
                                step="1"
                                class="main-bg"
                                pattern="[0-9]{3}"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            ></b-form-input>
                            <small class="mt-2 text-danger"
                                >Maximum 50 box</small
                            >
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div
                class="form-group d-flex justify-content-center mt-3 input-opn"
            >
                <b-button
                    class="button-confirm my-2 mb-0"
                    variant="primary"
                    @click="onOpen"
                    :disabled="
                        quantityOpen > ListBox[typeBox] || quantityOpen > 50
                    "
                >
                    Open {{ quantityOpen }}
                </b-button>

                <b-button
                    class="mx-auto my-2 mb-0 button-cancel"
                    variant="primary"
                    @click="$bvModal.hide('confirm-open')"
                >
                    Cancel
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="HeroBoxReward"
            title="Open Hero Box Received"
            @hidden="onCloseReceiver"
            size="xl"
            hide-footer
        >
            <carousel
                ref="carousel"
                v-if="heroList.length > 0"
                :key="`carousel-hero-receiver-${heroList.length}`"
                :perPage="4"
                :mouseDrag="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                :autoplay="true"
                :autoplayTimeout="2000"
                :perPageCustom="[
                    [320, 1],
                    [600, 2],
                    [1024, 3],
                    [1200, 4],
                    [1800, 4],
                ]"
                :centerMode="true"
                :loop="false"
                navigation-next-label
                navigation-prev-label
            >
                <slide
                    v-for="(rewardItem, idx) in heroList"
                    :key="`carousel-hero-receiver-#${idx}`"
                >
                    <div class="box">
                        <div class="box-img">
                            <img
                                :class="rewardItem.image"
                                :src="`https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/mystery-box/${rewardItem.image}.png`"
                                alt=""
                                class="d-sm-block mx-auto"
                                width="100%"
                            />
                        </div>
                        <div class="box-available box-name platinum">
                            <div>You Have Receive:</div>
                            <div>
                                <span>{{ rewardItem.name }} </span>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>

            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-3 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('HeroBoxReward')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<style lang="scss">
.input-opn {
    max-width: 350px;
    margin: 10px auto;
    input {
        width: 100%;
        margin: 0 auto;
    }
    label {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
    }
    small {
        font-size: 15px;
        color: #fff;
        margin-bottom: 0;
    }
}
#heroReceiver {
    .VueCarousel-navigation-next {
        right: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
    }
    .VueCarousel-navigation-prev {
        left: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
    }
    .VueCarousel-inner {
        visibility: visible !important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    @media (max-width: 575px) {
        .VueCarousel-navigation-next {
            right: 5% !important;
        }
        .VueCarousel-navigation-prev {
            left: 5% !important;
        }
    }
    .hero-receiver {
        flex: 1;
        min-width: 200px;
        max-width: 225px;
        width: 100%;
        margin: 0 15px 5px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 1 1 auto;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        border: 1px solid #00ffff;
                        max-width: calc(100% - 50px);
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .right-hero {
                        flex: 0 0 50px;
                        max-width: 50px;
                        .stat-hero {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            .hero-level {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ffff;
                                border: 1px solid #00ffff;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                            }
                            img {
                                width: 35px;
                                max-height: 35px;
                                height: auto;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }
                .hero-exp {
                    position: relative;
                    width: 100%;
                    border: 1px solid #00ffff;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px auto 15px;
                    background: rgba(0, 255, 255, 0.2);
                    .text {
                        position: relative;
                        z-index: 10;
                        font-weight: 500;
                        font-size: 12px;
                        font-style: italic;
                        span {
                            font-weight: 700;
                            font-family: 'Helvetica';
                            font-size: 15px;
                        }
                    }
                    .current {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
                .hero-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        width: auto;
                        padding: 5px;
                        min-width: 100px;
                        height: 40px;
                        border-radius: 5px;
                        border: 0;
                        color: #ffff;
                        &.sell {
                            background: {
                                image: url('~@/assets/images/mockup/account/sell.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.transfer {
                            background: {
                                image: url('~@/assets/images/mockup/account/transfer.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.price {
                            background: {
                                image: url('~@/assets/images/mockup/account/price.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.cancel {
                            background: {
                                image: url('~@/assets/images/mockup/account/cancel.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
#heroReceiver___BV_modal_outer_ {
    z-index: unset !important;
}
.customer-open-box {
    .modal-dialog {
        // max-width: 320px;

        .modal-content {
            border-radius: 5px;
        }
        .video-cn {
            width: 100%;
            position: relative;
            padding-bottom: 74.69%; /* ratio 16/9 */
        }
    }
}
.nav-box {
    display: flex;
    justify-content: space-around;
    align-content: center;
    padding: 10px 20px;
    &-item {
        background-image: url(~@/assets/images/mockup/summon/Summon.png);
        background-size: 100% 100%;
        padding: 5px 20px;

        font-family: 'helvetica-neue-regular';
        font-size: 20px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 0px 10px #00ff60;

        cursor: pointer;
        transition: 0.3s;
        opacity: 0.7;
        &.active,
        &:hover {
            opacity: 1;
        }
    }
}
.box-list-open {
    .land-name-content {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #f8f8f8;
        padding-left: 25px;
    }
    .box-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #f8f8f8;
    }
    .bede-btn {
        max-width: 136px;
        height: 38px;
        border-radius: 105px;
        outline: none !important;
        p {
            margin: 0;
        }
        &::before {
            border-radius: 105px;
        }
    }
    .box {
        width: 100%;
        min-height: 260px;
        padding: 5px 0 15px;
        margin: 0 auto 25px;
        max-width: 300px;
        background: {
            image: url(~@/assets/images/mockup/account/body-box.png);
            repeat: no-repeat;
            size: 100% 100%;
        }
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                object-fit: cover;
                max-height: 165px;
                object-fit: contain;
                object-position: bottom;
                @media (max-width: 767px) {
                    object-fit: contain;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
        .box-available {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0px 10px;
            text-align: center;
            color: #fff;
            span {
                color: #00ffff;
                font-weight: 600;
            }
        }
        .box-open {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border-radius: 7px;
            width: 120px;
            height: 40px;
            padding: 5px;
            button {
                color: #000;
                border-radius: 5px;
                font-weight: 600;
                border: 1.5px solid #fff;
                padding: 0;
                height: 100%;
                width: 100%;
                background: transparent;
                box-shadow: 0px 0px 5px #00ffff;
                text-transform: uppercase;
            }
        }
    }
}
#HeroBoxReward___BV_modal_outer_ {
    z-index: unset !important;
}
#HeroBoxReward {
    .VueCarousel-navigation-next {
        right: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        outline: none;
        opacity: 1 !important;
    }
    .VueCarousel-navigation-prev {
        left: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
        outline: none;
    }
    .VueCarousel-inner {
        visibility: visible !important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    .box-i,
    .box {
        max-width: 200px;
        width: 100%;
        padding: 5px 0 15px;
        margin: 0 auto 5px;
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            width: 100%;
            overflow: hidden;
            text-align: center;
            position: relative;
            max-width: 200px;
            margin: 10px auto;
            min-height: 200px;
            display: flex;
            align-items: flex-end;
            img {
                width: 100%;
                height: 100%;
                max-width: 130px;
                object-fit: scale-down;
                &.diamond,
                &.silver,
                &.platinum,
                &.gold {
                    max-width: 150px;
                }
                &.hbg {
                    max-width: 110px;
                }
                object-fit: scale-down;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.2em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.inventory-item-image {
    img {
        height: 123px;
        width: auto;
    }
}
</style>
