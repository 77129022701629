<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            showDetails: false,
            login: {
                uEmail: '',
                uPassword: '',
            },
            submitted: false,
        };
    },
    validations: {
        login: {
            uPassword: {
                required,
            },
            uEmail: {
                required,
                email,
            },
        },
    },
    methods: {
        onLogin() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.login.$invalid) {
                return;
            }
            this.$store.dispatch('auth/LoginEmail', {
                email: this.login.uEmail,
                password: this.login.uPassword,
            });
        },
    },
};
</script>

<template>
    <div class="loginByEmail">
        <b-button
            variant="none"
            class="emailBTN"
            @click="showDetails = !showDetails"
        >
            <div class="text">Login with email & password</div>
            <div class="icons">
                <img
                    src="~@/assets/images/icons/drop.png"
                    :style="`transform: rotate(${
                        showDetails ? '0' : '180deg'
                    })`"
                    alt=""
                />
            </div>
        </b-button>
        <div class="box-login" v-if="showDetails">
            <b-form @submit.prevent="onLogin">
                <b-form-group>
                    <b-form-input
                        type="email"
                        v-model="login.uEmail"
                        placeholder="Email"
                        :class="{
                            'is-invalid': submitted && $v.login.uEmail.$error,
                        }"
                    ></b-form-input>
                    <div
                        v-if="submitted && $v.login.uEmail.$error"
                        class="invalid-feedback"
                    >
                        <span class="pt-3" v-if="!$v.login.uEmail.required">
                            This field is required
                        </span>
                        <span v-if="!$v.login.uEmail.email">
                            Is not a valid email
                        </span>
                    </div>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        type="password"
                        v-model="login.uPassword"
                        placeholder="Password"
                        :class="{
                            'is-invalid': submitted && $v.login.uPassword.$error,
                        }"
                    ></b-form-input>
                    <div
                        v-if="submitted && $v.login.uPassword.$error"
                        class="invalid-feedback"
                    >
                        <span class="pt-3" v-if="!$v.login.uPassword.required">
                            This field is required
                        </span>
                    </div>
                </b-form-group>
                <b-form-group>
                    <!-- <router-link to="/forgot"> Forget password ? </router-link> -->
                </b-form-group>
                <b-form-group
                    class="d-flex justify-content-center align-items-center mb-0"
                >
                    <b-button type="submit"> Sign In </b-button>
                </b-form-group>
            </b-form>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.loginByEmail {
    width: 100%;
    max-width: 375px;
    .emailBTN {
        border: 1px solid #00ffff;
        background: linear-gradient(180deg, #18274a 0%, #001326 100%);
        color: #fff;
        font-weight: 500;
        height: 50px;
        width: 100%;
        max-width: 375px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 0;
        &:hover {
            color: #00ffff;
        }
        .icons {
            padding-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 17px;
                width: auto;
            }
        }
    }
    .box-login {
        min-height: 300px;
        background: {
            image: url(~@/assets/images/mockup/auth/box.png);
            size: 100% 100%;
        }
        padding: 15px;
        .form-group {
            margin: 1.75rem auto 1.75rem !important;
            text-align: right;
            a {
                color: #00ffff;
                font-size: 17px;
                font-weight: 500;
            }
            input {
                color: #fff;
                font-weight: 600;
                letter-spacing: 1px;
                &::-webkit-input-placeholder {
                    color: #fff;
                    opacity: 0.4;
                    text-align: center;
                    font-weight: 700;
                    font-size: 18px;
                    letter-spacing: 1px;
                }
                height: 45px;
                border: 1px solid #00ffff;
                background: linear-gradient(180deg, #18274a 0%, #001326 100%);
                border-radius: 0;
            }
            button {
                background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
                color: #002338;
                width: 150px;
                height: 40px;
                font-weight: 700;
                font-size: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>
