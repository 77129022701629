<script>
import { mapGetters } from 'vuex';

const axios = require('@/store/axios.config').default;

export default {
    data() {
        return {
            listItem: [
                {
                    name: 'Mystery Box',
                },
            ],
            tabItem: [
                {
                    name: 'Box',
                    value: 'box',
                    request: 'info/req_getBalance',
                },
                {
                    name: 'Tournament',
                    value: 'tournament',
                    request: 'inventory/req_getListTicket',
                },
            ],
            itemActive: 'box',
            ticketPick: null,
        };
    },
    computed: {
        ...mapGetters({
            BalanceSystem: 'info/Balance',
            ListTicket: 'inventory/ListTicket',
        }),
    },
    methods: {
        changeTabs(tab) {
            this.itemActive = tab.value;
            this.$store.dispatch(tab.request);
        },
        async onUsingTicket(id) {
            const data = await axios.post('/api/me/ticket', {
                _id: id,
            });
            if (!data) {
                return;
            }
            this.$bvModal.hide('confirm-use-ticket');
            this.$store.dispatch('inventory/req_getListTicket');
            this.$store.dispatch('info/req_getInfo');
        },
        pickTicket(ticket) {
            this.ticketPick = ticket;
            this.$bvModal.show('confirm-use-ticket');
        },
    },
    async mounted() {
        this.$store.dispatch('inventory/req_getListTicket');
        this.$store.dispatch('info/req_getBalance');
    },
};
</script>
<template>
    <div class="container">
        <div class="Item-inventory">
            <div class="inventory-box-search">
                <div class="row">
                    <div class="col-sm-5 col-12 my-3">
                        <label
                            for="inputSelect"
                            class="d-flex align-items-center justify-content-between"
                        >
                            <select
                                class="custom-select"
                                v-model="itemActive"
                                id="inputSelect"
                            >
                                <option value="box" selected>Box</option>
                                <option value="tournament">Tournament</option>
                            </select>
                            <img
                                src="../../assets/images/icons/icon-drop.svg"
                                alt=""
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div class="list-item">
                <div class="list-content" v-if="itemActive == 'box'">
                    <b-row>
                        <b-col cols="12" xl="4" md="4" lg="4" sm="6">
                            <div class="inventory-box-item">
                                <div class="inventory-item-image">
                                    <img
                                        src="~@/assets/images/mockup/shop/mystery.png"
                                        alt=""
                                        class="w-100"
                                    />
                                </div>
                                <div class="inventory-box-item-content">
                                    <router-link :to="{ name: 'InGame' }">
                                        <div class="land-name-content">
                                            <p>MYSTERY BOX</p>
                                        </div>
                                    </router-link>
                                    <hr />
                                    <div
                                        class="box-available d-flex justify-content-between"
                                    >
                                        <div>You Have:</div>
                                        <div>
                                            <span>
                                                {{ BalanceSystem.MisteryBox }}
                                                Box</span
                                            >
                                        </div>
                                    </div>
                                    <router-link
                                        :to="{
                                            name: 'MysteryBoxDetail',
                                        }"
                                    >
                                        <div
                                            class="d-flex justify-content-center pt-4"
                                        >
                                            <button class="bede-btn">
                                                <p>Open Box</p>
                                            </button>
                                        </div>
                                    </router-link>
                                    <div class="inventory-item-line">
                                        <img
                                            src="../../assets/images/icons/icon-line-svg.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="list-content" v-if="itemActive == 'tournament'">
                    <b-row
                        v-if="
                            ListTicket.results && ListTicket.results.length > 0
                        "
                        class="mx-0"
                    >
                        <b-col
                            cols="12"
                            xl="4"
                            md="4"
                            lg="4"
                            sm="6"
                            v-for="ticket in ListTicket.results"
                            :key="ticket._id"
                            class="px-2"
                        >
                            <div class="box">
                                <div class="box-img px-2 pb-2">
                                    <img :src="ticket.image" alt="" />
                                </div>
                                <router-link
                                    :to="{ name: 'InGame' }"
                                    class="box-name gold px-3 text-center w-100 d-block"
                                >
                                    {{ ticket.name }}
                                </router-link>
                                <div class="">
                                    <b-button
                                        :disabled="ticket.isUsed"
                                        class="box-open"
                                        @click="pickTicket(ticket)"
                                    >
                                        {{ ticket.isUsed ? 'Used' : 'Use' }}
                                    </b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <div v-else class="empty-item-inventory">
                        <p>No Ticket !!!</p>
                    </div>
                </div>
            </div>
            <b-modal
                id="confirm-use-ticket"
                title="Confirm Using Ticket"
                hide-footer
            >
                <div>
                    <p class="mb-0 text-confirm" v-if="ticketPick">
                        Do you want to use
                        {{ ticketPick.name }}?
                    </p>
                </div>
                <div
                    class="form-group d-flex justify-content-center"
                    v-if="ticketPick"
                >
                    <b-button
                        class="mx-auto mt-5 mb-0 button-confirm"
                        variant="primary"
                        @click="onUsingTicket(ticketPick._id)"
                    >
                        Confirm
                    </b-button>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.custom-select {
    outline: unset;
    box-shadow: none;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 26px;
}
.Item-inventory {
    .box-available {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #f8f8f8;
    }
    .land-name-content {
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            color: #f8f8f8;
            margin: 0;
            text-align: center;
        }
    }
    a {
        text-decoration: none !important;
    }
    button.bede-btn {
        height: 38px;
        width: 100%;
        max-width: 135px;
        border-radius: 105px;
        &::before {
            border-radius: 105px;
            background: #182c35;
        }
    }
}
</style>
