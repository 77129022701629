<template>
	<div>
		<b-row>
			<b-col cols="12" class="mt-5 pt-5">
				<b-button @click="open5k()">
					Start Open 5k Mystery per one click
				</b-button>
				<export-excel
					class="btn btn-success mx-2"
					:data="dataOpen"
					:fields="json_fields2"
					worksheet="Mystery"
					name="OpenMysteryBox.xls"
					title
				>
					Download Excel Mystery
				</export-excel>
				<ul class="text-white">
					<li>Info:</li>
					{{
						hero
					}}
				</ul>
				<h1 class="text-white">
					tỉ lệ mở Mystery: {{ this.index * 50 }} box
				</h1>
				<ul v-if="counts">
					<li
						v-for="reward in listReward"
						:key="reward.name"
						class="text-white"
					>
						{{ reward.name }}:
						{{ counts[reward.name] }} (percent:
						{{ (counts[reward.name] / 5000) * 100 }}%)
					</li>
				</ul>
			</b-col>
		</b-row>
	</div>
</template>

<script>
const moment = require('moment');
const axios = require('@/store/axios.config').default;
const _ = require('lodash');

export default {
	data() {
		return {
			hero: 0,
			listReward: [],
			dataUpgrade: [],
			json_fields: {
				'TokenId Hero': 'tokenID',
				'Upgrade Status': 'status',
				'Upgrade Time': 'time',
				'Type Upgrade': 'type',
			},
			json_fields2: {
				Box: 'No',
				name: 'name',
			},
			index: 0,
			typeTesting: 2,
			countUpdate: {
				success: 0,
				failed: 0,
			},
			counts: {},
			dataOpen: [],
		};
	},
	methods: {
		async onFetchHero(type) {
			const params = {
				page: 1,
				limit: 100,
				ratity: type,
				summon: 1,
			};
			const hero = await axios.get(`api/hero/user-hero/${type}`, {
				params,
			});
			this.hero = hero.totalResults;
		},
		async onStart(type) {
			if (type) {
				this.typeTesting = type;
			}
			const params = {
				page: 1,
				limit: this.typeTesting,
				ratity: 'common',
				summon: 1,
			};
			const hero = await axios.get(`api/hero/user-hero/Common`, {
				params,
			});
			this.hero = hero.results;

			const ListIdPicker = [];
			for (let i = 0; i < hero.results.length; i += 1) {
				ListIdPicker.push(hero.results[i].tokenId);
			}
			const data = await axios.post('api/hero/upgrade-hero', {
				listHero: ListIdPicker,
			});
			console.log('index:', this.index, data);
			let showFailed = null;
			if (data.success) {
				showFailed = false;
				this.countUpdate.success += 1;
			} else {
				showFailed = true;
				this.countUpdate.failed += 1;
			}

			const dataExl = {
				type: 'common',
				tokenID: ListIdPicker.toString(),
				status: !showFailed ? 'Success' : 'failed',
				time: moment().format('DD/MM/YYYY, HH:mm:ss'),
			};

			this.dataUpgrade.push(dataExl);
			this.index += 1;
			if (this.index < 100) {
				this.onStart();
			}
		},
		async open5k() {
			const data = await axios.post('api/box/open/multi/Mistery', {
				quantity: 50,
			});
			for (let i = 0; i < data.length; i += 1) {
				this.dataOpen.push({
					no: this.index + i,
					name: data[i].name.name,
				});
			}
			const result = _(this.dataOpen)
				.groupBy('name')
				.map((item, itemId) => {
					const obj = {};
					obj[itemId] = _.countBy(item, 'name');
					return obj;
				})
				.value();
			for (let i = 0; i < result.length; i += 1) {
				Object.keys(result[i]).forEach((key) => {
					this.counts[key] = result[i][key][key];
				});
			}
			this.index += 1;

			if (this.index < 100) {
				this.open5k();
			}
		},
	},
	async mounted() {
		const mysteryBox = await axios.get('api/shop/mistery-box');
		this.listReward = mysteryBox.items;
	},
};
</script>

<style lang="scss" scoped>
button {
	margin: 15px;
}
</style>
