const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        error: '',
        success: '',
        type_account: [],
        notifileList: [],
        maintainers: 0,
        fileGame: null,
        fee: {
            transferHBG: 0,
            claimHBG: 0,
        },
        urlS3: {
            imgFull:
                'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/full/',
            img3d: 'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/3d/',
            img2d: 'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/',
        },
    }),

    getters: {
        Error: (state) => state.error,
        Success: (state) => state.success,
        typeAccount: (state) => state.type_account,
        NotifileList: (state) => state.notifileList,
        Maintainers: (state) => state.maintainers,
        FileGame: (state) => state.fileGame,
        Fee: (state) => state.fee,
        UrlS3: (state) => state.urlS3,
    },

    actions: {
        req_getNotificationImage({ commit }) {
            axios.get('api/v1/notification-image').then((res) => {
                if (res) {
                    commit('SET_NOTIFICATION_IMAGE', res);
                }
            });
        },
        req_getSettings() {
            axios.get('api/v1/setting-main').then((res) => {
                if (res) {
                    // commit('SET_SETTING', res);
                }
            });
        },
        req_getFileGame({ commit }) {
            axios.get(`api/system/get-name-game`).then((res) => {
                if (res) {
                    commit('SET_FILE_GAME', res);
                }
            });
        },
        req_getFee({ commit }) {
            axios.get('/api/system/get-fee').then((res) => {
                if (res) {
                    commit('SET_FEE', res);
                }
            });
        },
    },
    mutations: {
        SET_FILE_GAME(state, payload) {
            state.fileGame = payload;
        },
        SUCCESS_MESSAGE(state, message) {
            state.success = message;
        },
        ERROR_MESSAGE(state, message) {
            state.error = message;
        },
        ERROR_500(state, message) {
            state.status = message;
        },
        SET_NOTIFICATION_IMAGE(state, data) {
            state.notifileList = data.system;
        },
        SET_FEE(state, data) {
            state.fee = {
                transferHBG: data.feeTransfer,
                claimHBG: data.feeClaim,
            };
        },
    },
};
