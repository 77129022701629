<script>
import { mapGetters, mapMutations } from 'vuex';
// import { Item } from '@/components/market/fake';
import { MetaMask } from '@/components/auth';
import LandShow from '@/components/inventory/land/LandShow.vue';

const axios = require('@/store/axios.config').default;

export default {
    components: { MetaMask, LandShow },
    data() {
        return {
            infoHeight: '10px',
            resizeObserver: null,
            inventoryActive: 'Hero',
            ListOffer: [],
            tabActive: 'stat',
            fields: [
                {
                    key: 'ID',
                    sortable: false,
                    label: 'FROM',
                    class: 'text-center',
                },
                {
                    key: 'f',
                    label: 'PRICE',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: 'registeredDatetime',
                    label: 'TIME',
                },
            ],
            listNav: [
                {
                    name: 'Hero',
                    id: 1,
                    components: 'HeroList',
                },
                {
                    name: 'Land',
                    id: 2,
                    components: 'LandList',
                },
            ],
            DetailLand: null,
            receivedLand: {},
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            isLogin: 'auth/loggedIn',
        }),
        Land() {
            if (this.DetailLand) {
                return this.DetailLand.land;
            }
            return null;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        onResize() {
            if (window.innerWidth >= 992) {
                this.infoHeight = `${this.$refs.title.clientHeight + 10}px`;
            }
        },
        onBuy() {},
        getPercents(current, end) {
            const percent = (current / end) * 100;
            if (percent < 20) {
                return 20;
            }
            if (percent >= 100) {
                return 100;
            }
            return percent;
        },
        async onCancelLand(tokenId) {
            this.onLoad();
            this.getDetailLand();
            const { market } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await market.cont.methods
                .cancelLandSale(tokenId)
                .encodeABI();
            let gas = 0;
            try {
                gas = await market.cont.methods
                    .cancelLandSale(tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: 1000000,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: market.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancel', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async Approve(tokenId, amount = 1) {
            const { hbg } = this.$store.state.contract.bep_20;
            const { web3 } = this.$store.state.contract;
            const { market } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            this.onLoad();
            const txObj = hbg.cont.methods
                .approve(
                    market.addr,
                    (amount * 10 ** 18).toLocaleString('fullwide', {
                        useGrouping: false,
                    }),
                )
                .encodeABI();
            let gas = 0;
            try {
                // Calculate gas
                gas = await hbg.cont.methods
                    .approve(
                        market.addr,
                        (amount * 10 ** 18).toLocaleString('fullwide', {
                            useGrouping: false,
                        }),
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: 5000000,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;
            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hbg.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txData.status) {
                            this.onBuyLand(tokenId);
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        async onBuyLand() {
            this.onLoad();
            this.getDetailLand();
            const { tokenId } = this.DetailLand;
            const { market } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await market.cont.methods
                .buyLand(tokenId)
                .encodeABI();
            let gas = 0;
            try {
                gas = await market.cont.methods.buyLand(tokenId).estimateGas({
                    from: addr,
                    value: '0',
                    gas: 5000000,
                });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: market.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('buy', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async sendTransaction(type, txData) {
            if (type === 'buy') {
                const hero = await axios.post(
                    'api/market/order/land/buy/send-transaction',
                    {
                        transactionHash: txData.transactionHash,
                    },
                );
                this.receivedLand = hero;
                this.$bvModal.hide('confirm-buy');
                this.$bvModal.show('modal-received');
            }
            if (type === 'cancel') {
                this.$store.dispatch('land/req_postCancelSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.backToMarket();
                this.$bvModal.hide('confirm-cancel');
            }
        },
        async getDetailLand() {
            const tokenId = this.$route.params.id;
            const detail = await axios.get(
                `api/market/dashboard/detail/land/${tokenId}`,
            );
            if (!detail || detail.buyer !== null || detail.isCancel) {
                if (!detail) {
                    this.$toastr.e('Land has been bought or sold', 'Oops!');
                }
                if (detail.buyer !== null) {
                    this.$toastr.e('Land has been bought', 'Oops!');
                }
                if (detail.isCancel) {
                    this.$toastr.e('Land has been sold', 'Oops!');
                }
                this.$router.push({ name: 'LandList' });
                this.outLoad();
                return;
            }
            if (detail.land == null) {
                this.$toastr.e('Something went wrong', 'Oops!');
                this.$router.push({ name: 'LandList' });
                this.outLoad();
                return;
            }
            if (detail) {
                this.DetailLand = detail;
            }
        },
        backToMarket() {
            this.$router.push({ name: 'LandList' });
        },
    },
    beforeUnmount() {
        this.resizeObserver.unobserve(this.$refs.title);
    },
    mounted() {
        this.onLoad();
        setTimeout(() => {
            if (
                this.$refs.title &&
                window.innerWidth >= 992 &&
                this.DetailLand &&
                this.DetailLand.land
            ) {
                this.resizeObserver = new ResizeObserver(this.onResize);
                this.resizeObserver.observe(this.$refs.title);
                this.onResize();
            }
            this.outLoad();
        }, 2000);
    },
    created() {
        this.getDetailLand();

        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'hero/ACTION_SUCCESS':
                    this.$router.push({ name: 'HeroList' });
                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
<template>
    <b-container class="land-detail pb-5 mb-5">
        <div class="nav-market">
            <router-link
                class="item-market"
                v-for="nav in listNav"
                :key="`market-${nav.name}-${nav.id}`"
                :to="{ name: nav.components }"
            >
                <a> {{ nav.name }} </a>
            </router-link>
        </div>
        <b-col cols="12" class="">
            <router-link
                :to="{ name: 'Market' }"
                class="back-to-shop text-deconration-none"
            >
                <div class="icons">
                    <img src="~@/assets/images/icons/back.png" alt="" />
                </div>
                <div class="text">Back</div>
            </router-link>
        </b-col>
        <b-row>
            <b-col cols="12" class="p-0" v-if="DetailLand && DetailLand.land">
                <b-row class="p-0 m-0">
                    <b-col cols="12" md="5" lg="5" xl="5" class="p-0 box-left">
                        <div
                            class="section-buy-box d-none d-lg-block"
                            :style="`padding-top:${infoHeight}`"
                            :key="`pad-${infoHeight}`"
                        >
                            <div class="hero-id">#{{ DetailLand.tokenId }}</div>
                            <div
                                class="box-img"
                                style="
                                    border-width: 3px;
                                    box-shadow: 0px 0px 15px #4effff;
                                "
                            >
                                <img :src="Land.image" alt="" />
                            </div>
                            <div
                                class="land-name mb-1"
                                style="
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                "
                            >
                                <div class="land-name-content">
                                    No.{{ Land.id }}
                                </div>
                            </div>
                            <div class="coordinates text-center">
                                <p class="mb-0">Coordinates:</p>
                                <span class="d-block">0, </span>
                                <b-button class="btn-view-on-map btn-secondary">
                                    View on map
                                </b-button>
                            </div>
                        </div>

                        <div class="section-box-mobile d-flex d-lg-none">
                            <div class="left">
                                <div class="box-img">
                                    <img :src="Land.image" alt="" />
                                </div>
                                <div
                                    class="land-name mb-1"
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    "
                                >
                                    <div class="land-name-content">
                                        No.{{ Land.id }}
                                    </div>
                                </div>
                            </div>
                            <div class="right">
                                <div class="hero-id">
                                    #{{ DetailLand.tokenId }}
                                </div>
                                <div class="box-buy">
                                    <div class="left">
                                        <div class="hero-price">
                                            <div class="left">Price:</div>
                                            <div class="right">
                                                <div class="hbg">
                                                    {{
                                                        TruncateToDecimals2(
                                                            DetailLand.price,
                                                            '',
                                                            2,
                                                        )
                                                    }}
                                                    HBG
                                                </div>
                                                <div class="usd text-white">
                                                    {{
                                                        TruncateToDecimals2(
                                                            DetailLand.price *
                                                                0.04,
                                                            '',
                                                            2,
                                                        )
                                                    }}
                                                    USD
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <MetaMask
                                        class="right"
                                        :isBuy="true"
                                        v-if="!isLogin"
                                    />
                                    <div
                                        class="right"
                                        v-b-modal.confirm-buy
                                        v-else-if="
                                            DetailLand.ownerAddress.toLowerCase() !==
                                            UserInfo.address.toLowerCase()
                                        "
                                    >
                                        <b-button> Buy </b-button>
                                    </div>
                                    <div
                                        class="right"
                                        v-b-modal.confirm-cancel
                                        v-else
                                    >
                                        <b-button> Cancel </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col
                        cols="12"
                        md="7"
                        lg="7"
                        xl="7"
                        class="box-right mt-5 mt-lg-0"
                    >
                        <b-row>
                            <b-col cols="12">
                                <div class="box-buy d-none d-lg-flex">
                                    <div class="left">
                                        <div class="hero-price">
                                            <div class="left">Price:</div>
                                            <div class="right">
                                                <div class="hbg">
                                                    {{
                                                        TruncateToDecimals2(
                                                            DetailLand.price,
                                                            '',
                                                            2,
                                                        )
                                                    }}
                                                    HBG
                                                </div>
                                                <span class="text-white">
                                                    {{
                                                        TruncateToDecimals2(
                                                            DetailLand.price *
                                                                0.04,
                                                            '',
                                                            2,
                                                        )
                                                    }}
                                                    USD
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <MetaMask
                                        class="right"
                                        :isBuy="true"
                                        v-if="!isLogin"
                                    />
                                    <div
                                        class="right"
                                        v-b-modal.confirm-buy
                                        v-else-if="
                                            DetailLand.ownerAddress.toLowerCase() !==
                                            UserInfo.address.toLowerCase()
                                        "
                                    >
                                        <b-button> Buy </b-button>
                                    </div>
                                    <div
                                        class="right"
                                        v-b-modal.confirm-cancel
                                        v-else
                                    >
                                        <b-button> Cancel </b-button>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <div class="right-land-detail mt-5">
                            <div
                                class="d-flex justify-content-end align-items-center"
                            >
                                <div class="icon-home" style="width: 35px">
                                    <img
                                        src="~@/assets/images/mockup/market/home.png"
                                        class="w-100"
                                    />
                                </div>
                                <b-button class="btn-land-sell">
                                    Sell
                                </b-button>
                                <b-button class="btn-land-transfer">
                                    Transfer
                                </b-button>
                            </div>
                            <div class="right-land-detail-content">
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit, sed diam nonummy nibh euismod
                                tincidunt ut laoreet dolore magna aliquam erat
                                volutpat. Ut wisi enim ad minim
                            </div>
                        </div>
                        <div class="minning-process-frame mt-5">
                            <div class="box-name" ref="title">
                                <div class="before platinum">About</div>
                                <div class="after platinum">About</div>
                            </div>
                            <div class="minning-process-box">
                                <div class="box-info">
                                    <div class="info w-50">
                                        <div class="left">No.</div>
                                        <div class="right">{{ Land.id }}</div>
                                    </div>
                                    <div class="info w-50">
                                        <div class="left">Lat</div>
                                        <div class="right">
                                            {{ Land.longitude || 0 }}
                                        </div>
                                    </div>
                                    <div class="info w-50">
                                        <div class="left">Lon</div>
                                        <div class="right">
                                            {{ Land.latitude || 0 }}
                                        </div>
                                    </div>
                                    <div class="info w-50">
                                        <div class="left">ID</div>
                                        <div class="right">
                                            {{ Land.tokenId }}
                                        </div>
                                    </div>
                                    <div class="info w-100">
                                        <div class="left">Owner</div>
                                        <div class="right">
                                            {{ Land.ownerAddress }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="reward-status d-flex flex-wrap justify-content-between mb-3"
                                >
                                    <h6>REWARD: <span>0 HBG</span></h6>
                                    <h6>STATUS: <span>NO MINNING</span></h6>
                                </div>
                                <div class="loading-progress">
                                    <div class="progress-bg">
                                        <div
                                            class="active-bg"
                                            style="width: 100%"
                                        ></div>
                                    </div>
                                    <div class="progress-active">
                                        Minning: 0 / 100
                                    </div>
                                </div>
                                <div
                                    class="text-uppercase minning-process-decription"
                                >
                                    There are no heroes participating in mining
                                </div>
                                <b-button class="btn-choose-hero">
                                    CHOOSE HERO
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-modal centered id="confirm-buy" title="Confirm Buy Hero" hide-footer>
            <div>
                <p class="mb-0 text-confirm">Do you want buy this hero?</p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="Approve(DetailLand.tokenId, DetailLand.price)"
                >
                    Confirm Buy
                </b-button>
            </div>
        </b-modal>
        <b-modal
            centered
            id="confirm-cancel"
            title="Confirm Buy Land"
            hide-footer
        >
            <div>
                <p class="mb-0 text-confirm">
                    Do you want to cancel sell this Land?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="onCancelLand(DetailLand.tokenId)"
                >
                    Cancel Now
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="modal-received"
            title="CONGRATULATION"
            :centered="true"
            footer-class="d-none"
            @hidden="backToMarket()"
        >
            <p class="my-3 text-normal">You have received:</p>
            <LandShow
                v-if="receivedLand"
                :landItem="receivedLand"
                :onlyEarth="true"
                class="mx-auto"
            ></LandShow>
            <b-button
                class="btn-confirm d-block mx-auto text-dark mb-3 rounded-0"
                @click="$bvModal.hide('modal-received')"
            >
                Confirm
            </b-button>
        </b-modal>
    </b-container>
</template>
<style lang="scss">
$box-max-width: 375px;
$img-max-width: calc(375px * 0.9);
$price-max-width: calc(375px * 0.8);
$price-max-height: calc(375px / 10 * 1.3);
$liner-gradient-color: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
$button-max-width: calc(375px * 0.6);
$button-max-height: calc(375px / 10 * 1.5);
.back-to-shop {
    margin: 0px 0 20px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    .icons {
        img {
            width: 40px;
            height: auto;
        }
    }
    .text {
        font-family: 'HHBI';
        color: #00ffff;
        font-size: 27px;
        margin-left: 15px;
    }
    @media (max-width: 575px) {
        .text {
            font-size: 20px;
        }
        margin-bottom: 30px;
    }
}

.land-detail {
    .box-name {
        position: relative;

        .before {
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 0.5px;
            font-family: 'Helvetica';

            font-size: clamp(80px, 6vw, 90px);
            line-height: 120%;
            text-transform: uppercase;

            -webkit-text-stroke-color: #ffff;
            opacity: 0.4;
        }
        .after {
            font-size: clamp(40px, 4vw, 50px);
            font-family: 'Helvetica';
            position: absolute;
            bottom: 5px;
            left: 5px;
            text-transform: uppercase;
            font-weight: 900;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
        @media (max-width: 991px) {
            margin-bottom: 10px;
            .mobile {
                display: block;
            }
            .before {
                text-align: center;
                margin: auto;
            }
            .after {
                left: 0;
                right: 0;
                margin: 0 auto;
                width: max-content;
            }
        }
        @media (max-width: 991px) {
            .before {
                font-size: clamp(70px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(40px, 3vw, 75px);
            }
        }
        @media (max-width: 575px) {
            .before {
                font-size: clamp(55px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .before {
                font-size: clamp(45px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .before {
                font-size: clamp(40px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                bottom: -10px;
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
    .section-box-mobile,
    .section-buy-box {
        max-width: $box-max-width;
        width: 100%;
        .hero-id {
            color: #ffff;
            font-size: 1.7em;
            font-weight: 800;
            margin-bottom: 0px;
            text-align: left;
            padding-left: 15px;
        }
        [class|='box'] {
            &[class$='-img'] {
                text-align: center;
                position: relative;
                max-width: $img-max-width;
                margin: auto;
                border: 1px solid #4effff;
                margin-bottom: 10px;
                box-shadow: 0px 0px 7px #4effff;
                img {
                    width: 100%;
                    width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 10;
                    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    background: linear-gradient(
                        0deg,
                        #e2dfd3 0%,
                        #f6cb0a00 100%
                    );
                    z-index: 0;
                    animation: light 2s infinite linear;
                }
                @media (max-width: 767px) {
                    max-width: 250px;
                }
            }
            &[class$='-price'] {
                margin: 30px auto;
                width: $price-max-width;
                min-height: $price-max-height;
                background: {
                    image: url('~@/assets/images/button/price.png');
                    size: 100% 100%;
                    repeat: no-repeat;
                }
                display: flex;
                justify-content: center;
                align-items: baseline;
                padding: 5px 0;
                .hbg {
                    font-size: 2em;
                    text-align: center;
                    font-family: 'HHBI';
                    color: #4effff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
                .usd {
                    font-size: 1em;
                    text-align: center;
                    font-family: 'HHBI';
                    margin-left: 5px;
                    color: #ffffff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
                @media (max-width: 767px) {
                    max-width: 200px;
                    .hbg {
                        font-size: 20px;
                    }
                }
                @media (max-width: 575px) {
                    max-width: 250px;
                }
            }
            &[class$='-buy-quantity'] {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: $button-max-width;
                margin: 40px auto;
                button {
                    background: $liner-gradient-color;
                    border-radius: 0px;
                    padding: 2px;
                    width: 50px;
                    height: 50px;
                    div {
                        background: #000000;
                        width: 100%;
                        height: 100%;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        padding: 5px;
                        span {
                            font-family: 'HHBI';
                            font-size: 2.5em;
                            margin-left: -5px;
                        }
                    }
                }
                span {
                    width: 100px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-family: 'HHBI';
                    font-size: 2.5em;
                }
                @media (max-width: 767px) {
                    max-width: 200px;
                }
            }
            &[class$='-buy-button'] {
                button {
                    background: $liner-gradient-color;
                    width: $button-max-width;
                    padding: 0;
                    height: $button-max-height;
                    padding: 0;
                    color: #000000;
                    font-family: 'Helvetica';
                    font-size: 1.7em;
                    font-weight: 900;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 25px auto;
                    @media (max-width: 767px) {
                        max-width: 200px;
                        margin: 15px auto;
                    }
                    @media (max-width: 575px) {
                        font-size: 1.6em;
                    }
                }
            }
        }
        .land-name {
            width: 100%;
            max-width: $img-max-width;
            background-color: #4effff;
            font-size: 20px;
            margin: auto;
            padding: 5px;
            line-height: 100%;
            color: #000000;
            text-decoration: none;
            font-family: 'HHBI';
            @media (max-width: 575px) {
                font-size: 18px;
            }
            .land-name-content {
                display: flex;
                width: max-content;
                height: max-content;
            }
        }
        .hero-exp {
            max-width: $img-max-width;
            position: relative;
            width: 100%;
            border: 1px solid #00ffff;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 25px auto 10px;
            background: rgba(0, 255, 255, 0.2);
            .current {
                background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                z-index: 0;
            }
        }
        .hero-exp-text {
            position: relative;
            z-index: 10;
            font-weight: 500;
            font-size: 1.6 em;
            color: #ffff;
            text-align: center;
            span {
                color: #00ffff;
                font-weight: 700;
                font-family: 'Helvetica';
            }
        }
        @media (max-width: 991px) {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: unset;
            // .box-img {
            //     flex: 0 0 50%;
            // }
            // .action-buy {
            //     flex: 0 0 50%;
            // }
        }
        @media (max-width: 575px) {
            flex-wrap: wrap;
            .box-img {
                flex: 0 0 100%;
            }
            .action-buy {
                flex: 0 0 100%;
            }
        }
    }
    .section-box-mobile {
        align-items: flex-start;
        justify-content: space-around;

        .left {
            flex: 0 0 45%;
            img {
                width: 100%;
                height: auto;
            }
        }
        .right {
            margin-left: 20px;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .exp-section {
                margin-top: 20px;
                width: 100%;
                .hero-exp {
                    max-width: 100%;
                }
            }
            .box-buy {
                margin-top: 20px;
                display: flex;
                max-width: unset;
                width: 100%;
                margin-left: auto;
                margin-right: 0;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: center;
                padding-left: 15px;
                .left {
                    flex: auto;
                    .hero-price {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        .left {
                            flex: 0 0 25%;
                            color: #ffff;
                            font-weight: 600;
                        }
                        .right {
                            flex: 0 0 75%;
                            padding-left: 10px;

                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            .hbg {
                                font-size: 1.4em;
                                font-weight: 900;
                                line-height: 120%;
                                font-family: 'Helvetica';
                                color: #00ffff;
                            }
                            .usd {
                                font-size: 1em;
                                font-weight: 900;
                                margin-left: 10px;
                                font-family: 'Helvetica';
                                color: #fff;
                            }
                        }
                    }
                }
                .right {
                    flex: auto;
                    margin-left: 15px;
                    text-align: center;
                    button {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        color: #002338;
                        width: 90px;
                        height: 40px;
                        font-weight: 700;
                        font-size: 21px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0;
                        box-shadow: 0 0 5px 2px #00b1ff;
                        border: 0;
                    }
                }
            }
            .hero-id {
                padding-left: 15px;
            }
        }
        @media (max-width: 575px) {
            .left,
            .right {
                flex: 0 0 calc(100% - 15px);
                max-width: 400px;
                margin: 0 15px;
                .exp-section,
                .box-buy,
                .hero-id {
                    margin-top: 15px;
                    padding-left: 0;
                    .hero-exp {
                        margin: 0 auto;
                    }
                }
                .box-buy {
                    .right {
                        button {
                            margin: 5px auto;
                        }
                    }
                }
            }
        }
    }

    .coordinates .btn-view-on-map,
    .right-land-detail .btn-land-sell,
    .right-land-detail .btn-land-transfer {
        border: none;
        padding: 10px 20px;
        font-style: italic;
        letter-spacing: 1px;
        background: url(~@/assets/images/mockup/market/bgBtn-orange.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .right-land-detail-content {
        font-size: 20px;
        color: white;
        margin-top: 15px;
    }

    .coordinates {
        color: #4effff;
        margin-bottom: 0px;
        text-shadow: -1px -1px 10px #4effff;
        font-size: 25px;

        span {
            color: white;
        }

        .btn-view-on-map {
            background-image: url(~@/assets/images/mockup/market/bgBtn-orange.png);
        }
    }

    .right-land-detail .btn-land-sell {
        background-image: url(~@/assets/images/mockup/market/bgBtn-red.png);
    }

    .right-land-detail .btn-land-transfer {
        background-image: url(~@/assets/images/mockup/market/bgBtn-green.png);
    }

    .land-process {
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
        .loading-progress {
            height: 30px;
            width: 100%;
            position: relative;
            .progress-bg {
                background: #33004d;
                border: 1px solid #00ff00;
                box-sizing: border-box;
                height: 30px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                .active-bg {
                    content: '';
                    background: #00ff00;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    bottom: 0;
                    margin: auto;
                    width: 10%;
                    height: 30px;
                }
            }
            .progress-active {
                position: absolute;
                top: 0;
                left: 0px;
                right: 0px;
                bottom: 0;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #f4f4f4;
                font-size: 15px;
                @media (max-width: 991px) {
                    font-size: 12px;
                }
                @media (max-width: 575px) {
                    font-size: 10px;
                }
            }
        }
    }
}
.land-detail {
    .nav-market {
        background: {
            image: url(~@/assets/images/mockup/account/nav-inventory.png);
            size: 100% 100%;
            repeat: no-repeat;
        }
        height: 50px;
        width: 100%;
        max-width: 768px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 35px auto 55px;
        .item-market {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            color: #fff;
            border-left: 2px solid rgba(0, 255, 255, 0.42);
            cursor: pointer;
            &.active {
                background: #00ffff;
                color: #000;
            }

            &:last-child {
                &.active {
                    background: {
                        color: unset;
                        image: url(~@/assets/images/mockup/account/right-active.png);
                        size: 100% 100%;
                        repeat: no-repeat;
                    }
                }
            }
            &:first-child {
                border-left: 0 !important;
                &.active {
                    background: {
                        color: unset;
                        image: url(~@/assets/images/mockup/account/left-active.png);
                        size: 100% 100%;
                        repeat: no-repeat;
                    }
                }
            }
            a {
                // color: #fff;
                font-weight: 600;
                font-family: 'Helvetica';
                font-size: clamp(0.6em, 2vw, 1.2em);
            }
        }
    }
    .box-right {
        .box-buy {
            display: flex;
            max-width: 400px;
            margin-left: auto;
            margin-right: 0;
            .left {
                flex: 1 1 auto;
                .hero-price {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    .left {
                        flex: 0 0 25%;
                        color: #ffff;
                        font-weight: 600;
                    }
                    .right {
                        flex: 0 0 75%;
                        padding-left: 10px;

                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        .hbg {
                            font-size: 1.4em;
                            font-weight: 900;
                            line-height: 120%;
                            font-family: 'Helvetica';
                            color: #00ffff;
                        }
                        .usd {
                            font-size: 1em;
                            font-weight: 900;
                            margin-left: 10px;
                            font-family: 'Helvetica';
                            color: #fff;
                        }
                    }
                }
            }
            .right {
                flex: 0 0 150px;
                margin-left: 15px;
                button {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    color: #002338;
                    width: 90px;
                    height: 40px;
                    font-weight: 700;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    box-shadow: 0 0 5px 2px #00b1ff;
                    border: 0;
                }
            }
        }
        .box-info {
            min-height: 150px;
            width: 100%;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            border: 1px solid #00ffff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            .info {
                display: flex;
                align-items: center;
                font-weight: 600;
                padding: 0 15px;
                .left {
                    color: #fff;
                    margin-right: 15px;
                }
                .right {
                    color: #00ffff;
                    word-break: break-all;
                    flex: 1 1 auto;
                }
            }
        }
        .box-stat {
            margin-top: 50px;
            .nav-stat {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .item-menu {
                    height: 50px;
                    flex: 1;
                    max-width: 150px;
                    background: linear-gradient(
                        180deg,
                        #18274a 0%,
                        #001326 100%
                    );
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #00ffff;
                    border-right: 1px solid #00ffff;
                    font-weight: 700;
                    cursor: pointer;
                    font-size: 1.2em;
                    &.active {
                        background: linear-gradient(
                            180deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        color: #001326;
                    }
                }
            }
            .body-stat {
                background: linear-gradient(180deg, #18274a 0%, #001326 100%);
                min-height: 250px;
                border: 1px solid #00ffff;
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                .tabStat {
                    width: 100%;
                    .stat {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin: 15px auto;
                        .left {
                            flex: 0 0 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 50px;
                            .box {
                                width: 50px;
                                height: 50px;
                                border: 2px solid #00ffff;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img {
                                    width: 100%;
                                    object-fit: scale-down;
                                }
                            }
                        }
                        .right {
                            flex: 1 1 auto;
                            max-width: 500px;
                            height: 50px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .top {
                                color: #00ffff;
                                font-weight: 600;
                                font-size: 1.2em;
                            }
                            .bottom {
                                position: relative;
                                width: 100%;
                                border: 1px solid #00ffff;
                                height: 25px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 0px auto;
                                background: rgba(0, 255, 255, 0.2);
                                .current {
                                    background: linear-gradient(
                                        90deg,
                                        #00ffa9 0%,
                                        #00b1ff 100%
                                    );
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    z-index: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #111;
                                    font-family: 'HHBI';
                                    font-size: large;
                                }
                            }
                        }
                    }
                }
                .tabInstinct {
                    .instinct-box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 25px;
                        .left {
                            flex: 0 0 45%;
                            .top {
                                color: #00ffff;
                                font-weight: 600;
                                font-size: 1.2em;
                                text-align: center;
                                margin-top: 15px;
                            }
                            .bottom {
                                img {
                                    width: 100%;
                                }
                            }
                        }
                        .right {
                            flex: 1 1 auto;
                            padding: 0 15px 0 0;
                            .top {
                                color: #00ffff;
                                font-weight: 600;
                                font-size: 1.2em;
                            }
                            .bottom {
                                color: #fff;
                                font-weight: 500;
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }
        .box-offer {
            .table-responsive {
                .customer-header {
                    background-color: #00ffff;
                }
                .table th,
                .table td {
                    border: 1px solid #00ffff;
                }
                table {
                    thead {
                        tr {
                            th {
                                border-right-color: #000 !important;
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                background: linear-gradient(
                                    180deg,
                                    #00172a 0%,
                                    #00000b 100%
                                );
                                height: 40px;
                                padding: 0 0.75rem;
                                vertical-align: middle;
                                color: #fff;
                                font-weight: 500;
                            }
                            &:nth-of-type(even) {
                                td {
                                    box-shadow: inset 0px 0px 2005px
                                        rgba(0, 255, 255, 0.2);
                                }
                            }
                        }
                    }
                }
            }
            .action-offer {
                margin-top: 20px;
                button {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    color: #002338;
                    width: 200px;
                    height: 50px;
                    font-weight: 700;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    box-shadow: 0 0 15px 5px #00b1ff;
                    border: 0;
                }
            }
        }
    }

    .minning-process-frame {
        .minning-process-name {
            font-size: clamp(40px, 4vw, 50px);
            font-family: 'Helvetica';
            color: #4effff;
            text-shadow: 1px 0px 9px #00fcf5;
            font-weight: 900;
        }
        .minning-process-box {
            padding: 15px;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;

            .box-info {
                min-height: 150px;
                width: 100%;
                background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
                border: 1px solid #00ffff;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                .info {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    padding: 0 15px;
                    .left {
                        color: #fff;
                        margin-right: 15px;
                    }
                    .right {
                        color: #00ffff;
                        word-break: break-all;
                        flex: 1 1 auto;
                    }
                }
            }

            .reward-status {
                color: #00ffff;
                span {
                    font-size: 25px;
                    color: #ffffff;
                    text-shadow: 1px 0 0 #00ffff, -1px 0 0 #00ffff,
                        1px 0 #00ffff, 0 -1px 0 #00ffff, 1px 1px #00ffff,
                        -1px -1px 0 #00ffff, 1px -1px 0 #00ffff,
                        -1px 1px 0 #00ffff;
                }
            }

            .loading-progress {
                height: 30px;
                position: relative;
                width: 100%;
            }

            .progress-bg {
                background: linear-gradient(to right, #00ffff, #2d84cc);
                border: 2px solid #00ffff;
                box-sizing: border-box;
                height: 35px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }

            .progress-active {
                position: absolute;
                top: 0;
                left: 0px;
                right: 0px;
                bottom: 0;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #f4f4f4;
                font-weight: bold;
            }

            .minning-process-decription {
                margin-top: 15px;
                font-size: 20px;
                min-height: 150px;
            }

            .btn-choose-hero {
                background: url('~@/assets/images/frame/hexagon-green.png');
                background-size: 100% 100%;
                width: 150px;
                outline: none;
                height: 60px;
                display: flex;
                position: relative;
                padding: 0;
                outline: none;
                font-family: 'HHBI';
                font-size: 17px;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                outline: none !important;
                box-shadow: none;
                color: #fff;
                margin: 0 auto;
                border: none;
            }
        }
    }
}

.text-normal {
    font-family: 'helvetica-neue-regular';
    color: white;
    font-size: 28px;
    text-align: center;
}

.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}

#modal-received {
    .modal-dialog {
        @media (min-width: 768px) {
            max-width: 600px;
        }
    }

    .modal-content {
        background-image: url(~@/assets/images/mockup/market/bg-modal-market-congrat.png);
        background-size: 110% 110%;
        background-position: center center;
    }

    .modal-body {
        background: none;
        font-family: 'hemiheadRg-BoldItalic';
        color: #00ffff;

        .hero-name {
            font-size: 1.5em;
            text-shadow: 0px 0px 20px #00ffff;

            span {
                font-size: 2em;
                color: white;
            }
        }

        .box-img-modifer {
            width: 250px;
            margin: 0 auto;
            max-height: 250px;
            overflow: hidden;
        }

        .x1 {
            width: 75px;
            height: 50px;
            margin: 15px auto 30px;
            border: 1px solid #00ffff;

            font-size: 43px;
            text-shadow: 0px 0px 10px #00ffff;
            line-height: 50px;
            text-align: center;
        }

        .btn-confirm {
            background-color: #00ffff;
        }
    }
}

#modal-market-offer {
    .box-input-group {
        font-family: 'hemiheadRg-BoldItalic';
        height: 65px;

        input {
            outline: none;
            margin: 0;
            border-radius: 0;
            color: #fff;
            font-size: clamp(1.5rem, 2vw, 2.4rem);
            height: 65px;

            border: 1px solid #00ffff;
            background: linear-gradient(to right, #18274a, #001326);
            display: block;
            width: 50%;

            &::placeholder {
                color: #fff;
                font-size: clamp(1.5rem, 2vw, 2.4rem);
            }
        }
        .text {
            background: #00ffff;
            font-size: clamp(1.5rem, 1.5vw, 2.4rem);
            color: #000;
            line-height: 65px;
            padding: 0 10px 0 5px;
        }
    }

    .btn-confirm,
    .btn-cancel {
        background-color: #00ffff;
        color: #000;
        font-family: 'helvetica';
        font-size: 20px;
    }

    .btn-cancel {
        background: none;
        border: 1px solid #00ffff;
    }
}
</style>
