<template>
    <b-container class="p-0 balance-2">
        <div class="wallet-ingame" v-if="!coin">
            <div class="title mx-1 mb-2">Ingame Currency</div>
            <div class="list-in-game">
                <div class="balance-hgb box-dark mx-1">
                    <div class="balance-title">Hero Book Game Token (HBG)</div>
                    <div class="balance-body">
                        <div class="balance-body-left">
                            <div class="balance-one balance-detail">
                                <div class="top">
                                    {{
                                        TruncateToDecimals2(
                                            BalanceSystem.HBG,
                                            '',
                                            4,
                                        )
                                    }}
                                    HBG
                                </div>
                                <!-- <div class="bottom">$0 USD</div> -->
                            </div>
                        </div>
                        <div class="balance-body-right">
                            <img src="~@/assets/images/logo/HBG.png" alt="" />
                        </div>
                        <div class="balance-body-bottom">
                            <MetaMask
                                class="right"
                                :isBuy="true"
                                v-if="!isLogin"
                                style="max-width: 200px !important"
                            />
                            <div class="balance-deposit" v-else>
                                <div class="col-md-6 col-10 py-2 pl-0 pr-2">
                                    <button
                                        class="bede-btn"
                                        @click="$bvModal.show('deposit')"
                                    >
                                        <p class="m-0">Deposit HBG</p>
                                    </button>
                                </div>

                                <div class="col-md-6 col-10 py-2 pr-0 pl-2">
                                    <button
                                        class="bede-btn"
                                        @click="$bvModal.show('withdraw')"
                                    >
                                        <p class="m-0">Claim HBG</p>
                                    </button>
                                </div>
                                <div class="col-md-6 col-10 py-2 pl-0 pr-2">
                                    <button
                                        v-if="true"
                                        class="bede-btn"
                                        @click="$bvModal.show('transfer')"
                                    >
                                        <p class="m-0">Transfer Energy</p>
                                    </button>
                                </div>
                                <div class="col-md-6 col-12 py-2 pr-0 pl-2">
                                    <button
                                        v-if="true"
                                        class="bede-btn"
                                        @click="$bvModal.show('transferHBG')"
                                    >
                                        <p class="m-0">Transfer HBG</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-game mt-3">
                    <b-row class="mx-0">
                        <b-col cols="12" class="px-1 mb-2">
                            <div class="box-dark">
                                <div class="circle">
                                    <img
                                        src="~@/assets/images/mockup/account/oxy.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">
                                    <div class="value">
                                        {{
                                            TruncateToDecimals2(
                                                BalanceSystem.Oxygen,
                                                '',
                                                0,
                                            )
                                        }}
                                    </div>
                                    <div class="name-energy">Oxygen</div>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" class="px-1 mb-2">
                            <div class="box-dark">
                                <div class="circle">
                                    <img
                                        src="~@/assets/images/mockup/account/electro.png"
                                        alt=""
                                    />
                                </div>

                                <div class="text">
                                    <div class="value">
                                        {{
                                            TruncateToDecimals2(
                                                BalanceSystem.Electric,
                                                '',
                                                0,
                                            )
                                        }}
                                    </div>
                                    <div class="name-energy">Electric</div>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" class="px-1 mb-2">
                            <div class="box-dark">
                                <div class="circle">
                                    <img
                                        src="~@/assets/images/mockup/account/heronium.png"
                                        alt=""
                                    />
                                </div>

                                <div class="text">
                                    <div class="value">
                                        {{
                                            TruncateToDecimals2(
                                                BalanceSystem.Heronium,
                                                '',
                                                0,
                                            )
                                        }}
                                    </div>
                                    <div class="name-energy">Heronium</div>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" class="px-1 mb-2">
                            <div class="box-dark">
                                <div class="circle">
                                    <img
                                        src="~@/assets/images/mockup/account/herostone.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">
                                    <div class="value">
                                        {{
                                            TruncateToDecimals2(
                                                BalanceSystem.HeroStone,
                                                '',
                                                0,
                                            )
                                        }}
                                    </div>
                                    <div class="name-energy">HeroStone</div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <b-modal
            id="withdraw"
            title="Withdraw HeroBook Game Token (HBG)"
            hide-footer
            @hidden="resetWithdraw"
        >
            <b-form @submit.prevent="onWithdraw()">
                <b-form-group>
                    <label for="inputAmount">Your Address Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            BalanceSystem.HBG,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Withdraw Amount</label>
                    <b-form-input
                        min="0"
                        v-model="withdrawAmount"
                        type="number"
                        id="inputAmount"
                        placeholder="Enter Your Withdraw Deposit"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Actually Receive</label>
                    <b-form-input
                        class="pl-2"
                        min="0"
                        :value="`${TruncateToDecimals2(
                            withdrawAmount - withdrawAmount * Fee.claimHBG,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <div class="note">
                    <div class="title">NOTED:</div>
                    <div class="content">
                        <p
                            class="text-left text-warning mb-1 pl-3 font-weight-bold"
                            style="letter-spacing: 1px"
                        >
                            - If you can't received hbg to your wallet, please
                            wait a minutes for the bscscan network to confirm
                            the transaction to log into the system
                            <span> (maximum time 48h will received) </span>
                        </p>
                        <p class="text-left text-warning pl-3 font-weight-bold">
                            - If you have a question or more than 48 hours
                            please contact with support team
                            <router-link :to="{ name: 'Support' }">
                                <span> In Here </span>
                            </router-link>
                        </p>
                    </div>
                </div>
                <b-button
                    class="button-confirm"
                    type="submit"
                    :disabled="withdrawAmount > BalanceSystem.HBG"
                >
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
        <b-modal
            id="deposit"
            title="Deposit HeroBook Game Token (HBG)"
            hide-footer
            @hidden="resetDeposit"
        >
            <b-form @submit.prevent="DepositHBG()">
                <b-form-group>
                    <label for="inputAmount">Your Address Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            Balance.hbg,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Deposit Amount</label>
                    <b-form-input
                        min="0"
                        v-model="depositAmount"
                        type="number"
                        id="inputAmount"
                        placeholder="Enter Your Amount Deposit"
                    ></b-form-input>
                </b-form-group>
                <div class="note">
                    <div class="title">NOTED:</div>
                    <div class="content">
                        <p
                            class="text-left text-warning mb-1 pl-3 font-weight-bold"
                            style="letter-spacing: 1px"
                        >
                            - If you can't received hbg to the marketplace,
                            please wait a minutes for the bscscan network to
                            confirm the transaction to log into the system
                            <span> (maximum time 48h will received) </span>
                        </p>
                        <p class="text-left text-warning pl-3 font-weight-bold">
                            - If you have a question or more than 48 hours
                            please contact with support team
                            <router-link :to="{ name: 'Support' }">
                                <span> In Here </span>
                            </router-link>
                        </p>
                    </div>
                </div>
                <b-button class="button-confirm" type="submit">
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
        <b-modal
            id="transferHBG"
            title="Transfer HeroBook Game Token (HBG)"
            hide-footer
            @hidden="resetTransferHBG"
        >
            <b-form @submit.prevent="onTransferHBG()">
                <b-form-group>
                    <label for="inputAmount">Your Address Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            BalanceSystem.HBG,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group>
                    <label for="inputAmount">Address Receive</label>
                    <b-form-input
                        min="0"
                        v-model="transferHBG.to"
                        id="inputAmount"
                        placeholder="Enter  Address Receive"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Amount Transfer</label>
                    <b-form-input
                        min="0"
                        v-model="transferHBG.amount"
                        type="number"
                        id="inputAmount"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Actually Receive</label>
                    <b-form-input
                        class="pl-2"
                        min="0"
                        :value="`${TruncateToDecimals2(
                            transferHBG.amount -
                                transferHBG.amount * Fee.transferHBG,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-button
                    class="button-confirm"
                    type="submit"
                    :disabled="withdrawAmount > BalanceSystem.HBG"
                >
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
        <b-modal
            id="transfer"
            title="Transfer Energy"
            hide-footer
            @hidden="resetTransfer"
        >
            <b-form @submit.prevent="onTransfer()">
                <b-form-group>
                    <label for="">Energy</label>
                    <div class="form-sl">
                        <b-form-select
                            v-model="transfer.type"
                            :options="typeTransfer"
                            class="mx-auto"
                        ></b-form-select>
                        <img src="~@/assets/images/icons/drop.png" alt="" />
                    </div>
                </b-form-group>
                <b-form-group>
                    <label for="">Your Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            BalanceSystem[transfer.type],
                            '',
                            4,
                        )} ${transfer.type}`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Transfer Amount</label>
                    <b-form-input
                        min="0"
                        v-model="transfer.amount"
                        type="number"
                        id="inputAmount"
                        placeholder="Enter Your Amount Transfer"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAddress">Address Receive </label>
                    <b-form-input
                        min="0"
                        v-model="transfer.to"
                        type="text"
                        id="inputAddress"
                        placeholder="Enter Address Receive"
                    ></b-form-input>
                </b-form-group>
                <b-button
                    class="button-confirm"
                    type="submit"
                    :disabled="depositAmount > BalanceSystem.HBG"
                >
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
        <b-modal id="SuggestDeposit" title="User Suggest Deposit" hide-footer>
            <div>
                <p class="mb-0 text-confirm-2">
                    Your balance InGame is not enough to buy this item.
                </p>
                <p class="mb-0 text-confirm-2">Do you want to deposit now ?</p>
            </div>
            <div
                class="d-flex mt-5 flex-wrap justify-content-around align-items-center"
            >
                <b-button
                    class="mx-auto my-2 button-confirm"
                    variant="primary"
                    @click="onShowDeposit()"
                >
                    Deposit Now
                </b-button>
                <b-button
                    class="mx-auto my-2 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('SuggestDeposit')"
                >
                    Cancel
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { MetaMask } from '@/components/auth';

const axios = require('@/store/axios.config').default;
const BigNumber = require('bignumber.js');

export default {
    props: {
        coin: {
            type: String,
            default: null,
        },
    },
    components: {
        MetaMask,
    },
    data() {
        return {
            depositAmount: 0,
            submitted: false,
            withdrawAmount: 0,
            transferHBG: {
                to: '',
                amount: 0,
            },
            transfer: {
                type: 'Heronium',
                to: null,
                amount: 1,
            },
            typeTransfer: [
                {
                    text: 'Heronium',
                    value: 'Heronium',
                },
                {
                    text: 'Oxygen',
                    value: 'Oxygen',
                },
                {
                    text: 'Electric',
                    value: 'Electric',
                },
                {
                    text: 'HeroStone',
                    value: 'HeroStone',
                },
            ],
            transaction: null,
        };
    },
    computed: {
        ...mapGetters({
            BalanceSystem: 'info/Balance',
            isLogin: 'auth/loggedIn',
            Balance: 'wallet/Balance',
            UserInfo: 'info/UserInfo',
            Fee: 'core/Fee',
        }),
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        resetDeposit() {
            this.depositAmount = 0;
        },
        resetWithdraw() {
            this.withdrawAmount = 0;
        },
        resetTransfer() {
            this.transfer = {
                type: 'Heronium',
                to: null,
                amount: 1,
            };
        },
        resetTransferHBG() {
            this.transferHBG = {
                to: '',
                amount: 0,
            };
        },

        ComingSoonT() {
            this.$toastr.s('Function Coming Soon', 'Coming Soon');
        },
        onShowDeposit() {
            this.$bvModal.show('deposit');
            this.$bvModal.hide('SuggestDeposit');
        },

        async onWithdraw() {
            this.onLoad();
            if (this.transfer.amount < 1) {
                this.$toastr.w('Please enter amount greater than 0', 'Error');
                this.outLoad();
                return;
            }
            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Withdraw'],
            });
            if (!txData) {
                this.$toastr.e('Please Confirm Withdraw', 'Error');
                return;
            }
            // this.submitted = false;
            await axios.post('api/me/withdraw', {
                amount: this.withdrawAmount,
                sign: txData,
            });

            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('withdraw');

            this.outLoad();
        },
        async onTransfer() {
            this.onLoad();
            if (this.transfer.amount < 1) {
                this.$toastr.w('Please enter amount greater than 0', 'Error');
                this.outLoad();
                return;
            }

            if (
                this.transfer.to.toLowerCase() ===
                this.UserInfo.address.toLowerCase()
            ) {
                this.$toastr.w('You can not transfer to yourself', 'Error');
                this.outLoad();
                return;
            }
            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Transfer'],
            });
            if (!txData) {
                this.$toastr.e('Please Confirm Transfer', 'Error');
                return;
            }
            this.transfer = {
                ...this.transfer,
                sign: txData,
            };
            // this.submitted = false;
            await axios.post('api/market/order/transfer/energy', this.transfer);

            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('transfer');

            this.outLoad();
        },
        async onTransferHBG() {
            this.onLoad();
            if (this.transferHBG.amount < 1) {
                this.$toastr.w('Please enter amount greater than 0', 'Error');
                this.outLoad();
                return;
            }
            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Transfer'],
            });
            this.transferHBG = {
                ...this.transferHBG,
                sign: txData,
            };
            // this.submitted = false;
            await axios.post(
                '/api/market/order/transfer/hbg',
                this.transferHBG,
            );

            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('transferHBG');

            this.outLoad();
        },
        async sendTransaction(transaction) {
            if (transaction) {
                this.transaction = transaction;
            }
            this.onLoad();
            const data = await axios.post('api/me/deposit', {
                transactionHash: transaction,
            });
            console.log(data);
            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('deposit');
            this.outLoad();
            this.outLoad();
        },
        async DepositHBG() {
            this.onLoad();
            const addressDeposit = await axios.get(
                'api/system/get-system-wallet',
            );
            if (!addressDeposit) {
                this.$toastr.e(
                    'Something went wrong, Please reload a page',
                    'Error',
                );
                this.outLoad();
                return;
            }
            this.submitted = true;
            if (this.depositAmount < 1) {
                this.$toastr.e(
                    'Please enter a valid amount, min deposit is 1 HBG',
                    'Deposit Failed',
                );
                this.outLoad();
                return;
            }
            const { web3 } = this.$store.state.contract;
            const { hbg } = this.$store.state.contract.bep_20;
            const { addr } = this.$store.state.wallet;
            const deposit = addressDeposit;
            const { depositAmount } = this.$data;
            const amount = web3.provider.utils.toBN(
                BigNumber(depositAmount * hbg.dec),
            );
            const txObj = hbg.cont.methods
                .transfer(deposit, amount)
                .encodeABI();
            let gas;
            try {
                // Calculate gas
                gas = await hbg.cont.methods
                    .transfer(deposit, amount)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: 2500000,
                    });
                // return;
            } catch (error) {
                this.outLoad();
                this.$toastr.e(error, 'Oops!');
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;
            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hbg.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                            type: '1',
                        },
                    ],
                });
                // transaction result
                let submitted = true;
                let check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        check = undefined;
                        if (txData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction(txData.transactionHash);
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.$toastr.e(error.message, 'Oops!');
            }
        },
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'suggest/SuggestDeposit':
                    this.$bvModal.show('SuggestDeposit');
                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<style lang="scss">
.text-confirm-2 {
    font-size: 1.4em;
    color: #fff;
    font-family: 'HHBI';
    text-align: center;
    margin-top: 10px;
}

#transferHBG,
#transfer,
#withdraw,
#deposit {
    .modal-content {
        .form-group {
            width: 80%;
            min-width: 200px;
            margin: 0 auto 15px;
        }
        .form-sl {
            position: relative;
            img {
                height: 15px;
                width: auto;
                position: absolute;
                right: 15px;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }
        select {
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #00ffff;
            font-weight: bold;
            letter-spacing: 1px;
            option {
                background: #00000b;
                &:hover {
                    background: #00ffff !important;
                    color: #00000b !important;
                    /* selected */
                }
            }

            option:hover,
            option:checked,
            option:active,
            option:focus {
                background: #00ffff !important;
                color: #00000b !important;
                /* selected */
            }
        }
        input {
            margin: 0 auto;
            width: 100%;
        }
        label {
            width: 100%;
            min-width: 200px;
            margin: 0 auto;
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
    }
    .note {
        text-align: center;
        .title {
            color: #00ffff;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        p {
            color: #fff;
            font-weight: 500;
            span {
                color: #00ffff;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }
}

.balance-2 {
    .wallet-ingame {
        .title {
            color: #fff;
            font-family: 'Helvetica';
            font-size: clamp(1.6em, 4vw, 1.9em);
            padding-bottom: 10px;
            position: relative;
            letter-spacing: 0.6px;
            width: max-content;
            margin: 25px 0 0;
            font-weight: 900;
            padding: 0 10px 10px;
            &::after {
                content: '';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #00ffff;
                position: absolute;
                font-weight: 600;
            }
        }
        .list-in-game {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .box-dark {
                border: 1px solid #00ffff;
                -webkit-backdrop-filter: blur(19px);
                backdrop-filter: blur(19px);
                flex: 1;
                margin: 0;
                position: relative;
                border-radius: 4px;
                &::before {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background: #00ffff;
                    top: 0;
                    right: 0;
                    border-radius: 2px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    background: #00ffff;
                    bottom: 0;
                    left: 0;
                    border-radius: 1.5px;
                }
                .balance-title {
                    color: #fff;
                    padding: 30px 0 10px 10%;
                    font-weight: 600;
                    font-size: 1.2em;
                    position: relative;
                    text-align: left;
                    &::after {
                        content: '';
                        bottom: 0;
                        left: 0;
                        width: calc(50% + 10px);
                        height: 2px;
                        background: #00ffff;
                        position: absolute;
                        font-weight: 600;
                    }
                }
                .balance-detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 20px 10%;
                    .top {
                        color: #00ffff;
                        font-weight: 600;
                        font-size: 1.5em;
                    }
                    .bottom {
                        color: #ffff;
                        font-weight: 600;
                        font-size: 0.9em;
                        font-style: italic;
                    }
                    @media (max-width: 575px) {
                        padding: 10px 5px;
                    }
                }
            }
            .balance-hgb {
                flex: 1 1 auto;
                margin-left: 0;
                .balance-body {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 15px;
                    min-height: calc(100% - 100px);
                    position: relative;
                }
            }
            .balance-body-left {
                flex: 0 0 calc(100% - 100px);
            }
            .balance-body-right {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                // position: absolute;
                // top: 10%;
                // right: 15px;
                img {
                    width: 65px !important;
                    height: 65px !important;
                }
                @media (max-width: 767px) {
                    top: calc(10% - 15px);
                }
            }
            .balance-body-bottom {
                flex: 0 0 100%;
                width: 100%;
                .balance-deposit {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-items: center;
                    padding: 5px 10px;
                    a {
                        flex: 1 1 auto;
                        border-radius: 0;
                        width: auto;
                        min-width: 90px;
                        height: 45px;
                        padding: 5px;
                        color: #000;
                        font-weight: 600;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    @media (max-width: 575px) {
                        padding: 10px 5px;
                        flex-wrap: wrap;
                    }
                }
            }
            .box-game {
                width: 100%;
                margin: 0;
                flex: unset !important;
                .box-dark {
                    min-height: 80px;
                    height: 100%;
                    text-align: center;
                    width: 100%;
                    display: flex;
                    margin: auto !important;
                    padding: 0 15px;
                    align-items: center;
                    max-width: unset;
                    @media (max-width: 991px) {
                        max-width: 500px;
                    }
                    .circle {
                        width: 65px;
                        height: 65px;
                        border-radius: 50%;
                        margin: 0;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .text {
                        color: #00ffffff;
                        line-height: 120%;
                        font-weight: 600;
                        flex: 1 1 auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .name-energy {
                            color: #fff;
                            font-size: 1.2em;
                        }
                        .value {
                            font-family: 'HHBI';
                            font-size: 1.5em;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
    button.bede-btn {
        height: 38px;
        width: 100%;
        border-radius: 105px;
        outline: unset;
        &::before {
            border-radius: 105px;
            background: #182c35;
        }
        p {
            font-size: 17px;
        }
    }
}
</style>
