<script>
import { mapGetters, mapMutations } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';
import BalanceBox from '@/components/shared/BalaneIngame.vue';
import VueSlickCarousel from 'vue-slick-carousel';

export default {
    components: {
        Paginate,
        BalanceBox,
        VueSlickCarousel,
    },
    data() {
        return {
            search: {
                page: 1,
                limit: 10,
            },
            fields: [
                {
                    key: 'id',
                    sortable: false,
                    label: 'Hash',
                    class: 'text-center',
                },
                {
                    key: 'code',
                    label: 'Method',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: 'loginTime',
                    label: 'dd/mm/yyy , time',
                },
            ],
            currentPage: 1,
            totalPages: 10,
            address: '0xc6ed69214CE2884d6C4D620CB1916321c4190865',
            isUpdateAvatar: false,
            qrActive: 1,
            isChose: '',
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            Balance: 'wallet/Balance',
            TotalHero: 'inventory/TotalHero',
            Login: 'history/Login',
            TotalLand: 'inventory/TotalLand',
            ListAvatar: 'info/ListAvatar',
            QrCode: 'auth/QrCode',
        }),
        AddressShow() {
            const { address } = this.UserInfo;
            // if (!address) return null;
            // const stringShort = `${address.slice(0, 7)}...${address.slice(
            //     address.length - 10,
            //     address.length,
            // )}`;
            return address;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('history/req_getHistoryLogin', this.search);
        },
        onGetQR() {
            this.$store.dispatch('auth/QrCode');
        },
        changeAvatar() {
            this.$store.dispatch('info/req_postChoseAvatar', {
                image: this.isChose,
            });
        },
    },
    created() {
        this.onChangePage(1);
        this.$store.dispatch('inventory/req_heroQuantity');
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'auth/SET_QRCODE':
                    this.$bvModal.show('QR-Login');
                    break;
                case 'info/SET_AVATAR_SUCCESS':
                    this.$bvModal.hide('ListAvatar');
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
<template>
    <b-container class="wallet-tab">
        <!-- ticket tournament -->
        <VueSlickCarousel
            v-if="UserInfo && UserInfo.ticket && UserInfo.ticket.length > 0"
            v-bind="settings"
            class="ml-auto slide-tournament"
        >
            <div
                class="carousel-item-home"
                v-for="ticket in UserInfo.ticket"
                :key="`${ticket._id}`"
            >
                <div class="tournament-active">
                    <div class="tournament-active-icon">
                        <img src="~@/assets/images/icons/cup.png" alt="" />
                    </div>
                    <div class="tournament-active-text">
                        {{ ticket.name }}
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
        <!-- change avatar -->
        <div
            class="avatar"
            @mouseenter="isUpdateAvatar = true"
            @mouseleave="isUpdateAvatar = false"
        >
            <img
                v-if="UserInfo && UserInfo.Avatar"
                :key="`avatar-${UserInfo.Avatar}`"
                :src="UserInfo.Avatar"
                alt=""
            />
            <img
                v-else
                src="~@/assets/images/mockup/account/hero.png"
                width="100%"
                alt=""
            />
            <div class="update-avatar" v-if="isUpdateAvatar">
                <button @click="$bvModal.show('ListAvatar')">Change</button>
            </div>
            <div class="camera">
                <img src="~@/assets/images/icons/camera.svg" alt="" />
            </div>
        </div>
        <!-- information user -->
        <div class="info-user">
            <div class="name">
                <span>{{ UserInfo.Username || 'N/A' }}</span>
            </div>
            <div class="email">
                <img src="~@/assets/images/icons/email.svg" alt="" />
                <span>{{ UserInfo.email || 'N/A' }}</span>
            </div>
            <b-button variant="info" class="button-qrLogin" @click="onGetQR">
                <img src="~@/assets/images/icons/show-qr.svg" alt="" /> Show QR
            </b-button>
        </div>
        <div class="wallet-address-2" v-if="false">
            <span class="address">{{ AddressShow }}</span>
            <a
                class="copy"
                v-clipboard:copy="UserInfo.address"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
            >
                <img src="~@/assets/images/icons/copy-2.png" alt="" />
            </a>
            <a
                :href="`https://bscscan.com/address/${UserInfo.address}`"
                target="_blank"
            >
                <img src="~@/assets/images/icons/open.png" alt=""
            /></a>
        </div>
        <div class="wallet">
            <div class="heading-section mb-4">
                <div class="text">Wallet</div>
            </div>
            <div class="wallet-address">
                <span class="address">{{ AddressShow }}</span>
                <a
                    class="copy custom-button"
                    v-clipboard:copy="UserInfo.address"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                >
                    <img src="~@/assets/images/icons/copy-2.png" alt="" /> Copy
                    Link
                </a>
                <a
                    class="custom-button"
                    :href="`https://bscscan.com/address/${UserInfo.address}`"
                    target="_blank"
                >
                    <img src="~@/assets/images/icons/open.png" alt="" />
                    Share</a
                >
            </div>
            <div class="list-box-wallet">
                <div
                    class="box-wallet balance-currencies justify-content-start"
                >
                    <div class="box-wallet-header border-b mb-2">
                        <div class="box-wallet-header-title">
                            Other Currencies
                        </div>
                    </div>
                    <div class="box-wallet-body">
                        <b-row class="w-100 ml-0 align-items-center">
                            <b-col cols="2">
                                <div class="dots-img"></div>
                            </b-col>
                            <b-col cols="6">
                                <div class="box-wallet-body-amount">
                                    {{
                                        TruncateToDecimals2(Balance.bnb, '', 4)
                                    }}
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <div class="box-wallet-body-symbol">[BNB]</div>
                            </b-col>
                        </b-row>
                        <b-row
                            class="w-100 ml-0 align-items-center justify-content-end"
                        >
                            <b-col cols="6">
                                <div class="box-wallet-body-amount row-usd">
                                    ~ ${{
                                        TruncateToDecimals2(
                                            Balance.bnb / Balance.bnbPrice,
                                            '',
                                            4,
                                        )
                                    }}
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <div class="box-wallet-body-symbol row-usd">
                                    [USD]
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="w-100 ml-0 align-items-center mt-4">
                            <b-col cols="2">
                                <div class="dots-img"></div>
                            </b-col>
                            <b-col cols="6">
                                <div class="box-wallet-body-amount">
                                    {{
                                        TruncateToDecimals2(
                                            Balance.busdPrice,
                                            '',
                                            4,
                                        )
                                    }}
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <div class="box-wallet-body-symbol">[BUSD]</div>
                            </b-col>
                        </b-row>
                        <b-row
                            class="w-100 ml-0 align-items-center justify-content-end"
                        >
                            <b-col cols="6">
                                <div class="box-wallet-body-amount row-usd">
                                    ~${{
                                        TruncateToDecimals2(
                                            Balance.bnb / Balance.usdb,
                                            '',
                                            4,
                                        )
                                    }}
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <div class="box-wallet-body-symbol row-usd">
                                    [USD]
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="balance-currencies box-dark" v-if="false">
                    <div class="balance-title">Other Currencies</div>
                    <div class="balance-body">
                        <div class="balance-one balance-detail">
                            <div class="top">
                                {{ TruncateToDecimals2(Balance.bnb, '', 4) }}
                                BNB
                            </div>
                            <div class="bottom">
                                ~ ${{
                                    TruncateToDecimals2(
                                        Balance.bnb / Balance.bnbPrice,
                                        '',
                                        4,
                                    )
                                }}
                                USD
                            </div>
                        </div>
                        <div class="balance-two balance-detail">
                            <div class="top">
                                {{ TruncateToDecimals2(Balance.busd, '', 4) }}
                                BUSD
                            </div>
                            <div class="bottom">
                                ~ ${{
                                    TruncateToDecimals2(
                                        Balance.busd / Balance.busdPrice,
                                        '',
                                        4,
                                    )
                                }}
                                USD
                            </div>
                        </div>
                    </div>
                </div>
                <div class="left-cnt">
                    <div class="mx-0">
                        <div
                            class="balance-title d-flex align-items0center pl-0"
                        >
                            <div class="title-img mr-3">
                                <img
                                    src="~@/assets/images/logo/HBG.png"
                                    alt=""
                                />
                            </div>
                            Hero Book Game Token (HBG)
                        </div>
                        <div class="balance-body">
                            <div class="balance-body-left">
                                <div class="balance-one balance-detail">
                                    <div
                                        class="top d-flex justify-content-between special-font pt-3 pl-3"
                                    >
                                        {{
                                            TruncateToDecimals2(
                                                Balance.hbg,
                                                '',
                                                4,
                                            )
                                        }}
                                        <span> [HBG] </span>
                                    </div>
                                    <div
                                        class="bottom mt-3 d-flex justify-content-between special-font pl-3"
                                    >
                                        ~ ${{
                                            TruncateToDecimals2(
                                                Balance.hbg / Balance.price,
                                                '',
                                                4,
                                            )
                                        }}
                                        <span> [USD] </span>
                                    </div>
                                </div>
                                <div class="balance-deposit" v-if="false">
                                    <b-button> Deposit </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="land-hero">
                        <a
                            href="https://system.heroland.app/my-land"
                            target="_blank"
                        >
                            <img
                                src="~@/assets/images/icons/land.svg"
                                alt=""
                                class="land"
                            />
                            <div class="text">
                                {{ parseInt(TotalLand) }} Land
                            </div>
                        </a>
                        <div class="divide-line"></div>
                        <a href="/account/inventory?active=hero">
                            <img
                                src="~@/assets/images/mockup/account/hero2.png"
                                alt=""
                                class="hero"
                            />
                            <div class="text">
                                {{ parseInt(TotalHero) }} Hero
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="heading-section mb-4 mt-5">
            <div class="text">Ingame Currency</div>
        </div>
        <BalanceBox />
        <div class="activity-list">
            <div class="heading-section mb-4 mt-5">
                <div class="text">Login History</div>
            </div>
            <div class="table-responsive">
                <b-table
                    striped
                    hover
                    :items="Login.list"
                    :fields="fields"
                    show-empty
                    thead-class="customer-header"
                >
                    <template #empty>
                        <h4 class="text-center p-3">No Data</h4>
                    </template>
                    <template #cell(id)="data">
                        <p>
                            {{ data.item.address }}
                        </p>
                    </template>
                    <template #cell(code)="data">
                        <p>
                            {{ data.item.code }}
                        </p>
                    </template>
                    <template #cell(loginTime)="data">
                        <p>
                            {{ getDateTime(data.item.createAt) }}
                        </p>
                    </template>
                </b-table>
            </div>
            <div class="table-paginate">
                <Paginate @current="onChangePage" :totalPages="Login.total" />
            </div>
        </div>
        <b-modal
            id="QR-Login"
            title="QR Code Information"
            :centered="true"
            footer-class="d-none"
        >
            <div class="tab-qr" v-if="false">
                <div
                    class="tab-qr-menu"
                    :class="{ active: qrActive == 1 }"
                    @click="qrActive = 1"
                >
                    QR Code
                </div>
                <div
                    class="tab-qr-menu"
                    :class="{ active: qrActive == 2 }"
                    @click="qrActive = 2"
                >
                    QR Scanner
                </div>
            </div>
            <template v-if="qrActive == 1">
                <img v-if="QrCode" :key="QrCode" :src="QrCode" alt="" />
                <div class="text-confirm">
                    Use the game's login qr function to scan the above qr code
                    to login
                </div>
            </template>
            <template v-if="qrActive == 2">
                <qrcode-stream @decode="onDecode" class="mb-3"></qrcode-stream>
                <div class="text-confirm">
                    Use qr scanning to login to the site
                </div>
            </template>
            <b-button
                variant="success"
                class="d-block btn-confirm-p mx-auto mb-3 rounded-2"
                @click="$bvModal.hide(`QR-Login`)"
            >
                Confirm
            </b-button>
        </b-modal>
        <b-modal
            id="ListAvatar"
            title="List Avatar Available"
            :centered="true"
            footer-class="d-none"
        >
            <div class="list-avatar">
                <b-button
                    class="avatar-button"
                    v-for="(avatar, key) in ListAvatar"
                    :key="`${avatar}-${key}`"
                    @click="isChose = avatar"
                    :class="{
                        active: isChose == avatar || UserInfo.Avatar == avatar,
                    }"
                >
                    <img :src="avatar" alt="" />
                </b-button>
            </div>
            <div
                class="list-button d-flex justify-content-center align-items-center"
            >
                <b-button
                    variant="success"
                    class="d-block btn-confirm-p rounded-2"
                    @click="changeAvatar"
                    :disabled="!isChose"
                >
                    Change
                </b-button>
                <b-button
                    variant="danger"
                    class="d-block rounded-2"
                    @click="$bvModal.hide(`ListAvatar`)"
                >
                    Close
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>
<style lang="scss" scoped>
.dots-img {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #00ffff;
}
.row-usd {
    color: #dcdcdc;
    font-size: 16px;
}
</style>
<style lang="scss">
.left-cnt {
    position: relative;
    width: 100%;
    background: transparent;
    border: 1px solid #00ffff;
    margin: 15px 0;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    &::before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        top: 0;
        right: 0;
        z-index: 5;
        background: #00ffff;
    }
    &::after {
        z-index: 5;
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        bottom: 0;
        left: 0;
        background: #00ffff;
    }
    .land-hero {
        padding-right: 2rem !important;
        padding: 10px 0 10px 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        a {
            text-align: center;
        }
        .divide-line {
            position: relative;
            width: 31px;
            height: 1px;
            background: #00ffff;
            margin: 35px auto;
        }
        .land {
            max-width: 65px;
            width: 100%;
            height: 35px;
            position: relative;
        }
        .hero {
            position: relative;
            max-width: 46px;
            width: 100%;
            height: 56px;
        }
        .text {
            position: relative;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 0;
            color: #dcdcdc;
            text-align: center;
        }
    }
    .bottom {
        color: white;
    }
    .balance-body {
        position: relative;
        display: block;
        width: 100%;
        align-items: center;
        padding: 20px 10px;
        min-height: calc(100% - 85px);
        color: #00ffff;
        .balance-body-left {
            flex: 0 0 calc(100% - 120px);
            .balance-deposit {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 10%;
                button {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    border-radius: 0;
                    width: 150px;
                    height: 45px;
                    padding: 5px;
                    color: #000;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }
        }
    }
    .title-img {
        width: 29px;
        height: 29px;
        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .balance-title {
        color: #ffff;
        padding: 0 0 10px 10%;
        font-weight: 600;
        font-size: 1.2em;
        font-family: 'HHBI';
        position: relative;
        // text-align: center;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }
}
#ListAvatar {
    .list-avatar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        .avatar-button {
            padding: 0;
            background: none;
            margin: 10px;
            img {
                width: 75px;
            }
            &.active {
                border: 4px solid #00b1ff;
            }
        }
    }
    .list-button {
        button {
            width: 100px;
            margin: 10px;
        }
    }
    .btn-confirm-p {
        display: flex;
        justify-content: center;
        height: 40px;
        align-items: center;
        font-size: 18px;
        font-family: 'HHBI';
        color: #00ffff;
        background: #00172a;
        border: 1px solid #00ffff;
        // border-radius: 10px;
        padding: 5px;
        &:hover {
            background: #00ffff;
            color: #00172a;
        }
    }
}
#QR-Login {
    .text-confirm {
        font-size: 22px;
        @media (max-width: 767px) {
            font-size: 13px;
        }
    }
    .modal-body {
        text-align: center;
    }
    .modal-title {
        font-size: 1.5em;
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }
    img {
        width: 275px;
        margin: 20px auto;
    }
    .btn-confirm-p {
        margin: 0 auto;
        width: 100%;
        max-width: 200px;
        display: flex;
        justify-content: center;
        height: 40px;
        align-items: center;
        font-size: 18px;
        font-family: 'HHBI';
        color: #00ffff;
        background: #00172a;
        border: 1px solid #00ffff;
        border-radius: 10px;
        padding: 5px;
        &:hover {
            background: #00ffff;
            color: #00172a;
        }
    }
    .tab-qr {
        background-image: url(~@/assets/images/frame/frame-ligting-blue.png);
        background-size: 100% 100%;
        min-height: 75px;
        align-items: center;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &-menu {
            align-items: center;
            padding: 5px 15px;
            height: 100%;
            min-height: 50px;
            display: flex;
            justify-content: center;
            min-width: 120px;
            font-family: 'HHBI';
            letter-spacing: 1px;
            font-size: 1.5em;
            font-weight: 900;
            color: #fff;
            line-height: 0;
            text-align: center;
            text-decoration: none !important;
            text-transform: uppercase;
            cursor: pointer;
            &.active,
            &:hover {
                color: #00ffff;
                background-color: rgba(0, 33, 51, 0.6);
            }
        }
    }
}
.cs-flex {
    display: flex;
    @media (min-width: 1329px) {
        flex-direction: column;
    }
}
.wallet-tab {
    padding: 0;
    .slide-tournament {
        max-width: 275px;
        margin-right: 15px;
        .slick-next {
            display: none;
            right: -13px;
        }
        .slick-prev {
            display: none !important;
        }
        @media (max-width: 575px) {
            width: 100%;
            max-width: 275px;
        }
    }
    .info-user {
        margin: 20px auto 20px;
        text-align: center;
        .name {
            span {
                color: #f8f8f8;
                font-weight: 600;
                font-size: clamp(1em, 2.5vw, 1.5em);
            }
        }
        .email {
            margin: 20px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                margin-right: 10px;
            }
            span {
                text-align: center;
                color: #f8f8f8;
                font-size: clamp(0.7em, 2.5vw, 1em);
                font-weight: 550;
                font-family: 'helvetica';
                letter-spacing: 1px;
                display: block;
            }
        }
        .button-qrLogin {
            background: rgba(0, 255, 255, 0.16);
            border: none;
            border-radius: 30px;
            width: 150px;
            height: 45px;
            padding: 5px;
            margin: 0px auto 0;
            color: #00ffff;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                margin-right: 10px;
            }
            &:hover {
                transform: scale(1.05);
            }
        }
    }
    .avatar {
        width: 150px;
        height: 150px;
        background: #fff;
        border-radius: 50%;
        margin: 10px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        position: relative;
        img {
            object-fit: cover;
            border-radius: 50%;
            height: 100%;
            width: auto;
            border: 2px solid #00ffff;
        }
        .update-avatar {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            transition: all 0.3s ease-in-out;
            width: 100%;
            height: 100%;
            border: 2px solid #00b1ff;
            border-radius: 50%;
            background: rgba($color: #000000, $alpha: 0.7);
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                margin: 0 auto;
                width: 90px;
                display: flex;
                justify-content: center;
                height: 30px;
                align-items: center;
                font-size: 18px;
                font-family: 'HHBI';
                color: #00ffff;
                background: #00172a;
                border: 1px solid #00ffff;
                border-radius: 10px;
                padding: 5px;
                &:hover {
                    background: #00ffff;
                    color: #00172a;
                }
            }
        }
        .camera {
            position: absolute;
            bottom: 0;
            right: 0;
            img {
                border: none;
            }
        }
    }
    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.6em, 4vw, 1.9em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 25px 0;
        font-weight: 900;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }
    .wallet-address {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: 991px) {
            flex-wrap: wrap;
        }
        .address {
            color: #fff;
            font-size: 1.2em;
            font-weight: bold;
            margin-right: 15px;
            @media (max-width: 991px) {
                text-align: center;
                margin-bottom: 10px;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        a {
            margin: 0 5px;
            cursor: pointer;
            img {
                height: 1.2em;
                width: auto;
            }
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    .table-responsive {
        .customer-header {
            color: #888888;
            // background-color: #00ffff;
        }
        .table th,
        .table td {
            border: none;
            text-align: center;
        }
        table {
            tbody {
                tr {
                    height: 70px;
                    td {
                        background: none;
                        height: 100%;
                        padding: 0 0.75rem;
                        vertical-align: middle;
                        color: #fff;
                        font-weight: 500;
                        p {
                            height: 70px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 0;
                            background: rgba(0, 255, 255, 0.1);
                            justify-content: center;
                        }
                    }
                    &:nth-of-type(even) {
                        td {
                            p {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    .list-box-wallet {
        display: flex;
        width: 100%;

        .box-dark {
            background: linear-gradient(180deg, #00172a61 0%, #00000b29 100%);
            // border: 1px solid #00ffff;
            -webkit-backdrop-filter: blur(19px);
            backdrop-filter: blur(19px);
            flex: 1;
            margin: 15px;
            .balance-title {
                color: #ffff;
                padding: 10px 0 10px 10%;
                font-weight: 600;
                font-size: 1.2em;
                position: relative;
                // text-align: center;
                &::after {
                    content: '';
                    bottom: 0;
                    left: 0;
                    width: calc(80% + 10px);
                    height: 2px;
                    background: #00ffff;
                    position: absolute;
                    font-weight: 600;
                }
            }
            .balance-detail {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 20px 10%;
                .top {
                    color: #00ffff;
                    font-weight: 600;
                    font-size: 1.5em;
                }
                .bottom {
                    color: #ffff;
                    font-weight: 600;
                    font-size: 0.9em;
                    font-style: italic;
                }
            }
        }
        .balance-currencies {
            // margin-left: 0;
            margin: 15px;
            width: max-content;
            flex: 0 0 350px;
        }
        .balance-hgb {
            .balance-body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 10px;
                min-height: calc(100% - 85px);
            }
        }
        .balance-body-left {
            flex: 0 0 calc(100% - 120px);
            .balance-deposit {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 10%;
                button {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    border-radius: 0;
                    width: 150px;
                    height: 45px;
                    padding: 5px;
                    color: #000;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }
        }
        .balance-body-right {
            flex: 0 0 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            img {
                width: 100px;
                height: 100px;
            }
        }
        .balance-game {
            flex: 0 0 150px;
            width: 150px;
            margin: 15px;
            // height: 150px;
            .land {
                margin: 0;
                margin-bottom: 15px;
                padding: 15px;
                text-align: center;
                display: flex;
                flex-direction: column;
                text-decoration: none;
            }
            .hero {
                margin: 0;
                padding: 15px;
                text-align: center;
                display: flex;
                flex-direction: column;
                text-decoration: none;
            }
            img {
                width: 50px;
                margin: auto;
            }
            .text {
                color: #00ffff;
                font-weight: 600;
                font-size: 1.2em;
                margin-top: 5px;
            }
        }
        @media (max-width: 991px) {
            flex-wrap: wrap;
            .balance-game {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                flex: unset;
                margin: 0;
                .box-dark {
                    width: 150px;
                    height: 130px;
                    max-width: 150px;
                    margin: 15px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        @media (max-width: 991px) {
            .balance-currencies,
            .balance-hgb {
                flex: unset;
                width: 100%;
                max-width: 500px;
                margin: 15px 0;
            }
            .balance-game {
                justify-content: center;
            }
        }
    }
    .special-font {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        span {
            font-family: 'HHBI';
        }
        &:last-child {
            font-size: 16px;
        }
    }
    .activity-list {
        margin-top: 20px;
    }
    @media (max-width: 991px) {
        .title {
            margin: 25px auto;
        }
        .wallet-address {
            justify-content: center;
            margin-bottom: 15px;
        }
        .list-box-wallet {
            justify-content: center;
        }
        .balance-game {
            justify-content: center;
        }
    }
}
</style>
