<script>
import { mapGetters, mapMutations } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';

export default {
    components: {
        Paginate,
    },
    data() {
        return {
            approve: false,
            boxInsideModal: null,
            modalValue: {
                sellPrice: 1,
                addressTransfer: '',
                tokenId: null,
            },
            isShowModalCancel: false,
            isShowModalSell: false,
            isShowModalTransfer: false,
            filterBox: {
                limit: 6,
                page: 1,
                type: 0,
                tokenId: null,
            },
            tabPicker: [
                {
                    text: 'All',
                    value: 0,
                },
                {
                    text: 'Silver',
                    value: 1,
                },
                {
                    text: 'Gold',
                    value: 2,
                },
                {
                    text: 'Platinum',
                    value: 3,
                },
                {
                    text: 'Diamond',
                    value: 4,
                },
            ],
            limit: [
                {
                    text: 'Display 6 Box ',
                    value: 6,
                },
                {
                    text: 'Display 12 Box ',
                    value: 12,
                },
                {
                    text: 'Display 20 Box ',
                    value: 20,
                },
                {
                    text: 'Display 30 Box ',
                    value: 30,
                },
                {
                    text: 'Display 50 Box ',
                    value: 50,
                },
                {
                    text: 'Display 100 Box ',
                    value: 100,
                },
            ],
            isShowModalSellSuccess: false,
            isShowModalTransferSuccess: false,
            isShowModalCancelSuccess: false,
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        onChangePage(pageNext) {
            this.filterBox.page = pageNext;
            this.$store.dispatch('box/req_getListBox', this.filterBox);
        },
        async ApproveSell(box) {
            const { web3 } = this.$store.state.contract;
            const { marketNew } = this.$store.state.contract.marketplace;
            const contracts = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            const { version, tokenId } = box;
            let HeroContract = null;
            Object.keys(contracts).forEach((key) => {
                if (key.includes('hero')) {
                    if (
                        contracts[key].addr.toLowerCase() ===
                        version.toLowerCase()
                    ) {
                        HeroContract = contracts[key];
                    }
                }
            });

            this.onLoad();
            this.modalValue.tokenId = tokenId;
            const { sellPrice } = this.modalValue;
            if (sellPrice < 0 || this.isNaN(sellPrice) || !sellPrice) {
                this.$toastr.e(
                    'Min sell is 1 HBG, Please check your price sell',
                    'Open Failed',
                );
                this.outLoad();
                return;
            }
            const txObj = HeroContract.cont.methods
                .approve(marketNew.addr, tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                // Calculate gas
                gas = await HeroContract.cont.methods
                    .approve(marketNew.addr, tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;

            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: HeroContract.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                let submitted = true;
                // Kết quả transaction
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);

                        if (txData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            await this.onSellBox(box);
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        // eslint-disable-next-line consistent-return
        async onSellBox(box) {
            // this.onLoad();
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const { sellPrice } = this.modalValue;
            const { version, tokenId } = box;
            if (parseFloat(sellPrice) > 1000000000 * this.Balance.price) {
                this.messageErrorToastr(
                    `Sell price must be less than ${
                        1000000000 * this.Balance.price
                    } `,
                );
                this.outLoad();
                return;
            }
            if (sellPrice < 0 || this.isNaN(sellPrice) || !sellPrice) {
                this.$toastr.w(
                    'Min sell is 1 USD, Please check your price sell',
                    'Open Failed',
                );
                return;
            }
            const deciamalPrice = web3.provider.utils.toWei(
                sellPrice.toString(),
                'ether',
            );
            const txObj = marketNew.cont.methods
                .sell(tokenId, deciamalPrice, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            console.log('check', gasLimit);
            try {
                gas = await marketNew.cont.methods
                    .sell(tokenId, deciamalPrice, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txSellData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txSellData != null) {
                        clearInterval(check);
                        if (txSellData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('sell', txSellData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        async onTransferHero(box) {
            // this.$toastr.w('Function transfer hero is maintenance', 'Oops!');

            this.onLoad();
            if (!this.modalValue.addressTransfer) {
                this.$toastr.e(
                    'Transfer Failed',
                    'Please check receive address',
                );
                this.outLoad();
                return;
            }
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const contracts = this.$store.state.contract.marketplace;

            const { version, tokenId } = box;
            let gas = 0;
            let hero = null;

            Object.keys(contracts).forEach((key) => {
                if (key.includes('hero')) {
                    if (
                        contracts[key].addr.toLowerCase() ===
                        version.toLowerCase()
                    ) {
                        hero = contracts[key];
                    }
                }
            });

            const txObj = await hero.cont.methods
                .transferFrom(addr, this.modalValue.addressTransfer, tokenId)
                .encodeABI();

            const gasLimit = await this.getLimitGas();
            try {
                gas = await hero.cont.methods
                    .transferFrom(
                        addr,
                        this.modalValue.addressTransfer,
                        tokenId,
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hero.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            console.log(123);
                            this.sendTransaction('transfer', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        async onCancelHero(box) {
            this.onLoad();
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const { version, tokenId } = box;
            const txObj = await marketNew.cont.methods
                .cancelSale(tokenId, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await marketNew.cont.methods
                    .cancelSale(tokenId, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancel', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async onCancelHeroOld(tokenId) {
            this.onLoad();
            const { market } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await market.cont.methods
                .cancelHeroSale(tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await market.cont.methods
                    .cancelHeroSale(tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: market.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancelOld', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },

        onShowCancel(value) {
            this.isShowModalSell = false;
            this.isShowModalTransfer = false;
            this.isShowModalCancel = true;
            this.boxInsideModal = value;
        },
        showModalSell(value) {
            this.isShowModalCancel = false;
            this.isShowModalSell = true;
            this.isShowModalTransfer = false;
            this.boxInsideModal = value;
        },
        showModalTransfer(value) {
            this.isShowModalCancel = false;
            this.isShowModalSell = false;
            this.isShowModalTransfer = true;
            this.boxInsideModal = value;
        },
        clearModalValue() {
            this.modalValue = {
                sellPrice: 0,
                addressTransfer: '',
            };

            this.$store.dispatch('box/req_getListBox', this.filterBox);
            this.$store.dispatch('inventory/req_getListBox');
        },
        changeTabs(tab) {
            this.filterBox.type = tab;
            this.$store.dispatch('box/req_getListBox', this.filterBox);
        },
        sendTransaction(type, txData) {
            if (type === 'transfer') {
                this.isShowModalTransfer = false;
                this.$store.dispatch('box/req_postTransferSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-transfer-box-success');
            }
            if (type === 'sell') {
                this.isShowModalSell = false;
                this.$store.dispatch('box/req_postSellSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-sell-box-success');
            }
            if (type === 'cancel') {
                this.isShowModalCancel = false;
                this.$store.dispatch('box/req_postCancelSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-cancel-box-success');
            }
            if (type === 'cancelOld') {
                this.isShowModalCancel = false;
                this.$store.dispatch('box/req_postCancelOldSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-cancel-box-success');
            }
        },
        ComingSoonT() {
            this.$toastr.s('Function Coming Soon', 'Coming Soon');
        },
        hiddenModalTransfer() {
            this.$bvModal.hide('modal-transfer-box');
            this.modalValue = {
                sellPrice: 0,
                addressTransfer: '',
            };
        },
        onSearchOld() {
            this.filterBox.page = 1;
            this.$store.dispatch('box/req_getListBox', {
                ...this.filterBox,
                market: 'old',
            });
        },
    },
    computed: {
        ...mapGetters({
            ListBox: 'box/ListBox',
            Balance: 'wallet/Balance',
        }),
    },
    mounted() {
        this.$store.dispatch('box/req_getListBox', this.filterBox);
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                // case 'box/ACTION_SUCCESS':
                //     this.$store.dispatch('box/req_getListBox', this.filterBox);
                //     break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <b-container class="p-0">
        <b-row class="box-list-sell">
            <b-col cols="12" class="d-none">
                <div class="search-member box-input-group flex-wrap">
                    <div class="form-limit">
                        <b-form-select
                            v-model="filterBox.limit"
                            :options="limit"
                        ></b-form-select>
                        <img src="~@/assets/images/icons/drop.png" alt="" />
                    </div>
                    <div class="form-sl">
                        <b-form-select
                            v-model="filterBox.type"
                            :options="tabPicker"
                        ></b-form-select>
                        <img src="~@/assets/images/icons/drop.png" alt="" />
                    </div>

                    <b-form-input
                        v-model="filterBox.tokenId"
                        placeholder="Search ID hero"
                    ></b-form-input>
                    <!-- <div class="btn-gr">
                        <b-button variant="info" @click="onSearchOld()">
                            <div class="icons mr-1">
                                <img
                                    src="~@/assets/images/icons/search.png"
                                    alt=""
                                />
                            </div>
                            <div class="text">Search</div>
                        </b-button>
                        <b-button
                            variant="warning"
                            class="mx-1"
                            @click="onSearchOld()"
                        >
                            <div class="text">Old Market</div>
                        </b-button>
                        <b-button
                            variant="danger"
                            class="mx-1"
                            @click="resetFilter()"
                        >
                            <div class="text">reset</div>
                        </b-button>
                    </div> -->
                </div>
            </b-col>
            <b-col cols="12" class="box-name" v-if="ListBox.list.length <= 0">
                <div class="after">No Box</div>
            </b-col>
            <b-col
                cols="12"
                md="4"
                lg="4"
                sm="6"
                class="col-xs-6 px-0 px-sm-2"
                v-for="box in ListBox.list"
                :key="`list-hero-inventory-${box.tokenId}`"
            >
                <div class="inventory-box-item">
                    <div class="inventory-item-image">
                        <img
                            :src="
                                require(`@/assets/images/mockup/account/${box.type.Name.toLowerCase()}.png?v=1`)
                            "
                            alt=""
                            class="w-100 mt-5"
                        />
                    </div>
                    <div class="inventory-box-item-content">
                        <div
                            class="d-flex align-items-end justify-content-center"
                        >
                            <!-- <img
                                src="../../../assets/images/icons/icon-title-land.svg"
                                alt=""
                            /> -->
                            <div class="idBox-selling">#{{ box.tokenId }}</div>
                            <div
                                class="land-name-content"
                                :class="box.type.Name.toLowerCase()"
                            >
                                {{ box.type.Name }}
                            </div>
                        </div>
                        <hr />
                        <div
                            v-if="false"
                            class="d-flex justify-content-between box-title"
                        >
                            <div>You Have:</div>
                            <div
                                v-if="
                                    ListBox[name] !== null ||
                                    ListBox[name] !== undefined
                                "
                            >
                                <span>
                                    {{ ListBox[name] }}
                                    Box
                                </span>
                            </div>
                        </div>
                        <div
                            v-if="!box.isSelling"
                            class="d-flex justify-content-center mt-3"
                        >
                            <div class="col-6 pl-0">
                                <button
                                    @click="showModalSell(box)"
                                    class="bede-btn"
                                >
                                    <p>Sell</p>
                                </button>
                            </div>
                            <div class="col-6 px-0">
                                <button
                                    @click="showModalTransfer(box)"
                                    class="bede-btn"
                                >
                                    <p>Transfer</p>
                                </button>
                            </div>
                        </div>
                        <div
                            v-else
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div class="col-6 box-selled p-0">
                                {{ TruncateToDecimals2(box.price || 0, '', 2) }}
                                USD
                            </div>
                            <div class="col-6 px-0">
                                <button
                                    @click="onShowCancel(box)"
                                    class="bede-btn"
                                >
                                    <p>Cancel</p>
                                </button>
                            </div>
                        </div>
                        <div class="inventory-item-line">
                            <img
                                src="../../../assets/images/icons/icon-line-svg.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <div class="d-flex justify-content-center mt-4">
            <Paginate @current="onChangePage" :totalPages="ListBox.total" />
        </div>
        <!-- sell -->
        <b-modal
            id="modal-sell-box"
            title="CONFIRM SELL BOX"
            hide-footer
            :centered="true"
            v-model="isShowModalSell"
        >
            <div class="form-group mt-3">
                <div class="box" v-if="boxInsideModal">
                    <div class="box-id">#{{ boxInsideModal.tokenId }}</div>
                    <div class="box-img">
                        <img
                            :src="
                                require(`@/assets/images/mockup/shop/${boxInsideModal.type.Name.toLowerCase()}.png`)
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="box-name"
                        :class="boxInsideModal.type.Name.toLowerCase()"
                    >
                        the {{ boxInsideModal.type.Name }} box
                    </div>
                </div>
                <div
                    class="box-input-group mx-auto d-flex align-items-end justify-content-center mt-3"
                >
                    <label class="mr-2 mb-0"> Price </label>
                    <div class="left-input-field">
                        <div class="top">
                            ~
                            {{
                                TruncateToDecimals2(
                                    modalValue.sellPrice * Balance.price,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                        <div class="bottom">
                            <b-form-input
                                v-model="modalValue.sellPrice"
                                class="not-max"
                                min="1"
                                type="number"
                                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 48 && event.keyCode !== 187 && event.keyCode !== 190"
                            ></b-form-input>
                            <div class="text text-black">USD</div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="ApproveSell(boxInsideModal)"
                    >
                        Confirm
                    </b-button>
                    <b-button
                        class="btn-cancel d-block text-white rounded-0"
                        @click="$bvModal.hide('modal-sell-box')"
                    >
                        Cancel
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="modal-sell-box-success"
            title="SELL BOX SUCCESS"
            hide-footer
            :centered="true"
            v-model="isShowModalSellSuccess"
            @hidden="clearModalValue"
        >
            <div class="form-group mt-3">
                <div class="box" v-if="boxInsideModal">
                    <div class="box-id">#{{ boxInsideModal.tokenId }}</div>
                    <div class="box-img">
                        <img
                            :src="
                                require(`@/assets/images/mockup/shop/${boxInsideModal.type.Name.toLowerCase()}.png`)
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="box-name"
                        :class="boxInsideModal.type.Name.toLowerCase()"
                    >
                        the {{ boxInsideModal.type.Name }} box
                    </div>
                </div>
                <div
                    class="box-input-group mx-auto d-flex align-items-end justify-content-center mt-3"
                >
                    <label class="mr-2 mb-0"> Price </label>
                    <div class="left-input-field">
                        <div class="top">
                            ~
                            {{
                                TruncateToDecimals2(
                                    modalValue.sellPrice * Balance.price,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                        <div class="bottom">
                            <b-form-input
                                v-model="modalValue.sellPrice"
                                class="not-max"
                                min="1"
                                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 48 && event.keyCode !== 187 && event.keyCode !== 190"
                            ></b-form-input>
                            <div class="text text-black">USD</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="isShowModalSellSuccess = false"
                    >
                        Confirm
                    </b-button>
                </div>
            </div>
        </b-modal>
        <!-- cancel -->
        <b-modal
            id="modal-cancel-sell-box"
            title="CANCEL SELL Box"
            hide-footer
            :centered="true"
            v-model="isShowModalCancel"
        >
            <div class="form-group mt-3">
                <div class="box" v-if="boxInsideModal">
                    <div class="box-id">#{{ boxInsideModal.tokenId }}</div>
                    <div class="box-img">
                        <img
                            :src="
                                require(`@/assets/images/mockup/shop/${boxInsideModal.type.Name.toLowerCase()}.png`)
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="box-name"
                        :class="boxInsideModal.type.Name.toLowerCase()"
                    >
                        the {{ boxInsideModal.type.Name }} box
                    </div>
                </div>
                <div
                    class="box-input-group flex-column mx-auto d-flex align-items-center justify-content-center mt-5"
                    v-if="boxInsideModal"
                >
                    <label class="mr-2 mb-0">Price sell</label>
                    <div
                        class="box-input-group mx-auto d-flex align-items-center justify-content-center mt-1"
                        v-if="boxInsideModal.price"
                    >
                        <b-form-input
                            :value="
                                TruncateToDecimals2(
                                    boxInsideModal.price,
                                    '',
                                    0,
                                ) || 0
                            "
                            readonly
                        ></b-form-input>
                        <div class="text text-black">HBG</div>
                    </div>
                </div>
                <div
                    class="d-flex justify-content-around mt-5 w-100"
                    v-if="boxInsideModal"
                >
                    <b-button
                        v-if="
                            1 >
                            new Date('2022-06-27 00:00:00').getTime() -
                                new Date(boxInsideModal.timeSell).getTime()
                        "
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onCancelHero(boxInsideModal)"
                    >
                        Cancel Now
                    </b-button>
                    <b-button
                        v-else
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onCancelHeroOld(boxInsideModal.tokenId)"
                    >
                        Cancel Now
                    </b-button>
                    <b-button
                        class="btn-cancel d-block text-white rounded-0"
                        @click="$bvModal.hide('modal-cancel-sell-box')"
                    >
                        Cancel
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="modal-cancel-box-success"
            title="CANCEL SELL BOX SUCCESS"
            hide-footer
            :centered="true"
            v-model="isShowModalCancelSuccess"
            @hidden="clearModalValue"
        >
            <div class="form-group mt-3">
                <div class="box" v-if="boxInsideModal">
                    <div class="box-id">#{{ boxInsideModal.tokenId }}</div>
                    <div class="box-img">
                        <img
                            :src="
                                require(`@/assets/images/mockup/shop/${boxInsideModal.type.Name.toLowerCase()}.png`)
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="box-name"
                        :class="boxInsideModal.type.Name.toLowerCase()"
                    >
                        the {{ boxInsideModal.type.Name }} box
                    </div>
                </div>
                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="isShowModalCancelSuccess = false"
                    >
                        Confirm
                    </b-button>
                </div>
            </div>
        </b-modal>
        <!-- transfer -->
        <b-modal
            id="modal-transfer-box"
            title="CONFIRM TRANSFER BOX"
            hide-footer
            :centered="true"
            v-model="isShowModalTransfer"
        >
            <div class="form-group mt-3">
                <div class="box" v-if="boxInsideModal">
                    <div class="box-id">#{{ boxInsideModal.tokenId }}</div>
                    <div class="box-img">
                        <img
                            :src="
                                require(`@/assets/images/mockup/shop/${boxInsideModal.type.Name.toLowerCase()}.png`)
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="box-name"
                        :class="boxInsideModal.type.Name.toLowerCase()"
                    >
                        the {{ boxInsideModal.type.Name }} box
                    </div>
                </div>

                <div class="mx-auto" style="width: 80%">
                    <label class="mb-0 mx-auto d-block label-address">
                        Address
                    </label>
                    <b-form-input
                        v-model="modalValue.addressTransfer"
                        class="input-address"
                    >
                    </b-form-input>
                </div>

                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onTransferHero(boxInsideModal)"
                    >
                        Confirm
                    </b-button>
                    <b-button
                        class="btn-cancel d-block text-white rounded-0"
                        @click="hiddenModalTransfer()"
                    >
                        Cancel
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="modal-transfer-box-success"
            title="TRANSFER BOX SUCCESS"
            hide-footer
            :centered="true"
            @hidden="clearModalValue"
            v-model="isShowModalTransferSuccess"
        >
            <div class="form-group mt-3">
                <div class="box" v-if="boxInsideModal">
                    <div class="box-id">#{{ boxInsideModal.tokenId }}</div>
                    <div class="box-img">
                        <img
                            :src="
                                require(`@/assets/images/mockup/shop/${boxInsideModal.type.Name.toLowerCase()}.png`)
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="box-name"
                        :class="boxInsideModal.type.Name.toLowerCase()"
                    >
                        the {{ boxInsideModal.type.Name }} box
                    </div>
                </div>

                <div class="mx-auto" style="width: 80%">
                    <label class="mb-0 mx-auto d-block label-address"
                        >Address</label
                    >
                    <b-form-input
                        v-model="modalValue.addressTransfer"
                        class="input-address"
                        readonly
                    >
                    </b-form-input>
                </div>

                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="isShowModalTransferSuccess = false"
                    >
                        Confirm
                    </b-button>
                </div>
            </div>
        </b-modal>
    </b-container>
</template>
<style lang="scss">
.box-list-sell {
    .box-navbar {
        background-image: url(~@/assets/images/frame/frame-ligting-blue.png);
        background-size: 100% 100%;
        min-height: 75px;
        align-items: center;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &-item {
            align-items: center;
            padding: 5px 15px;
            height: 100%;
            min-height: 50px;
            display: flex;
            justify-content: center;
            min-width: 80px;
            font-family: 'HHBI';
            letter-spacing: 1px;
            font-size: 1.5em;
            font-weight: 900;
            color: #fff;
            line-height: 0;
            text-align: center;
            text-decoration: none !important;
            text-transform: uppercase;
            cursor: pointer;
            &.active,
            &:hover {
                color: #00ffff;
                background-color: rgba(0, 33, 51, 0.6);
            }
        }
        .btn-toogle {
            width: 35px;
            height: 35px;
        }
    }
    .px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .box-name {
        position: relative;
        margin: auto;
        width: 100%;
        min-height: 130px;
        text-align: center;
        justify-content: center;
        display: flex;
        .after {
            font-size: clamp(60px, 4vw, 80px);
            font-family: 'HHBI';
            text-align: center;
            text-transform: uppercase;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            text-align: center;
            position: unset !important;
        }
        @media (max-width: 991px) {
            .after {
                font-size: clamp(40px, 3vw, 75px);
            }
        }
        @media (max-width: 575px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }

    .box {
        width: 100%;
        min-height: 260px;
        padding: 5px 0 15px;
        margin: 0 auto 25px;

        max-width: 275px;
        background: {
            image: url(~@/assets/images/mockup/account/body-box.png);
            repeat: no-repeat;
            size: 100% 100%;
        }
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                max-height: 165px;
                object-fit: scale-down;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
        .box-action {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0 7px;
            &.selling {
                flex-direction: column;
                button {
                    margin: 5px auto;
                }
            }
            button {
                width: auto;
                padding: 5px 20px;
                min-width: 90px;
                min-height: 40px;
                border-radius: 5px;
                border: 0;
                color: #ffff;
                font-weight: 600;
                &.sell {
                    background: {
                        image: url('~@/assets/images/mockup/account/sell.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
                &.transfer {
                    background: {
                        image: url('~@/assets/images/mockup/account/transfer.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
                &.price {
                    background: {
                        image: url('~@/assets/images/mockup/account/price.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
                &.cancel {
                    background: {
                        image: url('~@/assets/images/mockup/account/cancel.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
            }
        }
    }

    .btn-gr {
        position: relative;
        max-width: 255px;
        min-width: 255px;
        margin: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        div {
            line-height: 100%;
        }
    }
    .form-sl {
        position: relative;
        max-width: 255px;
        min-width: 255px;
        margin: 5px 10px;
        flex: 1;
        img {
            height: 15px;
            width: auto;
            position: absolute;
            right: 15px;
            bottom: 0;
            top: 0;
            margin: auto;
        }
    }
    .box-input-group {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
        // max-width: 1;
        margin: 10px auto 25px;
        .icons {
            img {
                width: 17px;
                height: auto;
            }
        }
        input {
            outline: none;
            border: 1px solid #00ffff;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 100%;
            border-radius: 0;
            color: #fff;
            margin: 5px 10px;
            min-width: 255px;
            max-width: 255px;
            flex: 1;
        }
        button {
            background: #00ffff;
            border-radius: 0;
            height: 40px;
            color: #000;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            &.btn-danger {
                background: #ff0000;
                color: #fff;
            }
            &.btn-warning {
                background: #febf32;
                color: #000;
                font-size: 16px;
            }
        }
        select {
            min-width: 255px;
            max-width: 255px;
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #00ffff;
            option {
                background: #00000b;
            }
        }
    }
    .form-limit {
        position: relative;
        flex: 1;
        margin: 5px 10px;
        min-width: 255px;
        max-width: 255px;
        img {
            height: 15px;
            width: auto;
            position: absolute;
            right: 15px;
            bottom: 0;
            top: 0;
            margin: auto;
        }
        select {
            min-width: 175px;
        }
    }
    .box-selled {
        color: #fff;
        font-family: 'HHBI';
        font-size: 20px;
    }
}
#modal-cancel-box-success,
#modal-cancel-sell-box,
#modal-sell-box,
#modal-sell-box-success,
#modal-transfer-box,
#modal-transfer-box-success {
    .box {
        max-width: 250px;
        width: 100%;
        padding: 5px 0 15px;
        margin: 0 auto 5px;
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                max-height: 210px;
                object-fit: cover;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
    }

    .box-input-group {
        font-family: 'hemiheadRg-BoldItalic';
        height: 40px;
        // font-size: clamp(1rem, 1.5vw, 1.5rem);

        label {
            color: #fff;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
        }

        input {
            outline: none;
            margin: 0;
            border-radius: 0;
            color: #fff;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
            // height: 50px;
            height: 100%;

            border: 1px solid #00ffff;
            background: linear-gradient(to right, #18274a, #001326);
            display: block;
            width: 50%;

            &::placeholder {
                color: #fff;
                font-size: clamp(1rem, 1.5vw, 1.5rem);
            }
        }
        .text {
            background: #00ffff;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
            color: #000;
            line-height: 40px;
            padding: 0 10px 0 5px;
        }
    }

    .btn-cancel {
        background: none;
        border: 1px solid #00ffff;
        color: #000;
        font-family: 'helvetica';
        font-size: 20px;
    }

    .label-address {
        font-family: 'hemiheadRg-BoldItalic';
        color: #fff;
        font-size: clamp(1rem, 1.5vw, 1.5rem);
    }

    .input-address {
        font-family: 'hemiheadRg-BoldItalic';
        outline: none;
        margin: 0;
        border-radius: 0;
        color: #fff;
        font-size: clamp(1rem, 1.5vw, 1.5rem);

        border: 1px solid #00ffff;
        background: linear-gradient(to right, #18274a, #001326);
        width: 100%;
    }
}
</style>

<style lang="scss" scoped>
.inventory-box-item {
    button.bede-btn {
        max-width: 136px;
        height: 38px;
        border-radius: 105px;
        outline: none !important;
        &::before {
            border-radius: 105px;
        }
    }
    .land-name-content {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #f8f8f8;
        padding-left: 25px;
    }
    .idBox-selling {
        font-weight: 500;
        color: #00ffff;
        padding-bottom: 4px;
        margin-right: -10px;
    }
}
</style>
