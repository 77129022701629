var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0"},[_c('div',{staticClass:"hero-list-vaccinate"},[(1 > _vm.ListPicker.length)?_c('div',{staticClass:"box-picker mb-3"},_vm._l((_vm.tabPicker),function(tab){return _c('div',{key:("name-" + (tab.name)),staticClass:"box-picker-item",class:{ active: _vm.params.ratity === tab.value },on:{"click":function($event){_vm.params.ratity = tab.value}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),0):_vm._e(),(_vm.ListHero.list.length <= 0)?_c('div',{staticClass:"box-name"},[_c('div',{staticClass:"after"},[_vm._v("No Hero")])]):_vm._e(),_c('carousel',{key:("carousel-hero-receiver-" + (_vm.ListHero.list.length) + "-" + (_vm.ListPicker.length)),ref:"carousel",attrs:{"perPage":4,"navigationEnabled":true,"paginationEnabled":false,"centerMode":true,"perPageCustom":[
                [320, 1],
                [600, 2],
                [1024, 3],
                [1200, 4],
                [1800, 4] ],"navigation-next-label":"","navigation-prev-label":""}},_vm._l((_vm.ListHero.list),function(hero){return _c('slide',{key:("list-hero-inventory-" + (hero.tokenId))},[_c('div',{staticClass:"hero"},[_c('div',{staticClass:"left-hero"},[_c('div',{staticClass:"top-hero"},[_c('div',{ref:"idHero",refInFor:true,staticClass:"id-hero"},[_vm._v(" #"+_vm._s(hero.tokenId)+" ")]),_c('div',{staticClass:"hero-img"},[_c('div',{staticClass:"avatar-hero"},[(hero.name)?_c('img',{attrs:{"src":_vm.getImageHero(
                                                '3d',
                                                ((hero.name.toLowerCase()) + "-" + (hero.skin.toLowerCase()))
                                            ),"alt":""}}):_vm._e()]),_c('div',{staticClass:"right-hero"},[_c('div',{staticClass:"stat-hero"},[_c('div',{staticClass:"hero-level"},[_vm._v(" "+_vm._s(10 > hero.level ? '0' + hero.level.toString() : hero.level)+" ")]),_c('img',{key:("inventory-" + (hero.id)),attrs:{"src":require(("@/assets/images/mockup/inventory/" + (hero.ratity.toLowerCase()) + ".png")),"alt":""}}),_c('img',{key:("inventory-2-" + (hero.id)),attrs:{"src":require(("@/assets/images/mockup/inventory/" + (hero.skin.toLowerCase()) + ".png?v=1")),"alt":""}}),_c('img',{key:("inventory-3-" + (hero.id)),attrs:{"src":require(("@/assets/images/mockup/inventory/" + (hero.type.toLowerCase()) + ".png?v=1")),"alt":""}})])])])]),_c('div',{staticClass:"bottom-hero",staticStyle:{"padding-right":"50px"}},[_c('div',{staticClass:"hero-name"},[_vm._v(" the "),_c('span',[_vm._v(" "+_vm._s(hero.name)+" ")])]),_c('div',{staticClass:"hero-summon text-white text-center"},[_vm._v(" Summon Count: "),_c('span',[_vm._v(" "+_vm._s(hero.quantitySummon)+" / "+_vm._s(hero.maxSummon)+" ")])]),[(
                                        hero.ratity.toLowerCase() !== 'free'
                                    )?_c('div',{staticClass:"hero-action justify-content-center text-center"},[(_vm.showRemoveHero(hero.tokenId))?_c('b-button',{staticClass:"btn-choose-hero d-flex remove",on:{"click":function($event){return _vm.onRemoveHero(hero.tokenId)}}},[_vm._v(" Remove ")]):(
                                            !_vm.hiddenLegend ||
                                            (_vm.hiddenLegend &&
                                                hero.ratity.toLowerCase() !==
                                                    'legend')
                                        )?_c('b-button',{staticClass:"btn-choose-hero d-flex",on:{"click":function($event){return _vm.onChoseHero(hero)}}},[_vm._v(" choose ")]):_vm._e()],1):_vm._e()]],2)])])])}),1)],1),_c('div',{staticClass:"d-flex justify-content-center mt-4 text-white"},[_vm._v(" Slide Available "),_c('span',{staticClass:"text-success px-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.params.limit * (_vm.params.page - 1))+" - "+_vm._s(_vm.params.limit * (_vm.params.page - 1) + _vm.params.limit)+" ")]),_vm._v(" of "),_c('span',{staticClass:"text-success px-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.ListHero.totalHero)+" ")]),_vm._v(" Hero ")]),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('Paginate',{attrs:{"totalPages":_vm.ListHero.total},on:{"current":_vm.onChangePage}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }