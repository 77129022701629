<script>
import { mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import Paginate from '@/components/shared/Paginate.vue';

export default {
    components: { Paginate, Carousel, Slide },
    props: {
        isSummon: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            params: {
                page: 1,
                limit: 100,
                ratity: null,
            },
            showCarousel: true,
            hiddenLegend: false,
            tabPicker: [
                {
                    name: 'Legend Hero',
                    value: 'Legend',
                },
                {
                    name: 'Platinum Box',
                    value: 'Platinum',
                },
                {
                    name: 'Diamond Box',
                    value: 'Diamond',
                },
            ],
            keyTime: null,
        };
    },
    methods: {
        onAction() {
            this.$toastr.w('', 'Coming Soon!');
        },
        onChangePageHero(pageNext) {
            this.params.page = pageNext;
            this.$store.dispatch('swap/req_getListHero', this.params);
        },
        onChangePageBox(pageNext) {
            this.params.page = pageNext;
            this.$store.dispatch('swap/req_getListBox', this.params);
        },
        onChoseHero(hero) {
            this.$store.commit('swap/ADD_HERO', hero);
        },
        onRemoveHero(hero) {
            this.$store.commit('swap/REMOVE_HERO', hero);
        },
        showRemoveHero(tokenId) {
            const idx = this.ListPicker.findIndex((x) => x.tokenId === tokenId);

            if (idx > -1) {
                return true;
            }
            return false;
        },
    },
    watch: {
        'params.ratity': {
            handler(newValue) {
                if (newValue === 'Legend') {
                    this.onChangePageHero(1);
                } else {
                    this.onChangePageBox(1);
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters({
            ListHero: 'swap/ListHero',
            ListPicker: 'swap/ListPicker',
            ListIdPicker: 'swap/ListIdPicker',
            ListBox: 'swap/ListBox',
        }),
    },
    mounted() {
        this.params.ratity = 'Legend';
        this.onChangePageHero(1);
        this.onChangePageBox(1);
        setTimeout(() => {
            this.$refs.carousel.onResize();
            this.$refs.carousel.goToPage(0);
        }, 200);
    },
};
</script>

<template>
    <b-container class="p-0">
        <div class="hero-list-swap">
            <div class="box-picker mb-3">
                <div
                    class="box-picker-item"
                    v-for="tab in tabPicker"
                    :key="`name-${tab.name}`"
                    :class="{ active: params.ratity === tab.value }"
                    @click="params.ratity = tab.value"
                >
                    {{ tab.name }}
                </div>
            </div>
            <div class="box-name" v-if="ListHero.list.length <= 0">
                <div class="after">No Hero</div>
            </div>
            <template v-if="params.ratity === 'Legend'">
                <carousel
                    ref="carousel"
                    :key="`carousel-hero-receiver-${ListHero.list.length}-${ListPicker.length}`"
                    :perPage="2"
                    :navigationEnabled="true"
                    :paginationEnabled="false"
                    :centerMode="true"
                    :perPageCustom="[
                        [320, 1],
                        [600, 2],
                        [1024, 3],
                        [1200, 3],
                        [1800, 4],
                    ]"
                    navigation-next-label
                    navigation-prev-label
                >
                    <template v-for="hero in ListHero.list">
                        <slide
                            v-if="hero.quantitySummon <= 0"
                            :key="`list-hero-inventory-${hero.tokenId}`"
                        >
                            <div class="hero">
                                <div class="hero-backdrop"></div>
                                <div class="hero-avatar">
                                    <img
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${hero.name.toLowerCase()}-${hero.skin.toLowerCase()}`,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>

                                <div class="hero-information">
                                    <div class="hero-information-header">
                                        <div
                                            class="hero-information-header-level"
                                        >
                                            {{
                                                10 > hero.level
                                                    ? '0' +
                                                      hero.level.toString()
                                                    : hero.level
                                            }}
                                            / 20
                                        </div>
                                        <div
                                            class="hero-information-header-name"
                                        >
                                            {{ hero.name }}
                                        </div>
                                    </div>
                                    <div class="hero-information-box">
                                        <div class="header-information-left">
                                            Token ID
                                        </div>
                                        <div class="header-information-right">
                                            #{{ hero.tokenId }}
                                        </div>
                                    </div>
                                    <div class="hero-information-box">
                                        <div class="header-information-left">
                                            Summon
                                        </div>
                                    </div>
                                    <div class="hero-information-button">
                                        <b-button
                                            class="pick-button remove"
                                            @click="onRemoveHero(hero)"
                                            v-if="
                                                ListIdPicker.length &&
                                                ListIdPicker[0] == hero.tokenId
                                            "
                                        >
                                            <div class="pick-button-content">
                                                Remove
                                            </div>
                                        </b-button>
                                        <b-button
                                            class="pick-button"
                                            @click="onChoseHero(hero)"
                                            v-else
                                        >
                                            <div class="pick-button-content">
                                                Add Swap
                                            </div>
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </slide>
                    </template>
                </carousel>
                <div class="d-flex justify-content-center mt-4 text-white">
                    Slide Available
                    <span class="text-success px-2 font-weight-bold">
                        {{ params.limit * (params.page - 1) }} -
                        {{ params.limit * (params.page - 1) + params.limit }}
                    </span>
                    of
                    <span class="text-success px-2 font-weight-bold">
                        {{ ListHero.totalHero }}
                    </span>
                    Hero
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <Paginate
                        @current="onChangePageHero"
                        :totalPages="ListHero.total"
                    />
                </div>
            </template>
            <template v-else>
                <carousel
                    ref="carousel"
                    :key="`carousel-box-receiver-${ListBox.list.length}-${ListPicker.length}`"
                    :perPage="2"
                    :navigationEnabled="true"
                    :paginationEnabled="false"
                    :centerMode="true"
                    :perPageCustom="[
                        [320, 1],
                        [600, 2],
                        [1024, 3],
                        [1200, 3],
                        [1800, 4],
                    ]"
                    navigation-next-labe
                    navigation-prev-label
                >
                    <slide
                        v-for="box in ListBox.list"
                        :key="`list-box-inventory-${box.tokenId}`"
                    >
                        <div class="hero">
                            <div class="hero-backdrop"></div>
                            <div class="hero-avatar">
                                <img
                                    :src="
                                        require(`@/assets/images/mockup/account/${box.type.Name.toLowerCase()}.png?v=1`)
                                    "
                                    alt=""
                                />
                            </div>

                            <div class="hero-information">
                                <div class="hero-information-header">
                                    <div class="hero-information-header-name">
                                        {{ box.name }}
                                    </div>
                                </div>
                                <div class="hero-information-box">
                                    <div class="header-information-left">
                                        Token ID
                                    </div>
                                    <div class="header-information-right">
                                        #{{ box.tokenId }}
                                    </div>
                                </div>
                                <div class="hero-information-button">
                                    <b-button
                                        class="pick-button remove"
                                        @click="onRemoveHero(box)"
                                        v-if="
                                            ListIdPicker.length &&
                                            ListIdPicker[0] == box.tokenId
                                        "
                                    >
                                        <div class="pick-button-content">
                                            Remove
                                        </div>
                                    </b-button>
                                    <b-button
                                        class="pick-button"
                                        @click="onChoseHero(box)"
                                        v-else
                                    >
                                        <div class="pick-button-content">
                                            Add Swap
                                        </div>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </slide>
                </carousel>
                <div class="d-flex justify-content-center mt-4 text-white">
                    Slide Available
                    <span class="text-success px-2 font-weight-bold">
                        {{ params.limit * (params.page - 1) }} -
                        {{ params.limit * (params.page - 1) + params.limit }}
                    </span>
                    of
                    <span class="text-success px-2 font-weight-bold">
                        {{ ListBox.totalHero }}
                    </span>
                    Box
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <Paginate
                        @current="onChangePageBox"
                        :totalPages="ListBox.total"
                    />
                </div>
            </template>
        </div>
    </b-container>
</template>

<style lang="scss">
.hero-list-swap {
    .box-picker {
        border-top: 1px solid #00ffff;
        border-bottom: 1px solid #00ffff;
        width: 100%;
        min-height: 50px;
        margin-bottom: 55px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        &-item {
            cursor: pointer;
            font-family: 'HHBI';
            background: none !important;
            box-shadow: unset !important;
            border: 0 !important;
            outline: unset !important;

            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            /* or 186% */

            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            letter-spacing: 0.75px;

            /* cyan */
            color: #fff;
            &.active,
            &:hover {
                color: #00ffff;
            }
        }
        .btn-toogle {
            width: 35px;
            height: 35px;
        }
    }
    .box-name {
        position: relative;
        margin: auto;
        width: 100%;
        min-height: 130px;
        text-align: center;
        justify-content: center;
        display: flex;
        .after {
            position: unset !important;
            font-size: clamp(60px, 4vw, 80px);
            font-family: 'HHBI';
            text-align: center;
            text-transform: uppercase;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
        @media (max-width: 991px) {
            .after {
                font-size: clamp(40px, 3vw, 75px);
            }
        }
        @media (max-width: 575px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
    .VueCarousel-navigation-next {
        right: 3% !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
        border: 0 !important;
        outline: none !important;
    }
    .VueCarousel-navigation-prev {
        left: 3% !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
        border: 0 !important;
        outline: none !important;
    }
    .VueCarousel-inner {
        visibility: visible !important;
        // flex-basis: 100%!important;
        // width: 100%!important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    @media (max-width: 575px) {
        .VueCarousel-navigation-next {
            right: 5% !important;
        }
        .VueCarousel-navigation-prev {
            left: 5% !important;
        }
    }
    .hero {
        position: relative;
        max-width: 236px;
        width: 100%;
        margin: auto;
        &-avatar {
            height: 175px;
            width: 100%;

            img {
                width: 100%;
                height: auto;
                max-height: 175px;
                object-fit: scale-down;
            }
        }
        &-backdrop {
            clip-path: polygon(40% 20%, 100% 0%, 100% 100%, 0 100%, 0 35%);
            background: linear-gradient(
                12.84deg,
                #00ffff 4.96%,
                rgba(0, 255, 255, 0) 102.53%
            );
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 312px;
            content: ' ';
            opacity: 0.1;
            z-index: 0;
        }
        &-information {
            height: 215px;
            width: 100%;

            position: relative;
            z-index: 1;
            padding: 30px 20px;
            &-header {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 1px solid #00ffff;
                padding-bottom: 15px;
                &-level {
                    font-family: 'HHBI';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 20px;
                    margin-right: 15px;
                    /* cyan */

                    color: #00ffff;
                }
                &-name {
                    font-family: 'HHBI';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 20px;

                    color: #f8f8f8;
                }
            }
            &-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                letter-spacing: 0.75px;
                margin: 10px 0;
                /* GrayScale/Background */
                color: #f8f8f8;
            }
            .pick-button {
                &.remove {
                    border: 1px solid #fe3f61;
                    color: #fe3f61;
                    &:after,
                    &::before {
                        background: #fe3f61;
                    }
                }
                &-content {
                    position: relative;
                    z-index: 10;
                    font-family: 'hemiheadRg-BoldItalic';
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    /* or 144% */

                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.75px;
                    // color: #FE3F61;
                    justify-content: center;
                    img {
                        filter: invert(35%) sepia(131%) saturate(624%)
                            hue-rotate(147deg) brightness(100%) contrast(106%);
                    }
                }
                border: 1px solid #00ffff;
                background: transparent;
                box-shadow: unset;
                position: relative;
                width: 200px;
                height: 50px;
                border-radius: 0;
                color: #00ffff;
                &:after,
                &::before {
                    z-index: 0;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: #00ffff;
                }

                &:after {
                    position: absolute;
                    top: 1px;
                    left: 1px;
                }
                &::before {
                    position: absolute;
                    bottom: 1px;
                    right: 1px;
                    -webkit-transform: scaleY(0.5);
                    transform: scaleY(0.5);
                    -webkit-transform-origin: 50% 100%;
                    transform-origin: 50% 100%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.5s;
                    transition-duration: 0.5s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                }
                &:hover,
                &:focus,
                &:active {
                    color: #000;

                    &::before {
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        -webkit-transform: scaleY(1);
                        transform: scaleY(1);
                        -webkit-transition-timing-function: cubic-bezier(
                            0.52,
                            1.64,
                            0.37,
                            0.66
                        );
                        transition-timing-function: cubic-bezier(
                            0.52,
                            1.64,
                            0.37,
                            0.66
                        );
                    }

                    img {
                        filter: invert(0%) sepia(0%) saturate(0%)
                            hue-rotate(15deg) brightness(22%) contrast(102%);
                    }
                }
            }
        }
    }
}
</style>
