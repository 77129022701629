<script>
import Paginate from '@/components/shared/Paginate.vue';
import { mapGetters, mapMutations } from 'vuex';
import ICountUp from 'vue-countup-v2';
import modalBurnVue from './modalBurn.vue';
import Hero from './Hero.vue';
import oneHero from './oneHero.vue';

const axios = require('@/store/axios.config').default;

export default {
    name: 'BurnPage',
    components: { Paginate, modalBurnVue, Hero, oneHero, ICountUp },
    data() {
        return {
            information: [
                {
                    name: 'Total expected (Days)',
                    value: 0,
                    symbol: 'USD',
                    key: 'todayExpect',
                },
                {
                    name: 'Total received (Total)',
                    value: 0,
                    symbol: 'USD',
                    key: 'totalExpect',
                },
                {
                    name: 'Total Hero burn',
                    value: 0,
                    symbol: 'HERO',
                    key: 'totalHero',
                },
            ],
            tableFields: [
                {
                    key: 'more',
                    sortable: false,
                    label: ' ',
                    class: 'text-center align-middle',
                },
                {
                    key: 'tokenId',
                    label: 'Hero',
                    sortable: false,
                    class: 'text-left align-middle',
                },
                {
                    key: 'totalPaidUSDT',
                    label: 'Amount Receiver',
                    sortable: false,
                    class: 'text-left align-middle',
                },
                {
                    key: 'expectHBG',
                    label: 'Price HBG',
                    sortable: false,
                    class: 'text-center text-capitalize align-middle',
                },

                {
                    key: 'createdAt',
                    label: 'TIME',
                    class: 'text-center align-middle',
                },
                {
                    key: 'status',
                    label: 'Status',
                    class: 'text-center align-middle',
                },
            ],
            showDestroy: false,
            burnResult: {
                status: 0,
                totalPaidHBG: 0,
                arrayReceiveHBG: [],
                acctuallyReceiveHBG: 0,
                address: '',
                transaction: '',
                tokenId: 0,
                ratity: '',
                totalPaidUSDT: 0,
                paidPerDayUSDT: 0,
                quantityDay: 0,
                createdAt: '',
                updatedAt: '',
                id: '0',
            },
            delay: 3000,
            endVal: 500,
            options: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '',
                decimalPlaces: 4,
            },
            search: {
                page: 1,
                limit: 10,
            },
            activeNav: 1,
            quantityBurn: 1,
            priceBox: null,
        };
    },
    computed: {
        ...mapGetters({
            ListPicker: 'burn/ListPicker',
            ListIdPicker: 'burn/ListIdPicker',
            History: 'burn/History',
            Static: 'burn/Static',
            Balance: 'wallet/Balance',
            ListBox: 'inventory/ListBox',
            UserInfo: 'info/UserInfo',
        }),
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('burn/req_getListHistory', this.search);
        },
        checkField(key) {
            // eslint-disable-next-line consistent-return
            this.tableFields.forEach((el) => {
                if (el.key === key) {
                    return true;
                }
            });
            return false;
        },
        clearPick2() {
            this.quantityBurn = 1;
            this.priceBox = null;
        },
        callFunction() {
            this.showDestroy = true;
            setTimeout(() => {
                this.$refs.childComponent.shatter();
            }, 1000);
        },
        destroySuccess() {
            this.$bvModal.show('burn-success');
            this.$store.dispatch('burn/req_getStatistical');
            this.$store.dispatch('burn/req_getListHistory');
        },
        clearPick() {
            this.showDestroy = false;
            this.$store.commit('burn/RESET_PICKER');
        },
        onRemoveHero(hero) {
            this.$store.commit('upgrade/REMOVE_HERO', hero);
        },
        showConfirm() {
            if (this.ListIdPicker.length <= 0) {
                this.$toastr.e('You must select at least 1 heroes to burn');
                return;
            }
            this.$bvModal.show('confirm-burn');
        },
        async onTransferHero() {
            this.onLoad();
            const { hero, hero2 } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const dealWallet = '0x0000000000000000000000000000000000000001';
            const tokenId = this.ListIdPicker[0];
            const txObj = await hero.cont.methods
                .transferFrom(addr, dealWallet, tokenId)
                .encodeABI();
            let gas = 0;
            let currentPick = hero2;
            if (this.activeNav === 1) {
                currentPick = hero;
            }
            const gasLimit = await this.getLimitGas();
            try {
                gas = await currentPick.cont.methods
                    .transferFrom(addr, dealWallet, tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: currentPick.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction(
                                txTransferData.transactionHash,
                            );
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async onBurnBox() {
            //
            this.onLoad();
            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Burn Box'],
            });
            this.onLoad();
            const dataBurn = await axios.post('/api/burn/force-burn-box', {
                amount: this.quantityBurn,
                sign: txData,
            });
            const dataRate = await axios.get(
                'api/system/get-rateHBGtoHeronium',
            );

            if (!dataBurn.totalPaidUSDT) {
                this.outLoad();
                return;
            }
            this.burnResult = dataBurn;
            this.burnResult.rateHRN = dataRate;

            this.$bvModal.show('burn-box-success');
            this.priceBox = null;
            this.$bvModal.hide('confirm-burn-box');
            this.outLoad();
        },
        async getPricePredict() {
            const data = await axios.post('/api/burn/force-burn-box/predict', {
                amount: this.quantityBurn,
            });
            if (data) {
                this.priceBox = data.totalPaidUSDT;
            }
        },
        async sendTransaction(txTransactionHash) {
            let routerAPI = '/api/burn/force-burn-hero';
            if (this.activeNav === 1) {
                routerAPI = '/api/burn';
            }
            const dataBurn = await axios.post(routerAPI, {
                tokenId: this.ListIdPicker[0],
                transactionHash: txTransactionHash,
            });
            const dataRate = await axios.get(
                'api/system/get-rateHBGtoHeronium',
            );

            if (!dataBurn.totalPaidUSDT) {
                this.outLoad();
                return;
            }
            this.burnResult = dataBurn;
            this.burnResult.rateHRN = dataRate;
            this.$bvModal.hide('confirm-burn');
            this.callFunction();
            this.outLoad();
        },
        changeBurn(type) {
            // if(type !== 1){
            //   this.$toastr.w('We are maintenance functionality','Is Maintenance')
            //   return;
            // }
            if (type === 1) {
                this.activeNav = 1;
            } else if (type === 2) {
                this.activeNav = 2;
            } else {
                this.$bvModal.show('confirm-burn-box');
            }
        },
    },
    watch: {
        activeNav: {
            handler() {
                this.$store.commit('burn/RESET_PICKER');
            },
            deep: true,
        },
    },
    created() {
        this.$store.commit('burn/RESET_PICKER');
        this.$store.dispatch('burn/req_getStatistical');
        this.$store.dispatch('burn/req_getListHistory', this.search);
        this.$store.dispatch('inventory/req_getListBox');
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'burn/ADD_HERO':
                case 'burn/REMOVE_HERO':
                    this.$bvModal.hide('listHero-burn');
                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <b-container class="p-0 burn__page">
        <div class="burn__title position-relative">
            <img
                src="~@/assets/images/frame/frame-large.png"
                class="burn__title-img d-block mx-auto"
            />
            <h3 class="burn__title-text">Burn NFT</h3>
        </div>
        <div class="burn__page__navigation">
            <b-button
                class="burn__page__navigation-item"
                @click="changeBurn(1)"
                :class="{ active: activeNav == 1 }"
            >
                Burn Hero 1.0
            </b-button>
            <b-button
                class="burn__page__navigation-item"
                @click="changeBurn(2)"
                :class="{ active: activeNav == 2 }"
            >
                Burn Hero 2.0
            </b-button>
            <b-button
                class="burn__page__navigation-item"
                @click="changeBurn(3)"
            >
                Burn Box 2.0
            </b-button>
        </div>
        <b-row class="m-0 justify-content-md-between">
            <b-col cols="12" md="5" lg="5" class="my-2">
                <div class="box__burn">
                    <div class="container-effect">
                        <div id="stars"></div>
                        <div id="stars2"></div>
                        <div id="stars3"></div>
                    </div>
                    <div class="box__burn__circle" v-if="ListPicker.length < 1">
                        <div class="box__burn__circle-one"></div>
                        <div class="box__burn__circle-two"></div>
                        <div class="box__burn__circle-three"></div>
                    </div>
                    <div class="box__burn__selection">
                        <div
                            class="box__burn__selection-pick"
                            v-if="ListPicker.length >= 1"
                        >
                            <oneHero
                                :hero="ListPicker[0]"
                                @onPenConfirm="showConfirm"
                                @clearPick="clearPick"
                                @destroyDone="destroySuccess"
                                ref="childComponent"
                            />
                            <modalBurnVue
                                v-if="false"
                                @destroyDone="destroySuccess"
                                ref="childComponent"
                                :urls="
                                    getImageHero(
                                        '3d-raw',
                                        `${ListPicker[0].name.toLowerCase()}-${ListPicker[0].skin.toLowerCase()}`,
                                    )
                                "
                            />
                        </div>
                        <div class="box__burn__selection-unpick" v-else>
                            <b-button
                                class="button-chose"
                                @click="$bvModal.show('listHero-burn')"
                            >
                                <img
                                    src="~@/assets/images/icons/plus.svg"
                                    alt="Chose"
                                />
                            </b-button>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="12" md="6" lg="6" class="my-2">
                <div class="box__information">
                    <div class="box__information-title">
                        <div class="page-break-title">
                            <div class="text">Total Information Hero</div>
                        </div>
                    </div>
                    <div class="box__information2">
                        <div
                            class="box__information__stat"
                            v-for="stat in information"
                            :key="stat.name"
                        >
                            <div class="box__information__stat-name">
                                {{ stat.name }}
                            </div>
                            <div class="box__information__stat-value">
                                {{
                                    TruncateToDecimals2(Static[stat.key], '', 4)
                                }}
                            </div>
                            <div class="box__information__stat-symbol">
                                {{ stat.symbol }}
                            </div>
                        </div>
                    </div>
                    <div class="box__total">
                        <div class="box__total-title">
                            Total Receive Expected
                        </div>
                        <div class="box__total-value">
                            {{
                                TruncateToDecimals2(
                                    Static.totalExpectHBG,
                                    '',
                                    4,
                                )
                            }}
                            <span>USD</span>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="12">
                <div class="box__table__burn">
                    <div class="page-break-title">
                        <div class="text">History Burn Hero</div>
                    </div>
                    <div class="table-responsive table-burn">
                        <b-table
                            striped
                            hover
                            :items="History.list"
                            :fields="tableFields"
                            show-empty
                            thead-class="table-burn-header"
                        >
                            <template #empty>
                                <h4 class="text-center my-3">No Data</h4>
                            </template>
                            <template #cell(more)="row">
                                <b-button
                                    size="sm"
                                    @click="row.toggleDetails"
                                    class="btn-open-detail"
                                    :class="{ open: row.item['_showDetails'] }"
                                >
                                    <img
                                        v-if="!row.item['_showDetails']"
                                        src="~@/assets/images/icons/plus.svg"
                                        alt="Chose"
                                    />
                                    <img
                                        v-else
                                        src="~@/assets/images/icons/clearH.svg"
                                        alt="clear hero"
                                    />
                                </b-button>
                            </template>
                            <template #cell(totalPaidUSDT)="data">
                                {{
                                    TruncateToDecimals2(
                                        data.item.totalPaidUSDT,
                                        '',
                                        2,
                                    )
                                }}
                                USD
                            </template>
                            <template #cell(expectHBG)="data">
                                {{
                                    TruncateToDecimals2(
                                        (data.item.totalPaidUSDT /
                                            data.item.expectHBG) *
                                            100,
                                        '',
                                        6,
                                    )
                                }}
                                HBG/USD
                            </template>
                            <template #cell(createdAt)="data">
                                {{
                            Number.isInteger(data.item.createdAt)? getDateTime(
                                data.item.createdAt * 1000)
                                    : getDateTime3(data.item.createdAt),

                                }}
                            </template>
                            <template #cell(status)="data">
                                <span
                                    :class="
                                        data.item.status === -1
                                            ? 'text-danger'
                                            : data.item.status == 0
                                            ? 'text-warning'
                                            : 'text-danger'
                                    "
                                >
                                    {{
                                        data.item.status == -1
                                            ? 'Cancel / Pending'
                                            : data.item.status == 0
                                            ? 'Burning'
                                            : 'Burned'
                                    }}
                                </span>
                            </template>
                            <template #row-details="row">
                                <div class="detail__table">
                                    <div class="detail__table__item">
                                        <div class="detail__table__item-left">
                                            Hero Information:
                                        </div>
                                        <div class="detail__table__item-right">
                                            <span>
                                                #{{ row.item.tokenId }}
                                            </span>
                                            <span>
                                                | {{ row.item.ratity }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="detail__table__item">
                                        <div class="detail__table__item-left">
                                            Transaction:
                                        </div>
                                        <div class="detail__table__item-right">
                                            <a
                                                style="color: #00ffff"
                                                target="_blank"
                                                :href="`https://bscscan.com/tx/${row.item.transaction}`"
                                            >
                                                {{
                                                    `${row.item.transaction.slice(
                                                        0,
                                                        10,
                                                    )}...${row.item.transaction.slice(
                                                        row.item.transaction
                                                            .length - 15,
                                                        row.item.transaction
                                                            .length,
                                                    )}`
                                                }}
                                            </a>
                                        </div>
                                    </div>

                                    <div class="detail__table__item">
                                        <div class="detail__table__item-left">
                                            Receiver Per Day:
                                        </div>
                                        <div class="detail__table__item-right">
                                            {{
                                                TruncateToDecimals2(
                                                    row.item.paidPerDayUSDT,
                                                    '',
                                                    4,
                                                )
                                            }}
                                            USD
                                        </div>
                                    </div>
                                    <div class="detail__table__item">
                                        <div class="detail__table__item-left">
                                            To Day Received:
                                        </div>
                                        <div class="detail__table__item-right">
                                            {{
                                                TruncateToDecimals2(
                                                    row.item.todayReceive,
                                                    '',
                                                    4,
                                                )
                                            }}
                                            Heronium
                                        </div>
                                    </div>
                                    <div class="detail__table__item">
                                        <div class="detail__table__item-left">
                                            Total Received(USD):
                                        </div>
                                        <div class="detail__table__item-right">
                                            {{
                                                TruncateToDecimals2(
                                                    row.item
                                                        .acctuallyReceiveUSDT,
                                                    '',
                                                    4,
                                                )
                                            }}
                                            USD
                                        </div>
                                    </div>
                                    <div class="detail__table__item">
                                        <div class="detail__table__item-left">
                                            Total Received (Heronium):
                                        </div>
                                        <div class="detail__table__item-right">
                                            {{
                                                TruncateToDecimals2(
                                                    row.item
                                                        .acctuallyReceiveHBG,
                                                    '',
                                                    4,
                                                )
                                            }}
                                            Heronium
                                        </div>
                                    </div>
                                    <div class="detail__table__item">
                                        <div class="detail__table__item-left">
                                            Remaining payment days:
                                        </div>
                                        <div class="detail__table__item-right">
                                            {{ row.item.quantityDay }} Days
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <div class="table-paginate">
                        <Paginate
                            @current="onChangePage"
                            :totalPages="History.total"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
            id="listHero-burn"
            :hide-footer="true"
            title="List Hero"
            :centered="true"
            body-class="p-1"
            content-class="border-0"
            size="xl"
        >
            <Hero class="my-2" :isSummon="true" :isActive="activeNav" />
        </b-modal>
        <b-modal id="confirm-burn" title="Confirm Burn" hide-footer>
            <div>
                <p class="mb-0 text-confirm">
                    Do you want to Burn Hero #{{ ListIdPicker[0] }}?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="onTransferHero"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="confirm-burn-box"
            title="Confirm Burn Box"
            size="md"
            @hidden="clearPick2"
            hide-footer
        >
            <div>
                <p class="mb-4 text-confirm">
                    How many boxes do you need to burn?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-col cols="12" class="text-center">
                    <img
                        :src="
                            require(`@/assets/images/mockup/account/herobox.png?v=1`)
                        "
                        alt=""
                        class="d-sm-block mx-auto"
                        width="100%"
                        style="max-width: 155px"
                    />
                    <div class="d-flex flex-column input-opn">
                        <label for="inputAmount">Burn Quantity</label>

                        <b-form-input
                            min="1"
                            max="100"
                            v-model.number="quantityBurn"
                            type="number"
                            id="inputAmount"
                            placeholder="Quantity"
                            step="1"
                            required
                            class="text-center"
                            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 48 && event.keyCode !== 187 && event.keyCode !== 190"
                        >
                            ></b-form-input
                        >
                        <small v-if="quantityBurn < 1" class="mt-2 text-danger">
                            Minimun burn 1 box
                        </small>
                        <small
                            v-if="quantityBurn >= 100"
                            class="mt-2 text-danger"
                        >
                            Maximum burn 100 box
                        </small>
                        <button @click="getPricePredict" class="mt-4">
                            Expected
                        </button>
                    </div>
                    <div class="d-flex flex-column input-opn" v-if="priceBox">
                        <label for="inputAmount">Heronium Expected</label>

                        <b-form-input
                            v-model.number="priceBox"
                            readonly
                        ></b-form-input>
                    </div>
                </b-col>
            </div>
            <div
                class="form-group d-flex justify-content-center mt-3 input-opn"
            >
                <b-button
                    class="button-confirm my-2 mb-0"
                    variant="primary"
                    @click="onBurnBox"
                    :disabled="quantityBurn < 1 || quantityBurn > 100"
                >
                    Burn {{ quantityBurn }}
                </b-button>

                <b-button
                    class="mx-auto my-2 mb-0 button-cancel"
                    variant="primary"
                    @click="$bvModal.hide('confirm-burn-box')"
                >
                    Cancel
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="burn-box-success"
            title=" Burn Box Success"
            @hidden="clearPick2"
            size="md"
            hide-footer
        >
            <div class="form-group d-flex justify-content-center">
                <b-row>
                    <b-col cols="12">
                        <img
                            :src="
                                require(`@/assets/images/mockup/account/herobox.png?v=1`)
                            "
                            alt=""
                            class="d-sm-block mx-auto"
                            width="100%"
                            style="max-width: 245px"
                        />
                        <p class="mb-4 text-confirm">
                            you have successfully burned
                            {{ burnResult.tokenId.length }} boxes, the estimated
                            time to complete the burning process may take up to
                            72 hours (due to many factors affecting), with
                            {{ burnResult.tokenId.length }}
                            boxes burned you are expected to receive
                            <span class="count-percent">
                                <span class="amount-received">
                                    <ICountUp
                                        :delay="delay"
                                        :endVal="burnResult.totalPaidUSDT"
                                        :options="options"
                                    />
                                    USD
                                </span>
                                <span class="mr-2">~</span>
                                <span class="amount-received">
                                    <ICountUp
                                        :delay="delay"
                                        :endVal="burnResult.expectHBG"
                                        :options="options"
                                    />
                                    Heronium
                                </span>
                            </span>
                        </p>
                    </b-col>
                </b-row>
            </div>
            <div
                class="form-group d-flex justify-content-center mt-3 input-opn"
            >
                <b-button
                    class="mx-auto my-2 mb-0 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('burn-box-success')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="burn-success"
            @hidden="clearPick"
            title="Burn Hero Success"
            hide-footer
        >
            <div class="count-percent">
                <div class="text">
                    Burn Hero <span> #{{ burnResult.tokenId }} </span> Success
                </div>
                <div>
                    <span class="mr-2">You Received </span>
                    <span class="amount-received">
                        <ICountUp
                            :delay="delay"
                            :endVal="burnResult.totalPaidUSDT"
                            :options="options"
                        />
                        USD
                    </span>
                </div>
                <div class="mt-3">
                    <span class="mr-2">~</span>
                    <span class="amount-received">
                        <ICountUp
                            :delay="delay"
                            :endVal="
                                burnResult.totalPaidUSDT *
                                Balance.price *
                                burnResult.rateHRN
                            "
                            :options="options"
                        />
                        Heronium
                    </span>
                </div>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('burn-success')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<style lang="scss">
.input-opn {
    max-width: 425px;
    margin: 10px auto;
    input {
        width: 100%;
        margin: 0 auto;
        max-width: unset;
    }
    label {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 10px;
    }
    small {
        font-size: 15px;
        color: #fff;
        margin-bottom: 0;
    }

    button {
        border-radius: 10px;
        height: 40px;
        width: 150px;
        border: none;
        font-family: 'HHBI';
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.75px;
        color: #000;
        box-shadow: unset;
        outline: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 255, 255, 1);
        margin: 10px auto;
    }
}
.count-percent {
    min-height: 200px;
    height: 100%;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text {
        text-align: center;
        font-family: 'HHBI';
        font-style: italic;
        font-weight: 400;
        color: #fff;
        font-size: 25px;
        line-height: 20px;
        margin-bottom: 25px;
        span {
            font-size: 25px;
            line-height: 20px;
            color: #00ffff;
        }
    }
    span {
        text-align: center;
        font-family: 'HHBI';
        font-style: italic;
        font-weight: 400;
        font-size: 25px;

        line-height: 27px;
        color: #fff;
        &.amount-received {
            span {
                font-size: 29px;
                line-height: 20px;
                color: #00ffff !important;
            }
        }
    }
}
#burn-box-success {
    .text-confirm {
        font-size: 17px;
        .count-percent {
            min-height: unset;
            display: block;
            width: 100%;
            span {
                font-size: 22px;
            }
        }
    }
}
.burn__page {
    &__navigation {
        display: flex;
        justify-content: space-around;
        align-items: center;
        max-width: 768px;
        margin: 0 auto 50px;
        button {
            padding: 0;
            box-shadow: none !important;
            outline: none !important;
            border: 0 !important;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48b7;
            backdrop-filter: blur(2px);
            height: 50px;
            width: 100%;
            max-width: 210px;
            padding: 5px 10px;
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #f0f0f0;
            text-align: center;
            margin-right: 15px;
            position: relative;

            &::after,
            &::before {
                height: 50%;
                width: 6px;
                top: 0;
                bottom: 0;
                content: '';
                background: #00ffff;
                margin: auto;
                position: absolute;
            }
            &::after {
                left: -2.5px;
            }
            &::before {
                right: -1.5px;
            }
            &:hover,
            &.active {
                background-color: rgba(0, 255, 255, 0.5) !important;
            }
        }
    }

    .burn__title {
        margin-bottom: 50px;
        &-text {
            font-family: hemiheadRg-BoldItalic;
            font-size: 42px;
            color: #00ffff;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: fit-content;
            height: fit-content;
        }
    }
    .box__outside {
        max-width: 300px;
        border: 1px solid #fff;
        border-radius: 5px;
    }
    .box__burn {
        position: relative;
        width: 275px;
        height: 384px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        &__circle {
            &-one,
            &-two,
            &-three {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                z-index: 0;
                border-radius: 50%;
            }

            &-one {
                background: #fe3f61;
                width: calc(275px * 0.45);
                height: calc(275px * 0.45);
                animation: ring 4s infinite ease-in-out;
            }
            &-two {
                border: 2px solid #fe3f61;
                width: calc(275px * 0.75);
                height: calc(275px * 0.75);
                animation: fill-anim 5s infinite ease-in-out;
            }
            &-three {
                border: 2px solid #fe3f61;
                width: 275px;
                height: 275px;
                animation: fill-anim 6s infinite ease-in-out;
            }
        }
        &__selection {
            position: absolute;
            z-index: 10;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-content: center;
            &-unpick {
                display: flex;
                justify-content: center;
                align-content: center;
                position: absolute;
                z-index: 10;
                height: 36px;
                width: 36px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                .button-chose {
                    padding: 0;
                    background: none !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border: 0 !important;
                    height: 36px;
                    width: 36px;
                }
            }
            &-pick {
                display: flex;
                justify-content: center;
                align-content: center;
            }
        }
    }

    .box__information {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        &__stat {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 32px;
            &-name {
                flex: 0 0 auto;
            }
            &-value {
                flex: 1 1 auto;
                text-align: right;
                padding-right: 15px;
            }
            &-symbol {
                flex: 0 0 45px;
                color: #00ffff;
            }
        }
    }

    .box__total {
        background-color: rgba(0, 255, 255, 0.15);
        width: 100%;
        min-height: 80px;
        border-radius: 5px;
        padding: 10px 15px;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        &::after,
        &::before {
            height: 50%;
            width: 6px;
            top: 0;
            bottom: 0;
            content: '';
            background: #00ffff;
            margin: auto;
            position: absolute;
        }
        &::after {
            left: -2.5px;
        }
        &::before {
            right: -1.5px;
        }
        &-title {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #f0f0f0;
            text-align: center;
        }
        &-value {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #ffff;
            text-align: center;
            span {
                color: #00ffff;
            }
        }
    }
    .box__expected {
        background-color: rgba(0, 255, 255, 0.15);
        width: 100%;
        min-height: 80px;
        border-radius: 5px;
        padding: 0 15px;
        .box__information {
            &__stat {
                margin-bottom: 5px;
            }
        }
    }
    .box__table__burn {
        margin-top: 60px;
        .table-burn {
            table {
                th,
                tr,
                td {
                    border: 0;
                    min-width: 100px;
                    &:first-child {
                        min-width: unset;
                        padding: 0;
                        text-align: center;
                        vertical-align: middle;
                    }
                }
                th {
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    color: #f0f0f0;
                    padding: 24px;
                    position: relative;
                    text-align: center !important;
                    &::after {
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 2px;
                        height: 16px;
                        content: '';
                        margin: auto;
                        background: #00ffff;
                    }
                    &:first-child,
                    &:last-child {
                        &::after {
                            background: transparent;
                        }
                    }
                }
                tbody {
                    tr,
                    td {
                        font-family: 'Work Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        /* or 150% */
                        vertical-align: middle;
                        letter-spacing: 0.01em;

                        /* GrayScale/Input BG */
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-left: 25px;
                        color: #f0f0f0;
                    }
                    tr:nth-of-type(odd) {
                        background-color: rgba(0, 255, 255, 0.15);
                        border-radius: 1px;
                    }
                    tr {
                        &:hover {
                            background-color: rgba(0, 255, 255, 0.05);
                            border-radius: 1px;
                            color: #f0f0f0;
                        }
                    }
                }
            }
            .btn-open-detail {
                padding: 0;
                box-shadow: none !important;
                outline: none !important;
                border: 0 !important;
                height: 25px;
                width: 25px;
                background: #00172a;
                border-radius: 50%;
                margin: auto 5px !important;
                img {
                    width: 15px;
                    height: 15px;
                }
                &.open {
                    width: 25px;
                    height: 25px;
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
            .b-table-details {
                background: #001326c4 !important;
            }
            .detail__table__item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 10px;
                text-transform: capitalize;
                &-left {
                    margin-right: 15px;
                    padding-right: 15px;
                }
                &-right {
                }
            }
        }
    }
}
</style>
