const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        sign: null,
        isLogin: window.$cookies.get('HGB_user'),
        // isLogin:true,
        qrcode: null,
    }),

    getters: {
        Signature: (state) => state.sign,
        loggedIn(state) {
            return state.isLogin;
        },
        QrCode: (state) => state.qrcode,
    },

    actions: {
        getSignature: async ({ commit }, input) => {
            axios.post('api/login/wallet', input).then((res) => {
                if (res.sign && res.sign.length > 0) {
                    commit('GET_SIGNATURE_SUCCESS', res);
                }
            });
        },
        LoginAddress: ({ commit }, input) => {
            axios.post('api/login/wallet', input).then((res) => {
                if (res.token && res.token.length > 0) {
                    commit('LOGIN_SUCCESS', res);
                }
            });
        },
        LoginEmail: ({ commit }, input) => {
            axios.post('api/login/email', input).then((res) => {
                if (res.token && res.token.length > 0) {
                    commit('LOGIN_SUCCESS', res);
                }
            });
        },

        Forgot: ({ commit }, input) => {
            axios.post('/api/me/forgot-password', input).then((res) => {
                commit('FORGOT_SUCCESS', res);
            });
        },
        Register: ({ commit }, input) => {
            axios.post('api/market/register', input).then((res) => {
                commit('REGISTER_SUCCESS', res);
            });
        },
        CheckRegister: ({ commit }, input) => {
            axios.post('api/market/register/system', input).then((res) => {
                commit('REGISTER_SYSTEM_SUCCESS', res);
            });
        },
        QrCode: ({ commit }) => {
            axios.post('api/login/qrcode', {}).then((res) => {
                commit('SET_QRCODE', res);
            });
        },
        SetUpInfo: () => {},
        ChangeEmail: () => {},
        ChangePassword: () => {},
    },
    mutations: {
        LOGOUT_SUCCESS() {
            window.$cookies.remove('HGB_user');
            window.location.replace('/login');
        },
        GET_SIGNATURE_SUCCESS: (state, data) => {
            state.sign = data.sign;
        },
        LOGIN_SUCCESS: (state, data) => {
            state.isLogin = true;
            window.$cookies.remove('HGB_user');
            axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
            window.$cookies.set('HGB_user', { key: data.token }, '8H');
        },
        REGISTER_SUCCESS(state, data) {
            console.log(data);
        },
        FORGOT_SUCCESS() {},
        REGISTER_SYSTEM_SUCCESS(state, data) {
            console.log(data);
        },
        SET_QRCODE(state, data) {
            state.qrcode = data;
        },
    },
};
