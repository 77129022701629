<script>
import { mapGetters, mapActions } from 'vuex';
import Paginate from '@/components/shared/paginate-second.vue';
import Item from './item-market.vue';
import ItemBuy from './item-buy.vue';
import ItemBox from './item-box.vue';

export default {
    components: {
        Item,
        Paginate,
        ItemBuy,
        ItemBox,
    },

    data() {
        return {
            marketTab: 'Popular',
            filter: {
                limit: 15,
                page: 1,
            },
        };
    },
    methods: {
        ...mapActions('hub', [
            'req_getPlatform',
            'req_getPopularList',
            'req_getNewlyList',
            'req_getSoldList',
            'req_getUserSellList',
            'req_getUserSoldList',
        ]),
        onChangePage(page) {
            if (page === this.filter.page) {
                return;
            }
            this.$refs.topOfListItem.scrollIntoView({ behavior: 'smooth' });

            this.filter.page = page;
            this[`req_get${this.marketTab}List`](this.filter);
        },
        onReloadList() {
            this.$refs.topOfListItem.scrollIntoView({ behavior: 'smooth' });
            this[`req_get${this.marketTab}List`](this.filter);
        },
    },
    computed: {
        ...mapGetters({
            Balance: 'wallet/Balance',
            isLogin: 'auth/loggedIn',
            UserInfo: 'info/UserInfo',
        }),
        ...mapGetters('hub', [
            'PlatformList',
            'ItemPopular',
            'ItemNewly',
            'ItemSold',
            'UserSellList',
            'UserSoldList',
        ]),
    },
    watch: {
        marketTab: {
            handler() {
                this[`req_get${this.marketTab}List`](this.filter);
            },
        },
    },
    created() {
        this.req_getPlatform();
        this[`req_get${this.marketTab}List`](this.filter);
    },
};
</script>
<template>
    <b-row class="market-public">
        <b-col cols="12" lg="8">
            <div class="market-user mt-0">
                <div class="market-user-nav market-user-nav-2">
                    <a
                        class="market-user-nav-item market-user-nav-2-item"
                        @click="marketTab = 'Popular'"
                        :class="{ active: marketTab == 'Popular' }"
                    >
                        Popular items
                    </a>
                    <a
                        class="market-user-nav-item market-user-nav-2-item"
                        @click="marketTab = 'Newly'"
                        :class="{ active: marketTab == 'Newly' }"
                    >
                        Newly listed
                    </a>
                    <a
                        class="market-user-nav-item market-user-nav-2-item"
                        @click="marketTab = 'Sold'"
                        :class="{ active: marketTab == 'Sold' }"
                    >
                        Recently sold
                    </a>
                </div>
            </div>
            <div class="item-list">
                <div class="item-list-header" ref="topOfListItem">
                    <div class="item-list-header-name">Name</div>
                    <div class="item-list-header-quantity">
                        {{
                            marketTab == 'Newly' || marketTab == 'Sold'
                                ? 'Seller'
                                : 'Quantity'
                        }}
                    </div>

                    <div class="item-list-header-price">Price</div>
                    <div
                        class="item-list-header-newly"
                        v-if="marketTab == 'Newly' || marketTab == 'Sold'"
                    ></div>
                </div>
                <template v-if="marketTab == 'Newly' || marketTab == 'Sold'">
                    <template
                        v-for="(item, indexFor) in this[`Item${marketTab}`]
                            .items"
                    >
                        <ItemBuy
                            @onReloadList="onReloadList"
                            :key="`${item.itemName}-market-${indexFor}`"
                            v-if="item.name != 'box'"
                            :class="{ odd: indexFor % 2 == 0 }"
                            :item="item"
                        />
                        <ItemBox
                            @onReloadList="onReloadList"
                            v-else
                            :key="`${item.itemName}-market2-${indexFor}`"
                            :class="{ odd: indexFor % 2 == 0 }"
                            :item="item"
                        />
                    </template>
                </template>
                <template v-else>
                    <Item
                        v-for="(item, indexFor) in this[`Item${marketTab}`]
                            .items"
                        :key="`${item._id.itemName}-market-${indexFor}`"
                        :class="{ odd: indexFor % 2 == 0 }"
                        :item="item"
                    />
                </template>
                <Paginate
                    :totalPages="this[`Item${marketTab}`].totalPages || 1"
                    :totalResult="this[`Item${marketTab}`].totalItem || 1"
                    :perPage="this[`Item${marketTab}`].limit || 1"
                    @current="onChangePage"
                    class="mt-3"
                />
            </div>
        </b-col>
        <b-col cols="12" lg="4">
            <div class="market-sidebar px-4">
                <p v-if="false" class="market-sidebar-title">
                    <span>Read about the security requirements</span> for using
                    the community marketplace.
                </p>
                <label
                    v-if="false"
                    for="input-search-market"
                    class="market-sidebar-search"
                >
                    <input
                        class="market-sidebar-search:input"
                        placeholder="Search ID"
                        id="input-search-market"
                        name="id"
                        type="text"
                    />
                    <button class="search-market-btn">
                        <img
                            src="~@/assets/images/icons/icon-search.svg"
                            alt=""
                        />
                    </button>
                </label>
                <b-button
                    v-if="false"
                    variant="none"
                    class="market-sidebar-advance w-100"
                >
                    <div class="market-sidebar-advance:text">
                        Show advanced options
                    </div>
                    <div class="market-sidebar-advance:icon">
                        <img
                            src="~@/assets/images/mockup/market/new/prev.png"
                            alt=""
                        />
                    </div>
                </b-button>
                <div class="market-sidebar:title">Item in game available</div>
                <div v-for="game in PlatformList" :key="`${game.id}-key-by-id`">
                    <a
                        v-if="game.name === 'Hero Book'"
                        href="#"
                        class="market-sidebar-game"
                    >
                        <div class="market-sidebar-game:avatar">
                            <img :src="game.image" alt="" />
                        </div>
                        <div class="market-sidebar-game:name">
                            {{ game.name }}
                        </div>
                    </a>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<style lang="scss">
.market-public {
    margin-top: 70px;
    a {
        text-decoration: none;
    }

    .item-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        background: rgba(53, 63, 72, 0.4);
        padding: 5px 15px;

        color: #fcfcfc;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        &-name {
            flex: 1 1 auto;
            max-width: 275px;
            min-width: 275px;
            @media (max-width: 767px) {
                min-width: 175px;
                max-width: 180px;
            }
        }
        &-quantity {
            flex: 0 0 100px;
            text-align: center;
            @media (max-width: 575px) {
                display: none;
            }
        }
        &-price {
            text-align: center;
            flex: 1 1 min(200px, 500px);
        }
        &-newly {
            text-align: center;
            flex: 0 0 110px;
            @media (max-width: 480px) {
                display: none;
            }
        }
    }
    .market-sidebar {
        min-height: 400px;
        width: 100%;
        background: rgba(53, 63, 72, 0.2);
        padding: 45px 20px;
        &\:title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #dcdcdc;
            padding-left: 15px;
            margin-bottom: 15px;
        }
        &-game {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            background: rgba(53, 63, 72, 0.3);
            border-radius: 4px;
            margin-bottom: 5px;
            &\:avatar {
                width: 50px;
                height: 50px;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #353f48;
                border: 0.5px solid #dcdcdc;
                border-radius: 100px;
                padding: 5px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
            &\:name {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                color: #f0f0f0;
                flex: 1 1 auto;
                margin-left: 50px;
            }
        }
        &-advance {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px;

            margin-bottom: 50px;
            outline: none !important;
            box-shadow: none !important;
            background: transparent !important;
            border: 0;
            border-bottom: 0.5px solid #dcdcdc;
            border-radius: 0;
            &\:text {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #888888;
            }
            &\:icon {
                transform: rotate(-90deg);
                height: 18px;
                width: 20px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
        }
        &-search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px;

            margin-bottom: 50px;
            outline: none !important;
            box-shadow: none !important;
            background: transparent !important;
            border: 0;
            border-bottom: 0.5px solid #dcdcdc;
            border-radius: 0;
            &\:input {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #888888;
                background: transparent;
                outline: none !important;
                box-shadow: none !important;
                background: transparent !important;
                border: 0;
                border-radius: 0;
                &:not(:placeholder-shown),
                &:focus {
                    color: #fcfcfc;
                }
            }
            &\:icon {
                transform: rotate(-90deg);
                height: 18px;
                width: 20px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
        }
        .market-sidebar-title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #f8f8f8;
            span {
                color: #00ffff;
            }
        }
        @media (max-width: 991px) {
            display: none;
        }
    }
}
</style>
