const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        info: {
            ClaimDate: '2022-05-17T00:00:00.000Z',
            MaxPresale: 0,
            Price: 0,
            Requirement: ' Whitelisted Wallet',
            TimeEnd: '2022-04-17T00:00:00.000Z',
            TimeStart: '2022-03-17T00:00:00.000Z',
            Vesting: 'Released at the time of TGE',
            TotalBought: 0,
        },
        list: [],
        statistical: {
            TotalBought: 0,
            TotalLocking: 0,
            TotalRemaining: 0,
            TotalUnlocked: 0,
        },
    }),
    getters: {
        Info: (state) => state.info,
        List: (state) => state.list,
        Statistical: (state) => state.statistical,
    },
    actions: {
        req_getInfoPreSales: ({ commit }) => {
            axios.get('api/presale/info').then((response) => {
                commit('SET_PRE_SALE_INFO', response);
            });
        },
        req_getBuyHistory: ({ commit }) => {
            axios.post('api/presale/unlock-schedule').then((response) => {
                commit('SET_PRE_SALE_USER', response);
            });
        },
        req_postBuyToken: ({ dispatch }, input) => {
            axios.post('api/presale/buy-token', input).then(() => {
                dispatch('req_getBuyHistory');
                dispatch('req_getInfoPreSales');
            });
        },
        req_postClaimToken: ({ dispatch }, input) => {
            axios.post('api/presale/claim', input).then(() => {
                dispatch('req_getBuyHistory');
                dispatch('req_getInfoPreSales');
            });
        },
    },
    mutations: {
        SET_PRE_SALE_INFO(state, data) {
            state.info = data;
        },
        SET_PRE_SALE_USER(state, data) {
            const {
                TotalBought,
                TotalLocking,
                TotalRemaining,
                TotalUnlocked,
                UnlockSchedule,
            } = data;
            state.list = UnlockSchedule;
            state.statistical = {
                TotalBought,
                TotalLocking,
                TotalRemaining,
                TotalUnlocked,
            };
        },
    },
};
