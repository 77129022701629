<template>
    <div class="coming-soon">
        <div class="page-name">
            <div class="before">COMING SOON</div>
            <div class="after">COMING SOON</div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.coming-soon {
    min-height: calc(100vh - 200px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .page-name {
        position: relative;
        width: 100%;
        text-align: center;
        .before {
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            font-family: 'HHBI';

            line-height: 120%;
            text-transform: uppercase;

            -webkit-text-stroke-color: #4effff;
            opacity: 0.6;
            font-size: clamp(70px, 8.5vw, 150px);
        }

        .after {
            font-family: 'HHBI';
            font-size: clamp(40px, 5vw, 80px);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            text-transform: uppercase;
            color: #fff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
        @media (max-width: 575px) {
            .before {
                font-size: clamp(55px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .before {
                font-size: clamp(45px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .before {
                font-size: clamp(40px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                bottom: -10px;
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
}
</style>
