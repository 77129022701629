<script>
import { mapGetters, mapMutations } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';
import LineChart from '@/components/shared/LineChart.vue';
import { MetaMask } from '@/components/auth';

const axios = require('@/store/axios.config').default;

export default {
    components: {
        Paginate,
        'Line-Chart': LineChart,
        MetaMask,
    },
    data() {
        return {
            // listHero,

            search: {
                tokenId: null,
                pricefrom: null,
                priceto: null,
                type: 'box',
                page: 1,
                limit: 12,
                sortBy: null,
                typeBox: null,
            },

            show: {
                class: false,
                price: false,
                typeBox: false,
                rarity: false,
                farming: false,
                stat: false,
            },
            filterClass: ['Sniper', 'Tanker', 'Warrior', 'Supporter'],
            filterSkin: ['Origin', 'Chromas', 'Prestige'],
            filterRare: ['Common', 'Rare', 'Epic', 'Legend'],
            filterBox: ['Silver', 'Gold', 'Platinum', 'Diamond'],
            showFilter: true,
            width: 0,
            listBox: [
                {
                    name: 'Silver',
                    img: 'silver',
                },
                {
                    name: 'Gold',
                    img: 'gold',
                },
                {
                    name: 'Platinum',
                    img: 'platinum',
                },
                {
                    name: 'Diamond',
                    img: 'diamond',
                },
            ],
            boxChose: 'Silver',
            showChart: false,
            dataChart: [],
            itemLowest: {},
            approve: false,
            showBuyNow: false,
            DetailBox: {},
        };
    },
    computed: {
        ...mapGetters({
            ListBoxSelling: 'market/ListBoxSelling',
            SortBoxList: 'market/SortBoxList',
            Balance: 'wallet/Balance',
            isLogin: 'auth/loggedIn',
            UserInfo: 'info/UserInfo',
        }),
    },
    watch: {
        'search.sortBy': {
            handler() {
                this.onChangePage(1);
            },
            deep: true,
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        getPercents(current, end) {
            const percent = (current / end) * 100;
            if (percent < 20) {
                return 20;
            }
            if (percent >= 100) {
                return 100;
            }
            return percent;
        },
        onChangePage(pageNext) {
            this.search.page = pageNext;
            this.$store.dispatch('market/req_getListBoxSelling', this.search);
        },
        handleResize() {
            this.width = window.innerWidth;
            if (window.innerWidth <= 1205) {
                this.showFilter = false;
            } else {
                this.showFilter = true;
            }
        },
        chooseBox(typeBox) {
            this.search.typeBox = typeBox;
        },
        onFilter(typeFilter) {
            if (typeFilter === 'reset') {
                this.search.pricefrom = null;
                this.search.priceto = null;
                this.search.typeBox = null;
                this.search.tokenId = null;
                this.$store.dispatch(
                    'market/req_getListBoxSelling',
                    this.search,
                );
                return;
            }
            if (this.search.tokenId && !Number.isInteger(this.search.tokenId)) {
                if (
                    this.isNaN(parseFloat(this.search.tokenId)) &&
                    this.search.tokenId.includes('#')
                ) {
                    this.search.tokenId = this.search.tokenId.replace('#', '');
                }
                this.search.tokenId = parseInt(this.search.tokenId, 10);
            }

            this.$store.dispatch('market/req_getListBoxSelling', this.search);
        },
        async onGetPriceBox(name) {
            this.boxChose = name;
            // this.showChart = false;
            const dataChart = await axios.get(
                '/api/market/order/box/quick/chart',
                {
                    params: {
                        typeBox: name,
                    },
                },
            );
            const lowPrice = await axios.get(
                '/api/market/order/box/quick/list',
                {
                    params: {
                        typeBox: name,
                        address: this.isLogin ? this.UserInfo.address : null,
                    },
                },
            );
            const { results } = lowPrice;
            if (results.length >= 1 && results[0]) {
                // eslint-disable-next-line prefer-destructuring
                this.itemLowest = results[0];
            } else {
                this.itemLowest = {};
            }
            this.dataChart = dataChart;
            this.showChart = true;
        },
        async checkApproval() {
            const { hbg } = this.$store.state.contract.bep_20;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            this.onLoad();
            if (addr && marketNew.addr && hbg.cont.methods) {
                const hbgApproval =
                    (await hbg.cont.methods
                        .allowance(addr, marketNew.addr)
                        .call()) /
                    10 ** 18;
                console.log(this.TruncateToDecimals2(hbgApproval));
                this.approve = hbgApproval >= 1000000000;
                this.outLoad();
            } else {
                await this.sleep(2000);
                this.checkApproval();
            }
        },
        async Approve(tokenId) {
            const { hbg } = this.$store.state.contract.bep_20;
            const { web3 } = this.$store.state.contract;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            this.onLoad();
            const txObj = hbg.cont.methods
                .approve(
                    marketNew.addr,
                    (1000000000 * 10 ** 18).toLocaleString('fullwide', {
                        useGrouping: false,
                    }),
                )
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                // Calculate gas
                gas = await hbg.cont.methods
                    .approve(
                        marketNew.addr,
                        (1000000000 * 10 ** 18).toLocaleString('fullwide', {
                            useGrouping: false,
                        }),
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;
            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hbg.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        if (txData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.$toastr.s(
                                'Approved Successfully',
                                'Successfully',
                            );
                            this.onPickHero('approve', tokenId);
                            this.checkApproval();
                            this.outLoad();
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                            this.outLoad();
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        async onBuyBox(tokenCheck) {
            this.onLoad();
            await this.getDetailBox(tokenCheck);
            const { tokenId, price, item } = this.DetailBox;
            const { version } = item;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const decimalPrice = web3.provider.utils.toWei(
                price.toString(),
                'ether',
            );
            const txObj = await marketNew.cont.methods
                .buy(tokenId, version, decimalPrice)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();

            try {
                gas = await marketNew.cont.methods
                    .buy(tokenId, version, decimalPrice)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;

                            this.sendTransaction('buy', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                            this.outLoad();
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
            return false;
        },
        async getDetailBox(tokenId) {
            const detail = await axios.get(
                `api/market/dashboard/detail/box/${tokenId}`,
            );
            if (
                !detail ||
                detail.buyer !== null ||
                detail.isCancel ||
                detail.box == null
            ) {
                if (!detail || detail.isCancel) {
                    this.$toastr.e('Box has been bought or sold', 'Oops!');
                }
                this.onGetPriceBox(this.boxChose);
                this.$bvModal.hide('confirm-buy');
                this.$bvModal.hide('confirm-cancel');
                this.$store.dispatch(
                    'market/req_getListBoxSelling',
                    this.search,
                );
                this.outLoad();
            }
            this.DetailBox = detail;
        },

        async onCancelHero(tokenCheck) {
            this.onLoad();
            await this.getDetailBox(tokenCheck);
            const { tokenId, item } = this.DetailBox;
            const { version } = item;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await marketNew.cont.methods
                .cancelSale(tokenId, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await marketNew.cont.methods
                    .cancelSale(tokenId, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancel', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        onPickHero(type, tokenId) {
            this.getDetailBox(tokenId);
            if (type === 'buy' || type === 'approve') {
                this.$bvModal.show('confirm-buy');
            }
            if (type === 'cancel') {
                this.$bvModal.show('confirm-cancel');
            }
        },
        async sendTransaction(type, txData) {
            if (type === 'buy') {
                const hero = await axios.post(
                    'api/market/order/box/buy/send-transaction',
                    {
                        transactionHash: txData.transactionHash,
                    },
                );
                this.outLoad();
                this.receivedHero = hero;
                this.$store.commit('wallet/updateWallet');
                this.$bvModal.hide('confirm-buy');
                this.$bvModal.show('modal-received');
            }
            if (type === 'cancel') {
                const cancel = await axios.post(
                    'api/market/order/box/buy/send-transaction',
                    {
                        transactionHash: txData.transactionHash,
                    },
                );
                this.outLoad();
                console.log(cancel);
                this.$bvModal.hide('confirm-cancel');
                this.$bvModal.show('modal-received');
            }
        },
    },
    mounted() {
        this.handleResize();
        this.checkApproval();
    },
    async created() {
        window.addEventListener('resize', this.handleResize);
        if (window.innerWidth <= 1205) {
            this.showFilter = false;
        } else {
            this.showFilter = true;
        }

        this.$store.dispatch('market/req_getListBoxSelling', this.search);
        this.$store.dispatch('market/req_getListSortBox');
        this.onGetPriceBox('Silver');
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<template>
    <div class="box-market">
        <div
            class="filter-active"
            v-if="!showFilter"
            @click="showFilter = !showFilter"
        >
            <div class="icons">
                <!-- <i delected class="far fa-caret-square-down"></i> -->
            </div>
            <div class="name">Filter</div>
        </div>
        <b-container class="content-market">
            <transition
                name="slide"
                enter-active-class="animate__animated animate__slideInLeft"
                leave-active-class="animate__animated animate__slideOutLeft"
            >
                <div class="left-filter" v-if="showFilter">
                    <div class="title">
                        Filter
                        <div
                            class="close"
                            v-if="showFilter"
                            @click="showFilter = !showFilter"
                        >
                            <!-- <i delected class="fas fa-times"></i> -->
                        </div>
                    </div>
                    <b-form-group class="search-id">
                        <b-form-input
                            v-model="search.tokenId"
                            placeholder="Search ID"
                        ></b-form-input>
                        <b-button @click="onFilter">
                            <img
                                src="~@/assets/images/icons/search-2.png"
                                alt=""
                            />
                        </b-button>
                    </b-form-group>
                    <div class="collapse-section">
                        <b-button
                            v-b-toggle.collapse-price
                            variant="none"
                            class="button-collapse-search"
                        >
                            <div class="text">Price</div>
                            <div class="icons">
                                <img
                                    src="~@/assets/images/icons/drop-2.png"
                                    :style="`transform: rotate(${
                                        show.price ? '180deg' : '0'
                                    })`"
                                    alt=""
                                />
                            </div>
                        </b-button>
                        <b-collapse
                            id="collapse-price"
                            v-model="show.price"
                            class="mt-2"
                        >
                            <div class="price-filter">
                                <div class="left-price">
                                    <span> From </span>
                                    <b-form-input
                                        v-model="search.pricefrom"
                                    ></b-form-input>
                                </div>
                                <div class="right-price">
                                    <span> To </span>
                                    <b-form-input
                                        v-model="search.priceto"
                                    ></b-form-input>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    <div class="collapse-section">
                        <b-button
                            v-b-toggle.collapse-farming
                            variant="none"
                            class="button-collapse-search"
                        >
                            <div class="text">Box Type</div>
                            <div class="icons">
                                <img
                                    src="~@/assets/images/icons/drop-2.png"
                                    :style="`transform: rotate(${
                                        show.farming ? '180deg' : '0'
                                    })`"
                                    alt=""
                                />
                            </div>
                        </b-button>
                        <b-collapse
                            id="collapse-farming"
                            v-model="show.typeBox"
                            class="mt-2"
                        >
                            <div class="class-filter">
                                <div
                                    class="item"
                                    v-for="cls in filterBox"
                                    :key="`class-filter-${cls}`"
                                    @click="chooseBox(cls)"
                                    :class="{ active: search.typeBox == cls }"
                                >
                                    <div class="icon">
                                        <img
                                            :src="
                                                require(`@/assets/images/mockup/shop/${cls.toLowerCase()}.png`)
                                            "
                                            alt=""
                                        />
                                    </div>
                                    <div class="text">
                                        {{ cls }}
                                    </div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    <div class="group-btn d-flex">
                        <b-button
                            variant="none"
                            class="button-search"
                            @click="onFilter('filter')"
                        >
                            Find Box
                        </b-button>
                        <b-button
                            variant="none"
                            class="button-reset"
                            @click="onFilter('reset')"
                        >
                            Reset Filter
                        </b-button>
                    </div>
                </div>
            </transition>
            <!-- CONTENT -->
            <div class="right-box">
                <b-col cols="12">
                    <div class="sort-box">
                        <b-button
                            class="btn-box-buy-now mr-3 ml-auto"
                            @click="showBuyNow = true"
                        >
                            Buy Now
                        </b-button>
                        <b-form-select
                            v-model="search.sortBy"
                            :options="SortBoxList"
                        ></b-form-select>
                        <img src="~@/assets/images/icons/drop.png" alt="" />
                    </div>
                </b-col>

                <b-col
                    cols="12"
                    class="box-name-2 mt-5 pt-5"
                    v-if="ListBoxSelling.results.length <= 0"
                >
                    <div class="after">No Selling</div>
                </b-col>
                <div class="box-list-market" id="box-list-market" v-else>
                    <b-row>
                        <b-col
                            cols="12"
                            xl="4"
                            md="4"
                            lg="3"
                            sm="6"
                            class="col-xs-6"
                            v-for="(Box, idx) in ListBoxSelling.results"
                            :key="`list-box-inventory-${Box._id}${idx}`"
                        >
                            <div class="text-decoration-none">
                                <div class="box">
                                    <div class="box-id">#{{ Box.tokenId }}</div>
                                    <div class="box-img">
                                        <img
                                            :src="
                                                require(`@/assets/images/mockup/account/${Box.item.type.Name.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        class="box-name"
                                        :class="
                                            Box.item.type.Name.toLowerCase()
                                        "
                                    >
                                        {{ Box.item.type.Name }}
                                    </div>
                                    <div class="box-price">
                                        <div class="left">Price:</div>
                                        <div class="right">
                                            <div class="hbg">
                                                ~
                                                {{
                                                    TruncateToDecimals2(
                                                        Box.price *
                                                            Balance.price,
                                                        '',
                                                        4,
                                                    )
                                                }}
                                                HBG
                                            </div>
                                            <div class="usd">
                                                {{
                                                    TruncateToDecimals2(
                                                        Box.price,
                                                        '',
                                                        2,
                                                    )
                                                }}
                                                USD
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action">
                                        <MetaMask
                                            :isBuy="true"
                                            v-if="!isLogin"
                                        />

                                        <b-button
                                            class="cancel"
                                            @click="
                                                onPickHero(
                                                    'cancel',
                                                    Box.tokenId,
                                                )
                                            "
                                            v-else-if="
                                                Box.ownerAddress.toLowerCase() ===
                                                UserInfo.address.toLowerCase()
                                            "
                                        >
                                            Cancel
                                        </b-button>

                                        <b-button
                                            class="buy"
                                            @click="Approve(Box.tokenId)"
                                            v-else-if="!approve"
                                        >
                                            Approve
                                        </b-button>

                                        <b-button
                                            class="buy"
                                            @click="
                                                onPickHero('buy', Box.tokenId)
                                            "
                                            v-else-if="
                                                Box.ownerAddress.toLowerCase() !==
                                                UserInfo.address.toLowerCase()
                                            "
                                        >
                                            Buy
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <Paginate
                        @current="onChangePage"
                        :totalPages="ListBoxSelling.totalPages"
                    />
                </div>
            </div>
        </b-container>

        <transition
            name="slide"
            enter-active-class="animate__animated animate__zoomIn"
            leave-active-class="animate__animated animate__zoomOut"
        >
            <div class="box-buy-now" v-if="showBuyNow">
                <div class="box-title">
                    <div class="before">buy now</div>
                    <div class="after">buy now</div>
                </div>
                <div class="left-nav">
                    <div
                        class="item"
                        v-for="box in listBox"
                        :key="`box-fast-${box.name}`"
                        :class="{ active: boxChose == box.name }"
                        @click="onGetPriceBox(box.name)"
                    >
                        <div class="box-img">
                            <img
                                :src="
                                    require(`@/assets/images/mockup/account/${box.img}.png?v=1`)
                                "
                                alt=""
                            />
                        </div>
                        <div class="box-name" :class="box.img">
                            {{ box.name }}
                        </div>
                    </div>
                </div>
                <div class="right-content">
                    <div class="text-white text-center text-platinum">
                        Lowest price at the moment:
                        <span v-if="itemLowest && itemLowest.price">
                            {{ TruncateToDecimals2(itemLowest.price, '', 2) }}
                            HBG
                        </span>
                        <span v-else> No Box Selling </span>
                    </div>
                    <div for="" class="text-white text-center mt-2 mb-1">
                        History Min Sell (Last 7 Days)
                    </div>
                    <div class="chart">
                        <Line-Chart v-if="showChart" :chartData="dataChart" />
                    </div>
                    <div class="action">
                        <template v-if="isLogin">
                            <b-button
                                :disabled="!itemLowest.price"
                                class="buy-now"
                                @click="Approve(itemLowest.tokenId)"
                                v-if="!approve"
                            >
                                Approve
                            </b-button>

                            <b-button
                                :disabled="!itemLowest.price"
                                class="buy-now"
                                @click="onPickHero('buy', itemLowest.tokenId)"
                                v-else
                            >
                                Buy Now
                            </b-button>
                        </template>
                        <b-button
                            class="cancel"
                            @click="
                                () => {
                                    showBuyNow = false;
                                }
                            "
                        >
                            Close
                        </b-button>
                    </div>
                </div>
            </div>
        </transition>

        <div class="backdrop-buy" v-if="showBuyNow"></div>
        <b-modal centered id="confirm-buy" title="Confirm Buy Box" hide-footer>
            <div>
                <p class="mb-0 text-confirm">Do you want to buy this box?</p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    v-if="DetailBox"
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="onBuyBox(DetailBox.tokenId)"
                >
                    Confirm Buy
                </b-button>
            </div>
        </b-modal>

        <b-modal
            id="modal-received"
            title="CONGRATULATION"
            :centered="true"
            footer-class="d-none"
            @hidden="
                () => {
                    this.$store.dispatch(
                        'market/req_getListBoxSelling',
                        this.search,
                    );
                }
            "
        >
            <p class="my-3 text-normal">You have received:</p>
            <div
                class="box"
                v-if="DetailBox && DetailBox.box && DetailBox.box.type"
            >
                <div class="box-id">#{{ DetailBox.tokenId }}</div>
                <div class="box-img">
                    <img
                        :src="
                            require(`@/assets/images/mockup/shop/${DetailBox.box.type.Name.toLowerCase()}.png`)
                        "
                        alt=""
                    />
                </div>
                <div
                    class="box-name"
                    :class="DetailBox.box.type.Name.toLowerCase()"
                >
                    the {{ DetailBox.box.type.Name }} box
                </div>
            </div>
            <b-button
                class="btn-confirm d-block mx-auto text-dark my-3 rounded-0"
                @click="$bvModal.hide('modal-received')"
            >
                Confirm
            </b-button>
        </b-modal>

        <b-modal
            centered
            id="confirm-cancel"
            title="Confirm Buy Box"
            hide-footer
        >
            <div>
                <p class="mb-0 text-confirm">
                    Do you want to cancel sell this box?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="onCancelHero(DetailBox.tokenId)"
                >
                    Cancel Now
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<style lang="scss">
.box-market {
    @media (max-width: 575px) and (min-width: 414px) {
        .col-xs-6 {
            flex: 0 0 50%;
        }
    }
    .content-market {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: 1399px) {
            max-width: calc(100% - 10px);
        }
        .left-filter {
            flex: 0 0 375px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            height: auto;
            padding-bottom: 25px;
            margin-right: 25px;
            .title {
                background: #00ffff;
                height: 60px;
                color: #00172a;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2em;
                font-weight: 600;
                text-transform: uppercase;
            }
            .search-id {
                padding: 0 15px;
                margin-top: 25px;
                position: relative;
                input.form-control {
                    color: #fff;
                    font-weight: 600;
                    letter-spacing: 1px;
                    &::-webkit-input-placeholder {
                        color: #fff;
                        // opacity: 0.6;
                        text-align: left;
                        font-weight: 500;
                        font-size: 16px;
                        letter-spacing: 1px;
                        font-style: italic;
                    }
                    height: 40px;
                    border: 1px solid #00ffff;
                    background: linear-gradient(
                        180deg,
                        #18274a 0%,
                        #001326 100%
                    );
                    border-radius: 0;
                    padding-right: 50px;
                }
                button {
                    background: transparent;
                    width: 40px;
                    height: 40px;
                    padding: 5px;
                    border: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 25px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .collapse-section {
                padding: 0 15px;
                margin-top: 15px;
                .button-collapse-search {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    outline: none;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    .text {
                        height: 40px;
                        width: calc(100% - 40px);
                        border: 1px solid #00ffff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #fff;
                        padding: 0 0.75rem;
                        font-style: italic;
                    }
                    .icons {
                        width: 40px;
                        height: 40px;

                        background: #00ffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .price-filter {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 10px 0 20px;
                .left-price,
                .right-price {
                    display: flex;
                    align-items: center;
                    span {
                        color: #fff;
                        margin-right: 10px;
                        font-size: 1.2em;
                    }
                    input {
                        width: 75px;
                        height: 35px;
                        border-radius: 0;
                        border: 1px solid #00ffff;
                        color: #fff;
                        background: linear-gradient(
                            180deg,
                            #18274a 0%,
                            #001326 100%
                        );
                    }
                }
            }
            .class-filter {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0 20px;
                .item {
                    width: 50%;
                    padding: 0 5%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                    }
                    .text {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1.2em;
                    }
                }
            }
            @media (max-width: 1205px) {
                .title {
                    position: relative;
                }
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                max-width: 375px;
                height: 100%;
                bottom: 0;
                z-index: 1037;
            }
        }
        .right-box {
            flex: 1 1 auto;

            .sort-box {
                display: flex;
                justify-content: flex-end;
                margin: 0 0 15px auto;
                padding-right: 5px;
                position: relative;
                max-width: 475px;
                .btn-box-buy-now {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    color: #002338;
                    min-width: 120px;
                    max-width: 150px;
                    width: max-content;
                    height: 40px;
                    font-weight: 700;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    box-shadow: 0 0 5px 2px #00b1ff;
                    border: 0;
                    @media (max-width: 576px) {
                        font-size: 15px;
                    }
                }
                select {
                    max-width: 275px;
                    height: 40px;
                    background: linear-gradient(
                        to right,
                        #00172a 0%,
                        #00000b 100%
                    );
                    color: #fff;
                    border-radius: 0;
                    border: 1px solid #00ffff;
                    option {
                        background: #00000b;
                    }
                }
                img {
                    height: 15px;
                    width: auto;
                    position: absolute;
                    right: 15px;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }
            }
        }
    }
    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 60px;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
    }
    .filter-active {
        position: fixed;
        left: 0;
        top: 175px;
        z-index: 1030;
        background: #00ffff;
        border-radius: 0;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 20px;
        width: 150px;
        .icons {
            i,
            svg {
                font-size: 16px;
            }
            margin: 0 10px;
        }
        .name {
            color: #000;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
        }
    }
    @media (max-width: 991px) {
        .filter-active {
            top: 90px;
        }
    }
    .close {
        background: #00ffff;
        width: 35px;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        height: 35px;
        justify-content: center;
        align-items: center;
        line-height: 0;
        color: #000;
        opacity: 1;
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        box-shadow: 5px 5px 5px black;
        display: none;
        @media (max-width: 1205px) {
            display: flex;
        }
    }
    .backdrop-buy {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1010;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 5px 0px 15px 5px rgba(0, 0, 0, 0.5);
    }
    .box-buy-now {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: calc(100% - 10px);
        height: max-content;
        max-width: 720px;
        margin: auto;
        z-index: 1030;
        border-radius: 15px;
        background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
        box-shadow: 5px 0px 15px 5px rgba(0, 0, 0, 0.5);
        border: 1px solid #02ffff;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-direction: column;
        padding: 15px;
        @media (max-height: 800px) {
            position: absolute;
            bottom: auto;
            top: 100px;
            // min-height: 500px;
        }
        .box-title {
            width: 100%;
            position: relative;
            text-align: center;
            max-height: 60px;
            .before {
                -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                -webkit-text-stroke-width: 1px;
                font-family: 'HHBI';

                line-height: 120%;
                text-transform: uppercase;

                -webkit-text-stroke-color: #4effff;
                opacity: 0.6;
                font-size: clamp(30px, 5.5vw, 50px);
            }

            .after {
                font-family: 'HHBI';
                font-size: clamp(30px, 3vw, 40px);
                position: absolute;
                bottom: -5px;
                left: 0;
                right: 0;
                margin: auto;
                text-transform: uppercase;
                color: #fff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            }
        }
        .left-nav {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 15px;
            width: 100%;
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 0.7px solid #ffff;
                width: 100%;
                max-width: 100px;
                max-height: 100px;
                margin: auto;
                padding: 5px;
                border-radius: 5px;
                cursor: pointer;
                margin: 5px;

                &.active {
                    border: 2px solid #4effff;
                    margin: 4px;
                }
                img {
                    width: auto;
                    height: 60px;
                    object-fit: scale-down;
                }
                .box-name {
                    font-size: 15px;
                    text-align: center;
                    margin-bottom: 5px;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    &.diamond {
                        color: #ff00ff;
                        text-shadow: 1px 0px 9px #ff00ff;
                    }
                    &.gold {
                        color: #f3ff00;
                        text-shadow: 1px 0px 9px #f3ff00;
                    }
                    &.silver {
                        color: #98d7e0;
                        text-shadow: 1px 0px 9px #98d7e0;
                    }
                    &.platinum {
                        color: #4effff;
                        text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                    }
                    &.herobox {
                        color: #fff;
                        text-shadow: 1px 0px 9px #ff00ff;
                    }
                }
                @media (max-height: 800px) {
                    width: 70px;
                    height: 70px;
                    img {
                        width: 100%;
                        height: auto;
                        max-height: 35px;
                        margin-bottom: 5px;
                    }
                    .box-name {
                        font-size: 13px;
                    }
                }
            }
        }
        .right-content {
            width: 100%;
            margin-top: 10px;
            .text-platinum {
                font-family: 'HHBI';
                font-size: 20px;
                margin: auto;
                text-transform: uppercase;
                color: #fff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                span {
                    color: #f3ff00;
                    text-shadow: 1px 0px 9px #f3ff00;
                }
            }
            .action {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                button {
                    margin: 5px;
                    &.buy-now {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        border-radius: 0;
                        width: auto;
                        padding: 5px 15px;
                        height: 45px;
                        color: #000;
                        font-weight: 600;
                        text-transform: capitalize;
                        min-width: 140px;
                        font-size: 1.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &.cancel {
                        background: linear-gradient(
                            90deg,
                            #762525 0%,
                            #f92323 100%
                        );
                        border-radius: 0;
                        width: auto;
                        padding: 5px 15px;
                        height: 45px;
                        color: #fff;
                        font-weight: 600;
                        text-transform: capitalize;
                        min-width: 140px;
                        font-size: 1.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid;
                        &:hover {
                            color: #fff !important;
                            border: 2px solid #fff !important;
                        }
                    }
                }
            }
        }
    }
}
.box-list-market {
    .box {
        width: 100%;
        min-height: 260px;
        padding: 5px 0 15px;
        margin: 0 auto 25px;
        max-width: 300px;
        background: {
            image: url(~@/assets/images/mockup/account/body-box.png);
            repeat: no-repeat;
            size: 100% 100%;
        }
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                max-height: 155px;
                object-fit: scale-down;
                object-position: bottom;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
        .box-action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 7px;
            &.selling {
                flex-direction: column;
                button {
                    margin: 5px auto;
                }
            }
            button {
                width: auto;
                padding: 5px 20px;
                min-width: 90px;
                min-height: 40px;
                border-radius: 5px;
                border: 0;
                color: #ffff;
                font-weight: 600;
                &.sell {
                    background: {
                        image: url('~@/assets/images/mockup/account/sell.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
                &.transfer {
                    background: {
                        image: url('~@/assets/images/mockup/account/transfer.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
                &.price {
                    background: {
                        image: url('~@/assets/images/mockup/account/price.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
                &.cancel {
                    background: {
                        image: url('~@/assets/images/mockup/account/cancel.png');
                        repeat: no-repeat;
                        size: 100% 100%;
                    }
                }
            }
        }
        .action {
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                margin: 5px;
                &.buy {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    border-radius: 0;
                    width: auto;
                    padding: 5px 15px;
                    height: 45px;
                    color: #000;
                    font-weight: 600;
                    text-transform: capitalize;
                    min-width: 140px;
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &.cancel {
                    background: linear-gradient(
                        90deg,
                        #762525 0%,
                        #f92323 100%
                    );
                    border-radius: 0;
                    width: auto;
                    padding: 5px 15px;
                    height: 45px;
                    color: #fff;
                    font-weight: 600;
                    text-transform: capitalize;
                    min-width: 140px;
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid;
                    &:hover {
                        color: #fff !important;
                        border: 2px solid #fff !important;
                    }
                }
            }
        }
    }
    .box-price {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0 10px;
        .left {
            flex: 0 0 25%;
            color: #ffff;
            font-weight: 600;
        }
        .right {
            flex: 0 0 75%;
            padding-left: 5px;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .hbg {
                font-size: 1em;
                font-weight: 900;
                line-height: 120%;
                font-family: 'Helvetica';
                color: #00ffff;
            }
            .usd {
                font-size: 0.7em;
                font-weight: 800;
                margin-left: 10px;
                font-family: 'Helvetica';
                color: #fff;
            }
        }
    }
}
.text-normal {
    font-family: 'helvetica-neue-regular';
    color: white;
    font-size: 28px;
    text-align: center;
}

.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}

#modal-received {
    .modal-dialog {
        @media (min-width: 768px) {
            max-width: 600px;
        }
    }

    .modal-content {
        background-image: url(~@/assets/images/mockup/market/bg-modal-market-congrat.png);
        background-size: 110% 110%;
        background-position: center center;
    }

    .modal-body {
        background: none;
        font-family: 'hemiheadRg-BoldItalic';
        color: #00ffff;

        .box {
            max-width: 250px;
            width: 100%;
            padding: 5px 0 15px;
            margin: 0 auto 5px;
            .box-id {
                color: #00ffff;
                font-size: 1.2em;
                font-weight: 800;
                margin-bottom: 0px;
                font-family: 'HHBI';
                line-height: 1.2em;
                text-align: center;
            }
            .box-img {
                text-align: center;
                position: relative;
                max-width: 300px;
                margin: 10px auto;
                img {
                    width: 100%;
                    width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 10;
                    max-height: 210px;
                    object-fit: cover;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    background: linear-gradient(
                        0deg,
                        #e2dfd3 0%,
                        #f6cb0a00 100%
                    );
                    z-index: 0;
                    animation: light 2s infinite linear;
                }
                @media (max-width: 767px) {
                    max-width: 250px;
                }
            }
            .box-name {
                font-size: 1.5em;
                text-align: center;
                margin: 10px auto;
                font-family: 'HHBI';
                text-transform: uppercase;
                min-height: unset;
                &.diamond {
                    color: #ff00ff;
                    text-shadow: 1px 0px 9px #ff00ff;
                }
                &.metal {
                    color: #fff;
                    text-shadow: 1px 0px 9px #ffff;
                }
                &.gold {
                    color: #f3ff00;
                    text-shadow: 1px 0px 9px #f3ff00;
                }
                &.silver {
                    color: #98d7e0;
                    text-shadow: 1px 0px 9px #98d7e0;
                }
                &.platinum {
                    color: #4effff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                    @media (max-width: 374px) {
                        font-size: 1.7em;
                    }
                }
                &.herobox {
                    color: #fff;
                    text-shadow: 1px 0px 9px #ff00ff;
                }
            }
        }

        .box-img-modifer {
            width: 250px;
            margin: 0 auto;
            max-height: 250px;
            overflow: hidden;
        }

        .x1 {
            width: 75px;
            height: 50px;
            margin: 15px auto 30px;
            border: 1px solid #00ffff;

            font-size: 43px;
            text-shadow: 0px 0px 10px #00ffff;
            line-height: 50px;
            text-align: center;
        }

        .btn-confirm {
            background-color: #00ffff;
        }
    }
}
</style>
