<!-- eslint-disable vue/no-parsing-error -->

<script>
import { mapGetters } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';
import { Carousel, Slide } from 'vue-carousel';
import { filter } from 'lodash';
import FAQ from '@/components/agency/faq.vue';
// import CountDown from '@/components/shared/CountDown2.vue';

export default {
    components: {
        Paginate,
        Carousel,
        Slide,
        FAQ,
        // CountDown,
    },
    data() {
        return {
            search: {
                search: '',
                page: 1,
                address: '',
            },
            fields: [
                {
                    key: '_id',
                    sortable: false,
                    label: 'Address Wallet',
                    class: 'text-center',
                },
                {
                    key: 'totalBet',
                    label: 'Total Bet (B2E Volume)',
                    sortable: false,
                    class: 'text-center',
                },
            ],
            currentPage: 1,
            totalPages: 10,
            renderComponent: true,
            options2: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ' HBG',
                decimalPlaces: 0,
            },
            delay: 2000,
            pkgChose: {},
        };
    },
    computed: {
        url() {
            return `${window.location.hostname}/register?sponsor=${this.UserInfo.address}`;
        },
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            Member: 'agency/Member',
            AgencyPackage: 'agency/AgencyPackage',
            Statistical: 'agency/Statistical',
            Balance: 'wallet/Balance',
            AgencyPackageUP: 'agency/AgencyPackageUP',
        }),
        statistical() {
            if (this.Statistical && this.Statistical.isBuyAgency) {
                const { myVolume, cB2E, cBuyAgency, total, groupVolume } =
                    this.Statistical;
                const totalCommission = {
                    redirect: cB2E.allConmissionB2E || 0,
                    agency: total.cAll || 0,
                    referral: cBuyAgency.all || 0,
                };
                const weeklyCommission = {
                    redirect: cB2E.currentWeekConmissionB2E || 0,
                    agency: total.cAll || 0,
                    volume: myVolume || 0,
                    group: groupVolume || 0,
                    referral: cBuyAgency.currentWeek || 0,
                };
                // redirect: cB2E,
                // agency: total.cAll,
                // referral: cBuyAgency,
                // totalBet: myVolume,
                return {
                    total: totalCommission,
                    weekly: weeklyCommission,
                };
            }

            return {
                total: {
                    redirect: 0,
                    agency: 0,
                    referral: 0,
                },
                weekly: {
                    redirect: 0,
                    agency: 0,
                    volume: 0,
                },
            };
        },
        getLevelInfo() {
            let infoLevel = {
                timeEnd: '',
                nowLevel: {
                    img: '',
                    personal: 0,
                    team: 0,
                },
                nextLevel: {
                    img: '',
                    personal: 0,
                    team: 0,
                },
            };
            if (this.Statistical) {
                const { agency, myVolume, groupVolume } = this.Statistical;
                const { timeEnd, type } = agency;
                const nextPack = filter(this.AgencyPackageUP, (elm) => {
                    return elm.conditionVolume === type.conditionVolume;
                })[0];

                infoLevel = {
                    timeEnd,
                    nowLevel: {
                        img: type.image,
                        personal: myVolume,
                        team: groupVolume,
                    },
                    nextLevel: {
                        img: nextPack.image,
                        personal: type.conditionVolume,
                        team: type.conditionVolumeGroup,
                        percentTeam:
                            (groupVolume / type.conditionVolumeGroup) * 100,
                        percentPersonal: myVolume / type.conditionVolume,
                    },
                };
            }
            return infoLevel;
        },
    },
    methods: {
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        onSearch(type) {
            if (type === 'reset') {
                this.search = {
                    search: '',
                    page: 1,
                };
            }
            this.$store.dispatch('agency/req_getListMember', this.search);
            this.forceRerender();
            this.search.page = 1;
        },
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('agency/req_getListMember', this.search);
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;
            });
        },
        onOpenListPackage() {
            this.$bvModal.show('listPackage');
            this.showCarousel = false;
            setTimeout(() => {
                this.$refs.listPackage.onResize();
                this.$refs.listPackage.goToPage(0);
            }, 200);
        },
        chosePackage(pkg) {
            this.$bvModal.show('confirm-buy-agency');
            this.pkgChose = pkg;
        },
        buyAgency() {
            this.$bvModal.hide('confirm-buy-agency');
            this.$store.dispatch('agency/req_postBuyAgencyPackage', {
                // eslint-disable-next-line no-underscore-dangle
                _idAgency: this.pkgChose.id ?? this.pkgChose._id,
            });
        },
        renewPackage() {
            this.$store.dispatch('agency/req_postRenewAgencyPackage');
        },
    },
    watch: {},
    mounted() {
        this.$store.dispatch('agency/req_getAgencyPackage');
        this.$store.dispatch('agency/req_getAgencyPackageUP');
        this.$store.dispatch('agency/req_getStatisticalAgency');
        this.onChangePage(1);
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            // eslint-disable-next-line default-case
            switch (mutation.type) {
                case 'agency/BUY_AGENCY_PACKAGE':
                    this.$bvModal.hide('listPackage');
                    this.$bvModal.show('agencyReceive');
                    this.$store.dispatch('info/req_getBalance');
                    this.$store.dispatch('agency/req_getStatisticalAgency');
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
<template>
    <div class="container agency-tab">
        <div class="statistical">
            <div class="title-agency d-flex align-items-center">
                <img
                    src="../../assets/images/icons/icon-title-agency.svg"
                    alt=""
                />
                <h2 class="title-agency-content">
                    Agency Battle To Earn (B2E)
                </h2>
            </div>
            <b-row class="overflow-hidden">
                <div class="new-buy-agency col-12">
                    <div class="agency-bordered d-flex flex-wrap w-100">
                        <div
                            class="col-sm-8 py-2 py-md-0 col-12 d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-center"
                        >
                            <img
                                class="agency-main-logo"
                                src="../../assets/images/logo/logo-hero.svg"
                                alt=""
                            />
                            <div class="buy-agency-title">
                                <h4>
                                    Name:
                                    <span>{{
                                        Statistical.isBuyAgency
                                            ? Statistical.agency.type.name
                                            : 'N/A'
                                    }}</span>
                                </h4>
                                <h4>
                                    IB:
                                    <span>{{
                                        Statistical.isBuyAgency
                                            ? Statistical.agency.type.volume *
                                                  100 +
                                              ' %'
                                            : 'N/A'
                                    }}</span>
                                </h4>
                            </div>
                        </div>
                        <div
                            class="col-sm-4 py-2 py-md-0 col-12 d-flex align-items-center justify-content-center justify-sm-content-end"
                        >
                            <button @click="onOpenListPackage">
                                Buy Agency
                            </button>
                        </div>
                    </div>
                </div>
                <div class="new-agency-commission col-12">
                    <div class="agency-bordered">
                        <div class="d-flex align-items-center">
                            <h3 class="commission-title">
                                Agency Weekly Commission
                            </h3>
                            <img
                                src="../../assets/images/icons/icon-question.svg"
                                alt=""
                            />
                        </div>
                        <div class="agency-commisstion-row row mt-3">
                            <div
                                :class="[
                                    'col-7',
                                    Statistical &&
                                    Statistical.agency &&
                                    Statistical.agency.type
                                        ? 'border-right'
                                        : '',
                                ]"
                            >
                                Personal Volume
                            </div>
                            <div
                                class="col-5 pl-md-5 d-flex align-items-center"
                            >
                                <div
                                    class="pending-content-right"
                                    v-if="
                                        Statistical &&
                                        Statistical.agency &&
                                        Statistical.agency.type
                                    "
                                >
                                    {{
                                        TruncateToDecimals2(
                                            statistical.weekly.volume || 0,
                                            '',
                                            4,
                                        )
                                    }}
                                    <span class="main-text-color">HBG</span>
                                </div>
                            </div>
                        </div>
                        <div class="agency-commisstion-row row">
                            <div
                                :class="[
                                    'col-7',
                                    Statistical &&
                                    Statistical.agency &&
                                    Statistical.agency.type
                                        ? 'border-right'
                                        : '',
                                ]"
                            >
                                Group Volume
                            </div>
                            <div
                                class="col-5 pl-md-5 d-flex align-items-center"
                            >
                                <div
                                    class="pending-content-right"
                                    v-if="
                                        Statistical &&
                                        Statistical.agency &&
                                        Statistical.agency.type
                                    "
                                >
                                    {{
                                        TruncateToDecimals2(
                                            statistical.weekly.group || 0,
                                            '',
                                            4,
                                        )
                                    }}
                                    <span class="main-text-color">USD</span>
                                </div>
                            </div>
                        </div>
                        <div class="agency-commisstion-row row">
                            <div class="col-7 border-right">
                                Redirect Commission Pending
                            </div>
                            <div
                                class="col-5 pl-md-5 d-flex align-items-center"
                            >
                                <span>{{ statistical.weekly.redirect }} </span>
                                <span class="main-text-color">&nbsp HBG</span>
                            </div>
                        </div>
                        <div class="agency-commisstion-row row">
                            <div class="col-7 border-right">
                                Agency Commission Pending
                            </div>
                            <div
                                class="col-5 pl-md-5 d-flex align-items-center"
                            >
                                {{
                                    TruncateToDecimals2(
                                        statistical.weekly.agency || 0,
                                        '',
                                        4,
                                    )
                                }}
                                <span class="main-text-color"
                                    >&nbsp Heronium</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="new-agency-total col-12">
                    <div class="agency-bordered">
                        <h3 class="commission-title">
                            Agency Total Commission
                        </h3>
                        <div class="agency-commisstion-row row mt-3">
                            <div class="col-7 border-right">
                                Total Redirect Commission
                            </div>
                            <div
                                class="col-5 pl-md-5 d-flex align-items-center"
                            >
                                {{
                                    TruncateToDecimals2(
                                        statistical.total.redirect || 0,
                                        '',
                                        4,
                                    )
                                }}
                                <span class="main-text-color">&nbsp HBG</span>
                            </div>
                        </div>
                        <div class="agency-commisstion-row row">
                            <div class="col-7 border-right">
                                Total Agency Commission
                            </div>
                            <div
                                class="col-5 pl-md-5 d-flex align-items-center"
                            >
                                {{
                                    TruncateToDecimals2(
                                        statistical.total.agency || 0,
                                        '',
                                        4,
                                    )
                                }}
                                <span class="main-text-color"
                                    >&nbsp Heronium</span
                                >
                            </div>
                        </div>
                        <div class="agency-commisstion-row row">
                            <div class="col-7 border-right">
                                Total Referral Commission
                            </div>
                            <div
                                class="col-5 pl-md-5 d-flex align-items-center"
                            >
                                {{
                                    TruncateToDecimals2(
                                        statistical.total.referral || 0,
                                        '',
                                        4,
                                    )
                                }}
                                <span class="main-text-color"
                                    >&nbsp Heronium</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </b-row>
        </div>
        <div class="member-list py-3">
            <div
                class="title-agency row align-items-start pt-5 pb-2 pb-0"
            >
                <div class="col-md-3 py-2 d-flex align-items-center">
                    <img
                        src="../../assets/images/icons/icon-title-agency.svg"
                        alt=""
                    />
                    <h2 class="title-agency-content">Member</h2>
                </div>
                <div class="col-md-9 py-2 d-flex justify-content-end">
                    <label
                        for="searchMember"
                        class="d-flex algin-items-center w-100"
                    >
                        <input
                            v-model="search.search"
                            type="text"
                            id="searchMember"
                            placeholder="Enter Wallet Address Here"
                        />
                        <button @click="onSearch(search.search)">
                            <img
                                src="../../assets/images/icons/icon-search.svg"
                                alt=""
                            />
                        </button>
                    </label>
                </div>
            </div>
            <div class="new-listMember">
                <div class="table-responsive">
                    <b-table :items="Member.list" :fields="fields" show-empty>
                        <template #empty>
                            <h4 class="text-center my-4">No Member</h4>
                        </template>
                        <template #cell(_id)="data">
                            {{ data.item._id || data.item.id }}
                        </template>
                        <template #cell(totalBet)="data">
                            {{
                                TruncateToDecimals2(
                                    data.item.totalBet || 0,
                                    '',
                                    4,
                                )
                            }}
                            <span class="main-text-color">Heronium</span>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="table-paginate mt-5">
                <Paginate
                    @current="onChangePage"
                    :totalPages="Member.total"
                    v-if="renderComponent"
                />
            </div>
        </div>
        <b-modal id="listPackage" title="Agency Package" hide-footer size="md">
            <carousel
                ref="listPackage"
                :perPage="4"
                :mouseDrag="true"
                :navigationEnabled="true"
                :paginationEnabled="false"
                :autoplay="true"
                :autoplayTimeout="2000"
                :perPageCustom="[[320, 1]]"
                :centerMode="true"
                :loop="false"
                navigation-next-label
                navigation-prev-label
            >
                <slide v-for="pkg in AgencyPackage" :key="pkg.id">
                    <div class="agency-box-container mt-4">
                        <!-- <div class="d-flex align-items-start"> -->
                        <div class="box-img position-relative">
                            <img :src="pkg.image" alt="" />
                        </div>
                        <!-- </div> -->
                        <div>
                            <div class="box-content">
                                <div class="name diamond mb-0 text-center">
                                    {{ pkg.name }}
                                </div>
                                <div class="buy-section">
                                    <div class="agency-box-price">
                                        <div class="left">Price:</div>
                                        <div class="right pl-0">
                                            <div class="hbg">
                                                ~
                                                {{
                                                    TruncateToDecimals2(
                                                        pkg.price *
                                                            Balance.price,
                                                        '',
                                                        4,
                                                    )
                                                }}
                                                HBG
                                            </div>
                                            <div class="usd">
                                                {{ pkg.price || 0 }} USD
                                            </div>
                                        </div>
                                    </div>
                                    <b-button
                                        :disabled="
                                            pkg.isBuy ||
                                            (Statistical.isBuyAgency &&
                                                Statistical.agency.price >=
                                                    pkg.price)
                                        "
                                        class="btn-buy-agency-box mx-auto d-block"
                                        @click="chosePackage(pkg)"
                                    >
                                        Buy
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </b-modal>
        <b-modal
            id="confirm-buy-agency"
            title="Confirm Buy Agency "
            hide-footer
        >
            <div v-if="pkgChose && pkgChose.price">
                <p class="mb-0 text-confirm">
                    Do you want to use
                    <br />
                    <span class="text-main">
                        ~
                        {{
                            TruncateToDecimals2(
                                pkgChose.price * Balance.price,
                                '',
                                4,
                            )
                        }}
                        HBG
                    </span>
                    to buy
                    <span class="text-main">
                        {{ pkgChose.name }}
                    </span>
                    ?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="buyAgency"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal id="agencyReceive" title="Buy Agency Successfully" hide-footer>
            <div class="box" v-if="pkgChose">
                <div class="box-img">
                    <img
                        :src="pkgChose.image"
                        alt=""
                        class="d-sm-block mx-auto"
                        width="100%"
                        style="max-width: 205px"
                    />
                </div>
                <div class="box-available">
                    <div>You have become an agent and have the rank of</div>
                    <span class="box-name diamond mt-0">
                        {{ pkgChose.name }}</span
                    >
                </div>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-3 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('agencyReceive')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="info-agency"
            title="Frequently Asked Questions"
            hide-footer
        >
            <FAQ></FAQ>
            <b-button
                class="mx-auto mt-5 mb-0 button-confirm"
                variant="primary"
                @click="$bvModal.hide('info-agency')"
            >
                confirm
            </b-button>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
.main-text-color {
    color: #00ffff;
}
.new-buy-agency {
    font-family: 'Poppins';
    margin-top: 50px;
    //agency main logo
    img.agency-main-logo {
        width: 124px;
        height: auto;
        @media (min-width: 768px) {
            margin-right: 43px;
        }
    }
    .buy-agency-title {
        height: 80px;
        width: 100%;
        @media (min-width: 768px) {
            border-left: 1px solid #dcdcdc;
            padding-left: 50px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        h4 {
            font-weight: 600;
            font-size: 16px;
            color: #dcdcdc;
            margin: 0;
            padding: 10px 0;
            span {
                color: #00ffff;
                padding-left: 20px;
            }
            @media (max-width: 480px) {
                text-align: center;
            }
        }
    }
    button {
        background: rgba(0, 255, 255, 0.2);
        border-radius: 54px;
        width: 100%;
        max-width: 185px;
        height: 47px;
        font-weight: 500;
        font-size: 16px;
        color: #00ffff;
        outline: unset !important;
    }
}
.new-agency-commission {
    margin-top: 20px;
    font-family: 'Poppins';
    .commission-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #00ffff;
        margin: 0;
        padding-right: 16px;
    }
    img {
        width: 26px;
        height: auto;
    }
}
.agency-commisstion-row {
    padding: 25px 0 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #dcdcdc;
    .border-right {
        border-right: 1px solid #00ffff !important;
    }
}
.new-agency-total {
    margin-top: 20px;
    font-family: 'Poppins';
    .commission-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #00ffff;
        margin: 0;
        padding-right: 16px;
    }
}
.agency-bordered {
    border: 0.5px solid #00ffff;
    border-radius: 4px;
    padding: 54px 60px;
    position: relative;

    // bottom left square
    &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: #00ffff;
        border-radius: 2px;
        bottom: 0;
        left: 0;
    }
    // top right square
    &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: #00ffff;
        border-radius: 2px;
        top: 0;
        right: 0;
    }
    @media (max-width: 480px) {
        padding: 30px;
    }
}
.member-list {
    label {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.1),
                rgba(0, 0, 0, 0.1)
            ),
            #353f48;
        border-radius: 128px;
        padding: 10px 36px;
        max-width: 470px;
        #searchMember {
            width: 100%;
            outline: unset;
            border: none;
            background: none;
            font-weight: 500;
            font-size: 16px;
            color: #00ffff;
            &::placeholder {
                font-weight: 500;
                font-size: 16px;
                color: #888888;
            }
        }
    }
    button {
        outline: none;
    }
}
.main-text-color {
    color: #00ffff;
}
.new-listMember {
    table {
        thead {
            tr div {
                background: red;
            }
        }
    }
}
</style>

<style lang="scss">
#listPackage {
    .agency-box-container {
        width: 100%;
        max-width: 270px;
        margin: auto;
        // display: flex;
        // flex-wrap: wrap;
        .box-img {
            position: relative;
            width: 100%;
            max-width: 280px;
            display: flex;
            align-items: flex-end;
            img {
                width: 100%;
                z-index: 10;
                position: relative;
                filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
                max-height: 200px;
                object-fit: contain;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
        }

        .box-content {
            background: {
                image: url('~@/assets/images/mockup/shop/content.png');
                size: 100% 100%;
                position: bottom center;
                repeat: no-repeat;
            }
            // background: none;
            margin-top: 5px;
            width: 100%;
            min-height: auto;
            max-width: 280px;
            padding: 15px;
            // padding: 0;
            .name {
                font-size: 2em;
                text-align: left;
                margin-bottom: 15px;
                font-family: 'HHBI';
                text-transform: uppercase;
                &.diamond {
                    color: #ff00ff;
                    text-shadow: 1px 0px 9px #ff00ff;
                }
                &.gold {
                    color: #f3ff00;
                    text-shadow: 1px 0px 9px #f3ff00;
                }
                &.silver {
                    color: #98d7e0;
                    text-shadow: 1px 0px 9px #98d7e0;
                }
                &.platinum {
                    color: #4effff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                    @media (max-width: 374px) {
                        font-size: 1.7em;
                    }
                }
            }
            .remaining-box {
                color: #fff;
                font-family: 'HHBI';
                font-size: 1.2em;
                text-align: center;
                span {
                    color: red;
                }
            }
            .buy-section {
            }
            .agency-box-price {
                margin-bottom: 10px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                color: white;
                .left {
                    // flex: 0 0 25%;
                    // color: #002338;
                    font-weight: 600;
                    margin-right: 10px;
                }
                .right {
                    // flex: 0 0 75%;
                    display: inline-block;
                    padding-left: 10px;
                    color: #00ffff;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .hbg {
                        font-size: 1.4em;
                        font-weight: 900;
                        line-height: 120%;
                        font-family: 'HHBI';
                    }
                    .usd {
                        font-size: 1em;
                        font-weight: 900;
                        margin-left: 10px;
                        font-family: 'HHBI';
                    }
                }
            }

            .detail {
                color: #fff;
                padding: 15px 0;
                text-align: center;
            }
        }
        .btn-modal-info-agency-box {
            background: #00ffff;
            width: 30px;
            height: 30px;
            border: 0;
            border-radius: 50%;
            padding: 0;

            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 10;

            color: black;
            font-weight: bold;
            font-family: 'HHBI';
        }
        .btn-buy-agency-box {
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border: 1px solid #00ffff;
            padding: 5px 30px;
            color: black;
            font-weight: bold;
            &:disabled {
                background: #001326;
                color: #fff;
            }
        }
        @media (max-width: 991px) {
            margin: 25px auto 0;
        }
    }
}
#agencyReceive___BV_modal_outer_ {
    z-index: unset !important;
}
#agencyReceive {
    .box-i,
    .box {
        max-width: 250px;
        width: 100%;
        padding: 5px 0 15px;
        margin: 0 auto 5px;
        .box-id {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
            text-align: center;
        }
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                max-height: 210px;
                object-fit: cover;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            min-height: unset;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
        .box-available {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0px 10px;
            text-align: center;
            flex-direction: column;
            color: #fff;
            font-size: 18px;
            div {
                font-size: 18px;
                font-weight: 500;
            }
            span {
                color: #00ffff;
                font-weight: 600;
            }
        }
    }
}
.agency-tab {
    .title-agency-content {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #f8f8f8;
        margin: 0;
        padding-left: 36px;
        @media (max-width: 480px) {
            padding-left: 5px;
        }
    }
    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 0 0 25px;
        font-weight: 900;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }
    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        max-width: 600px;
        .icons {
            img {
                width: 17px;
                height: auto;
            }
        }
        input {
            outline: none;
            border: 1px solid #00ffff;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
        }
    }
    .table-responsive {
        table {
            th,
            tr,
            td {
                border: 0;
                min-width: 100px;
                &:first-child {
                    min-width: unset;
                    padding: 0;
                    text-align: center;
                    vertical-align: middle;
                }
            }
            th {
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.01em;
                color: #00ffff;
                padding: 24px;
                position: relative;
                text-align: center !important;
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 2px;
                    height: 16px;
                    content: '';
                    margin: auto;
                    background: #00ffff;
                }
                &:first-child,
                &:last-child {
                    &::after {
                        background: transparent;
                    }
                }
            }
            tbody {
                tr,
                td {
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    /* or 150% */
                    vertical-align: middle;
                    letter-spacing: 0.01em;

                    /* GrayScale/Input BG */
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 25px;
                    color: #f0f0f0;
                }
                tr:nth-of-type(odd) {
                    background-color: rgba(0, 255, 255, 0.15);
                    border-radius: 1px;
                    margin: 10px;
                }
                tr {
                    &:hover {
                        // background-color: rgba(0, 255, 255, 0.05);
                        border-radius: 1px;
                        color: #f0f0f0;
                    }
                }
            }
        }
    }
    .table-statistical {
        border: 1px solid #00ffff;
        // max-width: 600px;
        margin: 35px auto;
        .table-item {
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            padding: 0 0.75rem;
            vertical-align: middle;
            color: #fff;
            font-weight: 600;
            display: flex;
            font-family: 'Helvetica';
            justify-content: space-between;

            &:nth-of-type(even) {
                box-shadow: inset 0px 0px 2005px rgba(0, 255, 255, 0.2);
            }
            .left {
                flex: 0 0 calc(100% - 300px);
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            .right {
                flex: 0 0 250px;
                border-left: 1px solid #00ffff;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (min-width: 992px) {
                    justify-content: flex-start;
                    text-align: left;
                    padding-left: 5%;
                }
            }
            @media (min-width: 768px) and (max-width: 991px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
            @media (max-width: 575px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
        }
    }
    .box-statistical {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #00ffff;
        margin-bottom: 15px;
        padding: 10px 10px;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 475px;
        &:after,
        &::before {
            z-index: 0;
            content: '';
            width: 6px;
            height: 6px;
            background: #00ffff;
        }

        &:after {
            position: absolute;
            top: 1px;
            right: 1px;
        }
        &::before {
            position: absolute;
            bottom: 1px;
            left: 1px;
        }
        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;
            img {
                width: 50px;
                height: auto;
            }
        }
        .text {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            .text-title {
                min-height: 40px;
                color: #4effff;
                font-size: 1.4em;
                line-height: 120%;
                font-family: 'HHBI';
                font-weight: 600;
                margin-bottom: 15px;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .text-content {
                height: calc(100% - 50px);
                color: #ffffff;
                font-size: 1.6em;
                line-height: 120%;
                font-family: 'HHBI';
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &.box-statistical-2 {
            max-width: unset;
            .header-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .text-title {
                    margin-bottom: 0;
                }
                margin-bottom: 15px;
            }
            .pending {
                width: 100%;
                &-content {
                    height: 40px;
                    padding: 0 0.75rem;
                    vertical-align: middle;
                    color: #fff;
                    font-weight: 600;
                    display: flex;
                    font-family: 'Helvetica';
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 5px;
                    &:nth-of-type(even) {
                        box-shadow: inset 0px 0px 2005px rgba(22, 22, 22, 0.5);
                    }
                    &:nth-of-type(odd) {
                        box-shadow: inset 0px 0px 2005px rgba(18, 37, 37, 0.2);
                    }
                    &-left {
                        flex: 0 0 calc(100% - 300px);
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                    &-right {
                        flex: 0 0 250px;
                        border-left: 1px solid #00ffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #02ffff;
                        @media (min-width: 992px) {
                            justify-content: flex-start;
                            text-align: left;
                            padding-left: 5%;
                        }
                    }
                    @media (min-width: 768px) and (max-width: 991px) {
                        flex-direction: column;
                        height: auto;
                        padding: 5px 0;
                        &-right,
                        &-left {
                            flex: unset;
                            width: 100%;
                            justify-content: center;
                            border: 0;
                        }
                    }
                    @media (max-width: 575px) {
                        flex-direction: column;
                        height: auto;
                        padding: 5px 0;
                        &-right,
                        &-left {
                            flex: unset;
                            width: 100%;
                            justify-content: center;
                            border: 0;
                        }
                    }
                }
            }
        }
        &.box-statistical-3 {
            max-width: 500px;
            flex-direction: column;
            .header-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .text-title {
                    margin-bottom: 0;
                }
                margin-bottom: 15px;
            }
        }
    }
    .agency-ib {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .left-icon {
            flex: 0 0 40%;
            max-height: 100%;
            padding: 0 5px;
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                max-width: 160px;
                height: auto;
                max-height: 100%;
                object-fit: scale-down;
            }
        }
        .right-info {
            flex: 0 0 60%;
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .name-agency,
            .ib-agency {
                font-size: 16px;
                span {
                    color: #00ffff;
                    font-family: 'HHBI';
                    font-size: 17px;
                }
            }
        }
    }
    .body-level {
        width: 100%;
        min-height: calc(100% - 55px);

        &-title {
            font-size: 16px;
            color: #00ffff;
            font-family: 'HHBI';
        }
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .arrow-level {
                flex: 1 1 auto;
                position: relative;
                height: 25px;
                img {
                    width: 50px;
                    max-height: 25px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    animation: toLeftArrow 4s infinite ease-in;
                }
            }
        }
        &-avatar {
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            border-radius: 50%;
            border: 1px solid #00ffff;
            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }
        &-body {
            width: 100%;
        }
        &-progress {
            margin: 10px 0;
            &-body {
                height: 25px;
                max-width: 500px;
                position: relative;
                margin-top: 5px;
                &-bar {
                    border: 1px solid #00ffff;
                    background: linear-gradient(
                        180deg,
                        #00172a 0%,
                        #00000b 100%
                    );
                    border-radius: 15px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: auto;
                    bottom: 0;
                    z-index: 3;
                    overflow: hidden;
                    &-active {
                        background: linear-gradient(
                            90deg,
                            #00ffa96b 0%,
                            #00b1ff 100%
                        );
                        border-radius: 15px;
                        width: calc(40% - 4px);
                        height: calc(100% - 4px);
                        position: absolute;
                        top: 0;
                        left: 2px;
                        right: auto;
                        bottom: 0;
                        margin: auto 0;
                        z-index: 4;
                        max-width: 100%;
                    }
                    &-text {
                        color: #fff;
                        position: relative;
                        z-index: 5;
                        font-size: 15px;
                        text-align: center;
                        font-family: 'HHBI';
                    }
                }
            }
        }
    }
    .agency-button {
        &-content {
            position: relative;
            z-index: 10;
            font-family: 'HHBI';
            font-weight: 400;
            font-size: 15px;
            line-height: 26px;
            /* or 144% */

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.75px;
            justify-content: center;
            img {
                filter: invert(35%) sepia(131%) saturate(624%)
                    hue-rotate(147deg) brightness(100%) contrast(106%);
            }
        }
        border: 1px solid #00ffff;
        background: transparent;
        box-shadow: unset;
        position: relative;
        width: 170px;
        height: 40px;
        border-radius: 0;
        color: #00ffff;
        margin: auto;
        &:after,
        &::before {
            z-index: 0;
            content: '';
            width: 5px;
            height: 5px;
            background: #00ffff;
        }

        &:after {
            position: absolute;
            top: 1px;
            left: 1px;
        }
        &::before {
            position: absolute;
            bottom: 1px;
            right: 1px;
            -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
        &:hover,
        &:focus,
        &:active {
            color: #000 !important;

            &::before {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transition-timing-function: cubic-bezier(
                    0.52,
                    1.64,
                    0.37,
                    0.66
                );
                transition-timing-function: cubic-bezier(
                    0.52,
                    1.64,
                    0.37,
                    0.66
                );
            }
        }
    }
    @keyframes toLeftArrow {
        0% {
            left: 0;
            opacity: 1;
        }
        100% {
            left: calc(100% - 50px);
            opacity: 0.2;
        }
    }
}
</style>
