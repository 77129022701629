<template>
    <div id="accountGame"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#accountGame {
    padding: 0;
}
</style>
