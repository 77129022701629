<script>
export default {};
</script>
<template>
    <b-container fluid class="p-0 page-shop">
        <div class="shop-body">
            <router-view></router-view>
        </div>
    </b-container>
</template>

<style lang="scss">
.shop-navbar {
    background-image: url(~@/assets/images/frame/frame-ligting-blue.png);
    background-size: 100% 100%;
    min-height: 75px;
    align-items: center;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &-item {
        align-items: center;
        padding: 5px 15px;
        display: flex;
        justify-content: center;
        min-width: 200px;
        height: 100%;
        min-height: 50px;
        font-family: 'HHBI';
        letter-spacing: 1px;
        font-size: 1.5em;
        font-weight: 900;
        color: #fff;
        line-height: 0;
        text-align: center;
        text-decoration: none !important;
        text-transform: uppercase;
        &.active,
        &:hover {
            color: #00ffff;
            background-color: rgba(0, 33, 51, 0.6);
        }
    }
}
</style>
