<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { mapGetters } from 'vuex';
import Banner from '@/components/shared/NewBannerDash.vue';
import ListCurrentGame from '@/components/shared/ListCurrentGame.vue';

import ItemBuy from '@/components/market-second/item-buy.vue';

export default {
    components: { Banner, ListCurrentGame, ItemBuy },
    data() {
        return {
            listing: '2022-05-02 14:00:00',
            settings: {
                dots: false,
                dotsClass: 'slick-dots custom-dot-class',
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 1000000,
            },
            s2Active: 1,
            s3RActive: 1,
            s3LActive: 1,
            page: {
                hero: 1,
                box: 1,
                land: 1,
            },
        };
    },
    computed: {
        ...mapGetters({
            statistic: 'dashboard/ListDashboardStatistic',
            recentlySold: 'dashboard/RecentlySold',
            recentlyListed: 'dashboard/RecentlyListed',
        }),
        total() {
            if (this.s2Active === 1) {
                return this.statistic.lastDay;
            }
            if (this.s2Active === 2) {
                return this.statistic.lastWeek;
            }
            return this.statistic.lastMonth;
        },
        listedLand() {
            const { land } = this.recentlyListed;
            if (land && land.limit) {
                return land;
            }
            return {
                results: [],
            };
        },
        listedHero() {
            const { hero } = this.recentlyListed;
            if (hero && hero.limit) {
                return hero;
            }
            return {
                results: [],
            };
        },
        listedBox() {
            const { box } = this.recentlyListed;
            if (box && box.limit) {
                return box;
            }
            return {
                results: [],
            };
        },
        soldLand() {
            const { land } = this.recentlySold;
            if (land && land.limit) {
                return land;
            }
            return {
                results: [],
            };
        },
        soldHero() {
            const { hero } = this.recentlySold;
            if (hero && hero.limit) {
                return hero;
            }
            return {
                results: [],
            };
        },
        soldBox() {
            const { box } = this.recentlySold;
            if (box && box.limit) {
                return box;
            }
            return {
                results: [],
            };
        },
    },
    methods: {
        onChangePage(page, type) {
            this.page = { ...this.page, [type]: page };
            if (page < 1) {
                this.page = { ...this.page, [type]: 1 };
            }
            if (page >= 10) {
                this.page = { ...this.page, [type]: 10 };
            }
            if (page >= this.recentlySold[type].totalPages) {
                this.page = {
                    ...this.page,
                    [type]: this.recentlySold[type].totalPages,
                };
            }
            this.$store.dispatch('dashboard/req_getRecentlySold', {
                type,
                page: this.page[type],
                limit: 5,
            });
        },
        onReloadList() {
            this.$refs.topOfListItem.scrollIntoView({ behavior: 'smooth' });
            this[`req_get${this.marketTab}List`](this.filter);
        },
    },
    created() {
        this.$store.dispatch('dashboard/req_getDashboardStatistic');

        this.$store.dispatch('dashboard/req_getRecentlySold', {
            type: 'hero',
            limit: 5,
        });
        this.$store.dispatch('dashboard/req_getRecentlySold', {
            type: 'box',
            limit: 5,
        });
        this.$store.dispatch('dashboard/req_getRecentlySold', {
            type: 'land',
            limit: 5,
        });

        this.$store.dispatch('dashboard/req_getRecentlListed', {
            type: 'hero',
            limit: 5,
        });
        this.$store.dispatch('dashboard/req_getRecentlListed', {
            type: 'box',
            limit: 5,
        });
        this.$store.dispatch('dashboard/req_getRecentlListed', {
            type: 'land',
            limit: 5,
        });
    },
};
</script>
<template>
    <b-container class="dashboard-page p-0" fluid>
        <div class="section section-1">
            <Banner />
        </div>
        <div class="container py-4 px-sm-0">
            <ListCurrentGame />
        </div>
        <b-container class="section section-2">
            <div class="box-statistical">
                <div class="statistical-menu">
                    <div
                        class="item-menu"
                        @click="s2Active = 1"
                        :class="{ active: s2Active == 1 }"
                    >
                        <p>Last 24h</p>
                    </div>
                    <div
                        class="item-menu"
                        @click="s2Active = 2"
                        :class="{ active: s2Active == 2 }"
                    >
                        <p>7 days</p>
                    </div>
                    <div
                        class="item-menu"
                        @click="s2Active = 3"
                        :class="{ active: s2Active == 3 }"
                    >
                        <p class="border-none">30 days</p>
                    </div>
                </div>
                <div class="statistical-body">
                    <div class="box box-1">
                        <div class="icon">
                            <img
                                src="~@/assets/images/mockup/dashboard/sale-2.png"
                                alt=""
                            />
                        </div>
                        <div class="text">
                            <div class="title">Total Sale</div>
                            <div class="volume">
                                {{ TruncateToDecimals2(total.totalSale) }}
                            </div>
                        </div>
                    </div>
                    <div class="box box-2">
                        <div class="icon">
                            <img
                                src="~@/assets/images/mockup/dashboard/volume.png"
                                alt=""
                            />
                        </div>
                        <div class="text">
                            <div class="title">Total Volume</div>
                            <div class="volume">{{ total.totalVolume }}</div>
                        </div>
                    </div>
                    <div class="box box-3">
                        <div class="icon">
                            <img
                                src="~@/assets/images/mockup/dashboard/sale.png"
                                alt=""
                            />
                        </div>
                        <div class="text">
                            <div class="title">Hero Sale</div>
                            <div class="volume">{{ total.totalHero }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
        <b-container class="section section-3">
            <b-row>
                <b-col cols="12" lg="9" xl="6" class="mx-auto">
                    <div class="title">Recently listed</div>
                    <div
                        class="body-box"
                        v-if="listedHero && listedHero.results.length > 0"
                    >
                        <ItemBuy
                            @onReloadList="onReloadList"
                            v-for="(hero, indexFor) in listedHero.results"
                            :key="hero.tokenId + hero.timeSell"
                            :class="{ odd: indexFor % 2 == 0 }"
                            :item="hero"
                        />
                        <div class="view-more">
                            <router-link to="/market">
                                <div class="text">View more on marketplace</div>
                            </router-link>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" lg="9" xl="6" class="mx-auto">
                    <div class="title">Recently sold</div>
                    <div
                        class="body-box"
                        v-if="soldHero && soldHero.results.length > 0"
                    >
                        <ItemBuy
                            @onReloadList="onReloadList"
                            v-for="(hero, indexFor) in soldHero.results"
                            :key="hero.tokenId + hero.timeSell"
                            :class="{ odd: indexFor % 2 == 0 }"
                            :item="hero"
                        />
                        <div class="paginate-more">
                            <b-button
                                class="prev"
                                :disabled="
                                    recentlySold.hero.totalPages <= 1 ||
                                    page.hero <= 1
                                "
                                @click="onChangePage(page.hero - 1, 'hero')"
                            >
                                <img
                                    src="~@/assets/images/mockup/dashboard/prev.png"
                                    alt=""
                                />
                            </b-button>
                            <div class="text">
                                Page <span> {{ page.hero }}</span> of
                                <span>{{ recentlySold.hero.totalPages }}</span>
                            </div>
                            <b-button
                                class="next"
                                :disabled="
                                    recentlySold.hero.totalPages <= 1 ||
                                    page.hero >= recentlySold.hero.totalPages
                                "
                                @click="onChangePage(page.hero + 1, 'hero')"
                            >
                                <img
                                    src="~@/assets/images/mockup/dashboard/next.png"
                                    alt=""
                                />
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<style lang="scss">
.dashboard-page {
    // margin-top: -20px;
    .carousel-item-home {
        padding: 0;
        min-height: 590px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background: url('~@/assets/images/mockup/dashboard/bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right center;
        .hero-book {
            align-items: center;
            .title {
                position: relative;
                margin-bottom: 5vh;
                .before {
                    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                    -webkit-text-stroke-width: 1px;
                    font-family: 'HHBI';

                    line-height: 120%;
                    text-transform: uppercase;

                    -webkit-text-stroke-color: #4effff;
                    opacity: 0.6;
                    font-size: clamp(45px, 3vw, 80px);
                }

                .after {
                    font-family: 'HHBI';
                    font-size: clamp(40px, 3vw, 65px);
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-transform: uppercase;
                    color: #fff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
            }
            .subtext {
                color: #00ffff;
                font-family: 'Helvetica';
                font-size: clamp(1.6em, 4vw, 1.9em);
                padding-bottom: 10px;
                position: relative;
                letter-spacing: 0.6px;
                word-break: normal;
                margin: 25px 0;
                font-weight: 700;

                padding-top: 5vh;
                text-shadow: 5px 4px 5px black;
                &::after {
                    content: '';
                    top: 0;
                    left: 0;
                    width: calc(80% + 10px);
                    height: 2px;
                    background: #ffff;
                    position: absolute;
                    font-weight: 600;
                    max-width: 150px;
                }
                &.subtext-2 {
                    font-size: 20px;
                    padding: 15px 0 0;
                    margin: 0;
                    &::after {
                        content: unset;
                    }
                    &:last-child {
                        font-size: 40px;
                        text-shadow: 2px 1px 5px rgba(255, 255, 255, 0.377);
                    }
                }
            }
        }
    }
    .section-2 {
        padding: 50px 0;
        .statistical-menu {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .item-menu {
                height: 50px;
                flex: 1;
                max-width: 150px;
                background: rgba(53, 63, 72, 0.4);
                backdrop-filter: blur(2px);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-weight: 700;
                cursor: pointer;
                &.active {
                    background: rgb(0, 255, 255, 0.15);
                    backdrop-filter: blur(2px);
                    color: #00ffff;
                }
                p {
                    margin: 0;
                    width: 100%;
                    text-align: center;
                    border-right: 1px solid #00ffff;
                }
                p.border-none {
                    border: none;
                }
            }
        }
        .statistical-body {
            margin-top: 1rem;
            background: rgba(53, 63, 72, 0.4);
            min-height: 250px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .box {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 315px;
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0 0 100px;
                    img {
                        width: 70px;
                        height: auto;
                    }
                }
                .text {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex: 1 1 auto;
                    flex-direction: column;
                    .title {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 1em;
                        font-weight: 600;
                    }
                    .volume {
                        color: #00ffff;
                        text-transform: capitalize;
                        font-size: clamp(1.8em, 4vw, 2.5em);
                        font-weight: 900;
                        font-family: 'HHBI';

                        margin-right: 15px;
                    }
                }
            }
            @media (max-width: 767px) {
                flex-wrap: wrap;
                padding: 15px 0;
                .box {
                    min-width: 250px;
                }
            }
        }
    }
    .section-3 {
        padding: 50px 0;
        div.title {
            color: #fff;
            font-weight: 900;
            text-align: left;
            margin: 25px 0 10px;
            font-size: 2em;
            text-transform: uppercase;
            text-align: center;
            font-family: 'HHBI';
        }
        .header-box {
            display: flex;
            align-items: center;
            justify-content: center;
            .header-item {
                height: 50px;
                flex: 1;
                max-width: 150px;
                background: rgba(0, 255, 255, 0.15);
                backdrop-filter: blur(2px);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-weight: 700;
                cursor: pointer;
                background: rgba(53, 63, 72, 0.4);
                backdrop-filter: blur(2px);
                &.active {
                    background: rgba(0, 255, 255, 0.15);
                    backdrop-filter: blur(2px);
                    color: #00ffff;
                }
            }
        }
        .body-box {
            margin-top: 1rem;
            width: 100%;
            padding: 25px 0;
            .no-data {
                position: relative;
                margin: 5vh auto;
                text-align: center;
                .before {
                    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                    -webkit-text-stroke-width: 1px;
                    font-family: 'HHBI';

                    line-height: 120%;
                    text-transform: uppercase;

                    -webkit-text-stroke-color: #4effff;
                    opacity: 0.6;
                    font-size: clamp(35px, 3vw, 50px);
                }

                .after {
                    font-family: 'HHBI';
                    font-size: clamp(25px, 3vw, 40px);
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-transform: uppercase;
                    color: #fff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
            }
        }
    }
    @media (max-width: 767px) {
        .section-2,
        .section-3 {
            max-width: calc(100% - 25px);
        }
    }
    @media (max-width: 575px) {
        .section-3 {
            .body-box {
                background-size: cover;
                background-position: center;
            }
            div.title {
                font-size: clamp(2em, 4vw, 3em);
            }
        }
    }
    .view-more {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        a {
            padding: 5px 15px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-decoration: none;
            .text {
                color: #fff;
                margin-right: 20px;
                font-size: 17px;
                font-weight: 600;
                letter-spacing: 1px;
                font-family: 'HHBI';
                &:hover {
                    color: #00ffff;
                }
            }
        }
    }
    .paginate-more {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 15px 5px;
        button {
            background: transparent;
            border: 0;
            box-shadow: none;
            padding: 0;
        }
        .text {
            color: #fff;
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 1px;
            margin: 0 15px;
            span {
                color: #00ffff;
            }
        }
    }
}
.count-main {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
    margin: auto;
    border-radius: 15px;
    .title-count {
        position: relative;
        margin-bottom: 5vh;

        .before {
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            font-family: 'HHBI';

            line-height: 120%;
            text-transform: uppercase;

            -webkit-text-stroke-color: #4effff;
            opacity: 0.6;
            font-size: clamp(45px, 3vw, 80px);
        }

        .after {
            font-family: 'HHBI';
            font-size: clamp(40px, 3vw, 65px);
            position: absolute;
            bottom: -15px;
            left: 0;
            right: 0;
            text-transform: uppercase;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
    }
    .sub-count {
        color: #ffffff;
        font-family: 'Helvetica';
        font-size: 24px;
        padding: 10px 0 0;
        position: relative;
        letter-spacing: 0.6px;
        word-break: normal;
        margin: 15px auto 60px;
        font-weight: 700;
        text-shadow: 5px 4px 5px black;
        text-transform: capitalize;
        text-align: left;
        &::after {
            content: '';
            top: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #ffff;
            position: absolute;
            font-weight: 600;
            max-width: 150px;
        }
    }
    .count-content {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}
</style>
