<script>
export default {
    props: {
        hero: {
            type: Object,
            default: null,
        },
    },
    methods: {
        onAction() {
            this.$toastr.w('', 'Coming Soon!');
        },
    },
};
</script>

<template>
    <b-container class="p-0">
        <div class="hero-only" :key="`list-hero-inventory-${hero.id}-only`">
            <div class="left-hero">
                <div class="top-hero">
                    <div class="id-hero" ref="idHero">#{{ hero.tokenId }}</div>
                    <div class="hero-img">
                        <div class="avatar-hero">
                            <img
                                v-if="hero.name"
                                :src="
                                    getImageHero(
                                        '3d',
                                        `${hero.name.toLowerCase()}-${hero.skin.toLowerCase()}`,
                                        hero.version,
                                    )
                                "
                                alt=""
                            />
                        </div>
                        <div class="right-hero">
                            <div class="stat-hero">
                                <div class="hero-level">
                                    {{
                                        10 > hero.level
                                            ? '0' + hero.level.toString()
                                            : hero.level
                                    }}
                                </div>
                                <img
                                    :key="`inventory-${hero.id}`"
                                    :src="
                                        require(`@/assets/images/mockup/inventory/${hero.ratity.toLowerCase()}.png`)
                                    "
                                    alt=""
                                />
                                <img
                                    :key="`inventory-2-${hero.id}`"
                                    :src="
                                        require(`@/assets/images/mockup/inventory/${hero.skin.toLowerCase()}.png?v=1`)
                                    "
                                    alt=""
                                />
                                <img
                                    :key="`inventory-3-${hero.id}`"
                                    :src="
                                        require(`@/assets/images/mockup/inventory/${hero.type.toLowerCase()}.png?v=1`)
                                    "
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-hero">
                    <div class="hero-name">
                        the
                        <span>
                            {{ hero.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<style lang="scss">
.hero-only {
    flex: 1;
    width: calc(100% - 10px);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .left-hero {
        flex: 1 1 auto;
        .top-hero {
            .id-hero {
                color: #00ffff;
                font-size: 1.2em;
                font-weight: 800;
                margin-bottom: 0px;
                font-family: 'HHBI';
                line-height: 1.2em;
            }
            .hero-img {
                display: flex;
                justify-content: center;
                align-items: flex-start;

                .avatar-hero {
                    flex: 1 1 auto;
                    // border: 1px solid #00ffff;
                    max-width: calc(100% - 50px);
                    img {
                        width: 100%;
                        height: auto;
                        max-height: 150px;
                        object-fit: scale-down;
                    }
                }
                .right-hero {
                    flex: 0 0 20%;
                    max-width: 20%;
                    .stat-hero {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        .hero-level {
                            width: calc(100% - 10px);
                            // height: calc(100% - 5px);
                            margin-bottom: 10px;
                            background: #000b1a;
                            color: #00ffff;
                            border: 1px solid #00ffff;
                            font-size: larger;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            font-weight: 900;
                            letter-spacing: 1px;
                        }
                        img {
                            width: calc(100% - 10px);
                            height: auto;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        .bottom-hero {
            .hero-name {
                font-size: 1em;
                text-align: center;
                margin: 10px auto;
                font-family: 'HHBI';
                text-transform: uppercase;
                color: #00ffff;
                line-height: 130%;
                text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                span {
                    font-size: 1.5em;
                    color: #fff;
                }
            }

            .hero-action {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 50px;
                .btn-choose-hero {
                    background: url('~@/assets/images/frame/hexagon-green.png');
                    background-size: 100% 100%;
                    cursor: pointer;
                    width: 150px;
                    outline: none;
                    height: 50px;
                    display: flex;
                    position: relative;
                    padding: 0;
                    outline: none;
                    font-family: 'HHBI';
                    font-size: 17px;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                }
            }
        }
    }
}
</style>
