var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0 summon-new-page",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"mx-auto mt-3",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('div',{staticClass:"search-member box-input-group"},[_c('b-form-input',{staticClass:"search-fr",attrs:{"placeholder":"Search ID hero"},model:{value:(_vm.filter.tokenId),callback:function ($$v) {_vm.$set(_vm.filter, "tokenId", $$v)},expression:"filter.tokenId"}}),_c('b-button',{on:{"click":_vm.onGetListSummon}},[_c('div',{staticClass:"icons mr-1"},[_c('img',{attrs:{"src":require("@/assets/images/icons/search.png"),"alt":""}})])]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.onGetListSummon('reset')}}},[_c('div',{staticClass:"icons mr-1"},[_c('img',{attrs:{"src":require("@/assets/images/icons/circular-arrow.png"),"alt":""}})])])],1)]),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('div',{staticClass:"summon-button green",on:{"click":function($event){return _vm.$emit('renderComponent', {
                        component: 'genesis-summon',
                        summon: {},
                    })}}},[_c('div',{staticClass:"summon-button-content"},[_vm._v("New Summon")])])])],1),(false)?_c('div',{staticClass:"summon-navbar"},_vm._l((_vm.tabSummon),function(tab){return _c('div',{key:("name-" + (tab.name)),staticClass:"summon-navbar-item",class:{ active: _vm.filter.tab === tab.value },on:{"click":function($event){return _vm.changeTabs(tab.value)}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),0):_vm._e(),_c('b-row',{staticClass:"modify-row"},_vm._l((_vm.summonList.results),function(summon){return _c('b-col',{key:("summon-" + (summon._id)),staticClass:"mt-3 col item-fr",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"arrow-right"},[_c('img',{attrs:{"src":require("@/assets/images/icons/two-arrow-right.png")}})]),_c('div',{staticClass:"arrow-left"},[_c('img',{attrs:{"src":require("@/assets/images/icons/two-arrow-left.png")}})]),_c('div',{staticClass:"summon-item p-3"},[_c('b-row',{staticClass:"align-items-center p-3"},[_c('b-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"id-hero"},[_vm._v(" #"+_vm._s(summon.summon[0].tokenId)+" ")]),_c('div',{staticClass:"img-hero"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getImageHero(
                                        '3d',
                                        ((summon.summon[0].name.toLowerCase()) + "-" + (summon.summon[0].skin.toLowerCase())),
                                        summon.summon[0].version
                                    )}})])]),_c('b-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"rare-and-box d-flex align-items-center justify-content-center flex-column"},[_c('img',{attrs:{"src":require(("@/assets/images/mockup/inventory/" + (summon.summon[1].ratity.toLowerCase()) + ".png")),"width":"32px","height":"auto","alt":""}}),_c('span',[_vm._v(_vm._s(summon.summon[1].ratity))])]),_c('img',{staticClass:"img-fluid d-block my-3 mx-auto",attrs:{"src":require("@/assets/images/mockup/summon/x-letter.png")}}),_c('div',{staticClass:"rare-and-box d-flex align-items-center justify-content-center flex-column"},[_c('img',{attrs:{"src":require("@/assets/images/mockup/account/herobox.png?v=1"),"width":"35px"}}),_c('span',[_vm._v("Hero Box")])])]),_c('b-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"id-hero"},[_vm._v(" #"+_vm._s(summon.summon[1].tokenId)+" ")]),_c('div',{staticClass:"img-hero"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getImageHero(
                                        '3d',
                                        ((summon.summon[1].name.toLowerCase()) + "-" + (summon.summon[1].skin.toLowerCase())),
                                        summon.summon[1].version
                                    )}})])])],1),_c('b-row',[_c('b-col',{staticClass:"pr-0 pl-2 d-flex align-items-center justify-content-center",attrs:{"cols":"5"}},[_c('p',{staticClass:"status"},[_vm._v(" Status: "),(summon.status == false)?_c('span',[_vm._v("  undone")]):_c('span',[_vm._v("  done")])])]),_c('b-col',{staticClass:"px-0 d-flex align-items-center justify-content-center",attrs:{"cols":"2"}},[_c('div',{staticClass:"little-dot"})]),_c('b-col',{staticClass:"px-0",attrs:{"cols":"5"}},[(
                                new Date().getTime() -
                                    new Date(summon.timeOpen).getTime() >
                                0
                            )?_c('div',{staticClass:"btn-claim-box mx-auto text-center",on:{"click":function($event){return _vm.onClaimSummon(
                                    summon.id ? summon.id : summon._id
                                )}}},[_vm._v(" CLAIM BOX ")]):_c('div',{staticClass:"btn-claim-box mx-auto text-center",on:{"click":function($event){return _vm.$emit('renderComponent', {
                                    component: 'genesis-summon-process',
                                    summon: summon,
                                })}}},[_vm._v(" Detail ")])])],1)],1)])}),1),_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_c('Paginate',{attrs:{"totalPages":_vm.summonList.totalPages},on:{"current":_vm.onChangePage}})],1),_c('b-modal',{attrs:{"id":"promotion-summon-claimed","title":"Successfully","hide-footer":""}},[(_vm.dataSummon && _vm.dataSummon.firstSummon)?_c('div',[_c('p',{staticClass:"mb-0 text-confirm text-confirm-2"},[_vm._v(" Summon Successful, Your are receive "+_vm._s(_vm.dataSummon.firstSummon.amountHeronium)+" Heronium by promotion first Legend Summon 2.0 ")]),_c('div',{staticClass:"img-box mx-auto mt-3 text-center"},[_c('img',{staticClass:"w-100",staticStyle:{"max-width":"275px"},attrs:{"src":require("@/assets/images/mockup/account/heronium.png")}})])]):_vm._e(),_c('div',{staticClass:"form-group d-flex justify-content-center"},[_c('b-button',{staticClass:"mx-auto mt-5 mb-0 button-confirm",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.hide('promotion-summon-claimed')}}},[_vm._v(" Confirm ")])],1)]),_c('b-modal',{attrs:{"id":"boxReceiverNew","title":"Box Receiver","hide-footer":""}},[(_vm.dataSummon)?_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":require("@/assets/images/mockup/account/herobox.png?v=1"),"alt":""}})]),_c('div',{staticClass:"box-name herobox"},[_vm._v("Hero Box")]),_c('div',{staticClass:"box-available"},[_c('div',[_vm._v("You Have Receive:")]),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.dataSummon.tokenId.length)+" Box ")])])]),_c('div',{staticClass:"box-available flex-column mt-3"},[_c('div',[_vm._v("Box ID:")]),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.dataSummon.tokenId)+" ")])])])]):_vm._e(),_c('div',{staticClass:"form-group d-flex justify-content-center"},[_c('b-button',{staticClass:"mx-auto mt-3 button-confirm",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.hide('boxReceiverNew')}}},[_vm._v(" Confirm ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }