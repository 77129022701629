<template>
    <div class="accordion faq-box" role="tablist">
        <b-card
            no-body
            class="mb-1"
            v-for="(qa, idx) in listFAQ"
            :key="qa.content"
        >
            <b-card-header header-tag="header" role="tab">
                <b-button
                    block
                    v-b-toggle
                    :href="`#FAQ-${idx}`"
                    variant="success"
                    class="text-left fq-title"
                    @click.prevent
                >
                    {{ idx + 1 }}, {{ qa.title }}
                </b-button>
            </b-card-header>
            <b-collapse
                :id="`FAQ-${idx}`"
                visible
                accordion="my-accordion"
                role="tabpanel"
            >
                <b-card-body>
                    <p class="content-title">
                        {{ qa.content }}
                    </p>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            listFAQ: [
                {
                    title: 'What is a Battle to Earn agent system?',
                    content:
                        'B2E dealer system is a system that helps increase profits by receiving commissions for players who refer members to join the game using HBG to bet',
                },
                {
                    title: 'Why is my weekly commission pending',
                    content:
                        'Your weekly commission will be pending for the week and paid out the following week as payments will be paid when you qualify for the agency plan week when the new week begins',
                },
                {
                    title: 'Condition Active',
                    content:
                        'This is your agency activation condition if you have purchased at least one agency package',
                },
                {
                    title: 'Redirect Commission',
                    content:
                        'This is the commission when the user you refer wins the game when using HBG as a bet.',
                },
                {
                    title: 'Agency Commission',
                    content:
                        'This is the commission when the user uses HBG to bet in the game',
                },
                {
                    title: 'Referral Commission',
                    content:
                        'This is the commission you get immediately when the user you refer buys the agency package',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.accordion {
    .card,
    .card * {
        border: 0;
    }
    .card-header {
        padding: 0;
    }
    .card-body {
        background: linear-gradient(0deg, #02ffff -60%, #001326 100%);
        color: #fff;
        font-size: 16px;
    }
}
.fq-title {
    background: cyan;
    color: black;
    text-transform: capitalize;
    font-weight: 800;
    font-size: 18px;
}
.faq-box {
    .content-title {
        font-size: 16px;
        text-align: initial;
        line-height: 120%;
        font-weight: 600;
        margin-bottom: 15px;
        letter-spacing: 1px;
    }
    .content-detail {
        font-size: clamp(14px, 3vw, 16px);
        text-align: initial;
        line-height: 120%;
        font-weight: 600;
        text-align: initial;
        margin-bottom: 10px;
        color: #333;
        span {
            font-weight: 700;
            color: #333;
        }
    }
}
</style>
