<script>
import { mapGetters } from 'vuex';
import { Email, MetaMask } from '@/components/auth';
import { filter } from 'lodash';

const axios = require('@/store/axios.config').default;

export default {
    components: { Email, MetaMask },
    data() {
        return {
            showMenu: false,
            showAccount: true,
            moreMenu: false,
            listTab: [
                { name: 'Account', id: 1, short: 'Wallet', img: 'wallet.png' },
                {
                    name: 'Inventory',
                    id: 2,
                    short: 'Inventory',
                    img: 'inventory.svg',
                },
                {
                    name: 'Activity',
                    id: 3,
                    short: 'Activity',
                    img: 'history.png',
                },
                {
                    name: 'Referral link',
                    id: 4,
                    short: 'Member',
                    img: 'member.png',
                },
                {
                    name: 'Account Setting',
                    id: 5,
                    short: 'Setting',
                    img: 'setting.png',
                },
                {
                    name: 'Agency',
                    id: 6,
                    short: 'Agency',
                    img: 'agency.png',
                },

                {
                    name: 'Burn NFT',
                    id: 7,
                    short: 'Burn',
                    img: 'burn.png',
                },
                {
                    name: 'Upgrade',
                    id: 8,
                    short: 'Upgrade',
                    img: 'upgrade.png',
                },
                {
                    name: 'Vaccinate Lab',
                    id: 9,
                    short: 'Vaccinate',
                    img: 'vacxin.svg',
                },
                {
                    name: 'Swap NFT',
                    id: 10,
                    short: 'Swap',
                    img: 'swap.svg',
                },
                {
                    name: 'Summon 2.0',
                    id: 11,
                    short: 'Summon',
                    img: 'summon.png',
                },
                // {
                //     name: 'Summon 1.0',
                //     id: 12,
                //     short: 'SummonOld',
                //     img: 'summon.png',
                // },
                {
                    name: 'Game Balance',
                    id: 12,
                    short: 'GameBalance',
                    img: 'game-balance.svg',
                },
                {
                    name: 'Support',
                    id: 13,
                    short: 'Support',
                    img: 'support.png',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            isLogin: 'auth/loggedIn',
            Balance: 'wallet/Balance',
            FileGame: 'core/FileGame',
            UserInfo: 'info/UserInfo',
        }),
        getMenu() {
            // listTab
            const self = this;
            return filter(this.listTab, (data) => {
                return !data.short.includes(self.hidden);
            });
        },
    },
    watch: {
        showMenu(newVal) {
            if (newVal) document.documentElement.style.overflow = 'hidden';
            else document.documentElement.style.overflow = 'auto';
        },
        $route(to, from) {
            if (to.name !== from.name) {
                if (window.innerWidth <= 991) {
                    this.showMenu = false;
                }
            }
            if (to.name === 'Account') {
                this.showAccount = false;
            } else {
                this.showAccount = true;
            }
        },
        isLogin: {
            handler() {
                if (this.isLogin) this.checkSummon1();
            },
        },
    },
    mounted() {
        if (this.$route.name === 'Account') {
            this.showAccount = false;
        } else {
            this.showAccount = true;
        }
        if (this.isLogin) this.checkSummon1();
    },
    methods: {
        logout() {
            this.$store.commit('auth/LOGOUT_SUCCESS');
        },
        async checkSummon1() {
            const data = await axios.get(`api/me/summon/quantity/all`, {
                params: {
                    limit: 10,
                    page: 1,
                },
            });
            if (data.results.length <= 0) {
                this.hidden = 'SummonOld';
            } else {
                this.hidden = 'allowance';
            }
        },
    },
};
</script>
<template>
    <header id="top-bar">
        <b-container id="newHeader" fluid class="header-container desktop">
            <div id="menu">
                <div class="nav-logo">
                    <img src="~@/assets/images/logo/logo.png" alt="" />
                </div>
                <div class="more-platform">
                    <b-button @click="moreMenu = !moreMenu">
                        <img src="~@/assets/images/icons/more.png" alt="" />
                    </b-button>
                </div>
                <div
                    class="list-navbar col-xl-6 col-md-5 p-0 d-flex justify-content-between"
                >
                    <router-link :to="{ name: 'Dashboard' }">
                        Dashboard
                    </router-link>
                    <router-link :to="{ name: 'InGame' }">Shop</router-link>
                    <router-link :to="{ name: 'Market' }"> Market </router-link>
                    <router-link :to="{ name: 'Promotion' }">
                        <div class="text">Promotion</div>
                    </router-link>
                </div>

                <div class="nav-user topbar-nv" v-if="isLogin">
                    <div v-if="isLogin" class="header-balance d-flex mr-2">
                        <p class="total-balance">
                            {{ TruncateToDecimals2(Balance.hbg, '', 2) }}
                            <span class="active-children px-2">HBG</span>
                        </p>
                        <a
                            href="https://pancakeswap.finance/swap?outputCurrency=0x8c2Da84EA88151109478846cc7c6c06C481dbe97"
                            target="bank"
                        >
                            <img
                                src="../../assets/images/icons/icon-add.svg"
                                alt=""
                        /></a>
                    </div>
                    <router-link
                        v-if="showAccount"
                        :to="{ name: 'Member' }"
                        class="account"
                    >
                        <div class="header-avatar">
                            <img
                                v-if="UserInfo && UserInfo.Avatar"
                                :key="`avatar-${UserInfo.Avatar}`"
                                :src="UserInfo.Avatar"
                                alt=""
                            />
                            <img
                                class="null-avatar"
                                v-else
                                src="~@/assets/images/icons/user.png"
                                alt=""
                            />
                        </div>
                    </router-link>
                    <div
                        v-else
                        class="account"
                        @click="logout"
                        style="cursor: pointer"
                    >
                        <div class="icons">
                            <img
                                src="~@/assets/images/icons/logout.png"
                                alt=""
                            />
                        </div>
                        <div class="text">Logout</div>
                    </div>
                </div>
                <router-link :to="{ name: 'Login' }" class="login" v-else>
                    <div class="text">Login</div>
                </router-link>
            </div>
        </b-container>
        <b-container class="header-container mobile">
            <div id="nav-mobile">
                <router-link :to="{ name: 'Market' }" class="menu-item">
                    <div class="icons">
                        <img src="~@/assets/images/icons/market.png" alt="" />
                    </div>
                    <div class="text">market</div>
                </router-link>
                <router-link :to="{ name: 'InGame' }" class="menu-item">
                    <div class="icons">
                        <img src="~@/assets/images/icons/shop.png" alt="" />
                    </div>
                    <div class="text">shop</div>
                </router-link>
                <router-link :to="{ name: 'Dashboard' }" class="menu-item">
                    <div class="icons">
                        <img
                            src="~@/assets/images/icons/dashboard.png"
                            alt=""
                        />
                    </div>
                    <div class="text">Dashboard</div>
                </router-link>
                <router-link
                    :to="{ name: 'Member' }"
                    class="menu-item"
                    v-if="isLogin"
                >
                    <div class="icons">
                        <img src="~@/assets/images/icons/user.png" alt="" />
                    </div>
                    <div class="text">Account</div>
                </router-link>
                <router-link :to="{ name: 'Login' }" class="menu-item" v-else>
                    <div class="icons">
                        <img src="~@/assets/images/icons/login2.png" alt="" />
                    </div>
                    <div class="text">Login</div>
                </router-link>
                <div class="toggle-menu-mobile mx-1">
                    <b-button @click="showMenu = !showMenu">
                        <img
                            src="~@/assets/images/icons/menu.png"
                            alt=""
                            v-if="!showMenu"
                        />
                        <img
                            src="~@/assets/images/icons/close.png"
                            alt=""
                            v-if="showMenu"
                        />
                    </b-button>
                </div>
                <transition
                    name="slide"
                    enter-active-class="animate__animated animate__slideInRight"
                    leave-active-class="animate__animated animate__slideOutRight"
                >
                    <div class="sidebar-menu" v-if="showMenu">
                        <div class="break-point break-point-2 mb-1"></div>
                        <div class="nav-logo justify-content-center">
                            <img src="~@/assets/images/logo/logo.png" alt="" />
                        </div>
                        <template v-if="isLogin">
                            <div class="break-section mt-4">
                                <div class="name text-white">User</div>
                            </div>
                            <div class="nav-user" v-if="isLogin">
                                <div class="balances w-100">
                                    <div class="icons">
                                        <img
                                            src="~@/assets/images/logo/HBG.png"
                                            alt=""
                                        />
                                    </div>
                                    <div class="amount">
                                        {{
                                            TruncateToDecimals2(
                                                Balance.hbg,
                                                '',
                                                2,
                                            )
                                        }}
                                    </div>
                                    <a
                                        href="https://pancakeswap.finance/swap?outputCurrency=0x8c2Da84EA88151109478846cc7c6c06C481dbe97"
                                        class="buy-btn"
                                        target="bank"
                                    >
                                        Buy
                                    </a>
                                </div>
                            </div>
                            <div class="list-sidebar-item">
                                <router-link
                                    class="sidebar-item"
                                    v-for="tab in getMenu"
                                    :key="`tabActive-${tab.id}`"
                                    :to="{
                                        name: tab.short,
                                    }"
                                >
                                    <div class="icons">
                                        <img
                                            :src="
                                                require(`@/assets/images/icons/${tab.img}`)
                                            "
                                            alt=""
                                        />
                                    </div>
                                    <div class="text">{{ tab.name }}</div>
                                </router-link>
                                <a
                                    href="javascript:void();"
                                    @click="logout"
                                    class="sidebar-item"
                                >
                                    <div class="icons">
                                        <img
                                            src="~@/assets/images/icons/logout2.png"
                                            alt=""
                                        />
                                    </div>
                                    <div class="text">
                                        <div class="title">Logout</div>
                                    </div>
                                </a>
                            </div>
                        </template>
                        <div class="break-section mt-4">
                            <div class="name">System</div>
                        </div>
                        <div class="list-sidebar-item">
                            <router-link
                                :to="{ name: 'Market' }"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/market.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">Market</div>
                            </router-link>
                            <router-link
                                :to="{ name: 'InGame' }"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/shop.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">shop</div>
                            </router-link>
                            <router-link
                                :to="{ name: 'Dashboard' }"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/dashboard.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">Dashboard</div>
                            </router-link>
                            <router-link
                                :to="{ name: 'PreSale' }"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/sale.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">Vesting</div>
                            </router-link>
                            <router-link
                                :to="{ name: 'Trade' }"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/exchange-funds-fill.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">Energy Exchange</div>
                            </router-link>
                            <router-link
                                :to="{ name: 'Promotion' }"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/promotion.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">Promotion</div>
                            </router-link>
                        </div>
                        <div class="break-section mt-4">
                            <div class="name">More</div>
                        </div>
                        <div class="list-sidebar-item">
                            <a
                                href="https://blog.herobook.io"
                                target="_blank"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/blog.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">
                                    <div class="title">Blog</div>
                                </div>
                            </a>
                            <a
                                href="https://game.herobook.io/"
                                target="_blank"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/game.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">
                                    <div class="title">Game Mini version</div>
                                </div>
                            </a>
                            <a
                                href="https://heroland.app/home"
                                target="_blank"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/mars.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">
                                    <div class="title">Hero Land</div>
                                </div>
                            </a>
                            <a
                                href="https://herobook.io/home"
                                target="_blank"
                                class="sidebar-item"
                            >
                                <div class="icons">
                                    <img
                                        src="~@/assets/images/icons/home.png"
                                        alt=""
                                    />
                                </div>
                                <div class="text">
                                    <div class="title">Home</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </transition>
                <div
                    class="back-drop"
                    v-if="showMenu"
                    @click="showMenu = !showMenu"
                ></div>
            </div>
        </b-container>

        <transition
            name="slide"
            enter-active-class="animate__animated animate__slideInLeft"
            leave-active-class="animate__animated animate__slideOutLeft"
        >
            <div
                class="detail-more"
                v-if="moreMenu"
                @click="moreMenu = !moreMenu"
            >
                <router-link :to="{ name: 'PreSale' }" class="item-more">
                    <div class="icon">
                        <img src="~@/assets/images/icons/sale.png" alt="" />
                    </div>
                    <div class="text">
                        <div class="title">Vesting</div>
                        <div class="description">
                            Hero Book Game Token Fair Launch
                        </div>
                    </div>
                </router-link>
                <a
                    href="https://blog.herobook.io"
                    target="_blank"
                    class="item-more"
                >
                    <div class="icon">
                        <img src="~@/assets/images/icons/blog.png" alt="" />
                    </div>
                    <div class="text">
                        <div class="title">Blog</div>
                        <div class="description">Update news information</div>
                    </div>
                </a>
                <a
                    href="https://game.herobook.io/"
                    target="_blank"
                    class="item-more"
                >
                    <div class="icon">
                        <img src="~@/assets/images/icons/game.png" alt="" />
                    </div>
                    <div class="text">
                        <div class="title">Game Mini version</div>
                        <div class="description">
                            Experience battle with Gory & Foxy in the simple
                            mode. Now Available in Android Devices
                        </div>
                    </div>
                </a>
                <a
                    href="https://heroland.app/home"
                    target="_blank"
                    class="item-more"
                >
                    <div class="icon">
                        <img src="~@/assets/images/icons/mars.png" alt="" />
                    </div>
                    <div class="text">
                        <div class="title">Hero Land</div>
                        <div class="description">Buy Your Land and more</div>
                    </div>
                </a>
                <a
                    href="https://herobook.io/home"
                    target="_blank"
                    class="item-more"
                >
                    <div class="icon">
                        <img src="~@/assets/images/icons/home.png" alt="" />
                    </div>
                    <div class="text">
                        <div class="title">Home</div>
                        <div class="description">
                            Information about the HeroBook
                        </div>
                    </div>
                </a>
                <router-link :to="{ name: 'Trade' }" class="item-more">
                    <div class="icon">
                        <img
                            src="~@/assets/images/icons/exchange-funds-fill.png"
                            alt=""
                        />
                    </div>
                    <div class="text">
                        <div class="title">Energy Exchange</div>
                        <div class="description">Energy trading and more</div>
                    </div>
                </router-link>
            </div>
        </transition>
        <b-modal
            id="wantLogin"
            hide-header
            body-class="p-0"
            hide-footer
            modal-class="customer-open-box"
        >
            <div
                class="d-flex align-items-center justify-content-center flex-column py-4"
            >
                <h3 class="text-white">Please login to download the game</h3>
                <MetaMask class="my-2" />
                <Email class="my-2" />
            </div>
        </b-modal>
    </header>
</template>
<style lang="scss" scoped>
$top-bar-height: 90px;
$top-bar-height-mb: 60px;
$side-bar-width: 320px;
$top-bar-color: linear-gradient(180deg, #00172a 0%, #00000b 100%);
$top-text-color: #ffffff;
$top-text-active: '';
.desktop,
.mobile {
    display: none;
}
@media (min-width: 992px) {
    .desktop {
        display: block;
    }
}
@media (max-width: 991px) {
    .mobile {
        display: block;
    }
}
#top-bar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: $top-bar-height;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        rgba(53, 63, 72, 0.8);
    backdrop-filter: blur(7px);
    @media (max-width: 991px) {
        background: linear-gradient(180deg, #00172ad1 0%, #00000b94 100%);
        box-shadow: 5px 0px 15px 5px rgba(0, 0, 0, 0.1);
    }
    width: 100%;
    z-index: 1030;
    backdrop-filter: blur(19px);
    @media (max-width: 991px) {
        height: $top-bar-height-mb;
    }
    @media (max-width: 575px) {
        position: fixed;
        z-index: 1041;
        bottom: 0;
        top: auto;
    }
    .login {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        height: 45px;
        text-decoration: none;
        cursor: pointer;
        margin: auto 0 auto 15px;
        background: cyan;
        clip-path: polygon(
            25% 0%,
            75% 0%,
            100% 50%,
            75% 100%,
            25% 100%,
            0% 50%
        );

        .icons {
            width: 35px;
            height: 35px;
            margin: auto 5px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .text {
            color: black;
            font-weight: 900;
            font-size: 20px;
            text-transform: capitalize;
        }
        @media screen and (max-width: 767px) {
            display: none !important;
        }
    }
}
.desktop {
    &.header-container {
        padding: 0 2vw;
        @media (max-width: 1399px) {
            max-width: 100%;
        }
        #menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $top-bar-height;
            font-family: 'HHBI';
            .nav-logo {
                width: fit-content;
                max-width: 250px;
                min-width: 120px;
                height: 100%;
                padding: 5px;
                img {
                    height: 100%;
                    width: auto;
                }
            }
            .nav-menu {
                flex-grow: 1;
                flex: auto;
                height: 100%;
                display: flex;
                justify-content: center;
                padding: 0 0vw;
                overflow: hidden;
                .menu-item {
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    flex: 1 1 auto;
                    max-width: 210px;
                    text-decoration: none;
                    cursor: pointer;
                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        min-width: 35px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        font-size: 21px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                    &.active {
                        // clip-path: polygon(25% 0%, 100% 0%, 70% 100%, 0 100%);
                        background: linear-gradient(
                            0deg,
                            #02ffff -60%,
                            #001326 100%
                        );
                        &.dashboard {
                            // min-width: 250px;
                        }
                        .icons {
                            margin-left: -5px;
                        }
                    }
                }
            }
            .nav-user {
                width: fit-content;
                max-width: 550px;
                min-width: 120px;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                .account {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    text-decoration: none;
                    cursor: pointer;
                    margin: auto 0 auto 15px;
                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        font-size: 21px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                }
                .balances {
                    width: 250px;
                    background: url('~@/assets/images/button/balance.png');
                    background-size: 100% 100%;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 0 0 5px;
                    .icons {
                        flex: 0 0 27px;
                        width: 27px;
                        height: 27px;
                        background: black;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .amount {
                        color: #fff;
                        font-size: 17px;
                    }
                    .buy-btn {
                        flex: 0 0 70px;
                        background: url('~@/assets/images/button/buy.png');
                        background-size: 100% 100%;
                        width: 70px;
                        color: black;
                        font-weight: 900;
                        font-size: 20px;
                        padding: 0;
                        height: calc(100% - 5px);
                        outline: none !important;
                        border: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            @media (max-width: 1500px) {
                .nav-user {
                    .account {
                        .icons {
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }
                        .text {
                            font-size: 18px;
                        }
                    }
                    .balances {
                        width: fit-content;

                        .amount {
                            padding: 0 5px;
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 1280px) {
                .nav-menu {
                    .menu-item {
                        flex-direction: column;
                        min-width: 85px;
                        .icons {
                            margin: 0;
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }
                        .text {
                            font-size: 17px;
                        }
                    }
                }
                .nav-user {
                    .account {
                        flex-direction: column;
                        min-width: 85px;
                        .icons {
                            margin: 0;
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }
                        .text {
                            font-size: 17px;
                        }
                    }
                    .balances {
                        width: fit-content;

                        .amount {
                            padding: 0 5px;
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 1200px) {
                .nav-logo {
                    display: flex;
                    align-items: center;
                    min-width: unset;
                    max-width: unset;
                    img {
                        width: 70px;
                        height: auto;
                    }
                }
                .nav-menu {
                    padding: 0 20px;
                    .menu-item {
                        max-width: 175px;
                        .text {
                            font-size: 16px;
                        }
                    }
                }
                .nav-user {
                    width: auto;
                    min-width: unset;
                    .balances {
                        width: auto;
                        .amount {
                            font-size: 15px;
                            padding: 0 7px;
                        }
                        .buy-btn {
                        }
                    }
                    .account {
                        .text {
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 1560px) and (min-width: 992px) {
                .nav-menu {
                    padding: 0 20px 0 5px;
                    .menu-item {
                        // max-width: 175px;
                        flex: 1 1 auto;
                        .icons {
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }
                    }
                }
            }
            @media (max-width: 1200px) and (min-width: 992px) {
                .nav-menu {
                    .menu-item {
                        .text {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
.mobile {
    &.header-container {
        @media (max-width: 1399px) {
            max-width: 100%;
            padding: 0;
        }
        #nav-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $top-bar-height-mb;
            font-family: 'HHBI';
            .nav-logo {
                display: flex;
                align-items: center;
                min-width: unset;
                max-width: unset;
                img {
                    width: 90px;
                    height: auto;
                }
            }
            .login {
                display: flex !important;
                clip-path: unset;
            }
            .menu-item {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: unset;
                flex: 1 1 auto;
                text-decoration: none;
                flex-direction: column;
                cursor: pointer;
                height: 100%;
                .icons {
                    width: 25px;
                    height: 25px;
                    margin: 0 auto 5px !important;
                    min-width: 25px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text {
                    font-size: 11px;
                    color: $top-text-color;
                    text-transform: capitalize;
                }
                &.active {
                    // clip-path: polygon(25% 0%, 100% 0%, 70% 100%, 0 100%);
                    background: linear-gradient(
                        0deg,
                        #02ffff -60%,
                        #001326 100%
                    );
                    &.dashboard {
                        // min-width: 250px;
                    }
                    .icons {
                        margin-left: -5px;
                    }
                }
            }
            .nav-user {
                width: auto;
                min-width: unset;
                max-width: 550px;
                height: auto;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                &.topbar-nv {
                    @media (max-width: 380px) {
                        display: none !important;
                    }
                    @media screen and (max-width: 767px) {
                        .account {
                            display: none !important;
                        }
                    }
                }
                .account {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    text-decoration: none;
                    cursor: pointer;
                    margin: auto 0 auto 15px;
                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text {
                        font-size: 15px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                }
                .balances {
                    width: auto;
                    background: url('~@/assets/images/button/balance.png');
                    background-size: 100% 100%;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 0 0 5px;
                    .icons {
                        flex: 0 0 27px;
                        width: 27px;
                        height: 27px;
                        background: black;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .amount {
                        color: #fff;
                        font-size: 15px;
                        padding: 0 7px;
                    }
                    .buy-btn {
                        flex: 0 0 70px;
                        background: url('~@/assets/images/button/buy.png');
                        background-size: 100% 100%;
                        width: 70px;
                        color: black;
                        font-weight: 900;
                        font-size: 15px;
                        padding: 0;
                        height: calc(100% - 5px);
                        outline: none !important;
                        border: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:focus,
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        .toggle-menu-mobile {
            button {
                background: #06284e;
                height: 45px;
                width: 45px;
                border: 0;
                padding: 0;
                img {
                    height: 30px;
                    width: auto;
                }
            }
        }
    }
    .sidebar-menu {
        position: fixed;
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: $side-bar-width;
        background: #00000b;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 2300;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 10px;
        height: 100%;
        min-height: calc(100vh - 60px);
        @media (max-width: 575px) {
            top: auto;
            bottom: 60px;
            width: 100%;
            height: calc(100vh - 60px);
        }
        .nav-menu {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            .menu-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 50px;
                text-decoration: none;
                padding: 0 15px;
                margin: 5px auto;
                cursor: pointer;
                .icons {
                    width: 30px;
                    height: 30px;
                    margin: auto 10px;
                    min-width: 30px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text {
                    font-size: 17px;
                    color: $top-text-color;
                    text-transform: capitalize;
                }
                &.active {
                    background: url('~@/assets/images/button/active-menu.jpg');
                }
            }
        }
        .break-point {
            background: #00ffffa4;
            height: 3px;
            width: 100%;
            margin: 20px 0;
            &.break-point-2 {
                height: 1px;
            }
        }
        .break-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
                color: #00ffff;
                font-size: 19px !important;
                flex: 0 0 max-content;
            }
            &::before,
            &::after {
                height: 1px;
                display: flex;
                flex: 1 1 auto;
                margin-left: 5px;
                background: #00ffff;
                content: '';
            }
            &::before {
                margin-right: 5px;
            }
        }
        .nav-user {
            width: 100% !important;
            justify-content: flex-start !important;
            flex-direction: column;
            .balances,
            .account {
                width: 100%;
                margin: 10px auto !important;
                &.isLeft {
                    margin: 10px 0 10px 25px !important;
                    width: max-content;
                }
            }
            .account {
                justify-content: flex-start !important;
                padding: 0 15px;
                .icons {
                    margin: 0 10px !important;
                    padding: 3px;
                    img {
                        height: auto !important;
                    }
                }
            }
        }
        @media (max-width: 425px) {
            width: 100%;
        }
        .login-2 {
            margin: 15px auto 0 !important;
            width: 100%;
            max-width: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 120px;
            height: 45px;
            text-decoration: none;
            cursor: pointer;
            margin: auto 0 auto 15px;
            background: cyan;
            clip-path: polygon(
                25% 0%,
                75% 0%,
                100% 50%,
                75% 100%,
                25% 100%,
                0% 50%
            );

            .icons {
                width: 35px;
                height: 35px;
                margin: auto 5px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                color: black;
                font-weight: 900;
                font-size: 20px;
                text-transform: capitalize;
            }
            @media (min-width: 768px) {
                display: none;
            }
        }
        .list-sidebar-item {
            display: flex;
            flex-wrap: wrap;
            max-width: 300px;
            width: 100%;
            margin: 10px auto;

            .sidebar-item {
                flex: 1 1 90px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 5px 2px;
                max-width: 90px;
                margin: 5px;
                min-height: 60px;
                justify-content: space-between;
                &.active {
                    border: 1px solid #00ffff;
                    border-radius: 3px;
                }
                .icons {
                    width: 35px;
                    height: auto;
                    max-height: 35px;
                    img {
                        width: 100%;
                        object-fit: scale-down;
                        height: 100%;
                    }
                }
                .text {
                    font-size: 11px;
                    color: #fff;
                    text-align: center;
                }
            }
        }
    }
    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
        @media (max-width: 575px) {
            position: fixed;
            bottom: 60px;
            top: 0;
            height: auto;
        }
    }
}
.slide-enter,
.slide-leave-to {
    transform: scaleY(0);
}
.more-platform {
    @media (max-width: 576px) {
        &.pc {
            display: none;
        }
    }
    button {
        background-color: transparent;
        border: 1px solid #00ffff;
        padding: 0;
        width: 45px;
        height: 45px;
        margin: 0 15px;
        img {
            width: 100%;
            height: auto;
        }
    }
}
.detail-more {
    position: fixed;
    top: 90px;
    left: 10px;
    width: 100%;
    max-width: 768px;
    min-height: 190px;
    z-index: 1050;
    background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
    border: 1px solid #00ffff;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item-more {
        text-decoration: none !important;
        display: flex;
        align-items: center;
        width: calc((100% / 3) - 10px);
        margin: 5px;
        height: 75px;
        border-radius: 5px;
        border: 1px solid #00ffff57;
        &:hover {
            background: linear-gradient(0deg, #02ffff -60%, #001326 100%);
        }
        .icon {
            flex: 0 0 50px;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            padding: 5px;

            img {
                width: 100%;
                height: auto;
                object-fit: scale-down;
            }
        }
        .text {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 5px;
            text-decoration: none !important;
            .title {
                color: #fff;
                font-family: 'HHBI';
                font-size: 16px;
                font-weight: 600;
            }
            .description {
                text-decoration: none !important;
                font-size: 12px;
                color: #fff;
                line-height: 120%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
    @media (max-width: 991px) {
        top: 65px;
        max-width: calc(100% - 20px);
    }
    @media (max-width: 575px) {
        .item-more {
            width: calc((100% / 2) - 10px);
        }
    }
    @media (max-width: 425px) {
        .item-more {
            width: 100%;
        }
    }
}
#newHeader {
    font-family: 'Poppins';
    font-weight: 500;
    // background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    //     rgba(53, 63, 72, 0.8);
    backdrop-filter: blur(7px);
    height: 90px;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 29;
    // @media (max-width: 1300px) {
    //     height: 69px;
    // }

    .detail-more {
        position: fixed;
        z-index: 1050;
        top: 90px;
        left: 10px;
        width: 100%;
        max-width: 768px;
        min-height: 190px;
        background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
        border: 1px solid #00ffff;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        .item-more {
            text-decoration: none !important;
            display: flex;
            align-items: center;
            width: calc((100% / 3) - 10px);
            margin: 5px;
            height: 75px;
            border-radius: 5px;
            border: 1px solid #00ffff57;
            &:hover {
                background: linear-gradient(0deg, #02ffff -60%, #001326 100%);
            }
            .icon {
                flex: 0 0 50px;
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                padding: 5px;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: scale-down;
                }
            }
            .text {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0 5px;
                text-decoration: none !important;
                .title {
                    color: #fff;
                    font-family: 'HHBI';
                    font-size: 16px;
                    font-weight: 600;
                }
                .description {
                    text-decoration: none !important;
                    font-size: 12px;
                    color: #fff;
                    line-height: 120%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; /* number of lines to show */
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
        @media (max-width: 991px) {
            top: 70px;
            max-width: calc(100% - 20px);
        }
        @media (max-width: 575px) {
            .item-more {
                width: calc((100% / 2) - 10px);
            }
        }
        @media (max-width: 425px) {
            .item-more {
                width: 100%;
            }
        }
    }

    .more-platform {
        @media (max-width: 576px) {
            &.pc {
                display: none;
            }
        }
        button {
            background-color: transparent;
            border: 1px solid #00ffff;
            padding: 0;
            width: 45px;
            height: 45px;
            margin: 0 15px;
            outline: unset !important;
            box-shadow: none !important;
            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .header-logo {
        width: 100%;
        max-width: 130px;
        min-width: 80px;
    }

    .list-navbar {
        a {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #f8f8f8;
            text-decoration: none;
            font-family: 'Poppins';
        }
        @media (max-width: 992) {
            display: none !important;
        }
    }
    .login {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        height: 45px;
        text-decoration: none;
        cursor: pointer;
        margin: auto 0 auto 15px;
        background: cyan;
        clip-path: polygon(
            25% 0%,
            75% 0%,
            100% 50%,
            75% 100%,
            25% 100%,
            0% 50%
        );

        .icons {
            width: 35px;
            height: 35px;
            margin: auto 5px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .text {
            color: black;
            font-weight: 900;
            font-size: 20px;
            text-transform: capitalize;
        }
        @media screen and (max-width: 767px) {
            display: none !important;
        }
    }
    .header-balance {
        font-size: 14px;
        color: #f0f0f0;
        padding: 8px 20px;
        border: 0.5px solid rgba(136, 136, 136, 0.8);
        border-radius: 163px;
        a {
            cursor: pointer;
        }
    }
    .header-control {
        position: relative;
        .ctrl-btn {
            width: 46px;
            height: 46px;
            padding: 0;
            outline: none;
            border: none;
            border-radius: 50%;
            background: none;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .logoutBtn {
            position: absolute;
            z-index: 100;
            left: 10px;
            bottom: -44px;
            background: rgba(0, 255, 255, 0.5);
            padding: 6px 20px;
            color: #fff;
            font-size: 14px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            outline: unset;
            box-shadow: none;
            border: none;
            img {
                width: 18px;
                height: auto;
                margin-left: 10px;
            }
            &:hover {
                background: rgba(0, 255, 255, 0.8);
            }
        }
    }
    .header-avatar {
        width: 46px;
        height: 46px;
        padding: 0;
        outline: none;
        border: none;
        border-radius: 50%;
        background: none;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            transform: scale(1.05);
        }
        .null-avatar {
            width: 80%;
            height: 80%;
            margin: auto;
        }
    }
    .isLogin-nav {
        @media (max-width: 576px) {
            width: 100% !important;
        }
    }
    .total-balance {
        margin: 0;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .menu-btn-view {
        position: relative;
        height: 36px;
        width: 36px;
        .menu-btn {
            outline: unset;
            box-shadow: none;
            img {
                height: 36px;
                width: 36px;
            }
        }
    }
    .list-menu-showed {
        position: fixed;
        z-index: 10000;
        right: 0;
        top: 0;
        width: 100%;
        max-width: 400px;
        height: 100vh;
        // background: rgb(0, 179, 179);
        // backdrop-filter: blur(19px);
        background: #00000b;
        .close-btn {
            color: #00ffff;
        }
        a,
        p {
            color: #000 !important;
            padding: 10px 0;
            margin: 0;
            text-decoration: none;
            font-weight: 400;
        }
        button {
            text-align: left;
            background: none;
            outline: unset;
            box-shadow: none;
            padding: 10px 0;
        }
    }
    .sidebar-menu {
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: $side-bar-width;
        // background: #00000b;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 2300;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 10px;
        // min-height: calc(100vh - 60px);
        height: 100vh;
        @media (max-width: 575px) {
            top: auto;
            bottom: 60px;
            width: 100%;
        }
        .nav-logo {
            display: flex;
            align-items: center;
            min-width: unset;
            max-width: unset;
            img {
                width: 150px;
                height: auto;
            }
        }
        .nav-menu {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            .menu-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 50px;
                text-decoration: none;
                padding: 0 15px;
                margin: 5px auto;
                cursor: pointer;
                .icons {
                    width: 30px;
                    height: 30px;
                    margin: auto 10px;
                    min-width: 30px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text {
                    font-size: 17px;
                    color: $top-text-color;
                    text-transform: capitalize;
                }
                &.active {
                    background: url('~@/assets/images/button/active-menu.jpg');
                }
            }
        }
        .break-point {
            background: #00ffffa4;
            height: 3px;
            width: 100%;
            margin: 20px 0;
            &.break-point-2 {
                height: 1px;
            }
        }
        .break-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
                color: #00ffff;
                font-size: 19px !important;
                flex: 0 0 max-content;
            }
            &::before,
            &::after {
                height: 1px;
                display: flex;
                flex: 1 1 auto;
                margin-left: 5px;
                background: #00ffff;
                content: '';
            }
            &::before {
                margin-right: 5px;
            }
        }
        .nav-user {
            width: 100% !important;
            justify-content: flex-start !important;
            flex-direction: column;
            .balances,
            .account {
                width: 100%;
                margin: 10px auto !important;
                &.isLeft {
                    margin: 10px 0 10px 25px !important;
                    width: max-content;
                }
            }
            .account {
                justify-content: flex-start !important;
                padding: 0 15px;
                .icons {
                    margin: 0 10px !important;
                    padding: 3px;
                    img {
                        height: auto !important;
                    }
                }
            }
            .balances {
                width: auto;
                background: url('~@/assets/images/button/balance.png');
                background-size: 100% 100%;
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 0 0 5px;
                .icons {
                    flex: 0 0 27px;
                    width: 27px;
                    height: 27px;
                    background: black;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .amount {
                    color: #fff;
                    font-size: 15px;
                    padding: 0 7px;
                }
                .buy-btn {
                    flex: 0 0 70px;
                    background: url('~@/assets/images/button/buy.png');
                    background-size: 100% 100%;
                    width: 70px;
                    color: black;
                    font-weight: 900;
                    font-size: 15px;
                    padding: 0;
                    height: calc(100% - 5px);
                    outline: none !important;
                    border: 0;
                    box-shadow: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:focus,
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
        @media (max-width: 425px) {
            width: 100%;
        }
        .login-2 {
            margin: 15px auto 0 !important;
            width: 100%;
            max-width: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 120px;
            height: 45px;
            text-decoration: none;
            cursor: pointer;
            margin: auto 0 auto 15px;
            background: cyan;
            clip-path: polygon(
                25% 0%,
                75% 0%,
                100% 50%,
                75% 100%,
                25% 100%,
                0% 50%
            );

            .icons {
                width: 35px;
                height: 35px;
                margin: auto 5px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                color: black;
                font-weight: 900;
                font-size: 20px;
                text-transform: capitalize;
            }
            @media (min-width: 768px) {
                display: none;
            }
        }
        .list-sidebar-item {
            display: flex;
            flex-wrap: wrap;
            max-width: 300px;
            width: 100%;
            margin: 10px auto;

            .sidebar-item {
                flex: 1 1 90px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 5px 2px;
                max-width: 90px;
                margin: 5px;
                min-height: 60px;
                justify-content: space-between;
                &.active {
                    border: 1px solid #00ffff;
                    border-radius: 3px;
                }
                .icons {
                    width: 35px;
                    height: auto;
                    max-height: 35px;
                    img {
                        width: 100%;
                        object-fit: scale-down;
                        height: 100%;
                    }
                }
                .text {
                    font-size: 11px;
                    color: #fff;
                    text-align: center;
                }
            }
        }
    }
}
</style>
