const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        listBox: [],

        listLand: {
            list: [],
            current: 1,
            total: 1,
        },
        totalHero: 0,
        totalLand: 0,
        listTicket: {
            results: [],
        },
    }),
    getters: {
        ListBox: (state) => state.listBox,
        ListLand: (state) => state.listLand,
        TotalHero: (state) => state.totalHero,
        TotalLand: (state) => state.totalLand,
        ListTicket: (state) => state.listTicket,
    },
    actions: {
        req_getListBox: ({ commit }) => {
            axios.post('api/box/user-box').then((response) => {
                commit('SET_LIST_BOX', response);
            });
        },
        req_getListLand: ({ commit }) => {
            axios.get('api/box/user-box').then((response) => {
                commit('SET_LIST_LAND', response);
            });
        },
        req_heroQuantity: ({ commit }) => {
            axios.get('api/me/my-hero/quantity').then((response) => {
                commit('SET_QUANTITY', response);
            });
            axios.get('api/me/my-land/quantity').then((response) => {
                commit('SET_QUANTITY_LAND', response);
            });
        },
        req_getListTicket: ({ commit }) => {
            axios.get('/api/me/ticket').then((response) => {
                if (response) {
                    commit('SET_USER_TICKET', response);
                }
            });
        },
    },
    mutations: {
        SET_LIST_BOX(state, data) {
            state.listBox = data;
        },

        SET_LIST_LAND(state, data) {
            console.log(data);
        },
        SET_QUANTITY(state, data) {
            state.totalHero = data.quantity || 0;
        },
        SET_QUANTITY_LAND(state, data) {
            state.totalLand = data.quantity || 0;
        },
        SET_USER_TICKET(state, data) {
            state.listTicket = data;
        },
    },
};
