const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        listBox: {
            list: [],
            current: 1,
            total: 1,
        },
    }),
    getters: {
        ListBox: (state) => state.listBox,
    },
    actions: {
        req_postTransferSuccess: ({ commit }, input) => {
            axios.post('api/market/order/transfer/hero', input).then(() => {
                commit('ACTION_SUCCESS');
            });
        },
        req_postSellSuccess: ({ commit }, input) => {
            axios
                .post('api/market/order/box/send-transaction', input)
                .then(() => {
                    commit('ACTION_SUCCESS');
                });
        },
        req_postCancelSuccess: ({ commit }, input) => {
            axios.post('api/market/order/sell/box/cancel', input).then(() => {
                commit('ACTION_SUCCESS');
            });
        },
        req_postCancelOldSuccess: ({ commit }, input) => {
          axios.post('api/market/order/sell/box/cancel/old', input).then(() => {
              commit('ACTION_SUCCESS');
          });
      },
        req_getListBox: ({ commit }, input) => {
            axios.post('api/box/list-user-box', input).then((response) => {
                commit('SET_LIST_HERO', response);
            });
        },
        req_refreshBoxes: () => {
            axios.get('api/me/refresh').then(() => {
                // commit('SET_LIST_HERO', response);
            });
        },
    },
    mutations: {
        SET_LIST_HERO(state, data) {
            const { results, page, totalPages } = data;
            state.listBox = {
                list: results,
                current: page,
                total: totalPages,
            };
        },
        ACTION_SUCCESS() {},
    },
};
