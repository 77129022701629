<script>
import { mapGetters, mapMutations } from 'vuex';
import ListSelling from './box/newListSelling.vue';
import ListOpen from './box/newListOpen.vue';

export default {
    components: { ListOpen, ListSelling },
    computed: {
        ...mapGetters({
            ListBox: 'box/ListBox',
            Balance: 'wallet/Balance',
        }),
    },
    data() {
        return {
            componentActive: 'ListOpen',
            steps: [
                {
                    target: '[data-v-step="1"]', // We're using document.querySelector() under the hood
                    header: {
                        title: 'Get Started',
                    },
                    content: `This tab will display a list of boxes you can open!`,
                },
                {
                    target: '[data-v-step="2"]',
                    content:
                        'This tab will show a list of boxes you can sell and transfer!',
                },
                {
                    target: '[data-v-step="3"]',
                    content:
                        "If you don't receive the box after 15 minutes of purchase please click it.",
                },
            ],
            tabPicker: [
                {
                    text: 'All',
                    value: 0,
                },
                {
                    text: 'Silver',
                    value: 1,
                },
                {
                    text: 'Gold',
                    value: 2,
                },
                {
                    text: 'Platinum',
                    value: 3,
                },
                {
                    text: 'Diamond',
                    value: 4,
                },
            ],
            filterBox: {
                limit: 6,
                page: 1,
                type: 0,
                tokenId: null,
            },
            onFinish: {
                onFinish: () => {
                    this.finishTour();
                },
            },
            listRankOfBox: {},
        };
    },
    methods: {
        onRefreshBoxes() {
            this.$bvModal.show('modal-refresh-boxes');
            this.$store.dispatch('box/req_refreshBoxes');
        },
        finishTour() {
            // window.$cookies.set('tour_box', true);
        },
        ...mapMutations(['onLoad', 'outLoad']),
        onChangePage(pageNext) {
            this.filterBox.page = pageNext;
            this.$store.dispatch('box/req_getListBox', this.filterBox);
        },
        async ApproveSell(box) {
            const { web3 } = this.$store.state.contract;
            const { marketNew } = this.$store.state.contract.marketplace;
            const contracts = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            const { version, tokenId } = box;
            let HeroContract = null;
            Object.keys(contracts).forEach((key) => {
                if (key.includes('hero')) {
                    if (
                        contracts[key].addr.toLowerCase() ===
                        version.toLowerCase()
                    ) {
                        HeroContract = contracts[key];
                    }
                }
            });

            this.onLoad();
            this.modalValue.tokenId = tokenId;
            const { sellPrice } = this.modalValue;
            if (sellPrice < 0 || this.isNaN(sellPrice) || !sellPrice) {
                this.$toastr.e(
                    'Min sell is 1 HBG, Please check your price sell',
                    'Open Failed',
                );
                this.outLoad();
                return;
            }
            const txObj = HeroContract.cont.methods
                .approve(marketNew.addr, tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                // Calculate gas
                gas = await HeroContract.cont.methods
                    .approve(marketNew.addr, tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;

            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: HeroContract.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                let submitted = true;
                // Kết quả transaction
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);

                        if (txData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            await this.onSellBox(box);
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        // eslint-disable-next-line consistent-return
        async onSellBox(box) {
            // this.onLoad();
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const { sellPrice } = this.modalValue;
            const { version, tokenId } = box;
            if (parseFloat(sellPrice) > 1000000000 * this.Balance.price) {
                this.messageErrorToastr(
                    `Sell price must be less than ${
                        1000000000 * this.Balance.price
                    } `,
                );
                this.outLoad();
                return;
            }
            if (sellPrice < 0 || this.isNaN(sellPrice) || !sellPrice) {
                this.$toastr.w(
                    'Min sell is 1 USD, Please check your price sell',
                    'Open Failed',
                );
                return;
            }
            const deciamalPrice = web3.provider.utils.toWei(
                sellPrice.toString(),
                'ether',
            );
            const txObj = marketNew.cont.methods
                .sell(tokenId, deciamalPrice, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            // console.log('check', gasLimit);
            try {
                gas = await marketNew.cont.methods
                    .sell(tokenId, deciamalPrice, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txSellData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txSellData != null) {
                        clearInterval(check);
                        if (txSellData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('sell', txSellData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        async onTransferHero(box) {
            // this.$toastr.w('Function transfer hero is maintenance', 'Oops!');

            this.onLoad();
            if (!this.modalValue.addressTransfer) {
                this.$toastr.e(
                    'Transfer Failed',
                    'Please check receive address',
                );
                this.outLoad();
                return;
            }
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const contracts = this.$store.state.contract.marketplace;

            const { version, tokenId } = box;
            let gas = 0;
            let hero = null;

            Object.keys(contracts).forEach((key) => {
                if (key.includes('hero')) {
                    if (
                        contracts[key].addr.toLowerCase() ===
                        version.toLowerCase()
                    ) {
                        hero = contracts[key];
                    }
                }
            });

            const txObj = await hero.cont.methods
                .transferFrom(addr, this.modalValue.addressTransfer, tokenId)
                .encodeABI();

            const gasLimit = await this.getLimitGas();
            try {
                gas = await hero.cont.methods
                    .transferFrom(
                        addr,
                        this.modalValue.addressTransfer,
                        tokenId,
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hero.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            // console.log(123);
                            this.sendTransaction('transfer', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        async onCancelHero(box) {
            this.onLoad();
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const { version, tokenId } = box;
            const txObj = await marketNew.cont.methods
                .cancelSale(tokenId, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await marketNew.cont.methods
                    .cancelSale(tokenId, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancel', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async onCancelHeroOld(tokenId) {
            this.onLoad();
            const { market } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await market.cont.methods
                .cancelHeroSale(tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await market.cont.methods
                    .cancelHeroSale(tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: market.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancelOld', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },

        onShowCancel(value) {
            this.isShowModalSell = false;
            this.isShowModalTransfer = false;
            this.isShowModalCancel = true;
            this.boxInsideModal = value;
        },
        showModalSell(value) {
            this.isShowModalCancel = false;
            this.isShowModalSell = true;
            this.isShowModalTransfer = false;
            this.boxInsideModal = value;
        },
        showModalTransfer(value) {
            this.isShowModalCancel = false;
            this.isShowModalSell = false;
            this.isShowModalTransfer = true;
            this.boxInsideModal = value;
        },
        clearModalValue() {
            this.modalValue = {
                sellPrice: 0,
                addressTransfer: '',
            };

            this.$store.dispatch('box/req_getListBox', this.filterBox);
            this.$store.dispatch('inventory/req_getListBox');
        },
        changeTabs(tab) {
            this.filterBox.type = tab;
            this.$store.dispatch('box/req_getListBox', this.filterBox);
        },
        sendTransaction(type, txData) {
            if (type === 'transfer') {
                this.isShowModalTransfer = false;
                this.$store.dispatch('box/req_postTransferSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-transfer-box-success');
            }
            if (type === 'sell') {
                this.isShowModalSell = false;
                this.$store.dispatch('box/req_postSellSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-sell-box-success');
            }
            if (type === 'cancel') {
                this.isShowModalCancel = false;
                this.$store.dispatch('box/req_postCancelSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-cancel-box-success');
            }
            if (type === 'cancelOld') {
                this.isShowModalCancel = false;
                this.$store.dispatch('box/req_postCancelOldSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-cancel-box-success');
            }
        },
        ComingSoonT() {
            this.$toastr.s('Function Coming Soon', 'Coming Soon');
        },
        hiddenModalTransfer() {
            this.$bvModal.hide('modal-transfer-box');
            this.modalValue = {
                sellPrice: 0,
                addressTransfer: '',
            };
        },
        onSearchBox() {
            this.filterBox.page = 1;
            this.$store.dispatch('box/req_getListBox', this.filterBox);
        },
    },
    mounted() {
        // const tour_box = window.$cookies.get('tour_box');
        // console.log(tour_box);
        // if (!tour_box) {
        // this.$tours.myTour.start();
        // }
    },
};
</script>

<template>
    <b-container>
        <div class="inventory-box-search mb-3">
            <form
                @submit.prevent="onSearchBox()"
                class="row justify-content-between align-items-center"
            >
                <div
                    class="col-sm-3 col-12 my-3"
                    :class="[
                        componentActive === 'ListSelling' ? '' : 'col-sm-4',
                    ]"
                >
                    <label
                        for="inputSelect"
                        class="d-flex align-items-center justify-content-center"
                    >
                        <select
                            v-model="componentActive"
                            id="inputSelect"
                            class="custom-select"
                        >
                            <option value="ListOpen" selected>Open Box</option>
                            <option value="ListSelling">Sell Box</option>
                        </select>
                        <img
                            src="../../assets/images/icons/icon-drop.svg"
                            alt=""
                        />
                    </label>
                </div>
                <div
                    v-if="componentActive === 'ListSelling'"
                    class="col-sm-3 col-12 my-3"
                >
                    <label
                        for="inputSelect"
                        class="d-flex align-items-center justify-content-center"
                    >
                        <b-form-select
                            v-model="filterBox.type"
                            :options="tabPicker"
                            class="custom-select"
                        ></b-form-select>
                        <img
                            src="../../assets/images/icons/icon-drop.svg"
                            alt=""
                        />
                    </label>
                </div>
                <div
                    v-if="componentActive === 'ListSelling'"
                    class="col-sm-3 col-12 my-3"
                >
                    <label
                        for="inputSelect"
                        class="d-flex align-items-center justify-content-center"
                    >
                        <span
                            v-if="
                                filterBox.tokenId !== '' &&
                                filterBox.tokenId !== null
                            "
                            class="text-white"
                            >#</span
                        >
                        <input
                            v-model="filterBox.tokenId"
                            type="number"
                            placeholder="ID Box"
                            class="w-100"
                            maxlength="10"
                        />
                        <img
                            class="search-icon-opa"
                            src="../../assets/images/icons/icon-search.svg"
                            alt=""
                        />
                    </label>
                </div>
                <div v-if="componentActive === 'ListSelling'" class="col-3 d-flex justify-content-end">
                    <button class="bede-btn">
                        <p class="m-0">FIND BOX</p>
                    </button>
                </div>
            </form>
        </div>
        <b-modal
            centered
            id="modal-refresh-boxes"
            title="Warning Refresh Box"
            hide-footer
        >
            <div>
                <p class="mb-0 text-confirm">
                    Function to refresh the inventory (boxes), when you activate
                    the new box button, we will proceed to check your purchase,
                    transaction, transfer your box. The normal check completion
                    time will take from 1 minute to 15 minutes depending on the
                    number of your transactions. Can you only call the new box
                    function once every 30 minutes!
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-3 mb-0 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('modal-refresh-boxes')"
                >
                    I understand
                </b-button>
            </div>
        </b-modal>
        <v-tour name="myTour" :steps="steps" :callbacks="onFinish"></v-tour>
        <!-- <keep-alive> -->
        <component :is="componentActive"></component>
        <!-- </keep-alive> -->
    </b-container>
</template>

<style lang="scss" scoped>
.search-icon-opa {
    opacity: 0.5;
}

.inventory-box-search {
    .bede-btn {
        width: 100%;
        max-width: 150px !important;
    }
}
.custom-select {
    outline: unset;
    box-shadow: none;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 26px;
}
.input-opn {
    max-width: 350px;
    margin: 10px auto;
    input {
        width: 100%;
        margin: 0 auto;
    }
    label {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
    }
    small {
        font-size: 15px;
        color: #fff;
        margin-bottom: 0;
    }
}
#modal-refresh-boxes {
    .text-confirm {
        font-size: 19px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
        font-family: Arial, Helvetica, sans-serif;
        text-transform: unset;
    }
}
#heroReceiver {
    .VueCarousel-navigation-next {
        right: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
    }
    .VueCarousel-navigation-prev {
        left: 20px !important;
        font-size: 40px !important;
        color: #f78b00 !important;
        opacity: 1 !important;
    }
    .VueCarousel-inner {
        visibility: visible !important;
    }
    @media (max-width: 991px) {
        .modal-dialog {
            max-width: 700px;
        }
    }
    .hero-receiver {
        flex: 1;
        min-width: 200px;
        max-width: 225px;
        width: 100%;
        margin: 0 15px 5px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 1 1 auto;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        border: 1px solid #00ffff;
                        max-width: calc(100% - 50px);
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .right-hero {
                        flex: 0 0 50px;
                        max-width: 50px;
                        .stat-hero {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            .hero-level {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ffff;
                                border: 1px solid #00ffff;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                            }
                            img {
                                width: 35px;
                                height: auto;
                                max-height: 35px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }
                .hero-exp {
                    position: relative;
                    width: 100%;
                    border: 1px solid #00ffff;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px auto 15px;
                    background: rgba(0, 255, 255, 0.2);
                    .text {
                        position: relative;
                        z-index: 10;
                        font-weight: 500;
                        font-size: 12px;
                        font-style: italic;
                        span {
                            font-weight: 700;
                            font-family: 'Helvetica';
                            font-size: 15px;
                        }
                    }
                    .current {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
                .hero-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        width: auto;
                        padding: 5px;
                        min-width: 100px;
                        height: 40px;
                        border-radius: 5px;
                        border: 0;
                        color: #ffff;
                        &.sell {
                            background: {
                                image: url('~@/assets/images/mockup/account/sell.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.transfer {
                            background: {
                                image: url('~@/assets/images/mockup/account/transfer.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.price {
                            background: {
                                image: url('~@/assets/images/mockup/account/price.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.cancel {
                            background: {
                                image: url('~@/assets/images/mockup/account/cancel.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
#heroReceiver___BV_modal_outer_ {
    z-index: unset !important;
}
.customer-open-box {
    .modal-dialog {
        // max-width: 320px;

        .modal-content {
            border-radius: 5px;
        }
        .video-cn {
            width: 100%;
            position: relative;
            padding-bottom: 74.69%; /* ratio 16/9 */
        }
    }
}
.nav-box {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 10px 20px;
    &-item {
        background-image: url(~@/assets/images/mockup/summon/Summon.png);
        background-size: 100% 100%;
        padding: 5px 20px;

        font-family: 'helvetica-neue-regular';
        font-size: 20px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 0px 10px #00ff60;

        cursor: pointer;
        transition: 0.3s;
        opacity: 0.7;
        &.active,
        &:hover {
            opacity: 1;
        }
        &.function {
            color: #f70021;
            opacity: 1;
            border-radius: 5px;
        }
    }
    @media (max-width: 576px) {
        padding: 10px;
    }
    @media (max-width: 414px) {
        flex-wrap: wrap;
        justify-content: space-around;
        &-item {
            max-width: 150px;
            min-width: 150px;
            max-height: 50px;
            font-size: 15px;
            padding: 5px;
            margin: 5px 0;
        }
    }
}
.box-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media (max-width: 1400px) {
        justify-content: space-around;
    }

    .box {
        max-width: 250px;
        width: 100%;
        min-height: 350px;
        margin: 15px;
        background: {
            image: url(~@/assets/images/mockup/account/body-box.png);
            repeat: no-repeat;
            size: 100% 100%;
        }
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                max-height: 210px;
                object-fit: cover;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
            &.herobox {
                color: #fff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
        }
        .box-available {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0px 10px;
            text-align: center;
            color: #fff;
            span {
                color: #00ffff;
                font-weight: 600;
            }
        }
        .box-open {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border-radius: 7px;
            width: 120px;
            height: 40px;
            padding: 5px;
            button {
                color: #000;
                border-radius: 5px;
                font-weight: 600;
                border: 1.5px solid #fff;
                padding: 0;
                height: 100%;
                width: 100%;
                background: transparent;
                box-shadow: 0px 0px 5px #00ffff;
                text-transform: uppercase;
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            max-width: 210px;
        }
    }
}
.box-sell-list {
    .box-navbar {
        background-image: url(~@/assets/images/frame/frame-ligting-blue.png);
        background-size: 100% 100%;
        min-height: 75px;
        align-items: center;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &-item {
            align-items: center;
            padding: 5px 15px;
            height: 100%;
            min-height: 50px;
            display: flex;
            justify-content: center;
            min-width: 120px;
            font-family: 'HHBI';
            letter-spacing: 1px;
            font-size: 1.5em;
            font-weight: 900;
            color: #fff;
            line-height: 0;
            text-align: center;
            text-decoration: none !important;
            text-transform: uppercase;
            cursor: pointer;
            &.active,
            &:hover {
                color: #00ffff;
                background-color: rgba(0, 33, 51, 0.6);
            }
        }
        .btn-toogle {
            width: 35px;
            height: 35px;
        }
    }
}
</style>
