<template>
    <router-link
        :to="{
            name: 'DetailMarket',
            params: {
                type: detailItem.name,
                itemName: detailItem.itemName,
            },
        }"
        class="market-item"
    >
        <div class="market-item-name">
            <div class="hero-info left">
                <div class="hero-info-avatar">
                    <img :src="item.image" alt="" />
                </div>
                <div class="hero-info-text">
                    <div
                        v-if="detailItem.name != 'box'"
                        class="hero-info-text-name"
                        :class="`${
                            detailItem.ratity.toLowerCase() || 'common'
                        }-color`"
                    >
                        {{ detailItem.itemName }}
                    </div>
                    <div
                        v-else
                        class="hero-info-text-name"
                        :class="`${detailItem.itemName.toLowerCase()}-color`"
                    >
                        {{ detailItem.itemName }}
                    </div>
                    <div class="hero-info-text-platform">
                        {{ detailItem.platform }}
                    </div>
                </div>
            </div>
        </div>
        <div class="market-item-quantity">{{ item.quantity }}</div>
        <div class="market-item-price">
            <div class="hero-info">
                <div class="hero-info-text2">
                    <div class="hero-info-text2-platform small">Start from</div>
                    <div class="hero-info-text2-name">
                        ~
                        {{
                            TruncateToDecimals2(
                                item.minPrice * Balance.price || 0,
                                '',
                                4,
                            )
                        }}
                        HBG
                    </div>
                    <div class="hero-info-text2-platform">
                        {{ item.minPrice }} USD
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            item2: {
                name: 'Mr. Pi',
                id: '200000',
                platform: 'Hero Book',
                price: 100,
                time: '21/11/2022',
                isSell: false,
                quantity: 100,
                img: 'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/3d-raw/crocky-chromas.png?v=1',
            },
        };
    },
    computed: {
        ...mapGetters({
            Balance: 'wallet/Balance',
        }),
        detailItem() {
            // eslint-disable-next-line no-underscore-dangle
            if (!this.item._id) {
                return {
                    itemName: 'Berry',
                    name: 'hero',
                    platform: 'Hero Book',
                    ratity: 'Common',
                };
            }
            // eslint-disable-next-line no-underscore-dangle
            return this.item._id;
        },
    },
};
</script>

<style lang="scss">
.market-item {
    text-decoration: none !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    background: rgba(53, 63, 72, 0.2);
    padding: 5px 15px;
    &.odd {
        background: rgba(53, 63, 72, 0.4);
    }
    &-name {
        flex: 1 1 auto;
        max-width: 275px;
        min-width: 275px;
        flex: 1 1 auto;
        max-width: 275px;
        min-width: 275px;
        @media (max-width: 575px) {
            min-width: 200px;
            max-width: 225px;
        }
    }
    &-quantity {
        flex: 0 0 100px;
        color: #fcfcfc;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        @media (max-width: 575px) {
            display: none;
        }
    }
    &-price {
        flex: 1 1 200px;
    }
    .hero-info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &.left {
            justify-content: flex-start;
        }
        &-avatar {
            width: 48px;
            height: 48px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
            /* GrayScale/Label */

            border: 0.5px solid #555555;
            border-radius: 8px;
            padding: 5px;
            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
            margin-right: 10px;
        }
        &-text {
            text-align: left;
            &-name {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
            }
            &-platform {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #fcfcfc9f;
            }
        }
        &-text2 {
            text-align: center;
            .small {
                font-size: 14px;
            }
            &-name {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #0fff;
            }

            &-platform {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
            }
        }
    }
}
</style>
