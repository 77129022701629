const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        member: {
            total: 1,
            list: [],
            statistical: {
                totalHGB: 0,
                totalMember: 0,
                totalMemberActive: 0,
                totalUSD: 0,
                totalCommissionInGame: 0,
                totalCommissionWallet: 0,
                totalSaleInGame: 0,
                totalSaleWallet: 0,
            },
        },
    }),

    getters: {
        Member: (state) => state.member,
    },

    actions: {
        req_getListMember: ({ commit }, input) => {
            axios
                .post('api/market/list', {
                    search: input.search || null,
                    page: input.page || 1,
                    address: input.address,
                })
                .then((res) => {
                    commit('SET_LIST_MEMBER', res);
                });
        },
        req_getStatistical: ({ commit }) => {
            axios.get('/api/me/statistic').then((res) => {
                commit('SET_STATISTICAL', res);
            });
        },
    },
    mutations: {
        SET_LIST_MEMBER(state, data) {
            state.member.total = data.total_page;
            state.member.list = data.downline;
            state.member.statistical = {
                ...state.member.statistical,
                totalHGB: data.totalHGB,
                totalMember: data.totalMember,
                totalMemberActive: data.totalMemberActive,
                totalUSD: data.totalUSD,
            };
        },
        SET_STATISTICAL(state, data) {
            const {
                totalCommissionIngame,
                totalCommissionWallet,
                totalSaleIngame,
                totalSaleWallet,
            } = data;
            state.member.statistical = {
                ...state.member.statistical,
                totalCommissionInGame: totalCommissionIngame.totalPrice,
                totalCommissionWallet: totalCommissionWallet.totalPrice,
                totalSaleInGame: totalSaleIngame.totalPrice,
                totalSaleWallet: totalSaleWallet.totalPrice,
            };
        },
    },
};
