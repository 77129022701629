import Vue from 'vue';
import Vuex from 'vuex';

// eslint-disable-next-line import/no-cycle
import infoModel from './modules/info';
import authModel from './modules/auth';
import contractModel from './modules/contract';
import coreModel from './modules/core';
import memberModel from './modules/member';
import walletModel from './wallet';
import preSaleModel from './modules/PreSale';
import shopModel from './modules/shop';
import inventoryModel from './modules/inventory';
import historyModel from './modules/history';
import upgradeModel from './modules/upgrade';
import heroModel from './modules/hero';
import marketModel from './modules/market';
import dashboardModel from './modules/dashboard';
import landModel from './modules/land';
import boxModel from './modules/box';
import supportModel from './modules/support';
import suggestModel from './modules/suggest';
import promotionModel from './modules/promotion';
import agencyModel from './modules/agency';
import burnModel from './modules/burn';
import vaccinateModel from './modules/vaccin';
import swapModel from './modules/swap';
// hub
import hubModel from './modules/hub';
import transformModel from './modules/transform';
import gameModel from './modules/game';
// --------------------------------
Vue.use(Vuex);

export default new Vuex.Store({
    state: () => ({
        loadingStatus: false,
        totalSend: 0,
        totalReceive: 0,
        error: '',
        success: '',
        status: 0,
    }),
    getters: {
        LoadingStatus: (state) => state.loadingStatus,
    },
    mutations: {
        onLoad(state) {
            this.commit('StatusLoad', true);
            state.totalSend += 1;
        },
        outLoad(state) {
            state.totalReceive += 1;
            if (state.totalSend <= state.totalReceive) {
                this.commit('StatusLoad', false);
            }
        },
        StatusLoad: (state, data) => {
            if (data) {
                state.loadingStatus = true;
                return;
            }
            state.loadingStatus = false;
            state.totalSend = 0;
            state.totalReceive = 0;
        },
    },
    actions: {},
    modules: {
        info: infoModel,
        auth: authModel,
        contract: contractModel,
        core: coreModel,
        member: memberModel,
        wallet: walletModel,
        preSale: preSaleModel,
        shop: shopModel,
        inventory: inventoryModel,
        history: historyModel,
        upgrade: upgradeModel,
        hero: heroModel,
        market: marketModel,
        dashboard: dashboardModel,
        land: landModel,
        box: boxModel,
        support: supportModel,
        suggest: suggestModel,
        promotion: promotionModel,
        agency: agencyModel,
        burn: burnModel,
        vaccinate: vaccinateModel,
        swap: swapModel,
        hub: hubModel,
        transform: transformModel,
        game: gameModel,
    },
});
