var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-promotion"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":_vm.promotion.image
                    ? _vm.promotion.image
                    : require('@/assets/images/logo/logo.png'),"alt":""}})]),_c('div',{staticClass:"content-promotion"},[_c('div',{staticClass:"title-promotion"},[_vm._v(" "+_vm._s(_vm.promotion.name)+" ")]),_c('div',{staticClass:"date-promotion"},[_vm._v(" Time: "+_vm._s(_vm.getDateTime6(_vm.promotion.timeStart))+" - "+_vm._s(_vm.getDateTime6(_vm.promotion.timeEnd))+" ")]),_c('div',{staticClass:"des-promotion",class:{
                join:
                    _vm.promotion.listHeroUser &&
                    _vm.promotion.listHeroUser.length >= 1,
            }},[_vm._v(" "+_vm._s(_vm.promotion.description)+" ")]),(
                _vm.promotion.listHeroUser && _vm.promotion.listHeroUser.length >= 1
            )?_c('div',{staticClass:"list-hero-locked"},_vm._l((_vm.promotion.listHeroUser),function(hero){return _c('img',{key:((hero.tokenId) + "-" + (_vm.promotion._id)),attrs:{"src":_vm.getImageHero(
                        '3d',
                        ((hero.name.toLowerCase()) + "-" + (hero.skin.toLowerCase())),
                        hero.version
                    ),"alt":""},on:{"click":function($event){return _vm.pickHero(hero)}}})}),0):_vm._e(),_c('div',{staticClass:"action-promotion"},[_c('router-link',{staticClass:"sell-item-button",attrs:{"to":{
                    name: 'DetailPromotion',
                    params: { slug: _vm.promotion.slug },
                }}},[_c('div',{staticClass:"sell-item-button-content"},[_vm._v(" "+_vm._s(_vm.isJoin || _vm.promotion.isLock === false ? 'Detail' : 'Join Now')+" ")])])],1)]),_c('b-modal',{attrs:{"id":("modal-info-" + (_vm.promotion._id)),"title":"Information Hero Locked in Promotion ","centered":true,"footer-class":"d-none"}},[(_vm.receivedHero)?_c('HeroShow',{attrs:{"hero":_vm.receivedHero}}):_vm._e(),_c('b-button',{staticClass:"d-block btn-confirm-p mx-auto mb-3 rounded-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.$bvModal.hide(("modal-info-" + (_vm.promotion._id)))}}},[_vm._v(" Confirm ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }