<template>
    <div id="SystemView" class="pb-5 px-0 px-sm-3">
        <b-container fluid>
            <b-tabs pills card vertical class="main-tabs" data-aos="zoom-in">
                <b-tab
                    v-for="(item, idx) in data"
                    :key="item + idx"
                    lazy
                    class="tab-1"
                >
                    <template #title>
                        <div class="background-card"></div>
                        <div class="svg-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg">
                                <rect class="shape" />
                            </svg>
                        </div>
                        <div class="tab-bg">
                            <img
                                :src="
                                    require(`../../assets/images/system/${item.bg}`)
                                "
                                alt=""
                                draggable="false"
                            />
                        </div>
                        <div class="tab-img">
                            <img
                                :src="
                                    require(`../../assets/images/system/${item.src}`)
                                "
                                alt=""
                                draggable="false"
                            />
                        </div>
                        <h5 class="ml-md-3 ml-2">{{ item.title }}</h5>
                    </template>
                    <div class="d-none d-md-block">
                        <div class="main-bg">
                            <img
                                :src="
                                    require(`../../assets/images/system/${item.mainBg}`)
                                "
                                alt=""
                                draggable="false"
                            />
                        </div>

                        <div
                            class="mt-0 mt-md-3 px-xl-5 px-md-3 px-0 position-relative"
                        >
                            <div class="logo-img">
                                <img
                                    :src="
                                        require(`../../assets/images/system/${item.logo}`)
                                    "
                                    alt=""
                                    draggable="false"
                                />
                            </div>
                            <h1 class="text-white">{{ item.title }}</h1>
                            <div class="d-flex align-items-center my-3">
                                <p class="available">Now Available</p>
                                <p class="ver ml-3">Ver.1.0.12</p>
                            </div>
                            <p class="des text-white">{{ item.des }}</p>
                            <div class="d-flex mt-4 mt-md-5 align-items-center">
                                <button @click="onClick(item.ref)">
                                    <p>
                                        {{
                                            item.ref
                                                ? 'Play now'
                                                : 'Coming soon'
                                        }}
                                    </p>
                                </button>
                                <div class="heart-icon ml-4">
                                    <img
                                        src="~@/assets/images/system/heart.png"
                                        alt=""
                                        draggable="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-block d-md-none main-mb">
                        <div class="bg-mb">
                            <img
                                :src="
                                    require(`../../assets/images/system/${item.mainBg}`)
                                "
                                alt=""
                                draggable="false"
                            />
                            <div class="logo-img-mb">
                                <img
                                    :src="
                                        require(`../../assets/images/system/${item.logo}`)
                                    "
                                    alt=""
                                    draggable="false"
                                />
                            </div>
                            <div class="label-fr">
                                <p class="available">Now Available</p>
                                <p class="ver ml-1 ml-md-2">Ver.1.0.12</p>
                            </div>
                            <div class="heart-icon-mb">
                                <img
                                    src="~@/assets/images/system/heart.png"
                                    alt=""
                                    draggable="false"
                                />
                            </div>
                        </div>
                        <b-row class="w-100 ml-0">
                            <b-col
                                cols="12"
                                class="d-flex justify-content-between align-items-center mb-3 px-0"
                            >
                                <h1 class="text-white">{{ item.title }}</h1>

                                <button @click="onClick(item.ref)">
                                    <p>
                                        {{
                                            item.ref
                                                ? 'Play now'
                                                : 'Coming soon'
                                        }}
                                    </p>
                                </button>
                            </b-col>
                            <b-col cols="12" class="px-0">
                                <p class="des text-white">{{ item.des }}</p>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
            </b-tabs>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'SystemView',
    data() {
        return {
            data: [
                {
                    id: 5,
                    title: 'Hero snake',
                    des: 'Belonging to the arcade genre, the gameplay is simple but creates a fun experience that attracts players, and this genre is suitable for the majority of players of many ages. Hero Snake will be the future of the "Fun to Earn" trend.',
                    src: '5.png',
                    bg: 'bg-5.png',
                    mainBg: 'hero-snake-bg.png',
                    logo: 'hero-snake-logo.png',
                    available: true,
                    ref: 'https://herosnake.io',
                },
                {
                    id: 1,
                    title: 'Hero book',
                    des: 'It is a series of skill moba games that combine survival while meeting the entertainment and competition needs of players with more attractive game play mechanics than Click to Earn games.',
                    src: '1.png',
                    bg: 'bg-1.png',
                    mainBg: 'hero-book-bg.png',
                    logo: 'hero-book-logo.png',
                    available: true,
                    ref: 'https://game.herobook.io/',
                },
                {
                    id: 2,
                    title: 'Hero Land',
                    des: 'As a construction and exploitation game genre, Hero Land is a 1:1 virtual version of Mars. This virtual Mars is divided into 167,000 plots of land (10 x 10 pieces each). Players can make a profit by buying, selling, leasing, building, and exploiting resources',
                    src: '2.png',
                    bg: 'bg-2.png',
                    mainBg: 'hero-land-bg.png',
                    logo: 'hero-land-logo.png',
                    available: true,
                    ref: 'https://heroland.app/home',
                },
                {
                    id: 3,
                    title: 'Hero Card',
                    des: 'Designed with the aim of creating a simple, fast-to-play, and fun card game for everyone, it belongs to the card game genre with tactical and high-minded gameplay.',
                    src: '3.png',
                    bg: 'bg-3.png',
                    mainBg: 'hero-card-bg.png',
                    logo: 'hero-card-logo.png',
                    available: true,
                    ref: '#'
                },
                {
                    id: 4,
                    title: 'Hero war',
                    des: 'The game belongs to the genres of shooters, survival, and role-playing games from both the 3rd and 1st person perspectives, meeting the entertainment, connection, and competition needs of players with top-notch graphics and attractive game-play mechanics. Help players earn income with personal skills and tactics.',
                    src: '4.png',
                    bg: 'bg-4.png',
                    mainBg: 'hero-war-bg.png',
                    logo: 'hero-war-logo.png',
                    available: true,
                    ref: 'https://herowar.co',
                },
            ],
        };
    },
    methods: {
        onClick(ref) {
            if (ref) {
                window.open(ref, '_blank');
            }
        },
    },
};
</script>
<style lang="scss">
#SystemView {
    .nav-item {
        @media (max-width: 991px) {
            margin-bottom: 0 !important;
        }
    }
    .main-mb {
        .label-fr {
            display: flex;
            align-items: center;
            margin: 0.75rem 0;
            @media (max-width: 768px) {
                position: absolute;
                bottom: 2%;
                left: 2%;
                transform: translate(-2%, -2%);
            }
        }
        .bg-mb {
            width: 100%;
            height: 155px;
            margin: 1rem auto;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            .heart-icon-mb {
                width: 37px;
                height: 37px;
                position: absolute;
                bottom: 2%;
                right: 2%;
                transform: translate(-2%, -2%);
                img {
                    object-fit: scale-down;
                    width: 100%;
                    height: 100%;
                }
            }
            .logo-img-mb {
                width: 75px;
                height: 35px;
                position: absolute;
                top: 5%;
                left: 2%;
                transform: translate(-2%, -5%);
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
        }
    }
    .tab-pane {
        width: 95%;
        margin: 0 auto;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    .tab-content {
        position: relative;
        padding: 0 12px;
        @media (max-width: 991px) {
            padding: 0;
            margin-top: 0;
        }
        .heart-icon {
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            border: 1px solid #dcdcdc;
            img {
                object-fit: scale-down;
            }
            @media (max-width: 768px) {
                width: 37px;
                height: 37px;
            }
        }
        h1 {
            font-weight: 600;
            font-size: 40px;
            line-height: 170%;
            @media (max-width: 991px) {
                font-size: 32px;
            }
            @media (max-width: 768px) {
                font-size: 24px;
            }
            @media (max-width: 575px) {
                font-size: 20px;
            }
        }
        .des {
            width: 50%;
            @media (max-width: 991px) {
                width: 100%;
            }
        }
        .available,
        .ver {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 0;
            color: #dcdcdc;
            @media (max-width: 991px) {
                font-size: 12px;
            }
            @media (max-width: 768px) {
                font-size: 10px;
            }
            @media (max-width: 575px) {
                font-size: 8px;
            }
        }

        .available {
            padding: 4px 10px;
            border: 0.5px solid #dcdcdc;
            border-radius: 4px;
        }
        .tab-pane {
            position: relative;
            height: 100%;
            padding: 14px;
            @media (max-width: 991px) {
                padding-top: 0;
            }
        }
        .main-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: rgb(0, 255, 255, 0.5) 0px 2px 8px 0px;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: right 0;
            }
        }
    }
    button {
        width: 200px;
        height: 50px;
        border: none;
        outline: none;
        position: relative;
        background: linear-gradient(to right, #18d4e2, #a506f9);
        cursor: pointer;
        @media (max-width: 768px) {
            width: 150px;
            height: 45px;
            p {
                font-size: clamp(14px, 1.5vw, 16px);
            }
        }
        @media (max-width: 575px) {
            max-width: 140px;
            width: 100%;
            height: 35px;
        }
        &:hover {
            background-size: 200%;
            animation: loop 1s infinite linear;
        }
        &::before {
            content: '';
            position: absolute;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
        }
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: clamp(14px, 1.5vw, 16px);
            line-height: 150%;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #00ffff;
            z-index: 10;
        }
    }
    .card {
        background: transparent;
        border: none;
    }
    .main-tabs {
        margin-top: 3rem;
        flex-direction: row-reverse;
        @media (max-width: 991px) {
            flex-direction: column;
            margin-top: 3rem;
        }
        @media (max-width: 575px) {
            margin-top: 1.25rem;
        }
        .tab-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            img {
                width: 100%;
                height: 100%;
                @media (max-width: 991px) {
                    object-fit: cover;
                }
            }
        }

        .logo-img {
            width: 99px;
            height: 55px;
            position: relative;
            z-index: 5;
            margin-top: 2rem;
            @media (max-width: 1200px) {
                margin-top: 0;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                // width: 100%;
                height: 100%;
                object-position: center;
            }
        }
        .nav-link {
            width: 270px;
            height: 98px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0;
            @media (max-width: 1200px) {
                width: 225px;
            }
            @media (max-width: 991px) {
                margin-top: 10px;
                width: 185px;
                margin-bottom: 10px;
            }
            @media (max-width: 768px) {
                width: 145px;
                height: 78px;
            }
            @media (max-width: 575px) {
                width: 125px;
                height: 68px;
            }
            h5 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: clamp(15px, 1.5vw, 16px);
                line-height: 150%;
                margin-bottom: 0;
                color: #f0f0f0;
                z-index: 5;
                text-transform: capitalize;
            }
        }
        .nav-item {
            padding: 5px 0;
            @media (max-width: 991px) {
                padding: 0 5px;
            }
        }
        .tab-img {
            z-index: 10;
            width: 50px;
            height: 50px;
            position: relative;
            @media (max-width: 768px) {
                width: 35px;
                height: 35px;
            }
            img {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                object-position: center;
            }
        }
        .svg-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            z-index: 10;
            // display: none;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        .shape {
            stroke-dasharray: 140 540;
            stroke-dashoffset: -474;
            stroke-width: 0px;
            fill: transparent;
            stroke: #19f6e8;
            border-bottom: 5px solid black;
            transition: stroke-width 1.25s, stroke-dashoffset 1.25s,
                stroke-dasharray 1.25s;
            width: 100%;
            height: 98px;
            @media (max-width: 1200px) {
                width: 225px;
            }
            @media (max-width: 991px) {
                width: 185px;
            }
            @media (max-width: 768px) {
                width: 145px;
                height: 78px;
            }
            @media (max-width: 575px) {
                width: 125px;
                height: 68px;
            }
        }

        .background-card {
            background-color: rgb(26, 30, 36);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.35;
            z-index: 3;
        }

        .nav-pills {
            padding: 0 10px;
            @media (max-width: 991px) {
                flex-direction: row !important;
                overflow-x: scroll;
                width: 100%;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
        }
        .nav-pills .nav-link.active,
        .nav-pills .show > .nav-link {
            // border-bottom: 7px solid #00ffff !important;
            background: none;
            .svg-wrapper .shape {
                stroke-width: 3px;
                stroke-dashoffset: 0;
                stroke-dasharray: 760;
                // display: block;
            }
        }
        .nav-link:hover {
            .svg-wrapper .shape {
                stroke-width: 3px;
                stroke-dashoffset: 0;
                stroke-dasharray: 760;
                // display: block;
            }
        }
    }
}
</style>
