<script>
import { Line } from 'vue-chartjs';

export default {
    name: '',
    extends: Line,
    props: {
        dataPropped: { type: Array, default: null },
    },
    data() {
        return {
            dataOfChart: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: 'transparent',
                        borderColor: '#688F3E',
                        pointBackgroundColor: '#cc9900',
                        label: '#value',
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    labels: {
                        font: {
                            family: 'Poppins',
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                fontColor: '#cccccc',
                            },
                            gridLines: {
                                color: '#1e3248',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                fontColor: '#cccccc',
                            },
                            gridLines: {
                                color: '#1e3248',
                            },
                        },
                    ],
                },
            },
            listDate: [],
            listValue: [],
        };
    },
    watch: {
        dataPropped: {
            handler() {
                this.setValueOfChart();
                this.renderChart(this.dataOfChart, this.options);
            },
        },
    },
    mounted() {
        this.setValueOfChart();
        this.renderChart(this.dataOfChart, this.options);
    },
    methods: {
        setValueOfChart() {
            // this.dataOfChart.labels = this.dataPropped.title;
            // this.dataOfChart.datasets[0].data = this.dataPropped.value;
            // console.log(this.dataPropped);
            if (this.dataPropped) {
                this.dataPropped.forEach((element) => {
                    this.listDate.push(this.GetDate(element.date));
                    this.listValue.push(element.price);
                });
            }
            this.dataOfChart.labels = this.listDate;
            this.dataOfChart.datasets[0].data =  this.listValue
        },
        GetDate(datetime) {
            const date = new Date(datetime).toLocaleDateString('en-us', {
                dateStyle: 'long',
            });
            return date;
        },
    },
};
</script>
