<script>
import { mapGetters, mapMutations } from 'vuex';
import { StartEffect, StopEffect } from '@/plugins/effect';
import Paginate from '@/components/shared/Paginate.vue';
import Hero from '@/components/herotosnake/Hero.vue';

const axios = require('@/store/axios.config').default;

export default {
    name: 'transformHistory',
    components: { Hero, Paginate },
    data() {
        return {
            listRate: [],
            isSelected: false,
            isOpened: false,
            listHeroes: [
                {
                    amount: '1/20',
                    name: 'FOXY',
                    path: 'hero1.png',
                    tokenID: '#1234',
                    summon: '1/50',
                },
                {
                    amount: '1/20',
                    name: 'Tygra',
                    path: 'hero2.png',
                    tokenID: '#1234',
                    summon: '1/50',
                },
                {
                    amount: '1/20',
                    name: 'dumbo',
                    path: 'hero3.png',
                    tokenID: '#1234',
                    summon: '1/50',
                },
                {
                    amount: '1/20',
                    name: 'MR. PI',
                    path: 'hero4.png',
                    tokenID: '#1234',
                    summon: '1/50',
                },
            ],
            settings: {
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 0,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            initialSlide: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
            filter: {
                tab: 'Legend',
                page: 1,
                limit: 3,
            },
            tableFields: [
                {
                    key: 'oldTokenId',
                    label: 'Hero',
                    sortable: false,
                    class: 'text-center align-middle min-100',
                },
                {
                    key: 'rare',
                    label: 'Rare',
                    sortable: false,
                    class: 'text-center align-middle',
                },
                {
                    key: 'skin',
                    label: 'Skin',
                    sortable: false,
                    class: 'text-center align-middle',
                },
                {
                    key: 'newTokenId',
                    label: 'Snake',
                    sortable: false,
                    class: 'text-center text-capitalize align-middle',
                },

                {
                    key: 'createdAt',
                    label: 'TIME',
                    class: 'text-center align-middle',
                },
                {
                    key: 'status',
                    label: 'Status',
                    class: 'text-center align-middle',
                },
            ],
            countBox: 0,
            listIdBox: [],
            snake: {},
            transformResult: {},
            search: {
                page: 1,
            },
        };
    },
    computed: {
        ...mapGetters({
            ListPicker: 'transform/ListPicker',
            ListIdPicker: 'transform/ListIdPicker',
            History: 'transform/History',
            ListPlatform: 'info/ListPlatform',
        }),
        hero() {
            if (this.ListPicker.length > 0 && this.ListPicker[0].timeOpen) {
                return {
                    name: this.ListPicker[0].type.Name,
                    tokenId: this.ListPicker[0].tokenId,
                    isBox: true,
                };
            }
            if (this.ListPicker.length > 0) {
                return this.ListPicker[0];
            }
            return null;
        },
        readyConnect() {
            let isConnect;
            this.ListPlatform.forEach((element) => {
                if (element.name.includes('Snake'))
                    isConnect = element.isConnected;
            });
            return isConnect;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        clearPick() {
            this.$store.commit('transform/RESET_PICKER');
            this.countBox = 0;
        },
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('transform/req_getListHistory', this.search);
        },
        onCloseReceiver() {
            this.$store.commit('transform/RESET_PICKER');
            this.Stop();
            this.onChangePage(1);
            this.listIdBox = [];
            this.countBox = 0;
            this.$store.dispatch('transform/req_getListHistory', this.search);
        },
        Start() {
            StartEffect();
        },
        Stop() {
            StopEffect();
        },
        showConfirm() {
            if (this.ListIdPicker.length <= 0) {
                this.$toastr.e('You must select at least 1 heroes to burn');
                return;
            }
            this.$bvModal.show('confirm-transform');
        },
        async onTransferHero() {
            // this.$toastr.e('Function is maintain', 'Oop!!!');
            // return;
            this.onLoad();

            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const dealWallet = '0x0000000000000000000000000000000000000002';
            const contracts = this.$store.state.contract.marketplace;
            const { version, tokenId } = this.ListPicker[0];

            let hero = null;
            Object.keys(contracts).forEach((key) => {
                if (key.includes('hero')) {
                  console.log(contracts[key],version);
                    if (
                        contracts[key].addr.toLowerCase() ===
                        version.toLowerCase()
                    ) {
                      console.log(contracts[key]);
                        hero = contracts[key];
                    }
                }
            });
            const txObj = await hero.cont.methods
                .transferFrom(addr, dealWallet, tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await hero.cont.methods
                    .transferFrom(addr, dealWallet, tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hero.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction(
                                txTransferData.transactionHash,
                            );
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },

        async sendTransaction(txTransactionHash) {
            const dataSwap = await axios.post('/api/hero/swap-hero-to-snake', {
                tokenId: this.ListIdPicker[0],
                transactionHash: txTransactionHash,
            });
            if (dataSwap.length <= 0) {
                this.outLoad();
                this.$bvModal.hide('confirm-transform');
                return;
            }
            this.transformResult = dataSwap;
            this.$bvModal.hide('confirm-transform');
            this.$bvModal.show('transform-received');
            this.Start();
            this.outLoad();
        },
        async getPricePredict() {
            const data = await axios.post(
                '/api/hero/swap-hero-to-snake/predict',
                {
                    tokenId: this.ListIdPicker[0],
                },
            );
            if (data) {
                this.snake = data;
            }
        },
    },

    created() {
        this.$store.dispatch('info/req_getListConnectSystems');
        this.$store.commit('transform/RESET_PICKER');
        this.$store.dispatch('transform/req_getListHistory', this.search);
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'transform/ADD_HERO':
                    this.getPricePredict();
                    this.$bvModal.hide('listHero-transform');
                    break;
                case 'transform/REMOVE_HERO':
                    this.$bvModal.hide('listHero-transform');
                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
<template>
    <b-container id="transform-hero" class="p-0">
        <div class="transform__title position-relative">
            <img
                src="~@/assets/images/frame/frame-large.png"
                class="transform__title-img d-block mx-auto"
            />
            <h3 class="transform__title-text">Transform Hero To Snake</h3>
        </div>
        <b-row class="align-items-center">
            <b-col cols="12">
                <div class="transform-box">
                    <div class="transform-box-container picking_hero">
                        <div class="transform-box-container-header">
                            <div class="text" v-if="hero">
                                {{ hero.name }} | {{ hero.tokenId }}
                            </div>
                            <div class="picked" v-if="hero">
                                <b-button @click="clearPick">
                                    <img
                                        src="~@/assets/images/mockup/swap/cancel.svg"
                                        alt=""
                                    />
                                </b-button>
                            </div>
                        </div>
                        <div class="transform-box-container-body">
                            <img
                                src="~@/assets/images/mockup/swap/bg.svg"
                                alt=""
                                class="background-transform"
                            />
                            <div class="picking" v-if="!hero">
                                <b-button
                                    @click="$bvModal.show('listHero-transform')"
                                >
                                    <img
                                        src="~@/assets/images/mockup/vac/plus.svg"
                                        alt=""
                                    />
                                </b-button>
                            </div>
                            <div
                                class="box-received hero"
                                v-else-if="hero.isBox"
                            >
                                <img
                                    :src="
                                        require(`@/assets/images/mockup/account/${hero.name.toLowerCase()}.png?v=1`)
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="box-received hero" v-else>
                                <img
                                    :src="
                                        getImageHero(
                                            '3d-raw',
                                            `${hero.name.toLowerCase()}-${hero.skin.toLowerCase()}`,
                                        )
                                    "
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="transform-box-button mt-2">
                        <div
                            class="d-flex justify-content-center"
                            v-if="readyConnect"
                        >
                            <b-button
                                class="transform-button"
                                @click="showConfirm"
                                :disabled="!hero"
                            >
                                <div class="transform-button-content">
                                    <img
                                        class="mr-1"
                                        src="~@/assets/images/icons/swap.svg"
                                        alt=""
                                    />
                                    Transform
                                </div>
                            </b-button>
                        </div>
                        <div class="d-flex justify-content-center" v-else>
                            <router-link
                                :to="{
                                    name: 'Setting',
                                    hash: '#ecosystem',
                                }"
                                class="transform-button d-flex"
                            >
                                <div class="transform-button-content">
                                    Connect Hero Snake
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="transform-box-container received">
                        <div class="transform-box-container-header">
                            <div class="text">
                                Rare: {{ snake.ratity || 'N/A' }} & Skin:
                                {{ snake.skin || 'N/A' }}
                            </div>
                        </div>
                        <div class="transform-box-container-body">
                            <img
                                src="~@/assets/images/mockup/swap/bg.svg"
                                alt=""
                                class="background-transform"
                            />
                            <div class="box-received">
                                <img
                                    v-if="!hero"
                                    src="~@/assets/images/icons/logoHSN.svg"
                                    alt=""
                                />
                                <img v-else :src="snake.image" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="12">
                <div class="transform__history mt-5">
                    <div class="transform__history__box__table">
                        <div
                            class="transform__history__box__table__heading mt-4"
                        >
                            <div class="text">History</div>
                        </div>
                        <div class="table-responsive table-transform">
                            <b-table
                                striped
                                hover
                                :items="History.list"
                                :fields="tableFields"
                                show-empty
                                thead-class="table-transform-header"
                            >
                                <template #empty>
                                    <h4 class="text-center my-3">No Data</h4>
                                </template>
                                <template #cell(rare)="data">
                                    {{
                                        data.item.item
                                            ? data.item.item.ratity
                                            : 'N/A'
                                    }}
                                </template>
                                <template #cell(skin)="data">
                                    {{
                                        data.item.item
                                            ? data.item.item.skin
                                            : 'N/A'
                                    }}
                                </template>
                                <template #cell(createdAt)="data">
                                    {{
                            Number.isInteger(data.item.createdAt)? getDateTime(
                                data.item.createdAt * 1000)
                                    : getDateTime3(data.item.createdAt),

                                    }}
                                </template>
                                <template #cell(status)="data">
                                    <span
                                        :class="
                                            data.item.status
                                                ? 'text-success'
                                                : 'text-danger'
                                        "
                                    >
                                        {{
                                            data.item.status
                                                ? 'Success'
                                                : 'Failed / Processing'
                                        }}
                                    </span>
                                </template>
                            </b-table>
                        </div>
                        <div class="table-paginate">
                            <Paginate
                                @current="onChangePage"
                                :totalPages="History.total"
                            />
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
            id="listHero-transform"
            :hide-footer="true"
            title="List Hero"
            :centered="true"
            body-class="p-1"
            content-class="border-0"
            size="xl"
        >
            <Hero />
        </b-modal>
        <b-modal id="confirm-transform" title="Confirm transform" hide-footer>
            <div v-if="this.hero">
                <p class="mb-0 text-confirm">
                    Do you want to Transform
                    {{ this.hero.isBox ? 'Box' : 'Hero' }} #{{
                        ListIdPicker[0]
                    }}
                    to Snake ,Rare:{{ snake.ratity || '' }} & Skin:
                    {{ snake.skin || '' }}?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="onTransferHero"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="transform-received"
            @hidden="onCloseReceiver"
            title="Snake Receiver"
            hide-footer
        >
            <div
                class="hero-snake"
                v-if="transformResult && transformResult.image"
            >
                <div class="hero-snake-backdrop"></div>
                <div class="hero-snake-avatar">
                    <img :src="transformResult.image" alt="" />
                </div>

                <div class="hero-snake-information">
                    <div class="hero-snake-information-header">
                        <div class="hero-snake-information-header-level">
                            N/A / N/A
                        </div>
                        <div class="hero-snake-information-header-name">
                            Snake
                        </div>
                    </div>
                    <div class="hero-snake-information-box">
                        <div class="header-information-left">Token ID</div>
                        <div class="header-information-right">
                           Processing
                        </div>
                    </div>
                    <div class="hero-snake-information-box">
                        <div class="header-information-left">Rare</div>
                        <div class="header-information-right">
                            #{{ transformResult.ratity }}
                        </div>
                    </div>
                    <div class="hero-snake-information-box">
                        <div class="header-information-left">Skin</div>
                        <div class="header-information-right">
                            #{{ transformResult.skin }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-3 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('transform-received')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>
<style lang="scss">
#transform-hero {
    .transform__title {
        width: 100%;
        max-width: 725px;
        margin: 0 auto 50px;
        &-text {
            font-family: 'HHBI';
            font-size: 42px;
            color: #00ffff;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: fit-content;
            height: fit-content;
            text-align: center;
            line-height: 100%;
        }
        img {
            width: 100%;
        }
        @media (max-width: 480px) {
            margin: 0 auto 15px;
            &-text {
                font-size: 30px;
            }
        }
    }
    .transform-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        &-container {
            max-width: 482px;
            position: relative;
            &-header {
                background: #00ffff2c;
                width: 286px;
                height: 46px;
                margin: 0 auto 10px;
                transform: skewX(-15deg);
                display: flex;
                align-items: center;
                justify-content: space-around;
                .text {
                    transform: skewX(15deg);
                    font-family: 'HHBI';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 33px;
                    /* or 183% */

                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    letter-spacing: 0.75px;

                    /* cyan */

                    color: #00ffff;
                }
            }
            .background-transform {
                width: 100%;
                height: auto;
            }
            .box-received {
                &.hero {
                    img {
                        max-height: 190px;
                        max-width: 200px;
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                    max-height: 250px;
                    max-width: 200px;
                    position: absolute;
                    top: 5%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 10;
                    margin: auto;
                    animation: infinite DownU 3s linear;
                    object-fit: scale-down;
                }
            }
            .picking {
                position: absolute;
                top: 15%;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                button {
                    padding: 0;
                    background: none !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border: 0 !important;
                    height: 84px;
                    width: 84px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .picked {
                // position: absolute;
                // left: 0;
                // right: 0;
                // bottom: 5%;
                margin-right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                transform: skewX(15deg);
                button {
                    padding: 0;
                    background: #0512207d !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border: 0 !important;
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .transform-button {
            &-content {
                position: relative;
                z-index: 10;
                font-family: 'hemiheadRg-BoldItalic';
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                /* or 144% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.75px;
                // color: #FE3F61;
                justify-content: center;
                img {
                    filter: invert(35%) sepia(131%) saturate(624%)
                        hue-rotate(147deg) brightness(100%) contrast(106%);
                }
                padding: 0 5px;
            }
            border: 1px solid #00ffff;
            background: transparent;
            box-shadow: unset;
            position: relative;
            width: 150px;
            height: 50px;
            border-radius: 0;
            color: #00ffff;
            &:after,
            &::before {
                z-index: 0;
                content: '';
                width: 5px;
                height: 5px;
                background: #00ffff;
            }

            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
            }
            &::before {
                position: absolute;
                bottom: 1px;
                right: 1px;
                -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: #000;

                &::before {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                    -webkit-transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                    transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                }

                img {
                    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(15deg)
                        brightness(22%) contrast(102%);
                }
            }
        }
        @media (max-width: 767px) {
            flex-wrap: wrap;
            .picking_hero {
                flex: 1 1 45%;
                max-width: 45%;
                order: 1;
            }

            .received {
                flex: 1 1 45%;
                max-width: 45%;
                order: 2;
            }
            .transform-box-button {
                order: 3;
                flex: 0 0 100%;
                // display: none;
            }
            &-container {
                &-header {
                    width: 100%;
                }
            }
        }
        @media (max-width: 575px) {
            &-container {
                &-header {
                    .text {
                        font-size: 15px;
                    }
                }
                .box-received {
                    &.hero {
                        img {
                            max-height: 110px;
                            max-width: 120px;
                        }
                    }
                }
            }
        }
        @media (max-width: 413px) {
            flex-direction: column;
            &-container {
                margin-bottom: 25px;
                &-header {
                    .text {
                        font-size: 15px;
                    }
                }
                .box-received {
                    &.hero {
                        img {
                            max-height: 110px;
                            max-width: 120px;
                        }
                    }
                }
            }
            .picking_hero,
            .received {
                flex: unset;
                width: 100%;
                max-width: 235px;
            }
            .received {
                margin-bottom: 5px;
            }
        }
    }
    .list__nft {
        margin: auto;
        margin-top: 40px;
        padding: 20px;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            rgba(53, 63, 72, 0.95);
        border: 0.5px solid #888888;
        border-radius: 16px;
    }
    @keyframes DownU {
        0%,
        50%,
        100% {
            transform: translateY(0);
        }
        25%,
        75% {
            transform: translateY(25px);
        }
    }
    .transform__history {
        &__box__table {
            margin-top: 60px;
            &__heading {
                display: inline-flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                margin: 0 0 15px;

                &::before {
                    content: '';
                    width: 40px;
                    flex: 0 0 40px;
                    height: 14px;
                    background: #00ffff;
                    margin-right: 15px;
                    margin-top: 7px;
                }
                .text {
                    flex: 1 1 auto;
                    margin-right: 10px;
                    font-family: 'HHBI';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    color: #f0f0f0;
                    &.borderStyle {
                        padding-bottom: 25px;
                        border-bottom: 1px solid #fff;
                    }
                }
            }
            .table-transform {
                table {
                    th,
                    tr,
                    td {
                        border: 0;
                        min-width: 100px;
                        &:first-child {
                            min-width: unset;
                            padding: 0;
                            text-align: center;
                            vertical-align: middle;
                        }
                    }
                    th {
                        font-family: 'Work Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.01em;
                        color: #f0f0f0;
                        padding: 24px;
                        position: relative;
                        text-align: center !important;
                        &::after {
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            width: 2px;
                            height: 16px;
                            content: '';
                            margin: auto;
                            background: #00ffff;
                        }
                        &:last-child {
                            &::after {
                                background: transparent;
                            }
                        }
                    }
                    tbody {
                        tr,
                        td {
                            font-family: 'Work Sans';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            /* or 150% */
                            vertical-align: middle;
                            letter-spacing: 0.01em;

                            /* GrayScale/Input BG */
                            padding-top: 15px;
                            padding-bottom: 15px;
                            padding-left: 25px;
                            color: #f0f0f0;
                        }
                        tr:nth-of-type(odd) {
                            background-color: rgba(0, 255, 255, 0.15);
                            border-radius: 1px;
                        }
                        tr {
                            &:hover {
                                background-color: rgba(0, 255, 255, 0.05);
                                border-radius: 1px;
                                color: #f0f0f0;
                            }
                        }
                    }
                }
            }
        }
    }
}
#transform-received___BV_modal_outer_ {
    z-index: unset !important;
}
#transform-received {
    .box {
        max-width: 300px;
        width: 100%;
        min-height: 350px;
        margin: 5px auto;
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                // max-height: 210px;
                // object-fit: cover;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            color: #fff;
        }
        .box-available {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0px 10px;
            text-align: center;
            color: #fff;
            span {
                color: #00ffff;
                font-weight: 600;
            }
        }
        .box-open {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border-radius: 7px;
            width: 120px;
            height: 40px;
            padding: 5px;
            button {
                color: #000;
                border-radius: 5px;
                font-weight: 600;
                border: 1.5px solid #fff;
                padding: 0;
                height: 100%;
                width: 100%;
                background: transparent;
                box-shadow: 0px 0px 5px #00ffff;
                text-transform: uppercase;
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            max-width: 210px;
        }
    }
}
</style>
