var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0"},[_c('div',{staticClass:"hero-list-summon"},[(1 > _vm.ListPicker.length)?_c('div',{staticClass:"box-picker mb-3"},_vm._l((_vm.tabPicker),function(tab){return _c('div',{key:("name-" + (tab.name)),staticClass:"box-picker-item",class:{ active: _vm.params.ratity === tab.value },on:{"click":function($event){_vm.params.ratity = tab.value}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),0):_vm._e(),(_vm.ListHero.list.length <= 0)?_c('div',{staticClass:"box-name"},[_c('div',{staticClass:"after"},[_vm._v("No Hero")])]):_vm._e(),_c('carousel',{key:("carousel-hero-receiver-" + (_vm.ListHero.list.length) + "-" + (_vm.ListPicker.length)),ref:"carousel",attrs:{"perPage":4,"navigationEnabled":true,"paginationEnabled":false,"centerMode":true,"perPageCustom":[
                [320, 1],
                [600, 2],
                [1024, 3],
                [1200, 4],
                [1800, 4] ],"navigation-next-label":"","navigation-prev-label":""}},_vm._l((_vm.ListHero.list),function(hero){return _c('slide',{key:("list-hero-inventory-" + (hero.tokenId))},[_c('div',{staticClass:"hero"},[_c('div',{staticClass:"hero-backdrop"}),_c('div',{staticClass:"hero-avatar"},[_c('img',{attrs:{"src":_vm.getImageHero(
                                    '3d-raw',
                                    ((hero.name.toLowerCase()) + "-" + (hero.skin.toLowerCase()))
                                ),"alt":""}})]),_c('div',{staticClass:"hero-information"},[_c('div',{staticClass:"hero-information-header"},[_c('div',{staticClass:"hero-information-header-level"},[_vm._v(" "+_vm._s(10 > hero.level ? '0' + hero.level.toString() : hero.level)+" / 20 ")]),_c('div',{staticClass:"hero-information-header-name"},[_vm._v(" "+_vm._s(hero.name)+" ")])]),_c('div',{staticClass:"hero-information-box"},[_c('div',{staticClass:"header-information-left"},[_vm._v(" Token ID ")]),_c('div',{staticClass:"header-information-right"},[_vm._v(" #"+_vm._s(hero.tokenId)+" ")])]),_c('div',{staticClass:"hero-information-box"},[_c('div',{staticClass:"header-information-left"},[_vm._v(" Summon ")]),_c('div',{staticClass:"header-information-right"},[_vm._v(" "+_vm._s(hero.quantitySummon)+" ")])]),_c('div',{staticClass:"hero-information-button"},[(_vm.showRemoveHero(hero.tokenId))?_c('b-button',{staticClass:"pick-button remove",on:{"click":function($event){return _vm.onRemoveHero(hero)}}},[_c('div',{staticClass:"pick-button-content"},[_vm._v(" Remove ")])]):_c('b-button',{staticClass:"pick-button",on:{"click":function($event){return _vm.onChoseHero(hero)}}},[_c('div',{staticClass:"pick-button-content"},[_vm._v(" Add Summon ")])])],1)])])])}),1)],1),_c('div',{staticClass:"d-flex justify-content-center mt-4 text-white"},[_vm._v(" Slide Available "),_c('span',{staticClass:"text-success px-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.params.limit * (_vm.params.page - 1))+" - "+_vm._s(_vm.params.limit * (_vm.params.page - 1) + _vm.params.limit)+" ")]),_vm._v(" of "),_c('span',{staticClass:"text-success px-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.ListHero.totalHero)+" ")]),_vm._v(" Hero ")]),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('Paginate',{attrs:{"totalPages":_vm.ListHero.total},on:{"current":_vm.onChangePage}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }