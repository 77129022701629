import { render, staticRenderFns } from "./Setting.vue?vue&type=template&id=1094a0a1&scoped=true&"
import script from "./Setting.vue?vue&type=script&lang=js&"
export * from "./Setting.vue?vue&type=script&lang=js&"
import style0 from "./Setting.vue?vue&type=style&index=0&id=1094a0a1&prod&lang=scss&scoped=true&"
import style1 from "./Setting.vue?vue&type=style&index=1&id=1094a0a1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1094a0a1",
  null
  
)

export default component.exports