const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        list_hero_selling: {
            results: [],
        },
        list_land_selling: {
            results: [],
        },
        list_box_selling: {
            results: [],
        },
        detailHero: {},
        sortList: [],
        sortBoxList: [],
    }),
    getters: {
        ListHeroSelling: (state) => state.list_hero_selling,
        ListLandSelling: (state) => state.list_land_selling,
        ListBoxSelling: (state) => state.list_box_selling,
        DetailHero: (state) => state.detailHero,
        SortList: (state) => state.sortList,
        SortBoxList: (state) => state.sortBoxList,
    },
    actions: {
        req_getListSortHero: ({ commit }, input) => {
            axios
                .get(`api/market/dashboard/recently-list/get-sort`, {
                    params: input,
                })
                .then((response) => {
                    commit('SET_SORT_HERO', response);
                });
        },
        req_getListHeroSelling: ({ commit }, input) => {
            axios
                .get(`api/market/dashboard/recently-list/${input.type}`, {
                    params: input,
                })
                .then((response) => {
                    commit('SET_HERO_SELLING', response);
                });
        },
        req_getListLandSelling: ({ commit }, input) => {
            axios
                .get(`api/market/dashboard/recently-list/${input.type}`, {
                    params: input,
                })
                .then((response) => {
                    commit('SET_LAND_SELLING', response);
                });
        },
        req_getListBoxSelling: ({ commit }, input) => {
            axios
                .get(`api/market/dashboard/recently-list/${input.type}`, {
                    params: input,
                })
                .then((response) => {
                    commit('SET_BOX_SELLING', response);
                });
        },
        req_getDetailHero: ({ commit }, input) => {
            axios
                .get(
                    `api/market/dashboard/detail/${input.type}/${input.tokenId}`,
                )
                .then((response) => {
                    commit('SET_DETAIL_HERO', response);
                });
        },
        req_getListSortBox: ({ commit }, input) => {
            axios
                .get(`api/market/dashboard/recently-list/get-sort-box`, {
                    params: input,
                })
                .then((response) => {
                    commit('SET_SORT_BOX', response);
                });
        },
    },
    mutations: {
        SET_SORT_HERO(state, data) {
            const arrSort = [];
            for (let i = 0; i < data.length; i += 1) {
                Object.entries(data[i]).forEach(([key, value]) => {
                    arrSort.push({
                        value: key,
                        text: value,
                    });
                });
            }
            arrSort.unshift({
                value: null,
                text: 'No Sort',
            });
            state.sortList = arrSort;
        },
        SET_HERO_SELLING(state, data) {
            state.list_hero_selling = data;
        },
        SET_LAND_SELLING(state, data) {
            state.list_land_selling = data;
        },
        SET_BOX_SELLING(state, data) {
            state.list_box_selling = data;
        },
        SET_DETAIL_HERO(state, data) {
            state.detailHero = data;
        },
        SET_SORT_BOX(state, data) {
            const arrSort = [];
            for (let i = 0; i < data.length; i += 1) {
                Object.entries(data[i]).forEach(([key, value]) => {
                    arrSort.push({
                        value: key,
                        text: value,
                    });
                });
            }
            arrSort.unshift({
                value: null,
                text: 'No Sort',
            });
            state.sortBoxList = arrSort;
        },
    },
};
