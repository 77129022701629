<template>
    <div class="accordion faq-box" role="tablist">
        <b-card
            no-body
            class="mb-1"
            v-for="(qa, idx) in listFAQ"
            :key="qa.content"
        >
            <b-card-header header-tag="header" role="tab">
                <b-button
                    block
                    v-b-toggle
                    :href="`#FAQ-${idx}`"
                    variant="success"
                    class="text-left fq-title"
                    @click.prevent
                >
                    {{ idx + 1 }}, {{ qa.title }}
                </b-button>
            </b-card-header>
            <b-collapse
                :id="`FAQ-${idx}`"
                visible
                accordion="my-accordion"
                role="tabpanel"
            >
                <b-card-body>
                    <p class="content-title">
                        {{ qa.content }}
                    </p>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            listFAQ: [
                {
                    title: 'What promotions can I participate in?',
                    content:
                        'The promotions in the ongoing section (happenings) are the programs you can participate in',
                },
                {
                    title: 'How can I participate in the promotion?',
                    content:
                        'You can participate in promotions with the hero lock required by the promotion',
                },
                {
                    title: 'How do I know that I have successfully participated in the promotion?',
                    content:
                        'When you lock the hero we will notify you that the hero has been successfully locked, when you have locked enough heroes you will see a text thank you for participating in the promotion.',
                },
                {
                    title: "What if I'm locked but can't join?",
                    content:
                        'There may be a data synchronization problem between herobook and smart chain, so it leads to the above situation, please go to the support section in my account and write a ticket, we will support you in 8 hours from the time you submit it.',
                },
                {
                    title: 'What happens when my hero is locked?',
                    content:
                        "You can still use summoning, farming, and playing games while your hero is locked, functions that cause a change in your hero's ownership will not be available.",
                },
                {
                    title: 'How many promotions can I participate in with one hero?',
                    content:
                        'Each hero can participate in unlimited promotions but the time the hero is locked must be as long as the hero is alive',
                },
                {
                    title: 'How long will each of my heroes be locked?',
                    content:
                        'Heroes will be locked for the period specified by the event',
                },
                {
                    title: "What happens if I don't lock enough heroes to participate in the promotion?",
                    content:
                        "In the above case, the heroes locked for the event will be locked until the end of the event even if you don't lock enough heroes",
                },
                {
                    title: 'What errors can I get when locking the hero?',
                    content:
                        "BEP721: transfer caller is not owner for approval: please contact support, this error can happen when you transfer hero to our locked wallet but the system hasn't recognized it yet",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.accordion {
    .card,
    .card * {
        border: 0;
    }
    .card-header {
        padding: 0;
    }
    .card-body {
        background: linear-gradient(0deg, #02ffff -60%, #001326 100%);
        color: #fff;
        font-size: 16px;
    }
}
.fq-title {
    background: cyan;
    color: black;
    text-transform: capitalize;
    font-weight: 800;
    font-size: 18px;
}
.faq-box {
    .content-title {
        font-size: 16px;
        text-align: initial;
        line-height: 120%;
        font-weight: 600;
        margin-bottom: 15px;
        letter-spacing: 1px;
    }
    .content-detail {
        font-size: clamp(14px, 3vw, 16px);
        text-align: initial;
        line-height: 120%;
        font-weight: 600;
        text-align: initial;
        margin-bottom: 10px;
        color: #333;
        span {
            font-weight: 700;
            color: #333;
        }
    }
}
</style>
