const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        list_box_store: {},
        list_box_user: {},
        statistical: {
            quantityBox: 0,
            price: 0,
        },
        list_heronium: {},
        list_ticket: {
            results: [],
        },
        list_TRex: {},
    }),
    getters: {
        ListBoxStore: (state) => state.list_box_store,
        ListBoxUser: (state) => state.list_box_user,
        Statistical: (state) => state.statistical,
        ListHeronium: (state) => state.list_heronium,
        ListTicket: (state) => state.list_ticket,
        ListTRex: (state) => state.list_TRex,
    },
    actions: {
        req_getBoxStore: ({ commit }) => {
            axios.get('api/box/all-box').then((response) => {
                commit('SET_BOX_STORE', response);
            });
        },
        req_getBoxUser: ({ commit }) => {
            axios.post('api/box/user-box').then((response) => {
                commit('SET_BOX_USER', response);
            });
        },
        req_postOpenBoxUser: ({ commit }, input) => {
            axios.post('api/box/hero', input).then((response) => {
                commit('OPEN_BOX_SUCCESS', response);
            });
        },
        req_postClaimToken: ({ dispatch }, input) => {
            axios.post('api/presale/claim', input).then(() => {
                dispatch('req_getBuyHistory');
                dispatch('req_getInfoPreSales');
            });
        },
        req_getStoreStatistical: ({ commit }, input) => {
            axios.get('api/box/price-total', input).then((res) => {
                commit('SET_STATISTICAL', res);
            });
        },
        req_postBuyBox: ({ commit }, input) => {
            axios.post('api/box/send-transaction/multi', input).then(() => {
                commit('BUY_SUCCESS');
            });
        },
        req_getHeronium: ({ commit }) => {
            axios.get('api/shop/heronium').then((response) => {
                commit('SET_HERONIUM', response);
            });
        },
        req_getListTicket: ({ commit }) => {
            axios.get('api/shop/game-ticket').then((response) => {
                if (response) {
                    commit('SET_LIST_TICKET', response);
                }
            });
        },
        req_postBuyTicket: ({ commit, dispatch }, input) => {
            axios.post('api/shop/game-ticket/buy', input).then(() => {
                commit('BUY_SUCCESS');
                dispatch('req_getListTicket');
            });
        },
        req_getTRex: ({ commit }) => {
            axios.get('/api/shop/t-rex').then((response) => {
                commit('SET_TREX', response);
            });
        },
    },
    mutations: {
        SET_BOX_STORE(state, data) {
            state.list_box_store = data;
        },
        SET_BOX_USER(state, data) {
            state.list_box_user = data;
        },
        OPEN_BOX_SUCCESS() {},
        SET_STATISTICAL(state, data) {
            const { quantityBox, price } = data;
            state.statistical = {
                quantityBox,
                price,
            };
        },
        BUY_SUCCESS() {},
        SET_HERONIUM(state, data) {
            state.list_heronium = data;
        },
        SET_LIST_TICKET(state, data) {
            state.list_ticket = data;
        },
        SET_TREX(state, data) {
            state.list_TRex = data;
        },
    },
};
