<template>
    <div class="">
        <countdown :end-time="convertsTime">
            <template v-slot:process="{ timeObj }">
                <div
                    class="process position-absolute"
                    :style="`width: ${getPercent(
                        timeObj.leftTime,
                        timeObj.endTime,
                    )}%!important`"
                ></div>
                <p class="mb-0 text-center">
                    <!-- {{ timeObj }} -->
                    {{
                        `${transform(timeObj.d)} Days- ${timeObj.h}:${
                            timeObj.m
                        }:${timeObj.s}`
                    }}
                </p>
            </template>
            <template v-slot:finish>
                <div
                    class="process position-absolute done"
                    style="width: 100%"
                ></div>
                <p class="mb-0 text-center">Done</p>
            </template>
        </countdown>
    </div>
</template>

<script>
// import moment from 'moment';

export default {
    props: {
        time: {
            type: String,
            default: '',
        },
    },
    computed: {
        convertsTime() {
            const time = Date.parse(this.time);
            return time;
        },
    },
    methods: {
        transform(props) {
            if (props < 10) {
                return `0${props}`;
            }
            return props;
        },
        // time hien tai
        // time end --- tong
        // time con lai
        // eslint-disable-next-line no-unused-vars
        getPercent(lefTime, endTime) {
            const percent = (lefTime/1000 / (7 * 86400)) * 100;
            return 100 - percent;
        },
    },
};
</script>

<style></style>
