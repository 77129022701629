<template>
    <div class="HeroBook bg-main-container">
        <div class="container-effect2">
            <div id="stars20"></div>
            <div id="stars21"></div>
            <div id="stars22"></div>
            <div id="stars32"></div>
        </div>
        <Header v-if="false"/>
        <NewHeader />
        <loader />
        <main class="content">
            <router-view></router-view>
        </main>
        <b-modal
            id="ReActiveUser"
            title="User Active Address"
            hide-footer
            no-close-on-backdrop
        >
            <ActiveUnKnow class="mx-auto" />
        </b-modal>
        <Footer />
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import Web3 from 'web3';
import { Header } from '@/components/Layouts';
import loader from '@/components/shared/Loader.vue';
import ActiveUnKnow from '@/components/auth/UnKnowActive.vue';
import Footer from '../../components/Layouts/Footer.vue'
import NewHeader from '../../components/Layouts/HeaderNew.vue'

export default {
    components: { Header, loader, ActiveUnKnow, Footer, NewHeader },
    computed: {
        ...mapGetters({
            isNetWork: 'contract/isNetWork',
            UnKnown: 'info/UnKnown',
        }),
        ChainID() {
            if (this.isNetWork === 'testnet') {
                return 97;
            }
            return 56;
        },
        netWorkConfig() {
            const testNet = {
                chainId: '0x61',
                chainName: 'Smart Chain - Testnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://data-seed-prebsc-2-s2.binance.org:8545/',
                    'https://data-seed-prebsc-1-s1.binance.org:8545/',
                    'https://data-seed-prebsc-5-s5.binance.org:8545/',
                    'https://data-seed-prebsc-3-s3.binance.org:8545/',
                    'https://data-seed-prebsc-4-s4.binance.org:8545/',
                ],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
            };
            const mainNet = {
                chainId: '0x38',
                chainName: 'Smart Chain',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/',
                    'https://bsc-dataseed1.defibit.io/',
                    'https://bsc-dataseed1.ninicoin.io/',
                ],
                blockExplorerUrls: ['https://bscscan.com'],
            };
            if (this.isNetWork === 'testnet') {
                return testNet;
            }
            return mainNet;
        },
    },
    watch: {
        UnKnown: {
            handler(newVal) {
                if (newVal) {
                    this.$bvModal.show('ReActiveUser');
                } else {
                    this.$bvModal.hide('ReActiveUser');
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        async checkWallet() {
            // return;
            this.onLoad();
            if (!window.ethereum) {
                this.outLoad();
                this.$toastr.e('Please Install Metamask To Join', 'Oops!');
                return false;
            }
            let addrs = [];
            try {
                addrs = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }

            if (window.ethereum.chainId !== this.ChainID) {
                const params = this.netWorkConfig;
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    })
                    .then(() => console.log('Success'))
                    .catch(() => {
                        this.showNotification = true;
                        this.message = {
                            content:
                                'Please switch To The  Binance Smartchain Network!',
                            failed: true,
                            title: 'Oops...',
                        };
                        window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: this.ChainID }],
                        });
                    });
            }

            if (addrs.length > 0) {
                this.$store.commit(
                    'wallet/setupWallet',
                    Web3.utils.toChecksumAddress(addrs[0]),
                );
            }
            this.outLoad();
            return addrs;
        },
        setup(address) {
            this.$store.commit('wallet/clearWallet');
            this.$store.commit(
                'wallet/updateWallet',
                Web3.utils.toChecksumAddress(address),
            );
        },
    },
    async mounted() {
        const web3 = await this.$store.state.contract.web3;
        if (web3) {
            this.$store.commit('contract/setupTokens');
            this.$store.commit('contract/setupManagers');
            this.$store.commit('wallet/updateWallet');
            this.$store.commit('wallet/getPriceHBG');
        }
        this.$store.watch(
            (state) => state.info.info,
            (user) => {
                if (user.unknownUser) {
                    this.$bvModal.show('ReActiveUser');
                } else {
                    this.setup(user.address);
                }
            },
        );
    },
    async created() {
        if (window.ethereum) {
            // eslint-disable-next-line no-unused-vars
            window.ethereum.on('accountsChanged', async (accounts) => {
                window.location.reload();
                this.$store.commit('auth/LOGOUT_SUCCESS');
            });
            await this.checkWallet();
            // eslint-disable-next-line no-unused-vars
            window.ethereum.on('chainChanged', async (chainId) => {
                window.location.reload();
                this.$store.commit('auth/LOGOUT_SUCCESS');
            });
        }
        // document.body.addEventListener('scroll', this.onScroll);
    },
    // destroyed() {
    //     document.body.removeEventListener('scroll', this.onScroll);
    // },
};
</script>

<style lang="scss">
.HeroBook.bg-main-container {
    background-image: url('~@/assets/images/background/global.svg'),
        linear-gradient(45deg, #00112c, #212323, #00112c);
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: center center;
    // min-height: 100vh;
    // width: 100%;
}
main.content {
    min-height: calc(100vh - 90px);
    height: 100%;
    padding: 20px 0;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    @media (max-width: 991px) {
        min-height: calc(100vh - 60px);
    }
    @media (max-width: 575px) {
        min-height: calc(100vh);
        padding-bottom: 80px;
    }
}
</style>
