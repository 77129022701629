var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"paginate-2"},[_c('b-row',{staticClass:"align-items-center justify-content-center"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"info-paginate"},[_vm._v(" Showing "),_c('span',[_vm._v(" "+_vm._s(_vm.perPage * (_vm.currentPage - 1) + 1))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm.perPage * (_vm.currentPage - 1) + _vm.perPage))]),_vm._v(" of "),_c('span',[_vm._v(" "+_vm._s(_vm.totalResult)+" ")])])]),_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('div',{staticClass:"paginate"},[_c('button',{staticClass:"paginate prev",attrs:{"disabled":_vm.currentPage <= 1},on:{"click":function($event){_vm.currentPage--}}},[_c('img',{attrs:{"src":require("@/assets/images/mockup/market/new/prev.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("prev")])]),_vm._l((_vm.range(0, _vm.totalPages).slice(
                        _vm.totalPages - _vm.currentPage < 4 &&
                            _vm.totalPages - _vm.currentPage > 5
                            ? _vm.totalPages - 4
                            : _vm.currentPage > 1
                            ? _vm.currentPage - 1
                            : 1,
                        _vm.currentPage <= 1
                            ? _vm.currentPage + 5
                            : _vm.currentPage + 4
                    )),function(pageIndex){return _c('button',{key:("current-page-" + pageIndex),staticClass:"paginate",class:{ active: pageIndex == _vm.currentPage },on:{"click":function($event){_vm.currentPage = pageIndex}}},[_c('div',{staticClass:"content-paginate"},[_vm._v(" "+_vm._s(pageIndex)+" ")])])}),_c('button',{staticClass:"paginate next",attrs:{"disabled":_vm.currentPage >= _vm.totalPages},on:{"click":function($event){_vm.currentPage++}}},[_c('div',{staticClass:"text"},[_vm._v("next")]),_c('img',{attrs:{"src":require("@/assets/images/mockup/market/new/next.png"),"alt":""}})])],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }