<template>
    <div class="item-buy-box">
        <div class="item-buy-box-name">
            <div class="hero-info left">
                <div class="hero-info-avatar">
                    <img :src="item.image" alt="" />
                </div>
                <div class="hero-info-text">
                    <div
                        class="hero-info-text-name"
                        :class="`${item.type.toLowerCase()}-color`"
                    >
                        {{ item.itemName }}
                    </div>
                    <div class="hero-info-text-platform">
                        {{ item.platform }}
                    </div>
                </div>
            </div>
        </div>
        <div class="item-buy-box-quantity">
            <span v-if="showSeller"> Seller: </span>{{ AddressShow }}
        </div>
        <div class="item-buy-box-price">
            <div class="hero-info">
                <div class="hero-info-text2">
                    <div class="hero-info-text2-price">Price:</div>
                    <div class="hero-info-text2-name">
                        ~
                        {{
                            TruncateToDecimals2(
                                item.price * Balance.price || 0,
                                '',
                                4,
                            )
                        }}
                        HBG
                    </div>
                    <div class="hero-info-text2-platform">
                        {{ item.price }} USD
                    </div>
                </div>
            </div>
        </div>
        <div class="item-buy-box-newly" v-if="item.buyer">
            <b-button class="sell-item-button cancel sold">
                <div class="sell-item-button-content">Sold</div>
            </b-button>
        </div>
        <div class="item-buy-box-newly" v-else-if="UserInfo && UserInfo.ID">
            <b-button class="sell-item-button newly" @click="getDetailBox()">
                <div class="sell-item-button-content newly">Buy now</div>
            </b-button>
        </div>
        <div class="item-buy-box-newly" v-else></div>
        <!-- confirm buy -->
        <b-modal :id="`confirm-buy-${ItemID}`" hide-footer hide-header>
            <div class="market-modal-title">
                <div class="market-modal-title:text">
                    Do you want to buy this box ?
                </div>
                <a
                    class="market-modal-title:close"
                    @click="$bvModal.hide(`confirm-buy-${ItemID}`)"
                >
                    X
                </a>
            </div>
            <div class="market-modal-body">
                <div class="market-modal-body:image">
                    <img :src="item.image" alt="" />
                </div>
                <div class="market-modal-body-content">
                    <div
                        class="market-modal-body-content:token detail-item-market"
                    >
                        <div class="name">Token ID:</div>
                        <div class="value">NFT-{{ item.tokenId }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Name:</div>
                        <div class="value">{{ item.itemName }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:owner detail-item-market"
                    >
                        <div class="name">Owner:</div>
                        <div class="value">{{ AddressShow }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-usd detail-item-market"
                    >
                        <div class="name">Price USD:</div>
                        <div class="value">{{ item.price }} USD</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-hbg detail-item-market"
                    >
                        <div class="name">Price HBG:</div>
                        <div class="value">
                            ~
                            {{
                                TruncateToDecimals2(
                                    item.price * Balance.price || 0,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                    </div>
                </div>
            </div>
            <div class="market-modal-footer">
                <b-button
                    class="market-modal-footer:cancel"
                    @click="$bvModal.hide(`confirm-buy-${ItemID}`)"
                >
                    <div class="button-content">Cancel</div>
                </b-button>
                <b-button class="market-modal-footer:confirm" @click="onBuyBox">
                    <div class="button-content">Confirm Buy</div>
                </b-button>
            </div>
        </b-modal>
        <!-- end confirm buy -->
        <!-- result -->
        <b-modal
            :id="`show-received-${ItemID}`"
            hide-footer
            hide-header
            @hidden="onReloadAPI"
        >
            <div class="market-modal-title">
                <div class="market-modal-title:text">
                    You bought the box successfully. Congratulation!
                </div>
                <a
                    class="market-modal-title:close"
                    @click="$bvModal.hide(`confirm-buy-${ItemID}`)"
                >
                    X
                </a>
            </div>
            <div class="market-modal-body">
                <div class="market-modal-body:image">
                    <img :src="item.image" alt="" />
                </div>
                <div class="market-modal-body-content">
                    <div
                        class="market-modal-body-content:token detail-item-market"
                    >
                        <div class="name">Token ID:</div>
                        <div class="value">NFT-{{ item.tokenId }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Name:</div>
                        <div class="value">{{ item.itemName }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-usd detail-item-market"
                    >
                        <div class="name">Price USD:</div>
                        <div class="value">{{ item.price }} USD</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-hbg detail-item-market"
                    >
                        <div class="name">Price HBG:</div>
                        <div class="value">
                            ~
                            {{
                                TruncateToDecimals2(
                                    item.price * Balance.price || 0,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                    </div>
                </div>
            </div>
            <div class="market-modal-footer">
                <b-button
                    class="market-modal-footer:confirm"
                    @click="$bvModal.hide(`show-received-${ItemID}`)"
                >
                    <div class="button-content">Confirm</div>
                </b-button>
            </div>
        </b-modal>
        <!-- end results -->
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

const axios = require('@/store/axios.config').default;

export default {
    props: {
        item: {
            type: Object,
            default: null,
        },
        showSeller: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            approve: false,
            showBuyNow: false,
            DetailBox: {},
            receivedHero: {},
        };
    },
    computed: {
        ...mapGetters({
            Balance: 'wallet/Balance',
            UserInfo: 'info/UserInfo',
        }),
        AddressShow() {
            const { ownerAddress } = this.item;
            if (!ownerAddress) return null;
            const stringShort = `${ownerAddress.slice(
                0,
                7,
            )}...${ownerAddress.slice(
                ownerAddress.length - 3,
                ownerAddress.length,
            )}`;
            return stringShort;
        },
        ItemID() {
            // eslint-disable-next-line no-underscore-dangle
            return this.item._id;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        async onBuyBox() {
            this.onLoad();
            const { tokenId, price, box } = this.DetailBox;
            const { version } = box;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const decimalPrice = web3.provider.utils.toWei(
                price.toString(),
                'ether',
            );
            const txObj = await marketNew.cont.methods
                .buy(tokenId, version, decimalPrice)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();

            try {
                gas = await marketNew.cont.methods
                    .buy(tokenId, version, decimalPrice)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;

                            this.sendTransaction('buy', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                            this.outLoad();
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
            return false;
        },
        async getDetailBox() {
            const detail = await axios.get(
                `api/market/dashboard/detail/box/${this.item.tokenId}`,
            );
            if (
                !detail ||
                detail.buyer !== null ||
                detail.isCancel ||
                detail.box == null
            ) {
                if (!detail || detail.isCancel) {
                    this.$toastr.e('Box has been bought or sold', 'Oops!');
                }
                this.$bvModal.hide(`confirm-buy-${this.item}`);
                this.onReloadAPI();
                this.outLoad();
                return;
            }
            this.DetailBox = detail;
            this.$bvModal.show(`confirm-buy-${this.ItemID}`);
        },

        async sendTransaction(type, txData) {
            const hero = await axios.post(
                'api/market/order/box/buy/send-transaction',
                {
                    transactionHash: txData.transactionHash,
                },
            );
            this.outLoad();
            this.receivedHero = hero;
            this.$store.commit('wallet/updateWallet');
            this.$bvModal.hide(`confirm-buy-${this.ItemID}`);
            this.$bvModal.show(`show-received-${this.ItemID}`);
        },
        onReloadAPI() {
            this.$emit('onReloadList', true);
        },
    },
};
</script>

<style lang="scss">
.item-buy-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    background: rgba(53, 63, 72, 0.2);
    padding: 5px 15px;

    &.odd {
        background: rgba(53, 63, 72, 0.4);
    }
    &-name {
        flex: 1 1 auto;
        max-width: 275px;
        min-width: 275px;
        @media (max-width: 767px) {
            min-width: 175px;
            max-width: 180px;
        }
    }
    &-quantity {
        flex: 0 0 100px;
        color: #fcfcfc;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        @media (max-width: 575px) {
            display: none;
        }
    }
    &-newly {
        flex: 0 0 110px;

        .sell-item-button.newly {
            width: 100px;
            border-radius: 15px;
            height: 30px;
            .sell-item-button-content {
                border-radius: 15px;
                font-size: 14px;
            }
        }
    }
    &-price {
        flex: 1 1 auto;
    }
    .hero-info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &.left {
            justify-content: flex-start;
            flex: 1 1 auto;
            width: 100%;
        }
        &-avatar {
            width: 48px;
            flex: 0 0 48px;
            height: 48px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
            /* GrayScale/Label */

            border: 0.5px solid #555555;
            border-radius: 8px;
            padding: 5px;
            img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
            margin-right: 10px;
        }
        &-text {
            text-align: left;
            flex: 1 1 auto;
            max-width: calc(100% - 50px);
            padding-right: 10px;
            &-name {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                max-width: 90%;
                text-overflow: ellipsis;
            }
            &-platform {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #fcfcfc9f;
            }
        }
        &-text2 {
            text-align: center;
            .small {
                font-size: 14px;
            }
            &-name {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #0fff;
                letter-spacing: 2px;
            }

            &-platform {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
                letter-spacing: 2px;
            }
            &-price {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                font-family: 'HHBI';
                color: #fcfcfc;
                letter-spacing: 2px;
                display: none;
            }
        }
    }
    @media (max-width: 480px) {
        flex-wrap: wrap;
        &-newly {
            width: 50%;
            flex: 0 0 50%;
            margin-top: 5px;
            display: flex;
            justify-content: center;
            order: 4;
        }
        &-quantity {
            width: 50%;
            flex: 0 0 50%;
            margin-top: 5px;
            display: flex;
            order: 3;
            span {
                display: block;
                margin-right: 5px;
            }
        }
        &-price {
            width: 50%;
            flex: 0 0 50%;
        }
    }
    @media (max-width: 413px) {
        flex-wrap: wrap;
        &-name,
        &-price {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .hero-info {
                &-text2 {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin: 10px 0;
                    &-price {
                        display: flex;
                        flex: 1 1 auto;
                    }
                    &-name {
                        flex: 1 1 auto;
                    }
                    &-platform {
                        flex: 1 1 auto;
                    }
                }
            }
        }
    }
}
</style>
