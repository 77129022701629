const axios = require('../axios.config').default;
// /api/ticket/list
export default {
    namespaced: true,
    state: () => ({
        listTicket: {
            list: [],
            current: 1,
            total: 1,
        },
        listTitle: [],
    }),
    getters: {
        ListTicket: (state) => state.listTicket,
        ListTitle: (state) => state.listTitle,
        DetailTicket: (state) => state.detailTicket,
    },
    actions: {
        req_getListTicket: ({ commit }, input) => {
            const page = (input && input.page) ? input.page : 1
            axios.get(`api/ticket/list?page=${page}`).then((response) => {
                commit('GET_LIST_TICKET_SUCCESS', response.tickets);
                commit('GET_LIST_TITLE_SUCCESS', response.title);
            });
        },
        req_postCreateTicket: ({dispatch}, input) => {
            axios.post('api/ticket/create', input).then(() => {
                dispatch('req_getListTicket')
            })
        },
    },
    mutations: {
        GET_LIST_TICKET_SUCCESS(state, data) {
            const { results, page, totalPages } = data;
            state.listTicket = {
                list: results,
                current: page,
                total: totalPages,
            };
        },
        GET_LIST_TITLE_SUCCESS(state, data) {
            state.listTitle = data
        },
    },
};