<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
    props: {
        isBuy: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            isNetWork: 'contract/isNetWork',
        }),
        ChainID() {
            if (this.isNetWork === 'testnet') {
                return 97;
            }
            return 56;
        },
        netWorkConfig() {
            const testNet = {
                chainId: '0x61',
                chainName: 'Smart Chain - Testnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://data-seed-prebsc-2-s2.binance.org:8545/',
                    'https://data-seed-prebsc-1-s1.binance.org:8545/',
                    'https://data-seed-prebsc-5-s5.binance.org:8545/',
                    'https://data-seed-prebsc-3-s3.binance.org:8545/',
                    'https://data-seed-prebsc-4-s4.binance.org:8545/',
                ],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
            };
            const mainNet = {
                chainId: '0x38',
                chainName: 'Smart Chain',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/',
                    'https://bsc-dataseed1.defibit.io/',
                    'https://bsc-dataseed1.ninicoin.io/',
                ],
                blockExplorerUrls: ['https://bscscan.com'],
            };
            if (this.isNetWork === 'testnet') {
                return testNet;
            }
            return mainNet;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        async onConnectMetaMask() {
            let address = [];
            try {
                address = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }
            if (window.ethereum.chainId !== this.ChainID) {
                const params = this.netWorkConfig;
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    })
                    .then(() => console.log('add network success'))
                    .catch(() => {
                        this.showNotification = true;
                        this.message = {
                            content:
                                'Please switch To The  Binance Smartchain Network!',
                            failed: true,
                            title: 'Oops...',
                        };
                        window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: this.ChainID }],
                        });
                    });
            }
            if (address.length < 0) {
                return false;
            }
            this.$store.commit('info/SET_ADDRESS', {
                address: address[0],
            });
            this.$store.dispatch('auth/getSignature', {
                wallet: address[0],
            });
            return true;
        },
        async onLogin({ address, signature }) {
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [address, signature],
            });
            this.$store.dispatch('auth/LoginAddress', {
                wallet: address,
                sign: txData,
            });
        },
    },
    mounted() {},
    created() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            // eslint-disable-next-line default-case
            switch (mutation.type) {
                case 'auth/GET_SIGNATURE_SUCCESS':
                    this.onLogin({
                        address: state.info.address,
                        signature: state.auth.sign,
                    });
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <b-button variant="none" class="metamaskLogin" @click="onConnectMetaMask">
        {{ isBuy ? 'Login' : 'Login with Meta mask' }}
    </b-button>
</template>
<style lang="scss">
.metamaskLogin {
    border: 1px solid #00ffff !important;
    background: linear-gradient(180deg, #18274a 0%, #001326 100%) !important;
    color: #fff !important;
    font-weight: 500 !important;
    height: 50px !important;
    width: 100% !important;
    max-width: 375px !important;
    display: flex !important;
    align-items: center !important;
    font-size: 18px !important;
    justify-content: center !important;
    border-radius: 0 !important;
    &:hover {
        color: #00ffff !important;
    }
}
</style>
