<script>
import { mapGetters } from 'vuex';
import { filter } from 'lodash';
import Paginate from '@/components/shared/Paginate.vue';
import ICountUp from 'vue-countup-v2';

export default {
    components: {
        Paginate,
        ICountUp,
    },
    data() {
        return {
            search: {
                search: '',
                page: 1,
                address: '',
            },
            fields: [
                {
                    key: 'Address',
                    sortable: false,
                    label: 'Address Wallet',
                    class: 'text-center',
                },
                {
                    key: 'F',
                    label: 'Ref',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: 'createAt',
                    label: 'Registration Date',
                },
            ],
            currentPage: 1,
            totalPages: 10,
            renderComponent: true,
            options2: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ' HBG',
                decimalPlaces: 0,
            },
            delay: 2000,
        };
    },
    computed: {
        url() {
            return `${window.location.hostname}/register?sponsor=${this.UserInfo.address}`;
        },
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            Member: 'member/Member',
        }),
        Statistical() {
            return this.Member.statistical;
        },
    },
    methods: {
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        onSearch(type) {
            if (type === 'reset') {
                this.search = {
                    search: '',
                    page: 1,
                };
            }
            this.$store.dispatch('member/req_getListMember', this.search);
            this.$store.dispatch('member/req_getStatistical');
            this.forceRerender();
            this.search.page = 1;
        },
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('member/req_getListMember', this.search);
            this.$store.dispatch('member/req_getStatistical');
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;
            });
        },
        test() {
            const data = [
                {
                    name: 'Transfer',
                    address: '0xA9Afb65805e143980C4FC231E008DA764FD29b53',
                },
                {
                    name: 'Approval',
                    address: '0xA9Afb65805e143980C4FC231E008DA764FD29b53',
                },
                {
                    name: 'Transfer',
                    address: '0xF78dA19B8c35A767B6F09e9C28fDf0E03e7a9FD6',
                },
                {
                    name: 'buyNewBox',
                    events: [
                        {
                            name: 'user',
                            type: 'address',
                            value: '0x9aabbc35cd3025eab5d59d7206de6058f3a2b1f4',
                        },
                        { name: 'boxType', type: 'string', value: 'Diamond' },
                        { name: 'tokenId', type: 'uint256', value: '81' },
                    ],
                    address: '0xF78dA19B8c35A767B6F09e9C28fDf0E03e7a9FD6',
                },
            ];

            // console.log(data);
            // const result = filter(data, { events: { name: 'tokenId' } });
            // console.log(result);
        },
    },
    watch: {
        UserInfo: {
            handler(newVal) {
                this.$store.dispatch('member/req_getListMember', {
                    address: newVal.address,
                });
                this.$store.dispatch('member/req_getStatistical');
                this.search.address = newVal.address;
            },
        },
    },
    mounted() {
        const self = this;
        if (self.UserInfo && self.UserInfo.address) {
            this.$store.dispatch('member/req_getListMember', {
                address: self.UserInfo.address,
            });
            this.$store.dispatch('member/req_getStatistical');
        }
    },
};
</script>
<template>
    <b-container class="member-tab">
        <div class="statistical">
            <!-- <b-button @click="test">test</b-button> -->
            <div class="d-flex align-items-center mb-2">
                <div class="header-icon mr-3">
                    <img src="~@/assets/images/icons/header-icon.png" alt="" />
                </div>
                <div class="title p-0">Referral Link</div>
            </div>
            <div class="ref-link box-input-group">
                <b-form-input
                    v-model.trim="url"
                    readonly
                    class="link-input"
                ></b-form-input>
                <b-button>
                    <div class="icons mr-3">
                        <img src="~@/assets/images/icons/copy.png" alt="" />
                    </div>
                    <div
                        class="text"
                        v-clipboard:copy="url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                    >
                        Copy Link
                    </div>
                </b-button>
            </div>
            <b-row class="overflow-hidden">
                <b-col cols="12" md="6" lg="6" sm="6" class="my-3">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title text-center">
                                Total Commission InGame
                            </div>
                            <div class="text-content">
                                <ICountUp
                                    v-if="
                                        !isNaN(
                                            Statistical.totalCommissionInGame,
                                        )
                                    "
                                    :delay="delay"
                                    :endVal="Statistical.totalCommissionInGame"
                                    :options="options2"
                                />
                                <ICountUp
                                    v-else
                                    :delay="delay"
                                    :endVal="0"
                                    :options="options2"
                                />
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="6" lg="6" sm="6" class="my-3">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title text-center">
                                Total Commission Wallet
                            </div>
                            <div class="text-content">
                                <ICountUp
                                    v-if="
                                        !isNaN(
                                            Statistical.totalCommissionWallet,
                                        )
                                    "
                                    :delay="delay"
                                    :endVal="Statistical.totalCommissionWallet"
                                    :options="options2"
                                />
                                <ICountUp
                                    v-else
                                    :delay="delay"
                                    :endVal="0"
                                    :options="options2"
                                />
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="6" lg="6" sm="6" class="my-3">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title text-center">
                                Total Sales InGame
                            </div>
                            <div class="text-content">
                                <ICountUp
                                    v-if="!isNaN(Statistical.totalSaleInGame)"
                                    :delay="delay"
                                    :endVal="Statistical.totalSaleInGame"
                                    :options="options2"
                                />
                                <ICountUp
                                    v-else
                                    :delay="delay"
                                    :endVal="0"
                                    :options="options2"
                                />
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="6" lg="6" sm="6" class="my-3">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title text-center">
                                Total Sales Wallet
                            </div>
                            <div class="text-content">
                                <ICountUp
                                    v-if="!isNaN(Statistical.totalSaleWallet)"
                                    :delay="delay"
                                    :endVal="Statistical.totalSaleWallet"
                                    :options="options2"
                                />
                                <ICountUp
                                    v-else
                                    :delay="delay"
                                    :endVal="0"
                                    :options="options2"
                                />
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="table-statistical">
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Your Member</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <p>
                            {{ Statistical.totalMember }}
                        </p>
                    </b-col>
                </b-row>
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Your Active Member</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <p>
                            {{ Statistical.totalMemberActive }}
                        </p>
                    </b-col>
                </b-row>
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Total Amount You Got</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <p>
                            {{
                                TruncateToDecimals2(Statistical.totalHGB, '', 4)
                            }}
                            HBG
                        </p>
                    </b-col>
                </b-row>
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Total Amount BNB You Got</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <p>
                            ~{{
                                TruncateToDecimals2(Statistical.totalUSD, '', 4)
                            }}
                            USDT
                        </p>
                    </b-col>
                </b-row>
            </div>
            <!-- <div class="table-statistical">
                <div class="table-item">
                    <div class="left">Your Member</div>
                    <div class="right">{{ Statistical.totalMember }}</div>
                </div>
                <div class="table-item">
                    <div class="left">Your Active Member</div>
                    <div class="right">{{ Statistical.totalMemberActive }}</div>
                </div>
                <div class="table-item">
                    <div class="left">Total Amount You Got</div>
                    <div class="right">
                        {{ TruncateToDecimals2(Statistical.totalHGB, '', 4) }}
                        HBG
                    </div>
                </div>
                <div class="table-item">
                    <div class="left">Total Amount BNB You Got</div>
                    <div class="right">
                        ~{{ TruncateToDecimals2(Statistical.totalUSD, '', 4) }}
                        USDT
                    </div>
                </div>
            </div> -->
        </div>

        <div class="member-list">
            <div class="d-flex justify-content-between align-items-start mb-3">
                <div class="d-flex align-items-center">
                    <div class="header-icon mr-3">
                        <img
                            src="~@/assets/images/icons/header-icon.png"
                            alt=""
                        />
                    </div>
                    <div class="title p-0">Member</div>
                </div>
                <div class="search-member">
                    <b-form-input
                        v-model="search.search"
                        placeholder="Enter Wallet Address Here"
                    ></b-form-input>
                    <b-button variant="info" @click="onSearch">
                        <div class="icons">
                            <img
                                src="~@/assets/images/icons/search.png"
                                alt=""
                            />
                        </div>
                    </b-button>
                </div>
            </div>

            <div class="table-responsive">
                <b-table
                    striped
                    hover
                    :items="Member.list"
                    :fields="fields"
                    show-empty
                    thead-class="customer-header"
                >
                    <template #empty>
                        <h4 class="text-center my-4">No Member</h4>
                    </template>
                    <template #cell(Address)="data">
                        <p>
                            {{ data.item.Address }}
                        </p>
                    </template>
                    <template #cell(F)="data">
                        <p>
                            {{ data.item.F }}
                        </p>
                    </template>
                    <template #cell(createAt)="data">
                        <p>
                            {{ getDateTime(new Date(data.item.createAt)) }}
                        </p>
                    </template>
                </b-table>
            </div>
            <div class="table-paginate">
                <Paginate
                    @current="onChangePage"
                    :totalPages="Member.total"
                    v-if="renderComponent"
                />
            </div>
        </div>
    </b-container>
</template>
<style lang="scss">
.member-tab {
    .header-icon {
        max-width: 55px;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .title {
        color: #f8f8f8;
        font-family: 'Poppins';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        // margin: 0 0 25px;
        font-weight: 600;
    }
    .search-member {
        max-width: 467px;
        height: 48px;
        width: 100%;
        border-radius: 128px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            rgba(53, 63, 72, 0.5);
        input {
            outline: none;
            border: none;
            background: none;
            height: 40px;
            width: 90%;
            border-radius: 0;
            color: #fff;
            &:focus {
                box-shadow: none;
            }
        }
        button {
            max-width: 20px;
            width: 100%;
            height: 20px;
            margin-left: auto;
            border-radius: 267px;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none !important;
        }
    }
    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .link-input {
            border: none;
            max-width: 570px;
            width: 100%;
            height: 42px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                rgba(53, 63, 72, 0.8);
            // opacity: 0.5;
            border-radius: 128px;
        }
        .icons {
            img {
                width: 17px;
                height: auto;
            }
        }
        input {
            outline: none;
            border: 1px solid #00ffff;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
            border: none;
        }
        button {
            max-width: 170px;
            width: 100%;
            height: 42px;
            margin-left: 2em;
            margin-right: auto;
            border-radius: 267px;
            background: rgba(0, 255, 255, 0.15);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
        }
    }
    .table-responsive {
        .customer-header {
            background-color: transparent;
            text-align: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #00ffff;
        }
        .table th,
        .table td {
            border: none;
        }
        table {
            thead {
                tr {
                    th {
                    }
                }
            }
            tbody {
                tr {
                    height: 70px;
                    td {
                        background: none;
                        height: 100%;
                        padding: 0 0.75rem;
                        vertical-align: middle;
                        color: #fff;
                        font-weight: 500;
                        p {
                            height: 70px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 0;
                            background: rgba(0, 255, 255, 0.1);
                            justify-content: center;
                        }
                    }
                    &:nth-of-type(even) {
                        td {
                            p {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    .table-statistical {
        margin: 35px auto;
        .table-item {
            border-radius: 1px;
            height: 75px;
            padding: 0 0.75rem;
            vertical-align: middle;
            color: #fff;
            font-weight: 600;
            display: flex;
            font-family: 'Poppins';
            justify-content: space-between;
            p {
                height: 100%;
                margin-bottom: 0;
                width: 95%;
                background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.2)
                    ),
                    #353f48;
                display: flex;
                align-items: center;
            }
            .left,
            .right {
                padding: 0%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .left {
                p {
                    padding-left: 5rem;
                }
            }
            .right {
                p {
                    justify-content: center;
                }
            }
            &:nth-of-type(even) {
                p {
                    background: transparent;
                }
            }
            @media (min-width: 768px) and (max-width: 991px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
            }
            @media (max-width: 575px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
        }
    }
    .box-statistical {
        // background: linear-gradient(to right, #18d4e2, #a506f9);
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 120px;
        height: 100%;
        outline: none;
        width: 100%;
        max-width: 427px;
        padding: 15px;
        border-radius: 4px;
        position: relative;
        margin: 0 auto;
        &::before {
            content: '';
            position: absolute;
            inset: 1px;
            border-radius: 4px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                rgba(53, 63, 72, 1);
        }
        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;
            img {
                width: 50px;
                height: auto;
            }
        }
        .text {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            z-index: 1;
            .text-title {
                color: #4effff;
                font-size: 1.4em;
                line-height: 120%;
                font-family: 'HHBI';
                font-weight: 600;
                margin-bottom: 15px;
                text-transform: capitalize;
            }
            .text-content {
                color: #ffffff;
                font-size: 1.6em;
                line-height: 120%;
                font-family: 'HHBI';
            }
        }
    }
}
</style>
