<script>
import { mapGetters, mapMutations } from 'vuex';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { listBox } from '../box';

const axios = require('@/store/axios.config').default;

export default {
    components: { VueSlickCarousel },
    data() {
        return {
            settings: {
                dots: false,
                dotsClass: 'slick-dots custom-dot-class',
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                responsive: [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
            approve: false,
            packActive: {},
            Receiver: {
                Heronium: 0,
            },
            isShowModalInfo: false,
            description: '',
        };
    },
    computed: {
        ...mapGetters({
            ListHeronium: 'shop/ListHeronium',
            Statistical: 'shop/Statistical',
            isLogin: 'auth/loggedIn',
            BalanceSystem: 'info/Balance',
        }),
        infoBox() {
            const arr = [
                {
                    img: 'shop-1.png',
                    title: 'Available',
                    content: 'Unlimited',
                },
                {
                    img: 'shop-2.png',
                    title: 'Time Remaining',
                    content: 'Unlimited',
                },
                {
                    img: 'shop-3.png',
                    title: 'Purchased',
                    content: `${this.TruncateToDecimals2(
                        this.Statistical.price,
                        '',
                        0,
                    )} HBG`,
                },
            ];
            return arr;
        },
        boxList() {
            return listBox;
        },
    },
    created() {
        this.$store.dispatch('shop/req_getHeronium');
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        async confirmBuy() {
            if (!this.isLogin) {
                this.$toastr.e('Please login to continue', 'Warning');
                return;
            }
            if (this.BalanceSystem.HBG < this.packActive.price) {
                this.$toastr.e('Insufficient balance', 'Warning');
                this.$bvModal.hide('confirm-buy');
                this.$store.commit('suggest/SuggestDeposit');
                return;
            }
            this.onLoad();

            const data = await axios.post('api/shop/heronium/buy', {
                type: this.packActive.type,
            });
            if (!data.amount || data.amount <= 0) {
                this.outLoad();
                return;
            }
            this.Receiver.Heronium = data.amount;
            this.$store.dispatch('info/req_getBalance');
            this.$bvModal.hide('confirm-buy');
            this.$bvModal.show('reward');
            this.outLoad();
        },
        onShowConfirm(pack) {
            // if (pack.type != null) {
            //     this.ComingSoonT();
            //     return;
            // }
            this.packActive = pack;
            this.$bvModal.show('confirm-buy');
        },
        ComingSoonT() {
            this.$toastr.s('Heronium is Coming Soon', 'Coming Soon');
        },
        showModalInfo(value) {
            this.description = value;
            this.isShowModalInfo = true;
        },
    },
};
</script>

<template>
    <b-container fluid class="shop-heronium">
        <b-container class="section section-4">
            <VueSlickCarousel v-bind="settings" v-if="ListHeronium.length >= 1">
                <div
                    class="carousel-item-shop"
                    v-for="box in ListHeronium"
                    :key="box.Name"
                >
                    <div class="mysteryBoxShop mt-3 px-2">
                        <div class="inventory-box-item">
                            <div class="inventory-item-image">
                                <img
                                    :src="
                                        require(`@/assets/images/mockup/shop/open-${box.name
                                            .replace(' Pack', '')
                                            .toLowerCase()}.png`)
                                    "
                                    alt=""
                                />
                                <b-button
                                    class="btn-modal-info-heronium d-block"
                                    v-b-modal="'info-heronium'"
                                    @click="showModalInfo(box.description)"
                                    >i</b-button
                                >
                            </div>
                            <div class="inventory-box-item-content">
                                <div class="land-name-content">
                                    <div
                                        class="heronium text-center"
                                        :class="box.name.replace(' Pack', '')"
                                    >
                                        {{ box.name }}
                                    </div>
                                </div>
                                <hr />
                                <div
                                    class="box-available d-flex justify-content-between"
                                >
                                    <div class="left text-white">Price:</div>
                                    <div class="right pl-0">
                                        <div class="hbg text-white">
                                            ~{{
                                                TruncateToDecimals2(
                                                    box.price,
                                                    '',
                                                    0,
                                                )
                                            }}
                                            HBG
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-center pt-4">
                                    <button
                                        @click="onShowConfirm(box)"
                                        class="bede-btn"
                                    >
                                        <p>Buy</p>
                                    </button>
                                </div>

                                <div class="inventory-item-line">
                                    <img
                                        src="@/assets/images/icons/icon-line-svg.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </b-container>
        <b-modal id="confirm-buy" title="Confirm Buy Pack" hide-footer>
            <div>
                <p class="mb-0 text-confirm" v-if="packActive.price">
                    Do you want to use
                    {{ TruncateToDecimals2(packActive.price, '', 0) }} HBG to
                    buy {{ packActive.name }}?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="confirmBuy"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal id="reward" title="Received Heronium" hide-footer>
            <div>
                <img src="" alt="" />
                <div class="circle text-center">
                    <img
                        src="~@/assets/images/mockup/account/heronium.png"
                        alt=""
                        style="width: 100%; max-width: 200px"
                    />
                </div>
                <div class="text-confirm mt-0" v-if="Receiver.Heronium">
                    {{ TruncateToDecimals2(Receiver.Heronium, '', 0) }}
                    <br />
                    Heronium
                </div>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('reward')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>

        <!-- MODAL -->
        <b-modal
            id="info-heronium"
            title="Information"
            hide-footer
            v-model="isShowModalInfo"
            @hidden="description = ''"
        >
            <div>
                <p class="mb-0 text-confirm-ticket">
                    Description: <span>{{ description }} </span>
                </p>
            </div>
            <b-button
                class="mx-auto mt-5 mb-0 button-confirm"
                variant="primary"
                @click="isShowModalInfo = false"
            >
                Cancel
            </b-button>
        </b-modal>
    </b-container>
</template>

<style lang="scss">
$box-color: linear-gradient(0deg, #02ffff -60%, #001326 100%);
.text-confirm {
    text-align: center;
    font-size: 2em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}
.shop-heronium {
    .section {
        padding: 50px 0;
        .page-name {
            position: relative;
            .before {
                -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
                -webkit-text-stroke-width: 1px;
                font-family: 'HHBI';

                line-height: 120%;
                text-transform: uppercase;

                -webkit-text-stroke-color: #4effff;
                opacity: 0.6;
                font-size: clamp(70px, 5.5vw, 120px);
            }

            .after {
                font-family: 'HHBI';
                font-size: clamp(40px, 3vw, 65px);
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                text-transform: uppercase;
                color: #fff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            }
            @media (max-width: 575px) {
                .before {
                    font-size: clamp(55px, 5.5vw, 120px);
                    opacity: 0.3;
                }
                .after {
                    font-size: clamp(30px, 3vw, 65px);
                }
            }
            @media (max-width: 424px) {
                .before {
                    font-size: clamp(45px, 5.5vw, 120px);
                    opacity: 0.3;
                }
                .after {
                    font-size: clamp(25px, 3vw, 65px);
                }
            }
            @media (max-width: 374px) {
                .before {
                    font-size: clamp(40px, 5.5vw, 120px);
                    opacity: 0.3;
                }
                .after {
                    bottom: -10px;
                    font-size: clamp(30px, 3vw, 65px);
                }
            }
        }
    }
    .section-4 {
        .slick-arrow {
            z-index: 30;
            width: max-content;
            height: max-content;

            &.slick-next {
                right: -2%;
                &:before {
                    content: '';
                    background: url('~@/assets/images/icons/next.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 40px;
                    width: 25px;
                    display: block;
                    filter: drop-shadow(1px 1px 2px #013131);
                }
            }
            &.slick-prev {
                left: -2%;
                &:before {
                    content: '';
                    background: url('~@/assets/images/icons/prev.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 40px;
                    width: 25px;
                    display: block;
                    filter: drop-shadow(5px 5px 5px black);
                }
            }
            @media (min-width: 1366px) {
                &.slick-next {
                    right: 0%;
                }
                &.slick-prev {
                    left: 0%;
                }
            }
            @media (max-width: 425px) {
                &.slick-next,
                &.slick-prev {
                    &:before {
                        height: 36px;
                        width: 25px;
                    }
                }
                &.slick-next {
                    right: -25px;
                }
                &.slick-prev {
                    left: -25px;
                }
            }
        }
        .none-dec {
            text-decoration: none !important;
        }
        .box-container {
            width: 100%;
            max-width: 270px;
            margin: auto;

            .box-img {
                position: relative;
                img {
                    width: 100%;
                    z-index: 10;
                    position: relative;
                    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
                    max-height: 200px;
                    object-fit: scale-down;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    background: linear-gradient(
                        0deg,
                        #e2dfd3 0%,
                        #f6cb0a00 100%
                    );
                    z-index: 0;
                    animation: light 2s infinite linear;
                }
            }

            .box-content {
                background: {
                    image: url('~@/assets/images/mockup/shop/content.png');
                    size: 100% 100%;
                    position: bottom center;
                    repeat: no-repeat;
                }
                margin-top: 5px;
                width: 100%;
                // min-height: 225px;
                min-height: 150px;
                padding: 15px 15px 0;
                .heronium {
                    // font-size: 2em;
                    font-size: 1.8em;
                    text-align: center;
                    margin-bottom: 15px;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    &.diamond {
                        color: #ff00ff;
                        text-shadow: 1px 0px 9px #ff00ff;
                    }
                    &.gold {
                        color: #f3ff00;
                        text-shadow: 1px 0px 9px #f3ff00;
                    }
                    &.silver {
                        color: #98d7e0;
                        text-shadow: 1px 0px 9px #98d7e0;
                    }
                    &.platinum {
                        color: #4effff;
                        text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                        @media (max-width: 374px) {
                            font-size: 1.7em;
                        }
                    }
                }
                .mystery-box-price {
                    margin-bottom: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    color: white;
                    .left {
                        // flex: 0 0 25%;
                        // color: #002338;
                        font-weight: 600;
                        margin-right: 10px;
                    }
                    .right {
                        // flex: 0 0 75%;
                        display: inline-block;
                        padding-left: 10px;
                        color: #00ffff;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .hbg {
                            font-size: 1.4em;
                            font-weight: 900;
                            line-height: 120%;
                            font-family: 'HHBI';
                        }
                        .usd {
                            font-size: 1em;
                            font-weight: 900;
                            margin-left: 10px;
                            font-family: 'HHBI';
                        }
                    }
                }
                .btn-buy-heronium {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    border: 1px solid #00ffff;
                    padding: 5px 30px;
                    color: black;
                    font-weight: bold;
                }
                .detail {
                    color: #fff;
                    padding: 15px 0;
                    text-align: center;
                }
            }
        }
        @media (max-width: 767px) {
            width: 100%;
            padding: 50px 10px;
            max-width: 100%;
        }

        .btn-modal-info-heronium {
            background: #00ffff;
            width: 30px;
            height: 30px;
            border: 0;
            border-radius: 50%;
            padding: 0;
            position: absolute;
            top: 5px;
            right: -50px;
            z-index: 10;

            color: black;
            font-weight: bold;
            font-family: 'HHBI';
        }
    }
}
</style>
<style lang="scss" scoped>
.mysteryBoxShop {
    font-family: 'Poppins';
    flex: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 575px) {
        max-width: 100%;
    }
    button {
        outline: unset;
    }
    .inventory-box-item {
        .inventory-item-image {
            width: 200px;
            height: auto;
            margin-top: 30px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .land-name-content {
            font-weight: 600;
            font-size: 24px;
            color: #f8f8f8;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .mysteryBoxShop-information {
            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #f8f8f8;
                padding: 8px 1rem;
            }
            .price {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #00ffff;
            }
        }
    }
    button.bede-btn {
        height: 38px;
        width: 100%;
        max-width: 135px;
        border-radius: 105px;
        &::before {
            border-radius: 105px;
            background: #182c35;
        }
    }
}
</style>
