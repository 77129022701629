<script>
import { mapGetters, mapMutations } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';
import LandItem from "../shared/landItem.vue";

export default {
    components: { Paginate, LandItem },
    data() {
        return {
            approve: false,
            landInsideModal: null,
            modalValue: {
                sellPrice: 0,
                addressTransfer: '',
                tokenId: null,
            },
            isShowModalCancel: false,
            isShowModalSell: false,
            isShowModalTransfer: false,
            filterLand: {
                limit: 6,
                page: 1,
            },
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        getPercents(current, end) {
            const percent = (current / end) * 100;
            if (percent < 20) {
                return 20;
            }
            if (percent >= 100) {
                return 100;
            }
            return percent;
        },
        onAction() {
            this.$toastr.w('', 'Coming Soon!');
        },
        onChangePage(pageNext) {
            this.filterLand.page = pageNext;
            this.$store.dispatch('land/req_getListLand', this.filterLand);
        },
        async ApproveSell(tokenId) {
            const { web3 } = this.$store.state.contract;
            const { land, market } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            this.onLoad();
            this.modalValue.tokenId = tokenId;
            const txObj = land.cont.methods
                .approve(market.addr, tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                // Calculate gas
                gas = await land.cont.methods
                    .approve(market.addr, tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;

            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: land.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction

                let submitted = true;
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        if (txData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            await this.onSellLand();
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        // eslint-disable-next-line consistent-return
        async onSellLand() {
            // this.onLoad();
            const { market } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const { sellPrice, tokenId } = this.modalValue;

            const deciamalPrice = web3.provider.utils.toWei(
                sellPrice.toString(),
                'ether',
            );
            const txObj = market.cont.methods
                .sellLand(tokenId, deciamalPrice)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await market.cont.methods
                    .sellLand(tokenId, deciamalPrice)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: market.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txSellData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txSellData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txSellData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('sell', txSellData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async onTransferLand(tokenId) {
            this.onLoad();
            const { land } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await land.cont.methods
                .transferFrom(addr, this.modalValue.addressTransfer, tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await land.cont.methods
                    .transferFrom(
                        addr,
                        this.modalValue.addressTransfer,
                        tokenId,
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: land.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('transfer', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async onCancelLand(tokenId) {
            this.onLoad();
            const { market } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await market.cont.methods
                .cancelLandSale(tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await market.cont.methods
                    .cancelLandSale(tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: market.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancel', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },

        onShowCancel(value) {
            this.isShowModalSell = false;
            this.isShowModalTransfer = false;
            this.isShowModalCancel = true;
            this.landInsideModal = value;
        },
        showModalSell(value) {
            this.isShowModalCancel = false;
            this.isShowModalSell = true;
            this.isShowModalTransfer = false;
            this.landInsideModal = value;
        },
        showModalTransfer(value) {
            this.isShowModalCancel = false;
            this.isShowModalSell = false;
            this.isShowModalTransfer = true;
            this.landInsideModal = value;
        },
        clearModalValue() {
            this.modalValue = {
                sellPrice: 0,
                addressTransfer: '',
            };
        },
        onTransferLandFree(id) {
            this.isShowModalTransfer = false;
            this.$store.dispatch('land/req_postTransferFree', {
                tokenId: id,
                newOwner: this.modalValue.addressTransfer,
            });
            this.outLoad();
            this.landInsideModal.modalTitle = 'Transfer Success';
            this.landInsideModal.typeShow = 'transfer';
            this.$bvModal.show('modal-success-land');
        },
        sendTransaction(type, txData) {
            if (type === 'transfer') {
                this.isShowModalTransfer = false;
                this.$store.dispatch('land/req_postTransferSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.landInsideModal.modalTitle = 'Transfer Success';
                this.landInsideModal.typeShow = 'transfer';
            }
            if (type === 'sell') {
                this.isShowModalSell = false;
                this.$store.dispatch('land/req_postSellSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.landInsideModal.modalTitle = 'Sell Success';
                this.landInsideModal.typeShow = 'sell';
            }
            if (type === 'cancel') {
                this.isShowModalCancel = false;
                this.$store.dispatch('land/req_postCancelSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.landInsideModal.modalTitle = 'Cancel Success';
                this.landInsideModal.typeShow = 'cancel';
            }
            this.$bvModal.show('modal-success-land');
        },
    },
    computed: {
        ...mapGetters({
            ListLand: 'land/ListLand',
        }),
    },
    mounted() {
        this.$store.dispatch('land/req_getListLand', this.filterLand);
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'land/ACTION_SUCCESS':
                    this.$store.dispatch(
                        'land/req_getListLand',
                        this.filterLand,
                    );
                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <b-container class="p-0">
        <b-row class="land-list">
            <b-col cols="12" class="box-name mt-5 pt-5" v-if="ListLand.list.length <= 0">
                <div class="after pt-4">No Land</div>
            </b-col>
            <b-col
                cols="12"
                md="4"
                lg="4"
                sm="6"
                xl="4"
                class="col-xs-6 px-0 px-sm-2"
                v-for="land in ListLand.list"
                :key="`list-hero-inventory-${land.tokenId}`"
            >
                <LandItem
                    class="mx-auto w-100"
                    @onSell="showModalSell"
                    @onTransfer="showModalTransfer"
                    @onCancel="onShowCancel"
                    :landItem="land"
                />
            </b-col>
        </b-row>
        <div class="d-flex justify-content-center mt-4">
            <Paginate @current="onChangePage" :totalPages="ListLand.total" />
        </div>

        <b-modal
            id="modal-sell-land"
            title="CONFIRM SELL"
            hide-footer
            :centered="true"
            @hidden="clearModalValue"
            v-model="isShowModalSell"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
            >
                <LandItem
                    :onlyEarth="true"
                    :landItem="landInsideModal"
                    v-if="landInsideModal"
                    :showAction="false"
                    class="w-100"
                />
                <div
                    class="box-input-group mx-auto d-flex align-items-center justify-content-center mt-3"
                >
                    <label class="mr-2 mb-0">Price</label>
                    <b-form-input v-model="modalValue.sellPrice"></b-form-input>
                    <div class="text text-black">HBG</div>
                </div>
                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="ApproveSell(landInsideModal.tokenId)"
                    >
                        Confirm
                    </b-button>
                    <b-button
                        class="btn-cancel d-block text-white rounded-0"
                        @click="$bvModal.hide('modal-sell-land')"
                    >
                        Cancel
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="modal-cancel-sell-land"
            title="CANCEL SELL HERO"
            hide-footer
            :centered="true"
            @hidden="clearModalValue"
            v-model="isShowModalCancel"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
            >
                <LandItem
                    :onlyEarth="true"
                    :landItem="landInsideModal"
                    v-if="landInsideModal"
                    :showAction="false"
                    class="w-100"
                />
                <div
                    class="box-input-group flex-column mx-auto d-flex align-items-center justify-content-center mt-5"
                    v-if="landInsideModal"
                >
                    <label class="mr-2 mb-0">Price sell</label>
                    <div
                        class="box-input-group mx-auto d-flex align-items-center justify-content-center mt-1"
                        v-if="landInsideModal.price"
                    >
                        <b-form-input
                            :value="
                                TruncateToDecimals2(
                                    landInsideModal.price,
                                    '',
                                    0,
                                ) || 0
                            "
                            readonly
                        ></b-form-input>
                        <div class="text text-black">HBG</div>
                    </div>
                </div>
                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onCancelLand(landInsideModal.tokenId)"
                    >
                        Cancel Now
                    </b-button>
                    <b-button
                        class="btn-cancel d-block text-white rounded-0"
                        @click="$bvModal.hide('modal-cancel-sell-land')"
                    >
                        Cancel
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="modal-transfer-land"
            title="CONFIRM TRANSFER"
            hide-footer
            :centered="true"
            v-model="isShowModalTransfer"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
            >
                <LandItem
                    :onlyEarth="true"
                    :landItem="landInsideModal"
                    v-if="landInsideModal"
                    :showAction="false"
                    class="w-100"
                />

                <div class="mx-auto" style="width: 80%">
                    <label class="mb-0 mx-auto d-block label-address">
                        Address
                    </label>
                    <b-form-input
                        v-model="modalValue.addressTransfer"
                        class="input-address"
                    >
                    </b-form-input>
                </div>

                <div
                    class="d-flex justify-content-around mt-5 w-100"
                    v-if="landInsideModal"
                >
                    <b-button
                        v-if="landInsideModal.isNFT"
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onTransferLand(landInsideModal.tokenId)"
                    >
                        Confirm
                    </b-button>
                    <b-button
                        v-else
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onTransferLandFree(landInsideModal.tokenId)"
                    >
                        Confirm
                    </b-button>
                    <b-button
                        class="btn-cancel d-block text-white rounded-0"
                        @click="$bvModal.hide('modal-transfer-land')"
                    >
                        Cancel
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="modal-success-land"
            :title="landInsideModal ? landInsideModal.modalTitle : ''"
            hide-footer
            :centered="true"
            @hidden="clearModalValue"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
                v-if="landInsideModal"
            >
                <LandItem
                    :onlyEarth="true"
                    :landItem="landInsideModal"
                    v-if="landInsideModal"
                    :showAction="false"
                    class="w-100"
                />

                <div
                    class="mx-auto"
                    style="width: 80%"
                    v-if="landInsideModal.typeShow === 'transfer'"
                >
                    <label class="mb-0 mx-auto d-block label-address">
                        Address
                    </label>
                    <b-form-input
                        v-model="modalValue.addressTransfer"
                        class="input-address"
                    >
                    </b-form-input>
                </div>
                <div
                    class="mx-auto"
                    style="width: 80%"
                    v-if="landInsideModal.typeShow === 'sell'"
                >
                    <label class="mb-0 mx-auto d-block label-address">
                        Price
                    </label>
                    <b-form-input
                        v-model="modalValue.sellPrice"
                        class="input-address"
                    >
                    </b-form-input>
                </div>
                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="mx-auto button-confirm d-block"
                        variant="primary"
                        @click="$bvModal.hide('modal-success-land')"
                    >
                        Confirm
                    </b-button>
                </div>
            </div>
        </b-modal>
    </b-container>
</template>

<style lang="scss">
.land-list {
    .px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .box-name {
        position: relative;
        margin: auto;
        width: 100%;
        min-height: 130px;
        text-align: center;
        justify-content: center;
        display: flex;
        .after {
            font-size: clamp(60px, 4vw, 80px);
            font-family: 'HHBI';
            text-align: center;
            text-transform: uppercase;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
        }
        @media (max-width: 991px) {
            .after {
                font-size: clamp(40px, 3vw, 75px);
            }
        }
        @media (max-width: 575px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
    .hero {
        flex: 1;
        min-width: 200px;
        max-width: 225px;
        width: 100%;
        // min-height: 350px;
        // margin: 0 15px 30px;
        margin: 0 auto 25px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 1 1 auto;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        border: 1px solid #00ffff;
                        max-width: calc(100% - 50px);
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .right-hero {
                        flex: 0 0 50px;
                        max-width: 50px;
                        .stat-hero {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            .hero-level {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ffff;
                                border: 1px solid #00ffff;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                            }
                            img {
                                width: 35px;
                                height: auto;
                                max-height: 35px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }
                .hero-exp {
                    position: relative;
                    width: 100%;
                    border: 1px solid #00ffff;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px auto 15px;
                    background: rgba(0, 255, 255, 0.2);
                    .text {
                        position: relative;
                        z-index: 10;
                        font-weight: 500;
                        font-size: 12px;
                        font-style: italic;
                        span {
                            font-weight: 700;
                            font-family: 'Helvetica';
                            font-size: 15px;
                        }
                    }
                    .current {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
                .hero-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        width: auto;
                        padding: 5px;
                        min-width: 100px;
                        height: 40px;
                        border-radius: 5px;
                        border: 0;
                        color: #ffff;
                        &.sell {
                            background: {
                                image: url('~@/assets/images/mockup/account/sell.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.transfer {
                            background: {
                                image: url('~@/assets/images/mockup/account/transfer.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.price {
                            background: {
                                image: url('~@/assets/images/mockup/account/price.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.cancel {
                            background: {
                                image: url('~@/assets/images/mockup/account/cancel.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                    }
                }
            }
            @media (min-width: 414px) and (max-width: 575px) {
                .top-hero {
                    .hero-img {
                        .avatar-hero {
                            max-width: calc(100% - 70px);
                        }
                        .right-hero {
                            flex: 0 0 50px;
                            max-width: 50px;
                            .stat-hero {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                flex-wrap: wrap;
                                .hero-level {
                                    width: 30px;
                                    height: 30px;
                                    margin-bottom: 10px;
                                    background: #000b1a;
                                    color: #00ffff;
                                    border: 1px solid #00ffff;
                                    font-size: larger;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-align: center;
                                    font-weight: 900;
                                    letter-spacing: 1px;
                                }
                                img {
                                    width: 30px;
                                    height: auto;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
                .bottom-hero {
                    max-width: 190px;
                    width: 100%;
                    .hero-action {
                        button {
                            min-width: 90px;
                        }
                    }
                }
            }
        }
    }
}
#modal-success-land,
#modal-cancel-sell-land,
#modal-sell-land,
#modal-transfer-land {
    .hero {
        flex: 1;
        min-width: 200px;
        max-width: 225px;
        width: 100%;
        margin: 0 15px 0px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 1 1 auto;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        border: 1px solid #00ffff;
                        max-width: calc(100% - 50px);
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .right-hero {
                        flex: 0 0 50px;
                        max-width: 50px;
                        .stat-hero {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            .hero-level {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ffff;
                                border: 1px solid #00ffff;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                            }
                            img {
                                width: 35px;
                                height: auto;
                                max-height: 35px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }
                .hero-exp {
                    position: relative;
                    width: 100%;
                    border: 1px solid #00ffff;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px auto 15px;
                    background: rgba(0, 255, 255, 0.2);
                    .text {
                        position: relative;
                        z-index: 10;
                        font-weight: 500;
                        font-size: 12px;
                        font-style: italic;
                        span {
                            font-weight: 700;
                            font-family: 'Helvetica';
                            font-size: 15px;
                        }
                    }
                    .current {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
                .hero-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        width: auto;
                        padding: 5px;
                        min-width: 100px;
                        height: 40px;
                        border-radius: 5px;
                        border: 0;
                        color: #ffff;
                        &.sell {
                            background: {
                                image: url('~@/assets/images/mockup/account/sell.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.transfer {
                            background: {
                                image: url('~@/assets/images/mockup/account/transfer.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.price {
                            background: {
                                image: url('~@/assets/images/mockup/account/price.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.cancel {
                            background: {
                                image: url('~@/assets/images/mockup/account/cancel.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .box-input-group {
        font-family: 'hemiheadRg-BoldItalic';
        height: 40px;
        // font-size: clamp(1rem, 1.5vw, 1.5rem);

        label {
            color: #fff;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
        }

        input {
            outline: none;
            margin: 0;
            border-radius: 0;
            color: #fff;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
            // height: 50px;
            height: 100%;

            border: 1px solid #00ffff;
            background: linear-gradient(to right, #18274a, #001326);
            display: block;
            width: 50%;

            &::placeholder {
                color: #fff;
                font-size: clamp(1rem, 1.5vw, 1.5rem);
            }
        }
        .text {
            background: #00ffff;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
            color: #000;
            line-height: 40px;
            padding: 0 10px 0 5px;
        }
    }

    .btn-cancel {
        background: none;
        border: 1px solid #00ffff;
        color: #000;
        font-family: 'helvetica';
        font-size: 20px;
    }

    .label-address {
        font-family: 'hemiheadRg-BoldItalic';
        color: #fff;
        font-size: clamp(1rem, 1.5vw, 1.5rem);
    }

    .input-address {
        font-family: 'hemiheadRg-BoldItalic';
        outline: none;
        margin: 0;
        border-radius: 0;
        color: #fff;
        font-size: clamp(1rem, 1.5vw, 1.5rem);

        border: 1px solid #00ffff;
        background: linear-gradient(to right, #18274a, #001326);
        width: 100%;
    }
}
</style>
