<script>
export default {
    props: {
        landItem: {
            type: Object,
        },
        onlyEarth: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            priceSell: 0,
        };
    },
    methods: {
        callBackEvt(typeCall, land) {
            switch (typeCall) {
                case 'sell':
                    this.$emit('onSell', land);
                    break;
                case 'transfer':
                    this.$emit('onTransfer', land);
                    break;
                case 'cancel':
                    this.$emit('onCancel', land);
                    break;
                default:
                    break;
            }
        },
    },
};
</script>
<template>
    <div class="land-item">
        <div class="item">
            <div class="id-land" ref="idHero" style="height: 25px">
                {{
                    landItem.isNFT
                        ? `#${landItem.tokenId}`
                        : `Free${landItem.tokenId}`
                }}
            </div>

            <div class="land-img">
                <img v-if="landItem.image" :src="landItem.image" alt="" />
                <img
                    v-else
                    src="~@/assets/images/mockup/market/land.jpg"
                    alt=""
                />
            </div>
            <div
                class="land-name"
                style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                "
            >
                <div class="land-name-content">No.{{ landItem.id }}</div>
            </div>
            <div class="land-reward mt-1 mb-1">
                {{ landItem.isDone ? 'REWARDED' : 'EXPECTED' }}:
                <span>
                    {{
                        landItem.totalPoint
                            ? TruncateToDecimals2(landItem.totalPoint, 0, 4)
                            : 0
                    }}
                    Heronium
                </span>
            </div>
            <div class="land-process">
                <div class="loading-progress">
                    <div class="progress-bg">
                        <div
                            class="active-bg"
                            :style="`width:${100 - landItem.restPoint}%`"
                        ></div>
                    </div>
                    <div class="progress-active">
                        Farming: {{ (100 - landItem.restPoint).toFixed(4) }}% /
                        100%
                    </div>
                </div>
            </div>
            <!-- || !onlyEarth -->
            <template v-if="landItem.miningHero">
                <b-button
                    variant="danger"
                    class="text-center mx-auto btn-lock-2"
                >
                    Mining Locked
                </b-button>
            </template>
            <div class="land-btn" v-else-if="showAction">
                <template v-if="!landItem.isSelling && !landItem.isNFT">
                    <b-button
                        class="btn-transfer mx-auto"
                        @click="callBackEvt('transfer', landItem)"
                    >
                        Transfer
                    </b-button>
                </template>
                <template v-else-if="!landItem.isSelling && false">
                    <b-button
                        v-if="landItem.isNFT"
                        class="btn-sell"
                        @click="callBackEvt('sell', landItem)"
                    >
                        Sell
                    </b-button>
                    <b-button
                        class="btn-transfer mx-auto"
                        @click="callBackEvt('transfer', landItem)"
                    >
                        Transfer
                    </b-button>
                </template>

                <template v-else-if="landItem.isSelling">
                    <b-button class="btn-price">
                        {{ TruncateToDecimals2(landItem.price || 0, '', 2) }}
                        HBG
                    </b-button>
                    <b-button @click="callBackEvt('cancel', landItem)">
                        Cancel
                    </b-button>
                </template>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
.land-item {
    flex: auto;
    max-width: 275px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 575px) {
        max-width: 100%;
    }
    .item {
        width: 100%;
        max-width: 250px;
        margin-bottom: 30px;
        @media (max-width: 575px) {
            max-width: 175px;
            margin-bottom: 30px;
        }
        .land-img {
            border: 1px solid #00ff00;
            margin-bottom: 10px;
            box-shadow: 0px 0px 7px #00ff00;
            img {
                width: 100%;
                height: auto;
                max-height: 160px;
                object-fit: cover;
            }
        }
        .id-land {
            color: #00ffff;
            font-size: 1.2em;
            font-weight: 800;
            margin-bottom: 0px;
            font-family: 'HHBI';
            line-height: 1.2em;
        }
        .land-name {
            width: 100%;
            background-color: #00ff00;
            font-size: 25px;
            line-height: 100%;
            color: #000000;
            text-decoration: none;
            font-family: 'HHBI';
            @media (max-width: 575px) {
                font-size: 18px;
            }
            .land-name-content {
                display: flex;
                width: max-content;
                height: max-content;
            }
        }
        .land-reward {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            color: #00ff00;
            text-shadow: 0px 0px 5px #00ff00;
            font-size: 16px;
            line-height: 100%;
            @media (max-width: 575px) {
                font-size: 12px;
            }
            span {
                font-size: 17px;
                color: #ffffff;
                @media (max-width: 575px) {
                    font-size: 15px;
                }
            }
        }
        .land-process {
            width: 100%;
            text-align: center;
            margin-bottom: 5px;
            .loading-progress {
                height: 30px;
                width: 100%;
                position: relative;
                .progress-bg {
                    background: #33004d;
                    border: 1px solid #00ff00;
                    box-sizing: border-box;
                    height: 30px;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    .active-bg {
                        content: '';
                        background: #00ff007a;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        bottom: 0;
                        margin: auto;
                        width: 10%;
                        height: 30px;
                    }
                }
                .progress-active {
                    position: absolute;
                    top: 0;
                    left: 0px;
                    right: 0px;
                    bottom: 0;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 800;
                    letter-spacing: 1px;
                    @media (max-width: 991px) {
                        font-size: 12px;
                    }
                    @media (max-width: 575px) {
                        font-size: 10px;
                    }
                }
            }
        }
        .land-btn {
            display: flex;
            justify-content: space-between;
            button {
                background: none;
                border: none;
                font-size: 13px;
                padding: 10px 20px;
                font-style: italic;
                letter-spacing: 1px;
                font-weight: 600;
                font-family: 'HHBI';
                @media (max-width: 575px) {
                    padding: 5px 10px;
                }
                &.btn-sell {
                    background: url('~@/assets/images/mockup/account/bgBtn-red.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                &.btn-price {
                    background: url('~@/assets/images/mockup/account/bgBtn-blue.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    color: #000;
                    font-weight: 800;
                }

                &:nth-child(2) {
                    background: url('~@/assets/images/mockup/account/bgBtn-green.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                &.btn-transfer {
                    background: url('~@/assets/images/mockup/account/bgBtn-green.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
    }
}
</style>
