<script>
import VueSlickCarousel from 'vue-slick-carousel';
import { Email, MetaMask, QrCode } from '@/components/auth';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

const axios = require('@/store/axios.config').default;

export default {
    components: { Email, MetaMask, QrCode, VueSlickCarousel },
    data() {
        return {
            settingsBannerProgame: {
                dots: false,
                dotsClass: 'slick-dots custom-dot-class',
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                adaptiveHeight:true
            },
            bannerList: [],
        };
    },
    methods: {
        async getBanner() {
            const banner = await axios.get(`/api/system/get-banner`);
            if (banner.length > 0) {
                this.bannerList = banner;
                this.$bvModal.show('modal-1');
            }
        },
    },
    mounted() {
        this.getBanner();
        // this.$bvModal.show('modal-1');
    },
};
</script>

<template>
    <div class="auth-page">
        <MetaMask class="my-2" />
        <QrCode class="my-2" v-if="false"/>
        <Email class="my-2" />
        <div class="more-info">
            <div class="text-more">
                By continuing, you agree to our
                <a href="#"> Terms of Use </a>
            </div>
            <div class="breaking"></div>
            <div class="text-more">
                Don’t know where to start?
                <router-link :to="{ name: 'Register' }">
                    Getting Started
                </router-link>
            </div>
        </div>
        <b-modal
            id="modal-1"
            title="Notification"
            hide-footer
            hide-header
            size="md"
            body-class="p-0"
        >
            <div class="close" @click="$bvModal.hide('modal-1')">x</div>
            <VueSlickCarousel v-bind="settingsBannerProgame">
                <div
                    class="carousel-item-home"
                    v-for="banner in bannerList"
                    :key="`${banner.image}-${banner.id}-${banner._id}`"
                >
                    <img :src="banner.image" width="100%" alt="" />
                </div>
            </VueSlickCarousel>
        </b-modal>
    </div>
</template>
<style lang="scss">
#modal-1 {
    .close {
        position: absolute;
        top: 0;
        opacity: 1;
        right: 0;
        z-index: 20;
        color: #fff;
        padding: 10px;
        cursor: pointer;
    }
}
.auth-page {
    padding: calc(5vh + 20px) 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    .more-info {
        .text-more {
            color: #fff;
            font-weight: 500;
            font-size: 16px;
            margin: 15px auto;
            display: flex;
            flex-direction: column;
            text-align: center;
            a {
                color: #00ffff;
                font-weight: 500;
                display: block;
                @media (min-width: 375px) {
                    margin-left: 10px;
                }
            }
            @media (min-width: 375px) {
                flex-direction: row;
                margin-left: 10px;
            }
        }
        .breaking {
            margin: 10px auto;
            background: linear-gradient(00deg, #00ffa9 0%, #00b1ff 100%);
            height: 2px;
            width: 100%;
        }
    }
}
</style>
