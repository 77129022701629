<template>
    <router-view></router-view>
</template>

<script>
export default {
    mounted() {
        if (
            this.$route.fullPath.includes('testing') &&
            window.location.hostname === 'marketplace.herobook.io'
        ) {
            this.$router.push({ name: 'Login' });
        }
    },
};
</script>

<style></style>
