var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"wallet-tab"},[_c('div',{staticClass:"heading-section mb-4 mt-5"},[_c('div',{staticClass:"text"},[_vm._v("Hero Book Balance")])]),_c('BalanceBox',{staticClass:"mt-4"}),_c('div',{staticClass:"heading-section mb-4 mt-5"},[_c('div',{staticClass:"text"},[_vm._v("Game Balance")])]),_c('b-row',{staticClass:"mt-4"},_vm._l((_vm.ListGameBalances),function(item,idx){return _c('b-col',{key:item + idx,staticClass:"px-1",attrs:{"md":"6","lg":"12","xl":"6"}},[_c('div',{staticClass:"system-wallet main-box"},[_c('div',{staticClass:"system-wallet-header border-b"},[_c('div',{staticClass:"system-wallet-header-image"},[_c('img',{attrs:{"src":item.image,"alt":""}})]),_c('div',{staticClass:"system-wallet-header-title"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"system-wallet-body"},[_c('div',{staticClass:"system-wallet-body-image"}),_c('div',{staticClass:"system-wallet-body-amount"},[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2( item.balance.HBG, '', 4 ))+" ")]),_c('div',{staticClass:"system-wallet-body-symbol"},[_vm._v("HBG")])]),_c('div',{staticClass:"system-wallet-footer"},[(item.isTransfer && _vm.readyConnect(item.name))?_c('b-button',{staticClass:"system-wallet-footer-button",on:{"click":function($event){return _vm.onChooseEco(item)}}},[_c('div',{staticClass:"system-wallet-footer-button-text"},[_vm._v(" Deposit ")])]):_c('router-link',{attrs:{"to":{
                            name: 'Setting',
                            hash: '#ecosystem',
                        }}},[_c('b-button',{staticClass:"system-wallet-footer-button"},[_c('div',{staticClass:"system-wallet-footer-button-text"},[_vm._v(" Connect "+_vm._s(item.name)+" ")])])],1)],1)])])}),1),_c('b-modal',{attrs:{"id":"deposit-ecosystem","title":("Deposit From  HeroBook To " + (_vm.depositEcosystem.name)),"hide-footer":""},on:{"hidden":_vm.resetDepositEco}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onDepositEco()}}},[_c('b-form-group',[_c('label',{attrs:{"for":"inputAmount"}},[_vm._v("Your Address Balance")]),_c('b-form-input',{attrs:{"type":"text","value":((_vm.TruncateToDecimals2(
                        _vm.BalanceSystem.HBG,
                        '',
                        4
                    )) + " HBG"),"readonly":""}})],1),_c('b-form-group',[_c('label',{attrs:{"for":"inputAmount"}},[_vm._v("Deposit Amount")]),_c('b-form-input',{attrs:{"min":"0","type":"number","id":"inputAmount","placeholder":"Enter Your Amount Deposit"},model:{value:(_vm.depositEcosystem.amount),callback:function ($$v) {_vm.$set(_vm.depositEcosystem, "amount", $$v)},expression:"depositEcosystem.amount"}})],1),_c('b-form-group',[_c('label',{attrs:{"for":"inputAmount"}},[_vm._v("Fee")]),_c('b-form-input',{staticClass:"pl-2",attrs:{"min":"0","value":((_vm.depositEcosystem.feeTransfer * 100) + "%"),"readonly":""}})],1),_c('b-form-group',[_c('label',{attrs:{"for":"inputAmount"}},[_vm._v("Actually Receive")]),_c('b-form-input',{staticClass:"pl-2",attrs:{"min":"0","value":((_vm.TruncateToDecimals2(
                        _vm.depositEcosystem.amount -
                            _vm.depositEcosystem.amount *
                                _vm.depositEcosystem.feeTransfer,
                        '',
                        4
                    )) + " HBG"),"readonly":""}})],1),_c('b-button',{staticClass:"button-confirm",attrs:{"type":"submit","disabled":_vm.depositEcosystem.amount > _vm.BalanceSystem.HBG}},[_vm._v(" Confirm ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }