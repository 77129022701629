<script>
import { mapGetters, mapMutations } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';
import HeroItem from '../shared/heroItem.vue';

export default {
    components: { Paginate, HeroItem },
    props: {
        isClickedReset: { type: Boolean, default: false },
    },
    data() {
        return {
            approve: false,
            heroInsideModal: null,
            modalValue: {
                sellPrice: 0,
                addressTransfer: '',
                tokenId: null,
            },
            isShowModalCancel: false,
            isShowModalSell: false,
            isShowModalTransfer: false,
            filterHero: {
                limit: 6,
                page: 1,
                ratity: null,
                tokenId: null,
            },
            isShowModalSellSuccess: false,
            isShowModalTransferSuccess: false,
            isShowModalCancelSuccess: false,
            tabPicker: [
                {
                    text: 'All',
                    value: null,
                },
                {
                    text: 'Common',
                    value: 'Common',
                },
                {
                    text: 'Rare',
                    value: 'Rare',
                },
                {
                    text: 'Epic',
                    value: 'Epic',
                },
                {
                    text: 'Legend',
                    value: 'Legend',
                },
            ],
            limit: [
                {
                    text: 'Display 6 Hero ',
                    value: 6,
                },
                {
                    text: 'Display 12 Hero ',
                    value: 12,
                },
                {
                    text: 'Display 20 Hero ',
                    value: 20,
                },
                {
                    text: 'Display 30 Hero ',
                    value: 30,
                },
                {
                    text: 'Display 50 Hero ',
                    value: 50,
                },
                {
                    text: 'Display 100 Hero ',
                    value: 100,
                },
            ],
            filterRare: [
                { value: null, text: 'All' },
                { value: 'Common', text: 'Common' },
                { value: 'Rare', text: 'Rare' },
                { value: 'Epic', text: 'Epic' },
                { value: 'Legend', text: 'Legend' },
            ],
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        getRept() {
            const { web3 } = this.$store.state.contract;
            web3.provider.eth.getTransactionReceipt(
                '0x9390124cbf20e1e423c6c5486476ff1660e3e3f7736d2e66fac1f81bc93f00ec',
                (err, txReceipt) => {
                    console.log(err, txReceipt);
                },
            );
        },
        getPercents(current, end) {
            const percent = (current / end) * 100;
            if (percent < 20) {
                return 20;
            }
            if (percent >= 100) {
                return 100;
            }
            return percent;
        },
        onAction() {
            this.$toastr.w('', 'Coming Soon!');
        },
        onChangePage(pageNext) {
            this.filterHero.page = pageNext;
            this.$store.dispatch('hero/req_getListHero', this.filterHero);
        },
        async ApproveSell(hero) {
            const { web3 } = this.$store.state.contract;
            const { marketNew } = this.$store.state.contract.marketplace;
            const contracts = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            const { version, tokenId } = hero;
            let HeroContract = null;

            Object.keys(contracts).forEach((key) => {
                if (key.includes('hero')) {
                    if (
                        contracts[key].addr.toLowerCase() ===
                        version.toLowerCase()
                    ) {
                        HeroContract = contracts[key];
                    }
                }
            });

            this.onLoad();
            const { sellPrice } = this.modalValue;
            if (sellPrice < 0 || this.isNaN(sellPrice) || !sellPrice) {
                this.$toastr.e(
                    'Min sell is 1 USD, Please check your price sell',
                    'Open Failed',
                );
                this.outLoad();
                return;
            }
            this.modalValue.tokenId = tokenId;
            const txObj = HeroContract.cont.methods
                .approve(marketNew.addr, tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                // Calculate gas
                gas = await HeroContract.cont.methods
                    .approve(marketNew.addr, tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;

            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: HeroContract.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                let submitted = true;
                // Kết quả transaction
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        if (txData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            await this.onSellHero(hero);
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        // eslint-disable-next-line consistent-return
        async onSellHero(hero) {
            // this.onLoad();
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const { sellPrice } = this.modalValue;
            const { version, tokenId } = hero;
            if (parseFloat(sellPrice) > 1000000000 * this.Balance.price) {
                this.messageErrorToastr(
                    `Sell price must be less than ${
                        1000000000 * this.Balance.price
                    } USD`,
                );
                this.outLoad();
                return;
            }
            if (sellPrice < 0 || this.isNaN(sellPrice) || !sellPrice) {
                this.$toastr.w(
                    'Min sell is 1 USD, Please check your price sell',
                    'Open Failed',
                );
                return;
            }
            const deciamalPrice = web3.provider.utils.toWei(
                sellPrice.toString(),
                'ether',
            );
            const txObj = marketNew.cont.methods
                .sell(tokenId, deciamalPrice, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await marketNew.cont.methods
                    .sell(tokenId, deciamalPrice, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txSellData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txSellData != null) {
                        clearInterval(check);
                        if (txSellData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('sell', txSellData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        async onTransferHero(hero) {
            // this.$toastr.w('Function transfer hero is maintenance', 'Oops!');
            this.onLoad();
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const contracts = this.$store.state.contract.marketplace;

            const { version, tokenId } = hero;
            let gas = 0;
            let HeroContract = null;

            Object.keys(contracts).forEach((key) => {
                if (key.includes('hero')) {
                    if (
                        contracts[key].addr.toLowerCase() ===
                        version.toLowerCase()
                    ) {
                        HeroContract = contracts[key];
                    }
                }
            });
            let txObj = '';
            try {
                txObj = await HeroContract.cont.methods
                    .transferFrom(
                        addr,
                        this.modalValue.addressTransfer,
                        tokenId,
                    )
                    .encodeABI();
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr('Address Invalid');
                return false;
            }
            const gasLimit = await this.getLimitGas();
            try {
                gas = await HeroContract.cont.methods
                    .transferFrom(
                        addr,
                        this.modalValue.addressTransfer,
                        tokenId,
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: HeroContract.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('transfer', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async onCancelHero(hero) {
            this.onLoad();
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const { version, tokenId } = hero;
            const txObj = await marketNew.cont.methods
                .cancelSale(tokenId, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await marketNew.cont.methods
                    .cancelSale(tokenId, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancel', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        async onCancelHeroOld(tokenId) {
            console.log(tokenId);
            this.onLoad();
            const { market } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await market.cont.methods
                .cancelHeroSale(tokenId)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await market.cont.methods
                    .cancelHeroSale(tokenId)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: market.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txTransferData.status) {
                            console.log(txTransferData);
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('cancelOld', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        onTransferHeroFree(id) {
            this.isShowModalTransfer = false;
            this.$store.dispatch('hero/req_postTransferFree', {
                tokenId: id,
                newOwner: this.modalValue.addressTransfer,
            });
            this.outLoad();
            this.$bvModal.show('modal-transfer-hero-success');
        },
        onShowCancel(hero) {
            this.isShowModalSell = false;
            this.isShowModalTransfer = false;
            this.isShowModalCancel = true;
            this.heroInsideModal = hero;
        },
        showModalSell(e) {
            this.isShowModalCancel = false;
            this.isShowModalSell = true;
            this.isShowModalTransfer = false;
            this.heroInsideModal = e;
        },
        showModalTransfer(e) {
            this.isShowModalCancel = false;
            this.isShowModalSell = false;
            this.isShowModalTransfer = true;
            this.heroInsideModal = e;
        },
        clearModalValue() {
            this.modalValue = {
                sellPrice: 0,
                addressTransfer: '',
            };
        },
        sendTransaction(type, txData) {
            if (type === 'transfer') {
                console.log(txData);

                this.isShowModalTransfer = false;
                this.$store.dispatch('hero/req_postTransferSuccess', {
                    transactionHash: txData.transactionHash,
                });
                const { web3 } = this.$store.state.contract;
                web3.provider.eth.getTransactionReceipt(
                    txData.transactionHash,
                    (err, txReceipt) => {
                        console.log(err, txReceipt);
                    },
                );

                this.outLoad();
                this.$bvModal.show('modal-transfer-hero-success');
            }
            if (type === 'sell') {
                this.isShowModalSell = false;
                this.$store.dispatch('hero/req_postSellSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-sell-hero-success');
            }
            if (type === 'cancel') {
                this.isShowModalCancel = false;
                this.$store.dispatch('hero/req_postCancelSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-cancel-hero-success');
            }
            if (type === 'cancelOld') {
                this.isShowModalCancel = false;
                this.$store.dispatch('hero/req_postCancelOldSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.outLoad();
                this.$bvModal.show('modal-cancel-hero-success');
            }
        },
        resetFilter() {
            this.filterHero = {
                limit: 6,
                page: 1,
                ratity: null,
                tokenId: null,
            };
        },
        onSearchOld() {
            this.filterHero.page = 1;
            this.$store.dispatch('hero/req_getListHero', {
                ...this.filterHero,
                market: 'old',
            });
        },
        resetItemSearch() {
            this.resetFilter();
        },
    },
    watch: {
        'filterHero.ratity': {
            handler() {
                this.filterHero.page = 1;
                this.$store.dispatch('hero/req_getListHero', this.filterHero);
            },
            deep: true,
        },
        'filterHero.limit': {
            handler() {
                this.filterHero.page = 1;
                this.$store.dispatch('hero/req_getListHero', this.filterHero);
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters({
            ListHero: 'hero/ListHero',
            Balance: 'wallet/Balance',
        }),
    },
    mounted() {
        this.$store.dispatch('hero/req_getListHero', this.filterHero);
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'hero/ACTION_SUCCESS':
                    this.$store.dispatch(
                        'hero/req_getListHero',
                        this.filterHero,
                    );
                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <b-container class="w-100">
        <b-row class="hero-list">
            <div class="col-12">
                <div class="inventory-tab-item">
                    <div class="inventory-box-search">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-sm-5 col-12 my-3">
                                <label for="inputSearch" class="d-flex">
                                    <span
                                        v-if="filterHero.tokenId !== ''"
                                        class="text-white"
                                        >#</span
                                    >
                                    <input
                                        v-model="filterHero.tokenId"
                                        type="text"
                                        id="inputSearch"
                                        placeholder="Search ID"
                                        class="w-100"
                                        @keyup.enter="onChangePage(1)"
                                        maxlength="10"
                                    />
                                    <img
                                        src="../../assets/images/icons/search-new.svg"
                                        alt=""
                                        class="btn-search"
                                    />
                                </label>
                            </div>
                            <div class="col-lg-5 col-sm-4 col-12 my-3">
                                <label
                                    for="inputSelect"
                                    class="d-flex align-items-center justify-content-between"
                                >
                                    <b-form-select
                                        v-model="filterHero.ratity"
                                        :options="filterRare"
                                        class="custom-select"
                                    ></b-form-select>
                                    <img
                                        src="../../assets/images/icons/icon-drop.svg"
                                        alt=""
                                        class="img-dropDown"
                                    />
                                </label>
                            </div>
                            <div
                                class="col-lg-2 col-sm-3 col-12 px-3 px-lg-2 px-md-0 d-flex align-items-center justify-content-between filter-form"
                            >
                                <button
                                    @click="onChangePage(1)"
                                    class="bede-btn mr-2"
                                >
                                    <p class="m-0">FIND HERO</p>
                                </button>
                                <button @click="resetFilter" class="d-sm-none">
                                    <img
                                        src="~@/assets/images/icons/refesh-icon.svg"
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-col cols="12" class="box-name" v-if="ListHero.list.length <= 0">
                <div class="after">No Hero</div>
            </b-col>
            <b-col
                cols="12"
                md="4"
                lg="4"
                sm="6"
                class="col-xs-6 px-0 px-sm-2"
                v-for="hero in ListHero.list"
                :key="`list-hero-inventory-${hero.tokenId}`"
            >
                <HeroItem
                    :hero="hero"
                    class="w-100 my-5"
                    @showModalTransfer="showModalTransfer"
                    @showModalSell="showModalSell"
                    @onShowCancel="onShowCancel"
                />
            </b-col>
        </b-row>
        <div class="d-flex justify-content-center mt-4 text-white" v-if="false">
            Display
            <span class="text-success px-2 font-weight-bold">
                {{ filterHero.limit * (filterHero.page - 1) + 1 }} -
                {{
                    filterHero.limit * (filterHero.page - 1) + filterHero.limit
                }}
            </span>
            Hero of
            <span class="text-success px-2 font-weight-bold">
                {{ ListHero.totalHero }}
            </span>
            Hero
        </div>
        <div class="d-flex justify-content-center mt-2">
            <Paginate @current="onChangePage" :totalPages="ListHero.total" />
        </div>

        <b-modal
            id="modal-sell-hero"
            title="CONFIRM SELL"
            hide-footer
            :centered="true"
            v-model="isShowModalSell"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
            >
                <div class="hero" v-if="heroInsideModal">
                    <div class="left-hero">
                        <div class="top-hero">
                            <div class="id-hero" ref="idHero">
                                #{{ heroInsideModal.tokenId }}
                            </div>
                            <div class="hero-img">
                                <div class="avatar-hero">
                                    <img
                                        v-if="heroInsideModal.name"
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${heroInsideModal.name.toLowerCase()}-${heroInsideModal.skin.toLowerCase()}`,
                                                heroInsideModal.version,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="right-hero">
                                    <div class="stat-hero">
                                        <div class="hero-level">
                                            {{
                                                10 > heroInsideModal.level
                                                    ? '0' +
                                                      heroInsideModal.level.toString()
                                                    : heroInsideModal.level
                                            }}
                                        </div>
                                        <img
                                            :key="`inventory-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.ratity.toLowerCase()}.png`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-2-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.skin.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-3-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.type.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <div class="hero-summon">
                                            {{
                                                getSummon(
                                                    heroInsideModal.quantitySummon,
                                                    heroInsideModal.maxSummon,
                                                    heroInsideModal.version,
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-hero">
                            <div class="hero-name">
                                the
                                <span>
                                    {{ heroInsideModal.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="box-input-group mx-auto d-flex align-items-end justify-content-center mt-3"
                >
                    <label class="mr-2 mb-0"> Price </label>
                    <div class="left-input-field">
                        <div class="top">
                            ~
                            {{
                                TruncateToDecimals2(
                                    modalValue.sellPrice * Balance.price,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                        <div class="bottom">
                            <b-form-input
                                v-model="modalValue.sellPrice"
                                class="not-max"
                                type="number"
                                min="1"
                                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 48 && event.keyCode !== 187 && event.keyCode !== 190"
                            ></b-form-input>
                            <div class="text text-black">USD</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="ApproveSell(heroInsideModal)"
                    >
                        Confirm
                    </b-button>
                    <b-button
                        class="btn-cancel d-block text-white rounded-0"
                        @click="$bvModal.hide('modal-sell-hero')"
                    >
                        Cancel
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="modal-sell-hero-success"
            title="SELL HERO SUCCESS"
            hide-footer
            :centered="true"
            v-model="isShowModalSellSuccess"
            @hidden="clearModalValue"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
            >
                <div class="hero" v-if="heroInsideModal">
                    <div class="left-hero">
                        <div class="top-hero">
                            <div class="id-hero" ref="idHero">
                                #{{ heroInsideModal.tokenId }}
                            </div>
                            <div class="hero-img">
                                <div class="avatar-hero">
                                    <img
                                        v-if="heroInsideModal.name"
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${heroInsideModal.name.toLowerCase()}-${heroInsideModal.skin.toLowerCase()}`,
                                                heroInsideModal.version,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="right-hero">
                                    <div class="stat-hero">
                                        <div class="hero-level">
                                            {{
                                                10 > heroInsideModal.level
                                                    ? '0' +
                                                      heroInsideModal.level.toString()
                                                    : heroInsideModal.level
                                            }}
                                        </div>
                                        <img
                                            :key="`inventory-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.ratity.toLowerCase()}.png`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-2-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.skin.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-3-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.type.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <div class="hero-summon">
                                            {{
                                                getSummon(
                                                    heroInsideModal.quantitySummon,
                                                    heroInsideModal.maxSummon,
                                                    heroInsideModal.version,
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-hero">
                            <div class="hero-name">
                                the
                                <span>
                                    {{ heroInsideModal.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="box-input-group mx-auto d-flex align-items-end justify-content-center mt-3"
                >
                    <label class="mr-2 mb-0"> Price </label>
                    <div class="left-input-field">
                        <div class="top">
                            ~
                            {{
                                TruncateToDecimals2(
                                    modalValue.sellPrice * Balance.price,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                        <div class="bottom">
                            <b-form-input
                                v-model="modalValue.sellPrice"
                                class="not-max"
                            ></b-form-input>
                            <div class="text text-black">USD</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="isShowModalSellSuccess = false"
                    >
                        Confirm
                    </b-button>
                </div>
            </div>
        </b-modal>

        <b-modal
            id="modal-cancel-sell"
            title="CANCEL SELL HERO"
            hide-footer
            :centered="true"
            v-model="isShowModalCancel"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
            >
                <div class="hero" v-if="heroInsideModal">
                    <div class="left-hero">
                        <div class="top-hero">
                            <div class="id-hero" ref="idHero">
                                #{{ heroInsideModal.tokenId }}
                            </div>
                            <div class="hero-img">
                                <div class="avatar-hero">
                                    <img
                                        v-if="heroInsideModal.name"
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${heroInsideModal.name.toLowerCase()}-${heroInsideModal.skin.toLowerCase()}`,
                                                heroInsideModal.version,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="right-hero">
                                    <div class="stat-hero">
                                        <div class="hero-level">
                                            {{
                                                10 > heroInsideModal.level
                                                    ? '0' +
                                                      heroInsideModal.level.toString()
                                                    : heroInsideModal.level
                                            }}
                                        </div>
                                        <img
                                            :key="`inventory-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.ratity.toLowerCase()}.png`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-2-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.skin.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-3-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.type.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <div class="hero-summon">
                                            {{
                                                getSummon(
                                                    heroInsideModal.quantitySummon,
                                                    heroInsideModal.maxSummon,
                                                    heroInsideModal.version,
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-hero">
                            <div class="hero-name">
                                the
                                <span>
                                    {{ heroInsideModal.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="box-input-group flex-column mx-auto d-flex align-items-center justify-content-center mt-5"
                    v-if="heroInsideModal"
                >
                    <label class="mr-2 mb-0">Price sell</label>
                    <div
                        class="box-input-group mx-auto d-flex align-items-center justify-content-center mt-1"
                        v-if="heroInsideModal.price"
                    >
                        <b-form-input
                            class="text-white"
                            :value="
                                TruncateToDecimals2(
                                    heroInsideModal.price,
                                    '',
                                    0,
                                ) || 0
                            "
                            readonly
                        ></b-form-input>
                        <div class="text text-white">HBG</div>
                    </div>
                </div>
                <div
                    class="d-flex justify-content-around mt-5 w-100"
                    v-if="heroInsideModal"
                >
                    <b-button
                        v-if="
                            1 >
                            new Date('2022-06-27 00:00:00').getTime() -
                                new Date(heroInsideModal.timeSell).getTime()
                        "
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onCancelHero(heroInsideModal)"
                    >
                        Cancel Now
                    </b-button>
                    <b-button
                        v-else
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onCancelHeroOld(heroInsideModal.tokenId)"
                    >
                        Cancel Now
                    </b-button>
                    <b-button
                        class="btn-cancel d-block text-white rounded-0"
                        @click="$bvModal.hide('modal-cancel-sell')"
                    >
                        Cancel
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="modal-cancel-hero-success"
            title="CANCEL SELL HERO SUCCESS"
            hide-footer
            :centered="true"
            v-model="isShowModalCancelSuccess"
            @hidden="clearModalValue"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
            >
                <div class="hero" v-if="heroInsideModal">
                    <div class="left-hero">
                        <div class="top-hero">
                            <div class="id-hero" ref="idHero">
                                #{{ heroInsideModal.tokenId }}
                            </div>
                            <div class="hero-img">
                                <div class="avatar-hero">
                                    <img
                                        v-if="heroInsideModal.name"
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${heroInsideModal.name.toLowerCase()}-${heroInsideModal.skin.toLowerCase()}`,
                                                heroInsideModal.version,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="right-hero">
                                    <div class="stat-hero">
                                        <div class="hero-level">
                                            {{
                                                10 > heroInsideModal.level
                                                    ? '0' +
                                                      heroInsideModal.level.toString()
                                                    : heroInsideModal.level
                                            }}
                                        </div>
                                        <img
                                            :key="`inventory-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.ratity.toLowerCase()}.png`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-2-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.skin.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-3-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.type.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <div class="hero-summon">
                                            {{
                                                getSummon(
                                                    heroInsideModal.quantitySummon,
                                                    heroInsideModal.maxSummon,
                                                    heroInsideModal.version,
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-hero">
                            <div class="hero-name">
                                the
                                <span>
                                    {{ heroInsideModal.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="isShowModalCancelSuccess = false"
                    >
                        Confirm
                    </b-button>
                </div>
            </div>
        </b-modal>

        <b-modal
            id="modal-transfer-hero"
            title="CONFIRM TRANSFER"
            hide-footer
            :centered="true"
            v-model="isShowModalTransfer"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
            >
                <div
                    class="hero"
                    v-if="heroInsideModal && heroInsideModal.ratity"
                >
                    <div class="left-hero">
                        <div class="top-hero">
                            <div class="id-hero" ref="idHero">
                                {{
                                    heroInsideModal.ratity.toLowerCase() !==
                                    'free'
                                        ? `#${heroInsideModal.tokenId}`
                                        : `Free${heroInsideModal.tokenId}`
                                }}
                            </div>
                            <div class="hero-img">
                                <div class="avatar-hero">
                                    <img
                                        v-if="heroInsideModal.name"
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${heroInsideModal.name.toLowerCase()}-${heroInsideModal.skin.toLowerCase()}`,
                                                heroInsideModal.version,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="right-hero">
                                    <div class="stat-hero">
                                        <div class="hero-level">
                                            {{
                                                10 > heroInsideModal.level
                                                    ? '0' +
                                                      heroInsideModal.level.toString()
                                                    : heroInsideModal.level
                                            }}
                                        </div>
                                        <img
                                            :key="`inventory-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.ratity.toLowerCase()}.png`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-2-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.skin.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-3-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.type.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <div class="hero-summon">
                                            {{
                                                getSummon(
                                                    heroInsideModal.quantitySummon,
                                                    heroInsideModal.maxSummon,
                                                    heroInsideModal.version,
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-hero">
                            <div class="hero-name">
                                the
                                <span>
                                    {{ heroInsideModal.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mx-auto" style="width: 80%">
                    <label class="mb-0 mx-auto d-block label-address">
                        Address
                    </label>
                    <b-form-input
                        v-model="modalValue.addressTransfer"
                        class="input-address"
                    >
                    </b-form-input>
                </div>

                <div
                    class="d-flex justify-content-around mt-5 w-100"
                    v-if="heroInsideModal"
                >
                    <b-button
                        v-if="heroInsideModal.ratity.toLowerCase() !== 'free'"
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onTransferHero(heroInsideModal)"
                    >
                        Confirm
                    </b-button>
                    <b-button
                        v-else
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="onTransferHeroFree(heroInsideModal.tokenId)"
                    >
                        Confirm
                    </b-button>
                    <b-button
                        class="btn-cancel d-block text-white rounded-0"
                        @click="$bvModal.hide('modal-transfer-hero')"
                    >
                        Cancel
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="modal-transfer-hero-success"
            title="TRANSFER HERO SUCCESS"
            hide-footer
            :centered="true"
            @hidden="clearModalValue"
            v-model="isShowModalTransferSuccess"
        >
            <div
                class="form-group d-flex justify-content-center flex-wrap mt-3"
            >
                <div
                    class="hero"
                    v-if="heroInsideModal && heroInsideModal.ratity"
                >
                    <div class="left-hero">
                        <div class="top-hero">
                            <div class="id-hero" ref="idHero">
                                {{
                                    heroInsideModal.ratity.toLowerCase() !==
                                    'free'
                                        ? `#${heroInsideModal.tokenId}`
                                        : `Free${heroInsideModal.tokenId}`
                                }}
                            </div>
                            <div class="hero-img">
                                <div class="avatar-hero">
                                    <img
                                        v-if="heroInsideModal.name"
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${heroInsideModal.name.toLowerCase()}-${heroInsideModal.skin.toLowerCase()}`,
                                                heroInsideModal.version,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="right-hero">
                                    <div class="stat-hero">
                                        <div class="hero-level">
                                            {{
                                                10 > heroInsideModal.level
                                                    ? '0' +
                                                      heroInsideModal.level.toString()
                                                    : heroInsideModal.level
                                            }}
                                        </div>
                                        <img
                                            :key="`inventory-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.ratity.toLowerCase()}.png`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-2-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.skin.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <img
                                            :key="`inventory-3-${heroInsideModal.id}`"
                                            :src="
                                                require(`@/assets/images/mockup/inventory/${heroInsideModal.type.toLowerCase()}.png?v=1`)
                                            "
                                            alt=""
                                        />
                                        <div class="hero-summon">
                                            {{
                                                getSummon(
                                                    heroInsideModal.quantitySummon,
                                                    heroInsideModal.maxSummon,
                                                    heroInsideModal.version,
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-hero">
                            <div class="hero-name">
                                the
                                <span>
                                    {{ heroInsideModal.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mx-auto" style="width: 80%">
                    <label class="mb-0 mx-auto d-block label-address"
                        >Address</label
                    >
                    <b-form-input
                        v-model="modalValue.addressTransfer"
                        class="input-address"
                        readonly
                    >
                    </b-form-input>
                </div>

                <div class="d-flex justify-content-around mt-5 w-100">
                    <b-button
                        class="m-0 button-confirm d-block"
                        variant="primary"
                        @click="isShowModalTransferSuccess = false"
                    >
                        Confirm
                    </b-button>
                </div>
            </div>
        </b-modal>
    </b-container>
</template>

<style scoped lang="scss">
input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
.filter-form {
    .bede-btn {
        max-width: 170px;
        height: 50px;
        width: 100%;
        padding: 0 8px;
        p {
            font-size: clamp(12px, 1.5vw, 16px);
        }
    }
}
</style>
<style lang="scss">
.hero-list {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    // @media (max-width: 991px) {
    //     justify-content: center;
    // }

    .btn-gr {
        position: relative;
        max-width: 255px;
        min-width: 255px;
        margin: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
    }
    .form-sl {
        position: relative;
        max-width: 255px;
        min-width: 255px;
        margin: 5px 10px;
        flex: 1;
        img {
            height: 15px;
            width: auto;
            position: absolute;
            right: 15px;
            bottom: 0;
            top: 0;
            margin: auto;
        }
    }
    .box-input-group {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
        // max-width: 1;
        margin: 10px auto 25px;
        .icons {
            img {
                width: 17px;
                height: auto;
            }
        }
        input {
            outline: none;
            border: 1px solid #00ffff;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 100%;
            border-radius: 0;
            color: #fff;
            margin: 5px 10px;
            min-width: 255px;
            max-width: 255px;
            flex: 1;
        }
        button {
            background: #00ffff;
            border-radius: 0;
            height: 40px;
            color: #000;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            &.btn-danger {
                background: #ff0000;
                color: #fff;
            }
            &.btn-warning {
                background: #febf32;
                color: #000;
                font-size: 16px;
                line-height: 100%;
            }
        }
        select {
            min-width: 255px;
            max-width: 255px;
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #00ffff;
            option {
                background: #00000b;
            }
        }
    }
    .form-limit {
        position: relative;
        flex: 1;
        margin: 5px 10px;
        min-width: 255px;
        max-width: 255px;
        img {
            height: 15px;
            width: auto;
            position: absolute;
            right: 15px;
            bottom: 0;
            top: 0;
            margin: auto;
        }
        select {
            min-width: 175px;
        }
    }
    .px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .box-name {
        position: relative;
        margin: auto;
        width: 100%;
        min-height: 130px;
        text-align: center;
        justify-content: center;
        display: flex;
        .after {
            font-size: clamp(60px, 4vw, 80px);
            font-family: 'HHBI';
            text-align: center;
            text-transform: uppercase;
            color: #4effff;
            text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            position: relative;
        }
        @media (max-width: 991px) {
            .after {
                font-size: clamp(40px, 3vw, 75px);
            }
        }
        @media (max-width: 575px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
    .hero {
        flex: 1;
        min-width: 200px;
        max-width: 225px;
        width: 100%;
        // min-height: 350px;
        // margin: 0 15px 30px;
        margin: 0 auto 25px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 1 1 auto;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        max-width: calc(100% - 50px);
                        position: relative;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .right-hero {
                        flex: 0 0 50px;
                        max-width: 50px;
                        .stat-hero {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            .hero-level {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ffff;
                                border: 1px solid #00ffff;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                            }
                            .hero-summon {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ff60;
                                border: 1px solid #00ff60;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                                font-size: 15px;
                            }
                            img {
                                width: 35px;
                                height: auto;
                                max-height: 35px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }
                .hero-exp {
                    position: relative;
                    width: 100%;
                    border: 1px solid #00ffff;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px auto 15px;
                    background: rgba(0, 255, 255, 0.2);
                    .text {
                        position: relative;
                        z-index: 10;
                        font-weight: 500;
                        font-size: 12px;
                        font-style: italic;
                        span {
                            font-weight: 700;
                            font-family: 'Helvetica';
                            font-size: 15px;
                        }
                    }
                    .current {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
                .hero-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        width: auto;
                        padding: 5px;
                        min-width: 100px;
                        height: 40px;
                        border-radius: 5px;
                        border: 0;
                        color: #ffff;
                        &.sell {
                            background: {
                                image: url('~@/assets/images/mockup/account/sell.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.transfer {
                            background: {
                                image: url('~@/assets/images/mockup/account/transfer.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.price {
                            background: {
                                image: url('~@/assets/images/mockup/account/price.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                                min-height: 45px;
                                padding: 0 10px;
                            }
                            &.full {
                                min-width: 175px;
                                margin: 0 auto 10px;
                            }
                        }
                        &.cancel {
                            background: {
                                image: url('~@/assets/images/mockup/account/cancel.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                    }
                }
            }
            @media (min-width: 414px) and (max-width: 575px) {
                .top-hero {
                    .hero-img {
                        .avatar-hero {
                            max-width: calc(100% - 70px);
                        }
                        .right-hero {
                            flex: 0 0 50px;
                            max-width: 50px;
                            .stat-hero {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                flex-wrap: wrap;
                                .hero-level {
                                    width: 30px;
                                    height: 30px;
                                    margin-bottom: 10px;
                                    background: #000b1a;
                                    color: #00ffff;
                                    border: 1px solid #00ffff;
                                    font-size: larger;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    text-align: center;
                                    font-weight: 900;
                                    letter-spacing: 1px;
                                }
                                img {
                                    width: 30px;
                                    height: auto;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
                .bottom-hero {
                    max-width: 190px;
                    width: 100%;
                    .hero-action {
                        button {
                            min-width: 90px;
                        }
                    }
                }
            }
        }
    }
    .time-lock {
        background: linear-gradient(45deg, #053745, #061324);
        position: relative;
        margin-top: -45px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 10px);
        &-title {
            background: linear-gradient(45deg, #002246, #061324);
            color: #00ffff;
            position: absolute;
            top: -15px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: max-content;
            margin: auto;
            font-weight: 800;
            text-shadow: 1px 1px 1px #061324;
            font-size: 11px;
            letter-spacing: 0.5px;
            padding: 5px;
            left: 0;
            right: 0;
            transform: skewX(-10deg);
            div {
                transform: skewX(10deg);
            }
        }
        &-content {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            color: #ff0000;
            height: 100%;
            width: 100%;
            font-size: 11px;
            padding-top: 15px;
            line-height: 120%;
            font-family: 'HHBI';
            letter-spacing: 0.8px;
        }
    }
}
#modal-cancel-hero-success,
#modal-cancel-sell,
#modal-sell-hero,
#modal-sell-hero-success,
#modal-transfer-hero,
#modal-transfer-hero-success {
    .hero {
        flex: 1;
        min-width: 200px;
        max-width: 225px;
        width: 100%;
        margin: 0 15px 0px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .left-hero {
            flex: 1 1 auto;
            .top-hero {
                .id-hero {
                    color: #00ffff;
                    font-size: 1.2em;
                    font-weight: 800;
                    margin-bottom: 0px;
                    font-family: 'HHBI';
                    line-height: 1.2em;
                }
                .hero-img {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    .avatar-hero {
                        flex: 1 1 auto;
                        // border: 1px solid #00ffff;
                        max-width: calc(100% - 50px);
                        position: relative;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    .right-hero {
                        flex: 0 0 50px;
                        max-width: 50px;
                        .stat-hero {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            .hero-level {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ffff;
                                border: 1px solid #00ffff;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                            }
                            .hero-summon {
                                width: 35px;
                                height: 35px;
                                margin-bottom: 10px;
                                background: #000b1a;
                                color: #00ff60;
                                border: 1px solid #00ff60;
                                font-size: larger;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 900;
                                letter-spacing: 1px;
                                font-size: 15px;
                            }
                            img {
                                width: 35px;
                                height: auto;
                                max-height: 35px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .bottom-hero {
                .hero-name {
                    font-size: 1.1em;
                    text-align: center;
                    margin: 10px auto;
                    font-family: 'HHBI';
                    text-transform: uppercase;
                    color: #00ffff;
                    line-height: 130%;
                    text-shadow: 0px 1px 10px rgba(0, 255, 255, 0.7);
                    span {
                        font-size: 1.5em;
                        color: #fff;
                    }
                }
                .hero-exp {
                    position: relative;
                    width: 100%;
                    border: 1px solid #00ffff;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 5px auto 15px;
                    background: rgba(0, 255, 255, 0.2);
                    .text {
                        position: relative;
                        z-index: 10;
                        font-weight: 500;
                        font-size: 12px;
                        font-style: italic;
                        span {
                            font-weight: 700;
                            font-family: 'Helvetica';
                            font-size: 15px;
                        }
                    }
                    .current {
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 0;
                    }
                }
                .hero-action {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button {
                        width: auto;
                        padding: 5px;
                        min-width: 100px;
                        height: 40px;
                        border-radius: 5px;
                        border: 0;
                        color: #ffff;
                        &.sell {
                            background: {
                                image: url('~@/assets/images/mockup/account/sell.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.transfer {
                            background: {
                                image: url('~@/assets/images/mockup/account/transfer.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.price {
                            background: {
                                image: url('~@/assets/images/mockup/account/price.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                        &.cancel {
                            background: {
                                image: url('~@/assets/images/mockup/account/cancel.png');
                                repeat: no-repeat;
                                size: 100% 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .box-input-group {
        font-family: 'hemiheadRg-BoldItalic';
        height: 40px;
        // font-size: clamp(1rem, 1.5vw, 1.5rem);

        label {
            color: #fff;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
        }

        input {
            outline: none;
            margin: 0;
            border-radius: 0;
            color: #fff;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
            // height: 50px;
            height: 100%;

            border: 1px solid #00ffff;
            background: linear-gradient(to right, #18274a, #001326);
            display: block;
            width: 50%;

            &::placeholder {
                color: #fff;
                font-size: clamp(1rem, 1.5vw, 1.5rem);
            }
        }
        .text {
            background: #00ffff;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
            color: #000;
            line-height: 40px;
            padding: 0 10px 0 5px;
        }
    }

    .btn-cancel {
        background: none;
        border: 1px solid #00ffff;
        color: #000;
        font-family: 'helvetica';
        font-size: 20px;
        padding: 5px 20px;
        min-width: 140px;
        border-radius: 8px !important;
    }

    .label-address {
        font-family: 'hemiheadRg-BoldItalic';
        color: #fff;
        font-size: clamp(1rem, 1.5vw, 1.5rem);
    }

    .input-address {
        font-family: 'hemiheadRg-BoldItalic';
        outline: none;
        margin: 0;
        border-radius: 0;
        color: #fff;
        font-size: clamp(1rem, 1.5vw, 1.5rem);

        border: 1px solid #00ffff;
        background: linear-gradient(to right, #18274a, #001326);
        width: 100%;
    }
}
</style>

<style lang="scss" scoped>
.inventory-tab-item {
    font-family: 'Poppins';
    .inventory-tab-listHero a {
        text-decoration: none !important;
    }
    button {
        outline: unset !important;
    }
    input::placeholder {
        color: #f8f8f8;
    }
    .custom-select {
        outline: unset;
        box-shadow: none;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        height: 26px;
        position: relative;
        z-index: 5;
        width: 100%;
        padding-right: 0;
    }
    .img-dropDown {
        position: absolute;
        right: 32px;
        z-index: 0;
    }
}
</style>
