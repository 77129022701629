<template>
    <div
        class="p-0 container-image-effect"
        id="containerEffect"
    ></div>
</template>

<script>


export default {
    name: 'BurnModal',
    props: {
        urls: {
            type: String,
            default: null,
        },
    },
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {
        this.container = document.getElementById('containerEffect');
        if (this.urls) {
            this.onLoadComponent();
            this.imagesLoaded();
        }
    },
};
</script>

<style lang="scss">

</style>
