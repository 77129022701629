<script>
import { mapGetters, mapMutations } from 'vuex';
import BalanceBox from '@/components/shared/BalaneIngame.vue';

const axios = require('@/store/axios.config').default;

export default {
    components: {
        BalanceBox,
    },
    data() {
        return {
            depositEcosystem: {
                system: '',
                currency: 'HBG',
                amount: 0,
                fee: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            ListGameBalances: 'game/ListGameBalances',
            BalanceSystem: 'info/Balance',
            ListPlatform: 'info/ListPlatform',
        }),
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        onChooseEco(item) {
            this.depositEcosystem = {
                ...item,
                ...this.depositEcosystem,
            };
            this.$bvModal.show('deposit-ecosystem');
        },

        async onDepositEco() {
            this.onLoad();
            const { name: system, currency, amount } = this.depositEcosystem;
            if (amount < 1) {
                this.$toastr.w('Please enter amount greater than 0', 'Error');
                this.outLoad();
                return;
            }
            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                this.outLoad();
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Deposit Platform'],
            });
            if (!txData) {
                this.$toastr.e('Please Confirm Deposit', 'Error');
                this.outLoad();
                return;
            }

            this.deposit = {
                system,
                currency,
                amount,
                sign: txData,
            };
            // this.submitted = false;
            await axios.post('/api/me/platform/transfer', this.deposit);

            this.$store.dispatch('info/req_getBalance');
            this.$store.dispatch('game/req_getListGameBalances');
            this.$bvModal.hide('deposit-ecosystem');

            this.outLoad();
        },
        resetDepositEco() {
            this.depositEcosystem = {
                system: '',
                currency: 'HBG',
                amount: 0,
                fee: 0,
            };
        },
        readyConnect(name) {
            let isConnect;
            this.ListPlatform.forEach((element) => {
                if (element.name.includes(name))
                    isConnect = element.isConnected;
            });
            return isConnect;
        },
    },
    created() {
        this.$store.dispatch('game/req_getListGameBalances');
        this.$store.dispatch('info/req_getListConnectSystems');
    },
};
</script>
<template>
    <b-container class="wallet-tab">
        <div class="heading-section mb-4 mt-5">
            <div class="text">Hero Book Balance</div>
        </div>
        <BalanceBox class="mt-4" />
        <div class="heading-section mb-4 mt-5">
            <div class="text">Game Balance</div>
        </div>
        <b-row class="mt-4">
            <b-col
                v-for="(item, idx) in ListGameBalances"
                :key="item + idx"
                md="6"
                lg="12"
                xl="6"
                class="px-1"
            >
                <div class="system-wallet main-box">
                    <div class="system-wallet-header border-b">
                        <div class="system-wallet-header-image">
                            <img :src="item.image" alt="" />
                        </div>
                        <div class="system-wallet-header-title">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="system-wallet-body">
                        <div class="system-wallet-body-image"></div>
                        <div class="system-wallet-body-amount">
                            {{
                                TruncateToDecimals2(
                                    item.balance.HBG,
                                    '',
                                    4,
                                )
                            }}
                        </div>
                        <div class="system-wallet-body-symbol">HBG</div>
                    </div>
                    <div class="system-wallet-footer">
                        <b-button
                            v-if="item.isTransfer && readyConnect(item.name)"
                            class="system-wallet-footer-button"
                            @click="onChooseEco(item)"
                        >
                            <div class="system-wallet-footer-button-text">
                                Deposit
                            </div>
                        </b-button>
                        <router-link
                            v-else
                            :to="{
                                name: 'Setting',
                                hash: '#ecosystem',
                            }"
                        >
                            <b-button class="system-wallet-footer-button">
                                <div class="system-wallet-footer-button-text">
                                    Connect {{ item.name }}
                                </div>
                            </b-button>
                        </router-link>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
            id="deposit-ecosystem"
            :title="`Deposit From  HeroBook To ${depositEcosystem.name}`"
            hide-footer
            @hidden="resetDepositEco"
        >
            <b-form @submit.prevent="onDepositEco()">
                <b-form-group>
                    <label for="inputAmount">Your Address Balance</label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            BalanceSystem.HBG,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Deposit Amount</label>
                    <b-form-input
                        min="0"
                        v-model="depositEcosystem.amount"
                        type="number"
                        id="inputAmount"
                        placeholder="Enter Your Amount Deposit"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Fee</label>
                    <b-form-input
                        class="pl-2"
                        min="0"
                        :value="`${depositEcosystem.feeTransfer * 100}%`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount">Actually Receive</label>
                    <b-form-input
                        class="pl-2"
                        min="0"
                        :value="`${TruncateToDecimals2(
                            depositEcosystem.amount -
                                depositEcosystem.amount *
                                    depositEcosystem.feeTransfer,
                            '',
                            4,
                        )} HBG`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-button
                    class="button-confirm"
                    type="submit"
                    :disabled="depositEcosystem.amount > BalanceSystem.HBG"
                >
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
    </b-container>
</template>
<style lang="scss" scoped>
.system-wallet {
    width: 100%;
    min-height: 150px;
    border: 1px solid #00ffff;
    margin-bottom: 15px;
    padding: 10px 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &:after,
    &::before {
        z-index: 0;
        content: '';
        width: 6px;
        height: 6px;
        background: #00ffff;
    }

    &:after {
        position: absolute;
        top: 1px;
        right: 1px;
    }
    &::before {
        position: absolute;
        bottom: 1px;
        left: 1px;
    }
    &.main-box {
        min-height: 225px;
        padding: 15px 25px;
        @media (max-width: 413px) {
            padding: 15px 10px;
        }
    }
    &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        &.border-b {
            border-bottom: 1px solid #00ffff;
            padding: 0 0 15px;
        }
        &-image {
            margin-right: 20px;
            img {
                width: 29px;
                height: 29px;
            }
        }
        &-title {
            font-family: 'HHBI';
            font-style: italic;
            font-weight: 400;
            font-size: 20px;
            line-height: 33px;
            letter-spacing: 0.75px;
            color: #fcfcfc;
        }
    }
    &-body {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 15px 0;
        flex-wrap: wrap;
        &-image {
            position: relative;
            margin-right: 15px;
            flex: 0 0 65px;
            img {
                width: 43px;
                height: 43px;
                margin-right: 15px;
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 5px;
                height: 5px;
                background: #00ffff;
                border-radius: 50%;
            }
        }
        &-amount {
            flex: 1 1 auto;
            font-family: 'Epilogue';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #00ffff;
        }
        &-symbol {
            flex: 1 1 auto;
            font-family: 'HHBI';
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.75px;
            color: #00ffff;
            text-align: center;
        }
    }
    .system-wallet-footer {
        justify-content: flex-start;
    }
    &-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        a {
            .system-wallet-footer-button {
                max-width: unset;
            }
        }
        &-button {
            margin: 10px;
            background: #55555550;
            border-radius: 54px;
            height: 44px;
            width: 160px;
            border: none;
            box-shadow: unset;
            outline: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: calc(50% - 20px);
            &:hover,
            &:focus,
            &:active {
                background: rgba(0, 255, 255, 0.2);
                * {
                    color: #00ffff !important;
                }
            }
            &-text {
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.75px;
                color: #dcdcdc;
            }
            &-image {
                margin-right: 12px;
                img {
                    width: 17px;
                    height: auto;
                    max-height: 17px;
                    object-fit: scale-down;
                }
            }
        }
    }
}
.dots-img {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #00ffff;
}
.row-usd {
    color: #dcdcdc;
    font-size: 16px;
}
</style>
<style lang="scss">
.wallet-tab {
    padding: 0;
    .slide-tournament {
        max-width: 275px;
        margin-right: 15px;
        .slick-next {
            display: none;
            right: -13px;
        }
        .slick-prev {
            display: none !important;
        }
        @media (max-width: 575px) {
            width: 100%;
            max-width: 275px;
        }
    }

    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.6em, 4vw, 1.9em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 25px 0;
        font-weight: 900;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }

    .activity-list {
        margin-top: 20px;
    }
}
#deposit-ecosystem {
    .modal-content {
        .form-group {
            width: 80%;
            min-width: 200px;
            margin: 0 auto 15px;
        }
        .form-sl {
            position: relative;
            img {
                height: 15px;
                width: auto;
                position: absolute;
                right: 15px;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }
        select {
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #00ffff;
            option {
                background: #00000b;
            }
        }
        input {
            margin: 0 auto;
            width: 100%;
            background: rgba(0, 255, 255, 0.15) !important;
            border: none;
        }
        label {
            width: 100%;
            min-width: 200px;
            margin: 0 auto;
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
    }
}
</style>
