<script>
import { mapGetters, mapMutations } from 'vuex';
import { StartEffect, StopEffect } from '@/plugins/effect';

import BalanceBox from '../shared/NewBalanceIngame.vue';
import Hero from '../upgrade/Hero.vue';
import BoxHero from '../inventory/hero/HeroShow.vue';

const axios = require('@/store/axios.config').default;

export default {
    components: { BoxHero, Hero, BalanceBox },
    data() {
        return {
            hero: {
                rank: 1,
                miningSpeed: 200,
                level: 1,
                quantityLandIsMining: 0,
                quantitySummon: 0,
                maxSummon: 5,
                timeBlockSummon: '2022-03-31T14:01:23.622Z',
                isSelling: false,
                tokenId: 12,
                ownerAddress: '0xc6ed69214ce2884d6c4d620cb1916321c4190865',
                name: 'Leo',
                type: 'Tanker',
                ratity: 'Legend',
                skin: 'Origin',
                dieDate: '2024-12-05T06:56:44.494Z',
                image: 'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/3d-raw/leo-origin.png',
                id: '624550ac4befbb0f29ed39ab',
            },
            infoUpgrade: {
                ratio: 0,
                ratity: 'Epic',
                heronium: 0,
            },
            showFailed: false,
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        showConfirm() {
            if (this.ListIdPicker.length <= 1) {
                this.$toastr.e('You must select at least 2 heroes to upgrade');
                return;
            }
            this.$bvModal.show('confirm-upgrade');
        },
        confirmUpgrade() {
            this.$bvModal.hide('confirm-upgrade');
            this.onUpgradeHero();
        },
        async onUpgradeHero() {
            this.onLoad();
            const { version } = this.ListPicker[0];
            let data = null;
            if (
                version.toLowerCase() ===
                this.$store.state.contract.marketplace.hero.addr.toLowerCase()
            ) {
                data = await axios.post('/api/hero/upgrade-hero', {
                    listHero: this.ListIdPicker,
                });
            } else {
                data = await axios.post('/api/hero/upgrade-hero/v2', {
                    listHero: this.ListIdPicker,
                });
            }

            if (data && data.newHero) {
                this.hero = data.newHero;
                this.$bvModal.show('heroReceiver');
            }
            if (data.success) {
                this.Start();
                this.showFailed = false;
            } else {
                this.showFailed = true;
            }
            this.$store.commit('upgrade/RESET_PICKER');
            this.$store.dispatch('info/req_getBalance');
            this.outLoad();
        },
        onRemoveHero(hero) {
            this.$store.commit('upgrade/REMOVE_HERO', hero);
        },
        async onGetRatedHero() {
            const data = await axios.post('api/hero/upgrade-hero/ratio', {
                listHero: this.ListIdPicker,
            });
            const { ratio, ratity, heronium } = data;
            this.infoUpgrade = { ratio: ratio * 100, ratity, heronium };
        },
        Start() {
            StartEffect();
        },
        Stop() {
            StopEffect();
        },
        ComingSoon() {
            this.$toastr.s('Upgrade is coming soon', 'Coming Soon');
        },
    },
    computed: {
        ...mapGetters({
            ListPicker: 'upgrade/ListPicker',
            ListIdPicker: 'upgrade/ListIdPicker',
        }),
        listHeroNotChose() {
            return null;
        },
    },
    watch: {
        ListIdPicker: {
            handler() {
                const { length } = this.ListIdPicker;
                if (length >= 2) {
                    this.onGetRatedHero();
                }
                if (length <= 1) {
                    this.infoUpgrade = {
                        ratio: 0,
                        ratity: 'Epic',
                        heronium: 0,
                    };
                }
            },
        },
    },
    created() {
        this.$store.commit('upgrade/RESET_PICKER');
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'upgrade/ADD_HERO':
                case 'upgrade/REMOVE_HERO':
                    this.$bvModal.hide('listHero');
                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
<template>
    <div class="upgrade">
        <BalanceBox coin="Heronium" class="mt-4 px-3" />
        <div class="cotainer d-flex px-4 px-lg-2 mt-5 mb-3">
            <img src="../../assets/images/icons/icon-title-agency.svg" alt="" />
            <div class="upgrade-main-title">Upgrade</div>
        </div>
        <div class="new-upgrade-list">
            <div
                class="new__upgrade__list d-flex flex-wrap justify-content-center justify-content-lg-start"
            >
                <div
                    class="new__upgrade__list--item list-item-upgrade my-3"
                    v-for="heroPick in ListPicker"
                    :key="`heroPicked-${heroPick.tokenId}`"
                >
                    <div class="p-2 h-100">
                        <div class="hero-picked">
                            <div class="remove-hero">
                                <button @click="onRemoveHero(heroPick)">
                                    <img
                                        src="~@/assets/images/mockup/swap/cancel.svg"
                                        alt=""
                                    />
                                </button>
                            </div>
                            <div class="hero-info-id">
                                #{{ Math.abs(heroPick.tokenId) }}
                            </div>
                            <div class="hero-info">
                                <div class="hero-info-avatar">
                                    <img
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${heroPick.name.toLowerCase()}-${heroPick.skin.toLowerCase()}`,
                                                heroPick.version,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="hero-info-name">
                                    The
                                    <span>{{ heroPick.name }}</span>
                                </div>
                                <div class="hero-info-stat">
                                    <div class="hero-level">
                                        {{
                                            10 > heroPick.level
                                                ? '0' +
                                                  heroPick.level.toString()
                                                : heroPick.level
                                        }}
                                    </div>
                                    <img
                                        :key="`inventory-${heroPick.id}`"
                                        :src="
                                            require(`@/assets/images/mockup/inventory/${heroPick.ratity.toLowerCase()}.png`)
                                        "
                                        alt=""
                                    />
                                    <img
                                        :key="`inventory-2-${heroPick.id}`"
                                        :src="
                                            require(`@/assets/images/mockup/inventory/${heroPick.skin.toLowerCase()}.png?v=1`)
                                        "
                                        alt=""
                                    />
                                    <img
                                        :key="`inventory-3-${heroPick.id}`"
                                        :src="
                                            require(`@/assets/images/mockup/inventory/${heroPick.type.toLowerCase()}.png?v=1`)
                                        "
                                        alt=""
                                    />
                                    <div class="hero-level hero-summon">
                                        {{ heroPick.quantitySummon }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="new__upgrade__list--item list-item-upgrade my-3 p-2"
                    v-for="idx in 5 - ListPicker.length"
                    :key="`Picking-${idx}`"
                >
                    <div class="hero-picking">
                        <b-button v-b-modal.listHero>
                            <img
                                src="~@/assets/images/mockup/vac/plus.svg"
                                alt=""
                            />
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="upgrade-bottom mt-3 px-2">
            <div class="upgrade-bottom-box">
                <img
                    class="left-arrow d-none d-md-block"
                    src="../../assets/images/icons/icon-arrow.svg"
                    alt=""
                />
                <div
                    class="d-flex flex-wrap justify-content-between align-items-center"
                >
                    <div class="d-flex py-3 col-md-8 col-12">
                        <img
                            src="../../assets/images/logo/logo-upgrade.svg"
                            alt=""
                        />
                        <div
                            class="upgrade-item-content d-flex flex-column justify-content-center px-4"
                        >
                            <h4>
                                Fee:
                                <span>
                                    {{
                                        TruncateToDecimals2(
                                            infoUpgrade.heronium || 0,
                                            '',
                                            2,
                                        )
                                    }}
                                    Heronium
                                </span>
                            </h4>
                            <h4>
                                <span> {{ infoUpgrade.ratio }}%</span> to
                                <span>Rarity {{ infoUpgrade.ratity }}</span>
                            </h4>
                        </div>
                    </div>
                    <div
                        v-if="ListPicker && ListPicker.length > 0"
                        class="col-lg-4 col-12 py-3"
                    >
                        <button
                            @click.prevent="showConfirm"
                            class="btn-upgrade"
                        >
                            Upgrade
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
            id="listHero"
            :hide-footer="true"
            title="List Hero"
            :centered="true"
            body-class="p-1"
            content-class="border-0"
            size="xl"
        >
            <!-- <keep-alive> -->
            <Hero class="my-2" />
            <!-- </keep-alive> -->
        </b-modal>
        <b-modal id="confirm-upgrade" title="Confirm Upgrade" hide-footer>
            <div>
                <p class="mb-0 text-confirm">Do you want to Upgrade Hero?</p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="confirmUpgrade"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="heroReceiver"
            @hidden="Stop"
            :title="
                showFailed ? 'Upgrade Hero Failed' : 'Upgrade Hero Successfully'
            "
            hide-footer
        >
            <p v-if="showFailed" class="text-confirm">
                Upgrade fails, you get a hero back
            </p>

            <BoxHero :hero="hero" :isUpdate="showFailed ? false : true" />
            <p
                v-if="!showFailed"
                class="text-confirm"
                style="font-size: 1.2em !important"
            >
                Hero Upgrade Successful.
                <br />
                System Will Forward Hero To Your Inventory (Hero) Directly. The
                Time To Receive Your Hero Will Take about 1 Minute To 15 Minutes
                Depending On The Rarity And Transaction Processing Speed Of
                Binance Smart Chain
            </p>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-3 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('heroReceiver')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<style lang="scss">
.text-confirm {
    text-align: center;
    font-size: 2em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}
#heroReceiver___BV_modal_outer_ {
    z-index: unset !important;
}
#heroReceiver {
    .hero-receiver-list {
        justify-content: center !important;
    }
}
</style>
<style lang="scss" scoped>
@import url('https://fonts.cdnfonts.com/css/hemi-head');
.upgrade {
    font-family: 'Poppins';
    .upgrade-main-title {
        font-weight: 600;
        font-size: 20px;
        color: #f8f8f8;
        padding-left: 36px;
    }
    .upgrade-header {
        border: 1px solid rgba(0, 255, 255, 0.3);
        border-radius: 4px;
        padding: 47px 70px;
        .upgrage-header-content {
            padding-left: 50px;
            .upgrade-title {
                font-weight: 600;
                font-size: 24px;
                color: #00ffff;
                padding-left: 33px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    top: 40%;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    background: #00ffff;
                    border-radius: 50%;
                }
            }
            .upgrade-coin {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #f8f8f8;
                padding-left: 36px;
            }
            .upgrade-header-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: rgba(0, 255, 255, 0.2);
                height: 36px;
                border-radius: 54px;
                outline: unset !important;
                span {
                    padding-left: 16px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #00ffff;
                }
            }
            .upgrade-header-btn2 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: rgba(85, 85, 85, 0.2);
                height: 36px;
                border-radius: 54px;
                outline: unset !important;
                span {
                    padding-left: 16px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #dcdcdc;
                }
            }
        }
    }
    .new-upgrade-list {
        .list-item-upgrade {
            @media (max-width: 1200px) and (min-width: 992px) {
                width: 25% !important;
            }
            @media (max-width: 992px) and (min-width: 768px) {
                width: 33.33333% !important;
            }
            @media (max-width: 768px) and (min-width: 576px) {
                width: 50% !important;
            }
            @media (max-width: 576px) {
                width: 100% !important;
            }
            max-width: 200px;
        }
        .new__upgrade__list--item {
            width: 20%;
            height: 250px;
            .hero-picking {
                padding: 10px;
                height: 100%;
                background: linear-gradient(
                    180deg,
                    rgba(53, 70, 72, 0) 0%,
                    rgba(0, 240, 255, 0.3) 100%
                );
                button {
                    width: 100%;
                    height: 100%;
                    outline: unset;
                    box-shadow: none;
                    background: none;
                    border: none;
                    border-radius: 0;
                    img {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
            .hero-picked {
                position: relative;
                height: 100%;
                padding: 10px;
                background: linear-gradient(
                    180deg,
                    rgba(53, 70, 72, 0) 0%,
                    rgba(0, 240, 255, 0.3) 100%
                );
                .remove-hero {
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    width: 32px;
                    height: 32px;
                    button {
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .hero-info-avatar {
                    img {
                        width: 77%;
                        max-width: 130px;
                        min-height: 155px;
                        object-fit: scale-down;
                    }
                }
                .hero-info-name {
                    font-family: 'Hemi Head', sans-serif;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 18px;
                    color: #00ffff;
                    span {
                        color: #f8f8f8;
                        font-size: 22px;
                    }
                }
                .hero-info-id {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                    color: #00ffff;
                }
                .hero-info-stat {
                    position: absolute;
                    width: 30px;
                    top: 35px;
                    right: 6%;
                    img {
                        width: 100%;
                        height: auto;
                        margin: 5px 0;
                    }
                    .hero-level {
                        width: 100%;
                        height: 30px;
                        margin: 5px 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #00ffff;
                        color: #00ffff;
                        font-weight: 600;
                    }
                    .hero-summon {
                        border-color: #13ff60;
                        color: #13ff60;
                    }
                }
            }
        }
    }
    .upgrade-bottom {
        .upgrade-bottom-box {
            padding: 30px 90px;
            position: relative;
            background: linear-gradient(
                90deg,
                rgba(53, 63, 72, 0.2) 0%,
                rgba(53, 63, 72, 0) 100%
            );
            @media (max-width: 576px) {
                padding: 20px 30px;
            }
            img.left-arrow {
                position: absolute;
                top: 25%;
                left: 0;
            }
            .upgrade-item {
                img {
                    max-width: 70px;
                    height: auto;
                }
            }
            .upgrade-item-content {
                h4 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    color: #f8f8f8;
                    span {
                        color: #00ffff;
                    }
                }
            }
            button.btn-upgrade {
                outline: unset;
                border: 0.5px solid #00ffff;
                box-shadow: none;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #00ffff;
                height: 56px;
                width: 100%;
                max-width: 270px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 5px;
                    height: 5px;
                    background: #00ffff;
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 5px;
                    height: 5px;
                    background: #00ffff;
                }
            }
        }
    }
}
.box-balance-upgrade {
    margin-top: 100px;
    @media (max-width: 575px) {
        margin-top: 30px;
    }
}
.upgrade-title {
    &-text {
        font-family: hemiheadRg-BoldItalic;
        font-size: 42px;
        color: #00ffff;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
}
.frame-choose-hero {
    background-color: rgba(0, 68, 73, 0.8);
    border: 3px solid #004449;
    padding: 15px 2px;
}

.has-hero {
    .btn-choose-hero {
        display: none;
    }

    &:hover {
        .btn-choose-hero {
            display: flex;
        }
    }
}

.list-upgrade {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.hero {
    cursor: pointer;
    position: relative;
    background: linear-gradient(45deg, #022534, #02404e);
    border: 2px solid #00505c;
    width: 100%;
    min-width: 170px;
    max-width: 175px;
    padding: 15px 0;
    margin: 5px;
    flex: 1;
    min-height: 190px;
}

.btn-choose-hero {
    background: url('~@/assets/images/frame/hexagon-green.png');
    background-size: 100% 100%;
    cursor: pointer;
    width: 150px;
    outline: none;
    height: 50px;
    display: flex;
    position: relative;
    padding: 0;
    outline: none;
    font-family: 'HHBI';
    font-size: 17px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    outline: none !important;
    box-shadow: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    &.remove {
        clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0 50%);
        background: red;
        width: 120px;
        height: 45px;
    }
}

.frame-rarity {
    position: relative;

    &-content {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &-text {
        color: #00ffff;
        font-family: hemiheadRg-BoldItalic;
        font-size: 19px;
    }
}

.upgrade-button {
    background: url('~@/assets/images/frame/frame-upgrade-green2.png');
    background-size: 100% 100%;
    width: 220px;
    height: 110px;
    color: #00ffff;
    font-family: 'HHBI';
    font-size: 20px;
    outline: none !important;
    box-shadow: none;
    &:hover {
        transform: scale(1.01);
    }
    span {
        font-size: 17px;
    }
}
.frame-upgrade-bottom {
    & .content {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
</style>
