const axios = require('../axios.config').default;

const resource = 'api/exchange';

const listCoin = async () => {
    const data = await axios.get(`${resource}/list`);
    return data;
};
const tradingList = async (slug) => {
    const data = await axios.get(`${resource}/${slug}`);
    return data;
};
const orderBuy = async ({ idCoin, amount, price }) => {
    const data = await axios.post(`${resource}/order-buy`, {
        _id: idCoin,
        amount,
        price,
    });
    return data;
};
const orderSell = async ({ idCoin, amount, price }) => {
    const data = await axios.post(`${resource}/order-sell`, {
        _id: idCoin,
        amount,
        price,
    });
    return data;
};
const cancelOrderBuy = async (id) => {
    const data = await axios.delete(`${resource}/cancel-buy/${id}`);
    return data;
};
const cancelOrderSell = async (id) => {
    const data = await axios.delete(`${resource}/cancel-sell/${id}`);
    return data;
};
const detailCrypto = async (id) => {
    const data = await axios.get(`${resource}/list/${id}`);
    return data;
};
const dataChart = async ({ query, id }) => {
    const data = await axios.get(`${resource}/chart/${id}`, {
        params: query,
    });
    return data;
};
const historyOpen = async ({ query, coin }) => {
    console.log(coin);
    const data = await axios.get(`${resource}/open-trade/${coin}`, {
        params: query,
    });

    return data;
};
const historyClose = async ({ query, coin }) => {
    const data = await axios.get(`${resource}/history-trade/${coin}`, {
        params: query,
    });
    return data;
};
export {
    listCoin,
    tradingList,
    orderBuy,
    orderSell,
    cancelOrderBuy,
    cancelOrderSell,
    dataChart,
    historyOpen,
    historyClose,
    detailCrypto,
};
