<script>
import { mapGetters, mapMutations } from 'vuex';
import { StartEffect, StopEffect } from '@/plugins/effect';

const axios = require('@/store/axios.config').default;

export default {
    data() {
        return {
            quantityBuy: 1,
            mysteryBox: {},
        };
    },
    computed: {
        ...mapGetters({
            isLogin: 'auth/loggedIn',
            BalanceSystem: 'info/Balance',
            Balance: 'wallet/Balance',
        }),
    },
    watch: {},
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        Start() {
            StartEffect();
        },
        Stop() {
            StopEffect();
        },
        resetBuy() {
            this.quantityBuy = 1;
            this.Stop();
        },
        async getDetail() {
            this.onLoad();
            const mysteryBox = await axios.get('api/shop/mistery-box');
            this.mysteryBox = mysteryBox;
            this.listReward = mysteryBox.items;
            this.outLoad();
        },
        async buyMystery() {
            if (
                this.BalanceSystem.HBG <
                parseInt(this.quantityBuy * this.mysteryBox.price, 10)
            ) {
                this.$toastr.e('Insufficient balance', 'Warning');
                this.$bvModal.hide('confirm-buy-mystery');
                this.$store.commit('suggest/SuggestDeposit');
                return;
            }
            if (this.isNaN(this.quantityBuy) || this.quantityBuy < 1) {
                this.quantityBuy = 1;
                this.$toastr.e(
                    'Please check your quantity buy, min buy is 1 box',
                    'Warning',
                );
                return;
            }
            if (!Number.isInteger(this.quantityBuy)) {
                this.quantityBuy = parseInt(this.quantityBuy, 10);
            }
            this.onLoad();
            const data = await axios.post('/api/shop/mistery-box/buy', {
                amount: this.quantityBuy,
            });

            if (data) {
                this.$bvModal.hide('confirm-buy-mystery');
                this.$bvModal.show('mysteryReceive');
                this.Start();
                this.$store.dispatch('info/req_getBalance');
            }
            this.outLoad();
        },
        showConfirm() {
            this.$bvModal.hide('buy-mystery');
            this.$bvModal.show('confirm-buy-mystery');
        },
    },
    mounted() {
        this.getDetail();
    },
};
</script>

<template>
    <b-container>
        <div class="mysteryBoxShop mt-3">
            <div class="inventory-box-item">
                <div class="inventory-item-image">
                    <img
                        :src="
                            require(`@/assets/images/mockup/shop/mystery.png?v=1`)
                        "
                        alt=""
                    />
                </div>
                <div class="inventory-box-item-content">
                    <div class="land-name-content">
                        <p class="text-center">MYSTERY BOX</p>
                    </div>
                    <hr class="mt-0" />
                    <div class="box-available d-flex justify-content-between">
                        <div class="left text-white">Price:</div>
                        <div class="right pl-0">
                            <div class="hbg text-white">
                                ~{{
                                    TruncateToDecimals2(
                                        mysteryBox.price * Balance.price +
                                            0.0001 || 0,
                                        '',
                                        4,
                                    )
                                }}
                                HBG
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center pt-4">
                        <button
                            @click="$bvModal.show('buy-mystery')"
                            class="bede-btn"
                        >
                            <p>Buy</p>
                        </button>
                    </div>

                    <div class="inventory-item-line">
                        <img
                            src="@/assets/images/icons/icon-line-svg.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="info-mystery-box" title="Information" hide-footer>
            <div>
                <p class="mb-0 text-confirm-ticket">
                    Description:
                    <span>
                        Mystery Box is a special item in Herobook, when owning
                        Mystery box and opening the box you can randomly own a
                        lot of items such as: Hero, box , heronium, herostone,
                        HBG, land... The community has a great opportunity to
                        own a Hero of the Legendary level and have a very high
                        value in the market. Wish the community good luck and
                        own a lot of rare items with Mystery Box
                    </span>
                </p>
            </div>
            <b-button
                class="mx-auto mt-5 mb-0 button-confirm"
                variant="primary"
                @click="$bvModal.hide('info-mystery-box')"
            >
                Cancel
            </b-button>
        </b-modal>
        <b-modal id="buy-mystery" title="Buy Mystery Box" size="md" hide-footer>
            <div class="form-group d-flex justify-content-center">
                <b-row>
                    <b-col cols="12">
                        <img
                            src="~@/assets/images/mockup/shop/mystery.png"
                            alt=""
                            class="d-sm-block mx-auto"
                            width="100%"
                            style="max-width: 205px"
                        />
                        <div class="d-flex flex-column input-opn">
                            <label for="inputAmount" class="text-white">Buy Quantity</label>

                            <input
                                min="1"
                                v-model="quantityBuy"
                                type="number"
                                id="inputAmount"
                                class="main-bg text-center"
                                placeholder="Quantity"
                                step="1"
                                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 48 && event.keyCode !== 187 && event.keyCode !== 190"
                            />
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div
                class="form-group d-flex justify-content-center mt-3 input-opn"
            >
                <b-button
                    class="button-confirm my-2 mb-0"
                    variant="primary"
                    @click="showConfirm"
                >
                    Buy
                </b-button>

                <b-button
                    class="mx-auto my-2 mb-0 button-cancel"
                    variant="primary"
                    @click="$bvModal.hide('buy-mystery')"
                >
                    Cancel
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="confirm-buy-mystery"
            title="Confirm Buy Mystery Box"
            hide-footer
        >
            <div>
                <p class="mb-0 text-confirm">
                    Do you want to use
                    <br />
                    <span class="text-main">
                        ~{{
                            TruncateToDecimals2(
                                (mysteryBox.price * Balance.price + 0.0001) *
                                    quantityBuy || 0,
                                '',
                                4,
                            )
                        }}

                        HBG
                    </span>
                    to buy
                    <span class="text-main">
                        {{ quantityBuy }} Mystery Box
                    </span>
                    ?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="buyMystery"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="mysteryReceive"
            @hidden="resetBuy"
            title="Mystery Box Receiver"
            hide-footer
        >
            <div class="box">
                <div class="box-img">
                    <img
                        src="~@/assets/images/mockup/shop/mystery.png"
                        alt=""
                        class="d-sm-block mx-auto"
                        width="100%"
                        style="max-width: 205px"
                    />
                </div>
                <div class="box-name diamond">Mystery Box</div>
                <div class="box-available">
                    <div>You Have Receive:</div>
                    <span> {{ quantityBuy }} Mystery Box </span>
                </div>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-3 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('mysteryReceive')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<style lang="scss" scoped>
.mysteryBoxShop {
    font-family: 'Poppins';
    flex: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 575px) {
        max-width: 100%;
    }
    button {
        outline: unset;
    }
    .inventory-box-item {
        .inventory-item-image {
            width: 200px;
            height: auto;
            margin-top: 30px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .land-name-content {
            font-weight: 600;
            font-size: 24px;
            color: #f8f8f8;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .mysteryBoxShop-information {
            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #f8f8f8;
                padding: 8px 1rem;
            }
            .price {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #00ffff;
            }
        }
    }
    button.bede-btn {
        height: 38px;
        width: 100%;
        max-width: 135px;
        border-radius: 105px;
        &::before {
            border-radius: 105px;
            background: #182c35;
        }
    }
}
</style>
