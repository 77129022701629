<script>
import { mapGetters, mapMutations } from 'vuex';
import Paginate from '@/components/shared/paginate-second.vue';

const axios = require('@/store/axios.config').default;

export default {
    components: {
        Paginate,
    },
    data() {
        return {
            tableData: [
                {
                    name: 'Mr. Pi',
                    id: '200000',
                    platform: 'Hero Book',
                    price: 100,
                    time: '21/11/2022',
                    isSell: false,
                    img: 'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/3d-raw/crocky-chromas.png?v=1',
                },
                {
                    name: 'Mr. Pi',
                    id: '200000',
                    platform: 'Hero Book',
                    price: 100,
                    time: '21/11/2022',
                    isSell: false,
                    img: 'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/3d-raw/crocky-chromas.png?v=1',
                },
                {
                    name: 'Mr. Pi',
                    id: '200000',
                    platform: 'Hero Book',
                    price: 100,
                    time: '21/11/2022',
                    isSell: false,
                    img: 'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/3d-raw/crocky-chromas.png?v=1',
                },
                {
                    name: 'Mr. Pi',
                    id: '200000',
                    platform: 'Hero Book',
                    price: 100,
                    time: '21/11/2022',
                    isSell: false,
                    img: 'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/3d-raw/crocky-chromas.png?v=1',
                },
                {
                    name: 'Mr. Pi',
                    id: '200000',
                    platform: 'Hero Book',
                    price: 100,
                    time: '21/11/2022',
                    isSell: false,
                    img: 'https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/3d-raw/crocky-chromas.png?v=1',
                },
            ],
            userHistoryTab: 1,
            sellPage: 1,
            soldPage: 1,
            DetailBox: {},
            DetailHero: {},
        };
    },
    computed: {
        ...mapGetters({
            Balance: 'wallet/Balance',
        }),

        ...mapGetters('hub', ['UserSellList', 'UserSoldList']),
        field() {
            let arrField = [
                {
                    key: 'more',
                    sortable: false,
                    label: '',
                    class: 'text-center',
                },
                {
                    key: 'hero',
                    sortable: false,
                    label: 'Name',
                    class: 'text-left',
                },
                {
                    key: 'timeSell',
                    sortable: false,
                    label: 'Listing On',
                    class: 'text-center',
                },
                {
                    key: 'price',
                    sortable: false,
                    label: 'Price',
                    class: 'text-center',
                },
                {
                    key: 'status',
                    sortable: false,
                    label: 'Action',
                    class: 'text-center',
                },
            ];
            if (window.innerWidth <= 575) {
                arrField = [
                    {
                        key: 'more',
                        sortable: false,
                        label: '',
                        class: 'text-center',
                    },
                    {
                        key: 'hero',
                        sortable: false,
                        label: 'Name',
                        class: 'text-left',
                    },
                    {
                        key: 'price',
                        sortable: false,
                        label: 'Price',
                        class: 'text-center',
                    },
                ];
            }
            return arrField;
        },
        field2() {
            let arrField = [
                {
                    key: 'more',
                    sortable: false,
                    label: '',
                    class: 'text-center',
                },
                {
                    key: 'hero',
                    sortable: false,
                    label: 'Name',
                    class: 'text-left',
                },
                {
                    key: 'timeSuccess',
                    sortable: false,
                    label: 'Sold On',
                    class: 'text-center',
                },
                {
                    key: 'price',
                    sortable: false,
                    label: 'Price',
                    class: 'text-right',
                },
            ];
            if (window.innerWidth <= 575) {
                arrField = [
                    {
                        key: 'more',
                        sortable: false,
                        label: '',
                        class: 'text-center',
                    },
                    {
                        key: 'hero',
                        sortable: false,
                        label: 'Name',
                        class: 'text-left',
                    },
                    {
                        key: 'price',
                        sortable: false,
                        label: 'Price',
                        class: 'text-right',
                    },
                ];
            }
            return arrField;
        },
    },
    methods: {
        ...mapMutations(['outLoad', 'onLoad']),
        onChangePageSell(payload) {
            this.sellPage = payload;
            this.$store.dispatch('hub/req_getUserSellList', this.sellPage);
        },
        onChangePageSold(payload) {
            this.soldPage = payload;
            this.$store.dispatch('hub/req_getUserSellList', this.soldPage);
        },
        async getDetailBox(tokenId) {
            const detail = await axios.get(
                `api/market/dashboard/detail/box/${tokenId}`,
            );
            if (
                !detail ||
                detail.buyer !== null ||
                detail.isCancel ||
                detail.box == null
            ) {
                if (!detail || detail.isCancel) {
                    this.$toastr.e('Box has been bought or sold', 'Oops!');
                }
                this.$bvModal.hide('confirm-buy');
                this.$bvModal.hide('confirm-cancel-hero');
                this.$store.dispatch(
                    'market/req_getListBoxSelling',
                    this.search,
                );
                this.outLoad();
            }
            this.DetailBox = detail;
            this.$bvModal.show('confirm-cancel-box');
        },
        async onCancelBox() {
            this.onLoad();
            const { tokenId, box } = this.DetailBox;
            const { version } = box;
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const txObj = await marketNew.cont.methods
                .cancelSale(tokenId, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await marketNew.cont.methods
                    .cancelSale(tokenId, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('box', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },

        async getDetailHero(tokenId) {
            const detail = await axios.get(
                `api/market/dashboard/detail/hero/${tokenId}`,
            );
            if (!detail || detail.buyer !== null || detail.isCancel) {
                if (
                    !detail ||
                    detail.isCancel ||
                    detail.buyer !== null ||
                    detail.hero == null
                ) {
                    this.$toastr.e('Hero has been bought or sold', 'Oops!');
                }
                this.$router.push({ name: 'HeroList' });
                this.outLoad();
                return;
            }

            if (detail) {
                this.DetailHero = detail;
                this.$bvModal.show('confirm-cancel-hero');
            }
        },
        async onCancelHero() {
            this.onLoad();
            const { marketNew } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            const { tokenId, hero } = this.DetailHero;
            const { version } = hero;
            console.log(tokenId, version);
            const txObj = await marketNew.cont.methods
                .cancelSale(tokenId, version)
                .encodeABI();
            let gas = 0;
            const gasLimit = await this.getLimitGas();
            try {
                gas = await marketNew.cont.methods
                    .cancelSale(tokenId, version)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: gasLimit,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: marketNew.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txTransferData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txTransferData != null) {
                        clearInterval(check);
                        if (txTransferData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction('hero', txTransferData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                            this.outLoad();
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return false;
            }
            return true;
        },
        onReloadAPI() {
            this.$store.dispatch('hub/req_getUserSellList', this.sellPage);
        },
        async sendTransaction(type, txData) {
            if (type === 'box') {
                this.$store.dispatch('box/req_postCancelSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.$bvModal.hide('confirm-cancel-box');

                this.$bvModal.show('show-received-cancel-box');
            }
            if (type === 'hero') {
                this.$store.dispatch('hero/req_postCancelSuccess', {
                    transactionHash: txData.transactionHash,
                });
                this.backToMarket();
                this.outLoad();
                this.$bvModal.hide('confirm-cancel-hero');

                this.$bvModal.show('show-received-cancel-hero');
            }
        },
    },
    mounted() {
        this.$store.dispatch('hub/req_getUserSellList');
        this.$store.dispatch('hub/req_getUserSoldList');
    },
};
</script>
<template>
    <b-row class="market-private">
        <b-col cols="12">
            <div class="market-user">
                <div class="market-user-nav">
                    <a
                        class="market-user-nav-item"
                        @click="userHistoryTab = 1"
                        :class="{ active: userHistoryTab == 1 }"
                    >
                        My Active Listings ({{ UserSellList.totalItem }})
                    </a>
                    <a
                        class="market-user-nav-item"
                        @click="userHistoryTab = 2"
                        :class="{ active: userHistoryTab == 2 }"
                    >
                        My Market History
                    </a>
                </div>
                <router-link
                    :to="{ name: 'Inventory' }"
                    class="sell-item-button"
                >
                    <div class="sell-item-button-content">Sell an item</div>
                </router-link>
            </div>
            <div class="market-user-result" v-if="userHistoryTab == 1">
                <div class="market-user-result-type">
                    My sell listings
                    <span> ({{ UserSellList.totalItem }})</span>
                </div>
                <div class="market-user-result-list">
                    <b-table
                        striped
                        hover
                        :items="UserSellList.items"
                        :fields="field"
                        show-empty
                    >
                        <template #cell(more)="row">
                            <b-button
                                size="sm"
                                @click="row.toggleDetails"
                                class="btn-open-detail"
                                :class="{ open: row.item['_showDetails'] }"
                            >
                                <img
                                    v-if="!row.item['_showDetails']"
                                    src="~@/assets/images/icons/plus.svg"
                                    alt="Chose"
                                />
                                <img
                                    v-else
                                    src="~@/assets/images/icons/clearH.svg"
                                    alt="clear hero"
                                />
                            </b-button>
                        </template>

                        <template #cell(timeSell)="data">
                            <div class="hero-info">
                                <div class="hero-info-text">
                                    <div
                                        class="hero-info-text-name text-center"
                                    >
                                        {{ getDateTime4(data.item.timeSell) }}
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #cell(hero)="data">
                            <div class="hero-info left">
                                <div
                                    class="hero-info-avatar"
                                    v-if="data.item.name == 'hero'"
                                >
                                    <img
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${data.item.item.name.toLowerCase()}-${data.item.item.skin.toLowerCase()}`,
                                                $store.state.contract
                                                    .marketplace.hero2.addr,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div
                                    class="hero-info-avatar"
                                    v-if="data.item.name == 'box'"
                                >
                                    <img :src="data.item.image" alt="" />
                                </div>
                                <div class="hero-info-text">
                                    <div class="hero-info-text-name">
                                        {{ data.item.itemName }}
                                    </div>
                                    <div class="hero-info-text-platform">
                                        {{ data.item.platform }}
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #cell(price)="data">
                            <div class="hero-info">
                                <div class="hero-info-text2">
                                    <div class="hero-info-text2-name">
                                        ~
                                        {{
                                            TruncateToDecimals2(
                                                data.item.price *
                                                    Balance.price || 0,
                                                '',
                                                4,
                                            )
                                        }}
                                        HBG
                                    </div>
                                    <div class="hero-info-text2-platform">
                                        {{ data.item.price }} USD
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #cell(status)="data">
                            <b-button
                                class="sell-item-button cancel"
                                v-if="data.item.name == 'box'"
                                @click="getDetailBox(data.item.tokenId)"
                            >
                                <img
                                    src="~@/assets/images/mockup/market/new/remove.svg"
                                    alt=""
                                />
                                <div class="sell-item-button-content cancel">
                                    Cancel
                                </div>
                            </b-button>
                            <b-button
                                class="sell-item-button cancel"
                                v-if="data.item.name == 'hero'"
                                @click="getDetailHero(data.item.tokenId)"
                            >
                                <img
                                    src="~@/assets/images/mockup/market/new/remove.svg"
                                    alt=""
                                />
                                <div class="sell-item-button-content cancel">
                                    Cancel
                                </div>
                            </b-button>
                        </template>
                        <template #row-details="row">
                            <div
                                class="d-flex justify-content-around align-items-center"
                            >
                                <div class="data-time">
                                    Listing On: {{ row.item.time }}
                                </div>
                                <b-button class="sell-item-button cancel mx-0">
                                    <img
                                        src="~@/assets/images/mockup/market/new/remove.svg"
                                        alt=""
                                    />
                                    <div
                                        class="sell-item-button-content cancel"
                                    >
                                        Cancel
                                    </div>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                    <Paginate
                        :totalPages="UserSellList.totalPages || 1"
                        :totalResult="UserSellList.totalItem || 1"
                        :perPage="UserSellList.limit || 1"
                        @current="onChangePageSell"
                    />
                </div>
            </div>
            <div class="market-user-result" v-else>
                <div class="market-user-result-type">
                    My sell listings
                    <span> ({{ UserSoldList.totalItem }})</span>
                </div>
                <div class="market-user-result-list">
                    <b-table
                        striped
                        hover
                        :items="UserSoldList.items"
                        :fields="field2"
                        show-empty
                    >
                        <template #cell(more)="row">
                            <b-button
                                size="sm"
                                @click="row.toggleDetails"
                                class="btn-open-detail"
                                :class="{ open: row.item['_showDetails'] }"
                            >
                                <img
                                    v-if="!row.item['_showDetails']"
                                    src="~@/assets/images/icons/plus.svg"
                                    alt="Chose"
                                />
                                <img
                                    v-else
                                    src="~@/assets/images/icons/clearH.svg"
                                    alt="clear hero"
                                />
                            </b-button>
                        </template>

                        <template #cell(timeSuccess)="data">
                            <div class="hero-info">
                                <div class="hero-info-text">
                                    <div
                                        class="hero-info-text-name text-center"
                                    >
                                        {{
                                            getDateTime4(data.item.timeSuccess)
                                        }}
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #cell(hero)="data">
                            <div class="hero-info left">
                                <div
                                    class="hero-info-avatar"
                                    v-if="data.item.name == 'hero'"
                                >
                                    <img
                                        :src="
                                            getImageHero(
                                                '3d',
                                                `${data.item.item.name.toLowerCase()}-${data.item.item.skin.toLowerCase()}`,
                                                $store.state.contract
                                                    .marketplace.hero2.addr,
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div
                                    class="hero-info-avatar"
                                    v-if="data.item.name == 'box'"
                                >
                                    <img :src="data.item.image" alt="" />
                                </div>
                                <div class="hero-info-text">
                                    <div class="hero-info-text-name">
                                        {{ data.item.itemName }}
                                    </div>
                                    <div class="hero-info-text-platform">
                                        {{ data.item.platform }}
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #cell(price)="data">
                            <div class="hero-info justify-content-end">
                                <div class="hero-info-text2">
                                    <div class="hero-info-text2-name">
                                        ~
                                        {{
                                            TruncateToDecimals2(
                                                data.item.price *
                                                    Balance.price || 0,
                                                '',
                                                4,
                                            )
                                        }}
                                        HBG
                                    </div>
                                    <div class="hero-info-text2-platform">
                                        {{ data.item.price }} USD
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #row-details="row">
                            <div
                                class="d-flex justify-content-around align-items-center"
                            >
                                <div class="data-time">
                                    Listing On: {{ row.item.time }}
                                </div>
                                <b-button class="sell-item-button cancel mx-0">
                                    <img
                                        src="~@/assets/images/mockup/market/new/remove.svg"
                                        alt=""
                                    />
                                    <div
                                        class="sell-item-button-content cancel"
                                    >
                                        Cancel
                                    </div>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                    <Paginate
                        :totalPages="UserSoldList.totalPages || 1"
                        :totalResult="UserSoldList.totalItem || 1"
                        :perPage="UserSoldList.limit || 1"
                        @current="onChangePageSold"
                    />
                </div>
            </div>
        </b-col>

        <!-- confirm cancel box -->
        <b-modal :id="`confirm-cancel-box`" hide-footer hide-header>
            <div class="market-modal-title">
                <div class="market-modal-title:text">
                    Do you want to cancel this box ?
                </div>
                <a
                    class="market-modal-title:close"
                    @click="$bvModal.hide(`confirm-cancel-box`)"
                >
                    X
                </a>
            </div>
            <div class="market-modal-body" v-if="DetailBox">
                <div class="market-modal-body:image">
                    <img :src="DetailBox.image" alt="" />
                </div>
                <div class="market-modal-body-content">
                    <div
                        class="market-modal-body-content:token detail-item-market"
                    >
                        <div class="name">Token ID:</div>
                        <div class="value">NFT-{{ DetailBox.tokenId }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Name:</div>
                        <div class="value">{{ DetailBox.itemName }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-usd detail-item-market"
                    >
                        <div class="name">Price USD:</div>
                        <div class="value">{{ DetailBox.price }} USD</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-hbg detail-item-market"
                    >
                        <div class="name">Price HBG:</div>
                        <div class="value">
                            ~
                            {{
                                TruncateToDecimals2(
                                    DetailBox.price * Balance.price || 0,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                    </div>
                </div>
            </div>
            <div class="market-modal-footer">
                <b-button
                    class="market-modal-footer:cancel"
                    @click="$bvModal.hide(`confirm-cancel-box`)"
                >
                    <div class="button-content">Cancel</div>
                </b-button>
                <b-button
                    class="market-modal-footer:confirm"
                    @click="onCancelBox"
                >
                    <div class="button-content">Confirm Cancel</div>
                </b-button>
            </div>
        </b-modal>
        <!-- end cancel box -->
        <!-- result -->
        <b-modal
            :id="`show-received-cancel-box`"
            hide-footer
            hide-header
            @hidden="onReloadAPI"
        >
            <div class="market-modal-title">
                <div class="market-modal-title:text">
                    You cancel the box successfully. Congratulation!
                </div>
                <a
                    class="market-modal-title:close"
                    @click="$bvModal.hide(`confirm-cancel-box`)"
                >
                    X
                </a>
            </div>
            <div class="market-modal-body" v-if="DetailBox">
                <div class="market-modal-body:image">
                    <img v-if="DetailBox.image" :src="DetailBox.image" alt="" />
                </div>
                <div class="market-modal-body-content">
                    <div
                        class="market-modal-body-content:token detail-item-market"
                    >
                        <div class="name">Token ID:</div>
                        <div class="value">
                            NFT-{{ DetailBox.tokenId || 'NAN' }}
                        </div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Name:</div>
                        <div class="value">
                            {{ DetailBox.itemName || 'NAN' }}
                        </div>
                    </div>
                    <div
                        class="market-modal-body-content:price-usd detail-item-market"
                    >
                        <div class="name">Price USD:</div>
                        <div class="value">
                            {{ DetailBox.price || 'NAN' }} USD
                        </div>
                    </div>
                    <div
                        class="market-modal-body-content:price-hbg detail-item-market"
                    >
                        <div class="name">Price HBG:</div>
                        <div class="value">
                            ~
                            {{
                                TruncateToDecimals2(
                                    DetailBox.price * Balance.price || 0,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                    </div>
                </div>
            </div>
            <div class="market-modal-footer">
                <b-button
                    class="market-modal-footer:confirm"
                    @click="$bvModal.hide(`show-received-cancel-box`)"
                >
                    <div class="button-content">Confirm</div>
                </b-button>
            </div>
        </b-modal>
        <!-- end results -->
        <!-- confirm buy -->
        <b-modal :id="`confirm-cancel-hero`" hide-footer hide-header>
            <div class="market-modal-title">
                <div class="market-modal-title:text">
                    Do you want to cancel this hero ?
                </div>
                <a
                    class="market-modal-title:close"
                    @click="$bvModal.hide(`confirm-cancel-hero`)"
                >
                    X
                </a>
            </div>
            <div class="market-modal-body" v-if="DetailHero && DetailHero.hero">
                <div class="market-modal-body:image">
                    <img
                        :src="
                            getImageHero(
                                '3d',
                                `${DetailHero.hero.name.toLowerCase()}-${DetailHero.hero.skin.toLowerCase()}`,
                                this.$store.state.contract.marketplace.hero2
                                    .addr,
                            )
                        "
                        alt=""
                    />
                </div>
                <div class="market-modal-body-content">
                    <div
                        class="market-modal-body-content:token detail-item-market"
                    >
                        <div class="name">Token ID:</div>
                        <div class="value">NFT-{{ DetailHero.tokenId }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Name:</div>
                        <div class="value">{{ DetailHero.itemName }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Skin:</div>
                        <div class="value">
                            {{ DetailHero.hero.skin || '' }}
                        </div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Rare:</div>
                        <div class="value">{{ DetailHero.hero.type }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-usd detail-item-market"
                    >
                        <div class="name">Price USD:</div>
                        <div class="value">{{ DetailHero.price }} USD</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-hbg detail-item-market"
                    >
                        <div class="name">Price HBG:</div>
                        <div class="value">
                            ~
                            {{
                                TruncateToDecimals2(
                                    DetailHero.price * Balance.price || 0,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                    </div>
                </div>
            </div>
            <div class="market-modal-footer">
                <b-button
                    class="market-modal-footer:cancel"
                    @click="$bvModal.hide(`confirm-cancel-hero`)"
                >
                    <div class="button-content">Cancel</div>
                </b-button>
                <b-button
                    class="market-modal-footer:confirm"
                    @click="onCancelHero"
                >
                    <div class="button-content">Confirm Cancel</div>
                </b-button>
            </div>
        </b-modal>
        <!-- end confirm buy -->
        <!-- result -->
        <b-modal
            :id="`show-received-cancel-hero`"
            hide-footer
            hide-header
            @hidden="onReloadAPI"
        >
            <div class="market-modal-title">
                <div class="market-modal-title:text">
                    You cancel the hero successfully. Congratulation!
                </div>
                <a
                    class="market-modal-title:close"
                    @click="$bvModal.hide(`show-received-cancel-hero`)"
                >
                    X
                </a>
            </div>
            <div class="market-modal-body" v-if="DetailHero && DetailHero.hero">
                <div class="market-modal-body:image">
                    <img
                        :src="
                            getImageHero(
                                '3d',
                                `${DetailHero.hero.name.toLowerCase()}-${DetailHero.hero.skin.toLowerCase()}`,
                                this.$store.state.contract.marketplace.hero2
                                    .addr,
                            )
                        "
                        alt=""
                    />
                </div>
                <div class="market-modal-body-content">
                    <div
                        class="market-modal-body-content:token detail-item-market"
                    >
                        <div class="name">Token ID:</div>
                        <div class="value">NFT-{{ DetailHero.tokenId }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Name:</div>
                        <div class="value">{{ DetailHero.itemName }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Skin:</div>
                        <div class="value">
                            {{ DetailHero.hero.skin || '' }}
                        </div>
                    </div>
                    <div
                        class="market-modal-body-content:name detail-item-market"
                    >
                        <div class="name">Rare:</div>
                        <div class="value">{{ DetailHero.hero.type }}</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-usd detail-item-market"
                    >
                        <div class="name">Price USD:</div>
                        <div class="value">{{ DetailHero.price }} USD</div>
                    </div>
                    <div
                        class="market-modal-body-content:price-hbg detail-item-market"
                    >
                        <div class="name">Price HBG:</div>
                        <div class="value">
                            ~
                            {{
                                TruncateToDecimals2(
                                    DetailHero.price * Balance.price || 0,
                                    '',
                                    4,
                                )
                            }}
                            HBG
                        </div>
                    </div>
                </div>
            </div>
            <div class="market-modal-footer">
                <b-button
                    class="market-modal-footer:confirm"
                    @click="$bvModal.hide(`show-received-cancel-hero`)"
                >
                    <div class="button-content">Confirm</div>
                </b-button>
            </div>
        </b-modal>
        <!-- end results -->
    </b-row>
</template>

<style lang="scss">
a.market-user-nav-item {
    text-decoration: none;
}
.market-user-result {
    &-type {
        margin: 40px 0 30px 75px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #fcfcfc;
        span {
            color: #888888;
        }
    }
    &-list {
        table {
            border-collapse: separate;
            border-spacing: 0 5px;
            thead {
                background: rgba(53, 63, 72, 0.2);
                border-radius: 2px;

                th {
                    border: 0;
                    color: #fff;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                }
            }
            tbody {
                tr {
                    border: 0;
                    border-radius: 2px;
                    background: rgba(53, 63, 72, 0.2);
                    &:nth-child(odd) {
                        background: rgba(53, 63, 72, 0.4);
                    }
                    td {
                        border: 0;
                        color: #fff;
                        vertical-align: middle;
                        .hero-info {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            &.left {
                                justify-content: flex-start;
                            }
                            &-avatar {
                                width: 48px;
                                height: 48px;
                                background: linear-gradient(
                                        0deg,
                                        rgba(0, 0, 0, 0.2),
                                        rgba(0, 0, 0, 0.2)
                                    ),
                                    #353f48;
                                /* GrayScale/Label */

                                border: 0.5px solid #555555;
                                border-radius: 8px;
                                padding: 5px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: scale-down;
                                }
                                margin-right: 10px;
                            }
                            &-text {
                                text-align: left;
                                &-name {
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-family: 'HHBI';
                                    color: #fcfcfc;
                                }
                                &-platform {
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #888888;
                                }
                            }
                            &-text2 {
                                text-align: right;
                                &-name {
                                    font-weight: 400;
                                    font-size: 17px;
                                    line-height: 20px;
                                    font-family: 'HHBI';
                                    color: #fcfcfc;
                                }

                                &-platform {
                                    font-weight: 500;
                                    font-size: 15px;
                                    line-height: 20px;
                                    font-family: 'HHBI';
                                    color: #fcfcfcb7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.market-private {
    .market-user {
        @media (max-width: 575px) {
            flex-wrap: wrap;
            .market-user-nav {
                width: 100%;
                order: 1;
                &-item {
                    max-width: unset;
                }
            }
            .sell-item-button {
                width: 100%;
                order: 0;
                margin-bottom: 10px;
            }
        }
    }
    .btn-open-detail {
        padding: 0;
        box-shadow: none !important;
        outline: none !important;
        border: 0 !important;
        height: 25px;
        width: 25px;
        background: #00172a;
        border-radius: 50%;
        margin: auto 5px !important;
        img {
            width: 15px;
            height: 15px;
        }
        &.open {
            width: 25px;
            height: 25px;
            img {
                width: 25px;
                height: 25px;
            }
        }
        @media (min-width: 576px) {
            display: none;
        }
    }
}
a.sell-item-button {
    text-decoration: none;
}
</style>
