<template>
    <div>
        <b-row>
            <b-col cols="12" class="text-white p-5">
                <b-button variant="info" @click="getInfoUser"
                    >Get Info User</b-button
                >

                <br />
                Info User

                <br />

                Address: {{ this.$store.state.info.info.address }}
                <br />
                <template v-for="(box, key) in listBox">
                    <div :key="`${key}-${box}`">{{ key }}:{{ box }}</div>
                </template>
            </b-col>
            <b-col cols="12">
                <b-button
                    variant="danger"
                    v-for="box in listOpen"
                    :key="`name-${box}`"
                    @click="openBox(box)"
                >
                    Open Box {{ box }}
                </b-button>
            </b-col>
            <b-col cols="12" class="mb-5 pb-5" v-if="false">
                <export-excel
                    class="btn btn-success mx-2"
                    :data="dataUpgrade"
                    :fields="json_fields"
                    worksheet="Upgrade"
                    name="Upgrade.xls"
                    title
                >
                    Download Excel Upgrade
                </export-excel>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const moment = require('moment');
const axios = require('@/store/axios.config').default;
const _ = require('lodash');

export default {
    data() {
        return {
            listBox: {},
            listOpen: ['Silver', 'Gold', 'Platinum', 'Diamond'],
            indexOpen: 0,
            // json_fields: {
            //     'TokenId Hero': 'tokenId',
            //     'Name Hero': 'name',
            //     'Rarity Hero': 'ratity',
            //     'Skin Hero': 'skin',
            // },
            typeOpen: null,
        };
    },
    methods: {
        async getInfoUser() {
            const data = await axios.post('/api/box/user-box');
            this.listBox = data;
        },
        async openBox(box) {
            this.getInfoUser();
            if (box) {
                this.typeOpen = box;
            }
            const hero = await axios.post(
                `api/box/open/multi/${this.typeOpen}`,
                {
                    quantity: parseInt(50, 10),
                },
            );
            console.log(hero);
            await this.sleep(1000);
            this.indexOpen += 1;
            if (this.indexOpen < 100) {
                this.openBox();
            }
        },
    },
    async mounted() {
        this.getInfoUser();
    },
};
</script>

<style lang="scss" scoped>
button {
    margin: 15px;
}
</style>
