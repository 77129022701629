<template>
    <div class="inventory-box-item">
        <router-link
            :to="{
                name: 'DetailHero',
                params: { id: hero.tokenId },
            }"
            class="inventory-item-image"
        >
            <img :src="hero.image" alt="" class="mt-4" />
        </router-link>
        <div class="inventory-box-item-content">
            <router-link
                :to="{
                    name: 'DetailHero',
                    params: { id: hero.tokenId },
                }"
            >
                <div class="d-flex">
                    <div class="box-item-rate">
                        {{ hero.level }}/{{ hero.levelMax }}
                    </div>
                    <div class="box-item-name">{{ hero.name }}</div>
                </div>
            </router-link>
            <hr />
            <div class="row">
                <div class="col-8 d-flex flex-column justify-content-between">
                    <div class="d-flex justify-content-between">
                        <img
                            class="inventory-image-modal"
                            :key="`inventory-${hero.id}`"
                            :src="
                                require(`@/assets/images/mockup/inventory/${hero.ratity.toLowerCase()}.png`)
                            "
                            alt=""
                        />
                        <img
                            class="inventory-image-modal"
                            :key="`inventory-2-${hero.id}`"
                            :src="
                                require(`@/assets/images/mockup/inventory/${hero.skin.toLowerCase()}.png?v=1`)
                            "
                            alt=""
                        />
                        <img
                            class="inventory-image-modal"
                            :key="`inventory-3-${hero.id}`"
                            :src="
                                require(`@/assets/images/mockup/inventory/${hero.type.toLowerCase()}.png?v=1`)
                            "
                            alt=""
                        />
                    </div>
                    <div class="d-flex justify-content-between py-2">
                        <span class="inventory-content-title">ID</span>
                        <span
                            class="inventory-content-title"
                            v-if="hero.tokenId"
                            >#{{ Math.abs(hero.tokenId) }}</span
                        >
                    </div>
                    <div class="d-flex justify-content-between">
                        <span class="inventory-content-title">Summon</span>
                        <span
                            class="inventory-content-title"
                            v-if="hero.tokenId"
                        >
                            {{ hero.quantitySummon }}/{{ hero.maxSummon }}
                        </span>
                    </div>
                </div>
                <div
                    class="hero-informations col-4 d-flex flex-column justify-content-between"
                >
                    <div class="d-flex align-items-center pb-2">
                        <img
                            src="../../assets/images/icons/icon-heal.svg"
                            alt=""
                        />
                        <span class="text-white">{{ hero.helth.total }}</span>
                    </div>
                    <div class="d-flex align-items-center py-3">
                        <img
                            src="../../assets/images/icons/icon-damage.svg"
                            alt=""
                        />
                        <span class="text-white">{{ hero.damage.total }}</span>
                    </div>
                    <div class="d-flex align-items-center pt-2">
                        <img
                            src="../../assets/images/icons/icon-speed.svg"
                            alt=""
                        />
                        <span class="text-white">{{ hero.agility.total }}</span>
                    </div>
                </div>
            </div>
            <div v-if="!hero.isSelling" class="row">
                <div class="col-5 pr-0">
                    <button class="bede-btn">
                        <p @click="showModalSell(hero)">Sell</p>
                    </button>
                </div>
                <div class="col-7">
                    <button class="bede-btn">
                        <p @click="showModalTransfer(hero)">Transfer</p>
                    </button>
                </div>
            </div>
            <div v-else class="row align-items-center">
                <div class="col-6 pr-0">
                    <p class="isSellingHero">Hero is Selling</p>
                </div>
                <div class="col-6">
                    <button
                        @click="onShowCancel(hero)"
                        class="bede-btn"
                    >
                        <p>Cancel</p>
                    </button>
                </div>
            </div>
            <div class="inventory-item-line">
                <img src="../../assets/images/icons/icon-line-svg.svg" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
// import { mapGetters, mapMutations } from 'vuex';

export default {
    props: {
        hero: { type: Object, default: null },
    },
    data() {
        return {
            heroInsideModal: null,
        };
    },
    methods: {
        showModalTransfer(hero) {
            this.$emit('showModalTransfer', hero);
        },
        showModalSell(hero) {
            this.$emit('showModalSell', hero);
        },
        onShowCancel(hero) {
            this.$emit('onShowCancel', hero);
        },
    },
};
</script>

<style lang="scss" scoped>
.inventory-box-item {
    max-width: 330px;
    a {
        text-decoration: none !important;
    }
    .inventory-item-image {
        img {
            height: 140px;
        }
    }
    .bede-btn {
        margin-top: 25px;
        height: 38px;
        width: 100%;
        border-radius: 105px;
        &::before {
            border-radius: 105px;
            background: #182c35;
        }
        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #00ffff;
            margin: 0;
        }
    }
    .hero-informations {
        span {
            font-weight: 500;
            font-size: 16px;
            color: #f8f8f8;
            padding-left: 5px;
        }
        img {
            height: 16px;
            width: auto;
        }
    }
    button {
        outline: unset !important;
    }
    .isSellingHero {
        margin: 25px 0 0;
        color: #fff;
        font-family: 'HHBI';
    }
}
</style>
