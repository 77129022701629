<script>
import Summon from '../summon/Summon.vue';
import Genesis from '../summon/Genesis.vue';

export default {
    data() {
        return {
            component: 'summon',
            summon: {},
        };
    },
    components: { Summon, Genesis },
    methods: {
        renderComponent(payload) {
            this.component = payload.component;
            this.summon = payload.summon;
        },

    },
};
</script>
<template>
    <div>
        <Summon
            v-if="component === 'summon'"
            @renderComponent="renderComponent"
        />
        <Genesis
            v-if="component === 'genesis-summon'"
            @renderComponent="renderComponent"
        />
        <Genesis
            v-if="component === 'genesis-summon-process'"
            @renderComponent="renderComponent"
            :isSummonProcess="true"
            :summon="summon"
        />
    </div>
</template>

<style lang="scss">
// .summon-page {
//     .header-page {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//     }
//     .summon-new {
//         button {
//             background-color: #fff;
//             overflow: hidden;
//             width: 150px;
//         }
//     }
//     .title {
//         color: #00ffff;
//         font-family: 'Helvetica';
//         font-size: clamp(1.2em, 4vw, 1.8em);
//         padding-bottom: 10px;
//         position: relative;
//         letter-spacing: 0.6px;
//         width: max-content;
//         margin: 0 0 25px;
//         font-weight: 900;
//         &::after {
//             content: '';
//             bottom: 0;
//             left: 0;
//             width: calc(105% + 10px);
//             height: 2px;
//             background: #00ffff;
//             position: absolute;
//             font-weight: 600;
//         }
//     }

//     .btn-new-summon {
//         background-image: url(~@/assets/images/mockup/summon/Summon.png);
//         background-size: 100% 100%;
//         padding: 5px 20px;

//         font-family: "helvetica-neue-regular";
//         font-size: 20px;
//         color: #fff;
//         text-align: center;

//         cursor: pointer;
//         transition: .3s;

//         &:hover {
//             opacity: .8;
//         }
//     }

//     .box-input-group {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         margin-bottom: 20px;
//         max-width: 600px;
//         .icons {
//             img {
//                 width: 17px;
//                 height: auto;
//             }
//         }
//         input {
//             outline: none;
//             border: 1px solid #00ffff;
//             background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
//             height: 40px;
//             width: 80%;
//             border-radius: 0;
//             color: #fff;
//         }
//         button {
//             background: #00ffff;
//             border-radius: 0;
//             height: 40px;
//             color: #000;
//             font-weight: 600;
//             font-size: 18px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             text-transform: capitalize;
//         }
//     }

//     .summon-navbar {
//         background-image: url(~@/assets/images/frame/frame-ligting-blue.png);
//         background-size: 100% 100%;
//         align-content: center;
//         padding: 10px 0;

//         &-item {
//             padding: 0 30px;

//             font-family: "helvetica-neue-regular";
//             font-size: 20px;
//             color: #BAD1C9;
//             text-align: center;

//             &:hover {
//                 color: #00ffff;
//                 background-color: rgba(0, 33, 51, 0.6);
//             }
//         }
//         .btn-toogle {
//             width: 35px;
//             height: 35px;
//         }
//     }

//     .summon-item {
//         background-color: rgba(2, 29, 51, 0.8);
//         border: 3px solid rgba(65, 147, 231, 0.8);

//         .img-hero {
//             img {
//                 display: block;
//                 margin: 0 auto;
//             }
//         }

//         .rare-and-box {
//             background-image: url(~@/assets/images/mockup/summon/frame2.png);
//             background-size: 100% 100%;
//             // background-repeat: no-repeat;
//             padding: 10px 0;
//             font-family: "helvetica-neue-regular";
//             font-size: 14px;
//             color: #00ffff;

//             span {
//                 display: inline-block;
//                 margin-left: 5px;
//             }
//         }

//         .frame-process {
//             border: 3px solid #015160;
//             .process {
//                 width: 70%;
//                 height: 100%;
//                 background: #00ffff;

//                 left: 0;
//                 top: 0;
//             }
//             p {
//                 position: relative;
//                 z-index: 1;
//                 font-weight: bold;
//                 color: #01171D;
//             }
//         }

//         .btn-claim-box {
//             background-size: 100% 100%;
//             cursor: pointer;
//             width: 163px;
//             height: 45px;

//             color: white;
//             font-family: "helvetica-neue-regular";
//             font-size: 18px;
//             line-height: 45px;

//             &.orange {
//                 background-image: url(~@/assets/images/frame/frame-summon-orange.png);
//             }

//             &.blue {
//                 background-image: url(~@/assets/images/frame/frame-summon-blue.png);
//             }
//         }
//     }

//     .modify-row {
//         margin-left: -10px;
//         margin-right: -10px;

//         .col {
//             padding-left: 10px;
//             padding-right: 10px;
//         }
//     }
// }
</style>
