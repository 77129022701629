<script>
import {
    required,
    email,
    sameAs,
    minLength,
    // number,
    not,
} from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';

const axios = require('@/store/axios.config').default;

export default {
    data() {
        return {
            isUpdateAvatar: false,
            isChose: '',
            setUp: {
                email: '',
                password: '',
                repeat: '',
            },
            confirm: {
                code: '',
            },
            showConfirm: false,
            info: {
                name: '',
            },
            changeEmail: {
                current_password: '',
                userEmail: '',
                newEmail: '',
            },
            change: {
                current_password: '',
                new_password: '',
                User_Re_New_Password: '',
            },
            show1: false,
            show2: false,
            show3: false,
            submitted: false,
            notificationStatus: true,
            listSystem: [
                {
                    img: '',
                    nameSystem: 'Ecosystem',
                    decs: 'Connect with Ecosystem to do something...',
                    id: 0,
                },
                {
                    img: '',
                    nameSystem: 'Hero Snake',
                    decs: 'Connect with Hero Snake to do something...',
                    id: 1,
                },
                {
                    img: '',
                    nameSystem: 'Hero War',
                    decs: 'Connect with Hero War to do something...',
                    id: 2,
                },
            ],
            ecosystemChoose: {},
            ecosystem: {
                email: '',
                otp: '',
            },
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        onSetupAccount() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.confirm.$invalid.code) {
                return;
            }

            this.$store.dispatch('info/req_postConfirmEmail', {
                token: this.confirm.code,
            });
        },
        onSetupNewAccount(action) {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.setUp.$invalid) {
                return;
            }
            if (action === 'sendmail') {
                this.$store.dispatch('info/req_updateInfo', this.setUp);
            }
        },
        onChangePassword() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.change.$invalid) {
                return;
            }
            this.$store.dispatch('info/req_postChangePassword', this.change);
        },
        async onChangeEmail(type) {
            this.submitted = true;
            this.$v.$touch();

            if (type === 'code') {
                if (this.$v.changeEmail.$invalid) {
                    return;
                }
                this.$store.dispatch('info/req_postChangeEmail', {
                    email: this.changeEmail.userEmail,
                    password: this.changeEmail.current_password,
                    newEmail: this.changeEmail.newEmail,
                });
            }
            if (type === 'verify') {
                if (this.$v.confirm.$invalid.code) {
                    return;
                }
                this.onLoad();
                const { address } = this.UserInfo;
                const txData = await window.ethereum.request({
                    method: 'personal_sign',
                    params: [address, 'Confirm Change Mail'],
                });
                if (!txData) {
                    this.outLoad();
                    return;
                }
                this.outLoad();
                this.$store.dispatch('info/req_postConfirmChangeEmail', {
                    token: this.confirm.code,
                    sign: txData,
                });
            }
        },
        onChangeName() {
            const { name } = this.info;
            if (!name) {
                this.$toastr.e(
                    'Name at least 5 characters',
                    'Change Name Failed.',
                );
                return;
            }
            this.$store.dispatch('info/req_postChangeName', {
                username: this.info.name,
            });
        },
        checkStatusEmail() {
            const { email, IsVerifyMail } = this.UserInfo;
            if (!email) {
                this.showConfirm = false;
                return;
            }
            if (email && !IsVerifyMail) {
                this.showConfirm = true;
            }
        },
        reSendMail() {
            this.$store.dispatch('info/req_postReEmail', {
                email: this.UserInfo.email,
            });
        },
        forgotPassword() {
            this.$store.dispatch('info/req_getForgot');
        },
        onCloseChange() {
            this.showConfirm = false;
            this.changeEmail = {
                current_password: '',
                userEmail: '',
                newEmail: '',
            };
            this.confirm.code = null;
        },
        changeAvatarMb() {
            this.$store.dispatch('info/req_postChoseAvatar', {
                image: this.isChose,
            });
        },
        changeAvatar(item) {
            this.$store.dispatch('info/req_postChoseAvatar', {
                image: item,
            });
        },
        async onConnectSystem() {
            this.onLoad();
            const { address } = this.UserInfo;
            const { web3 } = this.$store.state.contract;
            let checkSum = '';
            if (address) {
                checkSum = web3.provider.utils.toChecksumAddress(address);
            } else {
                this.$toastr.e('Something went wrong', 'Error');
                return;
            }
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [checkSum, 'Confirm Connect'],
            });

            if (!txData) {
                this.$toastr.e('Please Confirm Transfer', 'Error');
                this.outLoad();
                return;
            }
            const data = await axios.post(
                `/api/market/connect-email/${
                    this.ecosystemChoose.id || this.ecosystemChoose._id
                }`,
                {
                    sign: txData,
                    email: this.ecosystem.email,
                },
            );
            console.log(data);
            if (data.successful) {
                this.$bvModal.hide('connect-ecosystem');
                this.$bvModal.show('connect-OTP');
            }
            this.outLoad();
        },
        async onConfirmOTP() {
            this.onLoad();
            const data = await axios.post(
                `/api/market/verify-connect-email/${
                    this.ecosystemChoose.id || this.ecosystemChoose._id
                }`,
                {
                    token: this.ecosystem.otp,
                },
            );

            if (data.successful) {
                this.$bvModal.hide('connect-OTP');
                this.$store.dispatch('info/req_getListConnectSystems');
            }
            this.outLoad();
        },
        onShowConnectModal(item) {
            this.ecosystemChoose = item;
            this.$bvModal.show('connect-ecosystem');
        },
        scrollToElement(elm) {
            const el = document.querySelector(elm);
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                setTimeout(() => {
                    el.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
        },
    },
    validations: {
        setUp: {
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength: minLength(8),
            },
            repeat: {
                required,
                sameAsPassword: sameAs('password'),
            },
        },
        confirm: {
            code: {
                required,
                minLength: minLength(6),
            },
        },
        changeEmail: {
            current_password: {
                required,
            },
            userEmail: {
                required,
                email,
            },
            newEmail: {
                required,
                email,
            },
        },
        change: {
            current_password: {
                required,
            },
            new_password: {
                required,
                minLength: minLength(8),
                notSameAsPassword: not(sameAs('current_password')),
            },
            User_Re_New_Password: {
                required,
                sameAsPassword: sameAs('new_password'),
            },
        },
    },
    watch: {
        UserInfo: {
            handler(newVal) {
                if (newVal && newVal.Username) {
                    this.info.name = newVal.Username;
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            ListAvatar: 'info/ListAvatar',
            ListPlatform: 'info/ListPlatform',
        }),
    },
    mounted() {
        if (this.UserInfo) {
            this.info.name = this.UserInfo.Username;
        }
        this.$store.watch(
            (state) => state.info.info.Username,
            (username) => {
                if (username) this.info.name = username;
            },
        );
        this.$store.dispatch('info/req_getListConnectSystems');
        if (this.$route.hash) this.scrollToElement(this.$route.hash);
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            // eslint-disable-next-line default-case
            switch (mutation.type) {
                case 'info/CHANGE_PASSWORD_SUCCESS':
                    this.$bvModal.hide('change-password');
                    this.change = {
                        current_password: '',
                        new_password: '',
                        User_Re_New_Password: '',
                    };

                    this.submitted = false;
                    break;
                case 'info/CHANGE_EMAIL_SUCCESS':
                    // this.$bvModal.hide('change-email');
                    this.changeEmail = {
                        current_password: '',
                        userEmail: '',
                        newEmail: '',
                    };
                    this.submitted = false;
                    this.showConfirm = true;
                    break;
                case 'info/CHANGE_EMAIL_CONFIRM':
                    this.$bvModal.hide('change-email');
                    this.changeEmail = {
                        current_password: '',
                        userEmail: '',
                        newEmail: '',
                    };
                    this.submitted = false;
                    this.showConfirm = false;
                    break;
                case 'info/CONFIRM_MAIL':
                    this.showConfirm = true;
                    break;
                case 'info/CONFIRM_MAIL_SUCCESS':
                    this.$bvModal.hide('setup-account');
                    this.showConfirm = false;
                    break;

                case 'info/SET_AVATAR_SUCCESS':
                    this.$bvModal.hide('ListAvatar');
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
<template>
    <b-container class="setting-tab">
        <template v-if="UserInfo.email && UserInfo.IsVerifyMail">
            <div class="d-flex align-items-center mb-4">
                <div class="header-icon mr-5">
                    <img src="~@/assets/images/icons/header-icon.png" alt="" />
                </div>
                <div class="title p-0">Avatar</div>
            </div>
            <b-container class="px-0">
                <b-row class="ml-0 w-100 avatar-setting d-none d-sm-flex">
                    <b-col
                        cols="5"
                        class="d-flex justify-content-center align-items-center"
                    >
                        <div
                            v-if="UserInfo && UserInfo.Avatar"
                            class="avatar m-auto userAvatar"
                        >
                            <img
                                :key="`avatar-${UserInfo.Avatar}`"
                                :src="UserInfo.Avatar"
                                alt=""
                            />
                        </div>
                        <div v-else class="no-avatar-fr">
                            <h4>No Data</h4>
                        </div>
                    </b-col>
                    <b-col cols="7" class="avatar-list-col">
                        <b-row class="avatar-list-fr">
                            <div class="dot dot-1">
                                <img
                                    src="~@/assets/images/icons/dot-icon.png"
                                    alt=""
                                />
                            </div>
                            <div class="dot dot-2">
                                <img
                                    src="~@/assets/images/icons/dot-icon.png"
                                    alt=""
                                />
                            </div>
                            <b-col
                                v-for="(avatar, key) in ListAvatar"
                                :key="`${avatar}-${key}`"
                                md="3"
                                cols="6"
                                class="px-2 my-2 my-md-4 my-sm-3 my-md-2 d-flex justify-content-center align-items-center"
                            >
                                <button
                                    @click="changeAvatar(avatar)"
                                    class="avatar-item"
                                    :class="{
                                        activeAvatar:
                                            isChose == avatar ||
                                            UserInfo.Avatar == avatar,
                                    }"
                                >
                                    <img :src="avatar" alt="" />
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <div
                    class="avatar-mb d-flex d-sm-none"
                    @mouseenter="isUpdateAvatar = true"
                    @mouseleave="isUpdateAvatar = false"
                >
                    <img
                        v-if="UserInfo && UserInfo.Avatar"
                        :key="`avatar-${UserInfo.Avatar}`"
                        :src="UserInfo.Avatar"
                        alt=""
                    />
                    <img
                        v-else
                        src="~@/assets/images/mockup/account/hero.png"
                        width="100%"
                        alt=""
                    />
                    <div class="update-avatar" v-if="isUpdateAvatar">
                        <button @click="$bvModal.show('ListAvatar')">
                            Change
                        </button>
                    </div>
                    <div class="camera">
                        <img src="~@/assets/images/icons/camera.svg" alt="" />
                    </div>
                </div>
                <b-modal
                    id="ListAvatar"
                    title="List Avatar Available"
                    :centered="true"
                    footer-class="d-none"
                >
                    <div class="list-avatar">
                        <b-button
                            class="avatar-button"
                            v-for="(avatar, key) in ListAvatar"
                            :key="`${avatar}-${key}`"
                            @click="isChose = avatar"
                            :class="{
                                active:
                                    isChose == avatar ||
                                    UserInfo.Avatar == avatar,
                            }"
                        >
                            <img :src="avatar" alt="" />
                        </b-button>
                    </div>
                    <div
                        class="list-button d-flex justify-content-center align-items-center"
                    >
                        <b-button
                            variant="success"
                            class="d-block btn-confirm-p rounded-2"
                            @click="changeAvatarMb"
                            :disabled="!isChose"
                        >
                            Change
                        </b-button>
                        <b-button
                            variant="danger"
                            class="d-block rounded-2"
                            @click="$bvModal.hide(`ListAvatar`)"
                        >
                            Close
                        </b-button>
                    </div>
                </b-modal>
            </b-container>
            <div class="d-flex align-items-center mb-5">
                <div class="header-icon mr-5">
                    <img src="~@/assets/images/icons/header-icon.png" alt="" />
                </div>
                <div class="title p-0">General Setting</div>
            </div>
            <div class="change-info-box">
                <b-container fluid class="px-0">
                    <b-row class="ml-0 w-100">
                        <b-col
                            md="2"
                            lg="1"
                            cols="2"
                            class="px-0 d-flex align-items-center justify-content-center"
                        >
                            <div class="edit-icon d-flex align-items-center">
                                <img
                                    src="~@/assets/images/icons/edit.png"
                                    alt=""
                                />
                            </div>
                        </b-col>
                        <b-col md="10" lg="11" cols="10">
                            <div class="change-name">
                                <div class="group-input general-setting">
                                    <b-form-input
                                        :placeholder="info.name"
                                        role="presentation"
                                        autocomplete="off"
                                        v-model="info.name"
                                    ></b-form-input>
                                    <b-button
                                        class="save-btn mb-2"
                                        @click="onChangeName"
                                    >
                                        <p>Save Name</p>
                                    </b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="ml-0 w-100 mt-5">
                        <b-col
                            md="2"
                            lg="1"
                            cols="2"
                            class="px-0 d-flex align-items-center justify-content-center"
                        >
                            <div class="edit-icon d-flex align-items-center">
                                <img
                                    src="~@/assets/images/icons/mail-icon.png"
                                    alt=""
                                />
                            </div>
                        </b-col>
                        <b-col md="10" lg="11" cols="10">
                            <div class="change-email">
                                <div class="group-input general-setting">
                                    <b-form-input
                                        role="presentation"
                                        autocomplete="off"
                                        readonly
                                        v-model="UserInfo.email"
                                    ></b-form-input>
                                    <b-button
                                        class="edit-mail-btn"
                                        v-b-modal.change-email
                                    >
                                        <img
                                            src="~@/assets/images/icons/pencil.svg"
                                            alt=""
                                        />
                                    </b-button>
                                </div></div
                        ></b-col>
                    </b-row>
                </b-container>
                <div class="d-flex align-items-center mb-2 my-5 mb-3">
                    <div class="header-icon mr-5">
                        <img
                            src="~@/assets/images/icons/header-icon.png"
                            alt=""
                        />
                    </div>
                    <div class="title p-0">Log In</div>
                </div>
                <b-container fluid class="px-0">
                    <b-row class="w-100 ml-0 mt-2">
                        <b-col
                            md="2"
                            lg="1"
                            cols="2"
                            class="px-0 d-flex align-items-center justify-content-center"
                        >
                            <div class="key-icon d-flex align-items-center">
                                <img
                                    src="~@/assets/images/icons/key-icon.png"
                                    alt=""
                                />
                            </div>
                        </b-col>
                        <b-col lg="11" md="10" cols="10">
                            <div class="change-password">
                                <div class="label">Change Password</div>
                                <div class="des-fr">
                                    <p>
                                        You should use a strong password that
                                        you haven't used anywhere else.
                                    </p>
                                    <b-button
                                        class="change-password-btn"
                                        v-b-modal.change-password
                                    >
                                        <img
                                            src="~@/assets/images/icons/right-arrow.png"
                                            alt=""
                                        />
                                    </b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <div class="divide-line"></div>
                    <b-row class="w-100 ml-0 mt-4">
                        <b-col
                            md="2"
                            lg="1"
                            cols="2"
                            class="px-0 d-flex align-items-center justify-content-center"
                        >
                            <div class="key-icon d-flex align-items-center">
                                <img
                                    src="~@/assets/images/icons/forgot-psw.png"
                                    alt=""
                                />
                            </div>
                        </b-col>
                        <b-col lg="11" md="10" cols="10">
                            <div class="change-password">
                                <div class="label">Forgot Password</div>
                                <div class="des-fr mt-2">
                                    <p>Send mail now.</p>
                                    <b-button
                                        class="change-password-btn"
                                        @click="$bvModal.show('confirm-forgot')"
                                    >
                                        <img
                                            src="~@/assets/images/icons/right-arrow.png"
                                            alt=""
                                        />
                                    </b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>

                <div
                    class="d-flex align-items-center mb-2 my-5 mb-3"
                    id="ecosystem"
                    ref="ecosystem"
                >
                    <div class="header-icon mr-5">
                        <img
                            src="~@/assets/images/icons/header-icon.png"
                            alt=""
                        />
                    </div>
                    <div class="title p-0">Connect System</div>
                </div>
                <b-container fluid>
                    <div
                        v-for="(item, idx) in ListPlatform"
                        :key="idx + 'abc'"
                        class="box-connecting"
                    >
                        <b-row>
                            <b-col
                                md="2"
                                lg="1"
                                cols="2"
                                class="px-0 d-flex align-items-center justify-content-center"
                            >
                                <div
                                    class="connect-icon d-flex align-items-center"
                                >
                                    <img :src="item.image" alt="" />
                                </div>
                            </b-col>
                            <b-col lg="11" md="10" cols="10">
                                <div class="change-password">
                                    <div class="label">
                                        {{ item.name }}
                                    </div>
                                    <div class="des-fr">
                                        <p v-if="item.isConnected">
                                            {{
                                                item.typeConnect
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                item.typeConnect.slice(1)
                                            }}
                                            Connect:
                                            {{
                                                `${item[item.typeConnect].slice(
                                                    0,
                                                    item[item.typeConnect]
                                                        .length - 10,
                                                )}***${item[
                                                    item.typeConnect
                                                ].slice(
                                                    item[item.typeConnect]
                                                        .length - 0,
                                                    item[item.typeConnect]
                                                        .length,
                                                )}`
                                            }}
                                        </p>
                                        <p v-else>
                                            Type Connect: {{ item.typeConnect }}
                                        </p>
                                        <b-button
                                            class="change-password-btn"
                                            v-if="item.isConnected"
                                        >
                                            <img
                                                src="~@/assets/images/icons/round_check.svg"
                                                alt=""
                                            />
                                        </b-button>
                                        <b-button
                                            class="btn-connect"
                                            @click="onShowConnectModal(item)"
                                            v-else
                                        >
                                            <span>Connect</span>
                                            <img
                                                src="~@/assets/images/icons/right-arrow.png"
                                                alt=""
                                            />
                                        </b-button>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-container>
                <!-- <div class="change-password">
                    <div class="label">Forgot Password</div>
                    <div class="group-input">
                        <b-button class="button-confirm">
                            Send Mail Now
                        </b-button>
                    </div>
                </div> -->
            </div>
            <div v-if="false">
                <div class="subtitle mt-5 mb-0 pt-2">Notification Settings</div>
                <div class="send-notification">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="notificationStatus"
                        name="checkbox-1"
                        value="accepted"
                        unchecked-value="not_accepted"
                    >
                        Send me an email whenever I successfully sell an asset.
                    </b-form-checkbox>
                </div>
            </div>
        </template>
        <div class="setup-account" v-else>
            <div class="text">Let’s complete setting up your account</div>
            <b-button variant="info" v-b-modal.setup-account>
                Set up email & password
            </b-button>
        </div>
        <b-modal
            id="setup-account"
            title="SET UP EMAIL & PASSWORD"
            hide-footer
            @close="showConfirm = false"
        >
            <b-form
                autocomplete="false"
                @submit.prevent="onSetupNewAccount('sendmail')"
                v-if="!showConfirm"
            >
                <b-form-input
                    role="presentation"
                    autocomplete="off"
                    v-model="setUp.email"
                    type="email"
                    placeholder="Email"
                    :class="{
                        'is-invalid': submitted && $v.setUp.email.$error,
                    }"
                ></b-form-input>
                <div
                    v-if="submitted && $v.setUp.email.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.setUp.email.required">
                        This field is required
                    </span>
                    <span v-if="!$v.setUp.email.email">
                        Is not a valid email
                    </span>
                </div>
                <b-form-input
                    role="presentation"
                    autocomplete="off"
                    v-model="setUp.password"
                    type="password"
                    placeholder="Password"
                    :class="{
                        'is-invalid': submitted && $v.setUp.password.$error,
                    }"
                ></b-form-input>
                <div
                    v-if="submitted && $v.setUp.password.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.setUp.password.required">
                        This field is required
                    </span>
                    <span v-if="!$v.setUp.password.minLength">
                        Password at least 8 characters
                    </span>
                </div>
                <b-form-input
                    role="presentation"
                    autocomplete="off"
                    v-model="setUp.repeat"
                    type="password"
                    placeholder="Repeat Password"
                    :class="{
                        'is-invalid': submitted && $v.setUp.repeat.$error,
                    }"
                ></b-form-input>
                <div
                    v-if="submitted && $v.setUp.repeat.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.setUp.repeat.required">
                        This field is required
                    </span>
                    <span v-if="!$v.setUp.repeat.sameAsPassword">
                        Repeat password must be the same as the password
                    </span>
                </div>
                <div
                    class="w-100 d-flex align-items-center justify-content-center mt-3"
                >
                    <b-button class="bede-btn" type="submit">
                        <p>Confirm</p>
                    </b-button>
                </div>
            </b-form>
            <b-form
                autocomplete="false"
                v-else
                @submit.prevent="onSetupAccount('confirm')"
                class="confirm-form"
            >
                <div class="text">
                    We have sent you a verification code to your email:
                    <div class="key-text">{{ UserInfo.email }}</div>
                    Please verify it before you use this email to login
                    <div class="confirm-form-group flex-wrap align-items-start">
                        <div class="d-flex justify-content-center flex-column">
                            <b-form-input
                                role="presentation"
                                autocomplete="off"
                                v-model="confirm.code"
                                type="text"
                                placeholder="Enter your code"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.confirm.code.$error,
                                }"
                            ></b-form-input>
                            <div
                                v-if="submitted && $v.confirm.code.$error"
                                class="invalid-feedback w-100 pt-2 text-left"
                            >
                                <span
                                    class="text-left"
                                    v-if="!$v.confirm.code.required"
                                >
                                    This field is required
                                </span>
                                <!-- <span class="text-left"  v-if="!$v.confirm.code.number">
                                    The confirm code only contains numbers
                                </span> -->
                                <span
                                    class="text-left"
                                    v-if="!$v.confirm.code.minLength"
                                >
                                    Confirm code at least 8 characters
                                </span>
                            </div>
                        </div>
                        <b-button class="button-confirm" type="submit">
                            Confirm
                        </b-button>
                    </div>
                    <a
                        class="key-text mb-0"
                        @click="reSendMail"
                        style="cursor: pointer"
                    >
                        Resend Mail
                    </a>
                    <div class="note">*Closing this will cancel the setup</div>
                </div>
            </b-form>
        </b-modal>
        <b-modal
            id="change-email"
            :title="!showConfirm ? 'CHANGE EMAIL' : 'VERIFY EMAIL'"
            hide-footer
            @hidden="onCloseChange()"
        >
            <b-form @submit.prevent="onChangeEmail('code')" v-if="!showConfirm">
                <div class="text-showcase">
                    We have sent you a verification code to your email Please
                    verify it before you use this email to login
                </div>
                <div
                    class="group-input d-flex justify-content-between align-items-center mb-0"
                >
                    <div class="label">Current Email</div>
                    <b-form-input
                        role="presentation"
                        autocomplete="off"
                        v-model="changeEmail.userEmail"
                        type="email"
                        placeholder="Current Email"
                        :class="{
                            'is-invalid':
                                submitted && $v.changeEmail.userEmail.$error,
                        }"
                    ></b-form-input>
                </div>
                <div
                    v-if="submitted && $v.changeEmail.userEmail.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.changeEmail.userEmail.required">
                        This field is required
                    </span>
                    <span v-if="!$v.changeEmail.userEmail.email">
                        Is not a valid email
                    </span>
                </div>
                <div
                    class="group-input d-flex justify-content-between align-items-center mb-0"
                >
                    <div class="label">New Email</div>
                    <b-form-input
                        role="presentation"
                        autocomplete="off"
                        v-model="changeEmail.newEmail"
                        type="email"
                        placeholder="New Email"
                        :class="{
                            'is-invalid':
                                submitted && $v.changeEmail.newEmail.$error,
                        }"
                    ></b-form-input>
                </div>
                <div
                    v-if="submitted && $v.changeEmail.newEmail.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.changeEmail.newEmail.required">
                        This field is required
                    </span>
                    <span v-if="!$v.changeEmail.newEmail.email">
                        Is not a valid email
                    </span>
                </div>

                <div
                    class="group-input d-flex justify-content-between align-items-center mb-0"
                >
                    <div class="label">Password</div>
                    <b-input-group class="">
                        <template #append>
                            <b-input-group-text class="p-0">
                                <b-button
                                    variant="info"
                                    @click="show1 = !show1"
                                    :key="`show-3${show1}`"
                                >
                                    <i
                                        class="far fa-eye"
                                        :class="
                                            show1 ? 'fa-eye-slash' : 'fa-eye'
                                        "
                                    ></i>
                                </b-button>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            class="mt-0"
                            role="presentation"
                            autocomplete="off"
                            :type="show1 ? 'text' : 'password'"
                            id="current_password"
                            v-model.trim="changeEmail.current_password"
                            placeholder="Current Password"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.changeEmail.current_password.$error,
                            }"
                        ></b-form-input>
                    </b-input-group>
                </div>
                <div
                    v-if="submitted && $v.changeEmail.current_password.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.changeEmail.current_password.required">
                        This field is required
                    </span>
                </div>
                <b-button class="button-confirm w-100 mt-4" type="submit">
                    Confirm
                </b-button>
                <div class="note-warning">
                    *Closing this will cancel the setup
                </div>
            </b-form>
            <b-form
                v-else
                @submit.prevent="onChangeEmail('verify')"
                class="confirm-form"
            >
                <div class="text">
                    We have sent you a verification code in email:
                    <div class="key-text">{{ changeEmail.userEmail }}</div>

                    Please verify it before you can this email to login
                    <div class="confirm-form-group">
                        <div class="d-flex justify-content-center flex-column">
                            <b-form-input
                                role="presentation"
                                autocomplete="off"
                                v-model="confirm.code"
                                type="text"
                                placeholder="Code Confirm"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.confirm.code.$error,
                                }"
                            ></b-form-input>
                            <div
                                v-if="submitted && $v.confirm.code.$error"
                                class="invalid-feedback w-100 pt-2 text-left"
                            >
                                <span v-if="!$v.confirm.code.required">
                                    This field is required
                                </span>
                                <span v-if="!$v.confirm.code.minLength">
                                    Confirm code at least 8 characters
                                </span>
                            </div>
                        </div>
                        <b-button class="button-confirm" type="submit">
                            Confirm
                        </b-button>
                    </div>
                    <div class="note">*Closing this will cancel the setup</div>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="change-password" title="Change Password" hide-footer>
            <b-form @submit.prevent="onChangePassword">
                <div class="form-group">
                    <b-input-group class="">
                        <template #append>
                            <b-input-group-text class="p-0">
                                <b-button
                                    variant="info"
                                    @click="show1 = !show1"
                                    :key="`show-3${show1}`"
                                >
                                    <i
                                        class="far fa-eye"
                                        :class="
                                            show1 ? 'fa-eye-slash' : 'fa-eye'
                                        "
                                    ></i>
                                </b-button>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            role="presentation"
                            autocomplete="off"
                            :type="show1 ? 'text' : 'password'"
                            required
                            id="current_password"
                            v-model.trim="change.current_password"
                            placeholder="Current Password"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.change.current_password.$error,
                            }"
                        ></b-form-input>
                    </b-input-group>
                    <div
                        v-if="submitted && $v.change.current_password.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.change.current_password.required">
                            this field is required
                        </span>
                    </div>
                    <a
                        href="#"
                        class="text-center mx-auto d-block"
                        v-if="false"
                    >
                        Forget Password ?
                    </a>
                </div>
                <div class="form-group">
                    <b-input-group class="">
                        <template #append>
                            <b-input-group-text class="p-0">
                                <b-button
                                    variant="info"
                                    @click="show2 = !show2"
                                    :key="`show-3${show2}`"
                                >
                                    <i
                                        class="far fa-eye"
                                        :class="
                                            show2 ? 'fa-eye-slash' : 'fa-eye'
                                        "
                                    ></i>
                                </b-button>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            role="presentation"
                            autocomplete="off"
                            id="new_password "
                            v-model.trim="change.new_password"
                            :type="show2 ? 'text' : 'password'"
                            placeholder="New Password"
                            :class="{
                                'is-invalid':
                                    submitted && $v.change.new_password.$error,
                            }"
                        ></b-form-input>
                    </b-input-group>
                    <div
                        v-if="submitted && $v.change.new_password.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.change.new_password.required">
                            This field is required
                        </span>
                        <span v-if="!$v.change.new_password.minLength">
                            Min length is 8 characters
                        </span>
                        <span v-if="!$v.change.new_password.notSameAsPassword">
                            New Password not Same as Password
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <b-input-group class="">
                        <template #append>
                            <b-input-group-text class="p-0">
                                <b-button
                                    variant="info"
                                    @click="show3 = !show3"
                                    :key="`show-3${show3}`"
                                >
                                    <i
                                        class="far fa-eye"
                                        :class="
                                            show3 ? 'fa-eye-slash' : 'fa-eye'
                                        "
                                    ></i>
                                </b-button>
                            </b-input-group-text>
                        </template>
                        <b-form-input
                            role="presentation"
                            autocomplete="off"
                            :type="show3 ? 'text' : 'password'"
                            required
                            id="User_Re_New_Password "
                            v-model.trim="change.User_Re_New_Password"
                            placeholder="Repeat New Password"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.change.User_Re_New_Password.$error,
                            }"
                        ></b-form-input>
                    </b-input-group>
                    <div
                        v-if="
                            submitted &&
                            !$v.change.User_Re_New_Password.required
                        "
                        class="invalid-feedback"
                    >
                        This field is required
                    </div>
                    <div
                        v-if="
                            submitted &&
                            !$v.change.User_Re_New_Password.sameAsPassword
                        "
                        class="invalid-feedback"
                    >
                        The Repeat password is same as the password
                    </div>
                </div>
                <div class="form-group d-flex justify-content-center">
                    <b-button
                        class="mx-auto mt-3 button-confirm"
                        variant="primary"
                        type="submit"
                    >
                        Confirm
                    </b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal
            id="connect-ecosystem"
            :title="`Connect to ${ecosystemChoose.name}`"
            hide-footer
        >
            <b-form @submit.prevent="onConnectSystem()">
                <div class="form-group">
                    <b-input-group class="">
                        <b-form-input
                            role="presentation"
                            autocomplete="off"
                            placeholder="Your email"
                            required
                            type="email"
                            v-model="ecosystem.email"
                        ></b-form-input>
                    </b-input-group>
                </div>
                <div class="form-group d-flex justify-content-center">
                    <b-button
                        class="mx-auto mt-3 button-confirm"
                        variant="primary"
                        type="submit"
                    >
                        Connect
                    </b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal
            id="connect-OTP"
            :title="`Confirm OTP to ${ecosystemChoose.name}`"
            hide-footer
        >
            <b-form @submit.prevent="onConfirmOTP">
                <div class="form-group">
                    <b-input-group class="">
                        <b-form-input
                            role="presentation"
                            autocomplete="off"
                            placeholder="Your OTP"
                            v-model="ecosystem.otp"
                            required
                            type="text"
                        ></b-form-input>
                    </b-input-group>
                </div>
                <div class="form-group d-flex justify-content-center">
                    <b-button
                        class="mx-auto mt-3 button-confirm"
                        variant="primary"
                        type="submit"
                    >
                        Confirm
                    </b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal
            centered
            id="confirm-forgot"
            title="Confirm Forgot Password"
            hide-footer
        >
            <div>
                <p class="mb-0 text-confirm">
                    Do you want to send email without new password?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="forgotPassword"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>
<style lang="scss" scoped>
.select-connect {
    select {
        color: #bbb;
        padding: 0;
    }
    .custom-select {
        background: rgba(0, 255, 255, 0.2) !important;
        background: none;
        border-radius: 0;
        border: none;
        outline: unset;
        box-shadow: none;
        text-align: center;
    }
}
.box-connecting {
    // box-shadow: 5px 5px 5px 5px black;
    margin-bottom: 1.5rem;
    padding: 5px 10px;
    cursor: pointer;
    .row {
        align-items: center;
        transition: linear 1s;
        .btn-connect {
            background: transparent;
            border: none;
            border-radius: 19.5px;
            transition: linear 1s;
            span {
                display: none;
            }
        }
        &:hover,
        &:focus,
        :active {
            overflow: hidden;
            transform: scale(1.05);
            .btn-connect {
                max-width: 100px;
                outline: none;
                width: 100% !important;
                background: rgba(0, 255, 255, 0.2) !important;
                height: 30px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;


                padding: 5px;
                span {
                    display: block;
                }
                img {
                    width: 12px;
                    height: 20px;
                    margin-left: 2px;
                }
            }
        }
    }
}
.change-name,
.change-email {
    input {
        background: none !important;
    }
}
#setup-account {
    input {
        border: 1px solid white;
    }
}
.divide-line {
    width: 100%;
    height: 1px;
    background: #555555;
    margin: 1.25rem auto;
}
#ListAvatar {
    .list-avatar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        .avatar-button {
            padding: 0;
            background: none;
            margin: 10px;
            img {
                width: 75px;
            }
            &.active {
                border: 4px solid #00b1ff;
            }
        }
    }
    .list-button {
        button {
            width: 100px;
            margin: 10px;
        }
    }
    .btn-confirm-p {
        display: flex;
        justify-content: center;
        height: 40px;
        align-items: center;
        font-size: 18px;
        font-family: 'HHBI';
        color: #00ffff;
        background: #00172a;
        border: 1px solid #00ffff;
        // border-radius: 10px;
        padding: 5px;
        &:hover {
            background: #00ffff;
            color: #00172a;
        }
    }
}
.avatar-mb {
    width: 150px;
    height: 150px;
    background: #fff;
    border-radius: 50%;
    margin: 10px auto 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: relative;
    img {
        object-fit: cover;
        border-radius: 50%;
        height: 100%;
        width: auto;
        border: 2px solid #00ffff;
    }
    .update-avatar {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transition: all 0.3s ease-in-out;
        width: 100%;
        height: 100%;
        border: 2px solid #00b1ff;
        border-radius: 50%;
        background: rgba($color: #000000, $alpha: 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            margin: 0 auto;
            width: 90px;
            display: flex;
            justify-content: center;
            height: 30px;
            align-items: center;
            font-size: 18px;
            font-family: 'HHBI';
            color: #00ffff;
            background: #00172a;
            border: 1px solid #00ffff;
            border-radius: 10px;
            padding: 5px;
            &:hover {
                background: #00ffff;
                color: #00172a;
            }
        }
    }
    .camera {
        position: absolute;
        bottom: 0;
        right: 0;
        img {
            border: none;
        }
    }
}
.avatar-setting {
    margin-bottom: 5rem;
    @media (max-width: 768px) {
        margin-bottom: 3.5rem;
    }
    .avatar-list-col {
        background: linear-gradient(to right, #18d4e2, #a506f9);
        padding: 1.25rem 2rem;
        position: relative;
        border-radius: 8px;
        @media (max-width: 768px) {
            padding: 1.25rem;
        }
        &::before {
            content: '';
            position: absolute;
            inset: 1px;
            z-index: 0;
            border-radius: 8px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                rgba(53, 63, 72, 1);
        }
        .avatar-list-fr {
            width: 100%;
            margin-left: 0;
            position: relative;
            .dot {
                width: 10px;
                height: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
            .dot-1 {
                position: absolute;
                top: 0;
                right: 0;
            }
            .dot-2 {
                position: absolute;
                bottom: 0;
                left: 0;
            }
            .activeAvatar {
                border-radius: 100%;
                border: 2px solid #00ffff;
            }
            .avatar-item {
                max-width: 85px;
                width: 100%;
                margin: 0 auto;
                padding: 0;
                outline: none;

                border-radius: 100%;
                &:focus {
                    outline: none;
                    border: none;
                }
                img {
                    border-radius: 100%;
                    width: 100%;
                    height: auto;
                    object-fit: scale-down;
                }
                &:focus {
                    border: 2px solid #00ffff;
                }
            }
        }
    }
    .no-avatar-fr {
        width: 169px;
        height: 169px;
        border-radius: 100%;
        background: linear-gradient(#18d4e2, #a506f9);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -70%;
            width: 120px;
            height: 1px;
            background: #00ffff;
        }
        &::before {
            content: '';
            position: absolute;
            inset: 1px;
            z-index: 0;
            border-radius: 100%;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                rgba(53, 63, 72, 1);
        }
        h4 {
            position: relative;
            z-index: 5;
            color: #f8f8f8;
            margin: 0;
        }
    }
    .avatar {
        position: relative;
        max-width: 169px;
        width: 100%;
        border-radius: 100%;
        background: linear-gradient(#18d4e2, #a506f9);
        @media (max-width: 768px) {
            max-width: 145px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -70%;
            width: 120px;
            height: 1px;
            background: #00ffff;
        }
        &::before {
            content: '';
            position: absolute;
            inset: 1px;
            z-index: 0;
            border-radius: 100%;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                rgba(53, 63, 72, 1);
        }
        img {
            border-radius: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            width: 98%;
            height: auto;
            object-fit: scale-down;
        }
    }
}
.edit-icon {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background: rgba(42, 50, 58, 0.7);
    img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
    }
}
.bede-btn {
    max-width: 175px;
    height: 50px;
}
.container-fluid {
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
}
</style>

<style lang="scss">
.custom-control-label {
    color: #ffff;
    font-size: 1.2em;
}
#change-email {
    .input-group {
        margin: 15px auto;
        width: 75%;
        min-width: 200px;
    }
}
.connect-icon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    padding: 5px;
    background: rgba(85, 85, 85, 0.2);
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: scale-down;
    }
}
#change-password {
    .form-group {
        margin: 15px auto;
        a {
            color: #00ffff;
        }
        label {
            font-weight: 600;
            color: #ffff;
            font-size: 16px;
            margin-top: 15px;
        }
        .input-group-text {
            background: transparent;
            padding: 0;
            margin: 10px auto;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
        }
        input {
            height: 40px;
            font-size: clamp(15px, 1vw, 16.2px);
            font-weight: 600;
            margin-top: 10px;
        }
    }
}
.group-input {
    .edit-mail-btn {
        width: 22px !important;
        height: 22px !important;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }
    .label {
        color: #fff;
        width: 100px;
        text-align: center;
        font-weight: 600;
    }
}
.text-showcase {
    color: #fff;
    text-align: center;
    font-size: 1.2em;
    .key-text {
        color: #00ffff;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 25px;
    }
}
.note-warning {
    font-size: 0.9em;
    margin: 15px auto 10px;
    color: #fff;
    text-align: center;
    font-style: italic;
}
.confirm-form {
    .text {
        color: #fff;
        text-align: center;
        font-size: 1.2em;
        .key-text {
            color: #00ffff;
            font-weight: 600;
            margin-top: 10px;
            margin-bottom: 25px;
        }
    }
    .note {
        font-size: 0.8em;
        margin: 25px auto 15px;
    }
    .confirm-form-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin: 15px 0;
        .button-confirm {
            margin: 0;
            height: 40px;
        }
        input {
            margin: 0;
            height: 40px;
            max-width: 50%;
        }
    }
}
.setting-tab {
    padding: 0;
    .title {
        color: #f8f8f8;
        font-family: 'Poppins';
        font-size: clamp(1.2em, 4vw, 1.8em);
        letter-spacing: 0.6px;
        width: max-content;
        font-weight: 900;
    }
    .subtitle {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.2em, 4vw, 1.5em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 0 0 15px;
        text-transform: uppercase;
    }
    .setup-account {
        background: {
            image: url(~@/assets/images/mockup/account/setting-box.png);
            size: 100% 100%;
            repeat: no-repeat;
        }
        width: 100%;
        max-width: 500px;
        min-height: 200px;
        margin: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        .text {
            color: #fff;
            text-align: center;
            font-size: 1.2em;
        }
        button {
            background: rgba(0, 255, 255, 0.15);
            border-radius: 267px;
            width: auto;
            padding: 5px 15px;
            height: 45px;
            border: none;
            font-weight: 600;
            text-transform: capitalize;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            display: flex;
            align-items: center;

            /* cyan */

            color: #00ffff;
        }
    }
    .change-info-box {
        .save-btn {
            max-width: 269px;
            width: 100%;
            background: rgba(0, 255, 255, 0.2);
            border: none;
            border-radius: 19.5px;
            &:focus,
            :active {
                outline: none;
            }
            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #00ffff;
                margin-bottom: 0;
            }
        }
        .label {
            color: #fff;
            text-transform: capitalize;
            margin-bottom: 5px;
        }
        .general-setting {
            border-bottom: 1px solid #888888;
        }
        .group-input {
            display: flex;
            justify-content: center;
            align-items: center;

            input {
                margin: 0 15px 0 0;
                height: 40px;
                background: transparent;
                border: none;
                color: #fff;
                font-weight: 600;
                border-radius: 0;
            }
            button {
                max-width: 180px;
                width: 100%;
                flex: 1 1 auto;
                margin: 0 0 0 15px;
                padding: 0;
                height: 40px;
                font-size: 1.2em;
            }
            @media (max-width: 575px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                input,
                button {
                    flex: unset;
                }
                input {
                    width: 100%;
                }
                button {
                    margin: 10px 0 0;
                }
            }
        }
        .change-password {
            justify-content: flex-start;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .des-fr {
                width: 100%;
                justify-content: space-between;
                align-items: center;
                display: flex;
                p {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #888888;
                    margin-bottom: 0;
                }
                .change-password-btn {
                    width: 18px;
                    height: 18px;
                    background: transparent;
                    border: none;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: scale-down;
                    }
                }
            }

            .group-input {
                margin-top: 0;
            }
            .label {
                margin-bottom: 5px;
            }
            button {
                width: auto;
                flex: unset;
                padding: 5px 25px;
                margin: 0;
            }
        }
    }
    .button-confirm {
        background: rgba(0, 255, 255, 0.2);
        border-radius: 0;
        width: auto;
        padding: 5px 5px;
        height: 45px;
        color: #000;
        font-weight: 600;
        text-transform: capitalize;
        min-width: 140px;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .form-group {
        span {
            text-align: center;
        }
    }
}
.invalid-feedback {
    display: block;
    span {
        margin: auto;
        display: block;
        width: 100%;
        text-align: center;
        &:first-child {
            margin-top: -10px;
        }
    }
}
</style>
