<template>
    <div id="inventoryAccount" class="px-3">
        <div class="inventory-header">
            <div class="container d-flex">
                <div
                    class="inventory-header-tabs-list col-sm-10 col-12 d-flex p-0"
                >
                    <button
                        @click="selectTab(1)"
                        :class="[currentTabs === 1 ? 'isActiveTab' : '']"
                        class="inventory-header-tab"
                    >
                        <p>Hero</p>
                    </button>
                    <button
                        @click="selectTab(2)"
                        :class="[currentTabs === 2 ? 'isActiveTab' : '']"
                        class="inventory-header-tab"
                    >
                        <p>Land</p>
                    </button>
                    <button
                        @click="selectTab(3)"
                        :class="[currentTabs === 3 ? 'isActiveTab' : '']"
                        class="inventory-header-tab"
                    >
                        <p>Box</p>
                    </button>
                    <button
                        @click="selectTab(4)"
                        :class="[currentTabs === 4 ? 'isActiveTab' : '']"
                        class="inventory-header-tab"
                    >
                        <p>Item</p>
                    </button>
                </div>
                <div
                    v-if="currentTabs === 1 || currentTabs === 3"
                    class="inventory-header-tabs-btn col-2 d-none d-sm-flex pb-1 justify-content-end pr-sm-5"
                >
                    <button @click="resetItemSearch(currentTabs)">
                        <img
                            src="~@/assets/images/icons/refesh-icon.svg"
                            alt=""
                        />
                    </button>
                </div>
            </div>
        </div>
        <div class="inventory-body">
            <TabHero
                :class="currentTabs !== 1 ? 'd-none' : 'd-block'"
                ref="tabHeroes"
            />
            <TabLand :class="currentTabs !== 2 ? 'd-none' : 'd-block'" />
            <TabBox :class="currentTabs !== 3 ? 'd-none' : 'd-block'" />
            <TabItem :class="currentTabs !== 4 ? 'd-none' : 'd-block'" />
        </div>
    </div>
</template>

<script>
import TabHero from '../inventory/newHero.vue';
import TabLand from '../inventory/newLand.vue';
import TabBox from '../inventory/newBox.vue';
import TabItem from '../inventory/newItem.vue';

// import TabLand from "../inventory/land/Land.vue";

export default {
    components: { TabHero, TabBox, TabItem, TabLand },
    data() {
        return {
            currentTabs: 1,
            isResetFindHero: false,
            isResetFindBox: false,
        };
    },
    methods: {
        selectTab(id) {
            this.currentTabs = id;
        },
        resetItemSearch(id) {
            if (id === 1) {
                this.$refs.tabHeroes.resetFilter();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
#inventoryAccount {
    // max-width: 1400px;
    font-family: 'Poppins';
    .inventory-header {
        .inventory-header-tabs-list {
            background-image: url('~@/assets/images/background/clone-bg.png');
            background-size: cover;
            background-repeat: no-repeat;
            height: 66px;
            .inventory-header-tab {
                width: 25%;
                padding: 0;
                box-shadow: unset;
                outline: none;
                p {
                    width: 100%;
                    margin: 0;
                    border-right: 1px solid #00ffff;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    color: #f8f8f8;
                }
            }
            .isActiveTab {
                background: rgba(0, 255, 255, 0.15);
                backdrop-filter: blur(2px);
            }
            .inventory-header-tab:last-child p {
                border: none;
            }
        }
        .inventory-header-tabs-btn {
            display: flex;
            align-items: center;
            height: 66px;
            button {
                background: none;
                border-radius: 50%;
                border: none;
                outline: none;
                box-shadow: unset;
                width: 44px;
                height: 44px;
                margin-left: 10px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>
