<script>
import { mapGetters, mapMutations } from 'vuex';
import { filter } from 'lodash';
import { MetaMask } from '@/components/auth';

import { StartEffect, StopEffect } from '@/plugins/effect';

const axios = require('@/store/axios.config').default;

export default {
    components: { MetaMask },
    data() {
        return {
            infoHeight: '10px',
            resizeObserver: null,
            quantity: 1,
            Rare: {
                origin: [],
                chromas: [],
                prestige: [],
            },
            out: [13, 14, 15, 19, 20, 21],
            approve: false,
        };
    },
    computed: {
        ...mapGetters({
            ListBoxStore: 'shop/ListBoxStore',
            isLogin: 'auth/loggedIn',
        }),
        Detail() {
            return filter(this.ListBoxStore, (item) => {
                return item.Name.toLowerCase() === this.$route.params.id;
            })[0];
        },
        // Rare() {
        //     return RareOfBox(this.Detail.id)[0];
        // },
    },
    watch: {
        Detail: {
            handler() {
                if (window.innerWidth >= 992 && this.Detail) {
                    this.resizeObserver = new ResizeObserver(this.onResize);
                    this.resizeObserver.observe(this.$refs.title);
                    this.onResize();
                    document.title = `HeroBook | ${this.Detail.Name} Box`;
                }
            },
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        Start() {
            StartEffect();
        },
        Stop() {
            StopEffect();
        },
        onResize() {
            if (window.innerWidth >= 992) {
                this.infoHeight = `${this.$refs.title.clientHeight + 30}px`;
            }
        },
        onBuy() {},
        upDown(num) {
            if (num <= 1) {
                this.quantity = 1;
                return true;
            }
            if (num >= 999) {
                this.quantity = 999;
                return true;
            }
            this.quantity = num;
            return true;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        async getDetail() {
            this.onLoad();
            const boxdetail = await axios.get(
                `api/box/shop-box/${this.capitalizeFirstLetter(
                    this.$route.params.id,
                )}`,
            );
            const {
                Chromas_Common,
                Chromas_Epic,
                Chromas_Legend,
                Chromas_Rare,
                Origin_Common,
                Origin_Epic,
                Origin_Legend,
                Origin_Rare,
                Prestige_Common,
                Prestige_Epic,
                Prestige_Legend,
                Prestige_Rare,
            } = boxdetail.SCRIPT_OPEN_BOX;

            const origin = [
                Origin_Common,
                Origin_Rare,
                Origin_Epic,
                Origin_Legend,
            ];
            const chromas = [
                Chromas_Common,
                Chromas_Rare,
                Chromas_Epic,
                Chromas_Legend,
            ];
            const prestige = [
                Prestige_Common,
                Prestige_Rare,
                Prestige_Epic,
                Prestige_Legend,
            ];
            this.Rare = {
                origin,
                chromas,
                prestige,
            };
            this.outLoad();
        },
        async checkApproval() {
            if (
                this.$store.state.wallet.addr &&
                this.$store.state.contract.marketplace.hero.addr
            ) {
                this.approve =
                    +(await this.$store.state.contract.bep_20.hbg.cont.methods
                        .allowance(
                            this.$store.state.wallet.addr,
                            this.$store.state.contract.marketplace.hero.addr,
                        )
                        .call());
            } else {
                await this.sleep(2000);
                this.checkApproval();
            }
        },
        async Approve() {
            const { hbg } = this.$store.state.contract.bep_20;
            const { web3 } = this.$store.state.contract;
            const { hero } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            this.onLoad();
            const txObj = hbg.cont.methods
                .approve(
                    hero.addr,
                    (100000000000 * 10 ** 18).toLocaleString('fullwide', {
                        useGrouping: false,
                    }),
                )
                .encodeABI();
            let gas = 0;
            try {
                // Calculate gas
                gas = await hbg.cont.methods
                    .approve(
                        hero.addr,
                        (100000000000 * 10 ** 18).toLocaleString(
                            'fullwide',
                            {
                                useGrouping: false,
                            },
                        ),
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: 5000000,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasPrice = await web3.provider.eth.getGasPrice();
            let res;
            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hbg.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txData.status) {
                            this.$toastr.s('Approve success !', 'Successfully');
                            await this.checkApproval();
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        // eslint-disable-next-line consistent-return
        async onBuyBox() {
            this.onLoad();
            const { hero } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { addr } = this.$store.state.wallet;
            if (this.quantity > 300) {
                this.$toastr.e('You can buy maximum 300 boxes', 'Error');
                this.outLoad();
                return;
            }
            if (this.quantity < 1) {
                this.$toastr.e('You can buy min 1 boxes', 'Error');
                this.outLoad();
                return;
            }
            const txObj = hero.cont.methods
                .buyBoxMulti(this.Detail.Type, this.quantity)
                .encodeABI();
            let gas = 0;
            try {
                gas = await hero.cont.methods
                    .buyBoxMulti(this.Detail.Type, this.quantity)
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: parseInt(1000000 * this.quantity, 10),
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: hero.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                this.$bvModal.hide('confirm-buy');
                // Kết quả transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txBuyData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txBuyData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txBuyData.status) {
                            // this.$toastr.s(
                            //     `Buy ${this.Detail.Name} Box Success`,
                            //     'Successfully!',
                            // );
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.$store.commit('wallet/updateWallet');
                            this.sendTransaction(txBuyData);
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        sendTransaction(hash) {
            this.$store.dispatch('shop/req_postBuyBox', {
                transactionHash: hash.transactionHash,
            });
            this.$bvModal.show('boxReceiver2');
            this.Start();
        },
        resetBuy() {
            this.Stop();
            this.quantity = 1;
        },
    },
    async mounted() {
        this.$store.dispatch('shop/req_getBoxStore');
        this.checkApproval();
        this.getDetail();
        this.onLoad();
        setTimeout(() => {
            if (
                this.$refs.title &&
                window.innerWidth >= 992 &&
                this.Detail &&
                this.Detail.Name
            ) {
                this.resizeObserver = new ResizeObserver(this.onResize);
                this.resizeObserver.observe(this.$refs.title);
                this.onResize();
            }
            this.outLoad();
        }, 2000);
    },

    beforeUnmount() {
        this.resizeObserver.unobserve(this.$refs.title);
    },
};
</script>
<template>
    <b-container class="box-detail">
        <b-col cols="12" class="">
            <router-link :to="{ name: 'Shop' }" class="back-to-shop">
                <div class="icons">
                    <img src="~@/assets/images/icons/back.png" alt="" />
                </div>
                <div class="text">Back</div>
            </router-link>
        </b-col>
        <b-row v-if="Detail">
            <b-col cols="12" class="p-0">
                <b-row class="p-0 m-0">
                    <b-col cols="12">
                        <div class="box-name mobile">
                            <div
                                class="before"
                                :class="Detail.Name.toLowerCase()"
                            >
                                {{ Detail.Name }}
                            </div>
                            <div
                                class="after"
                                :class="Detail.Name.toLowerCase()"
                            >
                                {{ Detail.Name }}
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="5" lg="5" xl="4" class="p-0 box-left">
                        <div
                            class="section-buy-box"
                            :style="`padding-top:${infoHeight}`"
                        >
                            <div class="box-img">
                                <img
                                    :src="
                                        require(`@/assets/images/mockup/shop/${Detail.Name.toLowerCase()}.png`)
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="action-buy">
                                <div class="box-price">
                                    <div class="hbg">
                                        {{
                                            TruncateToDecimals2(
                                                Detail.Price * quantity,
                                                '',
                                                0,
                                            )
                                        }}
                                        HBG
                                    </div>
                                    <div class="usd">
                                        <!-- ({{ Detail.usd }} USD) -->
                                    </div>
                                </div>
                                <div class="box-buy-quantity">
                                    <b-button @click="upDown(quantity - 1)">
                                        <div class="text">
                                            <span>-</span>
                                        </div>
                                    </b-button>
                                    <!-- <span>{{  }}</span> -->
                                    <b-form-input
                                        v-model.number="quantity"
                                        type="number"
                                    ></b-form-input>
                                    <b-button @click="upDown(quantity + 1)">
                                        <div class="text">
                                            <span>+</span>
                                        </div>
                                    </b-button>
                                </div>

                                <MetaMask
                                    class="my-2"
                                    :isBuy="true"
                                    v-if="!isLogin"
                                />

                                <div class="box-buy-button" v-else-if="approve">
                                    <b-button
                                        @click="$bvModal.show('confirm-buy')"
                                    >
                                        purchase
                                    </b-button>
                                </div>
                                <div class="box-buy-button" v-else>
                                    <b-button @click="Approve">
                                        approve
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="7" lg="7" xl="8" class="box-right">
                        <b-row>
                            <b-col cols="12" ref="title">
                                <div class="box-name desktop title">
                                    <div
                                        class="before"
                                        :class="Detail.Name.toLowerCase()"
                                    >
                                        {{ Detail.Name }}
                                    </div>
                                    <div
                                        class="after"
                                        :class="Detail.Name.toLowerCase()"
                                    >
                                        {{ Detail.Name }}
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="12" class="p-0">
                                <div class="table-detail">
                                    <div class="title">DROP RATE DETAIL</div>
                                    <div class="content">
                                        <div class="header-detail d-flex">
                                            <div class="item">Common</div>
                                            <div class="item">Rare</div>
                                            <div class="item">Epic</div>
                                            <div class="item">Legend</div>
                                        </div>
                                        <div class="content-detail">
                                            <div class="origin d-flex">
                                                <div
                                                    class="item"
                                                    v-for="(
                                                        percent, idx
                                                    ) in Rare['origin']"
                                                    :key="`origin-${percent}-${idx}`"
                                                >
                                                    <div class="name">
                                                        origin
                                                    </div>
                                                    <div class="value">
                                                        ({{
                                                            (
                                                                percent * 100
                                                            ).toFixed(3)
                                                        }}%)
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="chromas d-flex">
                                                <div
                                                    class="item"
                                                    v-for="(
                                                        percent, idx
                                                    ) in Rare['chromas']"
                                                    :key="`chromas-${percent}-${idx}`"
                                                >
                                                    <div class="name">
                                                        chromas
                                                    </div>
                                                    <div class="value">
                                                        ({{
                                                            (
                                                                percent * 100
                                                            ).toFixed(3)
                                                        }}%)
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="prestige d-flex">
                                                <div
                                                    class="item"
                                                    v-for="(
                                                        percent, idx
                                                    ) in Rare['prestige']"
                                                    :key="`prestige-${percent}-${idx}`"
                                                >
                                                    <div class="name">
                                                        prestige
                                                    </div>
                                                    <div class="value">
                                                        ({{
                                                            (
                                                                percent * 100
                                                            ).toFixed(3)
                                                        }}%)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="12" class="p-0">
                                <div
                                    class="title-available"
                                    ref="title-available"
                                >
                                    Available Heros:
                                    <span>{{ 60 - out.length }}</span>
                                </div>
                                <div class="item-on-box">
                                    <template v-for="i in 60 - out.length">
                                        <div
                                            v-if="!out.includes(i)"
                                            class="item-hero"
                                            :key="`hero-${i}`"
                                        >
                                            <img
                                                :src="`https://images-storage-bucket.s3.ap-southeast-1.amazonaws.com/herobook/avatar/${i}.png`"
                                                alt=""
                                            />
                                        </div>
                                    </template>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-modal id="confirm-buy" title="Confirm Buy Box" hide-footer>
            <div>
                <p class="mb-0 text-confirm">
                    Do you want to use
                    <br />
                    <span class="text-main">
                        {{ TruncateToDecimals2(Detail.Price * quantity) }} HBG
                    </span>
                    to buy
                    <span class="text-main"> {{ quantity }} Box </span>
                    ?
                </p>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-5 mb-0 button-confirm"
                    variant="primary"
                    @click="onBuyBox"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="boxReceiver2"
            @hidden="resetBuy"
            title="Box Receiver"
            hide-footer
        >
            <div class="box">
                <div class="box-img">
                    <img
                        :src="
                            require(`@/assets/images/mockup/shop/${Detail.Name.toLowerCase()}.png`)
                        "
                        alt=""
                    />
                </div>
                <div class="box-name" :class="Detail.Name.toLowerCase()">
                    {{ Detail.Name }} Box
                </div>
                <div class="box-available">
                    <div>You Have Receive:</div>
                    <div>
                        <span> {{ quantity }} Box </span>
                    </div>
                </div>
            </div>
            <div class="form-group d-flex justify-content-center">
                <b-button
                    class="mx-auto mt-3 button-confirm"
                    variant="primary"
                    @click="$bvModal.hide('boxReceiver2')"
                >
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </b-container>
</template>
<style lang="scss">
$box-max-width: 375px;
$img-max-width: calc(375px * 0.9);
$price-max-width: calc(375px * 0.8);
$price-max-height: calc(375px / 10 * 1.3);
$liner-gradient-color: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
$button-max-width: calc(375px * 0.6);
$button-max-height: calc(375px / 10 * 1.5);
#boxReceiver2___BV_modal_outer_ {
    z-index: unset !important;
}
#boxReceiver2 {
    .box {
        max-width: 300px;
        width: 100%;
        min-height: 350px;
        margin: 5px auto;
        // background: {
        //     image: url(~@/assets/images/mockup/account/body-box.png);
        //     repeat: no-repeat;
        //     size: 100% 100%;
        // }
        .box-img {
            text-align: center;
            position: relative;
            max-width: 300px;
            margin: 10px auto;
            img {
                width: 100%;
                height: auto;
                position: relative;
                z-index: 10;
                // max-height: 210px;
                // object-fit: cover;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                background: linear-gradient(0deg, #e2dfd3 0%, #f6cb0a00 100%);
                z-index: 0;
                animation: light 2s infinite linear;
            }
            @media (max-width: 767px) {
                max-width: 250px;
            }
        }
        .box-name {
            font-size: 1.5em;
            text-align: center;
            margin: 10px auto;
            font-family: 'HHBI';
            text-transform: uppercase;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.metal {
                color: #fff;
                text-shadow: 1px 0px 9px #ffff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                @media (max-width: 374px) {
                    font-size: 1.7em;
                }
            }
        }
        .box-available {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0px 10px;
            text-align: center;
            color: #fff;
            span {
                color: #00ffff;
                font-weight: 600;
            }
        }
        .box-open {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px auto;
            background: linear-gradient(90deg, #00ffa9 0%, #00b1ff 100%);
            border-radius: 7px;
            width: 120px;
            height: 40px;
            padding: 5px;
            button {
                color: #000;
                border-radius: 5px;
                font-weight: 600;
                border: 1.5px solid #fff;
                padding: 0;
                height: 100%;
                width: 100%;
                background: transparent;
                box-shadow: 0px 0px 5px #00ffff;
                text-transform: uppercase;
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            max-width: 210px;
        }
    }
}
.text-confirm {
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    font-family: 'HHBI';
    margin: 20px 0;
}
.back-to-shop {
    margin: 0px 0 20px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: flex-start;
    .icons {
        img {
            width: 40px;
            height: auto;
        }
    }
    .text {
        font-family: 'HHBI';
        color: #00ffff;
        font-size: 27px;
        margin-left: 15px;
    }
    @media (max-width: 575px) {
        .text {
            font-size: 20px;
        }
        margin-bottom: 30px;
    }
}

.box-detail {
    .box-name {
        position: relative;
        &.title {
            @media (min-width: 992px) {
                min-height: 140px;
            }
        }

        .before {
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 0.5px;
            font-family: 'HHBI';

            font-size: clamp(100px, 6vw, 120px);
            line-height: 120%;
            text-transform: uppercase;
            &.diamond {
                -webkit-text-stroke-color: #ff00ff;
                opacity: 1;
                -webkit-text-stroke-width: 1px;
            }
            &.gold {
                -webkit-text-stroke-color: #f3ff00;
                opacity: 0.6;
            }
            &.silver {
                -webkit-text-stroke-color: #98d7e0;
                opacity: 0.6;
            }
            &.platinum {
                -webkit-text-stroke-color: #4effff;
                opacity: 0.4;
            }
        }
        .after {
            font-size: clamp(60px, 4vw, 80px);
            font-family: 'HHBI';
            position: absolute;
            bottom: 0;
            left: 15px;
            text-transform: uppercase;
            &.diamond {
                color: #ff00ff;
                text-shadow: 1px 0px 9px #ff00ff;
            }
            &.gold {
                color: #f3ff00;
                text-shadow: 1px 0px 9px #f3ff00;
            }
            &.silver {
                color: #98d7e0;
                text-shadow: 1px 0px 9px #98d7e0;
            }
            &.platinum {
                color: #4effff;
                text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
            }
        }
        @media (max-width: 991px) {
            margin-bottom: 10px;
            .mobile {
                display: block;
            }
            .before {
                text-align: center;
                margin: auto;
            }
            .after {
                left: 0;
                right: 0;
                margin: 0 auto;
                width: max-content;
            }
        }
        @media (max-width: 991px) {
            .before {
                font-size: clamp(70px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(40px, 3vw, 75px);
            }
        }
        @media (max-width: 575px) {
            .before {
                font-size: clamp(55px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(30px, 3vw, 65px);
            }
        }
        @media (max-width: 424px) {
            .before {
                font-size: clamp(45px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                font-size: clamp(25px, 3vw, 65px);
            }
        }
        @media (max-width: 374px) {
            .before {
                font-size: clamp(40px, 5.5vw, 120px);
                opacity: 0.3;
            }
            .after {
                bottom: -10px;
                font-size: clamp(30px, 3vw, 65px);
            }
        }
    }
    .section-buy-box {
        padding-top: 80px;
        max-width: $box-max-width;
        width: 100%;
        [class|='box'] {
            &[class$='-img'] {
                text-align: center;
                position: relative;
                max-width: $img-max-width;
                margin: auto;
                img {
                    width: 100%;
                    width: 100%;
                    height: auto;
                    position: relative;
                    z-index: 10;
                    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    background: linear-gradient(
                        0deg,
                        #e2dfd3 0%,
                        #f6cb0a00 100%
                    );
                    z-index: 0;
                    animation: light 2s infinite linear;
                }
                @media (max-width: 767px) {
                    max-width: 250px;
                }
            }

            &[class$='-price'] {
                margin: 30px auto;
                width: $price-max-width;
                min-height: $price-max-height;
                background: {
                    image: url('~@/assets/images/button/price.png');
                    size: 100% 100%;
                    repeat: no-repeat;
                }
                display: flex;
                justify-content: center;
                align-items: baseline;
                padding: 5px 0;
                .hbg {
                    font-size: 2em;
                    text-align: center;
                    font-family: 'HHBI';
                    color: #4effff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
                .usd {
                    font-size: 1em;
                    text-align: center;
                    font-family: 'HHBI';
                    margin-left: 5px;
                    color: #ffffff;
                    text-shadow: 1px 0px 9px rgba(0, 252, 245, 1);
                }
                @media (max-width: 767px) {
                    max-width: 200px;
                    .hbg {
                        font-size: 20px;
                    }
                }
                @media (max-width: 575px) {
                    max-width: 250px;
                }
            }
            &[class$='-buy-quantity'] {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: $button-max-width;
                margin: 40px auto;
                input {
                    outline: none;
                    border: 1px solid #00ffff;
                    background: linear-gradient(
                        180deg,
                        #00172a 0%,
                        #00000b 100%
                    );
                    height: 50px;
                    margin: 0 5px;
                    border-radius: 0;
                    color: #fff;
                    font-family: 'HHBI';
                    font-size: 2em;
                    text-align: center;
                }
                input[type='number'] {
                    -moz-appearance: textfield;
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    /* display: none; <- Crashes Chrome on hover */
                    -webkit-appearance: none;
                    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                }
                button {
                    background: $liner-gradient-color;
                    border-radius: 0px;
                    padding: 2px;
                    width: 50px;
                    height: 50px;
                    div {
                        background: #000000;
                        width: 100%;
                        height: 100%;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        padding: 5px;
                        span {
                            font-family: 'HHBI';
                            font-size: 2.5em;
                            margin-left: -5px;
                        }
                    }
                }
                span {
                    width: 100px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-family: 'HHBI';
                    font-size: 2.5em;
                }
                @media (max-width: 767px) {
                    max-width: 200px;
                }
            }
            &[class$='-buy-button'] {
                button {
                    background: $liner-gradient-color;
                    width: $button-max-width;
                    padding: 0;
                    height: $button-max-height;
                    padding: 0;
                    color: #000000;
                    font-family: 'Helvetica';
                    font-size: 1.7em;
                    font-weight: 900;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 25px auto;
                    @media (max-width: 767px) {
                        max-width: 200px;
                        margin: 15px auto;
                    }
                    @media (max-width: 575px) {
                        font-size: 1.6em;
                    }
                }
            }
        }
        @media (max-width: 991px) {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: unset;
            .box-img {
                flex: 0 0 50%;
            }
            .action-buy {
                flex: 0 0 50%;
            }
        }
        @media (max-width: 575px) {
            flex-wrap: wrap;
            .box-img {
                flex: 0 0 100%;
            }
            .action-buy {
                flex: 0 0 100%;
            }
        }
    }
    .table-detail {
        background: #00ffff;
        font-family: 'HHBI';
        margin-top: 30px;
        .title {
            height: 60px;
            color: #000000;
            font-weight: 800;
            font-size: 1.8em;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 575px) {
                font-size: 1.4em;
            }
        }
        .item {
            flex: 1;
            border: 1px solid #00ffff;
            background: linear-gradient(180deg, #18274a 0%, #001326 100%);
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            .name {
                color: #fff;
                text-transform: capitalize;
            }
            .value {
                color: #00ffff;
                margin-left: 3px;
                letter-spacing: 1px;
            }
            @media (max-width: 767px) {
                flex-direction: column;
            }
            @media (max-width: 575px) {
                min-width: 125px;
            }
        }
        .header-detail {
            .item {
                color: #00ffff;
                font-size: 1.2em;
            }
        }
        @media (max-width: 575px) {
            .content {
                overflow-y: hidden;
                overflow-x: auto;
            }
        }
    }
    .title-available {
        color: #4effff;
        text-shadow: 1px 0px 5px rgba(0, 252, 245, 1);
        font-size: 2.3em;
        font-family: 'HHBI';
        margin-top: 50px;
        text-transform: uppercase;
        span {
            color: #fff;
        }
        @media (max-width: 991px) {
            margin-top: 20px;
        }
        @media (max-width: 575px) {
            font-size: 1.8em;
        }
    }
    .item-on-box {
        border: 1px solid #00ffff;
        background: linear-gradient(180deg, #18274a 0%, #001326 100%);
        width: 100%;
        // min-height: 300px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 15px;
        max-height: 400px;
        overflow: {
            x: hidden;
            y: auto;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            // background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 10px;
            background-color: #18274a;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 1px;
            background-color: #00ffff;
        }
        .item-hero {
            flex: 0 0 calc(100% / 6);
            width: 90px;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            // flex-basis: 16.67%;
            img {
                width: 100%;
                height: 100%;
            }
        }
        @media (max-width: 991px) {
            padding: 5px;
            .item-hero {
                flex: 0 0 100px;
            }
            justify-content: center;
        }
        @media (max-width: 575px) {
            padding: 5px;
            .item-hero {
                flex: 0 0 90px;
                padding: 10px;
            }
        }
    }
    .mobile {
        display: none;
    }
    @media (max-width: 991px) {
        .desktop {
            display: none !important;
        }
        .mobile {
            display: flex;
        }
    }
}
</style>
