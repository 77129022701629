<script>
import Delaunay from '@/plugins/delaunay';
import gsap from 'gsap';

const axios = require('@/store/axios.config').default;

export default {
    props: {
        hero: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            priceHero: {
                price: 0,
                quantityDayPayment: 0,
                paidPerDay: 0,
            },
            container: null,
            image: null,
            loaded: 0,
            vertices: [],
            indices: null,
            TWO_PI: Math.PI * 2,
            clickPosition: [128 * 0.5, 136 * 0.5],
            fragments: [],
            urls: null,
            tween: null,
        };
    },
    methods: {
        onAction() {
            this.$toastr.w('', 'Coming Soon!');
        },
        clearPick() {
            this.$emit('clearPick', true);
        },
        onBurn() {
            this.$emit('onPenConfirm', true);
        },
        async getPricePredict(id) {
            const data = await axios.post('/api/burn/predict', {
                tokenId: id,
            });
            if (data) {
                this.priceHero = data;
            }
        },
        onLoadComponent() {
            const self = this;
           this.tween = gsap.to(self.container, { perspective: 500 });

            self.image = new Image();
            self.image.onLoad = () => {
                self.loaded += 1;
                if (self.loaded === 1) {
                    this.imagesLoaded();
                    self.image.src = self.urls;
                }
            };
            self.image.src = self.urls;
        },
        imagesLoaded() {
            this.placeImage(false);
            this.triangulate();
            // this.shatter();
        },
        placeImage(transitionIn) {
            this.container.appendChild(this.image);

            if (transitionIn !== false) {
                gsap.fromTo(
                    this.image,
                    0.75,
                    { y: -1000 },
                    { y: 0, ease:  "power1.out" },
                );
            }
        },
        triangulate() {
            const self = this;

            const rings = [
                { r: 50, c: 12 },
                { r: 150, c: 12 },
                { r: 300, c: 12 },
                { r: 1200, c: 12 }, // very large in case of corner clicks
            ];
            let x;
            let y;
            const centerX = self.clickPosition[0];
            const centerY = self.clickPosition[1];

            this.vertices.push([centerX, centerY]);

            rings.forEach((ring) => {
                const radius = ring.r;
                const count = ring.c;
                const variance = radius * 0.25;

                for (let i = 0; i < count; i += 1) {
                    x =
                        Math.cos((i / count) * self.TWO_PI) * radius +
                        centerX +
                        self.randomRange(-variance, variance);
                    y =
                        Math.sin((i / count) * self.TWO_PI) * radius +
                        centerY +
                        self.randomRange(-variance, variance);
                    self.vertices.push([x, y]);
                }
            });

            self.vertices.forEach((v) => {
                // eslint-disable-next-line no-param-reassign
                v[0] = self.clamp(v[0], 0, 200);
                // eslint-disable-next-line no-param-reassign
                v[1] = self.clamp(v[1], 0, 245);
            });

            self.indices = Delaunay.triangulate(self.vertices);
        },
        shatter() {
            let p0 = null;
            let p1 = null;
            let p2 = null;
            let fragment = null;
            const self = this;

            const tl0 = new gsap.timeline({
                onComplete: self.shatterCompleteHandler,
            });
            function Fragment(v0, v1, v2) {
                this.v0 = v0;
                this.v1 = v1;
                this.v2 = v2;

                this.computeBoundingBox();
                this.computeCentroid();
                this.createCanvas();
                this.clip();
            }
            Fragment.prototype = {
                computeBoundingBox() {
                    const xMin = Math.min(this.v0[0], this.v1[0], this.v2[0]);
                    const xMax = Math.max(this.v0[0], this.v1[0], this.v2[0]);
                    const yMin = Math.min(this.v0[1], this.v1[1], this.v2[1]);
                    const yMax = Math.max(this.v0[1], this.v1[1], this.v2[1]);

                    this.box = {
                        x: xMin,
                        y: yMin,
                        w: xMax - xMin,
                        h: yMax - yMin,
                    };
                },
                computeCentroid() {
                    const x = (this.v0[0] + this.v1[0] + this.v2[0]) / 3;
                    const y = (this.v0[1] + this.v1[1] + this.v2[1]) / 3;

                    this.centroid = [x, y];
                },
                createCanvas() {
                    this.canvas = document.createElement('canvas');
                    this.canvas.width = this.box.w;
                    this.canvas.height = this.box.h;
                    this.canvas.style.width = `${this.box.w}px`;
                    this.canvas.style.height = `${this.box.h}px`;
                    this.canvas.style.left = `${this.box.x}px`;
                    this.canvas.style.top = `${this.box.y}px`;
                    this.ctx = this.canvas.getContext('2d');
                },
                clip() {
                    this.ctx.translate(-this.box.x, -this.box.y);
                    this.ctx.beginPath();
                    this.ctx.moveTo(this.v0[0], this.v0[1]);
                    this.ctx.lineTo(this.v1[0], this.v1[1]);
                    this.ctx.lineTo(this.v2[0], this.v2[1]);
                    this.ctx.closePath();
                    this.ctx.clip();
                    this.ctx.drawImage(self.image, 0, 0);
                },
            };

            for (let i = 0; i < self.indices.length; i += 3) {
                p0 = self.vertices[self.indices[i + 0]];
                p1 = self.vertices[self.indices[i + 1]];
                p2 = self.vertices[self.indices[i + 2]];

                fragment = new Fragment(p0, p1, p2);

                const dx = fragment.centroid[0] - self.clickPosition[0];
                const dy = fragment.centroid[1] - self.clickPosition[1];
                const d = Math.sqrt(dx * dx + dy * dy);
                const rx = 30 * self.sign(dy);
                const ry = 90 * -self.sign(dx);
                const delay = d * 0.003 * self.randomRange(0.9, 1.1);
                fragment.canvas.style.zIndex = Math.floor(d).toString();

                const tl1 = new gsap.timeline();

                tl1.to(fragment.canvas, 1, {
                    z: -500,
                    rotationX: rx,
                    rotationY: ry,
                    ease: "power1.in"
                });
                tl1.to(fragment.canvas, 0.4, { alpha: 0 }, 0.6);

                tl0.add(tl1, delay);

                self.fragments.push(fragment);
                self.container.appendChild(fragment.canvas);
            }

            self.container.removeChild(self.image);
        },
        shatterCompleteHandler() {
            // add pooling?
            const self = this;
            self.fragments.forEach(function (f) {
                self.container.removeChild(f.canvas);
            });
            self.fragments.length = 0;
            self.vertices.length = 0;
            self.indices.length = 0;
            this.$emit('destroyDone', true);
        },

        randomRange(min, max) {
            return min + (max - min) * Math.random();
        },
        clamp(x, min, max) {
            // eslint-disable-next-line no-nested-ternary
            return x < min ? min : x > max ? max : x;
        },
        sign(x) {
            return x < 0 ? -1 : 1;
        },
    },
    mounted() {
        this.getPricePredict(this.hero.tokenId);
        this.container = document.getElementById('containerEffect');
        this.urls = this.getImageHero(
            '3d-raw',
            `${this.hero.name.toLowerCase()}-${this.hero.skin.toLowerCase()}`,
        );
        if (this.urls) {
            this.onLoadComponent();
            this.imagesLoaded();
        }
    },
};
</script>

<template>
    <div class="hero__burn">
        <div class="hero__burn__header">
            <div class="hero__burn__header-title">#{{ hero.tokenId }}</div>
            <div class="hero__burn__header-clear">
                <b-button @click="clearPick">
                    <img
                        src="~@/assets/images/icons/clearH.svg"
                        alt="clear hero"
                    />
                </b-button>
            </div>
        </div>
        <div class="hero__burn__body">
            <div class="hero__burn__body-hero" id="containerEffect">
                <img
                    v-if="false"
                    :src="
                        getImageHero(
                            '3d-raw',
                            `${hero.name.toLowerCase()}-${hero.skin.toLowerCase()}`,
                        )
                    "
                    alt=""
                />
            </div>
            <div class="hero__burn__body-stat">
                <div class="hero-level">
                    {{
                        10 > hero.level
                            ? '0' + hero.level.toString()
                            : hero.level
                    }}
                </div>
                <img
                    :key="`inventory-${hero.id}`"
                    :src="
                        require(`@/assets/images/mockup/inventory/${hero.ratity.toLowerCase()}.png`)
                    "
                    alt=""
                />
                <img
                    :key="`inventory-2-${hero.id}`"
                    :src="
                        require(`@/assets/images/mockup/inventory/${hero.skin.toLowerCase()}.png?v=1`)
                    "
                    alt=""
                />
                <img
                    :key="`inventory-3-${hero.id}`"
                    :src="
                        require(`@/assets/images/mockup/inventory/${hero.type.toLowerCase()}.png?v=1`)
                    "
                    alt=""
                />
            </div>
        </div>
        <div class="hero__burn__footer">
            <div class="hero__burn__footer__box">
                <div class="hero__burn__footer__box-title">Price Expected</div>
                <div class="hero__burn__footer__box-value">
                    {{ TruncateToDecimals2(priceHero.price, '', 4) }} USD
                </div>
            </div>
            <div class="hero__burn__footer__box">
                <div class="hero__burn__footer__box-title">Paid Per day</div>
                <div class="hero__burn__footer__box-value">
                    {{ TruncateToDecimals2(priceHero.paidPerDay, '', 4) }} USD
                </div>
            </div>
            <div class="hero__burn__footer__box">
                <div class="hero__burn__footer__box-title">Total Days Paid</div>
                <div class="hero__burn__footer__box-value">
                    {{
                        TruncateToDecimals2(priceHero.quantityDayPayment, '', 4)
                    }}
                    Days
                </div>
            </div>
        </div>
        <div class="hero__burn__burn">
            <b-button class="hero__burn__burn-button" @click="onBurn()">
                <div class="hero__burn__burn-button-content">BURN HERO</div>
            </b-button>
        </div>
    </div>
</template>

<style lang="scss">
.hero__burn {
    border: 1px solid #555555;
    border-radius: 4px;
    height: auto;
    width: 265px;
    max-height: 384px;
    padding: 20px 25px;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        &-title {
            font-family: 'HHBI';
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #00ffff;
        }
        &-clear {
            flex: 0 0 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 33px;
            button {
                padding: 0;
                background: none !important;
                box-shadow: none !important;
                outline: none !important;
                border: 0 !important;
                height: 33px;
                width: 33px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &__body {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &-hero {
            position: relative;
            width: 128px;
            height: 136px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 10;
            canvas {
                position: absolute;
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                max-width: 128px;
                max-height: 128px;
                object-fit: scale-down;
            }
        }
        &-stat {
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f4857;
            border-radius: 8px;
            padding: 12px 5px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex: 0 0 33px;
            .hero-level {
                width: 100%;
                background: #000b1a;
                color: #00ffff;
                border: 1px solid #00ffff;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                letter-spacing: 1px;
                margin-bottom: 11px;

                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 10px;
                line-height: 20px;

                color: #00ffff;
            }

            img {
                width: 100%;
                height: auto;
                max-height: 25px;
                margin-bottom: 11px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__footer {
        margin: 30px 0 25px;
        &__box {
            display: flex;
            justify-content: space-around;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
            &-title {
                flex: 1 1 auto;
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                /* or 200% */

                display: flex;
                align-items: center;
                text-align: right;
                justify-content: flex-start;
                letter-spacing: 0.01em;

                color: #00ffff;
            }
            &-value {
                flex: 1 1 auto;
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                /* or 200% */

                display: flex;
                align-items: center;
                text-align: right;
                justify-content: flex-end;
                letter-spacing: 0.01em;

                /* GrayScale/Line */

                color: #dcdcdc;
            }
        }
    }
    &__burn {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 15px;
        &-button {
            &-content {
                position: relative;
                z-index: 10;
                font-family: 'hemiheadRg-BoldItalic';
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                /* or 144% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.75px;
                // color: #FE3F61;
                justify-content: center;
            }
            border: 1px solid #fe3f61;
            background: #00000b;
            box-shadow: unset;
            position: relative;
            width: 160px;
            height: 38px;
            border-radius: 0;
            color: #fe3f61;
            &:after,
            &::before {
                z-index: 0;
                content: '';
                width: 5px;
                height: 5px;
                background: #fe3f61;
            }

            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
            }
            &::before {
                position: absolute;
                bottom: 1px;
                right: 1px;
                -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            &:hover,
            &:focus,
            &:active {
                color: #000;
                &::before {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    -webkit-transform: scaleY(1);
                    transform: scaleY(1);
                    -webkit-transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                    transition-timing-function: cubic-bezier(
                        0.52,
                        1.64,
                        0.37,
                        0.66
                    );
                }
            }
        }
    }
}
</style>
